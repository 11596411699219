import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  link: {
    color: theme.colorPalette.orange_10,
    cursor: 'pointer'
  }
});

export default makeStyles(styles);
