import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 4),
    backgroundColor: theme.colorPalette.green_30,
    marginBottom: theme.spacing(4)
  },
  errorCard: {
    backgroundColor: theme.colorPalette.red_20
  },
  label: {
    display: 'contents',
    fontWeight: 400
  },
  button: {
    width: 180,
    padding: theme.spacing(3, 4),
    display: 'flex',
    '& > span': {
      fontSize: 12,
      fontWeight: 600
    }
  },
  chevronRightIcon: {
    marginLeft: theme.spacing(2)
  }
}));

export default useStyles;
