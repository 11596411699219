// BASE FORM FIELDS
const string = () => import(/* webpackChunkName: 'string' */ './string');
const numeric = () => import(/* webpackChunkName: 'numeric' */ './numeric');
const autocomplete = () => import(/* webpackChunkName: 'autocomplete' */ './autocomplete');
const select = () => import(/* webpackChunkName: 'select' */ './select');
const date = () => import(/* webpackChunkName: 'date' */ './date');
const switchField = () => import(/* webpackChunkName: 'switch' */ './switch');
const checkbox = () => import(/* webpackChunkName: 'checkbox' */ './checkbox');
const radio = () => import(/* webpackChunkName: 'radio' */ './radio');
const address = () => import(/* webpackChunkName: 'address' */ './address');
const value = () => import(/* webpackChunkName: 'value' */ './value');
const slider = () => import(/* webpackChunkName: 'slide' */ './slide');
const submit = () => import(/* webpackChunkName: 'submit' */ './submit');
const array = () => import(/* webpackChunkName: 'array' */ './array');
const remove = () => import(/* webpackChunkName: 'array' */ './remove');
const removeEdit = () => import(/* webpackChunkName: 'array' */ './remove-edit');
const state = () => import(/* webpackChunkName: 'state' */ './state');
const file = () => import(/* webpackChunkName: 'file' */ './file');
const textarea = () => import(/* webpackChunkName: 'file' */ './text-area');
const csvFile = () => import(/* webpackChunkName: 'csv-file-upload' */ './csv-file-upload');

// BUSINESS SPECIFIC LOGIC FORM FIELDS
const affinity = () => import(/* webpackChunkName: 'affinity' */ './affinity');
const mortgageLenderName = () => import(/* webpackChunkName: 'mortgage-lender-name' */ './mortgage-lender-name');

export const fieldTypes = {
  string,
  numeric,
  autocomplete,
  select,
  date,
  switch: switchField,
  checkbox,
  radio,
  address,
  value,
  slider,
  submit,
  array,
  remove,
  removeEdit,
  state,
  affinity,
  mortgageLenderName,
  file,
  textarea,
  csvFile
};
