import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  buttons: {
    padding: theme.spacing(0, 7)
  },
  warning: {
    marginTop: 0,
    marginBottom: 0,
    boxShadow: 'none'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  mainLabel: {
    marginTop: theme.spacing(2),
    fontSize: 38
  }
});

export default makeStyles(styles);
