import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Button, Divider } from '@material-ui/core';
import Section from 'core/components/section';
import BranchTermLifeModal from 'common/components/branch-term-life-modal';
import AssurityTermLifeModal from 'common/components/assurity-life-modal';
import useStyles from './customer-additional-offers-section.styles';
import CheckIcon from 'core/assets/svg/check.svg';

const CustomerAdditionalOffersSection = ({
  branchTermLife = false,
  assurityTermLife = false,
  assurityTermLifeData
}) => {
  const {
    account: { policies },
    additionalOffers
  } = useStore();
  const policy = policies.getRecentPolicy();
  const webUserId = policy?.webUserId;
  useEffect(() => {
    if (webUserId && webUserId !== additionalOffers?.webUserId) {
      additionalOffers.getAdditionalOfferData(webUserId);
    }
  }, [webUserId]);

  const [showBranchTermLifeModal, setShowBranchTermLifeModal] = useState(false);
  const [showAssurityTermLifeModal, setShowAssurityTermLifeModal] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Section title="Additional Offers">
        {assurityTermLife && (
          <Card className={classes.card}>
            <div className={classes.cardTop}>
              <div>
                <Label className={classes.label}>(New) Term Life Insurance</Label>
                <Label className={classes.subtitle}>From Assurity, with more options</Label>
              </div>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => setShowAssurityTermLifeModal(true)}
              >
                See Offer
              </Button>
            </div>
            {additionalOffers.termLifeLastSubmitted && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.bottomMessage}>
                  <img src={CheckIcon} alt="check icon" />
                  <Label className={classes.lowerLabel}>{additionalOffers.termLifeLastSubmitted}</Label>
                </div>
              </>
            )}
          </Card>
        )}
        {branchTermLife && (
          <Card className={classes.card}>
            <div className={classes.cardTop}>
              <div>
                <Label className={classes.label}>Term Life Insurance</Label>
                <Label className={classes.subtitle}>Built within Branch's quoting platform</Label>
              </div>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => setShowBranchTermLifeModal(true)}
              >
                View details
              </Button>
            </div>
            {additionalOffers.termLifeLastSubmitted && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.bottomMessage}>
                  <img src={CheckIcon} alt="check icon" />
                  <Label className={classes.lowerLabel}>{additionalOffers.termLifeLastSubmitted}</Label>
                </div>
              </>
            )}
          </Card>
        )}
      </Section>
      {showBranchTermLifeModal && (
        <BranchTermLifeModal
          open={showBranchTermLifeModal}
          onClose={() => setShowBranchTermLifeModal(false)}
          fromAccount
        />
      )}
      {showAssurityTermLifeModal && (
        <AssurityTermLifeModal
          assurityData={assurityTermLifeData}
          open={showAssurityTermLifeModal}
          onClose={() => setShowAssurityTermLifeModal(false)}
        />
      )}
    </>
  );
};

export default observer(CustomerAdditionalOffersSection);
