import { addMonths, isBefore } from 'date-fns';
import { normalizeDate } from '@ourbranch/date-helpers';
import { DynamoDbClaim, DynamoDbPersonClaim, DynamoDbPersonDetails } from '@ourbranch/schema-types/dynamodb';
import { USAStatesByAbbv } from '@ourbranch/utility-types';
import { CCWStates } from '@ourbranch/lookups';

export function getBranchClaimClass(claim: DynamoDbClaim, state: USAStatesByAbbv) {
  let claimClass = 'NAF';
  if (claim.coverage) {
    if (claim.at_fault_ind === 'YES' || claim.at_fault_percentage > 50) {
      claimClass = 'AAF';
    } else if (claim.coverage.match(/THEFT|VAND/i)) {
      claimClass = 'theft_vandalism';
    } else if (claim.coverage.match(/COMP|GLASS/i)) {
      if (
        claim.descr &&
        claim.descr.match(/wind|hail|quake|storm|weather/i) &&
        CCWStates[state as keyof typeof CCWStates]
      ) {
        claimClass = 'CCW';
      } else {
        claimClass = claim.indpaid >= 1000 ? 'CMP' : 'CMU';
      }
    }
  }
  return claimClass;
}

type assignClaimsToDriversArgs = {
  drivers: DynamoDbPersonDetails[];
  claims: DynamoDbClaim[];
  effectiveDate: Date | string;
  state: USAStatesByAbbv;
  assignZeroClaims: boolean;
};

function sortClaimsByPaidDesc(a: DynamoDbClaim, b: DynamoDbClaim) {
  return (b.indpaid || 0) - (a.indpaid || 0);
}

export async function assignClaimsToDrivers({
  drivers,
  claims,
  effectiveDate,
  state,
  assignZeroClaims
}: assignClaimsToDriversArgs) {
  let addedClaims = 0;
  // constants we need
  const claimsLookBackInMonths = 35;
  const primary = drivers.find((d: DynamoDbPersonDetails) => d.isPrimary);
  if (!primary) {
    throw new Error('Could not find primary driver on policy');
  }

  if (Array.isArray(claims)) {
    const claimDates: any = {};
    for (const claim of claims.sort(sortClaimsByPaidDesc)) {
      if (claim.datereport?.value) {
        claim.datereport = claim.datereport.value;
      }
      const normalizedDate = normalizeDate(String(claim.datereport));
      console.log(`normalized date, ${normalizedDate}, using Branch claim: ${JSON.stringify(claim, null, 2)}`);
      const amountOK = assignZeroClaims ? true : (claim?.indpaid || 0) > 500;

      if (
        !claimDates[normalizedDate as keyof typeof claimDates] &&
        amountOK &&
        isBefore(new Date(normalizedDate || ''), new Date())
      ) {
        const normalizedDateLookBackDate = addMonths(new Date(normalizedDate || ''), claimsLookBackInMonths);
        isBefore(new Date(effectiveDate), normalizedDateLookBackDate);
        if (isBefore(new Date(effectiveDate), normalizedDateLookBackDate)) {
          const claimClass = getBranchClaimClass(claim, state);
          claimDates[normalizedDate as keyof typeof claimDates] = true;

          if (!primary.autoViolationDates[normalizedDate as keyof typeof primary.autoViolationDates]) {
            primary.autoViolationDates[normalizedDate as keyof typeof primary.autoViolationDates] = [
              {
                class: claimClass,
                miPoints: 0,
                points: claimClass === 'AAF' ? 4 : 2,
                source: 'claims',
                branch: true
              }
            ];

            // update counts on driver
            if (!primary.branchViolations) {
              primary.branchViolations = {};
            }
            const fc = claimClass as keyof typeof primary.branchViolations;
            primary.branchViolations[fc] = primary.branchViolations[fc] ? primary.branchViolations[fc] + 1 : 1;
            addedClaims += 1;
          } // if no assignment on this date already
        }
      }
    }
  }
  return addedClaims;
} // assignClaimsToDrivers

type assignPersonClaimsToDriversArgs = {
  drivers: DynamoDbPersonDetails[];
  personClaims: DynamoDbPersonClaim[];
  effectiveDate: Date | string;
  assignZeroClaims: boolean;
};

function sortPersonClaimsByPaidDesc(a: DynamoDbPersonClaim, b: DynamoDbPersonClaim) {
  return (b.indemnityPaid || 0) - (a.indemnityPaid || 0);
}

export async function assignPersonClaimsToDrivers({
  drivers,
  personClaims,
  effectiveDate,
  assignZeroClaims
}: assignPersonClaimsToDriversArgs) {
  let addedClaims = 0;
  // constants we need
  const claimsLookBackInMonths = 35;
  const primary = drivers.find((d: DynamoDbPersonDetails) => d.isPrimary);
  if (!primary) {
    throw new Error('Could not find primary driver on policy');
  }

  if (Array.isArray(personClaims)) {
    for (const claim of personClaims.sort(sortPersonClaimsByPaidDesc)) {
      const { faultCoding, lossDate, driverId, driverName, theftOrVandalism, indemnityPaid, compGlass, roadside } =
        claim;
      const driverNameUC = String(driverName).toUpperCase();
      const amountOK = assignZeroClaims ? true : (indemnityPaid || 0) > 500;
      const normalizedDateLookBackDate = addMonths(new Date(lossDate || ''), claimsLookBackInMonths);

      if (amountOK && isBefore(new Date(effectiveDate || ''), normalizedDateLookBackDate)) {
        const driver =
          drivers.find((d) => d.id === driverId) ||
          drivers.find(
            (d) =>
              driverNameUC.includes(String(d.firstName).toUpperCase().trim()) &&
              driverNameUC.includes(String(d.lastName).toUpperCase().trim())
          ) ||
          primary;

        if (!driver.autoViolationDates[lossDate as keyof typeof driver.autoViolationDates]) {
          driver.autoViolationDates[lossDate as keyof typeof driver.autoViolationDates] = [
            {
              class: faultCoding,
              miPoints: 0,
              points: faultCoding === 'AAF' ? 4 : 2,
              source: 'claims',
              branch: true,
              tvFlag: String(theftOrVandalism).toUpperCase() === 'YES',
              glassClaim: compGlass === 1,
              roadside: roadside === 1
            }
          ];

          // update counts on driver
          // update counts on driver
          if (!driver.branchViolations) {
            driver.branchViolations = {};
          }
          const fc = faultCoding as keyof typeof driver.branchViolations;
          driver.branchViolations[fc] = driver.branchViolations[fc] ? driver.branchViolations[fc] + 1 : 1;
          if (String(theftOrVandalism).toUpperCase() === 'YES') {
            driver.branchViolations.tv = driver.branchViolations.tv ? driver.branchViolations.tv + 1 : 1;
          }
          addedClaims += 1;
        } // if there isn't a claim on this date already
      }
    }
  }
  return addedClaims;
} // assignPersonClaimsToDrivers
