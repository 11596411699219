import React from 'react';
import { lookupsJson } from '@ourbranch/lookups';
import { useFormikContext } from 'formik';

import { Tooltip } from 'core/components/tooltip';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';

// https://github.com/gobranch/branch/issues/21669
const optionsNotAllowedInPAandOHFirstTerm = ['NRAT', 'CNNR', 'NRLV', 'NRLS', 'NRFP'];

const NonRenewForm = () => {
  const { values } = useFormikContext();
  const { state, term, policyType } = values;

  const limitedNonRenewOptions = ['OH', 'PA'].includes(state) && term === 1 && policyType === 'A';

  const nonRenewOptions = limitedNonRenewOptions
    ? lookupsJson.cancelReason.filter(
        (opt) => opt?.nonRenew && !opt?.deprecated && !optionsNotAllowedInPAandOHFirstTerm.includes(opt.id)
      )
    : lookupsJson.cancelReason.filter((opt) => opt?.nonRenew && !opt?.deprecated);

  return (
    <>
      <FormField
        name="nonRenewReason"
        type="select"
        label="Reason"
        mode="light"
        options={nonRenewOptions}
        xs={6}
        fast={false}
      />
      {limitedNonRenewOptions && (
        <Tooltip
          label="More Info"
          text="Non-renewal reasons are limited in the first term for Ohio and Pennsylvania."
        />
      )}
    </>
  );
};

export default withDatePicker(NonRenewForm);
