import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  errorLabel: {
    display: 'inline',
    color: 'inherit',
    fontWeight: 'normal',
    textAlign: 'initial',
    wordBreak: 'break-word'
  },
  link: {
    cursor: 'pointer',
    color: 'inherit',
    fontWeight: 'normal',
    textDecoration: 'underline'
  }
});

export default makeStyles(styles);
