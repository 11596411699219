import React from 'react';
import { Form as FormikForm, withFormik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import flowRight from 'lodash-es/flowRight';
import Grid from '@material-ui/core/Grid';
import { branchPlusCarriers, branchPlusPolicyTypes } from '@ourbranch/lookups';

import { useStore } from 'core/store/store.mobx';
import { Button } from 'core/components/button';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';

import useStyles from './branch-plus-multi-policy-discount.styles';

const initialValues = {
  policyType: '',
  policyCarrier: '',
  policyNumber: '',
  attestationAgent: false
};

const turnArrayIntoMaterialUIOptions = (options) => {
  return options.map((o) => {
    return {
      id: o,
      value: o.charAt(0).toUpperCase() + o.slice(1)
    };
  });
};
const handleSubmit = (values, { props }) => props.onAdd({ ...values, attestationAgent: props.username });

const validationSchema = () =>
  Yup.object().shape({
    policyType: Yup.string().required('Policy type is required!'),
    policyCarrier: Yup.string().required('carrier type is required!'),
    policyNumber: Yup.string(),
    attestationAgent: Yup.bool().oneOf([true]).required()
  });

const AddBranchPlusPolicyItem = observer(({ listLength, inOffer }) => {
  const classes = useStyles({ inOffer });
  const { offer } = useStore();
  const { setIncludeBranchPlusMultiPolicyDiscount } = offer;

  return (
    <FormikForm>
      {!listLength && (
        <Grid item className={classes.noPoliciesCard}>
          <Label>There are no Branch Plus Policies for this member yet.</Label>
          <Label>To add a policy, select from below</Label>
        </Grid>
      )}
      <Grid className={classes.addPolicyContainer} container spacing={2} justifyContent="space-between">
        <Label>Add Branch Plus Policy</Label>
        <Grid container spacing={2} justifyContent="space-between">
          <FormField
            type="select"
            id="policyCarrier"
            name="policyCarrier"
            label="Policy Carrier"
            mode={inOffer ? 'light' : 'dark'}
            options={turnArrayIntoMaterialUIOptions(branchPlusCarriers)}
            xs={6}
          />
          <FormField
            type="select"
            id="policyType"
            name="policyType"
            label="Policy Type"
            mode={inOffer ? 'light' : 'dark'}
            options={turnArrayIntoMaterialUIOptions(branchPlusPolicyTypes)}
            xs={6}
          />
          <FormField
            type="string"
            id="policyNumber"
            name="policyNumber"
            label="Policy Number (optional)"
            mode={inOffer ? 'light' : 'dark'}
            xs={12}
          />
          <FormField
            type="checkbox"
            name="attestationAgent"
            mode={inOffer ? 'light' : 'dark'}
            label="By opting-in, you agree that you have bound a policy for this applicant though this carrier."
          />
        </Grid>
        <Grid className={classes.buttonContainer}>
          <div>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => setIncludeBranchPlusMultiPolicyDiscount(true)}
            >
              Add Branch Plus Policy
            </Button>
          </div>
        </Grid>
      </Grid>
    </FormikForm>
  );
});

export default flowRight(
  withFormik({
    enableReinitialize: true,
    handleSubmit,
    validationSchema,
    mapPropsToValues: () => initialValues
  })
)(AddBranchPlusPolicyItem);
