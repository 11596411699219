import { localToUtcTime } from '@ourbranch/date-helpers';
import { policyTypes } from '@ourbranch/policy-types';
import { DynamoDbPolicy } from '@ourbranch/schema-types/dynamodb';
import { isBefore, addDays, isToday, startOfDay, isWithinInterval } from 'date-fns';
import { getDateFromString } from '@ourbranch/date-fns-helpers';

export const PolicyStatus = {
  Cancelled: 'Cancelled',
  InCancellation: 'In Cancellation',
  Inactive: 'Inactive',
  Future: 'Future',
  Active: 'Active'
};

// need to add this here, because importing '@ourbranch/rater-helpers' throws a circular dependency error
export const getPolicyOriginalEndDate = (effectiveDate: string, policyType: string) => {
  return new Date(
    new Date(effectiveDate).setMonth(new Date(effectiveDate).getMonth() + (policyType === 'A' ? 6 : 12)) - 1
  )
    .toISOString()
    .split('T')[0];
};

export const getPolicyStatus = (policy: any) => {
  const { endDate, effectiveDate, state } = policy;
  const fullTermPolicyEndDate = getPolicyOriginalEndDate(policy.effectiveDate, policy.policyType);

  const now = new Date();
  const endDateFromPolicy = localToUtcTime(`${endDate}T23:59:59.99`, state);

  const originalEndDate = localToUtcTime(fullTermPolicyEndDate.concat('T23:59:59.99'), state);
  const cancelledEndDate = isBefore(endDateFromPolicy, originalEndDate)
    ? localToUtcTime(`${endDate}T00:01:00.00`, state)
    : undefined;
  const startDateFromPolicy = localToUtcTime(effectiveDate, state);

  if (cancelledEndDate) {
    if (
      // add a day here to allow rescinding cancellation on canceled date
      isBefore(addDays(cancelledEndDate, 1), now) ||
      // flat cancel logic
      (endDate === effectiveDate && isToday(localToUtcTime(endDateFromPolicy, state)))
    ) {
      return PolicyStatus.Cancelled;
    }
    return PolicyStatus.InCancellation;
  }

  if (endDateFromPolicy < now) {
    return PolicyStatus.Inactive;
  }

  if (startDateFromPolicy > now) {
    return PolicyStatus.Future;
  }

  return PolicyStatus.Active;
};

// If the most recent autobill or rebill is a failure and there is not a transaction since that has been a success
export const isPolicyOverdue = (nextPayment: any, transactions?: any[]): boolean => {
  const transactionsData =
    Array.isArray(transactions) && transactions.length ? [...transactions] : nextPayment?.transactions?.data || [];

  const lastAutoBillFailedIndex = transactionsData?.findIndex((t: any) => {
    const trigger = t.metadata?.trigger || t.trigger;
    const status = t.status || t.paymentStatus;

    return (trigger === 'autobill' || trigger === 'rebill') && status === 'failed';
  });

  // If the most recent autobill or rebill is a failure and
  // there is not a transaction since that has been a success then the policy is overdue
  if (lastAutoBillFailedIndex >= 0) {
    const hasRecoverPayment = transactionsData?.some((t: any, ind: number) => {
      const status = t.status || t.paymentStatus;
      return ind < lastAutoBillFailedIndex && (status === 'paid' || status === 'success');
    });
    return !hasRecoverPayment;
  }

  return false;
};

export const isMOAutoPolicyOnUnderwritingPeriod = (policy: DynamoDbPolicy, transactionDate: string) => {
  const date = startOfDay(getDateFromString(transactionDate));

  // MO auto policy is first term AND effective date > current date - 60
  const effectiveDate = getDateFromString(policy?.effectiveDate);

  const isOnUnderwritingPeriod = isWithinInterval(date, {
    start: effectiveDate,
    end: addDays(effectiveDate, 60)
  });

  return policy.policyType === policyTypes.Auto && policy.state === 'MO' && policy.term === 1 && isOnUnderwritingPeriod;
};
