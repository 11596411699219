/** Summary
 * Only for Michigan module,
 * in the case we add something similar for other states,
 * we should follow an approach similar to UMBI policies
 * https://www.figma.com/file/Z78Nkx9SGGZIosFSYia8Gr/branch/QB30Ivxzsw1Qnzb1UOgzrY/Staff?type=design&node-id=11809-267388&t=dz6oosvkJ8e5oVYI-0
 */

import React, { useState } from 'react';
import { Divider } from '@material-ui/core';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import { ResidentsValues } from './residents-values';
import { HealthInsuranceInfo } from './health-insurance-info';
import { ExcludedResidents } from './excluded-residents';
import { PipWizardModal } from './pip-wizard-modal';
import useStyles from './personal-injury-protection.styles';

const PersonalInjuryProtection = () => {
  const classes = useStyles();
  const [pipWizardOpen, setPIPWizardOpen] = useState(false);
  return (
    <Section
      title="Personal injury protection"
      titleTooltipText="Personal injury protection covers medical expenses and lost wages for members, their spouse, and any resident relatives up to the limit they choose, no matter who caused the accident."
      type="SubSection"
    >
      <Card className={classes.card}>
        <ResidentsValues />
        <Divider className={classes.divider} />
        <HealthInsuranceInfo />
        <ExcludedResidents />
        <Button
          className={classes.button}
          mode="big"
          variant="contained"
          color="secondary"
          onClick={() => setPIPWizardOpen(true)}
        >
          Modify PIP Coverage
        </Button>
        {pipWizardOpen && <PipWizardModal open={pipWizardOpen} onClose={() => setPIPWizardOpen(false)} />}
      </Card>
    </Section>
  );
};

export default PersonalInjuryProtection;
