import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import VersionHistoryTable, { BodyTableCell } from '../shared';
import { convertUTCToET } from '../../../policy/dates';

const Header = ({ classes }) => (
  <>
    <TableCell size="small" className={classes.tableHeading}>
      Version
    </TableCell>
    <TableCell className={classes.tableHeading}>Internal Notes</TableCell>
  </>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Body = ({ internalNotes, version, updatedDateTime, effectiveDate, username, classes }) => {
  const adjustedDate = convertUTCToET(updatedDateTime);

  return (
    <>
      <TableRow className={classes.borderlessRow}>
        <BodyTableCell className={classes.borderlessCell}>{`00${version}`.substr(-3)}</BodyTableCell>
        <BodyTableCell className={classes.borderlessCell}>{internalNotes}</BodyTableCell>
      </TableRow>
      <TableRow className={classes.borderlessRow}>
        <BodyTableCell colSpan={3} className={classes.dateRow}>
          {`Created ${adjustedDate} ET by ${username || 'member or automation'}`}
        </BodyTableCell>
      </TableRow>
    </>
  );
};

Body.defaultProps = {
  internalNotes: ''
};

Body.propTypes = {
  internalNotes: PropTypes.string,
  version: PropTypes.number.isRequired,
  updatedDateTime: PropTypes.string.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired
};

const SummaryTable = ({ versions, onPageChange, count, page, state }) => (
  <VersionHistoryTable
    body={Body}
    header={Header}
    versions={versions}
    onPageChange={onPageChange}
    count={count}
    page={page}
    state={state}
  />
);

SummaryTable.propTypes = {
  versions: PropTypes.array.isRequired,
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  state: PropTypes.string.isRequired
};

SummaryTable.defaultProps = {
  onPageChange: undefined,
  count: undefined,
  page: undefined
};

export default SummaryTable;
