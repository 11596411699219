import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import styles from '../versions.styles';

const TableHeadCell = withStyles(styles)(({ classes, children }) => (
  <TableCell className={classes.bigTableHeading}>{children}</TableCell>
));

const SortLabel = ({ classes, onClick, orderBy, children, order, active }) => (
  <TableSortLabel
    classes={{ icon: classes.icon }}
    active={active}
    direction={order}
    onClick={() =>
      onClick({
        order: order === 'desc' ? 'asc' : 'desc',
        orderBy
      })
    }
    IconComponent={KeyboardArrowDownIcon}
  >
    {children}
  </TableSortLabel>
);

SortLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  orderBy: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  order: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
};

const ListHeader = ({ classes, order: { order }, onClick, showTotalPriceColumn }) => {
  const [active, setActive] = useState('version');
  return (
    <>
      <TableHeadCell>
        <SortLabel
          classes={classes}
          active={active === 'version'}
          order={order}
          orderBy={(a, b) => (a.version < b.version ? 1 : -1)}
          onClick={(newOrder) => {
            setActive('version');
            onClick(newOrder);
          }}
        >
          Version
        </SortLabel>
      </TableHeadCell>
      <TableHeadCell>
        <SortLabel
          classes={classes}
          active={active === 'updatedDateTime'}
          order={order}
          orderBy={(a, b) => (new Date(a.updatedDateTime) < new Date(b.updatedDateTime) ? 1 : -1)}
          onClick={(newOrder) => {
            setActive('updatedDateTime');
            onClick(newOrder);
          }}
        >
          Created Date
        </SortLabel>
      </TableHeadCell>
      <TableHeadCell>By</TableHeadCell>
      <TableHeadCell>Coverage Changed</TableHeadCell>
      <TableHeadCell>Items Changed</TableHeadCell>
      <TableHeadCell>Internal Notes</TableHeadCell>
      <TableHeadCell>Change In Premium</TableHeadCell>
      {showTotalPriceColumn && <TableHeadCell>Total Price</TableHeadCell>}
    </>
  );
};

ListHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  showTotalPriceColumn: PropTypes.bool.isRequired
};

export default ListHeader;
