import debounce from 'debounce-promise';

import awsExports from '../../../aws-exports';
import { getClient as getMeiliSearchClient } from '../../../search/meilisearch';

const indexNames = {
  offers: `offer-${awsExports.stackeryEnvironmentName}`,
  offerHistory: `offer-history-${awsExports.stackeryEnvironmentName}`,
  users: `users-${awsExports.stackeryEnvironmentName}`,
  policy: `policysummary-${awsExports.stackeryEnvironmentName}`,
  branchPlusPolicies: `branch-plus-policies-${awsExports.stackeryEnvironmentName}`,
  staffUsers: `staff-users-${awsExports.stackeryEnvironmentName}`
};

/**
 * This function mantains a cache of debounced function based on the provided key, in the case of meilisearch search,the searchIndex
 *
 * The point of this is to debounce only similar request.
 * This was done in response to a bug that Dave reported which was as a result of totally
 * different request getting debounced and essentially returning the same data.
 * For example, parallel request to query offer and customer only triggers a single network request
 *
 * @param {*} debuncedFunc
 * @param {*} timeOut
 */
const debounceWithArgs = (debuncedFunc, timeOut) => {
  const debouncedFuncs = {};

  return (key, ...args) => {
    if (!(key in debouncedFuncs)) debouncedFuncs[key] = debounce(debuncedFunc, timeOut);

    return debouncedFuncs[key](key, ...args);
  };
};

const normalizePhoneNumber = (searchString) => {
  if (searchString.match(/\+*1*\(*(\d{3})\)*-*\s*\(*(\d{3})\)*-*\s*\(*(\d{4})\)*/)) {
    return searchString.replace(/\+*1*\(*(\d{3})\)*-*\s*\(*(\d{3})\)*-*\s*\(*(\d{4})\)*/, '$1$2$3');
  }
  return searchString;
};

const mapToAlgoliaResponse = (response) => {
  if (response) {
    return {
      nbHits: response.totalHits,
      hits: response.hits
    };
  }
  return response;
};

export const debouncedMeiliSearch = debounceWithArgs(async (indexName, searchParams, userPreferences) => {
  const index = getMeiliSearchClient(userPreferences).index(indexName);
  const { query, attributeForDistinct, page, ...searchParamsRest } = searchParams;

  if (index) {
    return index
      .search(query, {
        page: page + 1,
        ...searchParamsRest
      })
      .then((response) => mapToAlgoliaResponse(response));
  }

  return [];
}, 500);

export const searchForWebUserId = async (webUserId, userPreferences) => {
  const indexName = indexNames.offerHistory;

  const searchParameters = {
    hitsPerPage: 250,
    page: 1,
    attributesToSearchOn: ['webUserId'],
    matchingStrategy: 'all'
  };

  const index = getMeiliSearchClient(userPreferences).index(indexName);
  if (index) {
    try {
      const res = await index.search(webUserId, searchParameters).then((response) => mapToAlgoliaResponse(response));
      return res;
    } catch (error) {
      return [];
    }
  }
  return [];
};

const meiliSearch = async ({ searchString, index, page, hitsPerPage = 10, expandedSearch }, userPreferences) => {
  const indexName = indexNames[index];

  let query = searchString;
  if (index === 'users') {
    // if someone searches by phone number, it's best to search just by the numbers:
    query = normalizePhoneNumber(searchString);
  }

  const searchParameters = {
    query,
    hitsPerPage,
    page,
    matchingStrategy: 'all'
  };

  if (index === 'users') {
    searchParameters.attributesToSearchOn = [
      'attributes.name',
      'attributes.family_name',
      'Username',
      'attributes.address',
      'attributes.custom_state',
      'attributes.custom_city',
      'attributes.custom_zip'
    ];

    if (expandedSearch) {
      searchParameters.attributesToSearchOn = searchParameters.attributesToSearchOn.concat([
        'attributes.phone_number',
        'attributes.custom_addl_phone_numbers',
        'attributes.email',
        'attributes.custom_affinity_code'
      ]);
    }
  }

  if (index === 'offers' || index === 'offerHistory') {
    searchParameters.attributesToSearchOn = [
      'quote.fname',
      'quote.lname',
      'quote.drivers.firstName',
      'quote.drivers.lastName',
      'quote.submittedAddress.address',
      'quote.submittedAddress.city',
      'quote.submittedAddress.state',
      'quote.submittedAddress.zip',
      'quote.rep',
      'webUserId'
    ];

    if (expandedSearch) {
      searchParameters.attributesToSearchOn = searchParameters.attributesToSearchOn.concat([
        'quote.email',
        'quote.phone',
        'quote.global.affinity'
      ]);
    }
  }

  if (index === 'policy') {
    searchParameters.attributesToHighlight = ['*'];
  }

  if (index === 'branchPlusPolicies') {
    searchParameters.attributesToSearchOn = ['address', 'state', 'customer_first_name', 'customer_last_name'];

    if (expandedSearch) {
      searchParameters.attributesToSearchOn = searchParameters.attributesToSearchOn.concat([
        'phone_number',
        'email',
        'policy_id',
        'account_id'
      ]);
    }
  }

  return debouncedMeiliSearch(indexName, searchParameters, userPreferences);
};

export const runSearch = async (
  { searchString, index, page, hitsPerPage = 10, expandedSearch, showOfferHistory },
  userPreferences
) => {
  return meiliSearch({ searchString, index, page, hitsPerPage, expandedSearch, showOfferHistory }, userPreferences);
};
