import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import ScreenHeader from 'core/components/screen-header';
import { Label } from 'core/components/label';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import useStyles from './header.styles';

const AgencyPortalHeader = () => {
  const classes = useStyles();
  const session = useSession();
  const history = useHistory();
  const { affinityLookups } = useStore();

  const userAffinityCode = session.user.affinityCode;
  const affinityData = affinityLookups.data.find(({ affinity }) => affinity === userAffinityCode);
  const agencyName = affinityData?.name;

  useEffect(() => {
    affinityLookups.getByAffinity(userAffinityCode);
  }, []);

  return (
    <Grid container className={classes.headerWrapper}>
      <Grid className={classes.titleWrapper} container item xs={10}>
        <ScreenHeader
          title="Agency Portal"
          options={{ hideBackButton: true }}
          onBack={() => {
            history.push(`/portal`);
          }}
        />
        <Label type="infoSubtitle">Your hub for all things Branch</Label>
      </Grid>
      <Grid item xs={2} className={classes.agentInfoContainer}>
        <Grid className={classes.agentLabelWrappers}>
          <Label type="greenExtraSmall" className={classes.agentLabels} label="User">
            User
          </Label>
          <Label className={classes.agentInfo}>{session.user.username}</Label>
        </Grid>
        <Grid className={classes.agentLabelWrappers}>
          {agencyName && (
            <>
              <Label type="greenExtraSmall" className={classes.agentLabels}>
                Agency
              </Label>
              <Label className={classes.agentInfo}>{agencyName}</Label>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(AgencyPortalHeader);
