import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import { getPaymentMethods } from '../helper';
import { ABODE_PRO_PLAN_AFFINITY, ABODE_PRO_PLAN_PROVIDER_NAME } from 'core/helpers/constants';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';

const useCheckoutContextGenerator = () => {
  const {
    offer: { offer, selectedOption, getIsLicensedForState }
  } = useStore();
  const session = useSession();
  const { isAgency, canBind } = session;

  if (!offer) {
    return {
      canBind: false,
      isLicensedToPurchase: false
    };
  }

  const { hasACH, hasCreditCard } = getPaymentMethods({
    paymentMethods: offer.quote.global,
    policyType: getQuoteFriendlySelectedOption(selectedOption),
    noBindHome: offer.quote.noBindHome,
    noBindAuto: offer.quote.noBindAuto,
    hasAbodeProPlan:
      offer.quote.global.affinity === ABODE_PRO_PLAN_AFFINITY &&
      offer.quote.connectedHome.providerName === ABODE_PRO_PLAN_PROVIDER_NAME
  });

  return {
    canBindDirectly: isAgency || (!hasACH && !hasCreditCard),
    isLicensedToPurchase: getIsLicensedForState(session) && canBind
  };
};

export default useCheckoutContextGenerator;
