/* eslint-disable func-names */
import * as Yup from 'yup';
import { addDays, isAfter, isBefore } from 'date-fns';
import { standaloneRentersStates, policyType, soldToEffectiveDaysIntervalByState } from '@ourbranch/lookups';

import { getSoldDaysIntervalFromToday } from 'offer/helpers/effective-date-helpers';
// Effective date for SA Renters policies must be after October 1st, 2022
const standaloneRentersLimitDate = new Date(2022, 9, 1);
const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

const getMinPolicyDateMessage = (days) => `Policy start date must be at least ${days} days in the future`;

const schema = Yup.object().shape({
  global: Yup.object()
    .shape({
      homeEffectiveDate: Yup.date()
        .test('min', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Home) && !this.options.context?.noBindHome) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const daysDateInterval = getSoldDaysIntervalFromToday(this.options.context.state);
            const dateDaysOut = addDays(new Date(), daysDateInterval - 1);
            let message = getMinPolicyDateMessage(daysDateInterval);
            let minDate = dateDaysOut;
            const date = new Date(obj);
            if (purchaseDateToCompare && isAfter(date, dateDaysOut) && isAfter(purchaseDateToCompare, dateDaysOut)) {
              message = 'Policy start date cannot be before the home purchase date';
              minDate = new Date(purchaseDateToCompare);
            }
            if (isBefore(date, minDate)) {
              return this.createError({ message });
            }
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days.', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Home) && !this.options.context?.noBindHome) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      autoEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days.', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Auto) && !this.options.context?.noBindAuto) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('min', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Auto) && !this.options.context?.noBindAuto) {
            const daysDateInterval = getSoldDaysIntervalFromToday(this.options.context.state);
            const dateDaysOut = addDays(new Date(), daysDateInterval - 1);
            const date = new Date(obj);
            if (!isBefore(dateDaysOut, date)) {
              const message = getMinPolicyDateMessage(
                soldToEffectiveDaysIntervalByState[this.options.context.state] || 7
              );
              return this.createError({ message });
            }
          }
          return true;
        }),
      rentersEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days.', function (obj) {
          if (
            this.options.context?.selectedOption?.includes(policyType.Renters) &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('standalone-renters-min', 'Policy start date must be after 10/1/2022.', function (obj) {
          if (
            this.options.context?.selectedOption?.includes(policyType.Renters) &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date >= standaloneRentersLimitDate;
          }
          return true;
        })
        .test('min', function (obj) {
          if (
            this.options.context?.selectedOption?.includes(policyType.Renters) &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const dateDaysOut = addDays(
              new Date(),
              (soldToEffectiveDaysIntervalByState[this.options.context.state] || 7) - 1
            );
            const date = new Date(obj);
            if (!isBefore(dateDaysOut, date)) {
              const message = getMinPolicyDateMessage(
                soldToEffectiveDaysIntervalByState[this.options.context.state] || 7
              );
              return this.createError({ message });
            }
          }
          return true;
        }),
      condoEffectiveDate: Yup.date()
        .nullable()
        .test('min', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Condo)) {
            const purchaseDate = this.options.context?.condo?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const dateDaysOut = addDays(
              new Date(),
              (soldToEffectiveDaysIntervalByState[this.options.context.state] || 7) - 1
            );
            let message = getMinPolicyDateMessage(soldToEffectiveDaysIntervalByState[this.options.context.state] || 7);
            let minDate = dateDaysOut;
            const date = new Date(obj);
            if (purchaseDateToCompare && isAfter(date, dateDaysOut) && isAfter(purchaseDateToCompare, dateDaysOut)) {
              message = 'Policy start date cannot be before the condo purchase date';
              minDate = new Date(purchaseDateToCompare);
            }
            if (isBefore(date, minDate)) {
              return this.createError({ message });
            }
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Condo)) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
    })
    .nullable()
});

export default schema;
