import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import { getPersonAge } from '../helpers';
import useStyles from './personal-details.styles';

const PersonalDetails = ({ person, children }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between">
      <div className={classes.nameContainer}>
        <Label type="body2" className={classes.name}>
          {person.firstName} {person.lastName}
        </Label>
        <Label type="body4">{person.age || getPersonAge(person.dateOfBirth)} YEARS</Label>
      </div>
      {children}
    </Grid>
  );
};

PersonalDetails.propTypes = {
  person: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default PersonalDetails;
