import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme) =>
  createStyles({
    layout: ({ showHeader }) => ({
      position: 'relative',
      width: '100%',
      left: 0,
      transition: '225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      height: showHeader ? `calc(100vh - ${theme.sizes.headerHeight}px)` : '100vh',
      'overflow-y': 'auto'
    }),
    loading: {
      display: 'flex',
      paddingTop: 0,
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: ({ showHeader }) => ({
      width: 1132,
      height: showHeader ? 'auto' : '100vh',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: showHeader ? theme.spacing(10) : 0
    }),
    logo: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100vh'
    }
  });

export default makeStyles(styles);
