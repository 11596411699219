import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Table } from '../../../../core/components/table';
import styles from './hold-cards.styles';

export const BodyTableCell = withStyles(styles)(({ children, classes, className, ...props }) => (
  <TableCell className={classNames(classes.tableCell, className)} {...props}>
    {children}
  </TableCell>
));

BodyTableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const AllHoldCardsTable = memo(({ allActiveHoldCards, classes, header, body, onPageChange, count, page, state }) => {
  const HeaderComponent = header;
  const BodyComponent = body;
  return (
    <div className={classes.tableContainer}>
      <Table
        classes={{ container: classes.table, header: classes.tableHeader }}
        header={<HeaderComponent classes={classes} />}
        body={(allActiveHoldCards || []).map(({ createdDate, createdBy, reason }, index) => (
          <BodyComponent
            state={state}
            key={index}
            createdDate={createdDate}
            createdBy={createdBy}
            index={index}
            reason={reason}
            classes={classes}
            count={count}
            page={page}
          />
        ))}
      />
      {!!onPageChange && (
        <TablePagination
          component="div"
          onPageChange={onPageChange}
          count={count}
          page={page}
          rowsPerPage={5}
          rowsPerPageOptions={[]}
          classes={{ caption: classes.paginationCaption }}
        />
      )}
    </div>
  );
});

AllHoldCardsTable.propTypes = {
  allActiveHoldCards: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  header: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  state: PropTypes.string.isRequired
};

AllHoldCardsTable.defaultProps = {
  onPageChange: undefined,
  count: undefined,
  page: undefined
};

export default withStyles(styles)(AllHoldCardsTable);
