import { flow, types } from 'mobx-state-tree';

import { areStringsEqual } from 'core/helpers/equality-checker';
import { getSingle, ContentTypes } from 'core/helpers/prismic';

export const CarriersStore = types
  .model({
    list: types.array(types.frozen())
  })
  .actions((self) => ({
    getCarriers: flow(function* getCarriers() {
      if (!self.list.length) {
        try {
          const response = yield getSingle(ContentTypes.CarriersInformation);
          if (response && response.data && response.data.body) {
            self.list = response.data.body.map((carrier) => ({
              name: carrier.primary.carrier_name,
              policyType: carrier.primary.policy_type,
              logo: carrier.primary.logo.url,
              serviceInstructions: carrier.primary.service_instructions,
              phoneNumber: carrier.primary.phone_number,
              website: carrier.primary.website,
              loginUrl: carrier.primary.policy_management_system_login_url
            }));
          } else {
            self.list = [];
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(`Failed to get carriers: ${error.message}`);
          self.list = [];
        }
      }
    }),
    getCarrierInformation: flow(function* getCarrierInformation(name, policyType) {
      if (!self.list.length) {
        yield self.getCarriers();
      }
      return self.list.find(
        (carrier) => areStringsEqual(carrier.name, name) && areStringsEqual(carrier.policyType, policyType)
      );
    })
  }));
