import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { Tooltip } from 'core/components/tooltip';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';

import closeIcon from 'core/assets/svg/x.svg';
import arrowIcon from 'core/assets/svg/ic-arrow.svg';
import learnMore from 'core/assets/svg/ic-learn-more.svg';
import bundleIcon from 'core/assets/svg/ic-bundle-icon.svg';
import caret from 'core/assets/svg/caretOrange.svg';
import increaseLowImpact from 'core/assets/svg/increase-low-impact.png';
import increaseMedImpact from 'core/assets/svg/increase-med-impact.png';
import increaseHighImpact from 'core/assets/svg/increase-high-impact.png';
import savingsLowImpact from 'core/assets/svg/savings-low-impact.png';
import savingsMedImpact from 'core/assets/svg/savings-med-impact.png';
import savingsHighImpact from 'core/assets/svg/savings-high-impact.png';
import opportunitiesLowImpact from 'core/assets/svg/opportunities-low-impact.png';
import opportunitiesMedImpact from 'core/assets/svg/opportunities-med-impact.png';
import opportunitiesHighImpact from 'core/assets/svg/opportunities-high-impact.png';
import upArrowIcon from 'core/assets/svg/ic-up-arrow.svg';
import checkIcon from 'core/assets/svg/ic-empty-check.svg';
import noSavingsIcon from 'core/assets/svg/ic-no-savings.svg';
import useStyles from './compare-renewal-price-factors-modal.styles';
import {
  derivePriceIncreaseReasons,
  deriveCurrentSavings,
  deriveSavingsOpportunties
} from './compare-price-factors-helpers';

const CompareRenewalPriceFactorsModal = ({
  open,
  onClose,
  currentPolicy,
  previousPolicy,
  renewalPriceComparisonDetails,
  loading,
  currentPolicyTotalPrice,
  previousPolicyExitPrice
}) => {
  const classes = useStyles();
  const [increaseOpen, setIncreaseOpen] = useState(true);
  const [savingsOpen, setSavingsOpen] = useState(true);
  const [opportunitiesOpen, setOpportunitiesOpen] = useState(true);

  const increases = derivePriceIncreaseReasons(currentPolicy, previousPolicy, renewalPriceComparisonDetails);
  const savings = deriveCurrentSavings(renewalPriceComparisonDetails);
  const opportunities = deriveSavingsOpportunties(currentPolicy.policyType, renewalPriceComparisonDetails);

  const priceDifference = (currentPolicyTotalPrice - previousPolicyExitPrice).toFixed(2);
  const percentDifference = (Math.abs(priceDifference / previousPolicyExitPrice) * 100).toFixed(0);
  const generateAccordion = ({
    title,
    singlularLabel,
    pluralLabel,
    items,
    setIsOpen,
    isOpen,
    iconColorClass,
    lowImpactSrc,
    medImpactSrc,
    highImpactSrc,
    noItemsIcon = null,
    noItemsIconAlt = '',
    noItemsBanner = null,
    noItemsText = null
  }) => {
    return (
      <div className={classes.collapse}>
        <Collapse in collapsedSize={68}>
          <div className={classes.collapseContainer}>
            <Grid className={classes.cardHeader}>
              <div className={classes.priceBlock}>
                <Label className={classes.cardTitle}>{title}</Label>
                <Label className={classes.cardCount}>
                  {loading
                    ? 'Loading...'
                    : items?.length === 1
                    ? `${items.length} ${singlularLabel}`
                    : `${items.length} ${pluralLabel}`}
                </Label>
              </div>
              <Button onClick={() => setIsOpen(!isOpen)} variant="text" color="secondary">
                <img className={isOpen ? classes.icon : classes.iconFold} src={caret} alt={open ? 'fold' : 'unfold'} />
              </Button>
            </Grid>
            {isOpen && (
              <Grid container>
                {items.map((item, index) => {
                  return (
                    <Grid item key={`${singlularLabel}-${index}`} className={classes.lineItem}>
                      <div className={classes.column}>
                        <div className={classes.lineItemTitle}>{item?.title}</div>
                        <div className={classes.lineItemDescription}>{item?.description}</div>
                        {item?.learnMoreLink && (
                          <IconButton className={classes.learnMoreLink}>
                            <a href={item?.learnMoreLink} target="_blank" rel="noreferrer noopener">
                              <img src={learnMore} alt="Learn More" />
                              <div className={classes.learnMoreLinkText}>Learn More</div>
                            </a>
                          </IconButton>
                        )}
                        {item?.bundleLink && (
                          <IconButton className={classes.bundlePolicyLink}>
                            <a href="/quote" target="_blank" rel="noreferrer noopener">
                              <img src={bundleIcon} alt="Bundle Link Icon" />
                              <div className={classes.learnMoreLinkText}>Check eligibility and get a price</div>
                            </a>
                          </IconButton>
                        )}
                      </div>
                      {item?.impact === 'LOW' && (
                        <div className={classNames(classes.impactIcon, iconColorClass)}>
                          <img className={classes.impactIconImage} src={lowImpactSrc} alt="Low Impact" />
                          Low impact
                        </div>
                      )}
                      {item?.impact === 'MED' && (
                        <div className={classNames(classes.impactIcon, iconColorClass)}>
                          <img className={classes.impactIconImage} src={medImpactSrc} alt="Medium Impact" />
                          Med. impact
                        </div>
                      )}
                      {item?.impact === 'HIGH' && (
                        <div className={classNames(classes.impactIcon, iconColorClass)}>
                          <img className={classes.impactIconImage} src={highImpactSrc} alt="High Impact" />
                          High impact
                        </div>
                      )}
                    </Grid>
                  );
                })}
                {!loading && items.length === 0 && noItemsIcon && (
                  <div className={classes.noResults}>
                    <img className={classes.noResultsIcon} src={noItemsIcon} alt={noItemsIconAlt} />
                    <div className={classes.noResultsBanner}>{noItemsBanner}</div>
                    <div className={classes.noResultsText}>{noItemsText}</div>
                  </div>
                )}
              </Grid>
            )}
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label className={classes.title}>Total term price comparison</Label>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.comparisonRow}>
          <Grid item className={classes.termInfo}>
            <Label className={classes.termLabel}>Prior term</Label>
            <Grid item>
              <Grid item className={classes.priceBlock}>
                <div className={classes.priceLabel}>Final Segment Price</div>
                <Tooltip
                  label="More info"
                  text="This includes fees and surplus contributions. It might be different from the total amount paid last term if coverage was changed mid-term"
                  placement="top"
                  className={classes.tooltip}
                  height={14}
                />
              </Grid>

              <div className={classes.price}>${previousPolicyExitPrice}</div>
            </Grid>
          </Grid>
          <Grid item className={classes.centerIcon}>
            <img className={classes.arrowIcon} src={arrowIcon} alt="Arrow Icon" />
          </Grid>

          <Grid item className={classes.termInfo}>
            <Label className={classes.termLabel}>Current term</Label>
            <div className={classes.comparisonRow}>
              <Grid item>
                <Grid item className={classes.priceBlock}>
                  <div className={classes.priceLabel}>Total price</div>
                </Grid>
                <div className={classes.price}>${currentPolicyTotalPrice}</div>
              </Grid>
              {priceDifference > 0 && (
                <Grid item className={classNames(classes.alert, classes.red)}>
                  <div className={classNames(classes.iconWrapper, classes.increase)}>
                    <img className={classes.upArrowIcon} src={upArrowIcon} alt="Increase Icon" />
                  </div>
                  ${priceDifference} ({percentDifference}%) increase
                </Grid>
              )}
              {priceDifference < 0 && (
                <Grid item className={classNames(classes.alert, classes.green)}>
                  <div className={classNames(classes.iconWrapper, classes.decrease)}>
                    <img className={classes.upArrowIcon} src={upArrowIcon} alt="Decrease Icon" />
                  </div>
                  ${Math.abs(priceDifference)} ({percentDifference}%) decrease
                </Grid>
              )}
              {priceDifference === 0 && (
                <Grid item className={classNames(classes.alert, classes.grey)}>
                  No change
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>

        {generateAccordion({
          title: 'Increase Reasons',
          singlularLabel: 'reason',
          pluralLabel: 'reasons',
          items: increases,
          setIsOpen: setIncreaseOpen,
          isOpen: increaseOpen,
          iconColorClass: classes.increasesColor,
          lowImpactSrc: increaseLowImpact,
          medImpactSrc: increaseMedImpact,
          highImpactSrc: increaseHighImpact,
          noItemsIcon: checkIcon,
          noItemsIconAlt: 'Checkmark',
          noItemsBanner: 'No increase reasons',
          noItemsText: 'This member’s policy does not have any increase reasons we could identify.'
        })}

        {generateAccordion({
          title: 'Current Savings',
          singlularLabel: 'saving',
          pluralLabel: 'savings',
          items: savings,
          setIsOpen: setSavingsOpen,
          isOpen: savingsOpen,
          iconColorClass: classes.savingsColor,
          lowImpactSrc: savingsLowImpact,
          medImpactSrc: savingsMedImpact,
          highImpactSrc: savingsHighImpact,
          noItemsIcon: noSavingsIcon,
          noItemsIconAlt: 'Wallet icon',
          noItemsBanner: 'No current savings',
          noItemsText: 'This member’s policy does not have any active savings or discounts.'
        })}

        {generateAccordion({
          title: 'Saving opportunities',
          singlularLabel: 'opportunity',
          pluralLabel: 'opportunities',
          items: opportunities,
          setIsOpen: setOpportunitiesOpen,
          isOpen: opportunitiesOpen,
          iconColorClass: classes.opportunitiesColor,
          lowImpactSrc: opportunitiesLowImpact,
          medImpactSrc: opportunitiesMedImpact,
          highImpactSrc: opportunitiesHighImpact
          // skipping the noItems params as there is ALWAYS at least one item in this list
        })}
      </DialogContent>
    </Dialog>
  );
};
export default observer(CompareRenewalPriceFactorsModal);
