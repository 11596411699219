import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end'
  },
  left: {
    textAlign: 'start'
  },
  noUnitNumber: {
    margin: 'auto',
    textAlign: 'start'
  },
  secondaryPhoneContainer: {
    border: `3px solid ${theme.colorPalette.white_10}`,
    borderRadius: 3,
    padding: theme.spacing(2, 0, 2, 5),
    margin: theme.spacing(4, 0)
  },
  textCheckbox: {
    marginLeft: '5px'
  },
  link: {
    color: theme.colorPalette.orange_10
  },
  underline: {
    borderBottom: `1px solid ${theme.colorPalette.orange_10}`
  }
});

export default makeStyles(styles);
