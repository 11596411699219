import React from 'react';
import { LottieLoader } from 'core/components/loading';

import useStyles from './auth-loader.styles.js';

const AuthLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loaderCard}>
        <LottieLoader height={50} width={50} />
      </div>
    </div>
  );
};

export default AuthLoader;
