import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 240,
    justifyContent: 'center',
    gap: theme.spacing(2)
  },
  title: {
    color: theme.colorPalette.green_20,
    fontSize: 24,
    lineHeight: 32,
    fontWeight: 300
  },
  content: {
    padding: theme.spacing(0, 12)
  },
  buttons: {
    paddingTop: theme.spacing(3)
  },
  buttonIcon: {
    marginRight: theme.spacing(2)
  }
});

export default makeStyles(styles);
