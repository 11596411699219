import React, { useEffect, useRef, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import { personalDetailsCodes } from '../quote-forms/quote-forms.constants';
import { Collapsible } from '../collapsible';
import { ErrorAlert } from '../quote-errors';

const PersonalDetails = ({ sectionsToShow }) => {
  const {
    quote: { getAlerts }
  } = useStore();

  const personalDetailsErrors = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);

  const { values, isSubmitting } = useFormikContext();

  const alerts = getAlerts(personalDetailsCodes, values.policyType);

  useEffect(() => {
    if (alerts.length && personalDetailsErrors.current && isSubmitting) {
      personalDetailsErrors.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [alerts, isSubmitting]);

  useEffect(() => {
    if (sectionsToShow && sectionsToShow?.length > 0) {
      const shouldBeShown = personalDetailsCodes.every((element) => sectionsToShow.includes(element));
      setShouldShow(shouldBeShown);
    }
  }, [sectionsToShow]);

  return (
    <Form>
      {alerts.length > 0 && <ErrorAlert errors={alerts} ref={personalDetailsErrors} />}
      {shouldShow && (
        <Collapsible title="Personal Details" forceOpen={alerts.length > 0} initialOpen={alerts.length > 0}>
          <Field
            xs={4}
            type="state"
            id="driversLicenseState"
            name="driversLicenseState"
            mode="light"
            label="Drivers License State"
          />
          <Field
            xs={8}
            type="string"
            id="driversLicenseNumber"
            name="driversLicenseNumber"
            mode="light"
            label="Drivers License Number"
            pii
          />
          <Field
            xs={4}
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            mode="light"
            label="Date of birth"
            defaultToEmpty
            pii
          />
        </Collapsible>
      )}
    </Form>
  );
};

PersonalDetails.propTypes = {
  sectionsToShow: PropTypes.array.isRequired
};

export default withDatePicker(PersonalDetails);
