import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(6)
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  }
});

export default makeStyles(styles);
