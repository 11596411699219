import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useContext } from 'react';
import { observer, useAsObservableSource } from 'mobx-react';
import { reaction } from 'mobx';
import { Route, Switch } from 'react-router-dom';

import { useStore } from 'core/store';
import CustomersResults from 'common/components/search-results/customers-results';
import OffersResults from 'common/components/search-results/offers-results';
import PoliciesResults from 'common/components/search-results/policies-results';
import BranchPlusResults from 'common/components/search-results/branch-plus-results';
import { AuthContext } from 'core/components/auth';
import { useQueryParams } from 'core/hooks/useQueryParams';
import SearchForm from './components/search-form';
import SearchHeader from './components/search-header';

const Search = observer(function Search({ location, history }) {
  const {
    quote: { setPrefillData },
    search
  } = useStore();
  const {
    loading,
    setCurrentResultsPage,
    currentResultsPage,
    setSearchString,
    searchString,
    searchCustomersAction,
    searchOffersAction,
    searchPoliciesAction,
    searchBranchPlusPoliciesAction,
    customersResults,
    customersResults: { nbHits: customerNumHits },
    offersResults,
    offersResults: { nbHits: offerNumHits },
    policiesResults,
    policiesResults: { nbHits: policyNumHits },
    branchPlusPoliciesResults,
    branchPlusPoliciesResults: { nbHits: branchPlusNumHits },
    error
  } = search;
  const { canEdit, canQuote, viewOnly, isInternalAgent } = useContext(AuthContext);
  const params = useQueryParams();

  // Set the current table page page back to 0 when the route changes.
  // `history.listen` returns a function that stops the listener when called,
  // which is used as the cleanup function for this effect.
  useEffect(() => history.listen(() => setCurrentResultsPage(0)));

  const runSearch = useCallback(async () => {
    // The `setOfferSearchResults` / `setUserSearchResults` functions will be called multiple times with the same
    // result when all the queued searches from the debounced search function resolve. But because they'll all resolve
    // with the same result, React's diffing will only cause one re-render, so this won't cause a performance issue.
    searchCustomersAction();
    searchOffersAction();
    searchPoliciesAction(isInternalAgent);
    if (isInternalAgent) {
      searchBranchPlusPoliciesAction();
    }
  }, [searchCustomersAction, searchOffersAction, searchPoliciesAction, searchBranchPlusPoliciesAction]);

  // The `runSearch` function changes when one of the items in its dependency array changes
  // When one of those things change we also want to run the search again.
  useEffect(() => {
    runSearch();
  }, [runSearch, currentResultsPage]);

  useEffect(() => {
    if (!canEdit || !canQuote) {
      history.replace('/search/policies');
    }
  }, [canEdit, history, canQuote]);

  // handle searching from query params, and redirect if no search results
  // need to create observablesource to work correctly with the reaction
  const source = useAsObservableSource({ inboundQuery: params?.search });

  useEffect(() => {
    if (source.inboundQuery) {
      setSearchString(source.inboundQuery);
      runSearch();
    }
  }, [source.inboundQuery, setSearchString, runSearch]);

  reaction(
    () => search.offersResults,
    (results) => {
      if (results.nbHits === 0 && source.inboundQuery) {
        // search returned 0 results so redirect to new quote form with prefilled values if supplied
        if (params.firstName) {
          setSearchString(''); // or else the results dropdown will show

          // the search key/value pair from query params isn't needed in the prefill data of the new quote form
          const { search: _search, ...prefillParams } = params;
          setPrefillData({ quote: prefillParams }, true);
          history.push('/quote');
        }
      }
    }
  );

  const onSearchStringChange = (newSearchString) => {
    setSearchString(newSearchString);
    setCurrentResultsPage(0);
    runSearch();
  };

  const onSearchButtonPress = () => {
    setCurrentResultsPage(0);
    runSearch();
  };

  return (
    <>
      <SearchHeader>
        <SearchForm
          value={searchString}
          onChange={(e) => onSearchStringChange(e.target.value)}
          onSearchButtonPress={onSearchButtonPress}
          location={location}
        />
      </SearchHeader>
      <Switch>
        {(canEdit || viewOnly) && (
          <Route path="/search/offers">
            <OffersResults
              loading={loading}
              hits={offersResults.hits}
              totalHits={offerNumHits}
              page={currentResultsPage}
              onPageChange={setCurrentResultsPage}
              showHeader
              error={error}
            />
          </Route>
        )}
        <Route path="/search/customers">
          <CustomersResults
            loading={loading}
            hits={customersResults.hits}
            totalHits={customerNumHits}
            page={currentResultsPage}
            onPageChange={setCurrentResultsPage}
            useLink
            error={error}
          />
        </Route>
        <Route path="/search/policies">
          <PoliciesResults
            loading={loading}
            hits={policiesResults.hits}
            totalHits={policyNumHits}
            page={currentResultsPage}
            onPageChange={setCurrentResultsPage}
            useLink
            error={error}
          />
        </Route>
        {isInternalAgent && (
          <Route path="/search/branch-plus">
            <BranchPlusResults
              loading={loading}
              hits={branchPlusPoliciesResults.hits}
              totalHits={branchPlusNumHits}
              page={currentResultsPage}
              onPageChange={setCurrentResultsPage}
            />
          </Route>
        )}
      </Switch>
    </>
  );
});

Search.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Search;
