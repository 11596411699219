import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'baseline'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  title: {
    color: theme.colors.header__label_primary
  },
  rightContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  rightLabel: {
    color: theme.colorPalette.beige_75,
    display: 'inline',
    textTransform: 'uppercase'
  },
  container: {
    marginBottom: theme.spacing(2)
  }
}));

export default useStyles;
