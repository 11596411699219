import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import Section from 'core/components/section';
import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import chevronRight from './chevron-right.svg';
import useStyles from './branch-plus-policies.styles';

const BranchPlusPolicies = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    account: {
      branchPlusPolicies: { list }
    }
  } = useStore();

  if (!list.length) {
    return null;
  }

  return (
    <Section title="Branch Plus Policies" type="SubSection">
      <div className={classes.list}>
        {list.map((policy) => (
          <Card key={policy.policyId} type="secondary" className={classes.card}>
            <div className={classes.policyInfo}>
              <Label type="body2" className={classes.value}>
                {policy.carrier.name} {policy.policyType}
              </Label>
              <Label type="body4" className={classes.value}>
                Policy number: {policy.policyId}
              </Label>
            </div>
            <Label
              type="action"
              className={classes.linkToDetails}
              onClick={() => {
                history.push(`/customer/${policy.customer.accountId}/branch-plus/${policy.policyId}`);
              }}
            >
              Go to policy details
              <img src={chevronRight} alt="Orange chevron pointing right" />
            </Label>
          </Card>
        ))}
      </div>
    </Section>
  );
};

export default observer(BranchPlusPolicies);
