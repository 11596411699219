import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2, 8, 6),
    backgroundColor: theme.colors.light_background,
    display: 'flex',
    justifyContent: 'space-between'
  },
  submit: {
    gap: theme.spacing(6),
    marginTop: theme.spacing(2)
  },
  button: {
    width: 168
  },
  closeButton: {
    height: 42
  },
  notificationCardBody: {
    marginLeft: theme.spacing(2)
  }
});

export default makeStyles(styles);
