import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';

import { Label, Loading } from 'core';
import Section from 'core/components/section';
import { Tooltip } from 'core/components/tooltip';
import { NoDataCard } from '../../no-data-card';
import rightArrowIcon from 'core/assets/svg/right-arrow.svg';
import noteIcon from 'core/assets/svg/note.svg';
import { useStore } from 'core/store';
import { communicationType } from '../constants';
import useStyles from './communication-history.styles';

const determineNotesToDisplay = (note) => {
  // The only time we have access to whether the sms or email is outgoing is if SMS or EMAILS is the data source.
  // Otherwise, when the data source is NOTES, we don't know if the sms or email is incoming or outgoing, so filtering those out.
  if (
    (note.dataSource === communicationType.SMS && note.smsIsOutgoing) ||
    (note.dataSource === communicationType.EMAILS && note.emailsIsOutgoing)
  ) {
    return true;
  }
  if (
    note.dataSource === communicationType.NOTES &&
    (note.notesNoteType === communicationType.PHONE_CALL_OUTBOUND ||
      note.notesNoteType === communicationType.PHONE_CALL_INBOUND ||
      note.notesNoteType === communicationType.COMMENT)
  ) {
    return true;
  }
};

const determineNoteIcon = (note) => {
  if (note.dataSource === communicationType.EMAILS || note.dataSource === communicationType.SMS) {
    return <img alt="right arrow icon" src={rightArrowIcon} />;
  }
  if (note.dataSource === communicationType.NOTES) {
    if (note.notesNoteType === communicationType.SMS || note.notesNoteType === communicationType.EMAIL) {
      return <img alt="right arrow icon" src={rightArrowIcon} />;
    }
    if (
      note.notesNoteType === communicationType.COMMENT ||
      note.notesNoteType === communicationType.PHONE_CALL_OUTBOUND ||
      note.notesNoteType === communicationType.PHONE_CALL_INBOUND
    ) {
      return <img alt="note icon" src={noteIcon} />;
    }
  }
};

const determineNoteType = (note) => {
  if (note.dataSource === communicationType.NOTES) {
    switch (note.notesNoteType) {
      case communicationType.COMMENT:
        return 'FIELD NOTE';
      case communicationType.PHONE_CALL_OUTBOUND:
        return 'OUTBOUND CALL NOTE';
      case communicationType.PHONE_CALL_INBOUND:
        return 'INBOUND CALL NOTE';
      default:
        return note.notesNoteType;
    }
  }
  if (note.dataSource === communicationType.EMAILS) {
    return 'EMAIL';
  }
  return note.dataSource;
};

const formatNoteDate = (createdDate) => {
  const date = new Date(createdDate.replace(/{value=/g, '').replace(/}/g, ''));
  const formattedDate = format(date, 'MM/dd/yyyy @ h:mm a ');
  return `${formattedDate} ET`;
};

const truncate = (str, charCount) => (str.length > charCount ? `${str.substring(0, charCount - 3)}...` : str);

const notesRegex = new RegExp('(<([^>]+)>|(&[A-Za-z0-9#]+;))', 'g');

const formatNoteBody = (body) => {
  const formattedBody = body.replaceAll(notesRegex, ' ');
  return formattedBody;
};

const CommunicationHistory = ({ currentClaimExposureDetail }) => {
  const { account } = useStore();
  const classes = useStyles();

  const notesDetail = account?.notesDetail;

  const displayNoteDetails = useCallback(
    (note) => {
      if (
        note.dataSource === communicationType.EMAILS ||
        (note.dataSource === communicationType.NOTES && note.notesNoteType === communicationType.EMAIL)
      ) {
        if (note.emailsTo) {
          const chunks = note.emailsTo.split('@');
          const maskedEmail = `${chunks[0].slice(0, 3)}\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022@${
            chunks[1]
          }`;
          return <Label className={classes.commNote}>Sent to {maskedEmail}</Label>;
        }
        return <Label className={classes.commNote}>Sent to N/A</Label>;
      }
      if (
        note.dataSource === communicationType.SMS ||
        (note.dataSource === communicationType.NOTES && note.notesNoteType === communicationType.SMS)
      ) {
        if (note.smsTo) {
          const phoneNumber = parsePhoneNumber(note.smsTo, 'US');
          const maskedPhone = phoneNumber.isValid()
            ? `\u2022\u2022\u2022-\u2022\u2022\u2022-${phoneNumber.nationalNumber.slice(-4)}`
            : 'N/A';
          return <Label className={classes.commNote}>Sent to {maskedPhone}</Label>;
        }
        return <Label className={classes.commNote}>Sent to N/A</Label>;
      }
      if (
        note.dataSource === communicationType.NOTES &&
        (note.notesNoteType === communicationType.PHONE_CALL_OUTBOUND ||
          note.notesNoteType === communicationType.PHONE_CALL_INBOUND ||
          note.notesNoteType === communicationType.COMMENT)
      ) {
        return (
          <Box className={classes.noteDetail}>
            <Grid xs={12}>
              <p>{truncate(formatNoteBody(note.body), 300)}</p>
            </Grid>
            <Grid container alignItems="flex-end" justifyContent="flex-end">
              {note.body.length > 300 && (
                <Grid item>
                  <Tooltip
                    className={classes.tooltip}
                    text="This information offers a brief summary so you can keep the member updated."
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        );
      }
    },
    [notesDetail]
  );

  const associatedNoteToExposure = useCallback(
    (note) => {
      const foundExposure = currentClaimExposureDetail.find((exposure) => {
        // eslint-disable-next-line no-unused-vars
        const [_, exposureId] = exposure.exposureNumber.split('-');
        return note.exposureId === exposureId;
      });
      return foundExposure?.exposureName || foundExposure?.exposureType;
    },
    [notesDetail]
  );

  if (account.notesDetailLoading) {
    return <Loading />;
  }

  if (notesDetail.length === 0) {
    return (
      <NoDataCard
        message="There are no communications yet."
        subMessage="When they happen, the most recent communications will appear here."
        icon={false}
      />
    );
  }

  return (
    <Section title="Recent Outbound Communication & Notes History">
      {notesDetail
        .slice()
        .sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        })
        .map((note) => (
          <>
            {determineNotesToDisplay(note) && (
              <Box className={classes.noteContainer}>
                <span className={classes.noteTitle}>
                  <span className={classes.noteSpacing}>{determineNoteIcon(note)}</span>{' '}
                  <span className={classes.noteSpacing} style={{ fontWeight: 500 }}>
                    {determineNoteType(note)}
                  </span>{' '}
                  &#x2022;
                  <span className={classes.noteSpacing} style={{ fontWeight: 500 }}>
                    {associatedNoteToExposure(note)}
                  </span>{' '}
                  &#x2022;
                  <span className={classes.noteSpacing}>{note.userName || 'N/A'}</span> &#x2022;
                  <span className={classes.noteSpacing}>{formatNoteDate(note.createdAt)}</span>
                </span>
                {displayNoteDetails(note)}
              </Box>
            )}
          </>
        ))}
    </Section>
  );
};

CommunicationHistory.propTypes = {
  currentClaimExposureDetail: PropTypes.array.isRequired
};

export default observer(CommunicationHistory);
