/* eslint-disable func-names */
import * as Yup from 'yup';
import { getIn, validateYupSchema, yupToFormErrors } from 'formik';
import { policyType } from '@ourbranch/lookups';
import { validUSState, requiredString, validHomePurchaseDate } from 'common/helpers/yup-helpers';
import { isValidVin } from 'core/helpers/vin-validator';
import { driversLicenseNumberSchema } from 'core/helpers/drivers-license-number-validations';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);
Yup.addMethod(Yup.mixed, 'validHomePurchaseDate', validHomePurchaseDate);

const shouldValidate = (values, targetKeys, prefix = '') => {
  if (!targetKeys || !targetKeys.length) {
    return false;
  }
  return targetKeys.find((key) => getIn(values, `${prefix}${key}`));
};

const validationSchema = (_errorCodes, values, allowedStates) => {
  const base = Yup.object().shape({
    firstName: Yup.string().requiredString('First name is required'),
    lastName: Yup.string().requiredString('Last name is required'),
    address: Yup.string().requiredString('Address is required'),
    state: Yup.string()
      .requiredString('State is required')
      .validUSState('state')
      .test('is-licensed-in-state', 'You are not licensed in this state', (val) => {
        return allowedStates.includes(val);
      }),
    zip: Yup.string().requiredString('Zip is required'),
    city: Yup.string().requiredString('City is required'),
    unit: Yup.string()
      .nullable()
      .test('required-in-condo', `Condo's apartment or unit number is required`, (value) => {
        const hasCondo = [policyType.Condo, policyType.CABundle].includes(values.policyType);
        if (hasCondo && !values.noUnitNumber) {
          return !!value;
        }
        return true;
      }),
    leadSource: Yup.string().nullable(),
    fcraDisclaimer: Yup.boolean().oneOf([true], 'Must agree to FCRA disclaimer'),
    driversLicenseNumber: driversLicenseNumberSchema.nullable(),
    driversLicenseState: Yup.string().validUSState('driversLicenseState'),
    affinity: Yup.string().nullable(),
    phone: Yup.string().min(10, 'Must be at least 10 digits').nullable(),
    policyType: Yup.string().requiredString('Policy type is required').nullable(),
    additionalPhoneNumbers: Yup.array()
      .of(
        Yup.object().shape({
          phoneNumber: Yup.string()
            .required('Required or click trash to remove')
            .min(10, 'Must be at least 10 digits')
            .test(
              'check-for-primary-phone',
              'You cannot add a secondary phone without a primary phone',
              (secondaryPhone) => {
                return (secondaryPhone && secondaryPhone.length > 0 && values.phone?.length > 0) || !secondaryPhone;
              }
            ),
          note: Yup.string().max(50, 'Must be less than 50 characters').nullable()
        })
      )
      .nullable(),
    communicationConsent: Yup.boolean().oneOf([true], 'Must agree to communications disclaimer')
  });
  let final = base;

  // if any values in the priorAddress section are filled in, validate for all values in that section
  const priorAddress = shouldValidate(values, Object.keys(values.priorAddress || []), 'priorAddress.');
  const addingCar = values.VIN;
  const isNewConstruction = values.isNewConstruction;
  const isNewPurchase = values.isNewPurchase;

  if (addingCar) {
    final = final.concat(
      Yup.object().shape({
        VIN: Yup.string()
          .ensure()
          .test('is-valid-vin', 'Please enter a valid car VIN', (val) => isValidVin(val))
      })
    );
  }

  if (priorAddress) {
    final = final.concat(
      Yup.object().shape({
        priorAddress: Yup.object().shape({
          address: Yup.string().requiredString('Address is required'),
          state: Yup.string().requiredString('State is Required').validUSState('priorAddress.state'),
          zip: Yup.string().requiredString('Zip is required'),
          city: Yup.string().requiredString('City is required')
        })
      })
    );
  }

  // validation required if house is new construction
  if (isNewConstruction) {
    final = final.concat(
      Yup.object().shape({
        priorAddress: Yup.object().shape({
          address: Yup.string().requiredString('Address is required'),
          state: Yup.string().requiredString('State is Required').validUSState('priorAddress.newConstruction.state'),
          zip: Yup.string().requiredString('Zip is required'),
          city: Yup.string().requiredString('City is required')
        })
      })
    );
    if (values.policyType.includes('H')) {
      final = final.concat(
        Yup.object().shape({
          home: Yup.object().shape({
            basementSqFt: Yup.string().requiredString('Square footage of basement is required'),
            basementType: Yup.string().requiredString('Basement type is required'),
            constructionType: Yup.string().requiredString('Construction type is required'),
            exteriorWallType: Yup.string().requiredString('Exterior wall type is required'),
            garageCarCapacity: Yup.string().requiredString('Garage car capacity is required'),
            garageType: Yup.string().requiredString('Garage type is required'),
            homeQuality: Yup.string().requiredString('Home quality is required'),
            numFamiliesInDwelling: Yup.string().requiredString('Number of families in the dwelling is required'),
            numFireplaces: Yup.string().requiredString('Number of fireplaces is required'),
            numFullBathrooms: Yup.string().requiredString('Number of full bathrooms is required'),
            numHalfBathrooms: Yup.string().requiredString('Number of half bathrooms is required'),
            numStories: Yup.string().requiredString('Number of stories is required'),
            numWoodBurningStoves: Yup.string().requiredString('Number of wood burning stoves is required'),
            purchaseDate: Yup.string().validHomePurchaseDate('home.purchaseDate'),
            roofOriginal: Yup.string().requiredString('Roof originality is required'),
            roofShape: Yup.string().requiredString('Roof shape is required'),
            roofType: Yup.string().requiredString('Roof type is required'),
            roofYear: Yup.string().requiredString('Roof year is required'),
            sqFt: Yup.string().requiredString('Square footage is required'),
            typeOfHome: Yup.string().requiredString('Type of home is required'),
            yearBuilt: Yup.string().requiredString('Build year is required')
          })
        })
      );
    } else if (values.policyType.includes('C')) {
      final = final.concat(
        Yup.object().shape({
          condo: Yup.object().shape({
            constructionType: Yup.string().requiredString('Construction type is required'),
            condoQuality: Yup.string().requiredString('Condo quality is required'),
            numFullBathrooms: Yup.string().requiredString('Number of full bathrooms is required'),
            numHalfBathrooms: Yup.string().requiredString('Number of half bathrooms is required'),
            purchaseDate: Yup.string().validHomePurchaseDate('condo.purchaseDate'),
            roofShape: Yup.string().requiredString('Roof shape is required'),
            sqFt: Yup.string().requiredString('Square footage is required'),
            typeOfCondo: Yup.string().requiredString('Type of condo is required'),
            yearBuilt: Yup.string().requiredString('Build year is required'),
            monthsRentedOut: Yup.number().required('Months rented out is required')
          })
        })
      );
    }
  }
  // validation required if house is new Purchase
  if (isNewPurchase) {
    final = final.concat(
      Yup.object().shape({
        priorAddress: Yup.object().shape({
          address: Yup.string().requiredString('Address is required'),
          state: Yup.string().requiredString('State is Required').validUSState('priorAddress.newConstruction.state'),
          zip: Yup.string().requiredString('Zip is required'),
          city: Yup.string().requiredString('City is required')
        }),
        home: Yup.object().shape({
          purchaseDate: Yup.string().validHomePurchaseDate('home.purchaseDate', false)
        }),
        condo: Yup.object().shape({
          purchaseDate: Yup.string().validHomePurchaseDate('condo.purchaseDate', false)
        })
      })
    );
  }
  return final;
};

export const validateQuoteForm = (errorCodes, values, allowedStates, isAgency, affinityLookups) => {
  return validateYupSchema(values, validationSchema(errorCodes, values, allowedStates, isAgency, affinityLookups))
    .then(() => {
      return null;
    })
    .catch((e) => {
      if (e.name && e.name === 'ValidationError') {
        throw yupToFormErrors(e);
      }
      throw e;
    });
};
