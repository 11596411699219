import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import { createStore, StoreProvider } from '../store';
import { apolloClient } from '../apollo-client';
import { StaffSpeedDial } from './speed-dial';
import Auth from './auth';
import ErrorBoundary from './error-boundary';
import Layout from './layout/layout';
import Routes from './routes';
import ThemeProvider from './theme';
import { colors, fonts } from './theme/variables';
import { Toast, ToastProvider } from './toast';

const rootStyles = {
  '@global': {
    '*': {
      fontFamily: fonts.LibreFranklin
    },
    body: {
      backgroundColor: colors.page__background_primary
    },
    ':focus': {
      outline: 'none'
    },
    '::-moz-focus-inner': {
      border: 0
    },
    a: {
      textDecoration: 'none',
      display: 'contents' // https://drafts.csswg.org/css-display/#valdef-display-contents
    }
  }
};

const store = createStore(apolloClient);
const App = () => {
  return (
    <ThemeProvider>
      <StoreProvider store={store}>
        <ToastProvider>
          <Auth client={apolloClient}>
            <ApolloProvider client={apolloClient}>
              <CssBaseline />
              <ErrorBoundary>
                <Router basename="/">
                  <Layout>
                    <Routes />
                  </Layout>
                  <StaffSpeedDial />
                </Router>
              </ErrorBoundary>
              <Toast />
            </ApolloProvider>
          </Auth>
        </ToastProvider>
      </StoreProvider>
    </ThemeProvider>
  );
};

export default withStyles(rootStyles)(App);
