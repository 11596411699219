import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Section from 'core/components/section';
import { Label } from 'core/components/label';
import useStyles from './inner-section.styles';

const InnerSection = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Section className={classes.section}>
      {title && (
        <Label type="infoSubtitle" className={classes.title}>
          {title}
        </Label>
      )}
      <Grid container spacing={4}>
        {children}
      </Grid>
    </Section>
  );
};

InnerSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

InnerSection.defaultProps = {
  title: null,
  children: null
};

export default InnerSection;
