import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  warning: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600
  },
  subText: {
    fontSize: 14,
    lineHeight: 1.5
  },
  button: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colorPalette.white_10,
    fontSize: 16
  }
});

export default makeStyles(styles);
