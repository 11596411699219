import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { policyType as PolicyType } from '@ourbranch/lookups';

import Label from 'core/components/label/label';
import { Button } from 'core/components/button';
import { track } from 'core/helpers/analytics';
import AlertIconWhite from 'core/assets/svg/alert-white.svg';
import LaunchIconWhite from 'core/assets/svg/launch-white.svg';
import SlashIcon from './slash.svg';
import useStyles from './individual-quinstreet-offer.styles';

const numberDisplay = (number) =>
  Number.isInteger(number)
    ? number.toLocaleString('en-US')
    : number.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const IndividualQuinstreetOffer = ({
  quinstreetOffer,
  carrierData,
  policyType,
  webUserId,
  policyId,
  offerId,
  repUsername
}) => {
  const classes = useStyles();
  const { carrierName, status, bridgeUrl, monthly, premium } = quinstreetOffer;
  const showMonthly = monthly && monthly !== premium;

  const handleGoToCarrier = useCallback(() => {
    track('Go to Branch Plus Carrier Website', {
      webUserId,
      policyId,
      offerId,
      policyType,
      repUsername,
      carrier: carrierName
    });

    window.open(status === 'error' || !bridgeUrl ? carrierData[carrierName.toLowerCase()].url : bridgeUrl, '_blank');
  }, [webUserId, policyId, offerId, policyType, repUsername, carrierName, status, bridgeUrl, carrierData]);

  return (
    <div className={classes.container}>
      <div className={classes.firstContainer}>
        <img src={carrierData[carrierName.toLowerCase()].logo} className={classes.icon} alt="Banner Icon" />

        <Button variant="contained" color="secondary" className={classes.button} onClick={handleGoToCarrier}>
          <img src={LaunchIconWhite} className={classes.buttonIcon} alt="Launch Icon" />
          Go to {carrierName}
        </Button>
      </div>
      {status === 'success' ? (
        <>
          <Divider className={classes.divider} />
          <div className={classes.pricesContainer}>
            {showMonthly && (
              <>
                <div className={classes.priceContent}>
                  <Label type="infoValue" className={classes.beige}>
                    $
                  </Label>
                  <Label type="h1" className={classes.beige}>
                    {numberDisplay(monthly)}
                  </Label>
                  <div>
                    <Label type="status" className={classes.beige}>
                      PAID MONTHLY
                    </Label>
                    <Label type="noteSmall" className={classes.beige}>
                      /6 MOS
                    </Label>
                  </div>
                </div>
                <img src={SlashIcon} alt="Slash" />
              </>
            )}
            <div className={classes.priceContent}>
              <Label type="infoValue" className={classes.beige}>
                $
              </Label>
              <Label type="h1" className={classes.premiumPrice}>
                {numberDisplay(premium)}
              </Label>
              <div>
                <Label type="status" className={classes.beige}>
                  PAID IN FULL
                </Label>
                <Label type="noteSmall" className={classes.beige}>
                  /{policyType === PolicyType.Home ? '12' : '6'} MOS
                </Label>
              </div>
            </div>
          </div>
        </>
      ) : (
        status === 'error' && (
          <div className={classes.errorContainer}>
            <img src={AlertIconWhite} className={classes.icon} alt="Banner Icon" />
            <Label>
              We could not generate a quote for this carrier. Please try directly from the carriers website via the
              link.
            </Label>
          </div>
        )
      )}
    </div>
  );
};

IndividualQuinstreetOffer.propTypes = {
  quinstreetOffer: PropTypes.object.isRequired,
  carrierData: PropTypes.object.isRequired,
  policyType: PropTypes.string.isRequired,
  webUserId: PropTypes.string.isRequired,
  repUsername: PropTypes.string.isRequired,
  policyId: PropTypes.string,
  offerId: PropTypes.string
};

export default IndividualQuinstreetOffer;
