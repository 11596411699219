import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Dialog, DialogTitle, Grid, DialogContent } from '@material-ui/core';
import Lottie from 'react-lottie';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { useQueryParams } from 'core/hooks/useQueryParams';
import { track } from 'core/helpers/analytics';
import branchSpinnerJson from 'core/assets/lottie/branch-spinner.min.json';
import OfferBlock from './offer-block';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';
import { Button } from 'core/components/button';
import { useStore } from 'core/store/';
import closeIcon from 'core/assets/svg/x.svg';
import checkmarkIcon from 'core/assets/svg/ic-checkmark.svg';
import alertIcon from 'core/assets/svg/ic-info-dark.svg';

import { GET_OFFER, MERGE_QUOTES_AND_RECALCULATE } from 'core/store/offer-store/offer-queries.js';

import useStyles from './comparative-rater-modal.styles';

const ComparativeRaterModal = ({ open, onClose }) => {
  const queryParams = useQueryParams();
  const type = queryParams.type?.toUpperCase();
  const rater = queryParams.rater?.toUpperCase();
  const classes = useStyles();
  const history = useHistory();
  const {
    offer: { offer },
    search: { searchForOffersWithWebUserId }
  } = useStore();

  const [loading, setLoading] = useState(true);

  const [getOffer] = useLazyQuery(GET_OFFER);
  const [mergeQuotesAndRecalculate, { data: mergedOffer }] = useLazyQuery(MERGE_QUOTES_AND_RECALCULATE);

  const branchBundle = offer?.options.find((option) => option.name === 'Bundle');
  const raterBundle = mergedOffer?.mergeQuotesAndRecalculate?.options.find((option) => option.name === 'Bundle');

  useEffect(() => {
    const mergeOffers = async () => {
      if (offer?.webUserId) {
        // get all offers related to this webUserId
        const hits = await searchForOffersWithWebUserId(offer.webUserId);

        if (!hits || !hits.length > 1) {
          track('Comparative rater offer bridge - no options', {
            isBundle: !!branchBundle,
            merged: false,
            rater,
            bridgedFrom: type,
            offerId: offer.id,
            exitReason: 'No other offers found.'
          });
          onClose();
          return;
        }
        // find the offer with the same id as our current offer, then grab its timestamp
        const currentTimestamp = hits?.find((hit) => hit.id === offer.id)?.updatedDateTime;
        // if we can get its timestamp, filter the offers to only show ones that are within +- 2 mins of our current offer and are not the current offer
        const currentDate = new Date(currentTimestamp).getTime();
        const startTime = currentDate - 5 * 60 * 1000; // 5 mins in ms
        const endTime = currentDate + 5 * 60 * 1000; // 5 mins in ms

        const relevantOffers = hits.filter((hit) => {
          const thisOfferTimestamp = new Date(hit.updatedDateTime).getTime();
          return thisOfferTimestamp >= startTime && thisOfferTimestamp <= endTime && hit.id !== offer.id;
        });

        //  if there are no other offers for this user, close the modal and proceed to the original offer
        if (!relevantOffers.length) {
          track('Comparative rater offer bridge - no options', {
            isBundle: !!branchBundle,
            merged: false,
            rater,
            bridgedFrom: type,
            offerId: offer.id,
            exitReason: 'No offers within time param.'
          });
          onClose();
          return;
        }

        // if there is a relevant offer, we need to grab it, get all the data, and make the NEW rater + rater offer
        if (relevantOffers[0]) {
          const offersToMerge = [];
          // depending on if we landed on a home or auto offer, we need to merge the opposite offer
          // grab the option (currently being displayed) from the current offer
          const thisOffer = offer.options.find((option) => option.type === type);
          offersToMerge.push({ offerId: offer.id, optionId: thisOffer.id });

          // then grab the other option from the offerToMerge
          const { data: offerToMerge } = await getOffer({ variables: { offerId: relevantOffers[0].id } });
          if (!offerToMerge?.offer) {
            // if for some reason we fail to get the data for the offer we need to merge in, close the modal and proceed to the offer
            track('Comparative rater offer bridge - no options', {
              isBundle: !!branchBundle,
              merged: false,
              rater,
              bridgedFrom: type,
              offerId: offer.id,
              exitReason: 'Failed to get paired offer from dynamo.'
            });
            onClose();
            return;
          }
          const typeToMerge = type === 'H' ? 'A' : 'H';
          const mergeOption = offerToMerge.offer.options.find((option) => option.type === typeToMerge);
          if (!mergeOption) {
            // if the opposite option type doesn't exist on the offerToMerge (aka two auto offers or two home offers)
            // close the modal and proceed to the original offer
            track('Comparative rater offer bridge - no options', {
              isBundle: !!branchBundle,
              merged: false,
              rater,
              bridgedFrom: type,
              offerId: offer.id,
              exitReason: 'Paired offer does not have opposite option.'
            });
            onClose();
            return;
          }
          offersToMerge.push({ offerId: offerToMerge?.offer.id, optionId: mergeOption.id });

          // now call the graphql query to merge the offers and get the merged offer
          const { data } = await mergeQuotesAndRecalculate({ variables: { offersToMerge } });
          if (!data?.mergeQuotesAndRecalculate) {
            // if for some reason we fail to merge the offers, close the modal and proceed to the offer
            track('Comparative rater offer bridge - no options', {
              isBundle: !!branchBundle,
              merged: false,
              rater,
              bridgedFrom: type,
              offerId: offer.id,
              exitReason: 'Merge failed.'
            });
            onClose();
            return;
          }

          // at this point we have two offers, the original offer and the merged offer
          // we need to check and see which of them have bundle options
          const mergedBranchBundle = data.mergeQuotesAndRecalculate.options.find((option) => option.name === 'Bundle');
          // regardless of whether we have a branch bundle or not, if there is no merged bundle, close the modal and proceed to the original offer
          if (!mergedBranchBundle) {
            track('Comparative rater offer bridge - no options', {
              isBundle: !!branchBundle,
              merged: false,
              rater,
              bridgedFrom: type,
              offerId: offer.id,
              exitReason: 'Merged offer has no bundle.'
            });
            onClose();
            return;
          }
          // the branch offer does NOT have a bundle, but the merged offer does, so skip the modal and go straight to the merged offer
          if (!branchBundle && !!mergedBranchBundle) {
            track('Comparative rater offer bridge - no options', {
              isBundle: !!mergedBranchBundle,
              merged: true,
              rater,
              bridgedFrom: type,
              offerId: offer.id,
              exitReason: 'Bridged offer has no bundle.'
            });
            history.push(`/offer/${data.mergeQuotesAndRecalculate.id}`);
            onClose();
            history.go(0);
          }
          // if BOTH offers have a bundle option, show the modal
          if (!!branchBundle && !!mergedBranchBundle) {
            track('Comparative rater offer bridge - options presented', {
              rater,
              bridgedFrom: type,
              offerId: offer.id
            });
          }
        }

        setLoading(false);
      }
    };

    mergeOffers();
  }, [offer]);

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} classes={{ paperScrollPaper: classes.loading }}>
        <div className={classes.spinnerContainer}>
          <div className={classes.spinner}>
            <Lottie options={{ animationData: branchSpinnerJson, loop: true, autoplay: true }} />
          </div>
          <div className={classes.spinnerText}>Gathering offer details...</div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paperScrollPaper: classes.paperScrollPaper
      }}
      scroll="paper"
    >
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <Grid container className={classes.container}>
        <DialogTitle>
          <Label className={classes.title}>
            Which {type === 'H' ? 'auto' : 'home'} offer do you want to bundle with your
            {type === 'H' ? ' home' : ' auto'}?
          </Label>
        </DialogTitle>
        <div className={classes.subtitle}>
          These two offers have different sources of data for the {type === 'H' ? 'auto' : 'home'} offer.
        </div>

        <DialogContent>
          <Grid container className={classes.bundleRow}>
            {/* rater + rater bundle */}
            <Grid item className={classes.bundle}>
              <div className={classes.bundleTitle}>{type === 'H' ? 'Auto' : 'Home'} with the data you entered</div>
              <div className={classes.bundleSubtitle}>This offer uses data manually entered from the rater</div>
              <OfferBlock raterIcon={rater} type={type} bundle={raterBundle} isBranch={false} />
              <Button
                loading={loading}
                className={classes.submitButton}
                onClick={() => {
                  track('Comparative rater offer selected', {
                    offerType: 'Rater',
                    totalPriceRater: raterBundle.homeTotal + raterBundle.autoTotal,
                    totalPriceBranch: branchBundle.homeTotal + branchBundle.autoTotal,
                    rater,
                    bridgedFrom: type
                  });
                  history.push(`/offer/${mergedOffer?.mergeQuotesAndRecalculate?.id}`);
                  onClose();
                  history.go(0);
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Review bundle
              </Button>
              <div className={classes.featuresList}>
                <div className={classes.featureHeader}>FEATURES</div>
                <div className={classes.feature}>
                  <img src={checkmarkIcon} className={classes.checkmark} alt="checkmark" />
                  Quick and easy bundle checkout experience
                </div>
                <div className={classes.divider} />
                <div className={classes.feature}>
                  <img src={checkmarkIcon} className={classes.checkmark} alt="checkmark" />
                  Manually entered vehicles, drivers, and coverages from the rater
                </div>
              </div>
            </Grid>

            {/* rater + branch bundle */}
            <Grid item className={classes.bundle}>
              <div className={classes.bundleTitle}>{type === 'H' ? 'Auto' : 'Home'} with carrier data sources</div>
              <div className={classes.bundleSubtitle}>
                This offer uses Branch's trusted data sources to help you get an accurate rate
              </div>
              <OfferBlock raterIcon={rater} type={type} bundle={branchBundle} isBranch />
              <Button
                loading={loading}
                className={classes.submitButton}
                onClick={() => {
                  track('Comparative rater offer selected', {
                    offerType: 'Branch',
                    totalPriceRater: raterBundle.homeTotal + raterBundle.autoTotal,
                    totalPriceBranch: branchBundle.homeTotal + branchBundle.autoTotal,
                    rater,
                    bridgedFrom: type
                  });
                  onClose();
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Review bundle
              </Button>

              <div className={classes.featuresList}>
                <div className={classes.featureHeader}>FEATURES</div>
                <div className={classes.feature}>
                  <img src={checkmarkIcon} className={classes.checkmark} alt="checkmark" />
                  Quick and easy bundle checkout experience
                </div>
                <div className={classes.divider} />
                {type === 'H' && (
                  <>
                    <div className={classes.feature}>
                      <img src={checkmarkIcon} className={classes.checkmark} alt="checkmark" />
                      Branch's trusted data sources pulled vehicles and drivers
                    </div>
                    <div className={classes.feature}>
                      <img src={checkmarkIcon} className={classes.checkmark} alt="checkmark" />
                      Auto coverages applied from prior carrier information
                    </div>
                  </>
                )}
                {type === 'A' && (
                  <div className={classes.feature}>
                    <img src={checkmarkIcon} className={classes.checkmark} alt="checkmark" />
                    Branch's trusted data sources pulled home details
                  </div>
                )}
              </div>
            </Grid>
          </Grid>

          {/* row */}
          <Grid container className={classes.disclaimer}>
            <img src={alertIcon} className={classes.alertIcon} alt="checkmark" />
            <div className={classes.disclaimerColumn}>
              <div className={classes.bold}>
                Want a monoline offer instead? If eligible, you'll be able to change your policy type after selecting a
                bundle.
              </div>
              <div>
                Monoline offer prices are subject to monoline rates, which are different than bundle rates shown above.
              </div>
            </div>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default observer(ComparativeRaterModal);
