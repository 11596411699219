import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  notification: {
    margin: 0,
    boxShadow: 'none',
    textAlign: 'start'
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: 'auto'
  },
  action: {
    fontSize: 16,
    fontWeight: 600,
    minWidth: 275,
    maxWidth: 315,
    padding: theme.spacing(0, 4),
    width: 'fit-content'
  },
  buttonContent: {
    display: 'flex',
    gap: theme.spacing(0.5),
    overflow: 'hidden',
    width: '100%'
  },
  email: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

export default makeStyles(styles);
