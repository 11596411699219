import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { reaction } from 'mobx';

import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import { useToast } from 'core/components/toast';

export const useCustomerProvider = ({ accountId, policyId, branchPlusPolicyId }) => {
  const store = useStore();
  const { account } = store;
  const history = useHistory();
  const toast = useToast();
  const { isAgency } = useSession();
  /* @TODO I think there is a better way to do this with fragments that allow us to decouple the querying behavior with the component rendering.
  This could be especially useful and efficient for us because the queries could be constructed dynamically, based on which components are in the tree
  You could also add new data to queries simply by updating the fragment on an existing component.
  */

  // accountId changes, clear everything stored
  useEffect(() => {
    if (account.id && account.id !== accountId) {
      store.resetStore();
    }
  }, [store, account.id, accountId]);

  useEffect(() => {
    if (policyId || (!account.accountLoading && policyId && policyId !== account.policies.policy.policy?.id)) {
      // no account, no policy, on policy page
      account.fetchFullAccountAndPolicy(policyId, accountId, history, isAgency);
    } else if (
      branchPlusPolicyId ||
      (!account.accountLoading && branchPlusPolicyId && branchPlusPolicyId !== account.branchPlusPolicy.id)
    ) {
      // get branch plus policy
      account.fetchFullAccountAndBranchPlusPolicy(branchPlusPolicyId, accountId, history, isAgency);
    } else {
      // no account, on account summary page
      account.fetchFullAccount(accountId, history, isAgency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, policyId, account]);

  // this reaction tracks the loading state, and when it changes (true/false), checks if the account id was set correctly, if it was not both account and policy are not set in the store
  // essentially handling cases were access to an account/policy is unauthorized
  // or the account/policy fails to load/set in the store for any reason
  reaction(
    () => account.accountLoading,
    (accountLoading) => {
      if (!accountLoading && !account.id) {
        toast.notify({
          type: 'error',
          message: `An Error was encountered. Error: ${account.errors[0]?.message}. Please contact ${
            isAgency ? 'Agency Support' : 'the Help Desk'
          }.`,
          durationInSeconds: 10
        });
        setTimeout(() => {
          history.push('/search/offers');
        }, 7000);
      }
    }
  );
};
