import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';

import emailIcon from 'core/assets/svg/email.svg';
import { Card } from 'core/components/card';
import { useToast } from 'core/components/toast';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import useStyles from './application-links.styles';

const ApplicationLinks = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const store = useStore();
  const accountId = store.account.id;
  const { unsigned } = store.account.policies;
  const { isAgency } = useSession();

  const sendApplicationUrl = useCallback(() => {
    setLoading(true);
    store.account.policies
      .sendApplicationLink(accountId, unsigned.policyId)
      .then(({ data }) => {
        setLoading(false);
        if (data && data.sendApplicationLink) {
          store.account.policies.getDocuments(accountId, isAgency);
          toast.notify({
            type: 'success',
            message: `Email was sent to ${data.sendApplicationLink.email}`
          });
        } else {
          throw new Error('Error sending the application url email');
        }
      })
      .catch((e) => {
        toast.notify({
          type: 'error',
          message: 'Oops! we couldn’t send the email. Please try again.'
        });
        setLoading(false);
      });
  }, [setLoading, unsigned, toast, store.account.policies, accountId]);

  const copyToClipboard = useCallback(() => {
    const copied = copy(unsigned.applicationUrl);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'Quote’s link is copied to your clipboard.'
      });
    } else {
      toast.notify({
        type: 'error',
        message: `We could not copy the link, but here it is: ${unsigned.applicationUrl}`
      });
    }
  }, [unsigned, toast]);

  if (!unsigned) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <span>Application Links</span>
      <div className={classes.buttons}>
        <Button
          disabled={loading}
          disableFocusRipple
          className={classes.sendButton}
          onClick={sendApplicationUrl}
          variant="text"
          color="secondary"
        >
          <img
            alt="email icon"
            className={classNames(classes.sendIcon, { [classes.disabledIcon]: loading })}
            src={emailIcon}
          />
          Send link to member
        </Button>
        <Button
          disabled={loading}
          disableFocusRipple
          className={classes.sendButton}
          onClick={copyToClipboard}
          variant="text"
          color="secondary"
        >
          Copy to clipboard
        </Button>
      </div>
    </Card>
  );
};

export default observer(ApplicationLinks);
