import { makeStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  container: {
    borderTop: `1px solid ${theme.colorPalette.grey_10_op_38}`,
    paddingBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.colorPalette.orange_10_op_10
    }
  },
  rowTitle: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(4),
    fontWeight: 600
  },
  rowDescription: {
    marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    color: theme.colorPalette.grey_90
  },
  category: {
    fontWeight: 500
  }
});

export default makeStyles(useStyles);
