import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  modalHeader: {
    marginBottom: theme.spacing(6),
    fontSize: 40,
    fontWeight: 300
  },
  modalContent: {
    lineHeight: 1.2,
    display: 'block'
  },
  card: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(3),
    backgroundColor: theme.colors.page__background_primary,
    height: 112,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8)
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  cardHeader: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43
  },
  notificationIcon: {
    display: 'block',
    height: 32,
    width: 32
  },
  btn: {
    padding: theme.spacing(1, 2),
    width: 215,
    height: 36,
    position: 'relative',
    float: 'right'
  }
});

export default makeStyles(styles);
