import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    borderRadius: '6px'
  },
  primary: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_primary
  },
  primaryEditable: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_primary_editable
  },
  secondary: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_secondary
  },
  tertiary: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_tertiary
  },
  quatrinary: {
    composes: '$root',
    backgroundColor: theme.colors.info__label_secondary
  },
  modal: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_modal
  },
  highlight: {
    composes: '$root',
    backgroundColor: theme.colors.payment__background_primary
  },
  outline: {
    composes: '$root',
    background: 'transparent',
    border: `2px solid ${theme.colors.card_background_primary}`
  },
  noBorders: {
    borderRadius: 0,
    backgroundColor: theme.colors.card_background_primary,
    borderTop: `1px solid ${theme.colors.card_background_primary_editable}`
  },
  noBordersTop: {
    composes: '$noBorders',
    borderRadius: '0 0 6px 6px'
  },
  scrollable: {
    maxHeight: 630,
    overflowY: 'scroll'
  },
  noData: {
    height: 232,
    display: 'flex'
  },
  refund: {
    composes: '$root',
    backgroundColor: theme.colorPalette.beige_10
  },
  error: {
    border: `1px solid ${theme.colors.error}`
  }
});

export default makeStyles(styles);
