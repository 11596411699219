import React from 'react';
import { Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LensIcon from '@material-ui/icons/Lens';

import { getUserFriendlyStatus } from 'tools/helpers';
import { Label } from 'core/components/label';
import useStyles from '../tool-details.styles';

const getIconAndClassName = (status, classes) => {
  if (status === 'SUCCESS') {
    return { icon: CheckCircleIcon, className: classes.successIcon };
  }
  if (status === 'FAILED') {
    return { icon: CancelIcon, className: classes.failedIcon };
  }

  if (status === 'PARTIAL_SUCCESS') {
    return { icon: LensIcon, className: classes.partialSuccessIcon };
  }

  return {};
};

const OutputRunStatus = ({ output, toolDetail }) => {
  const classes = useStyles();

  const { icon: IconComponent, className: iconClass } = getIconAndClassName(toolDetail?.status, classes);
  const outputError = output?.error;

  return (
    <Grid container alignItems="center">
      {IconComponent && <IconComponent className={iconClass} />}
      <Grid>
        <Label type="body1Bold">{getUserFriendlyStatus(toolDetail?.status)}</Label>
        <Label type="body1">
          Finished at {new Date(toolDetail?.endedAt).toLocaleString()} by {toolDetail?.startedBy}
        </Label>
      </Grid>
      {outputError && <Label type="body1">{outputError}</Label>}
    </Grid>
  );
};

export default OutputRunStatus;
