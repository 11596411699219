import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end'
  }
});

export default makeStyles(styles);
