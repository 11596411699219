import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { mortgageLookups, paymentMethod } from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import RemoveButton from 'core/components/remove-button';
import { setPrimaryMortgageValues, trackCustomMortgageLender } from 'offer/helpers/mortgage-helpers';
import MortgageRadio from './mortgage-radio';
import useStyles from './mortgage-item.styles';

const MORTGAGE_LIST = mortgageLookups?.map((mortgage, index) => ({
  id: index,
  text: mortgage.correctName
}));

const MortgageItem = ({ fieldId: id, fieldName, index, onRemove, item, removeFromList }) => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { home, condo } = values;
  const mortgageDetails = home ? home.mortgageDetails : condo.mortgageDetails;

  const {
    account: {
      policies: { policy: policyStore }
    },
    offer
  } = useStore();

  const { policy } = policyStore;
  const mortgage = mortgageDetails[index];

  useEffect(() => {
    if (mortgage.primary) {
      setPrimaryMortgageValues(setFieldValue, mortgage);
    }
  }, [mortgage, setFieldValue]);

  const handleRemove = useCallback(() => {
    if (mortgage.isCustom) {
      const idToTrack = policy?.id ? { policyId: policy?.id } : { offerId: offer?.offer?.id };
      trackCustomMortgageLender(false, idToTrack, mortgage);
    }
    if (mortgage.primary) {
      setPrimaryMortgageValues(setFieldValue, {
        mortgageHolderAddress: {
          city: '',
          state: '',
          zip: '',
          address: '',
          address2: ''
        },
        mortgageHolderName: '',
        mortgageHolderId: '',
        loanNumber: ''
      });
    }
    onRemove(index, removeFromList);
  }, [mortgage.primary, setFieldValue, onRemove, index, removeFromList]);

  const onRadioChange = useCallback(() => {
    const previousPrimaryIndex = mortgageDetails.findIndex((mgItem) => mgItem.primary);
    if (previousPrimaryIndex >= 0) {
      setFieldValue(`${id}.${previousPrimaryIndex}.primary`, false);
    }

    setFieldValue(`${fieldName}.primary`, true);
    setFieldTouched(id);
  }, [mortgageDetails, setFieldValue, id, fieldName, setFieldTouched, mortgage]);

  const handleSelection = useCallback(
    (_, mortgageLender) => {
      // When user clears the value in the field, mortgageLender will be null
      if (!mortgageLender) {
        return;
      }

      const mortgage = mortgageLookups.find((mgItem) => mgItem.correctName === mortgageLender.text);
      if (mortgage) {
        const { address, address2, city, state, zip, correctName, id: mortgageID } = mortgage;
        setFieldTouched(fieldName, true, false);
        setFieldValue(fieldName, {
          ...item,
          mortgageHolderAddress: {
            address,
            address2,
            city,
            state,
            zip
          },
          mortgageHolderName: correctName,
          id: mortgageID || mortgageLender.id
        });
      }
    },
    [setFieldTouched, id, setFieldValue, fieldName, item]
  );

  const preventRemoveMortgage = useMemo(() => {
    // can't remove mortgage if payment type is mortgage with only one mortgage
    return (
      policy?.paymentMethod === paymentMethod.Escrow && mortgageDetails[index].primary && mortgageDetails.length <= 1
    );
  }, [policy?.paymentMethod, mortgageDetails, index]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.identifier}>
          <Label type="darkSmall">MORTGAGE #{mortgageDetails[index].loanNumber}</Label>
          {preventRemoveMortgage && (
            <Label type="infoLabel">Change the payment method, or add a mortgage to eliminate this mortgage.</Label>
          )}
        </div>
        <div>
          <MortgageRadio checked={mortgageDetails[index].primary || false} onChange={onRadioChange} classes={classes} />
          <RemoveButton
            mode="big"
            actionType="button"
            className={classes.button}
            disabled={preventRemoveMortgage}
            onClick={handleRemove}
          />
        </div>
      </div>
      <Grid container spacing={4}>
        <FormField
          type="mortgageLenderName"
          mode="light"
          name={`${fieldName}.mortgageHolderName`}
          id={`${fieldName}.mortgageHolderName`}
          options={MORTGAGE_LIST}
          onSelection={handleSelection}
          label="Lender"
          xs={12}
          fast={false}
          permissions={{ isLicensedAction: false }}
          showChevronIcon
          showDeleteIcon
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.address`}
          id={`${fieldName}.mortgageHolderAddress.address`}
          type="string"
          label="Lender Address"
          xs={8}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.address2`}
          id={`${fieldName}.mortgageHolderAddress.address2`}
          type="string"
          label="Address 2"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.city`}
          id={`${fieldName}.mortgageHolderAddress.city`}
          type="string"
          label="City"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.state`}
          id={`${fieldName}.mortgageHolderAddress.state`}
          type="state"
          label="State"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          mode="light"
          name={`${fieldName}.mortgageHolderAddress.zip`}
          id={`${fieldName}.mortgageHolderAddress.zip`}
          type="string"
          label="Zip code"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          name={`${fieldName}.loanNumber`}
          id={`${fieldName}.loanNumber`}
          type="string"
          label="Loan Number"
          mode="light"
          xs={12}
          permissions={{ isLicensedAction: false }}
        />
      </Grid>
    </div>
  );
};

MortgageItem.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  removeFromList: PropTypes.func.isRequired
};

export default observer(MortgageItem);
