import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { ProtectedRoute } from 'core/components/protected-route';
import { flatMap } from 'lodash-es';

import useSession from 'core/hooks/use-session';
import { ToolsList } from './tools-list';
import { ToolDetail } from './tool-detail';
import { tools } from './tools-list/constants';

const Tools = () => {
  const session = useSession();
  const { path } = useRouteMatch();

  const toolsDetails = flatMap(tools.map((tool) => tool.items));

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <ProtectedRoute path={`${path}`} exact component={ToolsList} permission={session.canViewTools} />
        {toolsDetails.map((tool) => {
          return (
            <ProtectedRoute
              key={tool.path}
              path={tool.path}
              exact
              component={tool.component}
              permission={session[tool.permissionName]}
            />
          );
        })}
        {toolsDetails.map((tool) => {
          return (
            <ProtectedRoute
              key={`${tool.path}/run/:taskYYMM/:taskRunId`}
              path={`${tool.path}/run/:taskYYMM/:taskRunId`}
              exact
              permission={session[tool.permissionName]}
            >
              <ToolDetail toolConfig={tool} />
            </ProtectedRoute>
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Tools;
