import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(8, 0, 12, 0)
  },
  card: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
    marginBottom: 0
  },
  output: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(4)
  },
  submit: {
    marginTop: theme.spacing(4),
    minWidth: 150
  },
  outputHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  pagination: {
    color: theme.colorPalette.orange_10
  },
  outputTable: {
    paddingBottom: theme.spacing(6)
  },
  icon: {
    height: 38,
    width: 38,
    transform: 'rotate(180deg)'
  },
  chatIcon: {
    color: theme.colorPalette.grey_10_op_38,
    marginRight: theme.spacing(2)
  },
  outputTitle: {
    fontWeight: 600
  },
  liveMessage: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 5px ${theme.colorPalette.grey_10_op_38}`
  }
}));

export default useStyles;
