import React from 'react';
import { observer } from 'mobx-react';

import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store/store.mobx';
import Driver from 'common/components/people/driver';
import DriverActions from 'common/components/people/driver-actions';

const PolicyDriver = (props) => {
  const { item } = props;
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const session = useSession();

  const isBix = policyStore.isBix;
  const state = policyStore.geographicState;

  const driverSavedOrToggledExcluded =
    policyStore?.segment?.drivers.find((d) => d.id === item.id)?.excludeDriver ?? item.excludeDriver;
  // never allow toggling off an exclusion for a driver on policies
  // https://github.com/gobranch/branch/issues/14526
  const hasPermissionToExcludeDriver = session.canChangeExclusions && !driverSavedOrToggledExcluded;

  return (
    <Driver
      {...props}
      state={state}
      isBix={isBix}
      actions={
        <DriverActions
          driver={item}
          formikFieldPath={props.fieldName}
          state={state}
          hasPermissionToExcludeDriver={hasPermissionToExcludeDriver}
          {...props}
        />
      }
    />
  );
};

export default observer(PolicyDriver);
