import React from 'react';

import { Grid } from '@material-ui/core';
import Field from 'core/components/form/form.v2';
import useStyles from './disclaimer.styles';

const disclaimerText =
  'I, as a Branch sales representative, assert that I have disclosed the following to the client on whose behalf I am obtaining this offer: "To provide your accurate price, we\'ll ask for information as well as gather information such as your claims, driving, and credit information, including credit report, from other sources for you and your household. We may also share personal information with service providers who act on our behalf, our carrier partners, or others operating at your direction or where legally necessary or appropriate. "FOR TEXAS RESIDENTS ONLY: "A copy of the Texas Use of Credit Disclosure is available at ourbranch.com"';

const Disclaimer = () => {
  const classes = useStyles();

  return (
    <Grid container item className={classes.checkboxContainer}>
      <Field
        className={classes.checkbox}
        type="checkbox"
        name="fcraDisclaimer"
        id="fcraDisclaimer"
        label={disclaimerText}
        mode="light"
      />
    </Grid>
  );
};

export default Disclaimer;
