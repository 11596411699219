import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 580,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: theme.spacing(6)
  },
  title: {
    color: theme.colorPalette.green_10,
    fontSize: 40,
    lineHeight: '56px',
    fontWeight: 300,
    padding: 0,
    width: '100%'
  },
  text: {
    color: theme.colors.green_15,
    marginTop: 0,
    fontWeight: 400
  },
  content: {
    padding: theme.spacing(0, 6),
    gap: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  detailsContent: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  card: {
    display: 'flex',
    padding: theme.spacing(4),
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(4),
    alignSelf: 'stretch'
  },
  btnContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center'
  },
  btn: {
    padding: theme.spacing(1, 4)
  }
}));

export default useStyles;
