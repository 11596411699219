import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { observer } from 'mobx-react';
import { docIdToFriendlyName } from '@ourbranch/be-constants';
import * as Sentry from '@sentry/react';

import { useStore } from 'core/store';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { Button } from 'core/components/button';
import { Loading } from 'core/components/loading';
import { useToast } from 'core/components/toast/context';
import { copyDocFromInternalToCustomer } from './s3client';
import useStyles from '../../documents.styles';

// Show only BF_A or BF_H options depending on policy selection
const getDocumentIdOptions = (policy) => {
  if (!policy) {
    return Object.keys(docIdToFriendlyName).map((id) => ({ id, value: `${id} - ${docIdToFriendlyName[id]}` }));
  }
  const ids = policy.includes('home')
    ? Object.keys(docIdToFriendlyName).filter((docId) => docId.startsWith('H'))
    : Object.keys(docIdToFriendlyName).filter((docId) => docId.startsWith('A'));
  return ids.map((id) => ({ id, value: `${id} - ${docIdToFriendlyName[id]}` }));
};

const getDateTime = () => {
  const now = new Date();
  const dateTime = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}`;
  return dateTime;
};

const CopyInternalDocToPolicyDocsModal = ({ open, document, getDocName, onClose, refetchDocuments }) => {
  const classes = useStyles();
  const {
    account: {
      id: accountId,
      policies: { list }
    }
  } = useStore();
  const toast = useToast();
  // Initially show all document options (both BF_A and BF_H)
  const [documentOptions, setDocumentOptions] = useState(
    Object.keys(docIdToFriendlyName).map((id) => ({ id, value: `${id} - ${docIdToFriendlyName[id]}` }))
  );
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (policy, docType) => {
      setSubmitting(true);
      const ext = document.path.split('.').pop();
      const customerDocPath = `docs/${accountId}/customer/${policy}`;
      const customerDocName = `${getDateTime()}-${docType}.${ext}`;
      copyDocFromInternalToCustomer({ internalDocPath: document.path, customerDocPath, customerDocName })
        .then((res) => {
          setSubmitting(false);
          if (res && res.success) {
            refetchDocuments();
            onClose();
            toast.notify({
              type: 'success',
              message: 'The internal document was copied successfully to policy documents'
            });
          } else {
            Sentry.captureException(
              JSON.stringify({
                res,
                internalDocPath: document.path,
                customerDocPath,
                customerDocName
              })
            );
            toast.notify({
              type: 'error',
              message: 'There was an error copying the internal document to policy documents'
            });
          }
        })
        .catch((error) => {
          Sentry.captureException(
            JSON.stringify({
              error
            })
          );
          toast.notify({
            type: 'error',
            message: 'There was an error copying the internal document to policy documents'
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    [setSubmitting, refetchDocuments, onClose, toast, accountId, document]
  );

  return (
    <BaseDialog open={open} onClose={onClose} size="lg">
      {submitting || !list.length ? (
        <Loading noBackground />
      ) : (
        <>
          <Label type="titleSecondary" className={classes.modalText}>
            Copy file to policy documents
          </Label>
          <Label type="infoSubtitle" className={classes.modalText}>
            The file <Label type="infoSubtitleBold">{document.path.split('/').pop()}</Label> will be copied into this
            member's policy documents allowing them to view this file.
          </Label>
          <Label type="greenSmall" className={classes.modalText}>
            Select which policy this file belongs to and what type of document it is
          </Label>
          <Formik initialValues={{ policy: null, documentType: null }} enableReinitialize>
            {({ values }) => (
              <Form>
                <Grid container justifyContent="space-around" alignItems="flex-start" spacing={2}>
                  <FormField
                    name="policy"
                    type="select"
                    label="Policy"
                    xs={6}
                    mode="light"
                    options={list.map((policy) => ({ id: policy.id, value: policy.id }))}
                    onChange={(fieldValue) => setDocumentOptions(getDocumentIdOptions(fieldValue))}
                    permissions={{ isLicensedAction: false }}
                  />

                  <FormField
                    name="documentType"
                    type="select"
                    label="Document Type"
                    xs={6}
                    mode="light"
                    options={documentOptions}
                    fast={false}
                    permissions={{ isLicensedAction: false }}
                  />
                </Grid>
                <div className={classes.btnContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onClick={() => onSubmit(values.policy, values.documentType)}
                    disabled={!values.policy || !values.documentType || submitting}
                  >
                    <Label>Copy</Label>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </BaseDialog>
  );
};

CopyInternalDocToPolicyDocsModal.propTypes = {
  toast: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  document: PropTypes.object.isRequired,
  getDocName: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchDocuments: PropTypes.func.isRequired
};

export default observer(CopyInternalDocToPolicyDocsModal);
