/* eslint-disable func-names */
import * as Yup from 'yup';
import { differenceInYears, isAfter } from 'date-fns';
import { policyType as PolicyType, offerExcessUM } from '@ourbranch/lookups';

import { canAddUmbrella, canAddUmbrellaUMUIM } from 'core/helpers/quoter.service';

export const umbrellaValidationSchema = ({ policies, savedSegment, autoPolicyDetails }) =>
  Yup.object().shape({
    includeUmbrella: Yup.boolean()
      .required()
      .test(
        'includeUmbrella',
        'Error, you cannot activate umbrella with an excluded driver on your Auto policy',
        (value) => {
          if (value) {
            // auto policy with a future end date
            const policy = policies
              ? policies.find(
                  ({ policyType, endDate }) => policyType === PolicyType.Auto && isAfter(new Date(endDate), new Date())
                )
              : null;
            if (policy) {
              const driver = policy.policyDetails.drivers
                ? policy.policyDetails.drivers.find(({ excludeDriver }) => excludeDriver)
                : null;
              return !driver;
            }
          }
          return true;
        }
      )
      .test(
        'canAddUmbrella',
        'Umbrella is disabled since underlying policies do not meet the following requirements: Auto BI needs to be at least $500K per occurrence ($250K/$500K for split or $500K for CSL), homeowner&lsquo;s  liability needs to be at least $300K, there must not be any excluded drivers or drivers with a UDR, and there must be no more than 2 incidents total.',
        // eslint-disable-next-line func-names
        function (val, { options: { context } }) {
          if (val) {
            const { homeCoverage, condoCoverage, drivers, renewalVariables, global } = context;
            const policyLimitBIPD = autoPolicyDetails?.autoCoverage?.policyLimitBIPD;
            const { coverageX } = homeCoverage || condoCoverage;

            return canAddUmbrella({
              policyLimitBIPD,
              coverageX,
              drivers,
              global,
              renewalVariables,
              includeUmbrella: true
            });
          }
          return true;
        }
      )
      .nullable(),
    umbrellaCoverage: Yup.object()
      .when('includeUmbrella', {
        is: true,
        then: Yup.object().shape({
          liabilityCoverageLimit: Yup.number()
            .test(
              'umbrella-limit-caped-at-2mil',
              'You cannot increase coverage over $2m',
              function (value, { options: { context } }) {
                // currently not allowing umbrella higher than 2mil
                const liabilityCoverageLimitChanged =
                  context.includeUmbrella && savedSegment?.umbrellaCoverage?.liabilityCoverageLimit !== value;
                if (liabilityCoverageLimitChanged && value > 2000000) {
                  return false;
                }
                return true;
              }
            )
            .required('Umbrella Limit is required')
            .nullable(),
          watercraftHullLengths: Yup.array()
            .of(
              Yup.number().test(
                'craftHullLengthsMax',
                'We do not allow boats with hull lengths over 30 feet.',
                function (value, { options: { context } }) {
                  const { includeUmbrella } = context;
                  return includeUmbrella ? value <= 30 : true;
                }
              )
            )
            .nullable(),
          uninsuredMotoristLimit: Yup.string()
            .test(
              'canHaveExcessUM',
              'You must have 500K per occurrence of UM/UIM to add excess UM to umbrella',
              function (value, { options: { context } }) {
                if (!value || value === '0') {
                  return true;
                }

                const { state, includeUmbrella } = context;
                const { policyLimitUMBI, policyLimitUIMBI } = autoPolicyDetails?.autoCoverage || {};

                const canAddUM = canAddUmbrellaUMUIM({ policyLimitUMBI, policyLimitUIMBI, state });

                return includeUmbrella ? canAddUM : true;
              }
            )
            .test(
              'stateCannotHaveExcessUM',
              'This state does not allow excess UM/UIM',
              function (value, { options: { context } }) {
                if (!value || value === '0') {
                  return true;
                }

                const { state, includeUmbrella } = context;

                return includeUmbrella ? offerExcessUM[state] : true;
              }
            )
            .test('requiredWhenOffering', 'Excess UM/UIM is required', function (value, { options: { context } }) {
              const { state } = context;
              return offerExcessUM[state] ? !!value : true;
            })
            .nullable(),
          numVehiclesTotal: Yup.number()
            .test(
              'minCount',
              'The total number of Umbrella vehicles cannot be less than the number of vehicles on the auto policy',
              function (value, { options: { context } }) {
                if (!autoPolicyDetails) {
                  return true;
                }
                const numCars = autoPolicyDetails?.cars?.length;
                const { includeUmbrella } = context;
                return includeUmbrella ? value >= numCars : true;
              }
            )
            .typeError('Number of vehicles is required')
            .required(),
          numLicensedDriversUnder25: Yup.number()
            .test(
              'minCountDrivers',
              'The total number of Umbrella drivers under age 25 cannot be less than the number of drivers under age 25 on the auto policy',
              function (value, { options: { context } }) {
                if (!autoPolicyDetails) {
                  return true;
                }
                const driversUnder25 = autoPolicyDetails?.drivers.filter((d) => {
                  const age = differenceInYears(new Date(), new Date(d.dateOfBirth));
                  return age < 25;
                }).length;

                const { includeUmbrella } = context;
                return includeUmbrella ? value >= driversUnder25 : true;
              }
            )
            .typeError('Number of licensed drivers under 25 is required')
            .required(),
          numRVs: Yup.number()
            .required('Number of Golf Carts and Snowmobiles is required')
            .typeError('Number of Golf Carts and Snowmobiles is required'),
          numMotorcyclesScooters: Yup.number()
            .required('Number of Motorcycles/Scooters is required')
            .typeError('Number of Motorcycles/Scooters is required'),
          numATVs: Yup.number()
            .required('Number of ATVs is required')
            .default(null)
            .typeError('Number of ATVs is required'),
          numPersonalWatercraft: Yup.number()
            .required('Number of Personal Watercraft is required')
            .typeError('Number of Personal Watercraft is required')
        }),
        otherwise: Yup.object().nullable()
      })
      .nullable()
  });
