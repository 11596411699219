export { default as geoPolygons } from './geoPolygons.json';
export { default as mortgageServiceContact } from './mortgageServiceContact.json';
export { default as violationPointsByState } from './violationPointsTables.json';
export { default as branchInternalCodes } from './branchInternalCodes.json';

export { insuranceProviderContact } from './insuranceProviderContact';
export { templates } from './templates';
export { carsWithPassiveRestraint } from './carsWithPassiveRestraint';
export { creditVisionAutoReasons } from './creditVisionAutoReasons';
export { trueRiskPropertyReasons } from './trueRiskPropertyReasons';
export * from './affinitySettings';
export * from './stateSettings';
export * from './docsSettings';
export * from './ratingSettings';

export const highMSRPMakes = [
  'mercedes-benz',
  'bmw',
  'audi',
  'land rover',
  'lucid',
  'maserati',
  'porsche',
  'tesla',
  'cadillac',
  'lotus',
  'rivian',
  'ferrari',
  'pagani',
  'mclaren',
  'rolls-royce',
  'fisker',
  'rezvani',
  'saleen',
  'shelby'
];

export const highMSRPModels = ['corvette', 'viper', 'nsx', 'gt-r'];

export const aleTimeLinePerState = {
  CO: 'Up to 24 months not to exceed'
};

export const alternateFRStates = {
  PA: true
};

/** Now getting from the main rater */
export const alternateUMUIMStates = {
  UT: false
};

export const umUimIncludedInPerCarTotal = {
  TX: true
};

export const umpdPerPolicyStates = {
  TX: true
};

export const listExcludedDriversOnIDCardsStates = {
  AR: true,
  LA: true,
  MI: true,
  NE: true,
  OK: true,
  PA: true
};

export const separateUMandUIMOnFormsStates = {
  AR: true,
  DC: true,
  IA: true,
  ID: true,
  KY: true,
  MA: true,
  MO: true,
  MT: true,
  PA: true,
  SC: true,
  SD: true,
  UT: true,
  VT: true,
  WI: true,
  WV: true
};

export const umOnlyOnLabelStates = {
  GA: true,
  MS: true,
  OK: true,
  SD: true,
  TN: true,
  UT: true,
  WY: true
};

export const leaseLoanGapLimitStates = {
  AR: 20231214,
  DC: 20240104,
  GA: 20240524,
  IA: 20231012,
  IL: 20231106,
  IN: 20230714,
  KY: 20230804,
  LA: 20240104,
  MD: 20230719,
  MI: 20230804,
  MO: 20230921,
  MS: 20231109,
  MT: 20230914,
  ND: 20231005,
  NE: 20230831,
  NM: 20231005,
  OH: 20230915,
  OK: 20230804,
  OR: 20230921,
  PA: 20231026,
  TX: 20240421,
  UT: 20230928,
  WI: 20230921,
  WV: 20230914
};

export const roadsideAssistanceLimitStates = {
  AR: 20231214,
  DC: 20240104,
  GA: 20240524,
  IA: 20231012,
  IL: 20231106,
  IN: 20230714,
  KY: 20230804,
  LA: 20240104,
  MD: 20230719,
  MI: 20230804,
  MO: 20230921,
  MS: 20231109,
  MT: 20230914,
  ND: 20231005,
  NE: 20230831,
  NM: 20231005,
  OH: 20230915,
  OK: 20230804,
  OR: 20230921,
  PA: 20231026,
  TX: 20240421,
  UT: 20230928,
  WI: 20230921,
  WV: 20230914
};

export const condoType = {
  LR: 'Low Rise',
  MR: 'Mid Rise',
  HR: 'High Rise',
  TH: 'TownHouse',
  R: 'Rowhouse',
  D: 'Detached'
};

/** Friendly names for policy documents */
export const docIdToFriendlyName = {
  A_AMND: 'Auto Policy Amendatory Endorsement',
  A_APPL: 'Auto Application',
  A_APTA: 'Automobile Policy Term Acknowledgement Form',
  A_BIOF: 'Bodily Injury Option and Premiums',
  A_BISF: 'Choice of Bodily Injury Liability Coverage Limits',
  A_CNPY: 'Cancellation Notice',
  A_CNREL: 'Cancellation Notice',
  A_CNRET: 'Cancellation Notice',
  A_CNREV: 'Cancellation Notice',
  A_CNRS: 'Cancellation Notice',
  A_CODW: 'Collision Deductible Waiver',
  A_DECK: 'Auto Declarations',
  A_DECLL: 'Declination Notice',
  A_DECLT2: 'Declination Notice',
  A_DECLT: 'Declination Notice',
  A_DECLV: 'Declination Notice',
  A_DITR: 'Declaration of Intent to Reside',
  A_ICSE: 'Insurance Consultation Services Exemption',
  A_IDCD: 'Auto ID Cards',
  A_MRMV: 'Massachusetts RMV Insurance Certification',
  A_INNO: 'Important Insurance Notice',
  A_LOIW: 'Loss of Income Waiver',
  A_MEDP: 'MedPay Selection/Rejection Form',
  A_MPSF: 'Selection of Personal Injury Protection',
  A_NDEL: 'Named Driver Exclusion Election Form',
  A_NDEX: 'Named Driver Exclusion',
  A_NNOP: 'Named Non-Owner Coverage Endorsement',
  A_NOBE: 'Notice of Benefits',
  A_NOTO: 'Notice of Tort Options',
  A_NPRCT: 'Rescission Notice',
  A_NRBIX: 'Insurance Cancellation Notice',
  A_NRRS: 'Non-Renewal Notice',
  A_NULL: 'Null Contract',
  A_PIPR: 'Pet Injury Endorsement',
  A_PIPS: 'Notice of Waiver of Personal Injury Protection',
  A_PJAK: 'Auto Policy',
  A_PLCY: 'Auto Policy',
  A_PSDS: 'Surcharge Disclosure',
  A_RENT: 'Renters Endorsement',
  A_RIDE: 'Rideshare Endorsement',
  A_SFSW: 'Auto Stress-Free Switch Letter',
  A_SMCQ: 'State Minimum Coverage Quote',
  A_TOSF: 'Tort Selection Rejection Form',
  A_UIMS: 'UIM Selection Rejection Form',
  A_POSF: 'Florida Selection of Personal Injury Protection',
  A_UMNF: 'Notice Regarding UMPD',
  A_UMRJ: 'UM/UIM Rejection Form',
  A_UMSF: 'UM or UM/UIM Selection Rejection Form',
  A_WGPT: 'Window Glass Coverage Endorsement',
  A_WLBW: 'Work Loss Benefits Waiver',
  B_AUTH: 'Recurring Payment Authorization',
  B_SASP: 'Subscriber Agreement Signature Page',
  BRANCH_AUTO_ID_CARDS: 'Auto ID Cards',
  H_AAVK: 'Adverse Action Decision Notice',
  H_ACSF: 'Additional Coverage Offerings',
  H_AMND: 'Home Policy Amendatory Endorsement',
  H_ANME: 'Animal Liability Exclusion',
  H_ANML: 'Animal Liability Limitation Endorsement',
  H_APPL: 'Homeowners Application',
  H_BIND: 'Home Insurance Proof of Insurance',
  H_CMSL: 'Coal Mine Subsidence Waiver',
  H_CNPY: 'Cancellation Notice',
  H_CNRN: 'Insurance Cancellation Notice',
  H_CNRS: 'Cancellation Notice',
  H_DECK: 'Homeowners Declarations',
  H_DPUA: 'Homeowners Personal Umbrella Liability Declarations Addendum',
  H_EUSF: 'Excess UM/UIM Motorists Selection/Rejection Form',
  H_FCRA: 'Adverse Action Decision Notice',
  H_FLOX: 'Flood Notice',
  H_IB10: 'Increased Building Structure Protection 10% Endorsement',
  H_INVC: 'Escrow Invoice',
  H_LADN: 'Louisiana Deductible Notice',
  H_MINA: 'Mine Subsidence Insurance Underwriting Association Application',
  H_MINE: 'Mine Subsidence Endorsement',
  H_MNSF: 'Mine Subsidence Selection Rejection Form',
  H_NRBIX: 'Insurance Cancellation Notice',
  H_NRREV: 'Non-Renewal Notice',
  H_NRRS: 'Non-Renewal Notice',
  H_NULL: 'Null Contract',
  H_PJAK: 'Homeowners Policy',
  H_PLCY: 'Homeowners Policy',
  H_PUMB: 'Personal Umbrella Liability Endorsement',
  H_RCEL: 'Home Replacement Cost Estimate Letter',
  H_RENI: 'Fair Rental Income Endorsement',
  H_ROFE: 'Roof Surfaces Extended Coverage',
  H_ROFM: 'Metal Roof Surfaces Cosmetic Damage Exclusion',
  H_ROOF: 'Roof Surfaces Endorsement',
  H_SEWX: 'DC Sewer Notice',
  H_SFSW: 'Home Stress-Free Switch Letter',
  H_SHAR: 'Home Data Sharing Authorization',
  H_SPPE: 'Scheduled Personal Property ',
  H_WHXX: 'Wind/Hail Exclusion Form',
  H_WNHD: 'Windstorm and Hail Deductible Endorsement',
  H_WTBU: 'Water Backup Endorsement',
  R_AMND: 'Renters Policy Amendatory Endorsement',
  R_APPL: 'Renters Application',
  R_CNPY: 'Cancellation Notice',
  R_CNRS: 'Cancellation Notice',
  R_DECK: 'Renters Declarations',
  R_NRRS: 'Non-Renewal Notice',
  R_PJAK: 'Renters Policy',
  R_PLCY: 'Renters Policy',
  R_SFSW: 'Renters Stress-Free Switch Letter',
  C_APPL: 'Condo Application',
  C_MINA: 'Mine Subsidence Insurance Underwriting Association Application',
  C_DECK: 'Condo Declarations',
  C_PJAK: 'Condo Policy',
  C_WTBU: 'Water Backup Endorsement',
  C_DPUA: 'Condo Personal Umbrella Liability Declarations Addendum',
  C_PUMB: 'Personal Umbrella Liability Endorsement',
  R_SPPE: 'Scheduled Personal Property',
  C_SPPE: 'Scheduled Personal Property',
  C_INVC: 'Escrow Invoice',
  C_BIND: 'Condo Insurance Proof of Insurance',
  C_AMND: 'Condo Policy Amendatory Endorsement'
};

/** Descriptive text for whether you're paying up front or monthly */
export const paymentTypeText = {
  I: 'monthly',
  F: 'up front',
  E: 'billed to mortgage lender'
};

export const escrowPaymentMethod = 'W';

export const referral = {
  payoutMethods: {
    manualPayoutId: 'f271c5d0-4c3c-4274-8276-1184b0238111',
    amazonGiftCardPayoutId: 'fd8e8811-61d5-4b9c-9324-7bf90abc0566'
  },
  programs: {
    inventoryUploadProgramId: '44511320-d510-4ef7-897c-b8ef7b08713a',
    referAndEarnProgramId: 'd10f7c85-5aa1-4ef4-a0a9-5ea4f753bba5'
  },
  payoutAmounts: {
    optionalInventorySubmissionPayoutUSD: 10
  }
};

/** At present, the reasons that don't generate a fee: CNCN, CNMV, CNUW, CNDP, CNBX, CNDE */
export const cancelReasonsForFee = {
  CNDC: 1,
  CNPR: 1,
  CNDR: 1,
  CNDB: 1,
  CNMS: 1,
  CNPY: 1,
  CNID: 1,
  CNMV: 1,
  CNNO: 1,
  CNSO: 1,
  NA: 1
};

export const insuredInitiatedCancelReason = {
  CNDC: 1,
  CNPR: 1,
  CNDR: 1,
  CNDB: 1,
  CNMV: 1,
  CNNO: 1,
  CNSO: 1,
  NA: 1
};

export const holdBackOnInsuredCancelStates = {
  VA: 0.1
};

export const maximumNewHomeDiscountHomeAgeByState = {
  DC: 49,
  ME: 49,
  NH: 49,
  SD: 49,
  VT: 49
};

// cancel fees apply to both cancellations and reinstatements
export const cancelFeeStates = {
  AK: true,
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: false,
  GA: true,
  IA: true,
  ID: true,
  IL: false,
  IN: true,
  KS: true,
  KY: true,
  LA: false,
  MD: true,
  ME: true,
  MI: false,
  MO: true,
  MS: true,
  MT: true,
  ND: false,
  NE: true,
  NH: false,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: true,
  SD: false,
  TN: true,
  TX: false,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: false,
  WY: true
};

export const nonBixPolicyFeeStates = {
  AZ: true,
  MO: true,
  IL: true,
  OH: true,
  TX: true
};

export const nonBixImageryFeeStates = {
  AZ: true,
  MO: true,
  IL: true,
  OH: true,
  TX: true
};

export const medpayFormRegenerateStates = {
  CO: true
};

export const activeStatus = 'ActiveStatus';
export const startedLoggingReceivedCVData = '2019-10-01';
/** Append with a clarion door id */
export const clarionDoorURL = 'https://gobranch.insuredapp.com/rating/prod-requests/';

// these ignore certain codes in the auto-mapper for certain things
export const arkansasRulesNoRateCodes = {
  AR: {
    NAF: true
  },
  DC: {
    NAF: true
  }
};

export const noRateCodesForCleanAtInceptionByState = {
  LA: {
    CMP: true,
    NAF: true
  }
};

// these ignore certain codes in the auto-mapper
export const noRateCodesByState = {
  AZ: {
    startDateAsInt: 20210201,
    NAF: true,
    CMP: true,
    PUA: true,
    PUN: true,
    CMU: true
  },
  IA: {
    startDateAsInt: 20210201,
    NAF: true,
    CMP: true,
    CMU: true
  },
  KS: {
    startDateAsInt: 20180101,
    NAF: true
  },
  LA: {
    startDateAsInt: 20180101,
    NAF: true
  },
  MO: {
    startDateAsInt: 20180101,
    NAF: true,
    CMP: true
  },
  MT: {
    startDateAsInt: 20180101,
    CMP: true,
    NAF: true,
    UDR: true
  },
  TN: {
    startDateAsInt: 20180101,
    CMP: true,
    CMU: true
  },
  TX: {
    startDateAsInt: 20180101,
    PUA: true,
    PUN: true,
    CMP: true,
    CMU: true
  },
  UT: {
    stateDateAsInt: 20210101,
    NAF: true,
    CMP: true
  },
  VT: {
    startDateAsInt: 20180101,
    PUA: true,
    PUN: true,
    CMP: true,
    CMU: true
  },
  WY: {
    startDateAsInt: 20180101,
    CMP: true,
    CMU: true
  }
};

export const safeDrivingViolationsByState = {
  AZ: {
    startDateAsInt: 20180101,
    violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI']
  },
  DC: { startDateAsInt: 20180101, violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI'] },
  MO: { startDateAsInt: 20180101, violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI'] },
  ND: { startDateAsInt: 20180101, violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI', 'NAF', 'PUA', 'PUN'] }
};

export const drivingViolationsByState = {
  TX: ['DWI', 'MAJ']
};

export const creditThresholdStates = {
  AL: { A: 283, H: 231 },
  AR: { A: 283, H: 231 },
  AZ: { A: 385, H: 415 },
  CO: { A: 283, H: 218 },
  DC: { A: 283, H: 305 },
  FL: { A: 283, H: 231 },
  GA: { A: 283, H: 231 },
  IA: { A: 283, H: 231 },
  ID: { A: 283, H: 231 },
  IL: { A: 283, H: 231 },
  IN: { A: 283, H: 231 },
  KS: { A: 825, H: 776 },
  KY: { A: 283, H: 231 },
  LA: { A: 283, H: 231 },
  MA: { A: 283, H: 999 },
  MD: { A: 283, H: 999 },
  ME: { A: 283, H: 305 },
  MI: { A: 283, H: 231 },
  MO: { A: 385, H: 415 },
  MS: { A: 283, H: 231 },
  MT: { A: 283, H: 231 },
  ND: { A: 283, H: 305 },
  NE: { A: 283, H: 231 },
  NH: { A: 283, H: 305 },
  NM: { A: 283, H: 231 },
  OH: { A: 283, H: 231 },
  OK: { A: 283, H: 231 },
  OR: { A: 283, H: 231 },
  PA: { A: 283, H: 231 },
  SC: { A: 825, H: 776 },
  SD: { A: 283, H: 305 },
  TN: { A: 283, H: 231 },
  TX: { A: 510, H: 472 },
  UT: { A: 283, H: 231 },
  VA: { A: 283, H: 218 },
  VT: { A: 283, H: 305 },
  WI: { A: 283, H: 231 },
  WV: { A: 283, H: 231 },
  WY: { A: 283, H: 231 }
};

export const notifyMediaAlphaEvents = {
  /** Succesfully rated for a policy */
  REJECT_NO_CODE: 1,
  /** Succesfully bound a policy */
  BIND: 2,
  /** Rejected for a policy */
  REJECT_WITH_CODE: 3
};

export const raterBlacklist = [
  {
    fname: 'kelly', // all lowercase
    lname: 'carver',
    address: '602 harris st',
    city: 'kent',
    state: 'OH'
  },
  {
    fname: 'chris', // all lowercase
    lname: 'bezucha',
    address: '207 s mobberly ave',
    city: 'longview',
    state: 'TX'
  },
  {
    fname: 'bayron', // all lowercase
    lname: 'reyes',
    address: '8300 sheridan blvd',
    city: 'westminister',
    state: 'CO'
  },
  {
    fname: 'bayron', // all lowercase
    lname: 'reyes martinez',
    address: '7903 lowell blvd',
    city: 'westminister',
    state: 'CO'
  },
  {
    fname: 'manuel', // all lowercase
    lname: 'bay',
    address: '2401 rountree dr',
    city: 'bryan',
    state: 'TX'
  },
  {
    fname: 'megan', // all lowercase
    lname: 'coffey',
    address: '946 Ruma Rd',
    city: 'columbus',
    state: 'OH'
  }
];

export const homeClaimMapping = {
  /** O means we don't count it, but leaving here so we know we mapped it */
  'Additional Living Expenses': 'O',
  DPctOfA: 'O',
  'PROPERTY DAMAGE': 'E',
  PD: 'E',
  BI: 'E',
  'BODILY INJURY': 'E',
  BPctOfA: 'E',
  'Building Damage': 'E',
  'Business Interruption': 'E',
  'Contents Damage': 'E',
  CPctOfA: 'E',
  Dwelling: 'E',
  'Household Goods': 'E',
  'Leasehold/Rental': 'E',
  MEDPAY: 'O',
  'Med Pay': 'O',
  Medical: 'O',
  MP: 'O',
  Other: 'F',
  OTHER: 'F',
  'Personal Property': 'E',
  'Residential Property': 'E',
  'FIRE OR LIGHTNING': 'D',
  'WIND AND HAIL': 'F',
  'EXPLOSION OR IMPLOSION': 'D',
  'RIOT OF CIVIL COMMOTION': 'F',
  'DAMAGE BY VEHICLES': 'F',
  SMOKE: 'D',
  VANDALISM: 'E',
  'GLASS BREAKAGE': 'F',
  'CRIME (BURGLARY, THEFT)': 'E',
  DERAILMENT: 'F',
  'WATER DAMAGE': 'G',
  WaterBackup: 'G',
  'FALLING OBJECTS': 'F',
  'WEIGHT OF ICE/SNOW/SLEET': 'G',
  COLLAPSE: 'F',
  'ELECTRIC CURRENTS': 'F',
  'SONIC BOOM': 'F',
  'EARTHQUAKE, VOLCANO': 'O',
  'ENVIRONMENTAL CONTAMINATE': 'F',
  'MUDSLIDE / EARTH MOVEMENT': 'O',
  'MECHANICAL BREAKDOWN': 'F',
  'LOSS ASSESSMENT': 'F',
  'OFF-PREMISES POWER INTERRUPTION': 'F',
  'OFF PREMISES POWER INTER': 'F',
  property_claim_vandalism: 'E',
  property_claim_theft: 'E',
  property_claim_fire: 'D',
  property_claim_hail: 'F',
  property_claim_wind: 'F',
  property_claim_water_damage: 'G',
  property_claim_weather: 'F',
  property_claim_freezing_water: 'G',
  'Snow/Hail': 'F',
  'Wind/Windstorm': 'F'
};

// For our Branch claims
export const homeClaimActOfGod = {
  /** O means we don't count it, but leaving here so we know we mapped it */
  'WIND AND HAIL': true,
  'WEIGHT OF ICE/SNOW/SLEET': true,
  'EARTHQUAKE, VOLCANO': true,
  'MUDSLIDE / EARTH MOVEMENT': true
};

export const homeClaimWindHailLightning = {
  /** Need to classify for some states */
  'WIND AND HAIL': true,
  'FIRE OR LIGHTNING': true
};

export const surplusContributionMultiplier = 0.02; // this is the default
export const surplusContributionMultiplierByDate = [
  {
    startDateAsInt: 20230624,
    endDateAsInt: 30000101,
    multiplier: 0.15
  }
]; // for changes over time

export function getSurplusContributionMultiplier(rateControlDate: string | null, state?: string | null) {
  let rateControlDateAsInt = parseInt(String(rateControlDate).replace(/-/g, ''), 10);
  if (state === 'MI' && rateControlDateAsInt === 20230701) {
    // this case requires that we hand set the rate control date to the day before
    rateControlDateAsInt = 20230623;
  }
  for (const scm of surplusContributionMultiplierByDate) {
    if (rateControlDateAsInt >= scm.startDateAsInt && rateControlDateAsInt <= scm.endDateAsInt) {
      return scm.multiplier;
    }
  }

  return surplusContributionMultiplier;
} // getSurplusContributionMultiplier

/** Append with a stripe customer id */
export const stripeCustomerUrl = 'https://dashboard.stripe.com/customers/';

export const executionSummaryMapping = {
  rejectionType: {
    HOME: 'home',
    AUTO: 'auto',
    BOTH: 'both',
    NONE: 'none'
  }
};

export const fullStateNamesToAbbreviations: { [index: string]: string } = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  'DISTRICT OF COLUMBIA': 'DC',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  'WEST VIRGINIA': 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY'
};

export const slackChannels = {
  PARTNER_ERRORS: 'partner-errors',
  PARTNER_AGENCIES: 'partner-agencies',
  LETS_TALK_ERRORS: '4001-errors',
  DEV_TESTING: 'dev-testing',
  PARTNER_AGENCY_ERRORS: 'partner-agency-errors',
  SUSPICIOUS_QUOTES: '5019-errors'
};

// Prices provided by Product Manager Matt Hewitt on February 17 2022.
export const umbrellaCoveragePriceByState = {
  AL: { Price: '248.81' },
  AR: { Price: '248.81' },
  AZ: { Price: '130.00' },
  CO: { Price: '212.43' },
  DC: { Price: '212.43' },
  GA: { Price: '229.16' },
  IA: { Price: '156.98' },
  ID: { Price: '186.97' },
  IL: { Price: '184.49' },
  IN: { Price: '237.89' },
  KS: null,
  KY: { Price: '313.55' },
  MD: { Price: '256.08' },
  ME: { Price: '175.33' },
  MI: { Price: '175.33' },
  MO: { Price: '159.43' },
  MS: { Price: '212.43' },
  MT: { Price: '156.98' },
  ND: { Price: '130.00' },
  NE: { Price: '236.44' },
  NH: { Price: '230.76' },
  NM: { Price: '217.52' },
  OH: { Price: '156.98' },
  OK: { Price: '248.08' },
  OR: { Price: '211.70' },
  PA: { Price: '222.62' },
  SC: { Price: '214.61' },
  SD: { Price: '156.98' },
  TN: { Price: '229.16' },
  TX: { Price: '186.00' },
  UT: { Price: '258.26' },
  VA: { Price: '301.91' },
  VT: { Price: '305.62' },
  WI: { Price: '212.43' },
  WV: null
};

export const creditReorderRulesByState = {
  AL: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  AZ: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  CO: { A: { months: 12, deteriorate: false }, H: { months: 12, deteriorate: true } },
  IA: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  ID: { A: { months: 12, deteriorate: false }, H: { months: 12, deteriorate: false } },
  IN: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  KY: { A: { months: 12, deteriorate: false }, H: { months: 12, deteriorate: false } },
  KS: { A: { months: 12, deteriorate: false }, H: { months: 12, deteriorate: true } },
  MD: { A: { months: 12, deteriorate: true } },
  MI: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  MS: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  MO: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  NE: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  NM: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  OH: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  OK: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  SC: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  SD: { A: { months: 12, deteriorate: true } },
  TN: { H: { months: 12, deteriorate: true } },
  TX: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  UT: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: true } },
  WI: { A: { months: 12, deteriorate: true }, H: { months: 12, deteriorate: false } }
};

export const mandatoryCreditReorderRulesByState = {
  MD: { A: { terms: 4, deteriorate: false } },
  IA: { A: { months: 36, deteriorate: true }, H: { months: 36, deteriorate: true } },
  NH: { A: { months: 36, deteriorate: true }, H: { terms: 2, deteriorate: true } },
  NM: { A: { months: 36, deteriorate: true }, H: { terms: 2, deteriorate: true } },
  SD: { H: { terms: 3, deteriorate: true } }
};

export const renewalMVRDiscountStates = {
  MD: { months: 36 }
};

// This is a revised affinities dictionary
// Where key is the old affinity code and value is the new affinity code
export const aorAffinities = {
  QA10: 'TAC0051_1',
  QA27: 'NE0003_1',
  QA29: 'LO0001_1',
  QA34: 'PC0023_1',
  QA37: 'LE0004_1',
  QA39: 'TH0013_1',
  QA43: 'BE0005_1',
  QA46: 'TAC0051_1',
  QA38: 'TR0005_1',
  QA42: 'TAC0051_1',
  QA35: 'TAC0047_1',
  QA25: 'PC0033_9',
  QA18: 'PC0021_1',
  HE0006_1: 'PC0021_1',
  QA44: 'PA0009_1',
  BR0001_34: 'BR0001',
  BR0001_2: 'BR0001',
  QA15: 'PC0033_9',
  TK0001_1: 'PC0033_9',
  JA0001D: 'JA0001',
  TAG0001_8: 'DU0001_1',
  TWFG178: 'TWFG6',
  QA16: 'IN0062_1',
  SMAZ8: 'CL0004_1',
  AIS1: 'TWFG160',
  AGHRO346: 'TAC0074_1',
  SMAZ14: 'CL0004_1',
  AGHRO226: 'BRPT1',
  AMS21: 'SK0001_3',
  RSWL1: 'CV0002_2',
  HA0003: 'TAC0073_1',
  PR0002D: 'PR0002',
  BE0001_1D: 'BE0001_1',
  SMOH102_1D: 'SMOH102_1',
  WA0006_9D: 'WA0006_9',
  BLRDG1: 'YP2',
  PC0020_1: 'PC0033_12',
  AIA0001_10: 'AIA0001_24',
  FOUND: 'TAC0001_26',
  WA0001P: 'TAC0001_26',
  WA0001: 'TAC0001_26',
  AGHRO85: 'CS0001_1',
  QA14: 'HE0002',
  HA0001: 'CR0006_1',
  TAC0061_1: 'ROND1',
  TAC0061_2: 'ROND1',
  TAC0061P: 'ROND1',
  AD0010_11D: 'AD0010_11',
  VIV1: 'GH3',
  TAC0001_26D: 'TAC0001_26',
  TR0001P: 'TR0001',
  TR0001D: 'TR0001',
  ISG2D: 'ISG2',
  TH0064_1: 'PR0129_1',
  QA12: 'SMTX14',
  QA23: 'TAC0053_1',
  QA28: 'TH0046_1',
  QA3: 'TAC0056_1',
  QA30: 'SO0011_1',
  QA4: 'PC0027_1',
  AGHRO325D: 'AGHRO325',
  TH0089_1: 'PC0045_1',
  TAC0001_26D2: 'TAC0001_26',
  AL0057_2D: 'AL0057_2',
  FR0001_1PARTNER: 'FR0001_1',
  LU0001D: 'LU0001',
  BR0001_30: 'BR0001_31',
  BR0001_46PARTNERRATE: 'BR0001_46',
  GHD: 'GH3',
  GH3PARTNER: 'GH3',
  PC0022_1: 'PC0006_1',
  SALTY: 'EM0002_1',
  SALTYP: 'EM0002_1',
  TT0002_1: 'AL0102_1',
  BE0004_1: 'PC0023_1',
  CY0001_1: 'TH0056_1',
  CY0001_2: 'TH0056_1',
  CY0001_P: 'TH0056_1',
  FO0005_1: 'TH0055_1',
  GI0003: 'CA0016_1',
  AL0086_1: 'AL0096_1',
  PR0083_1: 'IN0059_1',
  PR0083_3: 'IN0059_1',
  RI0004_3: 'CA0017_1',
  TGS1D: 'TGS1',
  ASQ1: 'GO0009_1',
  ASQ2: 'GO0009_1',
  ASQ3: 'GO0009_1',
  ASQ4: 'GO0009_1',
  ASQ5: 'GO0009_1',
  ASQ6: 'GO0009_1',
  ASQ7: 'GO0009_1',
  ASQ8: 'GO0009_1',
  ASQ9: 'GO0009_1',
  HO0004_1: 'BRPT1',
  HO0004_2: 'BRPT1',
  HO0004_3: 'BRPT1',
  AGHRO168: 'AGHRO29',
  TWFG140: 'TWFG124',
  CO0008_1: 'PRTL1_1',
  AGHRO314: 'AGHRO',
  AGHRO349: 'AG0041_1',
  AGHRO13: 'AGHRO',
  AGHRO503_1: 'AGHRO',
  AGHRO503_1P: 'AGHRO',
  AGHRO91: 'AGHRO',
  AGHRO236: 'AGHRO',
  AGHRO104: 'AGHRO',
  AGHRO470_1: 'AGHRO',
  AGHRO470_1P: 'AGHRO',
  AGHRO23: 'AGHRO',
  AGHRO475_1: 'AGHRO',
  AGHRO475_2: 'AGHRO',
  RO0003: 'RO0016_2',
  BR0001_26: 'BR0001',
  MI0006_1: 'FI0011_1',
  MA0001_1: 'FI0011_1',
  MA0001_10: 'FI0011_1',
  MA0001_2: 'FI0011_1',
  MA0001_3: 'FI0011_1',
  MA0001_4: 'FI0011_1',
  MA0001_5: 'FI0011_1',
  MA0001_6: 'FI0011_1',
  MA0001_7: 'FI0011_1',
  MA0001_8: 'FI0011_1',
  MA0001_9: 'FI0011_1',
  MA0001: 'FI0011_1',
  AL0017_1: 'AL0132_1',
  AGHRO481_1: 'TAG0001_5',
  AGHRO481_2: 'TAG0001_5',
  AGHRO481_3: 'TAG0001_5',
  AGHRO481_4: 'TAG0001_5',
  PR0004_1: 'PR0137_1',
  PR0004_1P: 'PR0137_1',
  TH0060_1: 'ES0004_1',
  TH0060P: 'ES0004_1',
  SE0001_37: 'ES0004_1',
  RE0004_2: 'CL0008_13',
  RE0004_6: 'CL0008_13',
  RE0004_3: 'CL0008_13',
  RE0004_1P: 'CL0008_13',
  RE0004_1: 'CL0008_13',
  RE0004_9: 'CL0008_13',
  RE0004_10: 'CL0008_13',
  RE0004_7: 'CL0008_13',
  RE0004_11: 'CL0008_13',
  RE0004_12: 'CL0008_13',
  RE0004_13: 'CL0008_13',
  RE0004_5: 'CL0008_13',
  RE0004_4: 'CL0008_13',
  RE0004_8: 'CL0008_13',
  RE0004_14: 'CL0008_13',
  RE0004_15: 'CL0008_13',
  RE0004_16: 'CL0008_13',
  RE0004_17: 'CL0008_13',
  TH0070_1: 'GH3',
  TH0070P: 'GH3',
  RE0017_1: 'SU0012_1',
  RE0017P: 'SU0012_1',
  IN0015_1: 'MY0007_1',
  IN0015_2: 'MY0007_1',
  IN0015_3: 'MY0007_1',
  IN0015_4: 'MY0007_1',
  IN0015_5: 'MY0007_1',
  IN0019_1: 'MY0007_1',
  IN0019_2: 'MY0007_1',
  IN0019_3: 'MY0007_1',
  IN0019_4: 'MY0007_1',
  IN0019_5: 'MY0007_1',
  IN0019_6: 'MY0007_1',
  IN0019_7: 'MY0007_1',
  IN0019_8: 'MY0007_1',
  IN0019_9: 'MY0007_1',
  IN0019_10: 'MY0007_1',
  IN0019_11: 'MY0007_1',
  IN0019_12: 'MY0007_1',
  IN0019_13: 'MY0007_1',
  IN0019_14: 'MY0007_1',
  IN0019_15: 'MY0007_1',
  IN0019_16: 'MY0007_1',
  IN0019_17: 'MY0007_1',
  IN0019_18: 'MY0007_1',
  IN0019_19: 'MY0007_1',
  IN0019_20: 'MY0007_1',
  IN0019_21: 'MY0007_1',
  IN0019_22: 'MY0007_1',
  IN0019_23: 'MY0007_1',
  IN0019_24: 'MY0007_1',
  IN0019_25: 'MY0007_1',
  IN0019_26: 'MY0007_1',
  IN0019_27: 'MY0007_1',
  IN0019_28: 'MY0007_1',
  IN0019_29: 'MY0007_1',
  IN0019_30: 'MY0007_1',
  IN0052_1: 'MY0007_1',
  IN0015_1P: 'MY0007_1',
  D2G: 'MY0007_1',
  IN0015_7: 'MY0007_1',
  IN0015_8: 'MY0007_1',
  IN0015_9: 'MY0007_1',
  IN0015_11: 'MY0007_1',
  IN0015_12: 'MY0007_1',
  IN0015_13: 'MY0007_1',
  IN0015_14: 'MY0007_1',
  SE0016_1: 'MO0022_14',
  SE0016_2: 'MO0022_14',
  SE0016_3: 'MO0022_14',
  SE0016_4: 'MO0022_14',
  SE0016_5: 'MO0022_14',
  SE0016_6: 'MO0022_14',
  SE0016_7: 'MO0022_14',
  SE0016_8: 'MO0022_14',
  SE0016_9: 'MO0022_14',
  SE0016_10: 'MO0022_14',
  SE0016_11: 'MO0022_14',
  SE0016_12: 'MO0022_14',
  SE0016_13: 'MO0022_14',
  SE0016_14: 'MO0022_14',
  SE0016_15: 'MO0022_14',
  SE0016_16: 'MO0022_14',
  SE0016_17: 'MO0022_14',
  SE0016_18: 'MO0022_14',
  SE0016_19: 'MO0022_14',
  SE0016_20: 'MO0022_14',
  SE0016_21: 'MO0022_14',
  SE0016_22: 'MO0022_14',
  SE0016_23: 'MO0022_14',
  SE0016P: 'MO0022_14',
  IN0118_1: 'CK0002_1',
  IN0118_2: 'CK0002_1',
  IN0118P: 'CK0002_1',
  INT19: 'INT',
  CL0004_1: 'ST0018_1',
  CL0004_2: 'ST0018_1',
  CL0004_1P: 'ST0018_1'
};

export const allowedBrandedTitles = [
  'REBUILT RECONSTRUCTED',
  'MANUFACTURER BUYBACK LEMON',
  'RESTORED',
  'REBUILT RESTORED'
];

/* eslint-disable prefer-regex-literals */

export const everspanStates = {
  ID: true,
  MA: true,
  ME: true,
  NH: true,
  SD: true,
  TN: true,
  VT: true,
  WY: true
};

export const insurancePaper = {
  EVERSPAN: 'EVERSPAN',
  GSNIC: 'GSNIC',
  BIX: 'BIX'
};

export const renewalBlockedStates = ['MA', 'NH', 'SD', 'TN', 'VT', 'WY', 'CO', 'KS', 'SC', 'VA'];

export const branchStopNewBusinessDateStates = {
  AL: {
    ALL: '2023-10-29'
  },
  CO: {
    ALL: '2023-10-29'
  },
  KS: {
    ALL: '2023-11-28'
  },
  SC: {
    ALL: '2023-10-29'
  },
  VA: {
    ALL: '2023-10-29'
  }
};

export const noPetInsuranceStates = ['FL'];

export const architecturalShinglesStates = {
  ME: true,
  SD: true
};

export const expandedRoofTypeStates = {
  DC: true,
  ME: true,
  ND: true,
  NH: true,
  SD: true,
  VT: true
};

export const excludedDriverSignsStates = {
  UT: true
};

export const wipeOutNonBranchAutoCancelsOnTermByState = {
  VA: 7
};

export const neverWorseCommunityDriveScoreStates = {
  PA: true
};

export const umUimCoverageArrayAdditions = {
  MD: ['EUIMPD'],
  SC: ['UIMPD'],
  WV: ['UIMPD']
};

export const umPremiumCoverageArray = {
  AK: ['UM'],
  AL: ['UM_UIM'],
  AR: ['UMBI'],
  AZ: ['UM_UIM'],
  CO: ['UM_UIM'],
  DC: ['UMBI', 'UM'],
  FL: ['UM'],
  GA: ['UM'],
  IA: ['UM'],
  ID: ['UM'],
  IL: ['UMUIM'],
  IN: ['UM_UIM'],
  KS: ['UM'],
  KY: ['UM'],
  LA: ['UMBI'],
  MA: ['UM'],
  MD: ['UM'],
  ME: ['UMUIM'],
  MO: ['UM'],
  MI: ['UMUIM'],
  MS: ['UM'],
  MT: ['UM'],
  ND: ['UM'],
  NE: ['UM'],
  NH: ['UM'],
  NM: ['UMUIM'],
  OH: ['UM_UIM'],
  OK: ['UM'],
  OR: ['UM'],
  PA: ['UM'],
  SC: ['UM'],
  SD: ['UM'],
  TN: ['UM'],
  TX: ['UMUIMBI', 'UMUIMPD'],
  UT: ['UM'],
  VA: ['UMUIM'],
  VT: ['UM'],
  WI: ['UM'],
  WV: ['UMBI'],
  WY: ['UM']
};

export const uimPremiumCoverageArray = {
  AL: [],
  AR: ['UIM'],
  AZ: [],
  CO: [],
  DC: ['UIMBI', 'UIM'],
  FL: [],
  GA: [],
  IA: ['UIM'],
  ID: ['UIM'],
  IL: [],
  IN: [],
  KS: [],
  KY: ['UIM'],
  LA: [],
  MA: ['UIM'],
  MD: ['EUIM'],
  ME: [],
  MO: ['UIM'],
  MI: [],
  MS: ['UIM'],
  MT: ['UIM'],
  ND: ['UIM'],
  NE: ['UIM'],
  NH: ['UIM'],
  NM: [],
  OH: [],
  OK: [],
  OR: [],
  PA: ['UIM'],
  SC: ['UIM'],
  SD: ['UIM'],
  TN: [],
  TX: [],
  UT: ['UIM'],
  VA: [],
  VT: ['UIM'],
  WI: ['UIM'],
  WV: ['UIMBI'],
  WY: []
};

export const stackedUMStates = { NM: true, PA: true, FL: true };

export const autoCoveragesToSave = [
  'BI',
  'OBI',
  'RBI',
  'PD',
  'UM',
  'UIM',
  'UMBI',
  'UIMBI',
  'UM_UIM',
  'EUIM',
  'EUIMPD',
  'UMPD',
  'HUMPD',
  'UIMPD',
  'UMUIM',
  'UMUIMBI',
  'UMUIMPD',
  'COMP',
  'COLL',
  'LIM_COLL',
  'MED',
  'PIP',
  'APIP',
  'GPIP',
  'TRNSP',
  'ROAD',
  'ACPE',
  'ACQ_EXP',
  'OPS_EXP',
  'CONTENTS',
  'COMP_TRLR',
  'COLL_TRLR',
  'LOAN',
  'LPD',
  'PPI',
  'ME',
  'WL',
  'ACR',
  'INCM_LOSS',
  'COMBO_FPB',
  'FNRL_BEN',
  'MED_EXP',
  'ACC_DTH',
  'PIP_scenarios',
  'GPIP_scenarios',
  'affinity_discount_savings',
  'total_statutory_otherLiability', // this and the next three needed for stat reporting
  'total_statutory_PIP',
  'total_statutory_physDam',
  'rating_tier',
  'UM_25_50_UMPD_25', // this and the next one are needed for the UMSF form in WV
  'UM_100_300_UMPD_50',
  'affinity_discount',
  'continuous_savings',
  'direct_savings',
  'employee_savings',
  'five_year_savings',
  'good_payer_savings',
  'ho_mh_mc_savings',
  'multi_policy_savings',
  'my_community_discount_savings',
  'paid_eft_acp_savings',
  'paperless_savings',
  'smart_tech_savings',
  'three_year_savings',
  'vouch_for_me_savings',
  'aux_indicator'
];

export const autoCoveragesToOmitIfEmpty = [
  'continuous_savings',
  'direct_savings',
  'employee_savings',
  'five_year_savings',
  'good_payer_savings',
  'ho_mh_mc_savings',
  'multi_policy_savings',
  'my_community_discount_savings',
  'paid_eft_acp_savings',
  'paperless_savings',
  'smart_tech_savings',
  'three_year_savings',
  'vouch_for_me_savings',
  'HUMPD'
];

export const homeCoveragesToSave = [
  'acq_cost',
  'affinity_discount',
  'affinity_discount_savings',
  'all_other',
  'animal_exclusion',
  'az_cova_fire_tax_credit',
  'az_spp_fire_tax_credit',
  'claim_free_savings',
  'connected_home_discount',
  'connected_home_discount_savings',
  'covbmt',
  'covbp',
  'covb_1',
  'covb_10',
  'covb_11',
  'covb_12',
  'covb_2',
  'covb_3',
  'covb_4',
  'covb_5',
  'covb_6',
  'covb_7',
  'covb_8',
  'covb_9',
  'covca',
  'covc_1',
  'covc_10',
  'covc_11',
  'covc_12',
  'covc_2',
  'covc_3',
  'covc_4',
  'covc_5',
  'covc_6',
  'covc_7',
  'covc_8',
  'covc_9',
  'covdc',
  'covdr',
  'covd_1',
  'covd_10',
  'covd_11',
  'covd_12',
  'covd_2',
  'covd_3',
  'covd_4',
  'covd_5',
  'covd_6',
  'covd_7',
  'covd_8',
  'covd_9',
  'covd_unltd_diff',
  'coveb',
  'covepae',
  'coverage_a',
  'covf',
  'covfri',
  'covg',
  'covj',
  'covlp',
  'covmi',
  'covost',
  'covost_liab_protection',
  'covost_prop_remediation',
  'covsh',
  'covsl',
  'covsp',
  'covst',
  'covwb',
  'covx',
  'covy',
  'covyg',
  'cov_ms_premium',
  'direct_savings',
  'employee_savings',
  'fire_house',
  'fire_occupant',
  'fixed_exp_prem',
  'hail',
  'host_protection',
  'hurricane',
  'imagery_collection_savings',
  'liability',
  'lightning',
  'multi_policy_savings',
  'my_community_discount_savings',
  'ncor',
  'new_home_savings',
  'non_weather_water',
  'paperless_savings',
  'personal_umbrella_liability_total',
  'preferred_payer_savings',
  'rrp',
  'spp_business',
  'spp_camera',
  'spp_coin',
  'spp_deep_sea',
  'spp_fine_arts',
  'spp_fishing_other',
  'spp_furs',
  'spp_golf',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_sports_other',
  'spp_stamp',
  'spp_total',
  'spp_us_guns',
  'spp_world_guns',
  'theft',
  'total_increase_limits_addends',
  'vouch_for_me_savings',
  'weather_water',
  'wildfire',
  'wind',
  'bc_0_prem_diff',
  'bc_5_prem_diff',
  'bc_10_prem_diff',
  'bc_20_prem_diff',
  'ibsc_0_prem_diff',
  'ibsc_10_prem_diff',
  'ibsc_20_prem_diff',
  'ibsc_40_prem_diff'
];

export const homeCoveragesToOmitIfEmpty = [
  'direct_savings',
  'employee_savings',
  'my_community_discount_savings',
  'paperless_savings',
  'vouch_for_me_savings'
];

export const rentersCoveragesToSave = [
  'direct_savings',
  'paperless_savings',
  'employee_savings',
  'affinity_discount_savings',
  'connected_home_discount_savings',
  'my_community_discount_savings',
  'vouch_for_me_savings',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_furs ',
  'spp_camera',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_fine_arts',
  'spp_golf',
  'spp_us_guns',
  'spp_world_guns',
  'spp_deep_sea',
  'spp_fishing_other',
  'spp_sports_other',
  'spp_stamp',
  'spp_coin',
  'spp_business',
  'renters_fixed_expense',
  'personal_property_protection',
  'additional_living_expense',
  'family_liability_protection',
  'guest_medical_protection'
];

export const rentersCoveragesToOmitIfEmpty = [
  'direct_savings',
  'vouch_for_me_savings',
  'employee_savings',
  'paperless_savings',
  'my_community_discount_savings',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_furs ',
  'spp_camera',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_fine_arts',
  'spp_golf',
  'spp_us_guns',
  'spp_world_guns',
  'spp_deep_sea',
  'spp_fishing_other',
  'spp_sports_other',
  'spp_stamp',
  'spp_coin',
  'spp_business',
  'renters_fixed_expense',
  'personal_property_protection',
  'additional_living_expense',
  'family_liability_protection',
  'guest_medical_protection'
];

// @note assuming the same as home for now
export const condoCoveragesToSave = [
  'claim_free_savings',
  'new_home_savings',
  'connected_home_discount_savings',
  'multi_policy_savings',
  'preferred_payer_savings',
  'employee_savings',
  'direct_savings',
  'paperless_savings',
  'my_community_discount_savings',
  'affinity_discount_savings',
  'coverage_C',
  'QUOTE_TOTAL_ID',
  'covA',
  'covD',
  'covX',
  'covY',
  'covG',
  'total_increase_limits_addends',
  'covBP',
  'covCA',
  'covDR',
  'covEB',
  'covF',
  'covJ',
  'covMI',
  'cov_ms_premium',
  'covSP',
  'covST',
  'covEPAE',
  'covWB',
  'animal_exclusion',
  'personal_umbrella_liability_total',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_furs',
  'spp_camera',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_fine_arts',
  'spp_golf',
  'spp_us_guns',
  'spp_world_guns',
  'spp_deep_sea',
  'spp_fishing_other',
  'spp_sports_other',
  'spp_stamp',
  'spp_coin',
  'spp_business'
];

export const condoCoveragesToOmitIfEmpty = [
  'direct_savings',
  'employee_savings',
  'my_community_discount_savings',
  'paperless_savings',
  'vouch_for_me_savings'
];

export const vouchDiscountStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  GA: true,
  MD: true,
  MO: true,
  IA: true,
  IL: true,
  IN: true,
  KS: true,
  KY: true,
  LA: true,
  MA: true,
  ME: true,
  MI: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NH: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: false,
  SD: true,
  TN: true,
  TX: false,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: true,
  WY: true
};

export const vouchDiscountStateCaps = {
  AL: 5,
  AR: 5,
  AZ: 5,
  CO: 5,
  DC: 5,
  GA: 5,
  MD: 5,
  MO: 5,
  IA: 5,
  ID: 5,
  IL: 5,
  IN: 5,
  KS: 5,
  KY: 5,
  LA: 0,
  MA: 5,
  ME: 5,
  MI: 5,
  MS: 5,
  MT: 5,
  ND: 5,
  NH: 5,
  NM: 5,
  OH: 5,
  OK: 5,
  OR: 5,
  SC: 0,
  SD: 5,
  TN: 5,
  TX: 0,
  UT: 5,
  VA: 5,
  VT: 5,
  WI: 5,
  WV: 5,
  WY: 5
};

export const poBoxPatterns = [
  /^\s*(?:Post(?:al)?\s*(?:Office\s*)?|P[.\s]?O\.?\s*)?Box\b/i,
  /\b[p]+(ost)*\.*\s*[o|0]*(ffice)*\.*\s*b[o|0]x\b/i
];

export const statesWith250PD = ['AL', 'GA', 'IN', 'KS', 'MA', 'MD', 'MS', 'OH', 'PA', 'SC', 'WI', 'IA'];

export const sR22TicketEmail = 'stateverifications@ourbranch.com';

export const mandatoryPolicyUMPD = { MD: true, VT: true };
export const mandatoryUM = { IL: true, MA: true, MD: true, ME: true, MO: true, ND: true, OR: true, SD: true, VT: true };
export const mandatoryPIP = { KS: true, MD: true, ND: true, OR: true, UT: true };
export const noStrictCleanUWStates = ['MD', 'MI', 'ME', 'TX', 'NH'];
export const noExcludedDriversStates = [
  'AL',
  'AR',
  'AZ',
  'CO',
  'DC',
  'GA',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'ME',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NH',
  'NE',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WI',
  'WV',
  'WY'
];
export const noUDRInMonarchStates = ['MS', 'MT'];
export const specificExcludedDriversStates = ['IA'];

export const waterLossTypes = ['WATER', 'WBKP', 'FREEZE', 'SPRLE', 'SBKP', 'APPL'];

export const actsOfGodLossTypes = ['FLOOD', 'HAIL', 'LIGHT', 'MOVE', 'QUAKE', 'SINK', 'VOLCANO', 'WEATHER', 'WIND'];

export const noActsOfGodStates = {
  CT: true,
  MS: true,
  MT: true,
  SC: true
};

export const noFirstActsOfGodStates = {
  GA: true
};

export const noActsOfGodForRenewalUnderwritingStates = {
  MT: true,
  NM: true,
  TX: true
};

export const noWeatherForRenewalUnderwritingStates = {
  TX: true
};

export const windHailLightningLossTypes = ['HAIL', 'LIGHT', 'WIND'];

export const noWindHailLightningStates = {
  AR: true,
  TN: true
};

export const noFirstWindHailLightningLossTypesStates = {
  TX: true
};

export const SouthDakotaUMBIRulesStates = {
  SD: true
};

export const BI125CSLStates = {
  ME: true
};

export const noCSLStates = {
  MA: true
};

export const branchClaimCountJustAAFStates = { MS: true, OR: true };
export const separate3NAFsCheckStates = { MD: true };
export const separate3AAFsAndViolationsCheckStates = { MD: true };

export const printUMPDAtPolicyLevelStates = {
  DC: true,
  TN: true,
  VA: true
};

export const quinstreetStates = {
  AL: true,
  ID: true,
  IA: true,
  KS: true,
  NE: true,
  NH: true,
  NM: true,
  ND: true,
  SD: true,
  TN: true,
  UT: true,
  WV: true,
  WY: true
};

export const defaultCommunityDriveStates = { AZ: true, CO: true };
export const defaultCommunityDriveAffinityStates = {
  AL: /^GH.*/,
  AR: /^GH.*/,
  AZ: /^GH.*/,
  CO: /^GH.*/,
  DC: /^GH.*/,
  FL: /^GH.*/,
  GA: /^GH.*/,
  IA: /^GH.*/,
  ID: /^GH.*/,
  IL: /^GH.*/,
  IN: /^GH.*/,
  KS: /^GH.*/,
  LA: /^GH.*/,
  MA: /^GH.*/,
  MD: /^GH.*/,
  ME: /^GH.*/,
  MI: /^GH.*/,
  MO: /^GH.*/,
  MS: /^GH.*/,
  MT: /^GH.*/,
  ND: /^GH.*/,
  NE: /^GH.*/,
  NH: /^GH.*/,
  NM: /^GH.*/,
  OH: /^GH.*/,
  OK: /^GH.*/,
  OR: /^GH.*/,
  PA: /^GH.*/,
  SC: /^GH.*/,
  SD: /^GH.*/,
  TN: /^GH.*/,
  TX: /^GH.*/,
  UT: /^GH.*/,
  VA: /^GH.*/,
  VT: /^GH.*/,
  WI: /^GH.*/,
  WV: /^GH.*/,
  WY: /^GH.*/
};

export const excessCarsByDriverCarAssignmentStates = { VA: true };
export const vermontExcessDriversRatingRules = { VT: true };
export const assignDriversToCarsStates = { VA: true, VT: true, MA: true };
export const minPremiumCheckStates = { VA: 10 };

export const stateSpecificAutoUnderwriting = { MI: true, KY: true };

// THIS SHOULD ONLY BE USED FOR TESTING PURPOSES WHEN WE ARE NOT YET LIVE TO PULL
// FPC/PPC in A STATE:
// Example value: { NE: { fpc: '9', county: 'LANCASTER' } }
export const rewriteFPCForTesting = {};

export const ineligibleForUWHomeClaimGroupStates = {
  MD: ['F', 'G']
};

export const ratingLookbackByState = {
  KS: 35,
  MD: 35,
  PA: 35
};

export const maxValueForAFNClassification = {
  PA: [
    { startDateAsInt: 19000101, value: 1900 },
    {
      startDateAsInt: 20230701,
      value: 2100
    }
  ]
};

export const neverLoseCommunityDriveParticipationByState = {
  PA: true
};

export const neverLoseUmbrellaByState = {
  PA: true
};

export const yearBuiltMinimum = {
  MI: 1600
};

export const homeYearBuiltMinimum = {
  AL: 2014,
  AR: 1949,
  AZ: 1949,
  CO: 2014,
  DC: 2014,
  GA: 2014,
  IA: 1949,
  ID: 2014,
  IL: 1949,
  IN: 1949,
  KS: 2014,
  KY: 1949,
  LA: 2014,
  MA: 2014,
  MI: 1601,
  MO: 1949,
  MS: 2014,
  MT: 1949,
  ND: 2014,
  NE: 1949,
  NM: 1949,
  OH: 1949,
  OK: 2014,
  OR: 1949,
  PA: 2014,
  SC: 2014,
  SD: 2014,
  TN: 2014,
  UT: 1949,
  VA: 2014,
  VT: 2014,
  WI: 1949,
  WV: 2014,
  WY: 2014
};

export const overrideHomeYearBuiltWithCherreStates = {
  NE: true
};

export const overrideHomeExteriorWallTypeWithCherreStates = {
  MO: true
};

export const overrideHomeRoofTypeWithCherreStates = {
  AR: true,
  AZ: true,
  DC: true,
  FL: true,
  GA: true,
  IA: true,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MD: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  TX: true,
  UT: true,
  WI: true,
  WV: true
};

export const waterBackupNameIncludedIncreasedByState = {
  VA: true
};

export const waterBackupDeductibleStates = {
  DC: true,
  ME: true,
  ND: true,
  NH: true,
  SD: true
};

export const waterBackupDeductibleFixedStates = {
  OR: 1000
};

export const coverageBPInsideLimitStates = {
  DC: 2500,
  KS: 2500,
  MA: 2500,
  ND: 2500,
  SC: 2500
};

export const sewerBackupStates = {
  MD: true
};

export const invalidLicenseStatuses = [
  'CANCELL',
  'C',
  'CAN',
  'CAN,EX',
  'CLCSOR',
  'CN',
  'CNCTMP',
  'CS',
  'CSOR',
  'DECEAS',
  'DECEASE',
  'DRVEXP',
  'DRVSUS',
  'EX',
  'EXPIRE',
  'EXPIRED',
  'FAILURE',
  'INACTIV',
  'INVALID',
  'NL',
  'NON',
  'NON-LIC',
  'NOT ACT',
  'NOT LIC',
  'NOT VAL',
  'NOTLIC',
  'REVOKED',
  'S',
  'SUR',
  'SURREND',
  'SUSP/NO',
  'SUSPEND',
  'SUSPND',
  'VOL SUR'
];

// Note that CA, MO, MN, OH *were* in this array, but we have removed because we want to have our initial
// rating call not include MVR pricing as per a discussion with the product and agency teams
// see emails with Katie Ennis and Garrett Dupuis from early June 2024.
export const mvrStates = [];

export const driversLicenseNotRequiredStates = [
  'AK',
  'AZ',
  'CA',
  'CT',
  'FL',
  'GA',
  'HI',
  'IA',
  'IL',
  'IN',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'WA',
  'WI',
  'WY'
];

export const fcraDriverRiskStates = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KY',
  'LA',
  'MD',
  'ME',
  'MS',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
];

export const claimLimitRentalStates = {
  VA: true
};

export const defaultACPELimitStates = {
  FL: 2500
};

export const windHailDeductibleDefaultStates = {
  MO: 0.01,
  OK: 0.01,
  SD: 0.01
};

export const allOtherPerilsDeductibleDefaultStates = {
  AL: 2000,
  AK: 2000,
  AZ: 2000,
  AR: 2000,
  CA: 2000,
  CO: 2000,
  CT: 2000,
  DE: 2000,
  DC: 2000,
  FL: 2000,
  GA: 2000,
  HI: 2000,
  ID: 2000,
  IL: 2000,
  IN: 2000,
  IA: 2000,
  KS: 2000,
  LA: 2000,
  MA: 2000,
  MI: 2000,
  MN: 2000,
  MS: 2000,
  MO: 2000,
  MT: 2000,
  NE: 2000,
  NV: 2000,
  NJ: 2000,
  NM: 2000,
  NY: 2000,
  NC: 2000,
  ND: 2000,
  OH: 2000,
  OK: 2000,
  OR: 2000,
  PA: 2000,
  RI: 2000,
  SC: 2000,
  SD: 2000,
  TN: 2000,
  TX: 2000,
  UT: 2000,
  VT: 2000,
  VA: 2000,
  WA: 2000,
  WV: 2000,
  WI: 2000,
  WY: 2000
};

export const uninsuredMotoristFixedStates = {
  VT: '1000000'
};

export const defaultIBSCZeroAffinityStates = {
  AL: /^GH.*/,
  AR: /^GH.*/,
  AZ: /^GH.*/,
  CO: /^GH.*/,
  DC: /^GH.*/,
  FL: /^GH.*/,
  GA: /^GH.*/,
  IA: /^GH.*/,
  ID: /^GH.*/,
  IL: /^GH.*/,
  IN: /^GH.*/,
  KS: /^GH.*/,
  LA: /^GH.*/,
  MA: /^GH.*/,
  MD: /^GH.*/,
  ME: /^GH.*/,
  MI: /^GH.*/,
  MO: /^GH.*/,
  MS: /^GH.*/,
  MT: /^GH.*/,
  ND: /^GH.*/,
  NE: /^GH.*/,
  NH: /^GH.*/,
  NM: /^GH.*/,
  OH: /^GH.*/,
  OK: /^GH.*/,
  OR: /^GH.*/,
  PA: /^GH.*/,
  SC: /^GH.*/,
  SD: /^GH.*/,
  TN: /^GH.*/,
  TX: /^GH.*/,
  UT: /^GH.*/,
  VA: /^GH.*/,
  VT: /^GH.*/,
  WI: /^GH.*/,
  WV: /^GH.*/,
  WY: /^GH.*/
};

export const coverageIBSCDefaultStates = {
  AR: 0,
  IA: 20,
  LA: 20,
  MS: 20,
  SD: 20
};

export const coverageBPDefaultStates = {
  DC: 2500,
  KS: 2500,
  MA: 2500,
  ND: 2500,
  SC: 2500
};

export const coverageDPctOfADefaultStates = {
  DC: 10,
  LA: 10,
  ME: 10,
  MS: 10,
  ND: 10,
  VT: 10
};

export const coverageFDefaultStates = {};

export const coverageJDefaultStates = {
  DC: 1000,
  ME: 1000,
  ND: 1000,
  NH: 1000,
  SD: 1000,
  VT: 1000
};

export const coverageSTDefaultStates = {
  SD: 0,
  VT: 0
};

export const medPayDefaultStates = {
  FL: '1000'
};

export const policyLimitPIPWLDefaultStates = {
  FL: 'VA02'
};

export const guestPIPDefaultStates = {
  KY: 'NONE'
};

export const noFaultPIPDefaultStates = {
  KY: 'INCLUDED'
};

export const policyLimitUMPDDefaultStates = {
  VA: '20000'
};

export const policyLimitUMBIDefaultStates = {
  FL: '10/20 N'
};

export const incomeLossLabelStates = {
  SD: 'Total Disability, Loss of Income'
};

export const rateControlDateLagByStateAndPolicyType = {
  default: {
    A: 40,
    C: 45,
    H: 45,
    R: 45
  },
  AZ: {
    A: 45
  },
  DC: {
    A: 45
  },
  IA: {
    A: 45
  },
  KY: {
    A: 81,
    C: 81,
    H: 81,
    R: 81
  },
  MD: {
    A: 50,
    H: 50
  },
  MT: {
    A: 45
  },
  OK: {
    A: 45
  },
  OR: {
    A: 45
  },
  WI: {
    A: 45
  }
};

export const allowFlatRoofCoverageByState = {
  AZ: {
    roofAgeMax: 10,
    deductibleWindHailMin: 0.01
  },
  NM: {
    roofAgeMax: 10,
    deductibleWindHailMin: 0.01
  }
};

export const policyLevelAndCopyToCarUMPDStates = ['GA', 'IN', 'MD', 'MS', 'SC', 'TN', 'VA', 'VT', 'WV'];

export const priorCoverageRequiredInDays = {
  AL: 183,
  AR: 183,
  AZ: 183,
  CO: 30,
  DC: 183,
  FL: 183,
  GA: 183,
  IA: 183,
  ID: 183,
  IL: 183,
  IN: 183,
  KS: 183,
  KY: 183,
  LA: 183,
  MA: 183,
  MD: 364,
  ME: 30,
  MI: 30,
  MO: 183,
  MS: 183,
  MT: 183,
  ND: 183,
  NE: 183,
  NH: 30,
  NM: 183,
  OH: 183,
  OR: 183,
  OK: 183,
  PA: 364,
  SC: 183,
  SD: 183,
  TN: 183,
  TX: 183,
  UT: 183,
  VT: 183,
  VA: 183,
  WI: 183,
  WV: 183,
  WY: 183
};

export const umpdDeductibleByState = {
  AR: 200,
  DC: 200,
  GA: 250,
  ID: 250,
  IL: 250,
  LA: 250,
  MD: 250,
  MS: 200,
  NM: 250,
  OH: 250,
  OR: 200,
  SC: 200,
  TN: 200,
  UT: 250,
  VT: 150,
  WY: 200
};

export const noChargeInLastXDaysState = { AR: 60, MS: 60, SD: 60 };

export const billingErrorCodes = {
  ACH_IS_OWNED_BY_COMPANY: 6001,
  PAYMENT_METHOD_USED_ON_TWO_OR_MORE_ACCOUNTS: 6002
};

export const rejectText = {
  10009: 'property claims',
  10010: 'property claims',
  10011: 'property claims',
  10012: 'property claims',
  10013: 'motor vehicle record',
  10014: 'motor vehicle record',
  10015: 'auto claims',
  10016: 'auto claims',
  10017: 'auto claims',
  10018: 'auto claims',
  10019: 'property claims',
  10020: 'property claims',
  10021: 'property claims',

  20000: 'motor vehicle record',
  20001: 'motor vehicle record',
  20002: 'motor vehicle record',
  20003: 'motor vehicle record',
  20004: 'motor vehicle record',
  20005: 'motor vehicle record',

  // Non-FCRA rejections:
  50001: 'some invalid VINs supplied',
  50002: 'square footage exceeds maximum allowed',
  50003: 'too many home occupants',
  50004: 'too many vehicles for the number of drivers',
  50005: 'home is too old',
  50006: 'missing home characteristics',
  50007: 'insufficient prior insurance history',
  50008: 'eifs home construction',
  50009: 'stilts home construction',
  50010: 'wood roof',
  50011: 'replacement cost too low',
  50012: 'replacement cost too high',
  50013: 'unsupported fire protection class',
  50014: 'no fire protection class found',
  50015: 'too many drivers',
  50016: 'too many cars',
  50018: 'ineligible coastal location',
  50019: 'no fireline score returned',
  50020: 'fireline score too high',
  50021: 'fireline score plus fire protection class too high',
  50022: 'no distance-to-coast value returned',
  50023: 'unsupported distance-to-coast value',
  50024: 'scheduled personal property coverage exceeds 10% of Coverage C',
  50025: 'scheduled personal property coverage exceeds 10% of Coverage A',
  50026: 'scheduled personal property coverage exceeds 50% of Coverage C',
  50027: 'wind/hail exclusion is not supported',
  50028: 'fire protection clase is too high',
  50029: 'did not ask for auto',
  50030: 'roof condition from imagery requires new imagery to proceed',
  50031: 'yard debris from imagery requires new imagery to proceed',
  50032: 'unsupported coastal county',
  50033: 'insufficient prior insurance history',
  50034: 'invalid prior insurance history',
  50035: 'motor vehicle record',
  50036: 'too many cars for the number of drivers',
  50037: 'invalid prior insurance history',
  50038: 'could not find prior insurance history',
  50039: 'excluded drivers',
  50040: 'invalid history',
  50041: 'self-reported home underwriting disqualification',
  50042: 'self-reported auto underwriting disqualification',
  50064: 'flat roofs are not supported',
  50083: 'condo rented out',
  50084: 'monoline home ineligible via this rating system',
  50085: 'monoline condo ineligible via this rating system',
  50086: 'monoline auto ineligible via this rating system',
  50087: 'primary name insured too young',
  50088: 'secondary home ineligible',
  50089: 'property claims history',
  50094: 'replacement cost plus ibsc too high',
  50095: 'too much risk concentration in the area',
  50096: 'property mismatch',
  50097: 'no commercial properties',
  50098: 'unsupported property type',

  // API rejections
  50090: 'api rejection for home',
  50091: 'api rejection for auto',
  50092: 'api rejection for condo',
  50093: 'api rejection for auto',

  // Restarting numbering of new codes
  50100: 'auxiliary rating',
  50101: 'acreage too large',
  50102: 'asbestos home characteristics',
  50103: 'historic home',
  50104: 'branded title',
  50105: 'vacant home',
  50106: 'unsupported roof material'
};

export const rateCappingStates = {
  AR: {
    cap: 1.2,
    endDateAsInt: 29990701
  },
  KS: {
    cap: 1.3,
    endDateAsInt: 29990701
  },
  PA: {
    cap: 1.3,
    endDateAsInt: 20231110
  }
};

export const firelineCutoffsByState = {
  AZ: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  CO: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  ID: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  MT: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  NM: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  NV: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  OK: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  OR: {
    firelineAlone: 3,
    firelineWithPPC: 2
  },
  TX: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  UT: {
    firelineAlone: 2,
    firelineWithPPC: 2
  },
  WA: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  WY: {
    firelineAlone: 2,
    firelineWithPPC: 2
  }
};

export const buildingMaterialsTheftCoverageByState = {
  DC: 10000,
  LA: 5000,
  ME: 5000,
  MS: 5000,
  ND: 5000,
  NH: 5000,
  SD: 5000,
  WV: 5000
};

export const mineSubsidenceMaxStates = {
  KY: 300000,
  WV: 300000
};

export const mineSubsidenceStates = {
  IL: {
    deductible: 'AOP'
  },
  IN: {
    deductible: '2%'
  },
  KY: {
    deductible: '250'
  },
  OH: {
    deductible: '2%'
  },
  WV: {
    deductible: '250'
  }
};

export const mineSubsidenceDefaulted = {
  IL: {
    BOND: true,
    BUREAU: true,
    CHRISTIAN: true,
    CLINTON: true,
    DOUGLAS: true,
    FRANKLIN: true,
    FULTON: true,
    GALLATIN: true,
    GRUNDY: true,
    ISLAND: true,
    JACKSON: true,
    JEFFERSON: true,
    KNOX: true,
    LASALLE: true,
    LOGAN: true,
    MACOUPIN: true,
    MADISON: true,
    MARION: true,
    MARSHALL: true,
    MCDONOUGH: true,
    MENARD: true,
    MERCER: true,
    MONTGOMERY: true,
    PEORIA: true,
    PERRY: true,
    PUTNAM: true,
    RANDOLPH: true,
    ROCK: true,
    'ROCK ISLAND': true,
    'SAINT CLAIR': true,
    SALINE: true,
    SANGAMON: true,
    'ST CLAIR': true,
    'ST. CLAIR': true,
    TAZEWELL: true,
    VERMILLION: true,
    WASHINGTON: true,
    WILLIAMSON: true
  }
};

export const useIneligibleCoastalCitiesForAutoEligibilityStates = {
  LA: true,
  MS: true,
  TX: true
};

export const ineligibleCoastalCities = {
  LA: {
    ACADIA: { all: true },
    ALLEN: { all: true },
    ASCENSION: { all: true },
    ASSUMPTION: { all: true },
    BEAUREGARD: { all: true },
    CALCASIEU: { all: true },
    CAMERON: { all: true },
    'EAST BATON ROUGE': { all: true },
    'EAST FELICIANA': { all: true },
    EVANGELINE: { all: true },
    IBERIA: { all: true },
    IBERVILLE: { all: true },
    'JEFFERSON DAVIS': { all: true },
    JEFFERSON: { all: true },
    LAFAYETTE: { all: true },
    LAFOURCHE: { all: true },
    ORLEANS: { all: true },
    PLAQUEMINES: { all: true },
    'POINTE COUPEE': { all: true },
    'ST. BERNARD': { all: true },
    'ST. CHARLES': { all: true },
    'ST. HELENA': { all: true },
    'ST. JAMES': { all: true },
    'ST. JOHN THE BAPTIST': { all: true },
    'ST. LANDRY': { all: true },
    'ST. MARTIN': { all: true },
    'ST. MARY': { all: true },
    'ST. TAMMANY': { all: true },
    TANGIPAHOA: { all: true },
    TERREBONNE: { all: true },
    VERMILION: { all: true },
    WASHINGTON: { all: true },
    'WEST BATON ROUGE': { all: true }
  },
  MA: {
    BARNSTABLE: { all: true },
    DUKES: { all: true },
    NANTUCKET: { all: true }
  },
  MS: {
    ADAMS: { all: true },
    AMITE: { all: true },
    COVINGTON: { all: true },
    FORREST: { all: true },
    FRANKLIN: { all: true },
    GEORGE: { all: true },
    GREENE: { all: true },
    HANCOCK: { all: true },
    HARRISON: { all: true },
    JACKSON: { all: true },
    'JEFFERSON DAVIS': { all: true },
    JONES: { all: true },
    LAMAR: { all: true },
    LAWRENCE: { all: true },
    LINCOLN: { all: true },
    MARION: { all: true },
    'PEARL RIVER': { all: true },
    PERRY: { all: true },
    PIKE: { all: true },
    STONE: { all: true },
    WALTHALL: { all: true },
    WAYNE: { all: true },
    WILKINSON: { all: true }
  },
  SC: {
    BEAUFORT: { all: true },
    CHARLESTON: { all: true },
    COLLETON: { all: true },
    GEORGETOWN: { all: true },
    HORRY: { all: true },
    JASPER: { all: true }
  },
  TX: {
    ARANSAS: { all: true },
    BEE: { all: true },
    BRAZORIA: { all: true },
    BROOKS: { all: true },
    CALHOUN: { all: true },
    CAMERON: { all: true },
    CHAMBERS: { all: true },
    'FORT BEND': { all: true },
    GALVESTON: { all: true },
    GOLIAD: { all: true },
    HARDIN: { all: true },
    HARRIS: { all: true },
    HIDALGO: { all: true },
    JACKSON: { all: true },
    JEFFERSON: { all: true },
    'JIM WELLS': { all: true },
    KENEDY: { all: true },
    KLEBERG: { all: true },
    LIBERTY: { all: true },
    'LIVE OAK': { all: true },
    MATAGORDA: { all: true },
    NUECES: { all: true },
    ORANGE: { all: true },
    REFUGIO: { all: true },
    'SAN PATRICIO': { all: true },
    VICTORIA: { all: true },
    WHARTON: { all: true },
    WILLACY: { all: true }
  },
  FL: {
    'MIAMI-DADE': { all: true },
    BROWARD: { all: true },
    'PALM BEACH': { all: true }
  }
};

export const monolineAutoRejectInCoastalCountiesStates = {
  SC: true
};

export const coastalTerritoryCounties = {
  SC: {
    BEAUFORT: true,
    BERKELEY: true,
    CHARLESTON: true,
    COLLETON: true,
    DORCHESTER: true,
    GEORGETOWN: true,
    HORRY: true,
    JASPER: true
  }
};

export const coastalCounties1PctWH = {
  GA: {
    BRYAN: true,
    CAMDEN: true,
    CHATHAM: true,
    GLYNN: true,
    LIBERTY: true,
    MCINTOSH: true
  }
};

// county elements are cities, or "all" for all other cities
// array elements are distance to coast:
// <2mi, 2-3mi, 3-5mi, 5-10mi, 10+mi
export const coastalCountiesMinimumHurricaneDeductible = {
  LA: {
    AVOYELLES: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    BIENVILLE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    BOSSIER: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CADDO: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CALDWELL: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CATAHOULA: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CLAIBORNE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CONCORDIA: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'DE SOTO': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'EAST CARROLL': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    FRANKLIN: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    GRANT: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    JACKSON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    LASALLE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    LINCOLN: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    LIVINGSTON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    MADISON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    MOREHOUSE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    NATCHITOCHES: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    OUACHITA: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    RAPIDES: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'RED RIVER': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    RICHLAND: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    SABINE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    TENSAS: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    UNION: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    VERNON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    WEBSTER: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'WEST CARROLL': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'WEST FELICIANA': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    WINN: { all: [0.02, 0.02, 0.02, 0.02, 0.02] }
  },
  MA: {
    BARNSTABLE: { all: [0.05, 0.05, 0.05, 0.05, 0.05] },
    DUKES: { all: [0.05, 0.05, 0.05, 0.05, 0.05] },
    NANTUCKET: { all: [0.05, 0.05, 0.05, 0.05, 0.05] },
    BRISTOL: { all: [0.05, 0.05, 0.02, 1000, 1000] },
    PLYMOUTH: { all: [0.05, 0.05, 0.02, 1000, 1000] },
    NORFOLK: { COHASSET: [0.05, 0.05, 0.02, 1000, 1000], all: [0.02, 0.01, 0.01, 1000, 1000] },
    ESSEX: { all: [0.02, 1000, 1000, 1000, 1000] },
    SUFFOLK: { all: [0.02, 1000, 1000, 1000, 1000] }
  }
};

export const dtcToArrayIndexForMinimumHurricaneDeductible = {
  '01': 0,
  '02': 0,
  '03': 0,
  '04': 0,
  '05': 0,
  '06': 0,
  '07': 0,
  '08': 1,
  '09': 2,
  '10': 2,
  '11': 3,
  '12': 4,
  '13': 4,
  '14': 4,
  '15': 4,
  '16': 4,
  '': 4
};

export const coastalCounties = {
  SC: {
    ALLENDALE: true,
    BAMBERG: true,
    BARNWELL: true,
    BEAUFORT: true,
    BERKELEY: true,
    CALHOUN: true,
    CHARLESTON: true,
    CLARENDON: true,
    COLLETON: true,
    DARLINGTON: true,
    DILLION: true,
    DILLON: true,
    DORCHESTER: true,
    FLORENCE: true,
    GEORGETOWN: true,
    HAMPTON: true,
    HORRY: true,
    JASPER: true,
    LEE: true,
    MARION: true,
    MARLBORO: true,
    ORANGEBURG: true,
    SUMTER: true,
    WILLIAMSBURG: true
  },
  LA: {
    ACADIA: true,
    ALLEN: true,
    ASCENSION: true,
    ASSUMPTION: true,
    BEAUREGARD: true,
    CALCASIEU: true,
    CAMERON: true,
    'EAST BATON ROUGE': true,
    'EAST FELICIANA': true,
    EVANGELINE: true,
    IBERIA: true,
    IBERVILLE: true,
    'JEFFERSON DAVIS': true,
    JEFFERSON: true,
    LAFAYETTE: true,
    LAFOURCHE: true,
    ORLEANS: true,
    PLAQUEMINES: true,
    'POINTE COUPEE': true,
    'ST. BERNARD': true,
    'ST. CHARLES': true,
    'ST. HELENA': true,
    'ST. JAMES': true,
    'ST. JOHN THE BAPTIST': true,
    'ST. LANDRY': true,
    'ST. MARTIN': true,
    'ST. MARY': true,
    'ST. TAMMANY': true,
    TANGIPAHOA: true,
    TERREBONNE: true,
    VERMILION: true,
    WASHINGTON: true,
    'WEST BATON ROUGE': true
  },
  TX: {
    HARDIN: true,
    JEFFERSON: true,
    ORANGE: true,
    AUSTIN: true,
    BRAZORIA: true,
    CHAMBERS: true,
    COLORADO: true,
    'FORT BEND': true,
    GALVESTON: true,
    HARRIS: true,
    LIBERTY: true,
    MATAGORDA: true,
    MONTGOMERY: true,
    WALKER: true,
    WALLER: true,
    WHARTON: true,
    CALHOUN: true,
    DEWITT: true,
    GOLIAD: true,
    GONZALES: true,
    JACKSON: true,
    LAVACA: true,
    VICTORIA: true,
    ARANSAS: true,
    BEE: true,
    BROOKS: true,
    DUVAL: true,
    'JIM WELLS': true,
    KENEDY: true,
    KLEBURG: true,
    KLEBERG: true,
    'LIVE OAK': true,
    NUECES: true,
    REFUGIO: true,
    'SAN PATRICIO': true,
    CAMERON: true,
    HIDALGO: true,
    WILLACY: true
  }
};

export const pipCheckStates = {
  KY: true
};

export const coverageRSForcedAtXYearsAlwaysStates = {
  AR: 15
};

export const selfInspectionStates = { IN: true };

export const connectedHomeProvidersAllowingVerification = ['ADT'];

// As part of data cost reduction in May 2023; we should remove once adding significant new business
export const disableUDIDLAndVRR = true;

export const strictFpcStateNotAllowedValues = ['7', '8', '8B', '9'];
export const strictFpcStates = ['AZ', 'CO', 'ID', 'MT', 'NM', 'OK', 'OR', 'TX', 'UT', 'WY'];
export const priorLapseDays = { TX: 32 };

export const planType = { Standard: 'S', Economy: 'E', Premium: 'P', Custom: 'C' };
export const chargeDeskPaymentMethodTypes = {
  Escrow: ['Check', 'Bank Transfer', 'Cash', 'Transfer'],
  Card: ['Debit', 'Prepaid', 'Credit'],
  ACH: ['Bank']
};

// status on stripe-specific charges/transactions
export const stripeChargeStatus = {
  Succeeded: 'succeeded',
  Pending: 'pending',
  Failed: 'failed'
};

// status on transactions when they are retrieved from billingHandler.getChargeDeskTransactions()
export const normalizedPaymentStatus = {
  Paid: 'paid',
  Pending: 'pending',
  Refunded: 'refunded',
  PartiallyRefunded: 'partially refunded',
  Failed: 'failed'
};

export const cancelNoticeParagraphText = [
  {
    cancelNoticeName: 'CNRS',
    paragraphText:
      "We'll be happy to reinstate your policy if we receive the information requested above prior to cancellation. Just give us a call or, if you prefer, fax or mail the requested information along with a copy of this page to Branch. But don't delay. We won't be able to reinstate your policy if you contact us after the deadline."
  },
  {
    cancelNoticeName: 'CNPY',
    paragraphText:
      "Unfortunately, we didn't receive your payment and, as a result, your policy will be cancelled at 12:01 a.m. on [endDate]. Please know that this means you will no longer have insurance coverage. If you've already sent your payment, thank you. Your next regular payment will be due on [minimumAmountDueDate]. You can also pay online or over the phone using a credit card or authorizing a withdrawal from your bank account. We'll credit your payment right away so your insurance coverage will continue. We sincerely appreciate your attention to this matter and thank you for your business."
  }
];

export const passiveRestraintStates = ['PA'];

export const excludeBIXMonolineUnderwritingStates = [];

export const newBusinessMoratoriumZipCodes = [
  // TX DFW Zips blocked from 12/1/22
  '75001',
  '75002',
  '75006',
  '75007',
  '75009',
  '75010',
  '75013',
  '75019',
  '75022',
  '75023',
  '75024',
  '75025',
  '75028',
  '75032',
  '75033',
  '75034',
  '75035',
  '75036',
  '75038',
  '75039',
  '75040',
  '75041',
  '75042',
  '75043',
  '75044',
  '75048',
  '75050',
  '75051',
  '75052',
  '75054',
  '75056',
  '75057',
  '75060',
  '75061',
  '75062',
  '75063',
  '75065',
  '75067',
  '75068',
  '75069',
  '75070',
  '75071',
  '75072',
  '75074',
  '75075',
  '75077',
  '75078',
  '75080',
  '75081',
  '75082',
  '75086',
  '75087',
  '75088',
  '75089',
  '75093',
  '75094',
  '75097',
  '75098',
  '75101',
  '75104',
  '75105',
  '75114',
  '75115',
  '75116',
  '75119',
  '75125',
  '75126',
  '75132',
  '75134',
  '75135',
  '75137',
  '75141',
  '75142',
  '75143',
  '75146',
  '75147',
  '75149',
  '75150',
  '75152',
  '75154',
  '75155',
  '75157',
  '75158',
  '75159',
  '75160',
  '75161',
  '75164',
  '75165',
  '75166',
  '75167',
  '75169',
  '75172',
  '75173',
  '75180',
  '75181',
  '75182',
  '75189',
  '75201',
  '75202',
  '75203',
  '75204',
  '75205',
  '75206',
  '75207',
  '75208',
  '75209',
  '75210',
  '75211',
  '75212',
  '75214',
  '75215',
  '75216',
  '75217',
  '75218',
  '75219',
  '75220',
  '75223',
  '75224',
  '75225',
  '75226',
  '75227',
  '75228',
  '75229',
  '75230',
  '75231',
  '75232',
  '75233',
  '75234',
  '75235',
  '75236',
  '75237',
  '75238',
  '75240',
  '75241',
  '75243',
  '75244',
  '75246',
  '75247',
  '75248',
  '75249',
  '75251',
  '75252',
  '75253',
  '75254',
  '75287',
  '75402',
  '75407',
  '75409',
  '75424',
  '75442',
  '75454',
  '75474',
  '76001',
  '76002',
  '76006',
  '76008',
  '76009',
  '76010',
  '76011',
  '76012',
  '76013',
  '76014',
  '76015',
  '76016',
  '76017',
  '76018',
  '76020',
  '76021',
  '76022',
  '76028',
  '76031',
  '76033',
  '76034',
  '76036',
  '76039',
  '76040',
  '76041',
  '76044',
  '76050',
  '76051',
  '76052',
  '76053',
  '76054',
  '76058',
  '76059',
  '76060',
  '76061',
  '76063',
  '76064',
  '76065',
  '76071',
  '76078',
  '76084',
  '76085',
  '76086',
  '76087',
  '76092',
  '76102',
  '76103',
  '76104',
  '76105',
  '76106',
  '76107',
  '76108',
  '76109',
  '76110',
  '76111',
  '76112',
  '76114',
  '76115',
  '76116',
  '76117',
  '76118',
  '76119',
  '76120',
  '76123',
  '76126',
  '76127',
  '76129',
  '76131',
  '76132',
  '76133',
  '76134',
  '76135',
  '76137',
  '76140',
  '76148',
  '76155',
  '76164',
  '76177',
  '76179',
  '76180',
  '76182',
  '76201',
  '76205',
  '76206',
  '76207',
  '76208',
  '76209',
  '76210',
  '76226',
  '76227',
  '76244',
  '76247',
  '76248',
  '76249',
  '76258',
  '76259',
  '76262',
  '76266',
  '76651'
];

export const coloradoRulesForViolationsStatesAndDates = {
  CO: 20220519
};

// Jordan Rules are: use 59 months for the two five-year discounts, and otherwise
// only rate on the 35-month period
export const jordanRulesForViolationsStatesAndDates = {
  AK: 20220718,
  AL: 20220718,
  AR: 20220718,
  AZ: 20220718,
  CA: 20220718,
  CT: 20220718,
  DC: 20220718,
  DE: 20220718,
  FL: 20220718,
  GA: 20220718,
  HI: 20220718,
  IA: 20220718,
  ID: 20220718,
  IL: 20220718,
  IN: 20220718,
  KS: 20220718,
  KY: 20220718,
  LA: 20220718,
  MA: 20220718,
  MD: 20220718,
  ME: 20220718,
  MI: 20220718,
  MN: 20220718,
  MO: 20220718,
  MS: 20220718,
  MT: 20220718,
  NC: 20220718,
  ND: 20220718,
  NE: 20220718,
  NH: 20220718,
  NJ: 20220718,
  NM: 20220718,
  NV: 20220718,
  NY: 20220718,
  OH: 20220718,
  OK: 20220718,
  OR: 20220718,
  PA: 20220718,
  RI: 20220718,
  SC: 20220718,
  SD: 20220718,
  TN: 20220718,
  TX: 20220718,
  UT: 20220718,
  VA: 20220718,
  VT: 20220718,
  WA: 20220718,
  WI: 20220718,
  WV: 20220718,
  WY: 20220718
};

export const homeInspectionVendors = {
  IRS: 'irs',
  MUELLER: 'mueller'
};

export const noEBSLDefaultUnlessInStaff = {
  GH1: true,
  GH1P: true,
  GH2: true,
  GH3: true,
  GHD: true,
  GU0001: true,
  GU0001_1: true,
  MATIC1: true,
  MATIC1D: true
};

// This configuration blocks monoline policies for the given states and policy types
// This check is executed at the underwriting rule level after the data pulls are complete and
// before creating the options
export const blockMonolinePolicies = {
  CO: {
    GH1: ['H', 'A', 'C'],
    GH1P: ['H', 'A', 'C'],
    GH2: ['H', 'A', 'C'],
    GH3: ['H', 'A', 'C'],
    GHD: ['H', 'A', 'C'],
    GU0001: ['H', 'A', 'C'],
    GU0001_1: ['H', 'A', 'C'],
    MATIC1: ['H', 'A', 'C'],
    MATIC1D: ['H', 'A', 'C']
  },
  GA: {
    GH1: ['H', 'A', 'C'],
    GH1P: ['H', 'A', 'C'],
    GH2: ['H', 'A', 'C'],
    GH3: ['H', 'A', 'C'],
    GHD: ['H', 'A', 'C'],
    GU0001: ['H', 'A', 'C'],
    GU0001_1: ['H', 'A', 'C'],
    MATIC1: ['H', 'A', 'C'],
    MATIC1D: ['H', 'A', 'C']
  },
  OK: {
    GH1: ['H', 'A', 'C'],
    GH1P: ['H', 'A', 'C'],
    GH2: ['H', 'A', 'C'],
    GH3: ['H', 'A', 'C'],
    GHD: ['H', 'A', 'C'],
    MATIC1: ['H', 'A', 'C'],
    MATIC1D: ['H', 'A', 'C']
  }
};

export const homeInspectionVendorByState = {
  AZ: homeInspectionVendors.MUELLER,
  CO: homeInspectionVendors.MUELLER,
  IA: homeInspectionVendors.MUELLER,
  ID: homeInspectionVendors.MUELLER,
  KS: homeInspectionVendors.MUELLER,
  MT: homeInspectionVendors.MUELLER,
  ND: homeInspectionVendors.MUELLER,
  NE: homeInspectionVendors.MUELLER,
  NM: homeInspectionVendors.MUELLER,
  OK: homeInspectionVendors.MUELLER,
  OR: homeInspectionVendors.MUELLER,
  SD: homeInspectionVendors.MUELLER,
  UT: homeInspectionVendors.MUELLER,
  WY: homeInspectionVendors.MUELLER
};

export const InspectionStatus = {
  NotOrdered: 'Not ordered',
  Ordered: 'Ordered'
};

export const HomeInspectionTypes = {
  IRS: {
    ExteriorObservation: 'EO1',
    ExteriorObservationPlus4Point: 'EO4',
    ExteriorObservationPlus4PointRoof: 'EO4_ROOFTOP',
    ExteriorObservationRoof: 'EO_Rooftop' // idk why this one is not the same casing as the rest of them
  },
  MUELLER: {
    ExteriorObservation: 'EXTERIOR (NO DIAGRAM OR R/C)',
    ExteriorObservationWithSupplementForm: 'DRIVEBY_SUPP',
    Wildfire: 'BS' // Brush Supplement
  }
};

export const allowLowerLimitMonolineAutoAffinityCodes = ['GH1', 'GH2', 'GH3', 'MATIC1'];
export const onboardingDeadlines = {
  AL: {
    A: 60,
    H: 60
  },
  AK: {
    A: 60,
    H: 30
  },
  AZ: {
    A: 60,
    H: 60
  },
  AR: {
    A: 60,
    H: 60
  },
  CA: {
    A: null,
    H: 60
  },
  CO: {
    A: 60,
    H: 30
  },
  CT: {
    A: 60,
    H: 60
  },
  DE: {
    A: 60,
    H: 60
  },
  DC: {
    A: 60,
    H: 30
  },
  FL: {
    A: 60,
    H: 90
  },
  GA: {
    A: 60,
    H: 60
  },
  HI: {
    A: 60,
    H: 60
  },
  ID: {
    A: 60,
    H: 60
  },
  IL: {
    A: 60,
    H: 60
  },
  IN: {
    A: 60,
    H: 60
  },
  IA: {
    A: 60,
    H: 60
  },
  KS: {
    A: 60,
    H: 60
  },
  KY: {
    A: 60,
    H: 60
  },
  LA: {
    A: 60,
    H: 60
  },
  ME: {
    A: 60,
    H: 90
  },
  MD: {
    A: 45,
    H: 45
  },
  MA: {
    A: 90,
    H: 60
  },
  MI: {
    A: 55,
    H: 55
  },
  MN: {
    A: 60,
    H: 60
  },
  MS: {
    A: 60,
    H: 60
  },
  MO: {
    A: 60,
    H: 60
  },
  MT: {
    A: 60,
    H: 60
  },
  NE: {
    A: 60,
    H: 60
  },
  NV: {
    A: 70,
    H: 70
  },
  NH: {
    A: 60,
    H: 90
  },
  NJ: {
    A: 60,
    H: 60
  },
  NM: {
    A: 60,
    H: 60
  },
  NY: {
    A: 60,
    H: 60
  },
  NC: {
    H: 60
  },
  ND: {
    A: 60,
    H: 60
  },
  OH: {
    A: 90,
    H: 60
  },
  OK: {
    A: 60,
    H: 45
  },
  OR: {
    A: 60,
    H: 60
  },
  PA: {
    A: 60,
    H: 60
  },
  RI: {
    A: 60,
    H: 60
  },
  SC: {
    A: 90,
    H: 120
  },
  SD: {
    A: 60,
    H: 60
  },
  TN: {
    A: 60,
    H: 60
  },
  TX: {
    A: 60,
    H: 60
  },
  UT: {
    A: 60,
    H: 60
  },
  VT: {
    A: 60,
    H: 60
  },
  VA: {
    A: 60,
    H: 90
  },
  WA: {
    A: 60,
    H: 60
  },
  WV: {
    A: 60,
    H: 60
  },
  WI: {
    A: 60,
    H: 60
  },
  WY: {
    A: 60,
    H: 60
  }
};

export const zipCodeForceInspectionBeforeDate = {
  '31562': '2023-12-31',
  '31648': '2023-12-31',
  '31636': '2023-12-31',
  '39829': '2023-12-31',
  '39815': '2023-12-31',
  '31601': '2023-12-31',
  '31629': '2023-12-31',
  '31631': '2023-12-31',
  '31626': '2023-12-31',
  '31643': '2023-12-31',
  '31792': '2023-12-31',
  '31547': '2023-12-31',
  '39819': '2023-12-31',
  '39852': '2023-12-31',
  '39828': '2023-12-31',
  '31606': '2023-12-31',
  '31548': '2023-12-31',
  '31758': '2023-12-31',
  '31604': '2023-12-31',
  '31603': '2023-12-31',
  '31799': '2023-12-31',
  '31558': '2023-12-31',
  '31757': '2023-12-31',
  '31698': '2023-12-31',
  '31602': '2023-12-31',
  '39818': '2023-12-31',
  '31720': '2023-12-31',
  '31641': '2023-12-31',
  '39834': '2023-12-31',
  '39897': '2023-12-31',
  '31550': '2023-12-31',
  '39825': '2023-12-31',
  '31569': '2023-12-31',
  '31638': '2023-12-31',
  '31537': '2023-12-31',
  '31630': '2023-12-31',
  '39817': '2023-12-31',
  '31778': '2023-12-31',
  '31605': '2023-12-31',
  '31773': '2023-12-31',
  '39827': '2023-12-31',
  '39859': '2023-12-31',
  '31699': '2023-12-31',
  '31632': '2023-12-31',
  '31568': '2023-12-31',
  '31625': '2023-12-31',
  '31738': '2023-12-31',
  '31649': '2023-12-31',
  '31634': '2023-12-31',
  '31635': '2023-12-31',
  '31565': '2023-12-31',
  '31645': '2023-12-31',
  '31627': '2023-12-31',
  '31527': '2023-12-31',
  '31722': '2023-12-31',
  '31623': '2023-12-31',
  '31779': '2023-12-31',
  '31765': '2023-12-31',
  '31620': '2023-12-31',
  '31788': '2023-12-31',
  '31520': '2023-12-31',
  '31542': '2023-12-31',
  '31521': '2023-12-31',
  '31524': '2023-12-31',
  '39837': '2023-12-31',
  '31553': '2023-12-31',
  '31739': '2023-12-31',
  '31747': '2023-12-31',
  '31566': '2023-12-31',
  '31776': '2023-12-31',
  '31503': '2023-12-31',
  '31756': '2023-12-31',
  '31561': '2023-12-31',
  '31753': '2023-12-31',
  '31647': '2023-12-31',
  '31730': '2023-12-31',
  '31768': '2023-12-31',
  '31502': '2023-12-31',
  '31523': '2023-12-31',
  '31522': '2023-12-31',
  '31639': '2023-12-31',
  '31501': '2023-12-31',
  '31784': '2023-12-31',
  '31771': '2023-12-31',
  '31564': '2023-12-31',
  '39870': '2023-12-31',
  '31637': '2023-12-31',
  '31552': '2023-12-31',
  '31642': '2023-12-31',
  '31624': '2023-12-31',
  '31525': '2023-12-31',
  '31744': '2023-12-31',
  '31775': '2023-12-31',
  '31516': '2023-12-31',
  '31650': '2023-12-31',
  '39841': '2023-12-31',
  '31543': '2023-12-31',
  '31749': '2023-12-31',
  '31557': '2023-12-31',
  '31716': '2023-12-31',
  '31305': '2023-12-31',
  '31622': '2023-12-31',
  '31556': '2023-12-31',
  '31727': '2023-12-31',
  '31319': '2023-12-31',
  '31794': '2023-12-31',
  '31327': '2023-12-31',
  '31535': '2023-12-31',
  '31795': '2023-12-31',
  '39813': '2023-12-31',
  '31782': '2023-12-31',
  '31793': '2023-12-31',
  '31789': '2023-12-31',
  '31551': '2023-12-31',
  '31554': '2023-12-31',
  '31560': '2023-12-31',
  '31304': '2023-12-31',
  '31721': '2023-12-31',
  '31534': '2023-12-31',
  '31518': '2023-12-31',
  '31791': '2023-12-31',
  '31705': '2023-12-31',
  '31546': '2023-12-31',
  '39862': '2023-12-31',
  '39866': '2023-12-31',
  '31781': '2023-12-31',
  '31510': '2023-12-31',
  '31704': '2023-12-31',
  '39824': '2023-12-31',
  '31533': '2023-12-31',
  '31512': '2023-12-31',
  '31331': '2023-12-31',
  '39846': '2023-12-31',
  '31774': '2023-12-31',
  '31702': '2023-12-31',
  '31703': '2023-12-31',
  '31706': '2023-12-31',
  '31708': '2023-12-31',
  '31701': '2023-12-31',
  '31707': '2023-12-31',
  '31733': '2023-12-31',
  '31545': '2023-12-31',
  '31599': '2023-12-31',
  '31598': '2023-12-31',
  '31567': '2023-12-31',
  '31769': '2023-12-31',
  '31760': '2023-12-31',
  '31798': '2023-12-31',
  '31790': '2023-12-31',
  '39836': '2023-12-31',
  '31519': '2023-12-31',
  '31555': '2023-12-31',
  '31714': '2023-12-31',
  '31323': '2023-12-31',
  '31563': '2023-12-31',
  '39885': '2023-12-31',
  '31320': '2023-12-31',
  '31532': '2023-12-31',
  '39886': '2023-12-31',
  '31316': '2023-12-31',
  '31750': '2023-12-31',
  '31763': '2023-12-31',
  '31772': '2023-12-31',
  '31796': '2023-12-31',
  '31301': '2023-12-31',
  '31513': '2023-12-31',
  '31783': '2023-12-31',
  '31515': '2023-12-31',
  '31333': '2023-12-31',
  '39840': '2023-12-31',
  '39842': '2023-12-31',
  '39826': '2023-12-31',
  '31539': '2023-12-31',
  '31313': '2023-12-31',
  '31712': '2023-12-31',
  '31310': '2023-12-31',
  '31544': '2023-12-31',
  '31324': '2023-12-31',
  '31309': '2023-12-31',
  '31315': '2023-12-31',
  '31787': '2023-12-31',
  '31549': '2023-12-31',
  '31419': '2023-12-31',
  '39877': '2023-12-31',
  '31743': '2023-12-31',
  '30427': '2023-12-31',
  '31411': '2023-12-31',
  '31015': '2023-12-31',
  '31072': '2023-12-31',
  '31079': '2023-12-31',
  '31060': '2023-12-31',
  '31084': '2023-12-31',
  '31010': '2023-12-31',
  '31764': '2023-12-31',
  '31406': '2023-12-31',
  '31001': '2023-12-31',
  '31735': '2023-12-31',
  '31832': '2023-12-31',
  '31055': '2023-12-31',
  '31314': '2023-12-31',
  '31077': '2023-12-31',
  '31328': '2023-12-31',
  '31780': '2023-12-31',
  '31709': '2023-12-31',
  '31409': '2023-12-31',
  '31410': '2023-12-31',
  '30453': '2023-12-31',
  '30473': '2023-12-31',
  '31083': '2023-12-31',
  '31405': '2023-12-31',
  '31815': '2023-12-31',
  '31404': '2023-12-31',
  '31719': '2023-12-31',
  '31401': '2023-12-31',
  '31825': '2023-12-31',
  '31421': '2023-12-31',
  '31415': '2023-12-31',
  '31402': '2023-12-31',
  '31403': '2023-12-31',
  '31412': '2023-12-31',
  '31414': '2023-12-31',
  '31416': '2023-12-31',
  '31420': '2023-12-31',
  '30499': '2023-12-31',
  '31322': '2023-12-31',
  '31071': '2023-12-31',
  '31092': '2023-12-31',
  '30438': '2023-12-31',
  '30412': '2023-12-31',
  '31824': '2023-12-31',
  '31418': '2023-12-31',
  '31408': '2023-12-31',
  '30411': '2023-12-31',
  '31011': '2023-12-31',
  '31037': '2023-12-31',
  '31302': '2023-12-31',
  '31318': '2023-12-31',
  '31821': '2023-12-31',
  '30423': '2023-12-31',
  '31051': '2023-12-31',
  '30428': '2023-12-31',
  '30429': '2023-12-31',
  '31814': '2023-12-31',
  '30436': '2023-12-31',
  '30417': '2023-12-31',
  '30414': '2023-12-31',
  '30410': '2023-12-31',
  '30445': '2023-12-31',
  '31307': '2023-12-31',
  '31007': '2023-12-31',
  '31308': '2023-12-31',
  '31407': '2023-12-31',
  '31070': '2023-12-31',
  '31711': '2023-12-31',
  '31321': '2023-12-31',
  '31023': '2023-12-31',
  '30421': '2023-12-31',
  '30474': '2023-12-31',
  '31091': '2023-12-31',
  '30475': '2023-12-31',
  '30420': '2023-12-31',
  '31009': '2023-12-31',
  '31036': '2023-12-31',
  '31326': '2023-12-31',
  '31806': '2023-12-31',
  '31805': '2023-12-31',
  '31063': '2023-12-31',
  '30470': '2023-12-31',
  '31803': '2023-12-31',
  '30452': '2023-12-31',
  '30415': '2023-12-31',
  '31075': '2023-12-31',
  '31312': '2023-12-31',
  '31068': '2023-12-31',
  '31025': '2023-12-31',
  '31041': '2023-12-31',
  '31012': '2023-12-31',
  '30451': '2023-12-31',
  '30458': '2023-12-31',
  '31905': '2023-12-31',
  '30457': '2023-12-31',
  '30439': '2023-12-31',
  '31013': '2023-12-31',
  '31903': '2023-12-31',
  '31019': '2023-12-31',
  '30464': '2023-12-31',
  '31014': '2023-12-31',
  '31329': '2023-12-31',
  '31081': '2023-12-31',
  '30460': '2023-12-31',
  '31069': '2023-12-31',
  '31057': '2023-12-31',
  '30454': '2023-12-31',
  '30459': '2023-12-31',
  '31902': '2023-12-31',
  '31908': '2023-12-31',
  '31993': '2023-12-31',
  '31995': '2023-12-31',
  '31997': '2023-12-31',
  '31998': '2023-12-31',
  '31999': '2023-12-31',
  '31047': '2023-12-31',
  '31901': '2023-12-31',
  '31906': '2023-12-31',
  '31021': '2023-12-31',
  '31907': '2023-12-31',
  '31801': '2023-12-31',
  '31058': '2023-12-31',
  '30448': '2023-12-31',
  '30447': '2023-12-31',
  '31303': '2023-12-31',
  '31005': '2023-12-31',
  '31022': '2023-12-31',
  '30461': '2023-12-31',
  '30449': '2023-12-31',
  '31076': '2023-12-31',
  '31040': '2023-12-31',
  '31909': '2023-12-31',
  '31904': '2023-12-31',
  '30471': '2023-12-31',
  '30450': '2023-12-31',
  '30401': '2023-12-31',
  '31914': '2023-12-31',
  '31917': '2023-12-31',
  '31027': '2023-12-31',
  '31810': '2023-12-31',
  '31829': '2023-12-31',
  '31065': '2023-12-31',
  '31002': '2023-12-31',
  '31030': '2023-12-31',
  '30424': '2023-12-31',
  '31088': '2023-12-31',
  '31820': '2023-12-31',
  '31039': '2023-12-31',
  '31006': '2023-12-31',
  '30446': '2023-12-31',
  '31812': '2023-12-31',
  '31098': '2023-12-31',
  '31095': '2023-12-31',
  '31099': '2023-12-31',
  '31017': '2023-12-31',
  '31028': '2023-12-31',
  '31808': '2023-12-31',
  '30425': '2023-12-31',
  '31020': '2023-12-31',
  '31008': '2023-12-31',
  '31807': '2023-12-31',
  '31093': '2023-12-31',
  '31804': '2023-12-31',
  '31044': '2023-12-31',
  '31831': '2023-12-31',
  '31003': '2023-12-31',
  '31827': '2023-12-31',
  '30455': '2023-12-31',
  '31050': '2023-12-31',
  '31078': '2023-12-31',
  '31049': '2023-12-31',
  '31096': '2023-12-31',
  '31811': '2023-12-31',
  '31216': '2023-12-31',
  '31042': '2023-12-31',
  '31052': '2023-12-31',
  '30442': '2023-12-31',
  '31826': '2023-12-31',
  '30467': '2023-12-31',
  '31066': '2023-12-31',
  '31836': '2023-12-31',
  '31206': '2023-12-31',
  '31823': '2023-12-31',
  '31217': '2023-12-31',
  '31090': '2023-12-31',
  '30441': '2023-12-31',
  '31201': '2023-12-31',
  '31207': '2023-12-31',
  '31016': '2023-12-31',
  '29056': '2023-12-31',
  '29082': '2023-12-31',
  '29114': '2023-12-31',
  '29161': '2023-12-31',
  '29401': '2023-12-31',
  '29402': '2023-12-31',
  '29403': '2023-12-31',
  '29404': '2023-12-31',
  '29405': '2023-12-31',
  '29406': '2023-12-31',
  '29407': '2023-12-31',
  '29409': '2023-12-31',
  '29410': '2023-12-31',
  '29412': '2023-12-31',
  '29413': '2023-12-31',
  '29414': '2023-12-31',
  '29415': '2023-12-31',
  '29416': '2023-12-31',
  '29417': '2023-12-31',
  '29418': '2023-12-31',
  '29419': '2023-12-31',
  '29420': '2023-12-31',
  '29422': '2023-12-31',
  '29423': '2023-12-31',
  '29424': '2023-12-31',
  '29425': '2023-12-31',
  '29426': '2023-12-31',
  '29429': '2023-12-31',
  '29431': '2023-12-31',
  '29433': '2023-12-31',
  '29434': '2023-12-31',
  '29435': '2023-12-31',
  '29436': '2023-12-31',
  '29437': '2023-12-31',
  '29438': '2023-12-31',
  '29439': '2023-12-31',
  '29440': '2023-12-31',
  '29442': '2023-12-31',
  '29445': '2023-12-31',
  '29446': '2023-12-31',
  '29447': '2023-12-31',
  '29448': '2023-12-31',
  '29449': '2023-12-31',
  '29450': '2023-12-31',
  '29451': '2023-12-31',
  '29452': '2023-12-31',
  '29453': '2023-12-31',
  '29455': '2023-12-31',
  '29456': '2023-12-31',
  '29457': '2023-12-31',
  '29458': '2023-12-31',
  '29461': '2023-12-31',
  '29464': '2023-12-31',
  '29465': '2023-12-31',
  '29466': '2023-12-31',
  '29468': '2023-12-31',
  '29469': '2023-12-31',
  '29470': '2023-12-31',
  '29471': '2023-12-31',
  '29472': '2023-12-31',
  '29474': '2023-12-31',
  '29475': '2023-12-31',
  '29476': '2023-12-31',
  '29477': '2023-12-31',
  '29479': '2023-12-31',
  '29481': '2023-12-31',
  '29482': '2023-12-31',
  '29483': '2023-12-31',
  '29484': '2023-12-31',
  '29485': '2023-12-31',
  '29486': '2023-12-31',
  '29487': '2023-12-31',
  '29488': '2023-12-31',
  '29492': '2023-12-31',
  '29493': '2023-12-31',
  '29501': '2023-12-31',
  '29502': '2023-12-31',
  '29503': '2023-12-31',
  '29504': '2023-12-31',
  '29505': '2023-12-31',
  '29506': '2023-12-31',
  '29510': '2023-12-31',
  '29511': '2023-12-31',
  '29518': '2023-12-31',
  '29519': '2023-12-31',
  '29526': '2023-12-31',
  '29527': '2023-12-31',
  '29528': '2023-12-31',
  '29530': '2023-12-31',
  '29541': '2023-12-31',
  '29544': '2023-12-31',
  '29545': '2023-12-31',
  '29546': '2023-12-31',
  '29554': '2023-12-31',
  '29555': '2023-12-31',
  '29556': '2023-12-31',
  '29560': '2023-12-31',
  '29564': '2023-12-31',
  '29566': '2023-12-31',
  '29568': '2023-12-31',
  '29569': '2023-12-31',
  '29571': '2023-12-31',
  '29572': '2023-12-31',
  '29574': '2023-12-31',
  '29575': '2023-12-31',
  '29576': '2023-12-31',
  '29577': '2023-12-31',
  '29578': '2023-12-31',
  '29579': '2023-12-31',
  '29580': '2023-12-31',
  '29581': '2023-12-31',
  '29582': '2023-12-31',
  '29583': '2023-12-31',
  '29585': '2023-12-31',
  '29587': '2023-12-31',
  '29588': '2023-12-31',
  '29589': '2023-12-31',
  '29590': '2023-12-31',
  '29591': '2023-12-31',
  '29592': '2023-12-31',
  '29597': '2023-12-31',
  '29598': '2023-12-31',
  '29901': '2023-12-31',
  '29902': '2023-12-31',
  '29903': '2023-12-31',
  '29904': '2023-12-31',
  '29905': '2023-12-31',
  '29906': '2023-12-31',
  '29907': '2023-12-31',
  '29909': '2023-12-31',
  '29910': '2023-12-31',
  '29912': '2023-12-31',
  '29914': '2023-12-31',
  '29915': '2023-12-31',
  '29920': '2023-12-31',
  '29925': '2023-12-31',
  '29926': '2023-12-31',
  '29927': '2023-12-31',
  '29928': '2023-12-31',
  '29929': '2023-12-31',
  '29931': '2023-12-31',
  '29934': '2023-12-31',
  '29935': '2023-12-31',
  '29936': '2023-12-31',
  '29938': '2023-12-31',
  '29940': '2023-12-31',
  '29941': '2023-12-31',
  '29943': '2023-12-31'
};

export const thirtySixMonthAutoMinorIncidentRenewalRulesStates = [
  'AR',
  'CO',
  'GA',
  'LA',
  'MI',
  'MT',
  'NM',
  'OK',
  'PA',
  'SC',
  'TN',
  'TX',
  'UT'
];

export const thirtySixMonthAutoNonGlassRenewalRulesStates = ['CO', 'GA', 'PA', 'SC', 'TX'];

export const twelveMonthAutoTowLaborRenewalRulesStates = ['CO', 'GA', 'SC', 'TX'];

export const compRaterBlockedZipCodes = ['00000']; // easier to have a placeholder here than nothing for TypeScript

export const xAddress = [
  {
    carrierId: '3844011',
    datasetName: 'Branch - Accuserve Exteriors',
    assignmentAddress: 'MADSKY.HOME.WEB'
  },
  {
    carrierId: '3844010',
    datasetName: 'Branch - Accuserve Interiors',
    assignmentAddress: 'CODEBLUE.HOME.WEB'
  },
  {
    carrierId: '3844030',
    datasetName: 'Branch - Alacrity',
    assignmentAddress: 'FOURSEVENTY_CLAIMS.HOME.WEB'
  },
  {
    carrierId: '3844020',
    datasetName: 'Branch - Bees360',
    assignmentAddress: 'BEES360.HOME.WEB'
  },
  {
    carrierId: '3844015',
    datasetName: 'Branch - FRSTeam',
    assignmentAddress: 'FRSTEAM.HOME.WEB'
  },
  {
    carrierId: '3844025',
    datasetName: 'Branch - Hancock Claims',
    assignmentAddress: 'HANCOCK.HOME.WEB'
  },
  {
    carrierId: '3844035',
    datasetName: 'Branch - Hancock Claims (PRN)',
    assignmentAddress: 'BRANCHCLAIMS.HOME.WEB'
  },
  {
    carrierId: '1850599',
    datasetName: 'Branch - Pilot',
    assignmentAddress: 'PILOT.HOME.WEB'
  },
  {
    carrierId: '3844005',
    datasetName: 'Branch Claims - Staff',
    assignmentAddress: 'BRANCHCLAIMS.HOME.WEB'
  },
  {
    carrierId: '3844040',
    datasetName: 'Branch - Westhill Global',
    assignmentAddress: 'WESTHILL.HOME.WEB'
  }
];

export const xactCauseOfLoss = {
  property_claim_fire: 'FIRE',
  property_claim_smoke: 'SMOKE',
  property_claim_riot_or_civil_disorder: 'RIOT',
  property_claim_robbery: 'ROBBERY',
  property_claim_theft: 'THEFT',
  property_claim_sched_prop_theft: 'SCHEDPROP',
  property_claim_vandalism: 'VANDALISM',
  property_claim_third_party_damage: 'TPDAMAGE',
  property_claim_dog_bite: 'DOGBITE',
  property_claim_injury: 'INJURY',
  property_claim_slip_and_fall: 'FALL',
  property_claim_flood: 'FLOOD',
  property_claim_freezing_water: 'FRZNWATER',
  property_claim_sewer_backup: 'SEWER',
  property_claim_water_damage: 'WATERDMG',
  property_claim_water_backup: 'WATERBUP',
  property_claim_wind: 'WIND',
  property_claim_hail: 'HAIL',
  property_claim_broken_window: 'WINDOW',
  property_claim_building_collapse: 'COLLAPSE',
  property_claim_earthquake: 'EARTHQUAKE',
  property_claim_earth_movement: 'EARTHMOVE',
  property_claim_explosion: 'EXPLOSION',
  property_claim_falling_object: 'FALLINGOB',
  property_claim_ice_damage: 'ICEDAMAGE',
  property_claim_lightning: 'LIGHTNING',
  property_claim_sink_hole: 'SINKHOLE',
  property_claim_volcano: 'VOLCANO',
  property_claim_weather: 'WEATHER',
  property_claim_weight_of_ice_and_snow: 'ICESNOW',
  property_claim_equipment_breakdown: 'EBSL',
  property_claim_service_line: 'EBSL',
  property_claim_fallen_tree: 'TREE',
  property_claim_power_surge: 'POWERSURG',
  property_claim_collision: 'COLLISION',
  property_claim_other: 'OTHER'
};

export const overrideSqFtWithCherreStates = {
  MT: true,
  KY: true,
  WI: true,
  UT: true,
  OR: true
};

export const overrideBasementSqFtWithCherreStates = {
  MT: true,
  KY: true,
  WI: true,
  UT: true,
  OR: true
};
