/* eslint-disable func-names */
import { object, boolean } from 'yup';

import { getPreSaleChecklistConditions } from '../../helper';

export default (policyType, quote, values) => {
  // Always required
  let schema = object().shape({
    authorizePayments: boolean().required().oneOf([true]),
    branchCommunicationConsent: boolean().required().oneOf([true]),
    confirmDiscounts: boolean().required().oneOf([true]),
    confirmCoverages: boolean().required().oneOf([true])
  });

  const preSaleChecklistConditions = getPreSaleChecklistConditions(quote, values, policyType);
  // Conditionally required
  Object.entries(preSaleChecklistConditions).forEach(([key, value]) => {
    if (
      ![
        'payingInFull',
        'payingMonthly',
        // nodes for trailing docs validation node, not needed for individual validation
        'hasSPP',
        'hasInventoryDiscount',
        'hasConnectedHome',
        'hasGoodStudent',
        'hasTakenDrivingCourse',
        'hasKiaOrHyundai'
      ].includes(key) &&
      value
    ) {
      schema = schema.concat(
        object().shape({
          [key]: boolean().required().oneOf([true])
        })
      );
    }
  });

  return schema;
};
