import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subSection: {
    borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`,
    padding: theme.spacing(6, 8),
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  loadingWheel: {
    boxShadow: 'none'
  },
  container: {
    position: 'relative',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    backgroundColor: theme.colors.card_background_secondary
  },
  cardHeader: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 100,
    backgroundColor: theme.colorPalette.white_10_op_10,
    padding: theme.spacing(8, 7)
  },
  iconFold: {
    display: 'block',
    height: 32,
    width: 32,
    transition: 'transform 200ms'
  },
  icon: {
    composes: '$iconFold',
    transform: 'rotate(180deg)'
  },
  connectedCard: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(16),
    padding: theme.spacing(8)
  },
  module: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.card_background_secondary
  },
  priceIncrease: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 656,
    height: 56,
    marginBottom: theme.spacing(8),
    padding: theme.spacing(4),
    backgroundColor: theme.colorPalette.green_30,
    fontSize: 14,
    fontWeight: 400,
    color: theme.colorPalette.white_10,
    borderRadius: 6
  },
  iconSection: {
    display: 'flex',
    flexDirection: 'row',
    height: 20,
    alignItems: 'center'
  },
  notificationIcon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(2)
  },
  compareSection: {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    alignItems: 'center',
    color: theme.colorPalette.orange_10,
    fontSize: 14,
    borderRadius: theme.spacing(2)
  },
  compareLabel: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    alignItems: 'center'
  },
  compareIcon: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(2)
  }
}));

export default useStyles;
