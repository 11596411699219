import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { isBefore, isAfter, subDays, parseISO } from 'date-fns';
import flowRight from 'lodash-es/flowRight';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { lookupsJson, nonRenewalNotificationByState, everspanAndSCORStatesMapToPolicyType } from '@ourbranch/lookups';
import { policyTypes as pTypes } from '@ourbranch/policy-types';
import { getTodayStr, isFutureDate } from '@ourbranch/date-helpers';

import { useStore } from 'core/store';
import FormList from 'core/components/form-list';
import { Tooltip } from 'core/components/tooltip';
import { track } from 'core/helpers/analytics';
import { AddInterestedPartyForm, AddLienHoldersForm, InterestedParty, LienHolders } from 'common/components/people';
import { CognitoPermissionGroups, PermissionRequireTypes } from 'core/helpers/cognito-permission-groups';
import { Button } from 'core/components/button';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Loading from 'core/components/loading/loading';
import withDatePicker from 'core/components/with-date-picker';
import { dateFormatter } from 'core/helpers/formatters';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { AuthContext } from 'core/components/auth';
import { useDisabledState } from 'common/disabled-context';
import { BillingDetails } from './billing-details';
import useStyles from './policy-settings.styles';

const padWithZeros = (str) => str.toString().padStart(3, 0);

function getLastModifiedBy(policy) {
  return Array.isArray(policy.versionHistory)
    ? `${dateFormatter(new Date(policy.versionHistory[policy.versionHistory.length - 1].updatedDateTime))} by ${
        policy.versionHistory[policy.versionHistory.length - 1].username
      }`
    : 'Unknown';
}

const PolicySettings = ({
  cancelPolicy,
  handleCancellation,
  changed,
  segment,
  formik,
  handleAddHoldCard,
  minDate,
  maxDate
}) => {
  const classes = useStyles();
  const {
    account: {
      policies: {
        policy: { allActiveHoldCards, policy: policyFromStore, getAllowLicensedActions }
      }
    }
  } = useStore();
  const { values: policy, setFieldValue, setFieldTouched } = formik;

  const minEndDate = new Date(formik.values.effectiveDate);
  minEndDate.setDate(new Date(formik.values.effectiveDate).getDate() + 1);

  const session = useContext(AuthContext);

  /* Only allow users in CanAutoRenew cognito group to toggle auto renew.
    If auto renew toggle is turned off, a user with the CanAutoRenew permission can turn it back on, regardless of state notification requirements.
  */
  const disableAutoRenewToggle = () => {
    if (!session.canAutoRenew) return true;
    if (everspanAndSCORStatesMapToPolicyType[policy.state]?.includes(policy.policyType) && !policy.autoRenew)
      return true;
    if (session.canAutoRenew && (!policy.autoRenew || policy?.renewalVariables?.allowForcedNonRenewal)) return false;

    const currentDate = parseISO(getTodayStr(policy.state));
    if ([pTypes.Home, pTypes.Auto].includes(policy.policyType)) {
      // give a 1-day mailing buffer
      const notificationDays = nonRenewalNotificationByState[policy.state][policy.policyType] + 1;
      if (
        isBefore(currentDate, parseISO(policy.endDate)) &&
        isAfter(currentDate, subDays(parseISO(policy.endDate), notificationDays))
      )
        return true;
    }
  };

  const { disabled, setDisabled } = useDisabledState();

  const disabledEditEffectiveDate =
    (getPolicyStatus(policy) === PolicyStatus.Future && !session.canEditEffectiveDate) ||
    policy.term >= 2 ||
    isBefore(new Date(formik.values.effectiveDate), new Date());

  /* Only disable when checkbox checked or policy loaded with those dates
    or if there are any hold cards on the policy or if the policy is cancelled
  */
  const hasHoldCards = !!allActiveHoldCards().length;
  const cancelled = new Date(policy.endDate) < new Date(policy.fullTermPolicyEndDate);
  const disabledForm = !!formik.values.cancel || (cancelled && !changed) || !session.canEditPolicies || hasHoldCards;
  const disabledCancelPolicyButton = !getAllowLicensedActions(session);

  useEffect(() => {
    setDisabled(disabledForm);
  }, [disabledForm, setDisabled]);

  const onCancel = useCallback(() => {
    setFieldValue('cancel', !policy.cancel);
    setFieldTouched('cancel');
    const policyType = lookupsJson.policyTypes.find((policyType) => policyType.id === policy.policyType);
    track('Staff Cancellation Initiated', {
      policyType: policyType.value,
      policyId: policy.id,
      isFutureEffective: isFutureDate(policy.effectiveDate)
    });
  }, [setFieldValue, policy.cancel]);

  useEffect(() => {
    if (!cancelPolicy && cancelled && !changed) {
      handleCancellation(cancelled, false);
    }
  }, [cancelPolicy, cancelled, changed, handleCancellation]);

  useEffect(() => {
    if (!formik.values.expirationDate) {
      const vals = {
        expirationDate: policy.endDate,
        lastModified: getLastModifiedBy(policy)
      };
      formik.setValues({ ...formik.values, ...vals });
    }

    if (typeof formik.values.sendPaperDocuments === 'undefined') {
      const segments = [...policy.segments].reverse();
      const segmentWithDiscountInfo = segments.find((item) => typeof item?.global?.discountPaperless === 'boolean');
      const sendPaperDocuments = !segmentWithDiscountInfo.global.discountPaperless;

      formik.setValues({ ...formik.values, sendPaperDocuments });
    }
  }, [formik, policy, disabled]);

  const endDateDisabled = useCallback(
    (effectiveDate) => {
      if (session.canBackDate) return false;
      const currentDateTime = new Date(new Date().toISOString().slice(0, 10)).getTime();
      const effectiveDateTime = new Date(effectiveDate).getTime();
      if (effectiveDateTime >= currentDateTime) {
        return false;
      }
      return true;
    },
    [session.canBackDate]
  );

  if (!segment || !formik.values.expirationDate) {
    return <Loading type="secondary" />;
  }
  return (
    <>
      <Card key={`settings-${disabled}`} type="secondary" className={classes.card}>
        {formik.values.expirationDate && (
          <>
            <Grid container justifyContent="space-around" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} key="labelPolicyTerm">
                <Label key="lblTop" type="formSubTitle" className={classes.label}>
                  Policy Term
                </Label>
              </Grid>
              <Grid
                container
                key="row1"
                justifyContent={policy.effectiveDate !== policy.endDate ? 'space-between' : 'start'}
                spacing={2}
                className={classes.row}
              >
                <FormField
                  name="effectiveDate"
                  type="date"
                  label="Start Date"
                  mode="dark"
                  xs={3}
                  disabled={disabledEditEffectiveDate}
                  disableFuture={false}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                {policy.effectiveDate !== policy.endDate && (
                  <FormField
                    name="endDate"
                    type="date"
                    label="Last Full Day Of Coverage"
                    mode="dark"
                    minDate={minEndDate}
                    xs={3}
                    disabled={endDateDisabled(policy.effectiveDate)}
                    disableFuture={false}
                    fast={false}
                  />
                )}
                <FormField
                  name="rewriteReason"
                  type="select"
                  label="Rewrite"
                  mode="dark"
                  xs={6}
                  options={lookupsJson.rewriteReason}
                />
              </Grid>

              <BillingDetails
                paymentType={policyFromStore.paymentType}
                policyType={policyFromStore.policyType}
                state={policyFromStore.state}
                isCancelled={cancelled}
              />
              <Grid item xs={12} key="labelPolicyInformation">
                <Label key="lblPolicyInformation" type="formSubTitle" className={classes.label}>
                  Policy Information
                </Label>
              </Grid>
              <Grid container key="policyInformation" className={classes.row} justifyContent="space-between">
                <FormField name="state" type="value" label="State" mode="dark" xs={4} />
                <FormField name="term" type="value" label="Term" mode="dark" xs={1} formatter={padWithZeros} />
                <FormField name="version" type="value" label="Version" mode="dark" xs={2} formatter={padWithZeros} />
                <FormField name="lastModified" type="value" label="Last Modified" mode="dark" xs={5} />
              </Grid>
              <Grid container key="policyOfferInformation" className={classes.row}>
                <FormField
                  name="salesRep"
                  type="value"
                  label="Producer"
                  mode="dark"
                  xs={4}
                  formatter={(producer) => producer || 'N/A'}
                />
                <FormField
                  name="offer.quote.leadSource"
                  type="select"
                  label="Lead Source"
                  mode="dark"
                  options={lookupsJson.leadSources}
                  xs={4}
                />
              </Grid>
              <Grid item xs={12} key="labelPolicyActions">
                <Label key="lblPolicyActions" type="formSubTitle" className={classes.labelWithDivider}>
                  Policy Actions
                </Label>
              </Grid>
              <Grid container key="policyActions" justifyContent="space-between" className={classes.row}>
                <Grid container item xs={8} key="policyActionsLeft">
                  <FormField
                    xs={12}
                    type="switch"
                    name="autoRenew"
                    label="Auto Renew"
                    mode="dark"
                    className={classes.toggleRow}
                    disabled={disableAutoRenewToggle()}
                    permissions={{
                      isLicensedAction: false
                    }}
                    onChange={(_, val) => {
                      setFieldValue('nonRenewed', !val);
                      if (!val) {
                        // only change if turning off auto renew
                        setFieldValue('isNonRenew', true);
                      }
                    }}
                  />
                  <FormField
                    xs={12}
                    type="switch"
                    name="renewalCreditPull"
                    label="Run Credit/Insurance Score at Renewal"
                    mode="dark"
                    className={classes.toggleRow}
                    permissions={{
                      edit: { groups: [CognitoPermissionGroups.isService] }
                    }}
                  />
                  {!session.isAgency && (
                    <FormField
                      xs={12}
                      type="switch"
                      name="sendPaperDocuments"
                      label="Send Paper Documents"
                      mode="dark"
                      className={classes.toggleRow}
                      permissions={{
                        edit: {
                          groups: [CognitoPermissionGroups.canSendPaperDocs]
                        }
                      }}
                      tooltipLabel="More Info"
                      tooltipText={
                        !session.canSendPaperDocs
                          ? 'Please follow your designated process to perform this action.'
                          : null
                      }
                    />
                  )}
                  <FormField
                    xs={12}
                    type="switch"
                    name="blockCreditCheckAtRenewal"
                    label="Block Credit Check at Renewal for Hardship"
                    mode="dark"
                    className={classes.toggleRow}
                    permissions={{
                      edit: {
                        groups: [CognitoPermissionGroups.isInternalAgent, CognitoPermissionGroups.canToggleCreditCheck],
                        type: PermissionRequireTypes.All
                      }
                    }}
                  />
                  {session.canToggleIsAgencyPolicy && (
                    <FormField
                      xs={12}
                      type="switch"
                      name="isAgentSold"
                      label="Agency Sold Policy"
                      mode="dark"
                      className={classes.toggleRow}
                      permissions={{
                        edit: {
                          groups: [CognitoPermissionGroups.canToggleIsAgencyPolicy],
                          type: PermissionRequireTypes.All
                        }
                      }}
                    />
                  )}
                </Grid>
                <Grid container item xs={4} key="policyActionsRight" justifyContent="flex-end">
                  <Button className={classes.button} disabled={!session?.canAddHoldCards} onClick={handleAddHoldCard}>
                    Add Hold Card
                  </Button>
                  {!cancelled && (
                    <div>
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={onCancel}
                        className={classes.secondaryButton}
                        disabled={disabledCancelPolicyButton}
                      >
                        Cancel Policy
                      </Button>
                      {disabledCancelPolicyButton && (
                        <Tooltip
                          className={classes.holdPaymentWarning}
                          text="You must be licensed in this policy's state to perform this action."
                          placement="bottom"
                          label="More Info"
                        />
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      {policy.policyType === pTypes.Home ? (
        <FormList
          key={`parties-${disabled}`}
          id="additionalParties"
          item={InterestedParty}
          addForm={AddInterestedPartyForm}
          title="Additional interested party"
          addLabel="Add People"
          disabled={disabled || session.viewOnly}
        />
      ) : (
        <FormList
          key={`lien-holders-${disabled}`}
          id="additionalParties"
          item={LienHolders}
          addForm={AddLienHoldersForm}
          title="Interested Party or Lienholder"
          addLabel="Add Lien Holder"
          disabled={disabled || session.viewOnly}
          singular="Entity"
          plural="Entities"
        />
      )}
    </>
  );
};

PolicySettings.propTypes = {
  cancelPolicy: PropTypes.bool.isRequired,
  handleCancellation: PropTypes.func.isRequired,
  changed: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  policy: PropTypes.shape({
    effectiveDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    billingDayOfMonth: PropTypes.number.isRequired,
    autoRenew: PropTypes.bool,
    state: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired
  }).isRequired,
  segment: PropTypes.object,
  handleAddHoldCard: PropTypes.func.isRequired
};

PolicySettings.defaultProps = {
  segment: null
};

export default flowRight(withDatePicker, connect, observer)(PolicySettings);
