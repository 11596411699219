import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import { NotificationCard } from 'core/components/notification-card';
import { Button } from 'core/components/button';
import alertIcon from 'core/assets/svg/alert-white-filled.svg';
import { MortgageRadioGroup } from './mortgage-radio-group';
import { ModalHeader } from './modal-header';
import useStyles from './custom-lender-modal.styles';

const CustomLenderModal = ({ open, onClose, existingMortgages, customMortgage, onConfirm }) => {
  const classes = useStyles();
  const toast = useToast();
  const [selectedLender, setSelectedLender] = useState(null);
  const [error, setError] = useState(false);
  const optionsToDisplay = existingMortgages.concat([{ ...customMortgage, isCustom: true }]);

  const handleSelectedLender = (lenderIndex) => {
    setError(false);
    setSelectedLender({ ...optionsToDisplay[lenderIndex], index: lenderIndex });
  };

  const handleConfirm = useCallback(() => {
    if (selectedLender) {
      onConfirm(selectedLender);
    } else {
      setError(true);
      toast.notify({
        type: 'error',
        message: 'Please select a lender before proceeding.'
      });
    }
  }, [selectedLender, onConfirm, toast]);

  return (
    <BaseDialog open={open} onClose={onClose} className={classes.modal} title={<ModalHeader />}>
      <div className={classes.content}>
        <MortgageRadioGroup
          name="selectedLender"
          error={error}
          options={optionsToDisplay}
          onChange={handleSelectedLender}
        />
        <NotificationCard type="light" icon={alertIcon} className={classes.noteCard}>
          <div className={classes.noteText}>
            <Label type="body2">
              If the lender you added matches an existing lender, please choose the closest existing lender for accurate
              processing.
            </Label>
          </div>
        </NotificationCard>
      </div>
      <Button className={classes.button} onClick={handleConfirm} variant="contained" color="primary">
        Confirm selection
      </Button>
    </BaseDialog>
  );
};

CustomLenderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  existingMortgages: PropTypes.array.isRequired,
  customMortgage: PropTypes.object.isRequired
};

export default CustomLenderModal;
