import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { sub, isToday, add, format, isBefore } from 'date-fns';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { useToast } from 'core/components/toast';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import { awsDateFormatter } from 'core/helpers/formatters';
import { ActionButton } from 'core/components/action-button';
import { NotificationCard } from 'core/components/notification-card';
import { localToUtcTime } from '../../dates';
import useStyles from './hold-payment-form.styles';

const HoldPaymentForm = ({ close }) => {
  const classes = useStyles();
  const { setFieldValue, values, initialValues } = useFormikContext();
  const {
    account: {
      policies: {
        policy: { policy, setChanged, billingDetails, hasBillingHold }
      }
    }
  } = useStore();
  const toast = useToast();

  return (
    <Grid container className={classes.container}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Label type="coverageValue">Place a billing hold</Label>
        <ActionButton
          type="close"
          alt="close form"
          onClick={() => {
            setFieldValue('billingHoldUntil', initialValues.billingHoldUntil);
            close();
            setChanged(false);
          }}
          className={classes.closeButton}
        />
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid container alignItems="center" item xs={10}>
          <FormField
            name="billingHoldUntil"
            type="date"
            label="Hold Payment Until"
            disableFuture={false}
            maxDate={values.maxBillingHoldDate}
            mode="dark"
            xs={4}
            permissions={{ isLicensedAction: false }}
            value={values.billingHoldUntil || billingDetails?.nextPaymentDate}
            fast={false}
          />
          <FormField
            name="memberPaidEarly"
            type="checkbox"
            label="I am placing this billing hold because this member paid their full next monthly installment early."
            xs={8}
            permissions={{ isLicensedAction: false }}
            onChange={(e) => {
              if (e.target.checked) {
                const billingDayOfMonth = policy.billingDayOfMonth;
                const thisMonthsBillingDate = new Date().setDate(billingDayOfMonth);
                const thisMonthsBillingDateHasPassed = isBefore(thisMonthsBillingDate, new Date());

                const nextDate = add(thisMonthsBillingDate, { months: thisMonthsBillingDateHasPassed ? 2 : 1 });
                toast.notify({
                  type: 'success',
                  message: `Available billing hold dates extended to: ${format(nextDate, 'MM/dd/yyyy')}`
                });
                setFieldValue('billingHoldUntil', format(nextDate, 'yyyy-MM-dd'));
                setFieldValue('maxBillingHoldDate', format(nextDate, 'yyyy-MM-dd'));
              } else {
                setFieldValue('billingHoldUntil', initialValues.billingHoldUntil);
                setFieldValue('maxBillingHoldDate', initialValues.maxBillingHoldDate);
              }
            }}
          />
        </Grid>
      </Grid>

      {values.memberPaidEarly && (
        <NotificationCard type="quaternary">
          <Label className={classes.notificationCardBody}>
            This checkbox will extend the available billing hold dates by one month to accommodate early installment
            payments. It should not be used unless the account is paid a full month ahead. Please contact your
            Effectiveness Coach if you have additional questions.
          </Label>
        </NotificationCard>
      )}
      <Grid container className={classes.submit} justifyContent="flex-end">
        <Button
          onClick={() => {
            setChanged(true);
          }}
          variant="contained"
          color="secondary"
          className={classes.button}
          xs={3}
          disabled={hasBillingHold && initialValues.billingHoldUntil === values.billingHoldUntil}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            /* If there is no billing hold then the default value for this field should be null, or if it must be a date,
              it should be one month before the effective date of the policy, so it doesn't block billing.
              (We want to bill renewals before they start so it shouldn't be the effective date). */
            const oneMonthBeforeEffective = sub(new Date(policy.effectiveDate), { months: 1 });
            setFieldValue('billingHoldUntil', awsDateFormatter(oneMonthBeforeEffective));
            setChanged(true);
          }}
          variant="contained"
          color="secondary"
          className={classes.button}
          xs={3}
          disabled={!hasBillingHold || isToday(localToUtcTime(policy.billingHoldUntil, policy.state))}
        >
          Cancel Hold
        </Button>
      </Grid>
    </Grid>
  );
};

HoldPaymentForm.propTypes = {
  close: PropTypes.func.isRequired
};

export default flowRight(withDatePicker, observer)(HoldPaymentForm);
