import React, { useCallback, useRef, useEffect, useState, useContext } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { rejectionTypeToPolicyTypeMap } from '@ourbranch/lookups';

import { messages } from 'common/constants/error-messages';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { Loading } from 'core/components/loading';
import { useQueryParams } from 'core/hooks/useQueryParams';
import { phoneNumberFormatter } from 'core/helpers/formatters';
import { AuthContext } from 'core/components/auth';
import { getNestedErrors } from 'quote/helpers/get-nested-errors';
import { updateUserInputWithConnectedHomeValues } from 'quote/helpers/get-connected-home-new-sign-up-values';
import { AddCar } from '../add-car';
import { ApplicantDetails } from '../applicant-details';
import { PersonalDetails } from '../personal-details';
import { AutoDetails } from '../auto-details';
import { Footer } from '../footer';
import { PriorAddress } from '../prior-address';
import { PropertyDetail } from '../property-detail';
import { Disclaimer } from '../disclaimer';
import { getInitialValues } from './initial-values';
import { visibleSections, handleCleanUp } from './quote-forms.clean';
import { validateQuoteForm } from './quote-forms.validations';
import useStyles from './quote-forms.styles';

const ScrollToError = ({ quoteErrors, sectionsToShow, setSectionsToShow, showAllSectionsClicked }) => {
  const { errors, isSubmitting, setFieldTouched, setFieldValue, values } = useFormikContext();
  const { isNewConstruction, isNewPurchase } = values;

  useEffect(() => {
    if (!isSubmitting) {
      handleCleanUp(
        quoteErrors,
        sectionsToShow,
        isNewConstruction,
        isNewPurchase,
        setFieldValue,
        setSectionsToShow,
        showAllSectionsClicked
      );
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (Object.keys(errors).length && isSubmitting) {
      const smoothScrollToElementById = (id) =>
        document.getElementById(id).scrollIntoView({ block: 'center', behavior: 'smooth' });
      const errorPaths = getNestedErrors(errors);
      // touch all nested paths
      for (const path of errorPaths) {
        path.includes('.') && setFieldTouched(path);
      }
      try {
        if (errorPaths?.length > 1 && errorPaths[0] === 'fcraDisclaimer') {
          smoothScrollToElementById(errorPaths[1]);
        } else {
          smoothScrollToElementById(errorPaths[0]);
        }
      } catch (e) {
        // something went wrong just scroll to top
        smoothScrollToElementById('firstName');
      }
    }
  }, [errors, isSubmitting, setFieldTouched]);
  return null;
};

const formatAdditionalPhoneNumbers = (additionalPhoneNumbers) => {
  return additionalPhoneNumbers
    .filter((phoneDetail) => phoneDetail.phoneNumber)
    .map((phoneDetail) => {
      return {
        phoneNumber: phoneNumberFormatter({ phoneNumber: phoneDetail.phoneNumber }),
        note: phoneDetail?.note ? phoneDetail.note.trim() : '',
        canText: phoneDetail.canText
      };
    });
};

const QuoteForms = () => {
  const store = useStore();
  const {
    quote: {
      requestQuoteAction,
      getOfferDetails,
      clearOffer,
      errors,
      offer,
      loading,
      rejections,
      prefillData,
      fetchAffinityData,
      getBlocks
    },
    affinityLookups
  } = store;

  const blocks = getBlocks();
  const history = useHistory();
  const classes = useStyles({ rejections: rejections.length, blocks: blocks.length });
  const form = useRef(null);
  const params = useQueryParams();

  const { allowedStates, user, isAgency, canViewSoftRejections } = useContext(AuthContext);

  // these states added to remotely collapse / expand two of the collapsible cards based on the
  // value of isNewConstruction or isNewPurchase( only uses Prior Address)
  const [showPriorAddressCallout, setShowPriorAddressCallout] = useState(false);
  const [showPropertyDetailsCallout, setShowPropertyDetailsCallout] = useState(false);
  const [sectionsToShow, setSectionsToShow] = useState([]);
  const [showAllSectionsClicked, setShowAllSectionsClicked] = useState(false);
  const [hasDisabledAffinity, setHasDisabledAffinity] = useState(false);
  const [requestedPolicyType, setRequestedPolicyType] = useState(null);
  // we need to track in a state if we already tried to load affinities,
  // this is because to we don't know if we already triggered to load and if the loading failed
  const state = offer?.quote?.correctedAddress?.state;

  useEffect(() => {
    if (params.affinity && !affinityLookups.loading) {
      affinityLookups.getByAffinity(params.affinity);
    }
  }, [affinityLookups, params]);

  const handleSubmit = useCallback(
    async (values) => {
      setRequestedPolicyType(values?.policyType);
      const affinityData = affinityLookups.data?.find(({ affinity }) => affinity === values.affinity);

      // send in values with connected home toggled on for affinity codes that are home security partners
      if (affinityData?.data.homeSecurity) {
        values = updateUserInputWithConnectedHomeValues(values, affinityData);
      }

      if (values.additionalPhoneNumbers?.length) {
        values.additionalPhoneNumbers = formatAdditionalPhoneNumbers(values.additionalPhoneNumbers);
      }

      if (values.phone?.length) {
        values.phone = phoneNumberFormatter({ phoneNumber: values.phone.toString() });
      }

      values.isApartment = Boolean(values.policyType?.includes('R'));

      await requestQuoteAction(values, allowedStates);
      setSectionsToShow(
        visibleSections({
          errors,
          isNewConstruction: values.isNewConstruction,
          isNewPurchase: values.isNewPurchase,
          dirtySections: sectionsToShow,
          showAllFieldsClicked: showAllSectionsClicked
        })
      );

      // logic to redirect to modify offer page if successful offer received
      const { offer, alerts, blocks, rejections } = getOfferDetails();
      if (!canViewSoftRejections) {
        return;
      }
      if (!offer?.options?.length > 0) {
        return;
      }
      if (alerts.length > 0) {
        return;
      }
      if (blocks.length > 0) {
        return;
      }
      // if any of the rejections have a type that rejects our chosen policyType, then we should not push to offer
      const requestedPolicyTypeIsRejected = rejections.some((rejection) => {
        return rejectionTypeToPolicyTypeMap[rejection.type].some((type) => type === values.policyType);
      });
      if (requestedPolicyTypeIsRejected) {
        return;
      }
      history.push(`/offer/${offer.id}`);
    },
    [requestQuoteAction, errors, sectionsToShow, showAllSectionsClicked, allowedStates, affinityLookups]
  );

  const validate = useCallback(
    (values) => {
      return validateQuoteForm(
        errors.map(({ code }) => Number(code)),
        values,
        allowedStates,
        isAgency,
        affinityLookups
      )
        .then(() => null)
        .catch((e) => {
          // log so we can quickly see validation issues in production
          // eslint-disable-next-line no-console
          console.log({ FormikErrors: e });
          return e;
        });
    },
    [errors, allowedStates, isAgency, affinityLookups]
  );

  const prefill = prefillData || params;
  const initialValues = getInitialValues(prefill, user, requestedPolicyType);

  useEffect(() => {
    const affinityData = affinityLookups.data?.find(({ affinity }) => affinity === user.affinityCode);

    if (affinityData?.disabled) {
      setHasDisabledAffinity(true);
    }
  }, [affinityLookups.data]);

  useEffect(() => {
    fetchAffinityData(user.affinityCode);
  }, []);

  return (
    <div
      className={classNames(classes.container, {
        [classes.newContainer]: canViewSoftRejections,
        [classes.withRejections]: rejections.length > 0 || blocks.length > 0
      })}
    >
      <div className={classes.row}>
        <Label type="title" className={classes.title}>
          Create a New Offer
        </Label>
        {!!offer && (
          <Button
            className={classes.floatingButton}
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => clearOffer()}
          >
            Clear this offer
          </Button>
        )}
      </div>
      {affinityLookups.loading ? (
        <Loading noBackground />
      ) : (
        <Formik
          innerRef={form}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
        >
          {({ errors: formikErrors }) => (
            <>
              <ApplicantDetails
                setShowPriorAddressCallout={setShowPriorAddressCallout}
                setShowPropertyDetailsCallout={setShowPropertyDetailsCallout}
                sectionsToShow={sectionsToShow}
                setSectionsToShow={setSectionsToShow}
                showAllSectionsClicked={showAllSectionsClicked}
                setShowAllSectionsClicked={setShowAllSectionsClicked}
              />
              <PersonalDetails sectionsToShow={sectionsToShow} />
              <PriorAddress showPriorAddressCallout={showPriorAddressCallout} sectionsToShow={sectionsToShow} />
              <AutoDetails sectionsToShow={sectionsToShow} />
              <AddCar sectionsToShow={sectionsToShow} />
              <PropertyDetail showPropertyDetailsCallout={showPropertyDetailsCallout} sectionsToShow={sectionsToShow} />
              <Disclaimer />
              {!canViewSoftRejections && (
                <Form>
                  <Button
                    loading={loading}
                    disabled={Boolean(
                      loading || affinityLookups.loading || (formikErrors.affinity && isAgency) || hasDisabledAffinity
                    )}
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    See Price
                  </Button>
                  {formikErrors.affinity && isAgency && (
                    <Label type="largeWarning" className={classes.warning}>
                      {formikErrors.affinity}
                    </Label>
                  )}
                  {hasDisabledAffinity && (
                    <Label type="largeWarning" className={classes.warning}>
                      {messages(state)[5028].message}
                    </Label>
                  )}
                </Form>
              )}
              <ScrollToError
                quoteErrors={errors}
                sectionsToShow={sectionsToShow}
                setSectionsToShow={setSectionsToShow}
                showAllSectionsClicked={showAllSectionsClicked}
              />
              <Footer
                disableButton={Boolean(
                  loading || affinityLookups.loading || (formikErrors.affinity && isAgency) || hasDisabledAffinity
                )}
              />
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default observer(QuoteForms);
