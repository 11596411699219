import React from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

import awsConfig from 'aws-exports';
import useStyles from './amplify-auth-widget.styles';

import '@aws-amplify/ui-react/styles.css';
import './amplify-styles-override.css';

const AmplifyAuthWidget = () => {
  const classes = useStyles();
  return (
    <Authenticator.Provider>
      <Authenticator
        amplifyConfig={awsConfig}
        components={{
          SignIn: {
            Header() {
              return (
                <div className="header">
                  <h3 className="amplify-heading amplify-heading--3">Sign in to your account</h3>
                </div>
              );
            },
            Footer() {
              const { toResetPassword } = useAuthenticator();
              return (
                <div className={classes.amplifyWidgetFooter}>
                  <button className="resetPasswordBtn" onClick={toResetPassword}>
                    Forgot your password?
                  </button>
                </div>
              );
            }
          }
        }}
        hideSignUp
        formFields={{
          signIn: {
            username: {
              label: 'Email *',
              isRequired: true,
              placeholder: 'Enter your email'
            },
            password: {
              label: 'Password *',
              placeholder: 'Enter your password',
              isRequired: true
            }
          },
          resetPassword: {
            username: {
              label: 'Email *',
              isRequired: true
            }
          }
        }}
      />
    </Authenticator.Provider>
  );
};

export default AmplifyAuthWidget;
