import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Label } from 'core';
import { Button } from 'core/components/button';
import { useStore } from 'core/store';
import { validationSchema } from './footer.validation';
import useStyles from './footer.styles';

const FooterRight = ({ disabled }) => {
  const classes = useStyles();
  const {
    quote: { offer, loading }
  } = useStore();
  const staffHref = useMemo(() => {
    if (offer) {
      return offer.id;
    }
  }, [offer]);
  return (
    <Formik
      initialValues={{
        ...offer.quote,
        email: offer.quote.email || ''
      }}
      validationSchema={validationSchema}
    >
      <div className={classes.footerRightContainer}>
        <Button
          target="_blank"
          href={`/offer/${staffHref}`}
          disabled={disabled || loading}
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.btn}
        >
          <Label className={classes.btnLabel}>Go to offer</Label>
        </Button>
      </div>
    </Formik>
  );
};

FooterRight.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loadingEmail: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default FooterRight;
