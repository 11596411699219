import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { InfoField } from '../info-field';
import { Loader } from '../loader';
import useStyles from './service-instructions.styles';

const ServiceInstructions = ({ isLoading, policy }) => {
  const classes = useStyles();
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && policy && (
        <Section title="Service Instructions" type="SubSection">
          <Card className={classes.container}>
            <InfoField
              label="Notes"
              value={
                policy.carrier.serviceInstructions?.length ? (
                  <div className={classes.instructions}>
                    <RichText render={policy.carrier.serviceInstructions} />
                  </div>
                ) : null
              }
            />
          </Card>
        </Section>
      )}
    </>
  );
};

ServiceInstructions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  policy: PropTypes.object
};

ServiceInstructions.defaultProps = {
  policy: null
};

export default ServiceInstructions;
