import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { getValue } from '@ourbranch/lookups';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { Label } from 'core';
import { useToast } from 'core/components/toast';
import { currencyFormatter, awsDateToDateFormatter } from 'core/helpers/formatters';
import Field from 'core/components/form/form.v2';
import useStyles from '../payment.styles';

const validationSchema = () =>
  Yup.object().shape({
    chargeId: Yup.string().nullable().required('Please select the original charge'),
    amount: Yup.number().nullable().required('Please enter the refund amount')
  });

// we need to get custom labels to show what type of payment method was used
// without the fancy formatting of the other "getBillingPaymentMethod"
const getBillingPaymentMethod = (transaction) => {
  const { paymentMethodBrand: brand, paymentMethodLast4: last4, paymentMethod } = transaction;
  if (!paymentMethod && !brand && !last4) {
    return 'Unknown';
  }
  if (paymentMethod === paymentMethod.Escrow) {
    return getValue('homeownersPaymentMethod', paymentMethod) || getValue('condoPaymentMethod', paymentMethod);
  }
  if (paymentMethod === 'Check') {
    return paymentMethod;
  }
  if (!brand && last4) {
    return `ACH/ETF ${last4}`;
  }
  return `${brand ? `${brand} ` : ''}${last4}`;
};

const STRIPE_REFUND_CHARGE = gql`
  mutation stripeRefundCharge($chargeId: String!, $amount: Int!) {
    stripeRefundCharge(chargeId: $chargeId, amount: $amount) {
      chargeId
      amount
      refundDate
      status
      failureReason
    }
  }
`;

const RefundCharge = ({ transactions }) => {
  const classes = useStyles();
  const toast = useToast();

  const [refundCharge, { loading }] = useMutation(STRIPE_REFUND_CHARGE);

  const onSubmit = async (values, { setErrors }) => {
    // get the data of the selected transaction
    const selectedTransaction = transactions.find((transaction) => transaction.id === values.chargeId);

    // check to make sure we aren't refunding more that the customer has paid
    if (values.amount + (selectedTransaction?.paymentRefunded || 0) > selectedTransaction.paymentAmount) {
      setErrors({ amount: 'Refund amount cannot exceed the original charge' });
      return;
    }

    // refund amount needs to be provided in pennies
    const refundAmount = (values.amount * 100).toFixed(0);
    const { data, error } = await refundCharge({
      variables: { chargeId: values.chargeId, amount: refundAmount }
    });

    // on success show success toast
    const { stripeRefundCharge } = data;

    switch (stripeRefundCharge.status) {
      case 'succeeded':
        toast.notify({
          type: 'success',
          message: 'Charge successfully refunded.'
        });
        break;
      case 'pending':
        toast.notify({
          type: 'success',
          message: 'Refund pending.'
        });
        break;
      default:
        toast.notify({
          type: 'error',
          message: `Unable to process refund. If the payment method is blocked or refunding an ACH payment older than 180 days, refund by check. Refund Status: ${
            stripeRefundCharge.status
          } ${stripeRefundCharge?.failureReason ? `, Reason for failure: ${stripeRefundCharge?.failureReason}` : ''}`
        });
        break;
    }

    // if there was an error, throw a different flag so they know it was an error and to contact support
    if (error) {
      toast.notify({
        type: 'error',
        message: 'An error occurred while processing the refund. Please try again, or contact support.'
      });
    }
  };

  // filter our list of transactions to only show the ones that are refundable
  // go ahead and format it into an object we can use for the select options
  const refundableTransactionOptions = transactions
    .filter((transaction) => transaction.paymentStatus === 'paid' || transaction.paymentStatus === 'partially refunded')
    .map((transaction) => {
      return {
        id: transaction.id,
        value: `${awsDateToDateFormatter(transaction.paymentDate)} - ${currencyFormatter(
          transaction.paymentAmount
        )} - ${getBillingPaymentMethod(transaction)}`
      };
    });

  return (
    <div className={classes.refundSectionContainer}>
      <Grid container alignItems="center" justifyContent="flex-start" className={classes.rowTitle}>
        <Label type="coverageValue">Refund charge</Label>
      </Grid>
      <Formik
        initialValues={{ chargeId: null, amount: null }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Grid container key="row1" spacing={4} className={classes.paymentRow}>
              <Field
                name="chargeId"
                type="select"
                label="Original Charge"
                options={refundableTransactionOptions}
                mode="dark"
                xs={5}
                ignoreGlobalDisabledState
              />
              <Field
                name="amount"
                type="numeric"
                label="Amount"
                xs={4}
                format={{
                  thousandSeparator: true,
                  prefix: '$',
                  allowNegative: false,
                  fixedDecimalScale: true,
                  decimalScale: 2
                }}
                ignoreGlobalDisabledState
              />
              <Button
                variant="contained"
                color="secondary"
                className={classes.refundButton}
                disabled={loading}
                onClick={handleSubmit}
              >
                Refund charge
              </Button>
            </Grid>
          );
        }}
      </Formik>
    </div>
  );
};

RefundCharge.propTypes = {
  transactions: PropTypes.array.isRequired
};

export default RefundCharge;
