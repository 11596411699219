import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  paperScrollPaper: {
    display: 'flex',
    width: 1280,
    maxWidth: 'unset',
    padding: theme.spacing(5),
    backgroundColor: theme.colors.form__background_primary,
    flexDirection: 'column'
  },
  loading: {
    display: 'flex',
    width: 1280,
    minHeight: 785,
    maxWidth: 'unset',
    padding: theme.spacing(5),
    backgroundColor: theme.colors.form__background_primary,
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1
  },
  spinner: {
    width: 96,
    height: 96,
    marginBottom: 20
  },
  spinnerText: { fontWeight: 400, fontSize: 14, lineHeight: '20px', color: theme.colorPalette.green_100 },
  title: {
    color: theme.colorPalette.green_75,
    fontSize: 40,
    fontWeight: 300,
    padding: 0
  },
  subtitle: {
    paddingLeft: theme.spacing(6),
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.colorPalette.green_15
  },
  bundleRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(6),
    gap: theme.spacing(8)
  },
  bundle: {
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: theme.colorPalette.white_10,
    borderRadius: '6px',
    padding: theme.spacing(6)
  },
  bundleTitle: {
    color: theme.colorPalette.green_20,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '32px',
    marginBottom: theme.spacing(2)
  },
  bundleSubtitle: {
    color: theme.colorPalette.green_20,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    height: 48,
    marginBottom: theme.spacing(4)
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing(4)
  },
  featuresList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(6),
    fontSize: 14,
    lineHeight: '20px'
  },
  featureHeader: {
    color: theme.colorPalette.green_90,
    fontWeight: 600,
    marginBottom: theme.spacing(2)
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorPalette.green_100,
    fontWeight: 400,
    marginTop: theme.spacing(2)
  },
  checkmark: {
    marginRight: theme.spacing(1)
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colorPalette.beige_10,
    margin: theme.spacing(2, 0, 0, 0)
  },
  disclaimer: {
    padding: theme.spacing(0, 6),
    alignItems: 'flex-start'
  },
  alertIcon: { margin: theme.spacing(1, 2, 0, 0) },
  disclaimerColumn: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: theme.colorPalette.green_15
  },
  bold: {
    fontWeight: 600
  }
});

export default makeStyles(styles);
