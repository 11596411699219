import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: `2px solid ${theme.colorPalette.white_10}`,
    padding: theme.spacing(6, 8),
    marginBottom: theme.spacing(2)
  },
  addLabel: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-start',
    alignContent: 'flex-start',
    width: '100%',
    color: theme.colors.header__label_primary
  },
  buttonContainer: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end'
  }
});

export default makeStyles(styles);
