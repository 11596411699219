import React from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { Button } from 'core/components/button';
import { useStore } from 'core/store/';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import BranchPlusMultiPolicyDiscountForm from 'common/components/discounts/branch-plus-multi-policy-discount-form/branch-plus-multi-policy-discount-form';

import useStyles from 'common/components/discounts/branch-plus-multi-policy-discount-form/branch-plus-multi-policy-discount.styles';

const BranchPlusMultiPolicyDiscountModal = observer(() => {
  const { offer } = useStore();
  const classes = useStyles();
  const { values } = useFormikContext();
  const { setShowBranchPlusMultiPolicyDiscountModal, setIncludeBranchPlusMultiPolicyDiscount } = offer;

  const onClose = () => {
    if (!values.branchPlusPolicies?.length) {
      setIncludeBranchPlusMultiPolicyDiscount(false);
    }
    setShowBranchPlusMultiPolicyDiscountModal(false);
  };

  return (
    <BaseDialog onClose={onClose} open>
      <Label marginBottom={5} type="title">
        Branch Plus Bundle Discount
      </Label>
      <Grid container>
        <BranchPlusMultiPolicyDiscountForm inOffer />
      </Grid>
      <Grid className={classes.buttonContainer}>
        <div>
          <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
            Apply Discount
          </Button>
        </div>
      </Grid>
    </BaseDialog>
  );
});

export default BranchPlusMultiPolicyDiscountModal;
