import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  'light-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    backgroundColor: theme.colors.light_background,
    '&:hover': {
      backgroundColor: theme.colors.text_disabled
    }
  },
  'light-tableCellRoot': {
    fontSize: 14,
    color: theme.colors.light_text
  },
  'dark-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.light_background
    }
  },
  'dark-tableCellRoot': {
    color: theme.colors.light_text,
    fontSize: 14
  },
  'dark-tableSortLabel': {
    color: theme.colorPalette.beige_10
  },
  'red-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    backgroundColor: theme.colors.red_dark,
    '&:hover': {
      backgroundColor: theme.colors.red
    }
  },
  'red-tableCellRoot': {
    fontSize: 14,
    color: theme.colors.light_text
  },
  'orange-tableRowRoot': {
    height: 65,
    cursor: 'pointer',
    backgroundColor: theme.colorPalette.orange_50,
    '&:hover': {
      backgroundColor: theme.colorPalette.orange_50
    }
  },
  'orange-tableCellRoot': {
    fontSize: 14,
    color: theme.colors.light_text
  },
  iconFold: {
    display: 'block',
    height: 32,
    width: 32,
    transition: 'transform 200ms'
  },
  icon: {
    composes: '$iconFold',
    transform: 'rotate(180deg)'
  },
  badge: {
    display: 'inline-flex',
    maxWidth: 24,
    backgroundColor: theme.colorPalette.green_20,
    textAlign: 'center',
    fontSize: 10,
    color: theme.colors.light_text,
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(5),
    fontWeight: 'normal'
  },
  headerLabel: {
    color: theme.colorPalette.beige_10,
    fontSize: 12,
    opacity: 0.6
  },
  collapserRowTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: 16
  }
});

export default makeStyles(styles);
