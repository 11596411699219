import gql from 'graphql-tag';

export const BRANCH_PLUS_POLICY_FRAGMENT = gql`
  fragment BranchPlusPolicyFragment on BranchPlusPolicy {
    policyId
    policyType
    customer {
      firstName
      lastName
      email
      phoneNumber
      accountId
    }
    carrier {
      name
    }
    address {
      address
      city
      state
      zip
    }
  }
`;
