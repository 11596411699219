import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  policyLabel: {
    opacity: 0.6,
    color: theme.colors.light_text
  },
  tableContainer: {
    boxShadow: 'none'
  }
});

export default makeStyles(styles);
