import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { discountPaperlessStates, policyType as PolicyType, statesEligibleForBranchPlusMPD } from '@ourbranch/lookups';

import { autoMultiPolicyDiscountOptions, homeMultiPolicyDiscountOptions } from 'core/helpers/lookups';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';
import Switch from 'core/components/switch';
import BranchPlusMultiPolicyDiscountForm from 'common/components/discounts/branch-plus-multi-policy-discount-form/branch-plus-multi-policy-discount-form';
import { useDisabledState } from 'common/disabled-context/context';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import {
  useConnectedHomeSecurityProviderHelpers,
  getMonitoredStatus
} from 'common/components/discounts/hooks/connected-home.hooks';
import PolicyConnectedHomeSettings from './connected-home/policy-connected-home-settings';
import useStyles from './discounts.styles';

const Discounts = observer(function Discounts({
  disabled,
  showConnectedHome,
  onConnectedHomeChange,
  isBix,
  allowLicensedActions
}) {
  const session = useContext(AuthContext);
  const { disabled: disabledContext } = useDisabledState();
  const {
    canModifyAffinityAndLeadSource,
    isInternalSales,
    canToggleEmployeeDiscount,
    canToggleCHDiscount,
    canToggleInventoryDiscount,
    isAgency
  } = session;
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();

  const classes = useStyles();
  const { policyType, policyDetails } = store.policy;
  const [showBranchPlusDiscount, setShowBranchPlusDiscount] = useState(!!policyDetails?.branchPlusPolicies?.length);
  const isDwellingPolicy = [PolicyType.Condo, PolicyType.Home].includes(policyType);
  const multiPolicyDiscountOptions = isDwellingPolicy ? homeMultiPolicyDiscountOptions : autoMultiPolicyDiscountOptions;
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (!showBranchPlusDiscount && values?.branchPlusPolicies?.length) {
      setFieldValue('branchPlusPolicies', []);
      setFieldTouched('branchPlusPolicies');
    }
  }, [showBranchPlusDiscount]);

  return (
    <Section title="Discounts" type="SubSection">
      <Card type="secondary" className={classes.secondary}>
        <Form disabled={disabled}>
          <Grid container alignItems="flex-end" justifyContent="space-between" spacing={2}>
            {canModifyAffinityAndLeadSource && (
              <FormField
                id="global.affinity"
                name="global.affinity"
                label="Affinity Code"
                type="affinity"
                mode="dark"
                xs={6}
                fast={false}
              />
            )}
            {!policyType !== PolicyType.Renters && (
              <FormField
                id="multiPolicyDiscount"
                name="multiPolicyDiscount"
                label="Multiline Discount"
                type="select"
                mode="dark"
                options={multiPolicyDiscountOptions}
                xs={6}
                permissions={{
                  edit: {
                    groups: [CognitoPermissionGroups.canEditPolicyMPD]
                  }
                }}
              />
            )}
            <FormField
              id="global.discountPaperless"
              name="global.discountPaperless"
              label="Paperless Discount"
              type="switch"
              mode="dark"
              disabled={!isBix && !discountPaperlessStates[store.policy.state].isNotBix}
              xs={12}
              permissions={{ isLicensedAction: false }}
            />
            {canToggleEmployeeDiscount && (
              <FormField label="Employee Discount" xs={12} type="switch" name="global.employeeDiscount" mode="dark" />
            )}
            {policyType === PolicyType.Home && (
              <>
                <FormField
                  id="global.discountInventoryScore"
                  name="global.discountInventoryScore"
                  label="Inventory Discount"
                  type="switch"
                  mode="dark"
                  tooltipText={
                    store.policy.term > 1
                      ? 'Inventory discount can only be added to first term policies.'
                      : !canToggleInventoryDiscount && !values.global.discountInventoryScore
                      ? 'Please follow your designated escalation process to add this discount.'
                      : null
                  }
                  tooltipLabel={
                    (!canToggleInventoryDiscount && !values.global.discountInventoryScore) || store.policy.term > 1
                      ? 'More Info'
                      : null
                  }
                  disabled={
                    store.policy.term > 1 || (!canToggleInventoryDiscount && !values.global.discountInventoryScore)
                  }
                  xs={12}
                />
                {isInternalSales && values.global.discountInventoryScore && (
                  <Grid className={classes.innerCheckbox}>
                    <FormField
                      id="global.manualInventorySubmission"
                      name="global.manualInventorySubmission"
                      type="checkbox"
                      label="I have received the member's inventory by email instead of through account"
                      mode="dark"
                    />
                  </Grid>
                )}
              </>
            )}
            {policyType !== PolicyType.Auto && (
              <Switch
                id="discountConnectedHome"
                name="discountConnectedHome"
                label="Connected Home"
                mode="dark"
                value={showConnectedHome}
                onChange={onConnectedHomeChange}
                xs={12}
                tooltipText={
                  !canToggleCHDiscount && !showConnectedHome && isAgency
                    ? "Please provide proof of member's connected home to support@ourbranch.com to add this discount."
                    : !canToggleCHDiscount && !showConnectedHome && !isAgency
                    ? 'Once proof of connected home is received from the member, please follow your designated process to add this discount.'
                    : null
                }
                tooltipLabel={!canToggleCHDiscount && !showConnectedHome ? 'More Info' : null}
                disabled={!allowLicensedActions || (!canToggleCHDiscount && !showConnectedHome) || disabledContext} // have to manually disable because this is not a FormField component that would handle it for us
              />
            )}
            {statesEligibleForBranchPlusMPD[store.policy.state]?.[policyType] && (
              <Switch
                id="branchPlusPolicies"
                name="branchPlusPolicies"
                label="Branch Plus Bundle Discount"
                mode="dark"
                value={showBranchPlusDiscount}
                onChange={() => setShowBranchPlusDiscount(!showBranchPlusDiscount)}
                xs={12}
                disabled={!allowLicensedActions || disabledContext} // have to manually disable because this is not a FormField component that would handle it for us
              />
            )}
          </Grid>
        </Form>
        {showConnectedHome && <PolicyConnectedHomeSettings />}
        {showBranchPlusDiscount && <BranchPlusMultiPolicyDiscountForm />}
      </Card>
    </Section>
  );
});

Discounts.defaultProps = {
  isAdvancedConnectedHome: false,
  isBix: false
};

Discounts.propTypes = {
  showConnectedHome: PropTypes.bool.isRequired,
  onConnectedHomeChange: PropTypes.func.isRequired,
  isBix: PropTypes.bool
};

const DiscountsFormikProvider = observer(({ disabled }) => {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    account: {
      policies: {
        policy: {
          isAdvancedConnectedHome,
          getAllowLicensedActions,
          policy: { isBix: policyIsBix }
        }
      }
    },
    affinityLookups: affinityLookupsStore
  } = useStore();

  const isBix = policyIsBix || values.isBix; // policy isBix stored on policy level, this is segment context form
  const [showConnectedHome, setShowConnectedHome] = useState(
    !!values.connectedHome && values.connectedHome?.monitored !== 'N'
  );
  const allowLicensedActions = getAllowLicensedActions(session);

  const { isMonitoredByPartner } = useConnectedHomeSecurityProviderHelpers(
    { isAdvancedConnectedHome },
    affinityLookupsStore
  );
  const monitored = getMonitoredStatus({ isAdvancedConnectedHome, isMonitoredByPartner });

  // clear the values when changed
  const onConnectedHomeChange = useCallback(
    (e) => {
      setShowConnectedHome(e.target.checked);
      if (e.target.checked) {
        setFieldValue('connectedHome', {
          moistureDevices: undefined,
          motionDetectingDevices: undefined,
          smokeDetectingDevices: undefined,
          theftPreventionDevices: undefined,
          autoWaterShutoffDevices: false,
          providerName: undefined,
          ...values.connectedHome,
          monitored: values.connectedHome?.providerName ? monitored : 'N'
        });
      } else {
        setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
        setFieldValue('connectedHome', { ...values.connectedHome, monitored: 'N' });
      }
      setFieldTouched('connectedHome');
    },
    [setFieldValue, setFieldTouched, values.connectedHome]
  );

  return (
    <Discounts
      classes={classes}
      disabled={disabled}
      showConnectedHome={showConnectedHome}
      onConnectedHomeChange={onConnectedHomeChange}
      isBix={isBix}
      allowLicensedActions={allowLicensedActions}
    />
  );
});

DiscountsFormikProvider.propTypes = {
  disabled: PropTypes.bool.isRequired
};

Discounts.propTypes = {
  showConnectedHome: PropTypes.bool.isRequired,
  onConnectedHomeChange: PropTypes.func.isRequired,
  isBix: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  allowLicensedActions: PropTypes.bool.isRequired
};

Discounts.defaultProps = {
  isBix: false
};

export default DiscountsFormikProvider;
