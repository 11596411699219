import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  StylesProvider,
  MuiThemeProvider,
  createTheme,
  createGenerateClassName,
  jssPreset,
  withStyles
} from '@material-ui/core/styles';
import { create } from 'jss';
import jssCompose from 'jss-plugin-compose';
import jssExtend from 'jss-plugin-extend';

import { colorPalette, colors, screens, sizes, fonts } from './variables';
import overrideStyles from './material-overrides';

const generateClassName = createGenerateClassName();
const jss = create({
  plugins: [jssExtend(), ...jssPreset().plugins, jssCompose()]
});
export const theme = createTheme({
  typography: {
    fontFamily: "'Libre Franklin', sans-serif",
    useNextVariants: true
  },
  palette: {
    primary: {
      main: colorPalette.green_30,
      contrastText: colorPalette.white_10
    },
    secondary: {
      main: colorPalette.orange_10,
      contrastText: colorPalette.white_10
    },
    error: {
      main: colors.error
    }
  },
  colors,
  colorPalette,
  screens,
  sizes,
  spacing: 4
});

overrideStyles(theme);

const rootStyles = {
  '@global': {
    '*': {
      fontFamily: fonts.LibreFranklin
    },
    body: {
      backgroundColor: colors.page__background_primary
    },
    ':focus': {
      outline: 'none'
    },
    '::-moz-focus-inner': {
      border: 0
    },
    a: {
      textDecoration: 'none',
      display: 'contents' // https://drafts.csswg.org/css-display/#valdef-display-contents
    }
  }
};

const ThemeProvider = ({ children }) => (
  <StylesProvider jss={jss} generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  </StylesProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export default withStyles(rootStyles)(ThemeProvider);
