import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    '& td': {
      borderBottom: 'none',
      padding: theme.spacing(4, 0),
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`
    },
    '& tr:first-of-type > td': {
      paddingTop: 0
    },
    '& tr:last-of-type': {
      borderBottom: 'none',
      '& > td': {
        paddingBottom: 0
      }
    }
  },
  inaccurateValueNote: {
    color: 'red'
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    },
    '& > span:last-of-type': {
      fontSize: 22,
      lineHeight: 24
    }
  },
  container: {
    backgroundColor: theme.colorPalette.green_30,
    margin: theme.spacing(4, 0),
    padding: theme.spacing(6)
  },
  label: {
    display: 'flex',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.33,
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.colorPalette.beige_10,
    alignItems: 'center'
  },
  value: {
    display: 'flex',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.2,
    fontSize: 20,
    color: theme.colorPalette.beige_10
  }
}));

export default useStyles;
