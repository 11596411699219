import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import useStyles from './suspicious.styles';

const SuspiciousAccountModal = ({ open, onClose, toggleSuspiciousFlag, loading, suspicious }) => {
  const classes = useStyles();
  return (
    <BaseDialog open={open} onClose={onClose}>
      <Label type="titleSecondary" className={classes.title}>
        {suspicious ? 'Un-flag this member as suspicious?' : 'Flag this member as suspicious?'}
      </Label>
      {!suspicious && (
        <Label type="modalBody" className={classes.contentText}>
          This will add a warning to this member and their policies that will be visible to all staff users. Changes to
          the member and their policies will still be allowed.
        </Label>
      )}
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onClose} disabled={loading}>
            No, cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={toggleSuspiciousFlag}
            loading={loading}
            disabled={loading}
          >
            {suspicious ? 'Yes, un-flag' : 'Yes, flag as suspicious'}
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default SuspiciousAccountModal;
