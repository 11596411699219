import { useMemo } from 'react';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { awsDateToStringDate } from 'core/helpers/formatters';
import useDetailedPrice from 'offer/hooks/use-detailed-price';
import usePlanPrice from 'offer/hooks/use-plan-price';
import useBillingDayOfMonthOptions from 'core/hooks/use-billing-day-of-month-options';
import { getPrimaryOrBlankMortgageDetails } from 'offer/helpers/mortgage-helpers';

const useFormInitialValues = (offer, policyType) => {
  const {
    homePrice,
    autoPrice,
    rentersPrice,
    condoPrice,
    homeDownPayment,
    autoDownPayment,
    rentersDownPayment,
    condoDownPayment
  } = usePlanPrice(offer, policyType, useDetailedPrice);
  const billingDayOptions = useBillingDayOfMonthOptions(offer, policyType);

  let mortgageDetails = [];

  if (policyType?.includes(PolicyType.Home)) {
    mortgageDetails = offer.quote.home.mortgageDetails;
  } else if (policyType?.includes(PolicyType.Condo)) {
    mortgageDetails = offer.quote.condo.mortgageDetails;
  }
  const primaryMortgageDetails = getPrimaryOrBlankMortgageDetails(mortgageDetails);
  return useMemo(() => {
    if (!policyType) {
      return {};
    }

    const isRenters = policyType === PolicyType.ARBundle;
    const { quote } = offer;
    const { email, phone, additionalPhoneNumbers, selectedOption } = quote;
    const {
      autoEffectiveDate,
      autoPaymentMethod,
      autoPaymentType,
      homeEffectiveDate,
      homeownersPaymentType,
      homeownersPaymentMethod,
      homeMegaDownPay,
      billingDayOfMonth: globalBillingDayOfMonth,
      homeBillingDayOfMonth,
      autoBillingDayOfMonth,
      autoMegaDownPay,
      currentAutoCarrierPolicyNumber,
      breakupWithAuto,
      currentAutoCarrier,
      breakupWithHomeowners,
      currentHomeownersCarrier,
      rentersBillingDayOfMonth,
      rentersEffectiveDate,
      rentersPaymentType,
      rentersPaymentMethod,
      breakupWithRenters,
      rentersMegaDownPay,
      condoBillingDayOfMonth,
      condoEffectiveDate,
      condoPaymentType,
      condoPaymentMethod,
      condoMegaDownPay,
      noBindHome,
      noBindAuto
    } = quote.global;

    const billingDayOfMonth = globalBillingDayOfMonth || billingDayOptions?.pop()?.id; // default to the last option

    return {
      billingDayOfMonth,
      address: isRenters ? offer.quote.rentersCoverage.rentersLocation : offer.quote.correctedAddress,
      firstName: offer.quote.fname,
      lastName: offer.quote.lname,
      email,
      phone,
      additionalPhoneNumbers,
      cardBrand: '',
      cardLast4: '',
      completeCardData: false,
      completeACHData: false,
      breakupESignature: false,
      basisTheoryCardToken: '',
      basisTheoryBankAccountToken: '',
      selectedOption,
      noBindHome,
      noBindAuto,

      // Auto
      autoEffectiveDate: awsDateToStringDate(autoEffectiveDate),
      autoBillingDayOfMonth: autoBillingDayOfMonth || billingDayOfMonth,
      autoPaymentType,
      autoPaymentMethod,
      autoDownPayment,
      autoMegaDownPay,
      attestationsAutoAccepted: null,
      breakupWithAuto: breakupWithAuto || null,
      currentAutoCarrier: currentAutoCarrier || '',
      currentAutoCarrierPolicyNumber: currentAutoCarrierPolicyNumber || '',
      drivers: [],

      // Home
      homeBillingDayOfMonth: homeBillingDayOfMonth || billingDayOfMonth,
      homeEffectiveDate: awsDateToStringDate(homeEffectiveDate),
      homeownersPaymentType,
      homeownersPaymentMethod,
      homeMegaDownPay,
      homeDownPayment,
      attestationsHomeAccepted: null,
      mortgageDetails: primaryMortgageDetails,
      loanOfficerEmail: '',
      breakupWithHomeowners: breakupWithHomeowners || null,
      currentHomeownersCarrier: currentHomeownersCarrier || '',
      currentHomeownersCarrierPolicyNumber: '',

      // Renters
      rentersBillingDayOfMonth: rentersBillingDayOfMonth || billingDayOfMonth,
      rentersEffectiveDate: awsDateToStringDate(rentersEffectiveDate),
      rentersPaymentType,
      rentersPaymentMethod,
      rentersDownPayment,
      rentersMegaDownPay,
      attestationsRentersAccepted: null,

      // Condo
      condoBillingDayOfMonth: condoBillingDayOfMonth || billingDayOfMonth,
      condoEffectiveDate: condoEffectiveDate ? awsDateToStringDate(condoEffectiveDate) : null,
      condoPaymentType,
      condoPaymentMethod,
      condoDownPayment,
      condoMegaDownPay,
      attestationsCondoAccepted: null,

      breakupWithRenters: breakupWithRenters || null,
      currentRentersCarrier: currentHomeownersCarrier || '',
      currentRentersCarrierPolicyNumber: '',

      // Payment disclosures
      authorizePayments: false,

      // Pre-Sale Checklist
      buyingHomePolicyInformed: false,
      buyingCondoPolicyInformed: false,
      inColoradoInformed: false,
      branchCommunicationConsent: false,
      allApplicantsConfirmed: false,
      priorInsuranceEntered: false,
      homePaymentRemindersUpdated: false,
      autoPaymentRemindersUpdated: false,
      confirmDiscounts: false,
      confirmCoverages: false,
      microDepositsACH: false,
      noWindHailInformed: false,
      earthquakeAndFloodInformed: false,
      proofOfPriorCoverageInformed: false,
      lapsedLicensesVerified: false,
      excludedDriversInformed: false,
      roofSurfacesEndorsementInformed: false,
      driversLicenseInformed: false,
      michiganPIPInformed: false,
      trailingDocsInformed: false,
      homeAgeInventoryDiscountInformed: false,

      // Extra questions
      opposingPolicySoldByAgency: null,
      /**
       * This node is populated with data when the staff user enters checkout payment info
       * but doesn't complete the purchase (usually because the customer asks for more offer modifications)
       * because they navigated back to the MQFS route
       */
      recoveredPaymentData: null
    };
  }, [offer, policyType, billingDayOptions, homePrice, autoPrice, rentersPrice, condoPrice, primaryMortgageDetails]);
};

export default useFormInitialValues;
