/* eslint-disable max-len */
import React from 'react';

const KYHomeIneligibleMessage =
  'HOME INELIGIBLE: We do not allow this combination of home age, home loss, and Insurance Group in this state.';

export const messages = (state, isAgency, selectedOption, err = {}) => {
  return {
    // Only rejections
    10000: {
      title: 'Catch-all Verisk.',
      message:
        'This is the catch-all "Verisk FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
    },
    10009: {
      title: 'Total Property Claims',
      message:
        state === 'KY'
          ? KYHomeIneligibleMessage
          : 'HOME INELIGIBLE: We do not allow this many total property claims on a home that is less than 50 years old in the past 5 years.'
    },
    10010: {
      title: 'Same-Peril Property Claims',
      message:
        'HOME INELIGIBLE: This property has too many instances of the same type of peril (e.g., Fire, Wind, Water) in the past 5 years.'
    },
    10011: {
      title: 'Older Home Property Claims',
      message:
        state === 'KY'
          ? KYHomeIneligibleMessage
          : 'HOME INELIGIBLE: We do not allow this many losses in the last 5 years, as named insured, for homes greater than 50 years old.'
    },
    10012: {
      title: 'Bite Claims',
      message: 'HOME INELIGIBLE: We do not allow any animal bite claims.'
    },
    10013: {
      title: 'Suspended License (MVR)',
      message:
        'AUTO INELIGIBLE: We do not allow any record of a suspended license in the past 59 months; this one came from a Verisk MVR.'
    },
    10014: {
      title: 'Disqualifying Charge (MVR)',
      message:
        'AUTO INELIGIBLE: We do not allow any record of a "disqualifying charge" in the past 35 months: DWI, Reckless Driving, Vehicular Homicide, Failure to File Required Report, Fleeing from Police, Suspended License, Drag Racing. This one came from a Verisk MVR.'
    },
    10015: {
      title: 'Total Auto Incidents (MVR, Claims)',
      message:
        "AUTO INELIGIBLE: We do not allow this many incidents across all drivers on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Verisk MVR."
    },
    10016: {
      title: 'Driver Auto Incidents (MVR, Claims)',
      message:
        "AUTO INELIGIBLE: We have a limit on the number of incidents for any driver on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Verisk MVR."
    },
    10017: {
      title: 'PIP Claims',
      message:
        "AUTO INELIGIBLE: We have a limit on the number of pip claims that we found on the driver's claims history."
    },
    10018: {
      title: 'Felony (MVR)',
      message:
        'AUTO INELIGIBLE: We do not allow any record of any motor-vehicle felony in the past 59 months; this one came from a Verisk MVR.'
    },
    10019: {
      title: 'Water Loss Claims',
      message:
        'RENTERS INELIGIBLE: We do not allow more than one home claim for water loss in the last five years. If this is incorrect, please follow your designated support process to resolve.'
    },
    10020: {
      title: 'Too Many Home Claims',
      message:
        'RENTERS INELIGIBLE: We do not allow more than one home claim in the last three years. If this is incorrect, please follow your designated support process to resolve.'
    },
    10021: {
      title: 'Water Loss Claims',
      message:
        'HOME INELIGIBLE: We do not allow one or more home claim for water loss in the last five years. If this is incorrect, please follow your designated support process to resolve.'
    },
    20000: {
      title: 'Catch-all Transunion FCRA Issue',
      message:
        'This is the catch-all "Transunion FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
    },
    20001: {
      title: 'Suspended License (DR)',
      message:
        'AUTO INELIGIBLE: We do not allow any record of a suspended license in the past 59 months; this one came from Transunion DriverRisk.'
    },
    20002: {
      title: 'Disqualifying Charge (DR)',
      message:
        'AUTO INELIGIBLE: We do not allow any record of a "disqualifying charge" in the past 35 months: DWI, Reckless Driving, Vehicular Homicide, Failure to File Required Report, Fleeing from Police, Suspended License, Drag Racing. This one came from Transunion DriverRisk.'
    },
    20003: {
      title: 'Total Auto Incidents (DR)',
      message:
        "AUTO INELIGIBLE: We do not allow this many incidents across all drivers on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Transunion DriverRisk."
    },
    20004: {
      title: 'Driver Auto Incidents (MVR, Claims)',
      message:
        "AUTO INELIGIBLE: We have a limit on the number of incidents for any driver on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Transunion DriverRisk."
    },
    20005: {
      title: 'Felony (MVR)',
      message:
        'AUTO INELIGIBLE: We do not allow any record of any motor-vehicle felony in the past 59 months; this one came from a Transunion DriverRisk.'
    },
    50000: {
      title: 'Catch-all Issue',
      message:
        'This is the catch-all "Non-FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
    },
    50001: {
      title: 'No Cars',
      message:
        "AUTO INELIGIBLE: Couldn't find any cars in either Coverage Verifier or the Vehicle Registration database, and so we need to have one VIN supplied so that we can provide a price for auto. This may be a result of asking only for homeowners insurance in a state that does not pull auto information unless explicitly asked (e.g., New Hampshire)."
    },
    50002: {
      title: 'Too Many Square Feet',
      message:
        'HOME INELIGIBLE: We allow up to 6,000 square feet in homes that we insure, and this home exceeds that. If this is incorrect, please correct the update the value under Property Detail and resubmit.'
    },
    50003: {
      title: 'Too Many Occupants',
      message:
        'HOME INELIGIBLE: We allow up to 12 occupants in homes that we insure, and this home exceeds that. If you submit a smaller value, we will use it.'
    },
    50004: {
      title: 'Car Ratio',
      message:
        'This offer exceeds the 2 cars to 1 driver ratio. Please review the registered vehicles and drivers on this offer and, if they are incorrect, correct them. Otherwise, this offer is ineligible.',
      messageAsJsx: (
        <span>
          This offer exceeds the 2 cars to 1 driver ratio.
          <strong>
            {' '}
            Please review the registered vehicles and drivers on this offer and, if they are incorrect, correct them.
            Otherwise, this offer is ineligible.
          </strong>
        </span>
      )
    },
    50005: {
      title: 'Home Too Old',
      message: 'HOME INELIGIBLE: We do not allow homes this old in this state.'
    },
    50006: {
      title: 'Could Not Calculate Home Replacement Cost',
      message:
        'HOME INELIGIBLE: We tried to pull data on this home from three different sources (Verisk tax assessor data, Attom tax assessor data, and MLS data) and did not receive enough information to calculate a replacement cost. To resolve, scroll up, provide home characteristics under Property Detail and hit "See Price" again.'
    },
    50007: {
      title: 'Not Enough Continuous Auto Insurance',
      message:
        'We do not allow members with no auto insurance for the past 31 days, unless there was no need, which needs to be granted as an underwriting exception. In some states, we also require either six months or one year of continuous insurance. If this is incorrect, please follow your designated support process to resolve.',
      canBeOverridden: true
    },
    50008: {
      title: 'Exterior Insulation and Finish System',
      message:
        'HOME INELIGIBLE: We do not allow homes with Exterior Insulation and Finish System (EIFS) as their primary exterior wall type; we identify this from Verisk 360Value returning an exterior wall type of SSTU_BL.'
    },
    50009: {
      title: 'Stilts',
      message:
        'HOME INELIGIBLE: We do not allow homes built on stilts; we identify this from Verisk 360Value returning a foundation type of either E or ES.'
    },
    50010: {
      title: 'Wood Roof',
      message:
        'HOME INELIGIBLE: We do not allow homes with wood roofs; if this is incorrect, you can resubmit with the proper roof type.'
    },
    50011: {
      title: 'Replacement Cost Too Low',
      message:
        'HOME INELIGIBLE: Our minimum replacement cost for coverage is $50,000. If you think it is being calculated incorrectly, specify all of the home characteristics and resubmit.'
    },
    50012: {
      title: 'Replacement Cost Too High',
      message:
        'HOME INELIGIBLE: Our maximum replacement cost for coverage is $850,000 for TX and $950,000 for all other states. If you think it is being calculated incorrectly, review all of the home characteristics under Property Detail and resubmit.'
    },
    50013: {
      title: 'Fire Protection Class 10',
      message:
        'HOME INELIGIBLE: We do not allow homes with a fire protection class of 10. If you think this is in error, we will need proof of proximity to fire department and fire hydrant. If you are in KY or MI, talk to underwriting to see if this qualifies for an exception.'
    },
    50014: {
      title: 'No Fire Protection Class',
      message:
        'HOME INELIGIBLE: We did not receive a fire protection class for this home. This usually means that all of the home characteristics need to be entered from the New Offer screen.'
    },
    50015: {
      title: 'Too Many Drivers',
      message: 'AUTO INELIGIBLE: We allow a maximum of ten drivers per policy.'
    },
    50016: {
      title: 'Too Many Cars',
      message: 'AUTO INELIGIBLE: We allow a maximum of ten cars per policy.'
    },
    50017: {
      title: 'Test Case',
      message:
        'This is a test case rejection and should never been seen in production. If you do see this, please let joe@ourbranch.com know how to replicate.'
    },
    50018: {
      // no policy type specified because it applies to both H&A lines
      title: 'Ineligible Coastal Location',
      message:
        "This error is a fatal and non-recoverable error because the home is in a location that we aren't writing insurance for."
    },
    50019: {
      title: 'No Fireline Score Retrieved',
      message:
        "HOME INELIGIBLE: We didn't get a Fireline score from Verisk; re-run offer from start to try and get one."
    },
    50020: {
      title: 'Fireline Score is Too High',
      message: 'HOME INELIGIBLE: We received a Fireline score (wildfire hazard risk) that is too high for us to write.'
    },
    50021: {
      // no policy type specified because it applies to both H&A lines
      title: 'Fireline Score with Fire Protection Class is Too High',
      message:
        'We received a fireline score (wildfire hazard risk) that is too high for us to write with the fire protection class on this property. This is not an "FPC 10" error; this error only happens with fire protection classes of less than 10 and higher fireline scores.'
    },
    50022: {
      // no policy type specified because it applies to both H&A lines
      title: 'No Distance-to-Coast value found',
      message: 'We do not have a distance-to-coast value for this property, and we need one to run underwriting rules.'
    },
    50023: {
      // no policy type specified because it applies to both H&A lines
      title: 'Ineligible Distance-to-Coast value found',
      message: 'This property is too close to tidal water for us to underwrite it.'
    },
    50024: {
      title: 'Scheduled Personal Property too expensive',
      message: 'RESIDENCE INELIGIBLE: SPP is more than ten percent of Coverage C, which is not allowed.'
    },
    50025: {
      title: 'Scheduled Personal Property too expensive',
      message: 'RESIDENCE INELIGIBLE: SPP is more than ten percent of Coverage A, which is not allowed.'
    },
    50026: {
      title: 'Scheduled Personal Property too expensive',
      message: 'RESIDENCE INELIGIBLE: SPP is more than fifty percent of Coverage C, which is not allowed.'
    },
    50027: {
      title: 'No Home Wind/Hail Exclusion',
      message: 'HOME INELIGIBLE: We do not allow home with a wind/hail exclusion here'
    },
    50028: {
      title: 'Fire Protection Class Too High',
      message:
        'HOME INELIGIBLE: In some states (prone to wildfires) we have a lower-than-10 FPC limit, and this home exceeds that lower limit. If the homeowner is within 1000ft of a fire hydrant, this can be sent to underwriting for override.'
    },
    50029: {
      title: 'Did Not Ask for Auto',
      message:
        'AUTO INELIGIBLE: In some states we do not offer auto insurance if it is not asked for, and that was the case with this offer.'
    },
    50030: {
      title: 'Roof Condition from Imagery',
      message:
        'HOME INELIGIBLE: In the latest aerial imagery we have of this home, the roof appears to be in poor condition. We will need a recent photo of the roof to underwrite this policy (escalate to service/developers after underwriting has approved).'
    },
    50031: {
      title: 'Yard Debris from Imagery',
      message:
        'HOME INELIGIBLE: In the latest aerial imagery we have of this home, there appears to be a significant amount of yard debris. We will need a recent photo of the yard(s) to underwrite this policy (escalate to service/developers after underwriting has approved).'
    },
    50032: {
      title: 'Coastal County',
      message:
        'HOME INELIGIBLE: This property is in a coastal county in a state in which we restrict sales based upon coastal location.'
    },
    50033: {
      title: 'Less than One Year Continuous Coverage',
      message:
        'AUTO INELIGIBLE: Less than one year of prior continuous auto coverage for monoline direct purchase (based on Coverage Verifier). Re-run offer from scratch in staff to clear.'
    },
    50034: {
      title: 'Invalid Prior Carrier',
      message:
        'AUTO INELIGIBLE: Invalid prior carrier for monoline direct purchase (based on Coverage Verifier). Re-run offer from scratch in staff to clear.'
    },
    50035: {
      title: 'Some incidents and/or violations',
      message:
        'AUTO INELIGIBLE: Invalid 35-month violation/incident history for monoline direct purchase. Re-run offer from scratch in staff to clear.'
    },
    50036: {
      title: 'More cars than drivers',
      message:
        'AUTO INELIGIBLE: Invalid more cars than drivers for monoline direct purchase. Re-run offer from scratch in staff to clear.'
    },
    50037: {
      title: 'Bodily Injury Limit is Too Low',
      message:
        'AUTO INELIGIBLE: Invalid bodily injury limit (based on Coverage Verifier) for monoline direct purchase. Re-run offer from scratch in staff to clear.'
    },
    50038: {
      title: 'CV No Hit',
      message:
        'AUTO INELIGIBLE: We do not allow CV no hit for monoline direct purchase. Re-run offer from scratch in staff to clear.'
    },
    50039: {
      title: 'Some Drivers Excluded',
      message:
        'AUTO INELIGIBLE: We do not allow excluded drivers in monoline direct purchase. Re-run offer from scratch in staff to clear.'
    },
    50040: {
      title: 'Insurance Score Too Low/No Hit',
      message:
        'HOME INELIGIBLE: Review risk for any additional adverse factors and if present, "we are unable to offer a home policy due to risk management"'
    },
    // the following are all UW errors
    50041: {
      title: 'Self Reported Home Underwriting',
      message:
        'During this or a prior sales underwriting review, this member reported a home characteristic that does not meet Branch underwriting guidelines. If you believe this is an error, please contact a Team Lead to solve'
    },
    50042: {
      title: 'Self Reported Auto Underwriting',
      message:
        'During this or a prior sales underwriting review, this member reported a auto characteristic that does not meet Branch underwriting guidelines. If you believe this is an error, please contact a Team Lead to solve'
    },
    // the following are UW errors specific to FL
    50043: {
      title: 'Water Heater Older Than 15 Years',
      agentScript:
        'HOME INELIGIBLE: Is your water heater tankless? If not, we ask that you have it professionally replaced prior to getting a policy with Branch. You may submit proof of water heater replacement (permit, paid receipt, copy of completed contract) to us to allow the home policy to be bound'
    },
    50044: {
      title: 'Coverage A Is Less Than 100K',
      agentScript:
        'We need photos of the outside of your home to continue binding the policy. You may submit them to us for review',
      agentAction:
        'Review property and confirm the RCE is correct. Also review property appraiser and Zillow to confirm this is a Single-Family Residence'
    },
    50045: {
      title: 'Trampolines Present',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50046: {
      title: 'Property Greater Than 5 Acres Or Is PC 9 - 10',
      agentScript: `The fire protection class on your property returned a high score, meaning that there is no fire hydrant or fire department in close proximity to your property. If you feel this is a mistake, we will reach out to our vendor to confirm protection class. If they advise that you are in not an ineligible protection class, we will correct the protection class. \n If ineligible - this property does not meet our underwriting guidelines and we are unable to offer you a home policy.`
    },
    50047: {
      title: 'Unconventional Home',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50048: {
      title: 'Ineligible Dog Or Aggression History',
      message: 'HOME INELIGIBLE: Certain dog breeds and aggressive dogs, are ineligible for our home program.'
    },
    50049: {
      title: 'Prior Sinkhole Activity Or Adjacent To Sinkhole',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50050: {
      title: 'Saddle Animals, Farm, Or Non-Domesticated Animals',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50051: {
      title: 'Open Foundation',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50052: {
      title: 'In Foreclosure Property',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50053: {
      title: 'No Polybutylene Plumbing',
      message:
        'HOME INELIGIBLE: Homes with polybutylene plumbing are ineligible for our home program. If you wish to proceed with the offer, we must receive proof of professional replacement prior to binding. Acceptable proof includes permits, completed contract and a signed statement from a licensed plumber that all polybutylene plumbing has been removed from your property.'
    },
    50054: {
      title: 'Skateboard/Bike Ramp, Diving Board, Or Pool Slide Present',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50055: {
      title: 'Property Is Owned By Corporation, LLC, LP, LLP, Estate, Or Partnership',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50056: {
      title: 'Excluded Content Coverage',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50057: {
      title: 'Day Care Operating In Home',
      agentScript: `What type of business is conducted at your home? \n
- If anything other than day care this business exposure does not meet our underwriting guidelines and we are unable to offer you a policy\n
- If daycare, please submit a copy of your current commercial liability policy for our review`,
      agentAction:
        'If commercial liability policy received and there are no other hazards noted from your review, we are required to allow policy binding. We can reach out every year for confirmation that the daycare is still in business and ask for a copy of the commercial renewal policy. If not received, we can non-renew for failure to provide required documentation'
    },
    50058: {
      title: 'Seasonal Or Secondary Home',
      agentScript: 'Secondary or seasonal homes are sold as an accommodation to Branch policyholders.',
      agentAction:
        'Confirm if we have primary home. If not, advise we are unable to offer a price due to underwriting guidelines'
    },
    50059: {
      title: 'More Than 2 Mortgages',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50060: {
      title: 'Commercial Occupation',
      agentScript: `What type of business is conducted at your home? \n
- If anything other than day care this business exposure does not meet our underwriting guidelines and we are unable to offer you a policy\n
- If daycare, please submit a copy of your current commercial liability policy for our review`,
      agentAction:
        'If commercial liability policy received and there are no other hazards noted from your review, we are required to allow policy binding. We can reach out every year for confirmation that the daycare is still in business and ask for a copy of the commercial renewal policy. If not received, we can non-renew for failure to provide required documentation'
    },
    50061: {
      title: 'Home Age Is Greater Than 40 Years',
      agentScript:
        'Based on the age of your home, we require a 4-POINT inspection to be completed and submitted prior to binding. The 4-POINT must have been completed within the past 12 months, and documents the age and condition of electrical, roof, heating and cooling system and plumbing systems. The inspection must be completed by a licensed general or building contractor, building inspector, architect, engineer or a member of the American Society of Home Inspectors (ASHI), the Florida Association of Building Inspectors (FABI) or the National Association of Certified Home Inspectors (NACHI).',
      agentAction:
        'If any of the systems are ineligible (age, condition, etc.) the offer is rejected. "Unfortunately, due to the (age, condition, type) of (electrical, plumbing, heating and cooling or roof) we are unable to offer you a policy at this time as it does not meet our underwriting guidelines.'
    },
    50062: {
      title: 'Shingle Or Wood Roof',
      agentScript:
        'HOME INELIGIBLE: Review risk for any defects - if multiple adverse factors exist on risk, "we are unable to offer a home policy due to risk management"'
    },
    50063: {
      title: 'Ineligible Occupancy',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50064: {
      title: 'Flat Roof',
      message:
        'Properties with flat roofs over living area may be ineligible with Branch depending upon the age of the roof and the state. If the flat roof is not over living area, it is acceptable to allow the home policy. Please follow your escalation process for Underwriting approval.',
      agentAction: 'Please escalate to underwriting with the appropriate documentation.',
      canBeOverridden: true
    },
    50065: {
      title: 'Tile Or Metal Roof over 25 Years Old',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50066: {
      title: 'Prior Loss Reported In Last 5 Years',
      agentScript:
        'In order to continue with a home policy, we need details of your prior loss and proof that the repairs were completed by a professional. Proof of repairs include paid receipts for work done that match up to the amount paid in your loss.',
      agentAction:
        "If can't provide or the proof of repairs does not match up to the amount of prior loss - decline due to failure to provide required documentation"
    },
    50067: {
      title: 'Two Property Or Liability Losses',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },
    50068: {
      title: 'No Prior Home Insurance',
      agentScript: `Please provide the reason why you have had a lapse of home insurance. \n If we are not allowing an exception for the lapse "we are unable to provide you a policy due to risk management"`,
      agentAction: 'If exception to be made, we must have a solid reason and no other adverse factors present'
    },
    50069: {
      title: 'Prior Home Policy Cancelled or Non-Renewed',
      agentScript: `Please provide the reason your prior home policy was canceled or non-renewed. \nIf we are not allowing an exception for the prior policy being cancelled or non-renewed "we are unable to provide you a policy due to risk management"`
    },
    50070: {
      title: 'Coverage A Greater Than 950K',
      agentScript: "Unfortunately, the dwelling coverage quoted exceeds Branch's maximum allowable limit",
      agentAction: 'Decline the offer - do not offer to lower dwelling coverage to make the risk eligible'
    },
    50071: {
      title: 'Sinkhole Coverage Requested',
      agentScript:
        'In order to continue to bind home coverage with the sinkhole endorsement, we need a sinkhole inspection completed by a qualified inspection company. Please note, these inspections are typically $300 paid by you. Your policy does cover you for catastrophic ground collapse coverage',
      agentAction: 'Must have UW manager AND product approval prior to allowing this coverage. '
    },
    50072: {
      title: 'Unconventional Construction Or Defective Materials',
      message: 'HOME INELIGIBLE: Properties with these characteristics are ineligible for our home program.'
    },

    50073: {
      title: 'Wind/Hail Exclusion Requested',
      agentScript:
        'Please completely fill out the wind exclusion form and submit so that we may be able to allow home policy binding',
      agentAction: 'Form has to be hand-written, not typed to be acceptable'
    },

    50074: {
      title: 'Requested No BI Coverage',
      message:
        'AUTO INELIGIBLE: This requires dev escalation if requested in order to offer auto. Independent Agents should reach out to Agency Support to resolve this error.'
    },

    50075: {
      title: 'Lapse In Coverage',
      agentScript:
        'Please provide the reason why you have had a lapse in auto insurance. \nIf we are not allowing an exception for the lapse "this does not meet our underwriting guidelines and we are unable to offer you a policy"',
      agentAction: 'If exception to be made, we must have a solid reason and no other adverse factors present'
    },

    50076: {
      title: 'Too Many PIP Claims In Last 3 years',
      message: 'AUTO INELIGIBLE: Drivers with these characteristics are ineligible in our program.'
    },

    50077: {
      title: 'Too Many At Fault Accidents In Last 3 years',
      message: 'AUTO INELIGIBLE: Drivers with these characteristics are ineligible in our program.'
    },

    50078: {
      title: 'Too Many Accidents(Regardless of Fault) In Last 3 years',
      message: 'AUTO INELIGIBLE: Drivers with these characteristics are ineligible in our program.'
    },

    50079: {
      title: 'More Than 2 Infractions In Last 3 years',
      message: 'HOME INELIGIBLE: Drivers with these characteristics are ineligible for our home program.'
    },

    50080: {
      title: 'Insurance Score Too Low/No Hit',
      message:
        'AUTO INELIGIBLE: Try to rerun insurance score once confirmed spelling of name, DOB, etc. If still no hit and other adverse factors are present advise "we are unable to provide an auto policy at this time due to risk management"'
    },

    50081: {
      title: 'MVR No Hit Or UDR',
      message:
        'AUTO INELIGIBLE: Try to rerun MVR once confirmed spelling of name, DOB, driver\'s license number, etc. If still no hit and other adverse factors are present advise "we are unable to provide an auto policy at this time due to risk management"'
    },

    50082: {
      title: 'SR22 Or FR44 Required',
      message:
        'AUTO INELIGIBLE: Potential members with these characteristics are ineligible in our program. If this is a mid term endorsement, this requires a dev escalation. Independent Agents, please reach out to Agency Support to complete this action.'
    },
    50083: {
      title: "We don't cover condos that are rented out",
      message:
        'CONDO INELIGIBLE: If you believe this encompasses an extenuating circumstance, reach out to an Underwriter representative.'
    },
    50084: {
      title: 'Monoline Home Rejection',
      message: 'Sorry, we are not able to offer monoline home.'
    },
    50085: {
      title: 'Monoline Condo Rejection',
      message: 'Sorry, we are not able to offer monoline condo.'
    },
    50086: {
      title: 'Monoline Auto Rejection',
      message: 'Sorry, we are not able to offer monoline auto.'
    },
    50087: {
      title: 'Primary Insured is Too Young',
      message: 'The age of the primary is too young to be eligible for our program.'
    },
    50088: {
      title: 'Secondary Home Rejection',
      message: 'Sorry, we are not able to offer insurance on secondary homes.'
    },
    50089: {
      title: 'Too Many Home Incidents For This State',
      message: 'Sorry, we are not able to offer insurance for this home at this time.'
    },
    50094: {
      title: 'Replacement Cost Plus IBSC Too High',
      message:
        'HOME INELIGIBLE: Coverage A plus IBSC exceeds our maximum threshold, which is $1.2M in BIX and $950,000 in other states.'
    },
    50095: {
      title: 'Risk concentrated in the area',
      message: 'HOME INELIGIBLE: We exceeded the max limit of risk concentration for that area.'
    },
    50096: {
      title: 'Is this property a [type_placeholder]?',
      message:
        'Our data sources indicate that the property at this address is not a valid property type for the policy type requested.',
      agentAction:
        'If you think the data pull information is incorrect, please enter in full home or condo details, make sure the right policy type is selected, and request the price again.',
      canBeOverridden: false
    },
    50097: {
      title: 'No Commercial Properties',
      message:
        'HOME INELIGIBLE: Our data sources indicate that the property at this address is in a "non-residential zone", and Branch does not insure non-residential properties in this state.',
      canBeOverridden: false
    },
    50098: {
      title: 'Unsupported Property',
      message:
        'HOME INELIGIBLE: Our data sources indicate that the property at this address is zoned for non-residential use, and Branch does not insure these types of properties in this state. This includes mobile homes, apartments, manufactured homes, and, in some states, condos.  If this is inaccurate, please re-run offer to solve.',
      canBeOverridden: false
    },
    50100: {
      title: '[type_placeholder] Improper Vehicle Rating',
      message: 'We do not have an accurate rating for a vehicle on this offer and cannot provide a price.',
      agentAction:
        'Please escalate to underwriting to review the XX auxiliary rating on this vehicle and see we can provide a price.',
      canBeOverridden: false
    },
    50101: {
      title: '[type_placeholder] Automated Data Pull Says: Land is too large',
      message: 'Our data sources indicate that the property at this address sits on many acres of land.',
      agentAction:
        'If you think the data pull information is incorrect, please escalate to underwriting with proof of how much land the property is on.',
      canBeOverridden: false
    },
    50102: {
      title: '[type_placeholder] Automated Data Pull Says: Asbestos material in property',
      message: 'Our data sources indicate that the home contains asbestos',
      agentAction:
        'If you think the data pull information is incorrect, please escalate to underwriting with proof of siding and roof materials.',
      canBeOverridden: false
    },
    50103: {
      title: '[type_placeholder] Automated Data Pull Says: Historic Home',
      message: 'Our data sources indicate that the home has historic restrictions and requirements',
      agentAction:
        'If you think the data pull information is incorrect, please escalate to underwriting with an explanation of the historic restrictions and requirements or lack thereof.',
      canBeOverridden: false
    },
    50104: {
      title: '[type_placeholder] Automated Data Pull Says: Branded title',
      message: 'Our data sources indicate that a vehicle is a salvage vehicle or otherwise has a branded title',
      agentAction:
        'Please escalate to underwriting with an explanation of the why the title is branded for additional review.',
      canBeOverridden: true
    },
    50105: {
      title: '[type_placeholder] Automated Data Pull Says: Vacant home',
      message: 'Our data sources indicate that the home is vacant',
      agentAction: 'Please escalate to underwriting with details on why the home is not vacant.',
      canBeOverridden: true
    },
    50106: {
      title: '[type_placeholder] Automated Data Pull Says: Unsupported roof material',
      message:
        'Our data sources indicate the property has ineligible roof material. This could include corrugated, built-up, composition rolled roof, membrane material, etc.',
      agentAction: 'Please escalate to underwriting with documentation to dispute roof material.',
      canBeOverridden: true
    },
    50107: {
      title: 'Ineligible [type_placeholder]: Foreclosure',
      message: 'Our data sources indicate that the property is in foreclosure',
      messageAsJsx: (
        <p>
          <p>
            <strong>Why?</strong>
            <br />
            According to our trusted data sources, this home has been flagged as in foreclosure or being purchased out
            of foreclosure. Branch does not cover homes that are in foreclosure.
          </p>
          <p>
            <strong>Next steps?</strong>
            <br />
            If this is inaccurate, please follow your designated escalation process for review.
            {selectedOption === 'A' &&
              ` This applicant is still eligible for Branch monoline auto${
                !isAgency && ', or we can help them find coverage through Branch Plus'
              }.`}
            {!selectedOption &&
              !isAgency &&
              ' Additionally, we can still help the applicant find coverage through Branch Plus.'}
          </p>
        </p>
      ),
      canBeOverridden: true
    },
    50108: {
      title: '[type_placeholder] Line of business restriction',
      message: 'We do not allow this line of business to be written in this state in this context',
      agentAction: 'We do not allow this line of business to be written in this state in this context.',
      canBeOverridden: false
    },
    // These errors prevent from quoting
    4000: {
      title: 'We have received an error',
      isUnknownError: true
    },
    4001: {
      title: 'We have received an error',
      isUnknownError: true
    },
    4002: {
      title: 'We have received an error',
      message:
        "Unfortunately, we are experiencing an issue with one of our data vendors and cannot provide you an offer at this time. Please check back later, or provide your email address and we'll let you know when your offer is ready"
    },
    4003: {
      title: 'We have received an error',
      message: 'Unfortunately, we are not able to write insurance policies in that state yet'
    },
    4004: {
      title: 'We have received an error',
      message: 'There was an error in the data for this quote. For dev: cluster.offers is undefined'
    },
    4005: {
      title: 'We have received an error',
      message:
        'There was an error in the information provided for this quote. Please check that the full name and address is correct'
    },
    5001: {
      title: 'We have received an error',
      message:
        "We couldn't find you at that address. Please provide your most recent address prior to the one you already entered."
    },
    5002: {
      title: 'We have received an error',
      message: 'We will need your date of birth to properly identify you and complete your offer'
    },
    5003: {
      title: 'We have received an error',
      message:
        'We were not able to find information on your current Auto insurance provider. Please enter your current coverage limits and continuous years that you have had auto insurance.'
    },
    5004: {
      title: 'We have received an error',
      message: 'We were not able to find some information on your home. Please enter the details here.'
    },
    5005: {
      title: 'We have received an error',
      message: 'Please enter a VIN to receive an auto offer.'
    },
    5006: {
      title: 'We have received an error',
      message: 'Unfortunately, we cannot offer you insurance for this vehicle'
    },
    5007: {
      title: 'We have received an error',
      message: 'Unfortunately, we cannot offer you a price for insurance at this time.'
    },
    5008: {
      title: 'We have received an error',
      message: 'Unfortunately, we are not able to write insurance policies in that state yet.'
    },
    5009: {
      title: 'We have received an error',
      message:
        'Unfortunately, we are not able to write insurance on that vehicle. Please enter a different VIN, or continue to the next step.'
    },
    5010: {
      title: 'We have received an error',
      message:
        "That VIN doesn't match any vehicle in our database. Please check for typos and try again, or try to add a different vehicle."
    },
    5011: {
      title: 'We have received an error',
      message: 'The address is in a zip code in which we currently have a moratorium.'
    },
    5012: {
      title: 'We have received an error',
      message: "We need a driver's license number to proceed."
    },
    5013: {
      title: 'We have received an error',
      message: 'We do not allow PO Box addresses.'
    },
    5014: {
      title: 'We have received an error',
      message: 'Returned minimum premium; likely a rater error. Cannot proceed.'
    },
    5015: {
      title: 'We have received an error',
      message: "The primary driver has a UDR and no driver's license; please put in a driver's license."
    },
    5016: {
      title: 'We have received an error',
      message: 'We need more information on the primary insured.'
    },
    5017: {
      title: 'We have received an error',
      message: 'This state is restricted to only certain originators of offers.'
    },
    5018: {
      title: 'We have received an error',
      message:
        'We had an error in setting fees; for example, in Kentucky, finding the right local premium taxing authority.'
    },
    5019: {
      title: 'We have received an error',
      message: 'Our systems thought this quote looked suspicious and so did not let it complete.'
    },
    5020: {
      title: 'We have received an error',
      message: 'The offer details as submitted appeared blank to the system.'
    },
    5021: {
      title: 'We have received an error',
      message: 'This person has been quoted too many times in a row.'
    },
    5022: {
      title: 'We have received an error',
      message:
        'This person has cancelled for non-payment too many times and will no longer be allowed to purchase a new policy.'
    },
    5023: {
      title: 'We have received an error',
      message:
        'We did not find latitude and longitude for this address. Please re-generate the offer with the correct latitude and longitude to see a price.'
    },
    5024: {
      title: 'We have received an error',
      message: `We do not have a rate for this zip code in this state. This is most likely because of a typo in the zipcode; please check whether the zip code exists in the state. The second-most common reason for failure is that the zip code doesn't have anyone living in it, and is only used for PO Boxes. If neither of these two are the case, please contact rating support.`
    },
    5025: {
      title: 'We have received an error',
      message: 'Please provide the unit# for this apartment in the secondary address field.'
    },
    5026: {
      title: 'We have received an error',
      message: 'We are not allowing online quotes in this state at this time.'
    },
    5027: {
      title: 'We have received an error',
      message: 'Due to capacity, we cannot offer you a price for insurance at this time.'
    },
    5028: {
      title: 'We have received an error',
      message: "Sorry, you can't create quotes because your affinity code is disabled."
    },
    5029: {
      title: 'We have received an error',
      message: 'This quote is in a restricted state.'
    },
    5030: {
      title: 'We have received an error',
      message: 'This offer is too old to update.'
    },
    5031: {
      title: 'We have received an error',
      message: 'We are not offering rideshare coverage at this time.'
    },
    5032: {
      title: 'We have received an error',
      message: 'Unable to bundle offer due to too many rejections.'
    },
    5033: {
      title: 'We have received an error',
      message: 'There are no available options for this offer.'
    },
    5034: {
      title: 'We have received an error',
      message: 'This quote returned with an auxiliary auto rate, we are unable to offer a quote at this time.'
    },
    5035: {
      title: 'Too many requests for this name and address simultaneously',
      message:
        'We block simultaneous requests for the same name and address. Please wait 30 seconds and try again, and do not double-click to submit.'
    },
    6001: {
      title: 'Error from Stripe: Bank account type is company.',
      message:
        'Our data sources identified that this is a company-owned bank account. Branch requires personal bank accounts for insurance purposes. To continue, please use a personal bank account or change the payment method to a credit card.',
      messageAsJsx: (
        <p>
          Our data sources identified that this is a company-owned bank account. Branch requires personal bank accounts
          for insurance purposes.
          <strong>
            {' '}
            To continue, please use a personal bank account or change the payment method to a credit card.
          </strong>
        </p>
      )
    },
    6002: {
      title: 'Error from Stripe: Payment method used on two or more Branch accounts',
      message:
        'Our data sources have identified that the anonymized ID of this payment method is used on more than 2 Branch accounts. Branch requires the same payment method to be used on less than 2 Branch accounts. To use this payment method, check and see if this offer needs to be linked with an existing member—otherwise use a different payment method.',
      messageAsJsx: (
        <p>
          Our data sources have identified that the anonymized ID of this payment method is used on more than 2 Branch
          accounts. Branch requires the same payment method to be used on less than 2 Branch accounts.{' '}
          <strong>
            To use this payment method, check and see if this offer needs to be linked with an existing member—otherwise
            use a different payment method.
          </strong>
        </p>
      )
    },
    5037: {
      title: ({ error }) => {
        if (error.message) {
          return `Ineligible auto: ${error.message}`;
        }
        return `Ineligible auto: A vehicle on this policy can't be covered due to underwriting reasons.`;
      },
      messageAsJsx: (
        <p>
          <p>
            <strong>Why? </strong>
            Branch does not cover vehicles that have a manufacturer's suggested retail price (MSRP) greater than $90k.
          </p>
          <p>
            <strong>Next steps? </strong>
            Remove the ineligible vehicle then click on "Update Offer Price" to continue. If this is inaccurate, please
            follow your designated escalation process for review.
          </p>
        </p>
      ),
      hideRerunOfferButton: true
    },
    0: {
      title: 'We have received an error',
      isUnknownError: true
    }
  };
};
