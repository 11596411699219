import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import useStyles from './mortgage-section.styles';

const MortgageSection = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Label type="greenSmall" className={classes.title}>
        {title}
      </Label>
      <Card>{children}</Card>
    </div>
  );
};

MortgageSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

MortgageSection.defaultProps = {
  title: null,
  children: null
};

export default MortgageSection;
