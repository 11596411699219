import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { paymentType, policyType } from '@ourbranch/lookups';

import { useStore } from 'core/store/store.mobx';
import { FormField } from 'core/components/form';
import { PolicyTabs } from 'core/store/account-store/policies-store/policy-store/policy-store';
import { UpdateMortgageNotification } from 'common/components/mortgage-details';
import { StripeCustomerIdField } from '../stripe-customer-id-field';
import useStyles from '../billing-details.styles';

const Mortgage = () => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const {
    account: {
      policies: { policy }
    }
  } = useStore();

  let property = {};
  if (policy.policyType === policyType.Home) {
    property = policy.segment?.home;
  } else if (policy.policyType === policyType.Condo) {
    property = policy.segment?.condo;
  }
  const primaryMortgageDetail = property.mortgageDetails?.find((mortgage) => mortgage.primary) || {};

  const goToDetailsTab = () => {
    policy.setCurrentTab(PolicyTabs.DETAILS);
    policy.setScrollTo('addMortgage');
  };

  useEffect(() => {
    setFieldValue('paymentType', paymentType.Escrow);
  }, [setFieldValue]);

  return (
    <>
      <StripeCustomerIdField />
      <Grid container justifyContent="flex-start" className={classes.billingDetailsContainer} spacing={4}>
        <FormField
          name="primaryMortgageDetail.mortgageHolderName"
          type="value"
          label="Lender Name"
          mode="dark"
          xs={4}
          value={primaryMortgageDetail.mortgageHolderName ? primaryMortgageDetail.mortgageHolderName : 'Not provided'}
        />
        <FormField
          name="primaryMortgageDetail.loanNumber"
          type="value"
          label="Loan Number"
          mode="dark"
          xs={4}
          value={primaryMortgageDetail.loanNumber ? primaryMortgageDetail.loanNumber : 'Not provided'}
        />
        <FormField
          name="primaryMortgageDetail.mortgageHolderAddress.address"
          type="value"
          label="Lender Address"
          xs={4}
          mode="dark"
          value={
            primaryMortgageDetail.mortgageHolderAddress?.address
              ? `${primaryMortgageDetail.mortgageHolderAddress.address}, ${primaryMortgageDetail.mortgageHolderAddress.city}, 
                ${primaryMortgageDetail.mortgageHolderAddress.state}, ${primaryMortgageDetail.mortgageHolderAddress.zip}`
              : 'Not provided'
          }
        />
        <Grid item xs={12}>
          <UpdateMortgageNotification
            isMortgageAdded={!!primaryMortgageDetail.mortgageHolderName}
            onNavigateToDetails={goToDetailsTab}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default observer(Mortgage);
