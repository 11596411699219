import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form as FormikForm, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { mortgageLookups } from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { AuthContext } from 'core/components/auth';
import AddressAutocomplete from 'core/components/address-autocomplete';
import { useScroll } from 'core/hooks/use-scroll';
import { getMatchingLenders, trackCustomMortgageLender } from 'offer/helpers/mortgage-helpers';
import { mortgageValidationSchema } from '../mortgage-details.validation-schema';
import { CustomLenderModal } from '../custom-lender-modal';
import useStyles from './add-mortgage.styles';

const initialValues = {
  loanNumber: '',
  mortgageHolderAddress: {
    zip: '',
    address: '',
    city: '',
    state: ''
  },
  mortgageHolderName: '',
  primary: false,
  isCustom: false
};

const mortgageList = mortgageLookups?.map((mortgage, index) => ({
  id: index,
  text: mortgage.correctName
}));

const AddMortgage = ({ disabled, onAdd }) => {
  const classes = useStyles();
  const { viewOnly } = useContext(AuthContext);
  const {
    account: {
      policies: { policy: policyStore }
    },
    offer
  } = useStore();

  const { policy } = policyStore;
  const [showModal, setShowModal] = useState(false);
  const [existingMatchingLenders, setExistingMatchingLenders] = useState([]);
  const idToTrack = policy?.id ? { policyId: policy?.id } : { offerId: offer?.offer?.id };

  const afterScroll = useCallback(() => {
    if (offer) {
      offer.setScrollTo(null);
    } else if (policyStore) {
      policyStore.setScrollTo(null);
    }
  }, [offer, policyStore]);
  useScroll(offer?.scrollTo || policyStore?.scrollTo, afterScroll);

  return (
    <Formik initialValues={initialValues} validationSchema={mortgageValidationSchema} onSubmit={() => {}}>
      {({ setFieldValue, setFieldTouched, values, validateForm, setErrors, setTouched }) => {
        return (
          <FormikForm className={classes.container}>
            <Grid
              id="addMortgage"
              key="container"
              container
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={4}
            >
              <Label className={classes.addLabel} type="greenSmall">
                Add mortgage lender
              </Label>
              <FormField
                type="mortgageLenderName"
                mode="light"
                name="mortgageHolderName"
                id="mortgageHolderName"
                options={mortgageList}
                onSelection={(_, mortgageLender) => {
                  if (!mortgageLender) return;

                  const mortgage = mortgageLookups.find(({ correctName }) => correctName === mortgageLender.text);
                  if (mortgage) {
                    const { address, address2, city, state, zip, id: mortgageID } = mortgage;
                    setFieldValue('mortgageHolderAddress', { address, address2, city, state, zip });
                    setFieldValue('id', mortgageID || mortgageLender.id);
                    setFieldTouched('mortgageHolderAddress', true, false);
                  }
                }}
                label="Lender"
                helperText="Enter the name of the lender to populate matches"
                xs={12}
                permissions={{ isLicensedAction: false }}
                showChevronIcon
                showDeleteIcon
              />
              <AddressAutocomplete
                mode="light"
                key={values.id}
                name="mortgageHolderAddress.address"
                id="mortgageHolderAddress.address"
                type="string"
                label="Lender Address"
                xs={8}
                permissions={{ isLicensedAction: false }}
              />
              <FormField
                mode="light"
                name="mortgageHolderAddress.address2"
                id="mortgageHolderAddress.address2"
                label="Address 2"
                type="string"
                optional
                xs={4}
                permissions={{ isLicensedAction: false }}
              />
              <FormField
                mode="light"
                name="mortgageHolderAddress.city"
                id="mortgageHolderAddress.city"
                label="City"
                type="string"
                xs={4}
                permissions={{ isLicensedAction: false }}
              />
              <FormField
                mode="light"
                name="mortgageHolderAddress.state"
                id="mortgageHolderAddress.state"
                label="State"
                type="state"
                xs={4}
                permissions={{ isLicensedAction: false }}
              />
              <FormField
                mode="light"
                name="mortgageHolderAddress.zip"
                id="mortgageHolderAddress.zip"
                label="Zip Code"
                type="string"
                xs={4}
                permissions={{ isLicensedAction: false }}
              />
              <FormField
                mode="light"
                name="loanNumber"
                id="loanNumber"
                label="Loan Number"
                type="string"
                xs={12}
                permissions={{ isLicensedAction: false }}
              />
              <Grid container className={classes.buttonContainer}>
                <Button
                  key="addButton"
                  type="submit"
                  mode="big"
                  variant="contained"
                  color="secondary"
                  disabled={disabled || viewOnly}
                  onClick={() => {
                    validateForm().then((errors) => {
                      if (Object.keys(errors).length) {
                        setErrors(errors);
                        setTouched(errors);
                        return;
                      }

                      if (values.isCustom) {
                        const customLenderAddress = `${values.mortgageHolderAddress.address}, ${values.mortgageHolderAddress.city}, ${values.mortgageHolderAddress.state}, ${values.mortgageHolderAddress.zip}`;
                        const matchingLenders = getMatchingLenders(values.mortgageHolderName, customLenderAddress);

                        if (matchingLenders.length > 0) {
                          setExistingMatchingLenders(matchingLenders);
                          setShowModal(true);
                        } else {
                          trackCustomMortgageLender(true, idToTrack, values);
                          onAdd(values);
                        }
                      } else {
                        onAdd(values);
                      }
                    });
                  }}
                >
                  Add mortgage lender
                </Button>
              </Grid>
            </Grid>
            {showModal && (
              <CustomLenderModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={async (selectedLender) => {
                  if (selectedLender.isCustom) {
                    trackCustomMortgageLender(true, idToTrack, values);
                    onAdd(values);
                  } else {
                    const {
                      address,
                      address2,
                      city,
                      state,
                      zip,
                      id: mortgageID,
                      correctName
                    } = existingMatchingLenders[selectedLender.index];
                    const existingLenderValues = {
                      isCustom: false,
                      mortgageHolderName: correctName,
                      mortgageHolderAddress: { address, address2, city, state, zip },
                      id: mortgageID,
                      loanNumber: values.loanNumber,
                      primary: false
                    };
                    onAdd(existingLenderValues);
                  }
                }}
                existingMortgages={existingMatchingLenders}
                customMortgage={{
                  correctName: values.mortgageHolderName,
                  ...values.mortgageHolderAddress
                }}
              />
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
};
AddMortgage.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default AddMortgage;
