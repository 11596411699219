import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  // Price details
  billingDetails: {
    padding: theme.spacing(5, 8, 4),
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`
  },
  billingInfo: {
    marginBottom: 0
  },
  pastDueRow: {
    backgroundColor: theme.colorPalette.red_20
  },
  holdPaymentButton: {
    padding: 0,
    fontWeight: 600
  },
  holdPaymentButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  holdPaymentWarning: {
    display: 'flex'
  },
  manualChargeContainer: {
    padding: theme.spacing(4, 8),
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  rowTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  // Payment Details
  tableBodyPaymentMethodLast4: {
    display: 'inline-flex',
    color: theme.colorPalette.beige_10_op_60,
    width: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
    '& > span': {
      margin: '0 4px'
    }
  },
  creditCardLast4: {
    composes: '$tableBodyPaymentMethodLast4',
    display: 'block',
    fontSize: 16,
    width: '50%'
  },
  tableRow: {
    height: 47,
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  tableCell: {
    borderBottom: 'none',
    color: theme.colors.light_text,
    fontSize: 14
  },
  sectionContainer: {
    padding: theme.spacing(4, 8)
  },
  // manual charge
  submit: {
    width: 168,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  spaceBetweenRow: {
    justifyContent: 'space-between'
  },
  // manualChargeForm: {
  //   paddingTop: theme.spacing(2)
  // },
  loadingBar: {
    height: 2
  },
  stripeCustomerLink: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  warningText: {
    fontSize: 10,
    margin: 0,
    color: theme.colorPalette.white_10,
    justifyContent: 'center'
  },
  refundSectionContainer: {
    padding: theme.spacing(4, 8),
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${theme.colorPalette.white_40_op_70}`
  },
  paymentRow: {
    marginTop: theme.spacing(1)
  },
  refundButton: {
    width: 154,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  smallIcon: {
    width: 15,
    height: 15
  },
  centeredContentContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'center',
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colors.light_text,
    padding: theme.spacing(2)
  }
});

export default makeStyles(styles);
