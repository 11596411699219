// @NOTE this way to set the styles no longer works for components,
// since material changed the way it handles disabled and errored states
// If we need to support light and dark colors/background for texts we'll need to handle
// dark and light themes, and render components on the theme context. I'm patching fow now,
// to avoid showing orange colors to our users. But this is far from the best/correct solution.
const styles = (theme) => ({
  'light-textFieldRoot': {
    backgroundColor: theme.colors.text_secondary_background
  },
  'light-textFieldInput': {
    color: theme.colors.text_secondary
  },
  'light-textFieldDisabled': {
    color: `${theme.colorPalette.green_10} !important`
  },
  'light-labelRoot': {
    color: theme.colors.text_disabled,
    zIndex: 1
  },
  'light-labelShrink': {
    color: theme.colors.text_secondary,
    zIndex: 1
  },
  'light-labelDisabled': {
    color: `${theme.colors.text_secondary} !important`
  },
  'light-labelFocused': {
    color: `${theme.colors.text_secondary} !important`
  },
  'dark-labelRoot': {
    minWidth: 195
  },
  'dark-textFieldDisabled': {
    color: `${theme.colorPalette.white_10} !important`
  },
  'dark-labelDisabled': {
    color: `${theme.colorPalette.white_10} !important`
  },
  'red-labelShrink': {
    color: theme.colorPalette.white
  },
  'red-textFieldRoot': {
    backgroundColor: theme.colors.white
  },
  'red-textFieldInput': {
    color: theme.colors.text_secondary,
    zIndex: 2
  }
});

export default styles;
