import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderCell } from 'core';

const columns = {
  firstName: (
    <TableHeaderCell key="fname" columnId="fname">
      First Name
    </TableHeaderCell>
  ),
  lastName: (
    <TableHeaderCell key="lname" columnId="lname">
      Last Name
    </TableHeaderCell>
  ),
  address: (
    <TableHeaderCell key="address" columnId="address">
      Address
    </TableHeaderCell>
  ),
  state: (
    <TableHeaderCell key="state" columnId="state">
      State
    </TableHeaderCell>
  ),
  memberId: (
    <TableHeaderCell key="memberId" columnId="memberId">
      Branch Member ID
    </TableHeaderCell>
  ),
  carrier: (
    <TableHeaderCell key="carrier" columnId="carrier">
      Carrier
    </TableHeaderCell>
  ),
  policyType: (
    <TableHeaderCell key="policyType" columnId="policyType">
      Policy Type
    </TableHeaderCell>
  ),
  navigate: <TableHeaderCell key="navigate" columnId="navigate" />
};

const Header = ({ columnsToUse }) => columnsToUse.map((name) => columns[name]);

Header.propTypes = {
  columnsToUse: PropTypes.array.isRequired
};

export default Header;
