import xorWith from 'lodash-es/xorWith';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import intersectionWith from 'lodash-es/intersectionWith';

export const isArrayOfObjectsEqual = (array1, array2) => {
  isEmpty(xorWith(array1, array2, isEqual));
};

export const doesArrayHaveNewItems = (originalArray, newArray) =>
  intersectionWith(originalArray, newArray, isEqual).length !== newArray.length;

const normalizeString = (string) => string.toLowerCase().trim();

export const areStringsEqual = (a, b) => normalizeString(a) === normalizeString(b);
