import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Label } from 'core/components/label';
import { capitalize, phoneNumberToStringFormatter } from 'core/helpers/formatters';
import { InfoField } from '../../info-field';
import { InnerSection } from './inner-section';
import useStyles from './sections.styles';

const MemberInformation = ({ customer }) => {
  const classes = useStyles();
  const history = useHistory();
  const { firstName, lastName, email, phoneNumber, accountId } = customer;
  return (
    <InnerSection title="Member information">
      <InfoField label="First name" value={firstName} formatter={capitalize} xs={4} />
      <InfoField label="Last name" value={lastName} formatter={capitalize} xs={4} />
      <InfoField label="Email" value={email} xs={4} />
      <InfoField label="Phone number" value={phoneNumber} xs={4} formatter={phoneNumberToStringFormatter} />
      {accountId && (
        <InfoField
          label="Branch member Id"
          value={
            <Label
              type="infoValue"
              className={classes.link}
              onClick={() => {
                history.push(`/customer/${accountId}`);
              }}
            >
              {accountId}
            </Label>
          }
          xs={4}
        />
      )}
    </InnerSection>
  );
};

MemberInformation.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string,
    phoneNumber: PropTypes.string.isRequired
  }).isRequired
};

export default MemberInformation;
