import { makeStyles } from '@material-ui/core/styles';
import { screens } from 'core/components/theme/variables';

const styles = () => ({
  form: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexBasis: 'auto'
  },
  cancelFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  endDateContainer: {
    marginTop: -14,
    paddingLeft: 8,
    width: 188
  },
  endDate: {
    width: 188
  },
  cancelReasonSmall: {
    width: 284,
    [`@media (min-width:${screens.medium_desktop}px)px)`]: {
      width: 404
    },
    [`@media (min-width: ${screens.big_desktop}px)`]: {
      width: 480
    }
  },
  cancelReasonBig: {
    width: 480
  }
});

export default makeStyles(styles);
