import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    width: '100%'
  },
  label: {
    width: '100%'
  },
  radio: {
    marginBottom: theme.spacing(4)
  },
  informationContainer: {
    marginLeft: theme.spacing(2)
  },
  lastOptionInfoContainer: {
    marginBottom: theme.spacing(4)
  },
  separator: {
    borderBottom: `1px solid ${theme.colorPalette.beige_10}`,
    margin: theme.spacing(2, 0)
  },
  lastOption: {
    border: 'unset',
    margin: theme.spacing(0, 0, 4)
  }
}));

export default useStyles;
