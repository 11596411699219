/* eslint-disable func-names */
import * as Yup from 'yup';
import { validHomePurchaseDate } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.mixed, 'validHomePurchaseDate', validHomePurchaseDate);

const schema = Yup.object().shape({
  condo: Yup.object().shape({
    purchaseDate: Yup.string().validHomePurchaseDate('home.purchaseDate', false)
  }),
  condoCoverage: Yup.object().shape({
    coverageC: Yup.number()
      .test('coverageC min limit', 'Personal Property Limit cannot be lower than $10K.', function (currentCoverageC) {
        return currentCoverageC >= 10000;
      })
      .test(
        'coverageC max limit',
        'Personal Property Limit value cannot be higher than $500K',
        function (currentCoverageC) {
          return currentCoverageC <= 500000;
        }
      ),
    coverageA: Yup.number()
      .test(
        'coverageA min limit',
        'Building Property Protection cannot be lower than $50K.',
        function (currentCoverageA) {
          return currentCoverageA >= 50000;
        }
      )
      .test(
        'coverageA max limit',
        'Dwelling limit value cannot be higher than 150% RCE',
        function (
          currentCoverageA,
          {
            options: {
              context: { condo }
            }
          }
        ) {
          const max = condo.replacementCostEstimate.total * 1.5;
          if (currentCoverageA > max) {
            return this.createError({
              message: `Building Property Protection cannot exceed $${max}`
            });
          }
          return true;
        }
      )
  })
});

export default schema;
