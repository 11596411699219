export const autoOnly = () => import('./auto-only');
export const homeOnly = () => import('./home-only');
export const somethingWrong = () => import('./something-wrong');
export const offerUpdated = () => import('./offer-updated');
export const powerOutage = () => import('./power-outage');
export const purchaseFailed = () => import('./purchase-failed');
export const branchSpinner = () => import('./branch-spinner');
export const vinDiscrepancy = () => import('./vin-discrepancy');
export const mvrRejected = () => import('./mvr-dialogs/mvr-rejected');
export const mvrRetry = () => import('./mvr-dialogs/mvr-retry');
export const brandedTitleCarsWarning = () => import('./branded-title-cars-warning');
