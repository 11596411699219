import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import {
  driverCarAssignmentStates,
  editableResidenceInsuranceType,
  isResidentialOwnerStates,
  standaloneRentersStates,
  nonQuinstreetStates,
  policyType as PolicyType
} from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import FormList from 'core/components/form-list';
import AddDriver from 'common/components/people/add-form';
import CarAssignment from 'common/components/auto/car-assignment';
import { PersonalInjuryProtection } from 'common/components/personal-injury-protection';
import { useDisabledState } from 'common/disabled-context';
import { Coverage } from 'common/components/auto/auto-coverage';
import Cars from 'common/components/auto/cars';
import Trailers from 'common/components/auto/trailers';
import Renters from 'common/components/auto/renters';
import { ResidenceInsuranceType } from 'common/components/auto/residence-insurance-type';
import PriorCoverage from 'common/components/auto/prior-coverage';
import { QuinStreet } from 'common/components/quinstreet';
import Discounts from 'customer/components/policy/discounts';
import { PolicyDriver } from '../driver';

function AutoPolicy() {
  const session = useContext(AuthContext);
  const {
    account: {
      policies: {
        policy: { geographicState: state, policy, getAllowLicensedActions }
      }
    },
    account: {
      policies: { hasUmbrella }
    }
  } = useStore();
  const { disabled } = useDisabledState();

  const isQuinstreetAvailable = session?.canQuoteQuinstreet && !nonQuinstreetStates.includes(state);

  const allowLicensedActions = getAllowLicensedActions(session);

  return (
    <>
      <FormList
        disabled={disabled || !allowLicensedActions}
        id="drivers"
        item={PolicyDriver}
        addForm={AddDriver}
        addLabel="Add Driver"
        title="Drivers"
        singular="driver"
        plural="drivers"
        fromPolicy
        hasAuto
      />
      <Cars disabled={disabled} fromPolicy />
      {driverCarAssignmentStates[state] && <CarAssignment state={state} disabled={disabled} />}
      <Trailers disabled={disabled} policy={policy} />
      {(editableResidenceInsuranceType.includes(state) || isResidentialOwnerStates.includes(state)) && (
        <ResidenceInsuranceType disabled={disabled} />
      )}
      <Coverage disabled={disabled} policy={policy} hasUmbrella={hasUmbrella} />
      <PriorCoverage />
      {!standaloneRentersStates[state] && <Renters disabled={disabled} />}
      {isQuinstreetAvailable && <QuinStreet fromPolicy policyType={PolicyType.Auto} />}
      <Discounts disabled={disabled} />
      {state === 'MI' && <PersonalInjuryProtection />}
    </>
  );
}

export default observer(AutoPolicy);
