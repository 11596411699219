import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import BaseDialog from 'core/components/base-dialog';

import { Label } from 'core/components/label';
import useStyles from './warning-modal.styles';

const reasonsData = {
  BRANDED_TITLE_CAR_ADDED: {
    title: 'Please review coverages on the vehicle(s) you just added',
    description:
      'This/These vehicle(s) only qualifies for liability coverage with Branch because our title data sources indicate that they were rebuilt, restored, or were a manufacturer lemon buyback. Any selections for comprehensive or collision deductibles, roadside, loan/lease, rental coverage, rideshare coverage, or additional custom parts, will be removed. Please review these changes before continuing to other changes.'
  }
};

function WarningModal({ onClose, open, reason }) {
  const classes = useStyles();
  const { title, description } = reasonsData[reason];
  return (
    <BaseDialog size="lg" open={open}>
      <div className={classes.container}>
        <Label className={classes.title} type="greenBig">
          {title}
        </Label>
        <Label type="body1">{description}</Label>
        <div className={classes.footer}>
          <Button className={classes.button} variant="contained" color="primary" type="button" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </BaseDialog>
  );
}

WarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reason: PropTypes.string.isRequired
};

export default WarningModal;
