import { useRouteMatch } from 'react-router-dom';
import { flatMap } from 'lodash-es';

import { tools } from '../tools-list/constants';

/**
 * Uses the current frontend route to find the tool name in the tools-list/constants file.
 *
 * @returns {string} - The name of the tool
 */
export const useToolName = () => {
  const { path } = useRouteMatch();
  const toolsList = flatMap(tools.map((tool) => tool.items));
  const toolName = toolsList.find((tool) => tool.path === path)?.toolName;
  return toolName;
};
