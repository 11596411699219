import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  field: {
    wordBreak: 'break-word',
    marginBottom: 0
  }
});

export default makeStyles(styles);
