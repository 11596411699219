import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { Label } from '../label';
import { Tooltip } from '../tooltip';
import useStyles from './section.styles';

const leftTypes = {
  SubSection: 'infoSubtitle',
  Section: 'subtitle',
  InnerSection: 'greenSmall'
};

function Section({ title, titleTooltipText, type, rightLabel, rightValue, right, children, className }) {
  const hasHeader = !!title || (rightLabel && rightValue) || !!right;
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, className)}>
      {hasHeader && (
        <Grid container className={classes.header} justifyContent="space-between">
          {title && (
            <Grid item className={classes.titleContainer}>
              <Label type={leftTypes[type]} className={classes.title} data-cy="section-title">
                {title}
              </Label>
              {titleTooltipText && <Tooltip text={titleTooltipText} label="More Info" />}
            </Grid>
          )}
          <Grid item>
            {rightLabel && rightValue && (
              <div className={classes.rightContainer}>
                <Label type="body4" className={classes.rightLabel}>
                  {rightLabel}
                </Label>
                <Label type="body4" className={classes.rightValue}>
                  {rightValue}
                </Label>
              </div>
            )}
            {right}
          </Grid>
        </Grid>
      )}
      {children}
    </div>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  titleTooltipText: PropTypes.string,
  type: PropTypes.oneOf(['Section', 'SubSection', 'InnerSection']),
  rightLabel: PropTypes.string,
  rightValue: PropTypes.string,
  className: PropTypes.string,
  right: PropTypes.node,
  children: PropTypes.node
};

Section.defaultProps = {
  type: 'Section',
  title: null,
  titleTooltipText: null,
  rightLabel: '',
  rightValue: '',
  className: null,
  right: null,
  children: null
};

export default Section;
