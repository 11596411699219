import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button, Divider } from '@material-ui/core';

import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import BranchTermLifeModal from 'common/components/branch-term-life-modal';
import AssurityTermLifeModal from 'common/components/assurity-life-modal';
import useStyles from './additional-offers-section.styles';
import CheckIcon from 'core/assets/svg/check-white.svg';

const AdditionalOffersSection = ({ branchTermLife = false, assurityTermLife = false, assurityTermLifeData }) => {
  const {
    offer: { offer },
    additionalOffers
  } = useStore();
  const webUserId = offer?.webUserId;
  useEffect(() => {
    if (webUserId && webUserId !== additionalOffers?.webUserId) {
      additionalOffers.getAdditionalOfferData(webUserId);
    }
  }, [webUserId]);

  const [showBranchTermLifeModal, setShowBranchTermLifeModal] = useState(false);
  const [showAssurityTermLifeModal, setShowAssurityTermLifeModal] = useState(false);

  const { viewOnly } = useContext(AuthContext);

  const classes = useStyles();

  return (
    <>
      <Label type="darkGreenMedium" component="div" className={classes.sectionTitleLabel}>
        Additional Offers
      </Label>
      {assurityTermLife && (
        <Card className={classes.card}>
          <div className={classes.cardTop}>
            <div>
              <Label className={classes.label}>(New) Term Life Insurance</Label>
              <Label className={classes.subtitle}>From Assurity, with more options</Label>
            </div>
            <Button
              disabled={viewOnly}
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => setShowAssurityTermLifeModal(true)}
            >
              See Offer
            </Button>
          </div>
        </Card>
      )}
      {branchTermLife && (
        <Card className={classes.card}>
          <div className={classes.cardTop}>
            <div>
              <Label className={classes.label}>Term Life Insurance</Label>
              <Label className={classes.subtitle}>Built within Branch's quoting platform</Label>
            </div>
            <Button
              disabled={viewOnly}
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => setShowBranchTermLifeModal(true)}
            >
              View details
            </Button>
          </div>
          {additionalOffers.termLifeLastSubmitted && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.bottomMessage}>
                <img src={CheckIcon} alt="check icon" />
                <Label className={classes.lowerLabel}>{additionalOffers.termLifeLastSubmitted}</Label>
              </div>
            </>
          )}
        </Card>
      )}
      {showBranchTermLifeModal && (
        <BranchTermLifeModal open={showBranchTermLifeModal} onClose={() => setShowBranchTermLifeModal(false)} />
      )}
      {showAssurityTermLifeModal && (
        <AssurityTermLifeModal
          open={showAssurityTermLifeModal}
          assurityData={assurityTermLifeData}
          onClose={() => setShowAssurityTermLifeModal(false)}
        />
      )}
    </>
  );
};

export default observer(AdditionalOffersSection);
