import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  value: {
    textTransform: 'uppercase',
    color: theme.colors.light_text
  }
});

export default makeStyles(styles);
