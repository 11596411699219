import React from 'react';
import Grid from '@material-ui/core/Grid';
import { lookupsJson } from '@ourbranch/lookups';

import { useDisabledState } from 'common/disabled-context';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';

import useStyles from './renters-coverage.styles';

const id = 'rentersCoverage';

const RentersCoverage = () => {
  const classes = useStyles();
  const { disabled } = useDisabledState();

  return (
    <Grid container justifyContent="space-around" alignItems="flex-start" spacing={4} className={classes.containerDark}>
      <Grid item xs={6}>
        <LabelTooltip
          label="Personal Liability Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.personalLiabilityCoverage }}
        >
          <FormField
            mode="dark"
            name={`${id}.coverageX`}
            id={`${id}.coverageX`}
            options={lookupsJson.rentersLiability}
            type="select"
            disabled={disabled}
          />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="PP/AL Expense Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.PPALExpenseCoverage }}
        >
          <FormField
            mode="dark"
            name={`${id}.coverageCD`}
            id={`${id}.coverageCD`}
            options={lookupsJson.coverageCD}
            type="select"
            disabled={disabled}
          />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="Deductible"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleAllOther }}
        >
          <FormField
            mode="dark"
            name={`${id}.deductible`}
            id={`${id}.deductible`}
            options={lookupsJson.rentersDeductible}
            type="select"
            disabled={disabled}
          />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="Guest Medical Limit"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.guestMedicalLimit }}
        >
          <FormField
            mode="dark"
            name={`${id}.coverageY`}
            id={`${id}.coverageY`}
            options={lookupsJson.rentersCoverageY}
            type="select"
            disabled={disabled}
          />
        </LabelTooltip>
      </Grid>
    </Grid>
  );
};

export default RentersCoverage;
