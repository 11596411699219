import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { floridaHomeSoftRejectCodes, floridaAutoSoftRejectCodes } from '@ourbranch/lookups';

import { Error } from 'common/components/error';
import { messages } from 'common/constants/error-messages';
import useErrors from 'common/hooks/use-errors';

import { track } from 'core/helpers/analytics';
import { useStore } from 'core/store';
import useSession from 'core/hooks/use-session';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { ConfirmOverrideModal } from 'common/components/error/confirm-override-modal';
import useStyles from './policy-errors.styles';

const PolicyErrors = observer(() => {
  const router = useHistory();
  const classes = useStyles();
  const { offer: store, quote } = useStore();
  const { canClearUWFormRejections, viewOnly, isAgency, user } = useSession();
  const [errorToOverride, setErrorToOverride] = useState(null);
  const [showOverrideUWErrorModal, setShowOverrideUWErrorModal] = useState(false);
  const errorsAndRejections = useErrors(store.errors, (code) => code >= 4003);

  const onRequoteClick = (errorCode) => {
    quote.setPrefillData(store.offer, true);
    const { message: errorMessage } = messages(store.state)[errorCode];
    track('Re-run Offer', {
      errorCode,
      errorMessage,
      offerId: store.offer.id,
      staffUsername: user.username,
      isAgency,
      webuserId: store.offer.webUserId,
      offerType: store.offer.quote.selectedOption
    });
    router.push('/quote');
  };

  return (
    <>
      {showOverrideUWErrorModal && (
        <ConfirmOverrideModal error={errorToOverride} onClose={() => setShowOverrideUWErrorModal(false)} />
      )}
      <Label id="policyErrors" type="darkGreenMedium" className={classes.errorsLabel} component="div">
        Policy Errors
      </Label>
      {errorsAndRejections.map((error) => (
        <Card key={`${store.offer.id}-${error.code}`} className={classes.errorCard} type="primaryEditable">
          <Error error={error} state={store.state} type="tertiary">
            {!messages(store.state)[error.code]?.hideRerunOfferButton && (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={viewOnly}
                onClick={() => onRequoteClick(error.code)}
              >
                Re-run offer to solve
              </Button>
            )}
          </Error>
          {canClearUWFormRejections &&
            store.state === 'FL' &&
            (floridaHomeSoftRejectCodes.includes(error.code) || floridaAutoSoftRejectCodes.includes(error.code)) && (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={viewOnly}
                onClick={() => {
                  setShowOverrideUWErrorModal(true);
                  setErrorToOverride(error);
                }}
              >
                Override error
              </Button>
            )}
        </Card>
      ))}
    </>
  );
});

export default PolicyErrors;
