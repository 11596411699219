import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import useStyles from './pending-third-party-notification.styles';

const PendingThirdPartyNotification = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Label className={classes.styledText}>There are pending third party purchases.</Label>
      <Button className={classes.button} onClick={onClick}>
        Show me
      </Button>
    </Grid>
  );
};

PendingThirdPartyNotification.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default PendingThirdPartyNotification;
