import gql from 'graphql-tag';

export const GET_PAYMENT_METHOD_DETAILS_MULTIPLE = gql`
  query ($accountId: ID!, $policyIds: [String]) {
    getACHVerificationStatus(accountId: $accountId, policyIds: $policyIds) {
      achStatus
      policyId
      last4
      stripeBankAccountId
    }
  }
`;

export const VERIFY_BANK_ACCOUNT = gql`
  query ($verificationDetails: VerifyMicrodepositsInput!) {
    verifyMicrodeposits(verificationDetails: $verificationDetails) {
      status
    }
  }
`;
export const DOWNLOAD_ANON_POLICY_DOC = gql`
  query ($policyId: ID!, $asOfDate: String!) {
    signedUrl: getRedactedPolicyPDF(policyId: $policyId, asOfDate: $asOfDate)
  }
`;
