import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.card_background_secondary,
    overflow: 'hidden',
    padding: theme.spacing(6, 8)
  },
  switch: {
    marginLeft: theme.spacing(1)
  },
  logosContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.button__background_primary,
    justifyContent: 'space-around',
    gap: theme.spacing(1),
    padding: theme.spacing(8)
  },
  loadingContainer: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(4),
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.button__background_primary
  },
  skeleton: {
    backgroundColor: theme.colorPalette.green_75,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  divider: {
    backgroundColor: theme.colorPalette.green_15,
    width: '100%'
  },
  loadingContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(8, 0, 2)
  },
  outlineContainer: {
    border: `2px solid ${theme.colorPalette.green_15}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
    color: theme.colorPalette.white_30,
    borderRadius: theme.sizes.roundCorners,
    marginTop: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(4)
  }
}));

export default useStyles;
