import gql from 'graphql-tag';

import { BRANCH_PLUS_POLICY_FRAGMENT } from 'core/fragments/branch-plus-policy';

export const GET_BRANCH_PLUS_POLICY = gql`
  query ($policyId: String!) {
    branchPlus: getBranchPlusPolicy(policyId: $policyId) {
      ... on BranchPlusPolicyResponse {
        success
        policy {
          ...BranchPlusPolicyFragment
        }
      }
      ... on BranchPlusPolicyError {
        success
        error
      }
    }
  }
  ${BRANCH_PLUS_POLICY_FRAGMENT}
`;
