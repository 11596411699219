import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ClaimTitle from './claim-title';
import ClaimInfo from './claim-info';
import ExposureInfo from './exposure-info';
import CommunicationHistory from './communication-history';
import BaseDialog from 'core/components/base-dialog';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';

const BranchClaimDetail = ({ claim, open, hideBranchClaimDetail, exposureDetail }) => {
  const { isAgency } = useSession();
  const { account } = useStore();

  // Get the exposures for this specific claim
  const currentClaimExposureDetail = exposureDetail.filter(
    (exposure) => exposure.occurrence === claim.claimNumber || exposure.exposureNumber === claim.claimNumber
  );

  useEffect(() => {
    // Get the notes for this specific claim
    if (currentClaimExposureDetail?.[0]?.claimId && !account.notesDetailLoading) {
      account.getSnapsheetNotesDetail(currentClaimExposureDetail?.[0]?.claimId);
    }
  }, []);

  return (
    <BaseDialog open={open} onClose={hideBranchClaimDetail} size="md">
      <ClaimTitle claim={claim} />
      <ClaimInfo claim={claim} />
      <ExposureInfo currentClaimExposureDetail={currentClaimExposureDetail} />
      {!isAgency && <CommunicationHistory currentClaimExposureDetail={currentClaimExposureDetail} />}
    </BaseDialog>
  );
};

BranchClaimDetail.propTypes = {
  claim: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  hideBranchClaimDetail: PropTypes.bool.isRequired,
  exposureDetail: PropTypes.array.isRequired
};

export default observer(BranchClaimDetail);
