export const communicationType = {
  NOTES: 'NOTES',
  SMS: 'SMS',
  EMAILS: 'EMAILS',
  EMAIL: 'EMAIL',
  PHONE_CALL_INBOUND: 'PHONE_CALL_INBOUND',
  PHONE_CALL_OUTBOUND: 'PHONE_CALL_OUTBOUND',
  COMMENT: 'COMMENT'
};

export const labelStyleMap = {
  OPEN: 'statusOpen',
  CLOSED: 'statusClosed',
  CANCELLED: 'statusClosed',
  REOPENED: 'statusWarning',
  INCIDENT: 'statusWarning',
  UNKNOWN: 'statusUnknown'
};

export const causeOfLossMap = {
  auto_claim_collision_with_motor_vehicle: 'Collision',
  auto_claim_fire: 'Fire',
  auto_claim_collision_with_bus_or_train: 'Collision',
  auto_claim_hail: 'Hail',
  auto_claim_flood: 'Flood',
  auto_claim_falling_object: 'Falling Object',
  auto_claim_collision_with_fixed_object: 'Collision',
  auto_claim_glass: 'Glass',
  auto_claim_collision_with_bicycle: 'Collision',
  auto_claim_theft_of_parts: 'Theft',
  auto_claim_collision_with_pedestrian: 'Collision',
  auto_claim_storm: 'Storm',
  auto_claim_animal: 'Animal',
  auto_claim_vandalism: 'Vandalism',
  auto_claim_riot_or_civil_unrest: 'Riot or Civil Unrest',
  auto_claim_theft_of_vehicle: 'Theft',
  property_claim_broken_window: 'Property Broken Window',
  property_claim_building_collapse: 'Property Building Collapse',
  property_claim_collision_damage: 'Property Collision Damage',
  property_claim_earth_movement: 'Property Earth Movement',
  property_claim_earthquake: 'Property Earthquake',
  property_claim_equipment_breakdown: 'Property Equipment Breakdown',
  property_claim_explosion: 'Property Explosion',
  property_claim_fallen_tree: 'Property Fallen Tree',
  property_claim_falling_object: 'Property Falling Object',
  property_claim_fire: 'Property Fire',
  property_claim_flood: 'Property Flood',
  property_claim_freezing_water: 'Property Freezing Water',
  property_claim_hail: 'Property Hail',
  property_claim_ice_damage: 'Property Ice Damage',
  property_claim_lightning: 'Property Lightning',
  property_claim_other: 'Property Claim',
  property_claim_power_surge: 'Property Power Surge',
  property_claim_riot: 'Property Riot',
  property_claim_robbery: 'Property Robbery',
  property_claim_service_line: 'Property Service Line',
  property_claim_sewer_backup: 'Property Sewer Backup',
  property_claim_sinkhole: 'Property Sinkhole',
  property_claim_smoke: 'Property Smoke',
  property_claim_theft: 'Property Theft',
  property_claim_theft_scheduled_property: 'Property Theft Scheduled Property',
  property_claim_thirdparty_damage: 'Property thirdparty damage',
  property_claim_thirdparty_dog_bite: 'Property thirdparty dog bite',
  property_claim_thirdparty_fall: 'Property thirdparty fall',
  property_claim_thirdparty_injury: 'Property thirdparty injury',
  property_claim_vandalism: 'Property Vandalism',
  property_claim_volcano: 'Property Volcano',
  property_claim_water_backup: 'Property Water Backup',
  property_claim_water_damage: 'Property Water Damage',
  property_claim_weather: 'Property Weather',
  property_claim_weight_of_ice_snow: 'Property Weight of Ice/Snow',
  property_claim_wind: 'Property Wind'
};
