import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4, 8)
  },
  policyInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  value: {
    color: theme.colors.light_text,
    fontWeight: 400
  },
  linkToDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  }
});

export default makeStyles(styles);
