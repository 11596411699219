import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  modalHeader: {
    marginBottom: theme.spacing(6),
    fontSize: 40,
    fontWeight: 300
  },
  modalContent: {
    lineHeight: 1.2,
    display: 'block'
  },
  card: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(3),
    backgroundColor: theme.colors.page__background_primary,
    minHeight: 72,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    boxShadow: 'none'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  cardText: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      padding: theme.spacing(2, 0)
    }
  },
  cardHeader: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43
  },
  notificationIcon: {
    display: 'block',
    height: 32,
    width: 32
  },
  btncontainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  },
  yesbtn: {
    padding: theme.spacing(1, 2),
    width: 144,
    height: 36
  },
  nobtn: {
    padding: theme.spacing(1, 2),
    width: 98,
    height: 36
  }
});

export default makeStyles(styles);
