/* eslint-disable func-names */
import * as Yup from 'yup';
import { isAfter, isBefore, differenceInYears } from 'date-fns';
import { paymentMethod, paymentType, policyType, offerExcessUM } from '@ourbranch/lookups';

import { canAddUmbrella, canAddUmbrellaUMUIM } from 'core/helpers/quoter.service';
import { FormAction } from 'core/store/offer-store';
import { connectedHomeSchema } from '../components/sidebar/components/discounts-card/connected-home-discount/connected-home-form/connected-home.validation-schema';
import {
  validUSState,
  requiredString,
  validMortgageDetails,
  validMortgageLoanNumber,
  validHomePurchaseDate
} from 'common/helpers/yup-helpers';
import homeSchema from './validation-schemas/home.validation-schema';
import autoSchema from './validation-schemas/auto.validation-schema';
import condoSchema from './validation-schemas/condo.validation-schema';
import effectiveDateSchema from './validation-schemas/effective-date.validation-schema';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);
Yup.addMethod(Yup.mixed, 'validMortgageDetails', validMortgageDetails);
Yup.addMethod(Yup.mixed, 'validMortgageLoanNumber', validMortgageLoanNumber);
Yup.addMethod(Yup.mixed, 'validHomePurchaseDate', validHomePurchaseDate);

const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

const offerValidationSchema = (session) =>
  Yup.object().shape({
    home: Yup.object()
      .nullable()
      .shape({
        purchaseDate: Yup.string().validHomePurchaseDate('home.purchaseDate', false)
      }),
    condo: Yup.object()
      .nullable()
      .shape({
        purchaseDate: Yup.string().validHomePurchaseDate('condo.purchaseDate', false)
      }),
    isBix: Yup.boolean().nullable(),
    global: Yup.object()
      .shape({
        affinity: Yup.string().nullable(),
        autoBillingDayOfMonth: Yup.number().nullable(),
        autoPaymentMethod: Yup.string().requiredString(),
        autoPaymentType: Yup.string()
          .requiredString()
          .test(
            'payInFullRequired',
            "Offer requires One Time Payment Frequency due to customer's prior canceled policy for non-payment",
            function (value) {
              if (session.isTeamLeader || !this.options.parent.priorCancelNonPay) {
                return true;
              }
              // if paying in full, or if policy type is home, pass validation
              if (value === paymentType.OneTime || this.options.context?.selectedOption === policyType.Home) {
                return true;
              }
              return false;
            }
          ),
        billingDayOfMonth: Yup.number().nullable(),
        currentAutoCarrier: Yup.string().nullable(),
        currentHomeownersCarrier: Yup.string().nullable(),
        currentlyAutoInsured: Yup.boolean(),
        discountInventoryScore: Yup.boolean(),
        discountPaperless: Yup.boolean(),
        homeBillingDayOfMonth: Yup.number().nullable(),
        homeownersPaymentMethod: Yup.string().requiredString(),
        homeownersPaymentType: Yup.string()
          .requiredString()
          .test(
            'payInFullRequired',
            "Offer requires One Time Payment Frequency due to customer's prior canceled policy for non-payment",
            function (value) {
              if (
                session.isTeamLeader ||
                !this.options.parent.priorCancelNonPay ||
                this.options.parent?.homeownersPaymentMethod === paymentMethod.Escrow
              ) {
                return true;
              }
              // if paying in full, or if policy type is not a home policy
              if (value === paymentType.OneTime || !this.options.context?.selectedOption.includes('H')) {
                return true;
              }
              return false;
            }
          ),
        personalPropertyProtection: Yup.boolean()
      })
      .nullable(),
    includeUmbrella: Yup.boolean()
      .test(
        'canAddUmbrella',
        'Umbrella is disabled since underlying policies do not meet the following requirements: Auto BI needs to be at least $500K per occurrence ($250K/$500K for split or $500K for CSL), homeowner&lsquo;s  liability needs to be at least $300K, there must not be any excluded drivers or drivers with a UDR, and there must be no more than 2 incidents total.',
        // eslint-disable-next-line func-names
        function (val, { options: { context } }) {
          if (val) {
            const { autoCoverage, homeCoverage, condoCoverage, drivers, global } = context;
            const { policyLimitBIPD } = autoCoverage;
            const { coverageX } = homeCoverage || condoCoverage;

            return canAddUmbrella({
              policyLimitBIPD,
              coverageX,
              global,
              drivers
            });
          }
          return true;
        }
      )
      .nullable(),
    umbrellaCoverage: Yup.object()
      .when('includeUmbrella', {
        is: true,
        then: Yup.object().shape({
          liabilityCoverageLimit: Yup.number()
            .test('umbrella-limit-caped-at-2mil', 'You cannot increase coverage over $2m', function (value) {
              // currently not allowing umbrella higher than 2mil
              if (value > 2000000) {
                return false;
              }
              return true;
            })
            .required('Umbrella Limit is required')
            .nullable(),
          watercraftHullLengths: Yup.array()
            .of(
              Yup.number().test(
                'craftHullLengthsMax',
                'We do not allow boats with hull lengths over 30 feet.',
                function (value, { options: { context } }) {
                  const { includeUmbrella } = context;
                  return includeUmbrella ? value <= 30 : true;
                }
              )
            )
            .nullable(),
          uninsuredMotoristLimit: Yup.string()
            .test(
              'canHaveExcessUM',
              'You must have 500K per occurrence of UM/UIM to add excess UM to umbrella',
              function (value, { options: { context } }) {
                if (!value || value === '0') {
                  return true;
                }

                const {
                  autoCoverage: { policyLimitUMBI, policyLimitUIMBI },
                  state,
                  includeUmbrella
                } = context;

                const canAddUM = canAddUmbrellaUMUIM({ policyLimitUMBI, policyLimitUIMBI, state });

                return includeUmbrella ? canAddUM : true;
              }
            )
            .test(
              'stateCannotHaveExcessUM',
              'This state does not allow excess UM/UIM',
              function (value, { options: { context } }) {
                if (!value || value === '0') {
                  return true;
                }

                const { state, includeUmbrella } = context;

                return includeUmbrella ? offerExcessUM[state] : true;
              }
            )
            .test('requiredWhenOffering', 'Excess UM/UIM is required', function (value, { options: { context } }) {
              const { state } = context;
              return offerExcessUM[state] ? !!value : true;
            })
            .nullable(),
          numVehiclesTotal: Yup.number()
            .test(
              'minCount',
              'The total number of Umbrella vehicles cannot be less than the number of vehicles on the policy',
              function (val) {
                const numCars = this.options.context?.cars?.length;
                return this.options.context?.includeUmbrella ? val >= numCars : true;
              }
            )
            .typeError('Number of vehicles is required')
            .nullable(),
          numLicensedDriversUnder25: Yup.number()
            .test(
              'minCountDrivers',
              'The total number of Umbrella drivers under age 25 cannot be less than the number of drivers under age 25 on the auto policy',
              function (val) {
                const driversUnder25 = this.options.context?.drivers.filter((d) => {
                  // not relying on d.age here because that node is only accurate after save
                  // and we want to catch drivers under 25 in case they were added in the same update as adding umbrella
                  const age = differenceInYears(new Date(), new Date(d.dateOfBirth));
                  return age < 25;
                }).length;
                return this.options.context?.includeUmbrella ? val >= driversUnder25 : true;
              }
            )
            .required('Number of Licensed Drivers Under 25 is required')
            .typeError('Number of licensed drivers under 25 is required'),
          numRVs: Yup.number()
            .required('Number of Golf Carts and Snowmobiles is required')
            .typeError('Number of Golf Carts and Snowmobiles is required'),
          numMotorcyclesScooters: Yup.number()
            .required('Number of Motorcycles/Scooters is required')
            .typeError('Number of Motorcycles/Scooters is required'),
          numATVs: Yup.number()
            .default(null)
            .required('Number of ATVs is required')
            .typeError('Number of ATVs is required'),
          numPersonalWatercraft: Yup.number()
            .required('Number of Personal Watercraft is required')
            .typeError('Number of Personal Watercraft is required')
        }),
        otherwise: Yup.object().nullable()
      })

      .nullable(),
    scheduledPersonalProperty: Yup.object()
      .shape({
        deductible: Yup.string()
          .test('sppDeductible', 'SPP deductible is required.', function (value, { options }) {
            if (options.context?.scheduledPersonalProperty?.items?.length) {
              return !!value;
            }
            return true;
          })
          .nullable(),
        items: Yup.array().nullable()
      })
      .nullable()
  });

const michiganPIPSchema = Yup.object().shape({
  auto: Yup.object()
    .shape({
      hasSetPip: Yup.boolean()
        .test('requiredForMI', 'Please visit and confirm PIP details for this policy.', function (value) {
          return value;
        })
        .nullable()
    })
    .nullable()
});

// @TODO there is a way to programmatically update schemas, and this would having the more or less the same schema, but you need a workaround to support updating nested fields see  https://github.com/jquense/yup/issues/283#issuecomment-420279808
const checkoutValidationSchema = () =>
  Yup.object().shape({
    home: Yup.object()
      .nullable()
      .shape({
        purchaseDate: Yup.string().validHomePurchaseDate('home.purchaseDate', false)
      }),
    condo: Yup.object()
      .nullable()
      .shape({
        purchaseDate: Yup.string().validHomePurchaseDate('condo.purchaseDate', false)
      }),
    cars: Yup.array().test('maxCount', 'Vehicle to driver ratio must be 2:1 max', function (val) {
      if (this.options.context?.selectedOption?.includes('A')) {
        const numDrivers = this.options.context?.drivers?.length;
        const numCars = val?.length;
        const violatesRatio = numCars / numDrivers > 2;
        if (violatesRatio) {
          return this.createError({
            message:
              'This offer exceeds the 2 cars to 1 driver ratio. Please review the registered vehicles and drivers on this offer and, if they are incorrect, correct them. Otherwise, this offer is ineligible.'
          });
        }
        return true;
      }
      return true;
    }),
    forcedMultiPolicyDiscount: Yup.string()
      .test(
        'existingCustomerCheck',
        'You must have an existing member attached to add a multilineDiscount',
        function (value) {
          if (value && value !== 'N' && !this.options.context.existingCustomer?.id) {
            return false;
          }
          return true;
        }
      )
      .nullable(),
    isBix: Yup.boolean().nullable(),
    global: Yup.object().shape({
      affinity: Yup.string().nullable(),
      autoBillingDayOfMonth: Yup.number().nullable(),
      autoEffectiveDate: Yup.date().nullable(),
      autoPaymentMethod: Yup.string().requiredString(),

      autoPaymentType: Yup.string().requiredString(),
      billingDayOfMonth: Yup.number().nullable(),
      currentAutoCarrier: Yup.string().nullable(),
      currentHomeownersCarrier: Yup.string().nullable(),
      currentlyAutoInsured: Yup.boolean(),
      discountInventoryScore: Yup.boolean(),
      discountPaperless: Yup.boolean(),
      homeBillingDayOfMonth: Yup.number().nullable(),
      homeEffectiveDate: Yup.date()
        .test('min', 'Policy start date cannot be in the past or before the home purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('H')) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateHome =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateHome, date);
          }
          return true;
        })

        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('H')) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      homeownersPaymentMethod: Yup.string().requiredString(),
      homeownersPaymentType: Yup.string().requiredString(),
      personalPropertyProtection: Yup.boolean(),
      condoEffectiveDate: Yup.date()
        .nullable()
        .test('min', 'Policy start date cannot be in the past or before the condo purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('C')) {
            const purchaseDate = this.options.context?.condo?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateCondo =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateCondo, date);
          }
          return true;
        })

        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('C')) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
    }),
    primaryMortgageDetail: Yup.object()
      .when('global.homeownersPaymentMethod', {
        is: (homeownersPaymentMethod) => homeownersPaymentMethod === 'W',
        then: Yup.object().shape({
          mortgageHolderName: Yup.string().validMortgageDetails('mortgageHolderName'),
          loanNumber: Yup.string()
            .validMortgageDetails('loanNumber')
            .typeError('Loan number is required')
            .validMortgageLoanNumber(),
          mortgageHolderAddress: Yup.object()
            .shape({
              address: Yup.string()
                .validMortgageDetails('mortgageHolderAddress.address')
                .typeError('Mortgage lender address must be fully filled in'),
              address2: Yup.string().nullable(),
              city: Yup.string()
                .validMortgageDetails('mortgageHolderAddress.city')
                .typeError('Mortgage lender address must be fully filled in'),
              state: Yup.string()
                .validUSState('mortgageHolderAddress.state')
                .validMortgageDetails('mortgageHolderAddress.state')
                .typeError('Mortgage lender address must be fully filled in'),
              zip: Yup.string()
                .validMortgageDetails('mortgageHolderAddress.zip')
                .typeError('Mortgage lender address must be fully filled in')
            })
            .typeError('Mortgage lender address must be fully filled in')
        }),
        otherwise: Yup.object().nullable()
      })
      .nullable()
  });

export const buildSchema = ({
  formAction,
  includeConnectedHome,
  isAdvancedConnectedHome,
  needMVRs,
  session,
  affinityLookups,
  selectedOption,
  state,
  messageContext,
  initialValuesContext
}) => {
  let schema;

  if (formAction === FormAction.Checkout && !needMVRs) {
    schema = checkoutValidationSchema(session, affinityLookups);
    if (selectedOption.includes(policyType.Auto) && state === 'MI') {
      schema = schema.concat(michiganPIPSchema);
    }
  } else {
    schema = offerValidationSchema(session, affinityLookups);
  }

  if (selectedOption.includes(policyType.Home)) {
    schema = schema.concat(homeSchema(state, messageContext, session, initialValuesContext));
  }

  if (selectedOption.includes(policyType.Condo)) {
    schema = schema.concat(condoSchema);
  }

  if (selectedOption.includes(policyType.Auto)) {
    schema = schema.concat(autoSchema(state, session));
  }

  if (includeConnectedHome) {
    schema = schema.concat(connectedHomeSchema(isAdvancedConnectedHome, session.isAgency));
  }
  // If the offer is out of date (meaning the effective dates are in the past)
  // we don't want the validation to enforce the effective date rules, since the form's submit function
  // will automatically update the effective dates to today
  // This is because the validation will stop the form from being submitted if there are validation errors
  if (formAction !== FormAction.UpdateEffectiveDates) {
    schema = schema.concat(effectiveDateSchema);
  }
  return schema;
};
