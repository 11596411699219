import React from 'react';
import { TableRow, TableCell, Paper } from '@material-ui/core';

import { Label } from 'core/components/label';
import { Table } from 'core/components/table';
import { TableHeaderCell } from 'core';
import { displayData } from 'tools/helpers';

import useStyles from '../generate-task-ui.styles';

function TaskOutput({ success, taskOutput, hideTitle }) {
  const classes = useStyles();

  if (!taskOutput) {
    return null;
  }

  let tables = taskOutput ? [taskOutput] : [];
  // if only have a success key and a failed key, then it's two tables to display
  // @todo extend this to be generic
  if (!Object.keys(taskOutput[0] || {}).some((key) => key !== 'success' && key !== 'failed')) {
    tables = Object.values(taskOutput[0]);
  }
  return (
    <>
      {success && (
        <div className={classes.output}>
          {!hideTitle && <Label type="subtitle">Output</Label>}
          {tables.map((table, ind) => {
            const columns = table ? Object.keys(table[0] || {}) : [];
            return (
              <>
                {tables.length > 1 && <Label type="subtitle">{Object.keys(taskOutput[0])[ind]}</Label>}
                <Paper className={classes.paper}>
                  {table.length > 0 ? (
                    <Table
                      className={classes.table}
                      header={
                        <>
                          {columns.map((key) => (
                            <TableHeaderCell key={key} columnId={key}>
                              {key}
                            </TableHeaderCell>
                          ))}
                        </>
                      }
                      body={table?.map((data) => (
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell>{displayData(data[column])}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    />
                  ) : (
                    <Label type="body1">No results</Label>
                  )}
                </Paper>
              </>
            );
          })}
        </div>
      )}
    </>
  );
}

export default TaskOutput;
