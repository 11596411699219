import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

import { Label } from 'core/components/label';
import useStyles from '../generate-task-ui.styles';

const MAX_MESSAGES = 5;

function LiveTaskMessages({ taskRunId, messages, hideTitle }) {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const messagesToShow = showAll ? messages : messages.slice(0, MAX_MESSAGES);

  if (!taskRunId || !messages?.length) return null;

  return (
    <div className={classes.output}>
      {!hideTitle && <Label type="subtitle">Live Activity</Label>}
      <Grid>
        {messagesToShow.map((message, index) => (
          <Grid container item key={`message-${index}`} alignItems="center">
            <CommentIcon className={classes.chatIcon} />
            <Label key={index} type="liveTaskMessage" className={classes.liveMessage}>
              {message}
            </Label>
          </Grid>
        ))}
        {messages.length > MAX_MESSAGES && (
          <Button onClick={() => setShowAll(!showAll)} color="secondary">
            Show {showAll ? 'less messages' : `all ${messages.length} messages`}
          </Button>
        )}
      </Grid>
    </div>
  );
}

export default LiveTaskMessages;
