import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

import { Card } from 'core/components/card';
import useStyles from './loader.styles';

const Loader = () => {
  const classes = useStyles();
  return (
    <Card>
      <Grid container className={classes.loaderContainer} alignItems="center" justifyContent="center">
        <CircularProgress className={classes.loader} />
      </Grid>
    </Card>
  );
};

export default Loader;
