import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import NotificationIconGreen from 'core/assets/svg/alert-green.svg';
import { useStore } from 'core/store/';

import useStyles from './unverifiable-driving-record-modal.styles';

const UDRModal = observer(() => {
  const classes = useStyles();
  const { offer } = useStore();
  const { showUDRModal, setShowUDRModal } = offer;

  const onClose = () => {
    setShowUDRModal(false);
  };

  return (
    <BaseDialog onClose={onClose} open={showUDRModal}>
      <Label type="greenBig" className={classes.modalHeader}>
        Unverifiable Driving Record Found
      </Label>
      <Label type="darkGreenSmall" className={classes.modalContent}>
        One or more drivers have an unverifiable driving record (UDR) that make this offer unable to bind.
      </Label>
      <Card className={classes.card}>
        <Grid className={classes.cardContent}>
          <img className={classes.notificationIcon} src={NotificationIconGreen} alt="Notification Icon" />
          <div>
            <Label className={classes.cardHeader}>How to Resolve</Label>
            Please provide a valid license number for the driver(s). If you’ve already done so, please follow your
            designated process for troubleshooting and escalations.
          </div>
        </Grid>
      </Card>
      <Grid>
        <Button variant="contained" color="primary" className={classes.btn} onClick={onClose}>
          Go back and edit drivers
        </Button>
      </Grid>
    </BaseDialog>
  );
});

export default UDRModal;
