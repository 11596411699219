export default () => ({
  smallLabel: {
    marginTop: 32,
    marginBottom: 46,
    textAlign: 'center'
  },
  button: {
    minWidth: 255,
    marginBottom: 16
  }
});
