import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { addDays, format } from 'date-fns';
import getDate from 'date-fns/getDate';
import parseISO from 'date-fns/parseISO';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import {
  paymentMethod,
  paymentType,
  originalGSNICStates,
  allowInstallPayOnHomePoliciesStates
} from '@ourbranch/lookups';

import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { FormField } from 'core/components/form';
import { NotificationCard } from 'core/components/notification-card';
import withDatePicker from 'core/components/with-date-picker';
import { OfferPageTabs } from 'core/store/offer-store';
import { UpdateMortgageNotification } from 'common/components/mortgage-details';
import { getSoldDaysIntervalFromToday } from 'offer/helpers/effective-date-helpers';
import { canOfferMegaDownPay } from '../../helpers/mega-down-pay-helpers';
import { FutureEffectiveDateWarning } from './future-effective-date-warning';
import useStyles from './settings.styles';

const HomeSettings = observer(function HomeSettings({ paymentMethodOptions, billingDaysOptions, paymentTypeOptions }) {
  const { offer } = useStore();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { isBix } = offer;
  const { primaryMortgageDetail } = values;
  const [isPaymentMortgage, setIsPaymentMortgage] = useState(
    values.global.homeownersPaymentMethod === paymentMethod.Escrow
  );
  const state = offer?.state;
  const daysOut = getSoldDaysIntervalFromToday(state);
  const showCreditCardNote =
    values.global.homeownersPaymentMethod === paymentMethod.ACH && !isBix && originalGSNICStates.includes(state);

  const goToHomeDetailsTab = () => {
    offer.setCurrentTab(OfferPageTabs.HOME);
    offer.setScrollTo('addMortgage');
  };

  const offerMegaDownPay = useMemo(
    () =>
      canOfferMegaDownPay(
        values.global.homeEffectiveDate,
        values.global.homeBillingDayOfMonth,
        values.global.homeownersPaymentType
      ),
    [values.global.homeEffectiveDate, values.global.homeBillingDayOfMonth, values.global.homeownersPaymentType]
  );

  useEffect(() => {
    setIsPaymentMortgage(values.global.homeownersPaymentMethod === paymentMethod.Escrow);
  }, [values.global.homeownersPaymentMethod]);

  // this is used to default the billing day of the month to the day that the policy is effective
  const homeEffectiveDay = useMemo(() => {
    if (values.global.homeEffectiveDate) {
      return getDate(parseISO(values.global.homeEffectiveDate));
    }
  }, [values]);

  // if home billing day of month is null, default the selection to the effective day of the policy
  // or the 28th if the effectiveDay is past the allowed options of days 1 - 28
  useEffect(() => {
    if (!values.global.homeBillingDayOfMonth && values.global.homeBillingDayOfMonth !== homeEffectiveDay) {
      if (homeEffectiveDay > 28) {
        setFieldValue('global.homeBillingDayOfMonth', 28);
      } else {
        setFieldValue('global.homeBillingDayOfMonth', homeEffectiveDay);
      }
    }
  }, [values, homeEffectiveDay, setFieldValue]);

  const onHomePaymentChange = useCallback(
    (homePaymentMethod) => {
      if (homePaymentMethod === paymentMethod.Escrow) {
        // set payment freq to Mortgage
        setFieldValue('global.homeownersPaymentType', paymentType.Escrow);
      } else {
        setFieldValue('global.homeownersPaymentType', paymentType.OneTime);
      }
    },
    [setFieldValue]
  );

  const onEffectiveDateChange = (val) => {
    if (['H', 'C', 'HA', 'CA'].includes(values.selectedOption)) {
      // If home/condo purchase date matches policy effective date, make sure both keep matching
      const policyType = values.selectedOption.includes('H') ? 'home' : 'condo';
      const propertyPurchaseDate = values[policyType]?.purchaseDate || values[policyType]?.purchaseDate;
      const policyEffectiveDate =
        values.global[`${policyType}EffectiveDate`] || values.global[`${policyType}EffectiveDate`];

      if (propertyPurchaseDate === policyEffectiveDate) {
        setFieldValue(`${policyType}.purchaseDate`, format(new Date(val.stringValue), 'yyyy-MM-dd'));
      }
    }
  };

  return (
    <Section title="Home Policy Settings" type="SubSection">
      <Card className={classes.overflowContainer} type="secondary">
        <Grid container xs={12}>
          <Label type="formSubTitle" className={classes.subtitle}>
            Policy Term
          </Label>
        </Grid>
        <Grid container>
          <FormField
            type="date"
            name="global.homeEffectiveDate"
            label="Home policy effective date"
            xs={4}
            mode="dark"
            disableFuture={false}
            minDate={addDays(new Date(), daysOut)}
            maxDate={addDays(new Date(), 60)}
            fast={false}
            onChange={onEffectiveDateChange}
          />
          <Grid item xs={12}>
            <FutureEffectiveDateWarning daysOut={daysOut} />
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.billingDetails} spacing={2} xs={12}>
          <Grid item xs={2}>
            <Label type="formSubTitle" className={classes.subtitle}>
              Billing Details
            </Label>
          </Grid>
          {showCreditCardNote && (
            <Grid item xs={6}>
              <Label type="noteSmall">Note: There is no additional fee for paying with credit card.</Label>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid container spacing={4}>
            <FormField
              label="Home payment method"
              xs={4}
              type="select"
              name="global.homeownersPaymentMethod"
              options={paymentMethodOptions}
              onChange={onHomePaymentChange}
              mode="dark"
            />
            {!allowInstallPayOnHomePoliciesStates[state] && (
              <Grid item xs={4}>
                <LabelTooltip
                  label="Home payment frequency"
                  tooltip={{
                    label: 'More Info',
                    onHoverText:
                      'Home policies are not eligible to be paid monthly. Payment can be made by mortgage (escrow), or with a one-time payment in full by card or bank withdrawal (ACH).'
                  }}
                >
                  <FormField
                    type="select"
                    name="global.homeownersPaymentType"
                    options={paymentTypeOptions.filter((option) => {
                      if (!isPaymentMortgage) {
                        return option.id !== paymentType.Escrow;
                      }
                      return option;
                    })}
                    mode="dark"
                    disabled={isPaymentMortgage || !allowInstallPayOnHomePoliciesStates[state]}
                    fast={false}
                  />
                </LabelTooltip>
              </Grid>
            )}
            {values.global?.homeownersPaymentType === paymentType.Monthly && (
              <FormField
                type="select"
                name="global.homeBillingDayOfMonth"
                label="Home Billing Day"
                mode="dark"
                options={billingDaysOptions}
                xs={4}
                fast={false}
              />
            )}
            {allowInstallPayOnHomePoliciesStates[state] && (
              <FormField
                label="Home payment frequency"
                xs={4}
                type="select"
                name="global.homeownersPaymentType"
                options={paymentTypeOptions.filter((option) => {
                  if (!isPaymentMortgage) {
                    return option.id !== paymentType.Escrow;
                  }
                  return option;
                })}
                mode="dark"
                disabled={isPaymentMortgage}
                fast={false}
              />
            )}
          </Grid>
        </Grid>
        {isPaymentMortgage && (
          <Grid container>
            <Grid container spacing={4}>
              <FormField
                name="primaryMortgageDetail.mortgageHolderName"
                type="value"
                label="Lender Name"
                mode="dark"
                xs={4}
                value={
                  primaryMortgageDetail.mortgageHolderName ? primaryMortgageDetail.mortgageHolderName : 'Not provided'
                }
              />
              <FormField
                name="primaryMortgageDetail.loanNumber"
                type="value"
                label="Loan Number"
                mode="dark"
                xs={4}
                value={primaryMortgageDetail.loanNumber ? primaryMortgageDetail.loanNumber : 'Not provided'}
              />
              <FormField
                name="primaryMortgageDetail.mortgageHolderAddress.address"
                type="value"
                label="Lender Address"
                xs={4}
                mode="dark"
                value={
                  primaryMortgageDetail.mortgageHolderAddress?.address
                    ? `${primaryMortgageDetail.mortgageHolderAddress.address}, ${primaryMortgageDetail.mortgageHolderAddress.city}, 
                      ${primaryMortgageDetail.mortgageHolderAddress.state}, ${primaryMortgageDetail.mortgageHolderAddress.zip}`
                    : 'Not provided'
                }
              />
              <Grid item xs={12}>
                <UpdateMortgageNotification
                  isMortgageAdded={!!primaryMortgageDetail.mortgageHolderName}
                  onNavigateToDetails={goToHomeDetailsTab}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {offerMegaDownPay && (
          <>
            <Grid container>
              <FormField
                type="checkbox"
                name="global.homeMegaDownPay"
                id="global.homeMegaDownPay"
                label="Add second payment to down payment billing"
                fast={false}
              />
            </Grid>
            {!values.global.homeMegaDownPay && (
              <NotificationCard type="quaternary">
                <Label className={classes.warningText}>
                  The member will be charged a second payment within 20 days of making their down payment.
                </Label>
              </NotificationCard>
            )}
          </>
        )}
      </Card>
    </Section>
  );
});

HomeSettings.propTypes = {
  paymentMethodOptions: PropTypes.array.isRequired,
  billingDaysOptions: PropTypes.array.isRequired,
  paymentTypeOptions: PropTypes.array.isRequired
};

export default withDatePicker(HomeSettings);
