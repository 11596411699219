import {
  parse,
  format,
  intervalToDuration,
  differenceInCalendarDays,
  differenceInMonths,
  set,
  isBefore,
  addYears
} from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { USAStatesByAbbv } from '@ourbranch/utility-types';

// Re-export date-fns and date-fns-tz to consumers
import * as dateFns from 'date-fns';

const specificDriverAgeLogicStates = ['AL', 'LA', 'ME', 'SC', 'SD', 'VT'];

export function getDateFromString(date: string, customFormat?: string | undefined) {
  if (customFormat) {
    return parse(date, customFormat, new Date());
  }

  if (String(date).length === 8) {
    return parse(String(date), 'yyyyMMdd', new Date());
  }

  return parse(date, 'yyyy-MM-dd', new Date());
}

export function getStringFromDate(date: Date, customFormat?: string | undefined) {
  if (customFormat) {
    return format(date, customFormat);
  }
  return format(date, 'yyyy-MM-dd');
}

// Given a date with this format: 2020-01-01 it will return the numeric representation 20200101
export function getNumberFromString(date: string) {
  return parse(date, 'yyyyMMdd', new Date());
}

export function getIntervalBetween(start: Date, end: Date) {
  return intervalToDuration({
    start,
    end
  });
}

export function getDaysBetweenDates(start: Date, end: Date) {
  return differenceInCalendarDays(end, start);
}

export function getSecondsBetweenDates(start: Date, end: Date) {
  return dateFns.differenceInSeconds(end, start);
}

export function getMonthsBetweenDates(start: Date, end: Date) {
  return differenceInMonths(end, start);
}

// We default to Ohio for our state for timezone purposes
let tzSavedState = 'OH';

/** Returns an object describing age given a Date object set to birthday. */
export function calculateAge(birthday: Date, startMoment = new Date()): { years: number; months: number } {
  const lifetime = getIntervalBetween(birthday, startMoment);
  return { years: lifetime.years!, months: lifetime.months! };
}

/** Returns number of days between passed date and birthdate */
export function daysBetweenDateAndNextBirthday(date: Date, birthDate: Date) {
  const thisYearBirthday = set(birthDate, { year: new Date().getFullYear() });

  if (isBefore(date, thisYearBirthday)) {
    return getDaysBetweenDates(date, thisYearBirthday);
  }

  return getDaysBetweenDates(date, addYears(thisYearBirthday, 1));
}

/** Returns an object describing Driver age given a Date object set to birthday and an effective date. */
export function calculatePersonAge(
  birthday: Date,
  effectiveDate: Date | null,
  // eslint-disable-next-line default-param-last
  startMoment: Date = new Date(),
  stateIn?: USAStatesByAbbv
): { years: number; months: number } {
  const state = stateIn || tzSavedState; // we recall the last state if one isn't sent in
  tzSavedState = state;

  const lifetime = getIntervalBetween(birthday, startMoment);
  const lifetimeYears = lifetime.years as number;
  const daysBetweenEffectiveDateAndNextBirthday = effectiveDate
    ? daysBetweenDateAndNextBirthday(effectiveDate, birthday)
    : null;

  // For specificDriverAgeLogicStates, if the driver (is 24) OR (younger than 24 AND has a birthday within 30 days of the effective date)
  if (
    specificDriverAgeLogicStates.includes(state) &&
    (lifetimeYears === 24 ||
      (lifetimeYears < 24 && daysBetweenEffectiveDateAndNextBirthday && daysBetweenEffectiveDateAndNextBirthday <= 30))
  ) {
    return { years: lifetime.years! + 1, months: 0 };
  }
  return calculateAge(birthday, startMoment);
}

/** Returns YYYY-MM-DD from YYYYMMDD */
export function yyyymmddToAWSDate(yyyymmddStrIn: string) {
  const yyyymmddStr = typeof yyyymmddStrIn === 'string' ? yyyymmddStrIn : String(yyyymmddStrIn);
  return yyyymmddStr.length === 8
    ? [yyyymmddStr.slice(0, 4), yyyymmddStr.slice(4, 6), yyyymmddStr.slice(6, 8)].join('-')
    : null;
}

/** Helper function that pads a string to two digits */
function padTwoDigits(str: string) {
  return String(`00${String(str)}`).slice(-2);
}

/** Returns YYYY-MM-DD from an Excel Date  */
export function ExcelDateToAWSDate(serial: number) {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);

  return `${dateInfo.getFullYear()}-${padTwoDigits(String(dateInfo.getMonth()))}-${padTwoDigits(
    String(dateInfo.getDate())
  )}`;
}

/** take a variety of different date formats and turn them all into YYYY-MM-DD */
export function normalizeDate(inputDate: string) {
  if (inputDate.length === 8 && !inputDate.includes('-')) {
    // assuming YYYYMMDD date
    return yyyymmddToAWSDate(inputDate);
  }
  if (inputDate.length < 8 && parseInt(inputDate, 10) > 0) {
    // assuming excel date:
    return ExcelDateToAWSDate(parseInt(inputDate, 10));
  }

  // return what we received
  return inputDate;
}

/** Return today's date as a string, accounting for timezones. */
export function getTodayStr(stateIn: string | undefined | null) {
  const state = stateIn || tzSavedState; // we recall the last state if one isn't sent in
  tzSavedState = state;

  if (['WA', 'CA', 'OR', 'NV'].includes(state)) {
    return dateFnsTz.formatInTimeZone(new Date(), 'America/Los_Angeles', 'yyyy-MM-dd');
  }
  if (['AZ', 'NM', 'WY', 'UT', 'CO', 'MT', 'ID', 'ND', 'SD', 'NE'].includes(state)) {
    return dateFnsTz.formatInTimeZone(new Date(), 'America/Boise', 'yyyy-MM-dd');
  }
  if (['AL', 'AR', 'IL', 'IA', 'LA', 'MN', 'MO', 'MS', 'OK', 'WI', 'IN', 'KS', 'MI', 'TN', 'TX'].includes(state)) {
    return dateFnsTz.formatInTimeZone(new Date(), 'America/Chicago', 'yyyy-MM-dd');
  }

  if (state === 'HI') {
    return dateFnsTz.formatInTimeZone(new Date(), 'Pacific/Honolulu', 'yyyy-MM-dd');
  }
  if (state === 'AK') {
    return dateFnsTz.formatInTimeZone(new Date(), 'America/Anchorage', 'yyyy-MM-dd');
  }
  // default to eastern
  return dateFnsTz.formatInTimeZone(new Date(), 'America/New_York', 'yyyy-MM-dd');
}

/** Return date as a string, accounting for timezones. */
export function getDateStrInPolicyTZ(state: string, dateStr: string) {
  // force end of day in utc otherwise will be a day off
  const date = new Date(dateStr?.includes('T') ? dateStr : `${dateStr}T23:59:59.999Z`);

  if (['WA', 'CA', 'OR', 'NV'].includes(state)) {
    return dateFnsTz.formatInTimeZone(date, 'America/Los_Angeles', 'yyyy-MM-dd');
  }
  if (['AZ', 'NM', 'WY', 'UT', 'CO', 'MT', 'ID', 'ND', 'SD', 'NE'].includes(state)) {
    return dateFnsTz.formatInTimeZone(date, 'America/Boise', 'yyyy-MM-dd');
  }
  if (['AL', 'AR', 'IL', 'IA', 'LA', 'MN', 'MO', 'MS', 'OK', 'WI', 'IN', 'KS', 'MI', 'TN', 'TX'].includes(state)) {
    return dateFnsTz.formatInTimeZone(date, 'America/Chicago', 'yyyy-MM-dd');
  }

  if (state === 'HI') {
    return dateFnsTz.formatInTimeZone(date, 'Pacific/Honolulu', 'yyyy-MM-dd');
  }
  if (state === 'AK') {
    return dateFnsTz.formatInTimeZone(date, 'America/Anchorage', 'yyyy-MM-dd');
  }
  // default to eastern
  return dateFnsTz.formatInTimeZone(date, 'America/New_York', 'yyyy-MM-dd');
}

export { dateFns, dateFnsTz };
