import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Radio } from 'core/components/radio';
import { MortgageSection } from '../mortgage-section';
import { MortgageInformation } from '../mortgage-information';
import useStyles from './mortgage-radio-group.styles';

const MortgageRadioGroup = ({ name, options, onChange, error }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('');
  const customRadioName = `${name}-custom`;

  const existingMortgageLenderOptions = options.filter(({ isCustom }) => !isCustom);
  const existingOptionsToDisplay = existingMortgageLenderOptions.map((option, index) => {
    const isLastExistingOption = index + 1 === existingMortgageLenderOptions.length;
    return {
      value: index.toString(),
      label: (
        <div
          className={classNames(classes.informationContainer, {
            [classes.lastOptionNameContainer]: isLastExistingOption
          })}
        >
          <MortgageInformation error={error} mortgageDetails={option} />
          <div className={classNames(classes.separator, { [classes.lastOption]: isLastExistingOption })} />
        </div>
      )
    };
  });

  const customOptions = options
    .filter(({ isCustom }) => isCustom)
    .map((option) => ({
      value: (options.length - 1).toString(),
      label: (
        <div className={classNames(classes.informationContainer, { [classes.lastOptionInfoContainer]: true })}>
          <MortgageInformation error={error} mortgageDetails={option} />
        </div>
      )
    }));

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <MortgageSection title={`Existing lender${options.length > 1 ? 's' : ''}`}>
        <Radio
          id={name}
          name={name}
          error={error}
          className={classes.radioGroup}
          labelClassName={classes.label}
          radioClassName={classes.radio}
          mode="light"
          value={selectedValue}
          options={existingOptionsToDisplay}
          onChange={handleChange}
          optionsSideBySide={false}
        />
      </MortgageSection>
      <MortgageSection title="Custom lender">
        <Radio
          id={customRadioName}
          name={customRadioName}
          error={error}
          className={classes.radioGroup}
          labelClassName={classes.label}
          radioClassName={classes.radio}
          mode="light"
          value={selectedValue}
          options={customOptions}
          onChange={handleChange}
          optionsSideBySide={false}
        />
      </MortgageSection>
    </>
  );
};

MortgageRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired
};

export default MortgageRadioGroup;
