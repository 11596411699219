import moment from 'moment-timezone';
import { zonedTimeToUtc } from 'date-fns-tz';

import { USAStatesByAbbv } from '@ourbranch/utility-types';
import { stateToTzMap } from '@ourbranch/state-to-tz-map';

// We default to Ohio for our state for timezone purposes
let tzSavedState = 'OH';

/** Return today's date as a string, accounting for timezones. */
export function getTodayStr(stateIn: USAStatesByAbbv) {
  const state = stateIn || tzSavedState; // we recall the last state if one isn't sent in
  tzSavedState = state;

  if (['WA', 'CA', 'OR', 'NV'].includes(state)) {
    return moment().tz('America/Los_Angeles').format('YYYY-MM-DD');
  }
  if (['AZ', 'NM', 'WY', 'UT', 'CO', 'MT', 'ID', 'ND', 'SD', 'NE'].includes(state)) {
    return moment().tz('America/Boise').format('YYYY-MM-DD');
  }
  if (['AL', 'AR', 'IL', 'IA', 'LA', 'MN', 'MO', 'MS', 'OK', 'WI', 'IN', 'KS', 'MI', 'TN', 'TX'].includes(state)) {
    return moment().tz('America/Chicago').format('YYYY-MM-DD');
  }

  if (state === 'HI') {
    return moment().tz('Pacific/Honolulu').format('YYYY-MM-DD');
  }
  if (state === 'AK') {
    return moment().tz('America/Anchorage').format('YYYY-MM-DD');
  }
  // default to eastern
  return moment().tz('America/New_York').format('YYYY-MM-DD');
}

/** Returns number of days between passed date and birthdate */
export function daysBetweenDateAndNextBirthday(date: Date, birthDate: Date) {
  const thisYearBirthday = moment(birthDate).set('year', new Date().getFullYear());

  if (moment(date).isBefore(thisYearBirthday)) {
    return moment(thisYearBirthday).diff(moment(date), 'days');
  }

  return moment(thisYearBirthday).add(1, 'years').diff(moment(date), 'days');
}

/** Returns an object describing age given a Date object set to birthday. */
export function calculateAge(birthday: Date | string, startMoment = moment()) {
  // note we moment() the startMoment in case it's a string
  const lifetime = moment.duration(moment(startMoment).diff(birthday));
  return { years: lifetime.years(), months: lifetime.months() };
}

export function getDateFromUnparsedString(dateString: string) {
  if (typeof dateString === 'string' && dateString.length === 8) {
    const year = dateString.substr(0, 4);
    const month = dateString.substr(4, 2);
    const day = dateString.substr(6, 2);
    return `${year}-${month}-${day}`;
  }
  return dateString;
}

/** Returns a boolean depending upon whether the supplied string is in the future */
export function isFutureDate(yyyymmddStr: string) {
  return parseInt(yyyymmddStr.replace(/[^0-9]/g, ''), 10) >= parseInt(moment().format('YYYYMMDD'), 10);
}

/** Returns the number of days between now and a supplied day */
export function daysBetween(day: string) {
  return moment().diff(day, 'days') - 1;
}

/** Returns the number of seconds between now and a supplied date/time */
export function secondsBetween(dt: moment.MomentInput) {
  return moment().diff(dt, 'seconds') - 1;
}

/** Returns YYYY-MM-DD from YYYYMMDD */
export function yyyymmddToAWSDate(yyyymmddStrIn: string) {
  const yyyymmddStr = typeof yyyymmddStrIn === 'string' ? yyyymmddStrIn : String(yyyymmddStrIn);
  return yyyymmddStr.length === 8
    ? [yyyymmddStr.slice(0, 4), yyyymmddStr.slice(4, 6), yyyymmddStr.slice(6, 8)].join('-')
    : null;
}

/** Helper function that pads a string to two digits */
function padTwoDigits(str: string) {
  return String(`00${String(str)}`).slice(-2);
}

/** Returns YYYY-MM-DD from an Excel Date  */
export function ExcelDateToAWSDate(serial: number) {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);

  return `${dateInfo.getFullYear()}-${padTwoDigits(String(dateInfo.getMonth()))}-${padTwoDigits(
    String(dateInfo.getDate())
  )}`;
}

/** take a variety of different date formats and turn them all into YYYY-MM-DD */
export function normalizeDate(inputDate: string) {
  if (inputDate.length === 8 && !inputDate.includes('-')) {
    // assuming YYYYMMDD date
    return yyyymmddToAWSDate(inputDate);
  }
  if (inputDate.length < 8 && parseInt(inputDate, 10) > 0) {
    // assuming excel date:
    return ExcelDateToAWSDate(parseInt(inputDate, 10));
  }

  // return what we received
  return inputDate;
}

export const localToUtcTime = (date: string | number | Date, state: string) => {
  return zonedTimeToUtc(date, stateToTzMap[state]);
};

export { moment };
