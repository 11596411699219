/* eslint-disable no-console */
import fetchRetry from 'fetch-retry';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';

import config from 'aws-exports';

const affinityLookupsEndpoint = `${config.coreResourcesEndpoint}/affinity_lookups`;
const agencyInformationEndpoint = `${config.coreResourcesEndpoint}/agency_information`;

const commonHeaders = {
  'X-Api-Key': config.coreResourcesApiKey,
  Accept: 'application/json'
};

const getToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

const fetchWithRetry = (url, options) => {
  return fetchRetry(fetch)(url, options);
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(
      `${response.status} (${response.statusText}) ${errorResponse.error ? `- ${errorResponse.error}` : ''}`
    );
  }

  return response.json();
};

const makeFetchRequest = async (url, requestOptions = {}) => {
  try {
    const response = await fetchWithRetry(url, {
      ...requestOptions,
      headers: commonHeaders
    });
    return handleResponse(response);
  } catch (error) {
    throw new Error(`Error fetching data: ${error.message}`);
  }
};

export const affinityLookupsRequest = async ({
  query = null,
  limit,
  isFuzzySearch,
  requestOptions = {},
  excludeDisabled,
  currentPage,
  perPage
}) => {
  let queryStr = '';
  const shouldRefreshCache = Cookies.get('toolsAffinityShouldRefreshCache') === 'true';

  if (query) {
    queryStr += `?query=${encodeURIComponent(JSON.stringify(query))}`;
  }

  if (limit) {
    // eslint-disable-next-line no-underscore-dangle
    const _limit = `limit=${limit}`;
    queryStr += query ? `&${_limit}` : `?${_limit}`;
  }

  if (isFuzzySearch) {
    queryStr += query ? `&fuzzy=true` : `?fuzzy=true`;
  }

  if (excludeDisabled) {
    queryStr += query ? `&excludeDisabled=true` : `?excludeDisabled=true`;
  }

  if (currentPage) {
    queryStr += query ? `&currentPage=${currentPage}` : `?currentPage=${currentPage}`;
  }

  if (perPage) {
    queryStr += `&perPage=${perPage}`;
  }

  if (shouldRefreshCache) {
    const timestamp = new Date().toISOString();
    queryStr += `${queryStr ? '&' : '?'}timestamp=${timestamp}`;
  }

  const url = `${affinityLookupsEndpoint}${queryStr}`;

  return makeFetchRequest(url, requestOptions);
};

export const updateAffinityLookupRequest = async (values) => {
  try {
    const token = await getToken();
    const response = await fetch(affinityLookupsEndpoint, {
      method: 'PUT',
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });

    return handleResponse(response);
  } catch (error) {
    throw new Error(`Error updating affinity lookup: ${error.message}`);
  }
};

export const addAffinityLookupRequest = async (values) => {
  try {
    const token = await getToken();
    const response = await fetch(affinityLookupsEndpoint, {
      method: 'POST',
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });

    return handleResponse(response);
  } catch (error) {
    throw new Error(`Error inserting affinity lookup: ${error.message}`);
  }
};

export const agencyInformationRequest = async ({ query, perPage, currentPage }) => {
  const { code, search } = query;
  const timestamp = new Date().toISOString();
  const shouldRefreshCache = Cookies.get('toolsAgencyShouldRefreshCache') === 'true';
  const timestampQueryParam = shouldRefreshCache ? `timestamp=${timestamp}` : '';

  const url = code
    ? `${agencyInformationEndpoint}/${code}?`
    : `${agencyInformationEndpoint}/search?free=${search || 'ALL'}`;

  const queryParams = `${perPage ? `&perPage=${perPage}&currentPage=${currentPage}` : ''}${
    timestampQueryParam ? `&${timestampQueryParam}` : ''
  }`;

  return makeFetchRequest(`${url}${queryParams}`, { retryOnNotFound: false });
};

export const updateAgencyInformationRequest = async (values) => {
  try {
    const token = await getToken();
    const response = await fetch(agencyInformationEndpoint, {
      method: 'PUT',
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });

    return handleResponse(response);
  } catch (error) {
    throw new Error(`Error updating agency information: ${error.message}`);
  }
};

export const addAgencyInformationRequest = async (values) => {
  try {
    const token = await getToken();
    const response = await fetch(agencyInformationEndpoint, {
      method: 'POST',
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });

    return handleResponse(response);
  } catch (error) {
    throw new Error(`Error inserting agency information: ${error.message}`);
  }
};
