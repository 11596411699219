import React, { useEffect, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Form, useFormikContext } from 'formik';
import classNames from 'classnames';
import { policyTypeNames, rejectionTypeToPolicyTypeMap } from '@ourbranch/lookups';

import { Label } from 'core';
import { Button } from 'core/components/button';
import { useToast } from 'core/components/toast/context';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import { Rejections } from '../rejections';
import { Blocks } from '../quote-errors';
import FooterRight from './footer-right';
import useStyles from './footer.styles';
import Blocked from 'core/assets/svg/error-blocked.svg';
import Rejected from 'core/assets/svg/error-rejected.svg';

const Footer = ({ disableButton }) => {
  const classes = useStyles();
  const toast = useToast();
  const { canViewSoftRejections } = useSession();
  const {
    quote: { rejections, offer, overriddenProtectedFields, getAlerts, getBlocks, loading }
  } = useStore();
  const { values } = useFormikContext();
  const availableQuote = useMemo(() => {
    return offer?.options?.length > 0;
  }, [offer]);

  // this is only to check for bypass alerts or dismissible rejections which should prevent us showing the footer
  const alerts = getAlerts([], values.policyType);
  const blocks = getBlocks();

  useEffect(() => {
    if (availableQuote && overriddenProtectedFields.length > 0) {
      toast.notify({
        type: 'success',
        message: `We retrieved property details about this home that are different than the details you've entered on fields: ${overriddenProtectedFields.join(
          ', '
        )}. These changes will be reflected in the offer. If you believe this to be incorrect, please follow your designated support process.`
      });
    }
  }, [availableQuote, overriddenProtectedFields.length]);

  const shouldShowFooter = offer && offer.options.length > 0 && !alerts.length > 0;

  const activeLabels = ['H', 'R', 'C', 'A'].filter((label) => {
    return values.policyType.split('').includes(label);
  });
  const emptyBubblesCount = !values.state ? 2 : 2 - activeLabels.length;
  const emptyBubbles = Array.from({ length: emptyBubblesCount }, (_, i) => (
    <div key={`empty-bubble-${i}`} className={classNames(classes.pill, classes.dashed)} />
  ));

  const partialOfferLabel = useMemo(() => {
    if (!availableQuote) {
      return null;
    }
    if (alerts.length > 0 || blocks.length > 0) {
      return null;
    }
    // based off the rejections we have, we need to filter out the options that are blocked
    const allRejectedTypes = rejections
      .flatMap((rejection) => rejectionTypeToPolicyTypeMap[rejection.type])
      .filter((item, index, self) => self.indexOf(item) === index);

    // now we need to filter out invalid / unrequested options from the offer
    const requestedOptions = offer.options.filter((option) => {
      const isRequested = option.type.split('').every((type) => values.policyType.includes(type));
      const isRejected = allRejectedTypes.includes(option.type);
      return isRequested && !isRejected;
    });

    const isOurRequestedOptionAvailable = requestedOptions.some((option) => {
      return option.type === values.policyType;
    });
    // if we have a requested option that is valid then we should show the partial offer
    if (requestedOptions.length > 0 && !isOurRequestedOptionAvailable) {
      return requestedOptions.find((option) => option.type.length === 1).name.toLowerCase();
    }

    return null;
  }, [rejections, offer, values.policyType, availableQuote, alerts, blocks]);

  return (
    <div className={classes.floatingContainer}>
      {rejections.length > 0 && <Rejections />}
      {blocks.length > 0 && <Blocks blocks={blocks} />}
      {shouldShowFooter && !canViewSoftRejections && (
        <div className={classes.footerContainer}>
          <div className={classes.footerContent}>
            <Label className={classes.title} type="subtitle">
              {availableQuote ? 'We got a new offer!' : 'Offer not available!'}
            </Label>
            <FooterRight disabled={!availableQuote} />
          </div>
        </div>
      )}
      {canViewSoftRejections && (
        <div className={classes.footerContainer}>
          <div className={classes.footerRow}>
            <div className={classes.coverageColumn}>
              <div className={classes.selectedCoverage}>SELECTED COVERAGE</div>
              <div className={classes.coverageRow}>
                {activeLabels.map((label) => {
                  if (!values.state) {
                    return null;
                  }

                  const isBlocked = blocks.length > 0;
                  const isRejected = rejections.some((rejection) =>
                    rejectionTypeToPolicyTypeMap[rejection.type].some((type) => type === label)
                  );

                  return (
                    <div
                      key={`${policyTypeNames[label]}-status-pill`}
                      className={classNames(classes.pill, {
                        [classes.active]: !loading && !isBlocked,
                        [classes.error]: !loading && (isBlocked || isRejected)
                      })}
                    >
                      {isBlocked && <img src={Blocked} alt="blocked" className={classes.errorIcon} />}
                      {isRejected && <img src={Rejected} alt="rejected" className={classes.errorIcon} />}
                      {!loading && policyTypeNames[label]}
                      {loading && <Skeleton animation="wave" width="100%" height="100%" className={classes.skeleton} />}
                    </div>
                  );
                })}
                {emptyBubblesCount > 0 && emptyBubbles}
              </div>
              {availableQuote && !loading && (
                <div className={classes.coverageRow}>
                  {activeLabels.map((label) => {
                    const skipPrice =
                      blocks.length > 0 ||
                      rejections.some((rejection) => rejection.type === policyTypeNames[label].toLowerCase());
                    let price;
                    const option = offer.options.find((option) => {
                      return option.type === label;
                    });
                    if (option) {
                      price = label === 'A' ? option.monthly : option.annual;
                    }
                    if (skipPrice || !price) {
                      return <div key={`${policyTypeNames[label]}-price-pill`} className={classes.pricePill} />;
                    }
                    return (
                      <div key={`${policyTypeNames[label]}-price-pill`} className={classes.pricePill}>
                        <span>$</span>
                        <span className={classes.priceBold}>{` ${price} `}</span>
                        <span>AVG / {label === 'A' ? 'MO' : 'YR'}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <Form>
              {!!partialOfferLabel && !loading && (
                <Button
                  loading={loading}
                  className={classNames(classes.submitButton, classes.buttonGap)}
                  href={`/offer/${offer.id}`}
                  target="_blank"
                  variant="contained"
                  color="secondary"
                >
                  <Label>Go to {partialOfferLabel} only offer </Label>
                </Button>
              )}

              <Button
                loading={loading}
                disabled={disableButton}
                className={classes.submitButton}
                variant="contained"
                color="primary"
                type="submit"
                loadingMessage={'Generating Price...'}
              >
                <Label>{availableQuote ? 'Regenerate offer' : 'See price'}</Label>
              </Button>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
