import { policyType as PolicyType } from '@ourbranch/lookups';

import { autoValidationSchema } from './auto.validation-schema';
import { homeValidationSchema } from './home.validation-schema';
import { condoValidationSchema } from './condo.validation-schema';
import { rentersValidationSchema } from './renters.validation-schema';

export const getSchema = (policyType, config) => {
  switch (policyType) {
    case PolicyType.Condo:
      return condoValidationSchema(config);
    case PolicyType.Home:
      return homeValidationSchema(config);
    case PolicyType.Renters:
      return rentersValidationSchema(config);
    default:
      return autoValidationSchema(config);
  }
};
