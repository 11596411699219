import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    minWidth: 850,
    minHeight: 290,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: 20
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    color: theme.colorPalette.green_75,
    fontSize: 40,
    fontWeight: 300,
    padding: 0,
    lineHeight: '56px'
  },
  subtitle: {
    color: theme.colorPalette.green_75,
    fontSize: 16,
    fontWeight: 400,
    padding: 0,
    lineHeight: '24px'
  },
  text: {
    color: theme.colors.green_15,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: 0
  },
  separator: {
    height: 1,
    backgroundColor: '#EAE2D6',
    width: '100%',
    marginTop: 32
  },
  priceLine: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.colors.green_30,
    marginTop: 16,
    marginBottom: 0
  },
  disclaimerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '16px 0'
  },
  disclaimerIcon: {
    height: 16,
    width: 16
  },
  disclaimerText: {
    marginLeft: 8,
    fontSize: 12,
    color: theme.colors.green_30,
    fontWeight: 400,
    lineHeight: '16px'
  },
  subContentContainer: {
    borderRadius: 6,
    backgroundColor: '#EAE2D6',
    padding: '16px 32px'
  },
  subContainerTitle: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px'
  },
  subContainerRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16
  },
  emailField: {
    marginBottom: 0,
    flexGrow: 1
  },
  subContainerButton: {
    marginLeft: 16
  },
  orText: {
    marginLeft: 16,
    fontSize: 12,
    fontWeight: 600
  },
  communicationConsentText: {
    margin: '0 0 0 16px',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.colors.green_15
  }
});

export default makeStyles(styles);
