import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 48,
    paddingBottom: theme.spacing(2)
  },
  identifier: {
    paddingBottom: theme.spacing(2)
  },
  button: {
    width: 36,
    height: 36
  }
});

export default makeStyles(styles);
