import React, { useRef, useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import { Collapsible } from '../collapsible';
import { addAutoCodes } from '../quote-forms/quote-forms.constants';
import { ErrorAlert } from '../quote-errors';

const AddCar = ({ sectionsToShow }) => {
  const {
    quote: { getAlerts }
  } = useStore();

  const carErrors = useRef(null);
  const { setFieldValue, values: formValues, values, isSubmitting } = useFormikContext();
  const [shouldShow, setShouldShow] = useState(false);

  const alerts = getAlerts(addAutoCodes, values.policyType);

  useEffect(() => {
    if (alerts.length && carErrors.current && isSubmitting) {
      carErrors.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [alerts, isSubmitting]);

  useEffect(() => {
    setFieldValue('VIN', formValues.VIN.toUpperCase().trim());
  }, [formValues.VIN, setFieldValue]);

  useEffect(() => {
    if (sectionsToShow && sectionsToShow.length > 0) {
      const shouldBeShown = addAutoCodes.every((element) => sectionsToShow.includes(element));
      setShouldShow(shouldBeShown);
    }
  }, [sectionsToShow]);

  return (
    (alerts.length > 0 || shouldShow) && (
      <Form>
        {alerts.length > 0 && <ErrorAlert errors={alerts} ref={carErrors} />}
        <Collapsible title="Add Car" forceOpen={alerts.length > 0} initialOpen={alerts.length > 0}>
          <Grid item xs={6}>
            <Field type="string" id="VIN" name="VIN" mode="light" label="Vin Number" />
          </Grid>
        </Collapsible>
      </Form>
    )
  );
};

AddCar.propTypes = {
  sectionsToShow: PropTypes.array.isRequired
};

export default withDatePicker(AddCar);
