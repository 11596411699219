import { DynamoDbPolicy } from '@ourbranch/schema-types/dynamodb';
import { policyTypes } from '@ourbranch/policy-types';
import { cancelCodeInformationalArray } from '@ourbranch/lookups';
import { templates } from './templates';
// NOTE: This settings file is intended to have any setting that's docs specifics and that's
// not consumed from a frontend.

export const documentTypes = {
  APPLICATION: 0,
  POLICY_JACKET: 1,
  ID_CARDS: 2,
  DECLARATION: 3,
  FCRA_NOTICE: 4,
  CANCELLATION: 5,
  POLICY_MOD_CONFIRMATION: 6,
  RENEWAL: 7,
  CONVERSION: 8,
  UM_NOTICE: 9,
  MEDPAY: 10,
  PIP_NOTICE: 11,
  BIX_CONVERSION_NON_RENEWAL: 12,
  NDEL_NOTICE: 13,
  ESCROW_INVOICE: 14,
  REPLACEMENT_COST_ESTIMATE: 15,
  SUBSCRIBER_AGREEMENT: 16,
  PIP_SELECTION_FORM: 17,
  REFUSAL_TO_WRITE: 18,
  NON_RENEWAL: 19
};

export const ndelFormAtApplicationStates = [
  'DC',
  'GA',
  'IA',
  'LA',
  'MA',
  'MI',
  'MO',
  'MT',
  'ND',
  'NM',
  'OH',
  'PA',
  'UT',
  'WV'
];

export const ndelFormAtApplicationBixStates = ['IL', 'TX'];

// note that in MA, we print the stamp/cert doc as the ID card, but called MRMV instead of IDCD
export const noIDCardStates = {};

export const individualAutoIdStates = ['FL'];

export const reduFormStates = {
  NH: true
};

export const adwrFormStates = {
  AR: true,
  AZ: true,
  CO: false,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MD: true,
  ME: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NH: true,
  NM: false,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  TN: true,
  TX: true,
  UT: true,
  VA: false,
  WI: true,
  WV: true,
  WY: false
};

export const trnoFormStates = {
  SC: true
};

export const discFormStates = {
  CO: true,
  VT: true
};

export const wgptFormStates = {
  MA: true,
  OH: true
};

export const tpanFormStates = {
  NH: true
};

export const aelcnFormStates = {
  VA: true
};

export const belcnFormStates = {
  FL: true,
  IA: true,
  IL: true,
  KS: true,
  LA: true,
  MI: true,
  MT: true,
  NH: true,
  NM: true,
  OK: true,
  TX: true,
  VT: true
};

export const rtnoFormStates = {
  MI: true,
  NH: true
};

export const gbckFormStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  FL: true,
  GA: true,
  IA: true,
  ID: false,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MA: true,
  MD: true,
  ME: true,
  MI: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NH: false,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VA: false,
  VT: true,
  WI: true,
  WV: true,
  WY: false
};

export const noNDEXStates = {
  MA: true
};

export const noANMLStates = {
  DC: true,
  IA: true,
  MA: true,
  ME: true,
  ND: true,
  NH: true,
  VA: true,
  VT: true
};

export const noROFMStates = {
  DC: true,
  MA: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  VT: true,
  WY: true
};

export const noROOFStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noROOSStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noROFEStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  VT: true
};

export const noROSEStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  OK: true,
  TX: true,
  VA: true,
  WV: true,
  VT: true
};

export const noRCOMStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noADNRStates = {
  ND: true
};

export const noADDIStates = {
  ND: true
};

export const noADTRStates = {
  ND: true
};

export const noADNOStates = {
  ND: true
};

export const noDPROStates = {
  IA: true
};

export const mandatoryROOSStates = {
  OK: true,
  TX: true
};

export const condoAMNDFormStateDates = {
  AZ: '2023-07-30',
  MO: '2023-09-21',
  PA: '2023-10-26',
  WI: '2023-09-21'
};

export const rentAMNDFormStateDates = {
  ND: '2023-10-05',
  NM: '2023-10-05',
  PA: '2023-10-26'
};

export const autoAMNDFormStateDates = {
  AR: '2022-11-22',
  DC: '2024-01-04',
  IA: '2023-10-12',
  ID: '2022-12-05',
  IL: '2023-11-06',
  KY: '2023-08-04',
  LA: '2024-01-04',
  MI: '2022-12-05',
  MO: '2023-09-21',
  MS: '2023-11-09',
  MT: '2023-09-14',
  ND: '2023-10-05',
  NE: '2023-08-31',
  NH: '2022-12-05',
  NM: '2022-12-23',
  OK: '2000-01-01',
  OR: '2022-11-22',
  PA: '2023-10-26',
  SD: '2022-12-05',
  TN: '2022-12-05',
  TX: '2024-04-21',
  UT: '2000-01-01',
  WI: '2023-09-21',
  WV: '2022-12-05',
  WY: '2022-12-05'
};

export const homeAMNDFormStartDates = {
  AR: '2023-12-14',
  AZ: '2000-01-01',
  DC: '2024-01-04',
  GA: '2023-09-28',
  IA: '2023-10-12',
  ID: '2022-12-05',
  IL: '2023-03-31',
  IN: '2023-07-14',
  KY: '2023-08-04',
  LA: '2000-01-01',
  MI: '2000-01-01',
  MO: '2000-01-01',
  MS: '2023-11-09',
  MT: '2023-09-14',
  ND: '2000-01-01',
  NE: '2023-08-31',
  NH: '2022-12-05',
  NM: '2000-01-01',
  OH: '2000-01-01',
  OK: '2023-08-04',
  OR: '2022-11-04',
  PA: '2022-12-15',
  TN: '2022-12-05',
  TX: '2024-07-11',
  UT: '2023-09-28',
  WI: '2023-09-21',
  WV: '2023-09-14',
  WY: '2022-12-05'
};

export const homeAMNDFormEndDates = {
  AZ: '2023-07-29',
  MI: '2023-08-04',
  OH: '2023-09-07'
};

export const trccFormStateDates = {
  AL: '2022-11-22',
  AR: '2022-11-22',
  AZ: '2022-11-22',
  CO: '2023-02-15',
  GA: '2022-01-01',
  IA: '2022-11-22',
  ID: '2022-11-22',
  IL: '2022-11-22',
  IN: '2022-11-22',
  KS: '2022-11-22',
  KY: '2022-11-22',
  LA: '2022-11-22',
  MD: '2022-11-22',
  ME: '2022-11-22',
  MI: '2022-11-22',
  MO: '2022-11-22',
  MS: '2022-01-01',
  MT: '2022-11-22',
  ND: '2022-11-22',
  NE: '2022-11-22',
  NH: '2022-11-22',
  NM: '2022-11-22',
  PA: '2022-11-22',
  OH: '2022-11-22',
  OK: '2022-11-22',
  OR: '2022-11-22',
  SC: '2022-11-22',
  SD: '2022-11-22',
  TN: '2022-11-22',
  TX: '2022-11-22',
  UT: '2022-01-01',
  VT: '2022-11-22',
  WI: '2022-11-22',
  WV: '2022-11-22'
};

export const tpdcFormStateDates = {
  AL: '2022-11-22',
  AR: '2022-11-22',
  AZ: '2022-11-22',
  CO: '2023-02-15',
  GA: '2022-01-01',
  IA: '2022-11-22',
  ID: '2022-11-22',
  IL: '2022-11-22',
  IN: '2022-11-22',
  KS: '2022-11-22',
  KY: '2022-11-22',
  LA: '2022-11-22',
  MD: '2022-11-22',
  ME: '2022-11-22',
  MI: '2022-11-22',
  MO: '2022-11-22',
  MS: '2022-01-01',
  MT: '2022-11-22',
  ND: '2022-11-22',
  NE: '2022-11-22',
  NH: '2022-11-22',
  NM: '2022-11-22',
  PA: '2022-11-22',
  OH: '2022-11-22',
  OK: '2022-11-22',
  OR: '2022-11-22',
  SC: '2022-11-22',
  SD: '2022-11-22',
  TN: '2022-11-22',
  TX: '2022-11-22',
  UT: '2022-01-01',
  VT: '2022-11-22',
  WI: '2022-11-22',
  WV: '2022-11-22'
};

export const sacnFormStateDates = {
  AL: '2022-11-22',
  AZ: '2022-11-22',
  IA: '2022-11-22',
  ID: '2022-11-22',
  IN: '2022-11-22',
  KS: '2022-11-22',
  KY: '2022-11-22',
  MD: '2022-11-22',
  MI: '2022-11-22',
  MO: '2022-11-22',
  OH: '2022-11-22',
  OK: '2022-11-22',
  OR: '2022-11-22',
  SC: '2022-11-22',
  SD: '2022-11-22',
  TN: '2022-11-22',
  TX: '2022-11-22',
  WI: '2022-11-22',
  WV: '2022-12-05'
};

export const bwvcdFormStates = {
  WV: true
};

export const autoEXPIFormStatesList = [
  'AR',
  'AZ',
  'DC',
  'GA',
  'IA',
  'ID',
  'IL',
  'IN',
  'LA',
  'MD',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NE',
  'NH',
  'NM',
  'OK',
  'OR',
  'PA',
  'SD',
  'TN',
  'TX',
  'UT',
  'WI',
  'WV',
  'WY'
];

export const homeEXPIFormStatesList = [
  'AL',
  'AR',
  'AZ',
  'CO',
  'DC',
  'GA',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'LA',
  'MD',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NE',
  'NH',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'WI',
  'WV',
  'WY'
];

export const rentEXPIFormStatesList = ['AZ', 'ND', 'NM', 'PA'];

export const condoEXPIFormStatesList = ['AZ', 'MI', 'MO', 'OH', 'PA', 'WI'];

export const mortgageHoldersAffinities = [
  {
    name: 'QUICKEN LOANS INC ISAOA',
    affinity: 'QL1'
  }
];

// This is the list of reasons where we need to add 3 days to the mail date.
// For any other reason we'll use today as mail date
export const branchRequestCancelReasons = ['CNCN', 'CNPP', 'CNPY', 'CNUW', 'CNUV', 'CNUH', 'CNSR', 'CNFP'];

// Calculates given the nonRenew reason and the state if we need to generate the
// document with Oden or CD
export const shouldHandleNonRenewalWithOden = (policy: DynamoDbPolicy) => {
  // Making the return here explicit for clarity and to allow for future changes
  // OH is the first state for which we are generating Oden documents and
  // NRSC is a GSNIC non renewal and will be generated with the legacy flow
  // NREV is an Everspan non renewal and will be generated with the legacy flow
  if (policy.state === 'OH' && !['NRSC', 'NREV'].includes(policy.nonRenewReason!)) {
    return true;
  }
  return false;
};

export function getTemplateId(policy: DynamoDbPolicy): string {
  // Given the policy type and cancellation code, it maps to the corresponding template id
  const policyTypeCancellationCodeTemplateMapping = {
    [policyTypes.Home]: {
      CNRS: templates.H_CNRS.id,
      CNPY: templates.H_CNPY.id,
      NRRS: templates.H_NRRS.id,
      NULL: templates.H_NULL.id
    },
    [policyTypes.Condo]: {
      CNRS: templates.H_CNRS.id,
      CNPY: templates.H_CNPY.id,
      NRRS: templates.H_NRRS.id,
      NULL: templates.H_NULL.id
    },
    [policyTypes.Auto]: {
      CNRS: templates.A_CNRS.id,
      CNPY: templates.A_CNPY.id,
      NRRS: templates.A_NRRS.id,
      NULL: templates.A_NULL.id
    },
    [policyTypes.Renters]: {
      CNRS: templates.R_CNRS.id,
      CNPY: templates.R_CNPY.id,
      NRRS: templates.R_NRRS.id
    }
  };

  const getCancelNoticeName = (reason: any) => {
    return (<any>cancelCodeInformationalArray).find((cancelCodeObject: any) => cancelCodeObject.cancelCode === reason)
      ?.cancelNoticeName;
  };

  let cancelNoticeName = getCancelNoticeName(policy.cancelReason);
  if (!cancelNoticeName && policy?.nonRenewReason && policy?.nonRenewReason !== 'NA') {
    // check for nonRenewReason if we didn't find a cancel doc with cancelReason
    cancelNoticeName = getCancelNoticeName(policy?.nonRenewReason);
  }
  return (<any>policyTypeCancellationCodeTemplateMapping[policy.policyType])[cancelNoticeName];
}
