import React from 'react';
import PropTypes from 'prop-types';

import { InfoField } from '../../info-field';
import { InnerSection } from './inner-section';

const AddressInformation = ({ address }) => {
  const { address: street, city, state, zip } = address || {};
  return (
    <InnerSection>
      <InfoField label="Address" value={street} xs={12} />
      <InfoField label="City" value={city} xs={4} />
      <InfoField label="State" value={state} xs={4} />
      <InfoField label="Zip" value={zip} xs={4} />
    </InnerSection>
  );
};

AddressInformation.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired
  })
};

AddressInformation.defaultProps = {
  address: null
};

export default AddressInformation;
