import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import useStyles from './transfer-to-agency-notification.styles';

const TransferToAgencyNotification = ({ fromPolicy }) => {
  const classes = useStyles();
  const {
    account: { id }
  } = useStore();
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (fromPolicy) {
      history.push(`/customer/${id}?scroll=agentDetails`);
    } else {
      document.getElementById('agentDetails').scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [id, history]);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Label className={classes.warning}>Refer to your resources for service instructions.</Label>
        <Label className={classes.subText}>This is an agency-bound member</Label>
      </Grid>
      <Button color="secondary" variant="contained" className={classes.button} onClick={handleClick}>
        Go to agency details
      </Button>
    </Grid>
  );
};

TransferToAgencyNotification.propTypes = {
  fromPolicy: PropTypes.bool
};

TransferToAgencyNotification.defaultProps = {
  fromPolicy: false
};

export default observer(TransferToAgencyNotification);
