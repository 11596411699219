import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';

import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import { useToolName } from 'tools/hooks/use-tool-name';
import { TaskHistoryRow } from './task-history-row';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  }
}));

const GET_TOOL_HISTORY = gql`
  query getToolHistory($toolName: String) {
    getToolHistory(toolName: $toolName) {
      taskRunId
      endedAt
      startedAt
      startedBy
      status
      taskName
      arguments
      parentRunId
      taskYYMM
    }
  }
`;

const TaskHistory = ({ title, doneRunning }) => {
  const classes = useStyles();
  const [taskHistory, setTaskHistory] = useState([]);
  const toast = useToast();
  const toolName = useToolName();
  const { data, error, loading, refetch } = useQuery(GET_TOOL_HISTORY, {
    variables: { toolName },
    skip: !toolName
  });

  useEffect(() => {
    if (error) {
      toast.notify({
        type: 'error',
        message: `Error fetching task history - ${error?.message}`
      });
    }
    if (data?.getToolHistory) {
      setTaskHistory(data.getToolHistory);
    }
  }, [error, data]);

  useEffect(() => {
    if (doneRunning) {
      refetch();
    }
  }, [doneRunning, refetch]);

  if (loading) {
    <Label type="subtitle" className={classes.sectionTitle}>
      Recent Runs
    </Label>;
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Label type="subtitle" className={classes.sectionTitle}>
        Recent Runs
      </Label>
      {taskHistory.map((task) => (
        <TaskHistoryRow key={task.taskRunId} task={task} title={title} />
      ))}
    </div>
  );
};

export default TaskHistory;
