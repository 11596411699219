import React from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';

import useStyles from '../footer.styles';
import { ConfirmCheckout } from '../../checkout';

const Checkout = observer(({ showValidationErrorsModal, onCheckout, copyLink, buttonLabel }) => {
  const { offer: store } = useStore();
  const classes = useStyles();
  const { validateForm } = useFormikContext();
  const buttonLabelValue = buttonLabel || 'Continue to Checkout';

  return (
    <>
      <div>
        <Label className={classes.title} type="subtitle">
          Your Offer is Ready
        </Label>
        <Button className={classes.copyLink} color="secondary" variant="text" onClick={copyLink}>
          Copy Offer Link
        </Button>
      </div>
      <ConfirmCheckout
        onCheckout={onCheckout}
        showValidationModal={showValidationErrorsModal}
        validateCheckout={validateForm}
        CheckOutAction={({ onClick }) => (
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            disabled={store.loading}
            onClick={onClick}
          >
            <Label className={classes.btnLabel}>{buttonLabelValue}</Label>
          </Button>
        )}
      />
    </>
  );
});
export default withToast(Checkout);
