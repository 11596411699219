import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';

import NotificationIconWhite from 'core/assets/svg/alert-white-filled.svg';
import NotificationIconOrange from 'core/assets/svg/info-orange.svg';
import NotificationIconGreen from 'core/assets/svg/alert-green.svg';
import icInfoIconGreen from 'core/assets/svg/ic-info-dark.svg';
import icInfoIconWhite from 'core/assets/svg/ic-info-white.svg';
import { Card } from '../card';
import { Label } from '../label';
import useStyles from './notification-card.styles';

const icons = {
  primary: NotificationIconWhite,
  secondary: NotificationIconOrange,
  tertiary: NotificationIconWhite,
  quaternary: NotificationIconWhite,
  quinary: NotificationIconOrange,
  light: NotificationIconGreen,
  infoGreen: icInfoIconGreen,
  infoWhite: icInfoIconWhite
};

const NotificationCard = ({
  children,
  type,
  noBackground,
  className,
  icon: ic,
  header,
  iconByHeader,
  iconSize: icSize
}) => {
  const classes = useStyles();
  const icon = ic || icons[type];
  const iconSize = icSize || 'icon';

  // using noBackground prop to return an inline icon and text notification instead of the whole card
  if (noBackground) {
    return (
      <Grid className={classes.noBackground}>
        <img src={icon} className={classes[iconSize]} alt="Notification Icon" />
        {children}
      </Grid>
    );
  }

  if (header) {
    return iconByHeader ? (
      <Card className={classnames(classes.columnContainer, classes[type], className)}>
        <Grid className={classes.headerRow}>
          <img src={icon} className={classes[iconSize]} alt="Notification Icon" />
          <Label type="darkSmall" fontWeight="600">
            {header}
          </Label>
        </Grid>

        <Grid className={classes.text}>
          <Grid className={classes.notificationContentContainer}>{children}</Grid>
        </Grid>
      </Card>
    ) : (
      <Card className={classnames(classes.sideBySideContainer, classes[type], className)}>
        <img src={icon} className={classes[iconSize]} alt="Notification Icon" />
        <Grid className={classes.text}>
          <Label type="darkSmall" fontWeight="600">
            {header}
          </Label>
          <Grid className={classes.notificationContentContainer}>{children}</Grid>
        </Grid>
      </Card>
    );
  }
  return (
    <Card className={classnames(classes.container, classes[type], className)}>
      <Grid className={classes.notificationContentContainer}>
        <img src={icon} className={classes[iconSize]} alt="Notification Icon" />
        {children}
      </Grid>
    </Card>
  );
};

NotificationCard.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'light']),
  icon: PropTypes.string,
  iconSize: PropTypes.oneOf(['icon', 'icon16'])
};

NotificationCard.defaultProps = {
  type: 'primary',
  icon: null
};

export default NotificationCard;
