import React, { useEffect } from 'react';
import { useQueryParams } from 'core/hooks/useQueryParams';
import { AuthLoader, setCognitoUserSession } from 'core/components/auth';
import ErrorDialog from 'core/components/error-dialog/error-dialog';

const OAuthResponse = () => {
  const { code, state, error_description } = useQueryParams();

  useEffect(() => {
    if (code && state) {
      setCognitoUserSession(code);
    }
  }, []);

  // Handle Cognito error
  if (error_description) {
    return (
      <ErrorDialog
        open
        onClose={() => {
          window.location.href = '/signin';
        }}
        title="Authentication Error"
        errors={[error_description]}
        label="Contact Support"
        // @TODO: Handle onClick
        onClick={() => {}}
      />
    );
  }

  return <AuthLoader />;
};

export default OAuthResponse;
