import { useMemo } from 'react';

import { capitalize } from 'core/helpers/formatters';
import useSession from 'core/hooks/use-session';
import { messages } from '../constants/error-messages';

const parseMessage = (message) => {
  try {
    const parsedMessage = JSON.parse(message);
    return parsedMessage.errorMessage;
  } catch (e) {
    return message;
  }
};

const isFunction = (obj) => {
  return typeof obj == 'function' || false;
};

const useError = (err, state, selectedOption = '') => {
  const { canClearUWFormRejections, isAgency } = useSession();
  return useMemo(() => {
    let error = null;

    if (err?.message === 'Execution timed out.') {
      return {
        code: err.code,
        title: 'We have received an error',
        message: `This offer took too long to generate. After 15 seconds, please search for the applicant's name and address in
        the offer search. If the offer does not appear, please retry.`
      };
    }

    if (!isNaN(err.code)) {
      error = { ...err, ...messages(state, isAgency, selectedOption)[err?.code] };
    }

    if (error && (error.message || error.data?.message)) {
      const { isUnknownError } = error;
      if (isUnknownError) {
        error.message = parseMessage(err.message);
      } else if (error.data?.message) {
        error.message = error.data.message;
      }

      // this allows us to use computed titles for errors
      // if error.title is a function, call it with the error as a parameter, and set the error title to the computed value
      // (should always return a string always)
      if (isFunction(error.title)) {
        error.title = error.title({ error });
      }

      if (error.type && error.title && String(error.title).includes('[type_placeholder]')) {
        error.title = String(error.title).replace('[type_placeholder]', capitalize(error.data?.type || error.type));
      }

      error.code = Number(err.code);
      error.message = error.agentScript || error.messageAsJsx || error.message;
      error.action = error.canBeOverridden && (canClearUWFormRejections || isAgency) ? '' : error.agentAction;
    }

    return error;
  }, [canClearUWFormRejections, isAgency, err]);
};

export default useError;
