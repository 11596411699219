/* eslint-disable */
import awsConfig from './aws-exports';
import Cookies from 'js-cookie';
import { debounce as _debounce } from 'lodash-es';
import { init as sentryInit, captureException } from '@sentry/react';

const isCrawler = () => /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

const AGENCY_CHAT_ID = '3d698c17b45c1575b862ceb645b4b4bd';
const INTERNAL_HELP_DESK_CHAT_ID = '6353596ce5e684d6a05ecc03875b6356';
const TEST_FRONT_CHAT_WITH_KYBER = 'fa80b34c1dacf6801f17c8a6faf477fd';

function initializeSegment() {
  if (isCrawler() || !awsConfig.trackingEnabled) {
    return;
  }
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on'
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(awsConfig.segmentKey, {
          integrations: {
            All: true
          }
        });
        analytics.page();
        const sessionId = Cookies.get('ampSession.id') || Date.now();
        Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 }); //session expires in 30 mins
      }
  })();
}

function initializeLogRocket() {
  if (awsConfig.trackingEnabled) {
    import('logrocket').then(({ default: LogRocket }) => LogRocket.init(awsConfig.logRocketApiKey));
  }
}

function initializeFriendBuy(merchantId) {
  window['friendbuyAPI'] = [];
  friendbuyAPI = window['friendbuyAPI'];

  friendbuyAPI.merchantId = merchantId;
  friendbuyAPI.push(['merchant', friendbuyAPI.merchantId]);
  // load the merchant SDK and your campaigns
  (function (f, r, n, d, b, u, y) {
    while ((u = n.shift())) {
      (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
      b.async = 1;
      b.src = u;
      y.parentNode.insertBefore(b, y);
    }
  })(document, 'script', [
    'https://static.fbot.me/friendbuy.js',
    'https://campaign.fbot.me/' + friendbuyAPI.merchantId + '/campaigns.js'
  ]);
}

export async function initializeFB() {
  if (awsConfig.friendBuyMerchantId) {
    await initializeFriendBuy(awsConfig.friendBuyMerchantId);
  }
}

export const getFrontChatId = (session) => {
  if (awsConfig.stackeryEnvironmentName.includes('uat')) {
    return TEST_FRONT_CHAT_WITH_KYBER;
  }
  return !session || session?.isAgency ? AGENCY_CHAT_ID : INTERNAL_HELP_DESK_CHAT_ID;
};

/**
 * Initialize front chat and identify user in the chat.
 * If no session, still inits front chat with no session in case an external agent is having issues logging in.
 *
 * Cannot rapidly fire the identify after init or it will not work, so use setTimeout to space out the calls.
 *
 * @param {Object} session - session context object
 *
 */
export const initializeFrontChatAndIdentify = _debounce((session) => {
  try {
    console.log(`initializing front chat for session: ${JSON.stringify({ isAgency: session?.isAgency })}`);

    const chatId = getFrontChatId(session);

    setTimeout(() => {
      window.FrontChat('init', {
        chatId,
        useDefaultLauncher: false
      });
    }, 1000);

    setTimeout(() => {
      identifyUserInFront(session?.user);
    }, 2000);
  } catch (error) {
    console.log(`error initializing front chat: ${JSON.stringify(error)}`);
    captureException(error);
  }
}, 100);

export function identifyUserInFront(user) {
  if (user) {
    console.log(`identifying user in front: ${JSON.stringify({ username: user.username, email: user.email })}`);
    window.FrontChat('identity', {
      name: user.username,
      email: user.email
    });
  }
}

function initializeUpscope() {
  // This will initiate Upscope connection. It's important it is added to all pages,
  // even when the user is not logged in.

  // You can place this in the head or the body of the page.

  (function (w, u, d) {
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://code.upscope.io/PZsehcBK2C.js';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (typeof u !== 'function') {
      w.Upscope = i;
      l();
    }
  })(window, window.Upscope, document);

  Upscope('init');
}

export function identifyUserInUpscope(user) {
  // If the user is logged in, optionally identify them with the following method.
  // You can call Upscope('updateConnection', {}); at any time.

  Upscope('updateConnection', {
    // Set the user ID below. If you don't have one, set to undefined.
    uniqueId: user.username,

    // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
    identities: [user.email]
  });
}

function initializeSentry() {
  if (process.env.REACT_APP_SENTRY_ENABLED === 'true' && process.env.NODE_ENV === 'production') {
    sentryInit({
      dsn: 'https://92e31532c8f0419eb6c9d3ce2f620928@sentry.io/1501891',
      environment: process.env.REACT_APP_STACKERY_ENVIRONMENT,
      ignoreErrors: ['ResizeObserver loop limit exceeded', 'NotAuthorizedException', 'subscriptionFailedCallback'],
      // Documentation: https://docs.sentry.io/platforms/javascript/configuration/filtering/
      beforeSend(event) {
        try {
          // Level: error
          if (event.level === 'error' && event.exception?.values) {
            const failedToFetchException = event.exception.values.some(
              (v) => v.type === 'TypeError' && v.value === 'Failed to fetch'
            );
            // Exception: TypeError: Failed to fetch
            if (failedToFetchException && event.breadcrumbs) {
              const audiencepsynchBreadcrumb = event.breadcrumbs.find(
                (b) =>
                  b.category === 'fetch' &&
                  b.data &&
                  b.data.method === 'POST' &&
                  b.data.url?.includes('analytics2.audiencepsynch.com/api/v1/pixel-data/json')
              );
              // audiencepsynch endpoint
              if (audiencepsynchBreadcrumb) {
                // Ignore event
                return null;
              }
            }
          }
          return event;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('[sentry] Failed checking if event should be ignored', error);
          return event;
        }
      }
    });
  }
}

export default function configureServices() {
  initializeLogRocket();
  initializeSegment();
  initializeUpscope();
  initializeSentry();
}
