import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 32,
    position: 'relative',
    overflowWrap: 'anywhere'
  },
  separator: {
    width: '100%',
    borderBottom: `1px solid ${theme.colorPalette.beige_10}`,
    marginBottom: theme.spacing(4)
  },
  copyButtonLabel: {
    textTransform: 'uppercase',
    fontWeight: 600
  }
}));

export default useStyles;
