import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import notificationIcon from './assets/alert-small-white.svg';
import chevronRightIcon from './assets/chevron-right.svg';
import useStyles from './update-mortgage-notification.styles';

const UpdateMortgageNotification = ({ isMortgageAdded, onNavigateToDetails }) => {
  const classes = useStyles();

  return (
    <Card className={classNames(classes.card, { [classes.errorCard]: !isMortgageAdded })}>
      <img src={notificationIcon} alt="Notification Icon" />
      <Label type="smallWhiteBold">
        {isMortgageAdded ? 'Want to update the mortgage lender?' : 'You need to add a mortgage lender.'}{' '}
        <Label type="label" className={classes.label}>
          Navigate to the details tab and update the lender associated with the property.
        </Label>
      </Label>
      <Button color="secondary" variant="text" className={classes.button} onClick={onNavigateToDetails}>
        Go to details
        <img src={chevronRightIcon} className={classes.chevronRightIcon} alt="Go to details" />
      </Button>
    </Card>
  );
};

UpdateMortgageNotification.propTypes = {
  isMortgageAdded: PropTypes.bool,
  onNavigateToDetails: PropTypes.func.isRequired
};

UpdateMortgageNotification.defaultProps = {
  isMortgageAdded: false
};

export default UpdateMortgageNotification;
