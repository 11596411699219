/* eslint-disable func-names */
import * as Yup from 'yup';
// for the UW form, we use validation to determine if a user has submitted a wrong answer (an answer that goes against underwriting rules).
// ex. user submits Yes for noAsbestosCheck question, that will make the offer ineligible for home.
// the validation will run and return an error object with the node, which will be used in cannot-offer-modal.js to determine eligibility
// at that modal, the user is given the option to drop home/auto, setting ineligibleForHomeDueToUWSelfReport/ineligibleForAutoDueToUWSelfReport to true
// and skip validation to submit and recalc so that the backend UW rules can drop the ineligible policy type
export const UWPropertyValidationSchema = () =>
  Yup.object().shape({
    signedPropertyUWAttestation: Yup.boolean().oneOf([true], `Agent must check attestation checkbox`).required(),

    home: Yup.object().shape({
      noAsbestosCheck: Yup.string().test('noAsbestosCheck', function (value) {
        const needsValidation = !!this.options.context?.UWConditions?.noAsbestosCheck;
        if (needsValidation) {
          return value !== 'Yes' && value !== null && value !== undefined;
        }
        return true; // node is not part of initial values and not being rendered, force pass here
      }),
      homeWiringCheck: Yup.string().test('homeWiringCheck', function (value) {
        const needsValidation = !!this.options.context?.UWConditions?.homeWiringCheck;
        if (needsValidation) {
          return value !== 'No' && value !== null && value !== undefined;
        }
        return true; // node is not part of initial values and not being rendered, force pass here
      }),
      homePlumbingCheck: Yup.string().test('homePlumbingCheck', function (value) {
        const needsValidation = !!this.options.context?.UWConditions?.homePlumbingCheck;
        if (needsValidation) {
          return value !== 'No' && value !== null && value !== undefined;
        }
        return true; // node is not part of initial values and not being rendered, force pass here
      }),
      noOpenFoundationCheck: Yup.string().test('noOpenFoundationCheck', function (value) {
        const needsValidation = !!this.options.context?.UWConditions?.noOpenFoundationCheck;
        if (needsValidation) {
          return value !== 'No' && value !== null && value !== undefined;
        }
        return true; // node is not part of initial values and not being rendered, force pass here
      })
    })
  });
