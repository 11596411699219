import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import Documents from './components/documents';
import Policies from './components/policies';
import Tickets from './components/tickets';
import Claims from './components/claims';
import { CustomerNotifications } from './components/customer-notifications';
import CustomerTabs from './components/tabs';
import { OnboardingTaskCenter } from './components/onboarding-task-center';
import { BranchPlusPolicies } from './components/branch-plus-policies';

const Tabs = {
  DETAILS: 0,
  TICKETS: 1,
  CLAIMS: 2
};

const Customer = observer(function Customer({ match, history }) {
  const [activeTab, setActiveTab] = useState(Tabs.DETAILS);
  const session = useSession();

  const { account } = useStore();
  const { notifications } = account;

  return (
    <>
      {notifications?.length && <CustomerNotifications />}
      <CustomerTabs
        activeTab={activeTab}
        handleTabChange={(_, tab) => {
          setActiveTab(tab);
        }}
        setActiveTab={setActiveTab}
      />
      {activeTab === Tabs.DETAILS && (
        <>
          <Policies />
          {session.isInternalAgent && <BranchPlusPolicies />}
          <OnboardingTaskCenter />
          <Documents showPolicyIdColumn />
        </>
      )}
      {activeTab === Tabs.TICKETS && <Tickets accountId={match.params.id} />}
      {activeTab === Tabs.CLAIMS && <Claims />}
    </>
  );
});

Customer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(Customer);
