const oAuthStorage = {
  setAuthEmail: (email) => {
    window.sessionStorage.setItem('auth_email', email);
  },
  getAuthEmail: () => {
    const authEmail = window.sessionStorage.getItem('auth_email');
    window.sessionStorage.removeItem('auth_email');
    return authEmail;
  },
  setState: (state) => {
    window.sessionStorage.setItem('oauth_state', state);
    window.sessionStorage.setItem('auth_is_started', 'true');
  },
  getAuthStatus: () => {
    const authStatus = window.sessionStorage.getItem('auth_is_started');
    window.sessionStorage.removeItem('auth_is_started');
    return authStatus;
  },
  getState: () => {
    const oAuthState = window.sessionStorage.getItem('oauth_state');
    const hasInitOAuth = window.sessionStorage.getItem('auth_is_started');
    window.sessionStorage.removeItem('auth_is_started');
    window.sessionStorage.removeItem('oauth_state');

    return { oAuthState, hasInitOAuth };
  },
  setInitiatedAuthStatus: (status) => {
    window.sessionStorage.setItem('initiated_auth', status);
  },
  getInitiatedAuthStatus: () => {
    const initiated_auth = window.sessionStorage.getItem('initiated_auth');
    window.sessionStorage.removeItem('initiated_auth');
    return initiated_auth;
  },
  setPKCE: (privateKey) => {
    window.sessionStorage.setItem('oauth_pkce_key', privateKey);
  },
  getPKCE: () => {
    const oAuthPkceKey = window.sessionStorage.getItem('oauth_pkce_key');
    window.sessionStorage.removeItem('oauth_pkce_key');
    return oAuthPkceKey;
  },
  setDeepLink: (url) => {
    window.sessionStorage.setItem('deep_link', url);
  },
  getDeepLink: () => {
    const deepLink = window.sessionStorage.getItem('deep_link');
    window.sessionStorage.removeItem('deep_link');
    return deepLink;
  },
  clearAll: () => {
    window.sessionStorage.removeItem('ouath_pkce_key');
    window.sessionStorage.removeItem('oauth_state');
    window.sessionStorage.removeItem('initiated_auth');
    window.sessionStorage.removeItem('auth_email');
  }
};

export default oAuthStorage;
