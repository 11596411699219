import { policyType as PolicyType } from '@ourbranch/lookups';

import ProgressiveLogo from 'core/assets/svg/progressive-logo.svg';
import BristolWestLogo from 'core/assets/svg/bristol-west-logo.svg';
import DairylandLogo from 'core/assets/svg/dairyland-logo.svg';
import SafecoLogo from 'core/assets/svg/safeco-logo.svg';

export const buildQuinstreetQuoteFromPolicy = ({ policy, fname, lname, list }) => {
  return {
    id: policy.id,
    webUserId: policy.webUserId,
    quote: {
      fname,
      lname,
      cars: policy.policyDetails.cars,
      drivers: policy.policyDetails.drivers || policy.policyDetails.people,
      monthsAtCurrentAddress: 50, // placeholder for now
      home: policy.offer.quote.home,
      autoCoverage: policy.policyDetails.autoCoverage,
      homeCoverage: policy.policyDetails.homeCoverage,
      correctedAddress: policy.offer.quote.correctedAddress,
      priorAddress: policy.offer.quote.priorAddress,
      multiPolicyDiscount: policy.policyDetails.multiPolicyDiscount,
      scheduledPersonalProperty: policy.policyDetails.scheduledPersonalProperty,
      global: {
        currentlyInsured: true,
        currentAutoCarrier: 'Branch Insurance',
        continuousAutoCoverageStartDate: list.find((policy) => policy.id.includes('auto-001'))?.effectiveDate,
        priorIndividualBILimit: policy.policyDetails.autoCoverage
          ? parseInt(policy.policyDetails.autoCoverage.policyLimitBIPD.split('/')[0], 10) * 1000
          : null,
        priorOccurrenceBILimit: policy.policyDetails.autoCoverage
          ? parseInt(policy.policyDetails.autoCoverage.policyLimitBIPD.split('/')[1], 10) * 1000
          : null,
        homeEffectiveDate: policy.effectiveDate
      }
    }
  };
};

export const getCarrierData = (policyType) => {
  let carrierData = {
    safeco: {
      logo: SafecoLogo,
      url: 'https://www.safeco.com/'
    }
  };

  const progressive = {
    logo: ProgressiveLogo,
    url: 'https://www.foragentsonly.com/login/'
  };

  if (policyType === PolicyType.Auto) {
    const dairyland = {
      logo: DairylandLogo,
      url: 'https://agent.dairylandagent.com/'
    };

    carrierData = {
      ...carrierData,
      progressive,
      clearcover: {
        logo: ProgressiveLogo,
        url: 'https://www.foragentsonly.com/login/'
      },
      'bristol west': {
        logo: BristolWestLogo,
        url: 'https://www.iaproducers.com'
      },
      dairyland,
      'dairyland insurance': dairyland
    };
  } else if (policyType === PolicyType.Home) {
    carrierData = {
      ...carrierData,
      'asi/progressive': progressive
    };
  }

  return carrierData;
};
