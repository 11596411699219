import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { policyTypes as pTypes } from '@ourbranch/policy-types';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { Loading } from 'core/components/loading';
import Section from 'core/components/section';
import { CollapsibleTable } from 'core/components/sortable-table';
import { dateFormatter, getTotalPrice, numberFormatter } from 'core/helpers/formatters';
import { get, policyTypes } from 'core/helpers/lookups';
import { useStore } from 'core/store';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { checkAdditionalCoverages } from 'core/helpers/quoter.service';
import useStyles from './policies.styles';

const getPolicyType = get(policyTypes);

const Policies = observer(function Policies() {
  const classes = useStyles();
  const history = useHistory();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [inactiveOpen, setInactiveOpen] = useState(false);
  const { account } = useStore();
  const {
    id,
    policies: { list, loading: policiesLoading, getUnsignedBixConversion }
  } = account;
  const loading = policiesLoading || !list.length;

  const rowColor = ({ status, mode, id, policyType, policyDetails, offer }) => {
    if (status === PolicyStatus.InCancellation) {
      return 'red';
    }

    const bixConversionDocsInfo = getUnsignedBixConversion(id);
    const { canAddEarthquake, canAddFlood } = checkAdditionalCoverages(offer, policyType);

    if (
      !bixConversionDocsInfo?.signedDocUploadedInS3 ||
      ((policyType === pTypes.HABundle || policyType === pTypes.Home) &&
        ((policyDetails?.homeCoverage?.interestedInSeparateWindHail &&
          !policyDetails?.homeCoverage?.externalPolicyId) ||
          (canAddEarthquake && policyDetails?.includeEarthquake && !policyDetails?.earthquakeCoverage?.policyId) ||
          (canAddFlood && policyDetails?.includeFlood && !policyDetails?.floodCoverage?.policyId)))
    ) {
      return 'orange';
    }

    return mode;
  };

  const data = list.map((p) => {
    const status = getPolicyStatus(p);
    return {
      ...p,
      producer: p.salesRep || p?.offer?.quote?.rep || 'N/A',
      totalPrice: getTotalPrice(p.premium, p.fees, p.surplusContribution || 0),
      status,
      rowMode: rowColor({
        status,
        mode: ['Inactive', 'Cancelled'].includes(status) ? 'light' : 'dark',
        id: p.id,
        policyType: p.policyType,
        policyDetails: p.policyDetails,
        offer: p.offer
      })
    };
  });

  const inactivePolicies = [];
  const activePolicies = [];
  const cancelledPolicies = [];

  data.forEach((row) => {
    if (row.status === 'Inactive') {
      inactivePolicies.push(row);
    } else if (row.status === 'Cancelled') {
      cancelledPolicies.push(row);
    } else {
      activePolicies.push(row);
    }
  });

  const sections = [
    {
      title: 'Active',
      rows: activePolicies,
      individual: true
    },
    ...(inactivePolicies.length
      ? [
          {
            title: 'Inactive Policies',
            rows: inactivePolicies,
            mode: 'light',
            expanded: inactiveOpen,
            setExpanded: setInactiveOpen
          }
        ]
      : []),
    ...(cancelledPolicies.length
      ? [
          {
            title: 'Cancelled Policies',
            rows: cancelledPolicies,
            mode: 'light',
            expanded: cancelOpen,
            setExpanded: setCancelOpen
          }
        ]
      : [])
  ];

  return (
    <Section title="Policies" rightLabel="Total" rightValue={`${loading ? '...' : list.length} policies`}>
      {loading ? (
        <Loading type="secondary" />
      ) : (
        <Card className={classes.tableContainer} type="secondary">
          <CollapsibleTable
            mode="dark"
            dataCy="policies"
            selfContained={false}
            headers={[
              ['policyType', 'Policy'],
              ['effectiveDate', 'Effective Dates'],
              ['totalPrice', 'Total Price'],
              ['producer', 'Producer'],
              ['status', 'Status']
            ]}
            data={sections}
            onClick={(policy) => history.push(`/customer/${id}/policy/${policy.id}`)}
            formatters={{
              policyType: (p, row) => (
                <>
                  {getPolicyType(p)}
                  <Label type="infoLabel" className={classes.policyLabel}>
                    {row.id}
                  </Label>
                </>
              ),
              status: (status) => (
                <Label className={classes.label} style={{ width: '68px' }}>
                  {status}
                </Label>
              ),
              effectiveDate: (effectiveDate, row) => `${dateFormatter(effectiveDate)} - ${dateFormatter(row.endDate)}`,

              coverage: (c) => (String(c).includes('/') ? c : numberFormatter(c)),
              totalPrice: (p) => `$${p.toFixed(2)}`
            }}
          />
        </Card>
      )}
    </Section>
  );
});

export default Policies;
