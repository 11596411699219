import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import { policyType, policyType as PolicyType } from '@ourbranch/lookups';

export const getInitialValues = (prefillData, user, requestedPolicyType) => {
  let initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    driversLicenseNumber: '',
    driversLicenseState: '',
    state: '',
    zip: '',
    city: '',
    unit: '',
    noUnitNumber: false,
    dateOfBirth: '',
    email: undefined,
    phone: undefined,
    leadSource: undefined,
    affinity: user?.affinityCode,
    rep: user?.username,
    VIN: '',
    priorAddress: {
      address: '',
      address2: '',
      state: '',
      zip: '',
      city: ''
    },
    home: {
      sqFt: undefined,
      ownershipStatus: undefined,
      homeQuality: undefined,
      typeOfHome: undefined,
      constructionType: undefined,
      exteriorWallType: undefined,
      garageType: undefined,
      roofShape: undefined,
      roofDeck: undefined,
      roofCover: undefined,
      roofDeckAttachment: undefined,
      roofToWallConnection: undefined,
      openingProtection: undefined,
      roofSWR: undefined,
      yearBuilt: undefined,
      numFireplaces: undefined,
      numWoodBurningStoves: undefined,
      numOccupants: undefined,
      numMortgages: undefined,
      numFullBathrooms: undefined,
      numHalfBathrooms: undefined,
      numStories: undefined,
      garageCarCapacity: undefined,
      numFamiliesInDwelling: undefined,
      roofYear: undefined,
      roofType: undefined,
      roofOriginal: undefined,
      purchaseDate: undefined,
      basementSqFt: undefined,
      basementType: undefined,
      latitude: undefined,
      longitude: undefined,
      fireHydrantWithin1000ft: undefined,
      fpc: undefined,
      fpcFromLocation: undefined,
      wildfireHazardScore: undefined,
      dtoName: undefined,
      dtbowName: undefined,
      dtbowLabel: undefined,
      stormShutters: undefined
    },
    condo: {
      yearBuilt: undefined,
      purchaseDate: undefined,
      monthsRentedOut: undefined,
      floor: undefined,
      typeOfEntry: 'I',
      hasBasement: false,
      buildingHasMoreThanFiveUnits: false,
      condoQuality: undefined,
      constructionType: undefined,
      numHalfBathrooms: undefined,
      numFullBathrooms: undefined,
      roofShape: undefined,
      sqFt: undefined,
      latitude: undefined,
      longitude: undefined
    },
    insuranceInPast31Days: false,
    priorCoverageInYears: '',
    policyLimitBIPD: '',
    fcraDisclaimer: false,
    isNewConstruction: false,
    isNewPurchase: false,
    livedAtThisAddressFor1Year: undefined,
    connectedHome: undefined,
    global: undefined,
    signUpForNewMonitoringService: false,
    policyType: policyType.HABundle,
    isApartment: false,
    overrides: {}
  };

  if (prefillData && Object.keys(prefillData).length > 0) {
    const {
      fname,
      lname,
      firstName,
      lastName,
      correctedAddress: { address, city, state, zip } = {},
      priorAddress,
      email,
      phone,
      home,
      condo,
      drivers,
      leadSource,
      affinity,
      global,
      policyType
    } = prefillData;
    initialValues = {
      ...initialValues,
      firstName: fname || firstName,
      lastName: lname || lastName,
      rep: prefillData.salesRep,
      dateOfBirth: drivers?.length ? drivers[0]?.dateOfBirth : '',
      driversLicenseNumber: drivers?.length ? drivers[0]?.driversLicenseNumber : '',
      driversLicenseState: drivers?.length ? drivers[0]?.driversLicenseState : '',
      policyLimitBIPD: prefillData.autoCoverage?.policyLimitBIPD,
      priorCoverageInYears: prefillData.global?.continuousAutoCoverageStartDate
        ? Math.min(
            differenceInCalendarYears(new Date(), Date.parse(prefillData.global?.continuousAutoCoverageStartDate)),
            6
          )
        : 6,
      insuranceInPast31Days: !!prefillData.global?.continuousAutoCoverageStartDate,
      address,
      state,
      zip,
      city,
      priorAddress,
      home,
      phone,
      email,
      leadSource,
      affinity: affinity || global?.affinity,
      policyType: requestedPolicyType || prefillData?.selectedOption || policyType || PolicyType.HABundle,
      noUnitNumber: prefillData?.selectedOption?.includes(PolicyType.Condo) && !prefillData?.hasUnitNumber,
      condo,
      livedAtThisAddressFor1Year:
        Object.keys(prefillData?.priorAddress || {}).length > 0 && prefillData?.priorAddress?.address ? 'false' : 'true'
    };
  }

  return initialValues;
};
