import { useObserver } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Section from 'core/components/section';
import BillingDetails from '../billing-details';

function PaymentDetails() {
  return useObserver(() => (
    <Section title="Payment Details" type="SubSection">
      <BillingDetails />
    </Section>
  ));
}

PaymentDetails.propTypes = {
  billingHoldUntil: PropTypes.string
};

PaymentDetails.defaultProps = {
  billingHoldUntil: null
};

export default PaymentDetails;
