import { getOptions } from '@ourbranch/lookups';

export const getHighestEducationAppCoApp = (people) => {
  const options = getOptions('highestEducation');
  const highest = people
    .filter((p) => p.isPrimary || p.isCoApplicant)
    .reduce((prev, item, i) => {
      const index = options.findIndex((option) => item.highestEducation === option.id);
      return index > i ? i : prev;
    }, 0);
  return people[highest] ? people[highest].highestEducation : '';
};

export const getOldestAppCoApp = (people) => {
  return people
    .filter((p) => p.isPrimary || p.isCoApplicant)
    .reduce((currentOldestAge, person) => Math.max(currentOldestAge, person.age), 0);
};

export const formatValues = (values) => {
  if (!values) {
    return null;
  }
  return {
    ...values,
    oldestResident: undefined,
    highestEducation: undefined
  };
};
