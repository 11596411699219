import React from 'react';
import { TableRow, TableCell, Paper } from '@material-ui/core';

import { TableHeaderCell } from 'core';
import { Table } from 'core/components/table';
import { NotificationCard } from 'core/components/notification-card';
import DownloadButton from './download-button';

import useStyles from '../generate-task-ui.styles';

const headerRows = ['description', 'download'];

const FilePathOutput = ({ filePaths }) => {
  const classes = useStyles();

  return (
    <>
      <NotificationCard>
        The output was too large to display - please download the files to see the results:
      </NotificationCard>

      <Paper className="output-file-path">
        <Table
          className={classes.table}
          title="Output"
          header={
            <>
              {headerRows.map((key) => (
                <TableHeaderCell key={key} columnId={key}>
                  {key}
                </TableHeaderCell>
              ))}
            </>
          }
          body={filePaths?.map((filePath) => (
            <TableRow key={`row-${filePath.output}`}>
              <>
                <TableCell key={`cell-${filePath.output}-desc`}>{filePath.output}</TableCell>
                <TableCell key={`cell-${filePath.output}-download`}>
                  <DownloadButton path={filePath.path} />
                </TableCell>
              </>
            </TableRow>
          ))}
        />
      </Paper>
    </>
  );
};

export default FilePathOutput;
