import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    borderTop: `solid 1px ${theme.colorPalette.beige_10}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 8),
    position: 'relative'
  },
  completed: {
    backgroundColor: theme.colorPalette.white_30
  },
  task: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  taskInfo: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  taskName: {
    color: theme.colorPalette.green_15,
    lineHeight: 1.5
  },
  completedAt: {
    fontSize: 10,
    color: theme.colorPalette.green_15
  },
  completedTask: {
    color: theme.colorPalette.beige_75
  },
  tags: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  autoTag: {
    backgroundColor: theme.colorPalette.green_10_op_40
  },
  upload: {
    fontSize: 12,
    fontWeight: 600
  },
  uploadIcon: {
    marginLeft: theme.spacing(2)
  },
  completedIcon: {
    height: 24
  },
  progressBar: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0
  }
});

export default makeStyles(styles);
