import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';

import { useToast } from 'core/components/toast';
import copyIcon from 'core/assets/svg/copy.svg';
import backIcon from './go-to.svg';
import { Label } from '../label';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import useStyles from './screen-header.styles';

function ScreenHeader({ title, subtitles, children, onBack, rightValue, rightLink, options, className }) {
  const classes = useStyles();
  const hasElementsOnRight = rightValue || children;
  const toast = useToast();
  return (
    <Grid container className={classNames(classes.header, className)} justifyContent="space-between">
      <Grid item className={classes.container}>
        {!options.hideBackButton && (
          <IconButton classes={MapClassesToElem(ElemType.IconButton, classes)} onClick={onBack}>
            <img src={backIcon} className={classes.icon} alt="back" />
          </IconButton>
        )}
        {subtitles ? (
          <Grid container direction="column">
            <Label type="title">{title}</Label>
            {subtitles}
          </Grid>
        ) : (
          <Grid container direction="row">
            <Label type="title">{title}</Label>
            {options.copyableTitle && !title?.toLowerCase().includes('loading') && (
              <IconButton
                onClick={() => {
                  const copied = copy(title);
                  if (copied) {
                    toast.notify({
                      type: 'success',
                      message: `${title} copied to clipboard`
                    });
                  }
                }}
              >
                <img src={copyIcon} alt="copy" />
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
      {hasElementsOnRight && (
        <Grid item className={classes.rightContainer}>
          {rightValue && (
            <Label type="infoValue" className={classes.rightValue}>
              {rightLink ? (
                <a className={classes.link} href={rightLink} target="_blank" rel="noopener noreferrer">
                  {rightValue}
                </a>
              ) : (
                rightValue
              )}
            </Label>
          )}
          {children}
        </Grid>
      )}
    </Grid>
  );
}

ScreenHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subtitles: PropTypes.node,
  onBack: PropTypes.func,
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  rightLink: PropTypes.string,
  options: PropTypes.object,
  className: PropTypes.string
};

ScreenHeader.defaultProps = {
  children: null,
  rightValue: null,
  rightLink: undefined,
  subtitles: undefined,
  className: '',
  options: { hideBackButton: false, copyableTitle: false },
  onBack: null
};

export default ScreenHeader;
