import React from 'react';

const SORTING_ORDER = {
  HIGH: 1,
  MED: 2,
  NONE: 3,
  LOW: 4
};

export const derivePriceIncreaseReasons = (currentPolicy, previousPolicy, renewalPriceComparisonDetails) => {
  const increases = [];

  if (renewalPriceComparisonDetails?.increaseStateRateChange) {
    increases.push({
      title: 'State rate change',
      description: `Due to rising costs nationwide, which include the cost of insurance claims, Branch has increased rates in ${currentPolicy?.state} to ensure we continue to meet our obligations to all of our members.`,
      impact: 'HIGH',
      learnMoreLink: 'https://staff.ourbranch.com/portal/updates'
    });
  }

  if (renewalPriceComparisonDetails?.increaseMultiplePolicyDiscountRemoved) {
    increases.push({
      title: 'Multiple Policy discount',
      description: `The multiple policy discount on this policy has been impacted by a change to one of the other bundled policies on the account.`,
      impact: renewalPriceComparisonDetails?.multiplePolicyDiscountImpact || 'MED'
    });
  }

  if (renewalPriceComparisonDetails?.increaseConnectedHomeDiscountRemoved) {
    increases.push({
      title: 'Connected Home discount',
      description: `The Connected Home discount requires a professionally monitored moisture, theft, smoke, or motion detecting system. A system must be installed and monitored in the home in order for the policy to be eligible for this discount.`,
      impact: 'MED'
    });
  }

  const currentCoverageA = currentPolicy?.segments[0]?.homeCoverage?.coverageA;
  const previousCoverageA = previousPolicy?.segments[0]?.homeCoverage?.coverageA;
  if (renewalPriceComparisonDetails?.increaseDwellingAmountIncreased) {
    increases.push({
      title: 'Dwelling amount increase due to increased RCE',
      description: `With increases in the costs of repairs and materials, the total cost to replace the insured home has increased. We’ve increased the dwelling amount from $${previousCoverageA} to $${currentCoverageA} to reflect increased replacement costs to ensure adequate coverage in the event of catastrophe.`,
      impact: 'NONE'
    });
  }

  if (renewalPriceComparisonDetails?.increaseNumberOfClaims) {
    increases.push({
      title: 'Number of claims/incidents',
      description: `Due to new claims made under this policy’s coverage, the premium for this policy term has increased.`,
      impact: 'MED'
    });
  }

  if (renewalPriceComparisonDetails?.increaseSurplusContributionIncrease) {
    increases.push({
      title: 'Policy surplus contribution increase',
      description: `Each subscriber makes surplus contributions of up to 15% of total policy premiums. This amount goes toward the surplus needed to pay member claims, and is billed and collected alongside other premium.`,
      impact: 'NONE'
    });
  }

  if (renewalPriceComparisonDetails?.increasePriorMidTermChanges) {
    increases.push({
      title: 'Prior term mid-term changes',
      description: `There was a change made to the prior term impacting the renewal term’s price. Check the prior term’s version history for more information.`,
      impact: 'NONE'
    });
  }

  const priorFees = previousPolicy?.fees?.reduce((acc, fee) => acc + fee.amount, 0);
  const currentFees = currentPolicy?.fees?.reduce((acc, fee) => acc + fee.amount, 0);
  if (renewalPriceComparisonDetails?.increaseFees) {
    increases.push({
      title: 'Fees',
      description: (
        <>
          Additional fees or an increased fee amount has increased the total cost of fees from ${priorFees} to $
          {currentFees}. See the <strong>Payment and Price</strong> tab for more details.
        </>
      ),
      impact: 'NONE'
    });
  }

  if (renewalPriceComparisonDetails?.increaseRoofAge) {
    // this is always displayed for Home policies
    increases.push({
      title: 'Roof age',
      description: `The age of the roof on the insured home has a variety of effects on premiums. Given the potential impact on the premium, members should always update Branch with any changes to their roof condition. Follow the appropriate escalation process for ratings-specific questions.`,
      impact: 'NONE'
    });
  }

  // we have to have participation in both terms to compare their scores
  if (renewalPriceComparisonDetails?.increaseCommunityDriveScoreDecreased) {
    increases.push({
      title: 'Community Drive score decreased',
      description: `The Community Drive discount decreased based on the member’s Community Drive average score of ${renewalPriceComparisonDetails?.currentUBIAverage} with ${renewalPriceComparisonDetails?.currentCommunityDrivers} currently participating. This is lower than the average driving score from the last term. Consult our app on how to further improve this score for a total discount of up to 20% off the total premium at renewal when all drivers on the policy participate.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseCommunityDriveRemoved) {
    increases.push({
      title: 'Community Drive Participation',
      description:
        'The Community Drive discount was removed either from drivers opting out or from inactivity from drivers on the policy.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseInventoryDiscountRemoved) {
    increases.push({
      title: 'Inventory discount',
      description: 'The inventory discount acts as an inspection, and only impacts the first policy term with Branch.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseGoodPayerDiscountRemoved) {
    increases.push({
      title: 'Good payer discount',
      description:
        'Due to late payments on the prior policy term, the good payer discount no longer applies to this policy.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseNewHomeDiscountRemoved) {
    increases.push({
      title: 'New home discount',
      description:
        'The new home discount impacts the first three policy terms for new construction homes. The home on this policy is no longer eligible.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseSeniorCitizenDiscountRemoved) {
    increases.push({
      title: 'Senior citizen discount',
      description:
        'This discount is only available for senior drivers who have clean driving records and have taken a defensive driving course in the past three years. This policy is no longer eligible for the senior citizen discount.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseStudentAwayDiscountRemoved) {
    increases.push({
      title: 'Student away discount',
      description:
        'This discount only applies for policies with students located more than 100 miles from the vehicle garaging address. This policy is no longer eligible for the student away discount.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseGoodStudentDiscountRemoved) {
    increases.push({
      title: 'Good student discount',
      description:
        'One or more drivers on this policy are no longer eligible for the good student discount. This discount may have been removed if acceptable proof was not received, or if a driver’s age has exceeded 22 years old.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseFiveYearClaimFreeDiscountRemoved) {
    increases.push({
      title: 'Five year claim-free discount',
      description: 'The five year claim-free discount no longer applies to this policy due to recent claims.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseSafeDrivingDiscountRemoved) {
    increases.push({
      title: 'Safe driving discount',
      description:
        'The safe driving discount no longer applies to this policy. This may be due to a recent violation or claim.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increasePaperlessDiscountRemoved) {
    increases.push({
      title: 'Paperless discount',
      description:
        'The Paperless discount no longer applies to this policy, as the member has elected to receive paper documents on the prior term.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseCancellationOrReinstatementOnPriorTerm) {
    increases.push({
      title: 'Cancellation/reinstatement on prior term',
      description: `Cancellations due to non-payment on the prior policy term are causing an increase to the renewal policy's premium.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseHomeAge) {
    increases.push({
      title: 'Home age',
      description:
        'The age of the insured home has a variety of effects on premiums. Follow the appropriate escalation process for ratings-specific questions.',
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increasePaymentMethodChanged) {
    increases.push({
      title: 'Payment method',
      description: `This policy's payment frequency or method has changed, leading to an increase in premium.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.increaseCommunityPledgeDiscountRemoved) {
    increases.push({
      title: 'Community pledge discount',
      description: `One or more people that vouched for the member are no longer with Branch.`,
      impact: 'LOW'
    });
  }

  return increases.sort((a, b) => {
    return SORTING_ORDER[a.impact] - SORTING_ORDER[b.impact];
  });
};

export const deriveCurrentSavings = (renewalPriceComparisonDetails) => {
  const savings = [];

  if (renewalPriceComparisonDetails?.currentSavingsMultiplePolicyDiscount) {
    savings.push({
      title: 'Multiple policy discount',
      description: `The member has more than one Branch policy.`,
      impact: 'MED'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsConnectedHomeDiscount) {
    savings.push({
      title: 'Connected Home discount',
      description: `The member is protecting their home with professional home monitoring.`,
      impact: 'MED'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsCommunityDriveDiscount) {
    savings.push({
      title: 'Community Drive discount',
      description: `The member’s Community Drive discount is based on their average Community Drive score of ${renewalPriceComparisonDetails?.currentUBIAverage} with ${renewalPriceComparisonDetails?.currentCommunityDrivers} currently participating. Consult our app on how to further improve this score for a total potential discount of up to 20% off the total premium at renewal when all drivers on the policy participate.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsGoodPayerDiscount) {
    savings.push({
      title: 'Good payer discount',
      description: `The member has paid on time with no failed payments.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsNewHomeDiscount) {
    savings.push({
      title: 'New home discount',
      description: `The member’s home is less than three years old.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsSeniorCitizenDiscount) {
    savings.push({
      title: 'Senior citizen discount',
      description: `Senior citizen(s) on the policy have a clean driving record and taken a defensive driving course in the past three years.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsStudentAwayDiscount) {
    savings.push({
      title: 'Student away discount',
      description: `One or more drivers on the policy are a student located more than 100 miles from the vehicle garaging address.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsGoodStudentDiscount) {
    savings.push({
      title: 'Good student discount',
      description: `One or more drivers on the policy have provided proof of good student and are 22 years old or younger.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsFiveYearClaimFreeDiscount) {
    savings.push({
      title: 'Five year claim-free discount',
      description: `The member has gone five or more years claim-free.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsSafeDrivingDiscount) {
    savings.push({
      title: 'Safe driving discount',
      description: `The member doesn’t have any driving violations or claims on their record.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsPaperlessDiscount) {
    savings.push({
      title: 'Paperless discount',
      description: `The member has elected to not receive paper documents.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.currentSavingsCommunityPledgeDiscount) {
    savings.push({
      title: 'Community pledge discount',
      description: `Currently vouched for by ${renewalPriceComparisonDetails?.currentNumberOfVouches} members.`,
      impact: 'LOW'
    });
  }

  return savings.sort((a, b) => {
    return SORTING_ORDER[a.impact] - SORTING_ORDER[b.impact];
  });
};

export const deriveSavingsOpportunties = (policyType, renewalPriceComparisonDetails) => {
  const opportunities = [];

  if (renewalPriceComparisonDetails?.savingsOpportunitiesBundleAuto) {
    opportunities.push({
      title: 'Bundle the auto policy',
      description: `Depending on the state and eligibility, adding a residence policy can save up to 30% on the total premium price of the bundle applicable at renewal.`,
      impact: 'HIGH',
      bundleLink: true
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesBundleHome) {
    opportunities.push({
      title: 'Bundle the home policy',
      description: `Depending on the state and eligibility, adding an auto policy can save up to 25% on the total premium price of the bundle applicable at renewal.`,
      impact: 'HIGH',
      bundleLink: true
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesConnectedHome) {
    opportunities.push({
      title: 'Add a professionally monitored security system',
      description: `Get up to 18% off the home policy from having a professionally monitored security system through our Connected Home program.`,
      impact: 'MED'
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportuntiesCoverageCounseling) {
    opportunities.push({
      title: 'Coverage counseling',
      description: `Reviewing and updating the policy’s deductibles and coverages to meet the member's current needs may result in a decrease in price.`,
      impact: 'LOW'
    });
  }

  if (renewalPriceComparisonDetails?.savingsOpportunitiesCommunityDriveLogMoreTrips) {
    opportunities.push({
      title: 'Log more Community Drive trips',
      description: `${renewalPriceComparisonDetails?.currentCommunityDrivers} are part of the Community Drive program but haven’t logged enough trips to qualify for a Community Drive score. Start logging more trips on the Branch app to unlock a potential Community Drive discount of up to 20% off the total premium price at renewal when all drivers on the policy participate.`,
      impact: renewalPriceComparisonDetails?.logMoreTripsImpact || 'LOW'
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesAddDriversToCommunityDrive) {
    opportunities.push({
      title: 'Add drivers to Community Drive',
      description: `Save up to 20% at next renewal when all drivers on the policy participate in Branch's telematics program, Community Drive. Drivers can sign up through the Branch app and track their drives automatically. Currently enrolled drivers: ${
        renewalPriceComparisonDetails?.currentCommunityDrivers || 'None'
      }. Eligible drivers: ${renewalPriceComparisonDetails?.eligibleCommunityDrivers}. `,
      // if there are community drivers on the policy already the impact is low
      impact: renewalPriceComparisonDetails?.currentCommunityDrivers ? 'LOW' : 'MED'
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesCommunityDriveImproveScore) {
    opportunities.push({
      title: 'Improve the Community Drive score',
      description: `Consult our app on how to further improve the driving score of ${renewalPriceComparisonDetails?.currentCommunityDrivers}. This can help increase the discount for a total potential discount of up to 20% off the total premium at renewal when all drivers on the policy participate.`,
      impact: 'LOW'
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesGoPaperless) {
    let detail = '';
    if (policyType === 'H') {
      detail = '3% on the home policy';
    }
    if (policyType === 'A') {
      detail = '4% on the auto policy';
    }
    opportunities.push({
      title: 'Go paperless',
      description: `Save ${detail} by opting into receiving ID cards and policy documents electronically, rather than paper copies to the member’s mail.`,
      impact: 'LOW'
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesSwitchToOneTimePayments) {
    opportunities.push({
      title: 'Switch to one-time payments',
      description: `The member can save by switching to one-time payment rather than installments.`,
      impact: 'LOW'
    });
  }
  if (renewalPriceComparisonDetails?.savingsOpportunitiesGetVouchedFor) {
    opportunities.push({
      title: 'Get vouched for by another brancher',
      description: `Consider referring friends and family to Branch so they can vouch that the member won't commit insurance fraud. Plus, the member can receive a gift card for every referred person that receives a price using the member's referral link.`,
      impact: 'LOW'
    });
  }

  return opportunities.sort((a, b) => {
    return SORTING_ORDER[a.impact] - SORTING_ORDER[b.impact];
  });
};
