import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { taskDocumentType, onboardingTasks } from '@ourbranch/lookups';
import { LinearProgress } from '@material-ui/core';

import { Label } from 'core/components/label';
import { Tag } from 'core/components/tag';
import { capitalize, dateTimeFormatter } from 'core/helpers/formatters';
import { IconButtonWithTooltip } from 'core/components/icon-button-with-tooltip';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store';
import useSession from 'core/hooks/use-session';
import useStorage from 'customer/hooks/use-storage';
import viewIcon from 'customer/assets/view.svg';
import undoIcon from 'customer/assets/undo.svg';
import uploadIcon from 'customer/assets/upload.svg';
import completedIcon from 'customer/assets/completed.svg';
import ResetModal from '../reset-task/reset-task-modal';
import useStyles from './task.styles';

const getDocName = (taskName, policyData) => {
  const { policyType, policyId } = policyData;
  const now = new Date();
  switch (taskName) {
    case onboardingTasks.SignApplication:
      return `${now.getFullYear()}-${
        now.getMonth() + 1
      }-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${policyType[0].toUpperCase()}_APPL`;
    case onboardingTasks.CompleteInventory:
      return `inventory_${policyId}`;
    case onboardingTasks.ConnectedHomeRenewal:
      return `connected_home_renewal_${policyId}`;
    default:
      return null;
  }
};

const Task = ({ taskId, taskName, data, completed, completedDate, policyId, readOnly, completedBy, rejections }) => {
  const classes = useStyles();
  const {
    account: { id, completeTask, resetTask }
  } = useStore();
  const { canResetOnboardingTasks } = useSession();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const toast = useToast();
  const { customer, documentType, documentUrl } = data || {};
  const { upload, download } = useStorage({
    onUpload: async (path) => {
      setProgress(0);
      setUploading(false);
      const res = await completeTask({ taskId, accountId: id, documentUrl: path, policyId, taskName, data });
      if (res.ok) {
        toast.notify({
          type: 'success',
          message: `${taskName} ${customer ? `for ${customer} ` : ''}has been marked complete and uploaded to ${
            documentType ? documentType.toLowerCase() : ''
          } documents.`
        });
      } else {
        toast.notify({
          type: 'error',
          message: `There was an error completing the task - ${res.error}`
        });
      }
    },
    onProgress: (progress) => {
      setProgress((progress.loaded / progress.total) * 100);
    }
  });

  const [showResetModal, setResetModal] = useState(false);

  const fileRef = useRef(null);
  const [, type] = policyId.split('-');

  const handleUpload = useCallback(
    async ({ target: { files } }) => {
      setUploading(true);
      let fileName = getDocName(taskName, { policyType: type, policyId });
      let path = '';
      const [file] = files;
      const splitName = file.name.split('.');
      const ext = splitName[splitName.length - 1];
      if (documentType === taskDocumentType.Internal) {
        if (!fileName) {
          fileName = `${taskName}${customer ? ` - ${customer}` : ''}`;
        }
        path = `internal/${id}/${fileName}.${ext}`;
      } else if (fileName) {
        path = `docs/${id}/customer/${policyId}/${fileName}.${ext}`;
      }

      if (path) {
        await upload(path, file);
      }
    },
    [uploading, taskName]
  );
  const handleReset = async () => {
    const res = await resetTask({
      accountId: id,
      taskId,
      policyId,
      taskName,
      completedBy,
      rejections
    });
    if (res.ok) {
      toast.notify({
        type: 'success',
        message: `The "${taskName}" task has been reset. `
      });
    } else {
      toast.notify({
        type: 'error',
        message: `There was an error resetting the task - ${res.error}`
      });
    }
  };

  const showResetTaskIcon =
    canResetOnboardingTasks &&
    !(taskName === onboardingTasks.CompleteInventory) &&
    !(completedBy === 'member' && taskName === onboardingTasks.SignApplication);

  return (
    <div className={classNames(classes.container, { [classes.completed]: completed })}>
      <div className={classes.task}>
        {completed && <img alt="rounded checkmark" className={classes.completedIcon} src={completedIcon} />}
        <div>
          <div className={classes.taskInfo}>
            <Label type="infoValue" className={classNames(classes.taskName, { [classes.completedTask]: completed })}>
              {taskName}
            </Label>
            {completedDate && (
              <Label variant="darkGreenSmall" className={classes.completedAt}>
                Completed on {dateTimeFormatter(completedDate)} EST by {completedBy}
              </Label>
            )}
          </div>
          <div className={classes.tags}>
            <Tag className={classNames({ [classes.autoTag]: type === 'auto' })}>
              {capitalize(type)}
              {customer ? ` - ${customer}` : ''}
            </Tag>
          </div>
        </div>
      </div>
      {completed ? (
        <div className={classes.task}>
          {showResetTaskIcon && (
            <IconButtonWithTooltip
              onClick={() => setResetModal(true)}
              icon={undoIcon}
              tooltip="Reset task"
              alt="undo"
            />
          )}
          {documentUrl && (
            <IconButtonWithTooltip
              onClick={() => download(documentUrl)}
              icon={viewIcon}
              tooltip="View file"
              alt="eye"
            />
          )}
        </div>
      ) : (
        <>
          {!readOnly && (
            <>
              <input
                ref={fileRef}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleUpload}
                id={`onboarding-task-${taskName}`}
              />
              <Button
                disabled={uploading}
                onClick={() => {
                  fileRef.current.click();
                }}
                disableFocusRipple
                className={classes.upload}
                variant="text"
                color="secondary"
              >
                Upload
                <img alt="upload icon" className={classNames(classes.uploadIcon)} src={uploadIcon} />
              </Button>
            </>
          )}
        </>
      )}
      {uploading && (
        <LinearProgress className={classes.progressBar} color="secondary" variant="determinate" value={progress} />
      )}

      <ResetModal
        open={showResetModal}
        onClose={() => setResetModal(false)}
        taskName={taskName}
        onReset={handleReset}
        completedBy={completedBy}
        rejections={rejections}
      />
    </div>
  );
};

Task.propTypes = {
  taskId: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
  data: PropTypes.object,
  completed: PropTypes.bool.isRequired,
  completedDate: PropTypes.string,
  readOnly: PropTypes.bool,
  completedBy: PropTypes.string
};

Task.defaultProps = {
  data: {},
  completedDate: null,
  readOnly: false
};

export default observer(Task);
