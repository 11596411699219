import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { policyType } from '@ourbranch/lookups';

import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import { DisableProvider } from 'common/disabled-context';
import { Collapsible } from '../collapsible';
import { MoreInfoNeededFlagItem } from '../new-construction-flag-item';
import { propDetailsCodes } from '../quote-forms/quote-forms.constants';
import { ErrorAlert } from '../quote-errors';
import { Condo } from './condo';
import { Home } from './home';

const PropertyDetail = ({ showPropertyDetailsCallout, sectionsToShow }) => {
  const { values, isSubmitting } = useFormikContext();
  const {
    quote: { getAlerts }
  } = useStore();

  const propertyErrors = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);
  const hasCondo = [policyType.CABundle, policyType.Condo].includes(values.policyType);

  const alerts = getAlerts(propDetailsCodes, values.policyType);

  useEffect(() => {
    if (alerts.length && propertyErrors.current && isSubmitting) {
      propertyErrors.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [alerts, isSubmitting]);

  useEffect(() => {
    const shouldBeShown = propDetailsCodes.every((element) => sectionsToShow.includes(element));
    setShouldShow(shouldBeShown);
  }, [sectionsToShow]);

  return (
    <Form>
      {values.isNewConstruction && <MoreInfoNeededFlagItem />}
      {alerts.length > 0 && <ErrorAlert errors={alerts} ref={propertyErrors} />}
      {(values.isNewConstruction || shouldShow) && (
        <Collapsible
          title="Property Detail"
          forceOpen={showPropertyDetailsCallout || alerts.length > 0}
          initialOpen={alerts.length > 0}
        >
          {hasCondo ? (
            <Condo />
          ) : (
            <DisableProvider>
              <Home />
            </DisableProvider>
          )}
        </Collapsible>
      )}
    </Form>
  );
};

PropertyDetail.propTypes = {
  sectionsToShow: PropTypes.array,
  showPropertyDetailsCallout: PropTypes.bool.isRequired
};

PropertyDetail.defaultProps = {
  sectionsToShow: []
};

export default withDatePicker(PropertyDetail);
