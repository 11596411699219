import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  subLabel: {
    marginTop: theme.spacing(5)
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: theme.spacing(2),
    marginTop: theme.spacing(10)
  }
});

export default makeStyles(styles);
