import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  header: {
    backgroundColor: theme.colors.white,
    display: 'flex',
    gap: theme.spacing(4),
    padding: theme.spacing(8),
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
    gap: theme.spacing(8)
  },
  login: {
    alignSelf: 'flex-end',
    fontSize: 16,
    fontWeight: 600,
    width: 200
  },
  logo: {
    maxHeight: 45,
    maxWidth: 45
  }
});

export default makeStyles(styles);
