import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Label } from 'core/components/label';
import { capitalize } from 'core/helpers/formatters';
import RemoveButton from 'core/components/remove-button';

import useStyles from './branch-plus-multi-policy-discount.styles';

const BranchPlusPolicyItem = ({ onRemove, index, removeFromList, inOffer, item = {}, listLength }) => {
  const first = index === 0;
  const last = index === listLength - 1;
  const classes = useStyles({ inOffer, first, last });

  return (
    <Grid container className={classes.policyCard}>
      <Grid container justifyContent="space-between" className={classes.policyCardContainer}>
        <Grid item className={classes.policyCardContent}>
          <Label>{`${capitalize(item?.policyCarrier)} ${capitalize(item?.policyType)}`}</Label>
          <Label className={inOffer ? null : classes.font} type="infoLabel">
            Policy Number: {item?.policyNumber?.toUpperCase() || 'NOT PROVIDED'}
          </Label>
        </Grid>
        <RemoveButton className={classes.removeButton} onClick={() => onRemove(index, removeFromList)} />{' '}
      </Grid>
    </Grid>
  );
};

export default BranchPlusPolicyItem;
