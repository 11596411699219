import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import CollapsibleCard from 'core/components/collapsible-card';
import Section from 'core/components/section';
import withDatePicker from 'core/components/with-date-picker';
import FormList from 'core/components/form-list';
import { useCurrentState } from 'common/hooks/useCurrentState';
import { setPrimaryMortgageValues } from 'offer/helpers/mortgage-helpers';
import { AddMortgage, MortgageItem } from '../../mortgage-details';
import { DwellingAddress } from '../../dwelling-address';
import { Property } from './property';
import { Residents } from './residents';
import useStyles from './detail.styles';

const id = 'condo';

const Detail = ({ disabled }) => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const { condo } = values;
  const mortgageDetails = condo.mortgageDetails;

  const state = useCurrentState(values);

  useEffect(() => {
    if (mortgageDetails.length > 0) {
      if (!mortgageDetails.find((item) => item.primary)) {
        const mortgage = mortgageDetails[0];
        setFieldValue(`${id}.mortgageDetails.0`, { ...mortgage, primary: true });
        setPrimaryMortgageValues(setFieldValue, mortgage);
      }
    }
  }, [mortgageDetails, setFieldValue]);

  return (
    <Section title="Condo detail" type="SubSection">
      <CollapsibleCard
        initialOpen
        content={
          <DwellingAddress
            dwelling="Condo"
            fieldName="condo.condoLocation"
            location={condo.condoLocation}
            latitude={condo.latitude}
            longitude={condo.longitude}
            includeApt
          />
        }
      >
        <Grid container justifyContent="space-around" className={classes.container}>
          <Grid container spacing={2} justifyContent="space-around" alignItems="flex-start">
            <Residents state={state} />
            <Property />
          </Grid>
        </Grid>
        <Grid container className={classes.mortgages}>
          <FormList
            disabled={disabled}
            id="condo.mortgageDetails"
            item={MortgageItem}
            type="InnerSection"
            addForm={AddMortgage}
            title="Mortgage lender details"
            singular="Mortgage"
            plural="Mortgages"
          />
        </Grid>
      </CollapsibleCard>
    </Section>
  );
};

Detail.propTypes = {
  disabled: PropTypes.bool
};

Detail.defaultProps = {
  disabled: false
};

export default withDatePicker(Detail);
