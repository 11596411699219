import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _uniq from 'lodash-es/uniq';
import flat from 'flat';
import { format, parseISO } from 'date-fns';

import { SelectableRow } from 'core/components/table/selectable-row';
import { SelectableCell } from 'core/components/table/selectable-cell';
import { getPolicyStatus } from 'core/helpers/policy-status';
import statusCircleGrey from 'common/assets/status-circle-grey.svg';
import statusCircleRed from 'common/assets/status-circle-red.svg';
import statusCircleGreen from 'common/assets/status-circle-green.svg';
import statusCircleOrange from 'common/assets/status-circle-orange.svg';

import useStyles from './policies-results.styles';

const policyLink = (hit) => `/customer/${hit.accountId}/policy/${hit.id}`;

const ATTRIBUTE_FRIENDLY_NAME = {
  firstName: 'first name',
  lastName: 'last name',
  cars: 'Car VIN',
  dateOfBirth: 'DOB',
  accountId: 'Account ID',
  id: 'Policy ID',
  state: 'State',
  mortgageHolderName: 'Mortgage Lender',
  status: 'Status'
};

const Row = ({ columnsToUse, hit, onClick, ...props }) => {
  const classes = useStyles({ status: getPolicyStatus(hit) });

  const getPrimaryApp = useCallback((people) => {
    return people?.find((p) => p?.isPrimary);
  }, []);

  const getMatchedAttributes = useCallback((hit) => {
    /* Flatten the highlight result object from meilisearch so this:
          { policyDetails: { cars: [ { VIN: { value: 1234567, matchLevel: "full" }} ] } }
        turns into this:
          { 'policyDetails.cars.0.VIN.matchLevel': "full", 'policyDetails.cars.0.VIN.value': 1234567 }
    */
    if (hit._highlightResult === undefined && hit._formatted === undefined) return '';

    const matchedAttributes = [];
    let flattenedOnlyMatches = {};

    // Meilisearch Result
    if (hit._formatted) {
      const flattenedFullResults = flat(hit._formatted);

      flattenedOnlyMatches = Object.keys(flattenedFullResults).reduce((accum, key) => {
        const objValue = flattenedFullResults[key];
        if (typeof objValue === 'string' && objValue.indexOf('<em>') >= 0) {
          return {
            ...accum,
            [key]: flattenedFullResults[key]
          };
        }
        return accum;
      }, {});
    }

    Object.keys(flattenedOnlyMatches).forEach((fullAttributePath) => {
      Object.keys(ATTRIBUTE_FRIENDLY_NAME).forEach((attribute) => {
        if (fullAttributePath.includes(attribute)) {
          let friendlyAttributeName = ATTRIBUTE_FRIENDLY_NAME[attribute];
          if (attribute === 'firstName' || attribute === 'lastName') {
            friendlyAttributeName = fullAttributePath.includes('drivers')
              ? `Driver ${friendlyAttributeName}`
              : `Person ${friendlyAttributeName}`;
          }
          matchedAttributes.push(friendlyAttributeName);
        }
      });
    });

    return _uniq(matchedAttributes).join(', ');
  }, []);

  const getEffectiveDates = useCallback((hit) => {
    const effectiveDate = format(parseISO(hit?.effectiveDate), 'MM/dd/yyyy');
    const endDate = format(parseISO(hit?.endDate), 'MM/dd/yyyy');
    return `${effectiveDate} - ${endDate}`;
  }, []);

  const getStatus = useCallback((hit) => {
    // status needs to run on every row in FE and not come from Meilisearch because meilisearch record only updates
    // when indexer updates which may not be often enough to be current
    const status = getPolicyStatus(hit);
    const srcToUse = {
      Cancelled: statusCircleRed,
      'In Cancellation': statusCircleRed, // has to be a string literal because of the space in the name
      Inactive: statusCircleGrey,
      Future: statusCircleOrange,
      Active: statusCircleGreen
    };
    const statusText = status === 'Future' ? 'Future Effective' : status;
    return (
      <div className={classes.status}>
        <img src={srcToUse[status]} alt="status" />
        {statusText}
      </div>
    );
  }, []);

  const columnTypes = {
    id: (hit) => (
      <SelectableCell to={policyLink(hit)} key="id">
        {hit.id}
      </SelectableCell>
    ),
    policyType: (hit) => (
      <SelectableCell to={policyLink(hit)} key="policyType">
        {hit.policyType === 'A' ? 'Auto' : 'Home'}
      </SelectableCell>
    ),
    firstName: (hit) => (
      <SelectableCell to={policyLink(hit)} key="firstName">
        {getPrimaryApp(hit?.policyDetails?.drivers || hit?.policyDetails?.people)?.firstName}
      </SelectableCell>
    ),
    lastName: (hit) => (
      <SelectableCell to={policyLink(hit)} key="lastName">
        {getPrimaryApp(hit?.policyDetails?.drivers || hit?.policyDetails?.people)?.lastName}
      </SelectableCell>
    ),
    matchedOn: (hit) => (
      <SelectableCell to={policyLink(hit)} key="matchedOn">
        {getMatchedAttributes(hit)}
      </SelectableCell>
    ),
    effectiveDate: (hit) => (
      <SelectableCell to={policyLink(hit)} key="effectiveDate">
        {getEffectiveDates(hit)}
      </SelectableCell>
    ),
    state: (hit) => (
      <SelectableCell to={policyLink(hit)} key="state">
        {hit?.state}
      </SelectableCell>
    ),
    status: (hit) => (
      <SelectableCell to={policyLink(hit)} key="status">
        {getStatus(hit)}
      </SelectableCell>
    )
  };

  return (
    <SelectableRow id={hit.id} className={classes.tableRow} onClick={onClick} {...props}>
      {columnsToUse.map((name) => columnTypes[name](hit))}
    </SelectableRow>
  );
};

Row.propTypes = {
  columnsToUse: PropTypes.array.isRequired,
  hit: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

Row.defaultProps = {
  onClick: () => {}
};

export default Row;
