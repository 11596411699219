import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';

import useStyles from './selectable-cell.styles';

const SelectableCell = ({ to, children, ...props }) => {
  const classes = useStyles();
  return (
    <TableCell {...props} classes={{ root: classes.root }}>
      <Link to={to} disabled className={classes.link} {...props}>
        {children}{' '}
      </Link>
    </TableCell>
  );
};

SelectableCell.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default SelectableCell;
