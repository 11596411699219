import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Label } from 'core/components/label';

import styles from './button.styles';

const Button = ({ children, loading, classes, loadingMessage, ...props }) => {
  return (
    <MUIButton {...props}>
      {loading ? (
        <>
          <CircularProgress classes={{ colorPrimary: classes.circleLoader }} size={14} />
          {loadingMessage && <Label className={classes.spacing}>{loadingMessage}</Label>}
        </>
      ) : (
        children
      )}
    </MUIButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  loading: false,
  disabled: false
};

export default withStyles(styles)(Button);
