import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  copyButton: {
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: 3,
    '&:hover': {
      background: theme.colorPalette.orange_10_op_08,
      cursor: 'pointer'
    }
  }
}));

export default useStyles;
