import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import AddressAutocomplete from 'core/components/address-autocomplete';
import { Collapsible } from '../collapsible';
import { MoreInfoNeededFlagItem } from '../new-construction-flag-item';
import { priorAddressCodes } from '../quote-forms/quote-forms.constants';
import { ErrorAlert } from '../quote-errors';

import useStyles from './prior-address.styles';

const options = [
  {
    value: 'true',
    label: 'Yes'
  },
  {
    value: 'false',
    label: 'No'
  }
];

const PriorAddress = ({ showPriorAddressCallout, sectionsToShow }) => {
  const {
    quote: { getAlerts }
  } = useStore();

  const priorAddressErrors = useRef(null);
  const { values, isSubmitting, setFieldValue } = useFormikContext();
  const [shouldShow, setShouldShow] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);

  const alerts = getAlerts(priorAddressCodes, values.policyType);

  const { address, address2, city, state, zip, isNewConstruction, isNewPurchase } = values;

  const classes = useStyles({ isNewResidence: isNewConstruction || isNewPurchase });

  useEffect(() => {
    if (alerts.length && priorAddressErrors.current && isSubmitting) {
      priorAddressErrors.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [alerts, isSubmitting]);

  useEffect(() => {
    const shouldBeShown = priorAddressCodes.every((element) => sectionsToShow.includes(element));
    setShouldShow(shouldBeShown);
  }, [sectionsToShow]);

  return (
    <Form>
      {(values.isNewConstruction || values.isNewPurchase) && <MoreInfoNeededFlagItem />}
      {alerts.length > 0 && <ErrorAlert errors={alerts} ref={priorAddressErrors} />}
      {(values.isNewConstruction || shouldShow || values.isNewPurchase) && (
        <Collapsible
          title="Prior Address"
          forceOpen={showPriorAddressCallout || alerts.length > 0}
          initialOpen={alerts.length > 0}
        >
          {!isNewConstruction && !isNewPurchase && (
            <Grid className={classes.left} xs={12}>
              <Grid xs={12}>
                Has the applicant lived at {address ? `${address}, ${city}, ${state}` : 'the property address'} for more
                than 1 year?
              </Grid>
              <Field
                xs={12}
                id="livedAtThisAddressFor1Year"
                name="livedAtThisAddressFor1Year"
                type="radio"
                mode="light"
                defaultValue={''}
                options={options}
                className={classes.radioGroup}
                radioClassName={classes.radio}
                onClick={(e) => {
                  if (e.target.value === 'false') {
                    setShowAddressFields(true);
                    setFieldValue('priorAddress', {
                      address: '',
                      address2: '',
                      state: '',
                      zip: '',
                      city: ''
                    });
                  } else {
                    setShowAddressFields(false);
                    setFieldValue('priorAddress', {
                      address,
                      address2,
                      state,
                      zip,
                      city
                    });
                  }
                }}
              />
            </Grid>
          )}
          {(showAddressFields ||
            isNewConstruction ||
            isNewPurchase ||
            values.livedAtThisAddressFor1Year === 'false') && (
            <>
              <Grid className={classes.whereDidYouLive} xs={12}>
                What is the address the applicant lived at prior to{' '}
                {address ? `${address}, ${city}, ${state}` : 'the property address'}?
              </Grid>
              <Grid item xs={8}>
                <AddressAutocomplete id="priorAddress.address" name="priorAddress" mode="light" label="Address*" />
              </Grid>
              <Grid item xs={4}>
                <Field
                  type="string"
                  id="priorAddress.unit"
                  name="priorAddress.unit"
                  mode="light"
                  label="Floor, unit, building, etc."
                />
              </Grid>
              <Grid item xs={4}>
                <Field type="string" id="priorAddress.city" name="priorAddress.city" mode="light" label="City*" />
              </Grid>
              <Grid item xs={4}>
                <Field type="state" id="priorAddress.state" name="priorAddress.state" mode="light" label="State*" />
              </Grid>
              <Grid item xs={4}>
                <Field type="string" id="priorAddress.zip" name="priorAddress.zip" mode="light" label="Zip Code*" />
              </Grid>
            </>
          )}
        </Collapsible>
      )}
    </Form>
  );
};

PriorAddress.propTypes = {
  showPriorAddressCallout: PropTypes.bool.isRequired
};

export default withDatePicker(PriorAddress);
