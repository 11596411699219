import React from 'react';

import FormList from 'core/components/form-list';
import useSession from 'core/hooks/use-session';
import AddBranchPlusPolicyItem from './add-branch-plus-policy-item';
import BranchPlusPolicyItem from './branch-plus-policy-item';

const BranchPlusMultiPolicyDiscountForm = ({ inOffer }) => {
  const session = useSession();
  return (
    <FormList
      id="branchPlusPolicies"
      item={BranchPlusPolicyItem}
      type="InnerSection"
      addForm={AddBranchPlusPolicyItem}
      title="Branch Plus Policies"
      singular="policies"
      plural="policies"
      addLabel="Branch Plus Policies"
      inOffer={inOffer}
      username={session.user.username}
    />
  );
};

export default BranchPlusMultiPolicyDiscountForm;
