import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { useStore } from 'core/store/store.mobx';
import useStyles from '../footer.styles';

const FillSkippedDataPulls = observer(({ onUpdate }) => {
  const { offer: store } = useStore();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.titleContainer}>
        <Label className={classes.title} type="subtitle">
          You are being shown an estimated price.
        </Label>
        <Label className={classes.subtitle} type="subtitle">
          In order to get you an accurate price, we will need to pull from more data sources.
        </Label>
      </div>
      <Button
        onClick={() => {
          store.fillOutSkippedDataPulls(history);
        }}
        disabled={store.loading}
        loading={store.loading}
        variant="contained"
        color="primary"
        className={classes.btn}
      >
        <Label className={classes.btnLabel}>Get an accurate price</Label>
      </Button>
    </>
  );
});

export default FillSkippedDataPulls;
