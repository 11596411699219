import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.colors.header__label_primary,
    marginBottom: theme.spacing(4)
  }
}));

export default useStyles;
