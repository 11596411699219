import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import { find as _find } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import copy from 'copy-to-clipboard';

import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import ScreenHeader from 'core/components/screen-header';
import { withStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { EditReferral } from './components/edit-referral';
import LinkedCustomer from './components/linked-customer';
import PotentialMatches from './components/potential-matches';
import PotentialCustomerMatches from './components/potential-customer-matches';
import useStyles from './header.styles';

const getCoappName = (drivers) => {
  if (drivers && drivers.length) {
    const coApp = _find(drivers, { isCoApplicant: true });
    if (coApp) {
      return `${coApp.firstName} ${coApp.lastName}`;
    }
  }
  return undefined;
};

const getFullAddressString = (correctedAddress) => {
  return `${correctedAddress.address} ${correctedAddress.address2 || ''} ${correctedAddress.city} ${
    correctedAddress.state
  } ${correctedAddress.zip}`;
};

const OfferHeader = ({
  offerId,
  history,
  options,
  onBack,
  store: {
    offer: { loading, offer },
    matchSearch
  }
}) => {
  const classes = useStyles();
  const location = useLocation();
  const toast = useToast();
  const fromCheckout = location.pathname.includes('checkout');
  // prepend the space so that we dont have an unneeded space in the query if state is undefined
  const state = ` ${offer?.state || offer?.quote?.correctedAddress?.state || offer?.quote?.address?.state || ''}`;

  useEffect(() => {
    if (offer?.quote?.existingCustomer?.id) {
      matchSearch.setCurrentResultsPage(0);
      matchSearch.setSearchString(offer.quote.existingCustomer.id);
      matchSearch.searchCustomersAction();
      matchSearch.searchOffersAction();
    } else if (
      offer?.quote?.fname &&
      offer?.quote?.lname &&
      !matchSearch.loading &&
      !matchSearch.customersResults?.hits &&
      !matchSearch.offersResults?.hits
    ) {
      matchSearch.setSearchString(`${offer.quote.fname} ${offer.quote.lname}${state}`);
      matchSearch.searchCustomersAction();
      matchSearch.searchOffersAction();
    }
  }, [matchSearch, offer]);

  let titleString;
  if ((loading && !offer) || !offer?.quote?.fname || !offer?.quote?.lname) {
    titleString = 'Loading...';
  } else if (options.showAddress) {
    titleString = `${offer.quote.fname} ${offer.quote.lname} - ${getFullAddressString(offer.quote.correctedAddress)}`;
  } else {
    titleString = `${offer.quote.fname} ${offer.quote.lname}`;
  }

  let matchCustomer;

  if (matchSearch.loading || (loading && !offer)) {
    matchCustomer = <Loading noBackground />;
  } else if (!offer?.quote?.existingCustomer?.id) {
    matchCustomer = (
      <PotentialMatches
        classes={classes}
        hits={matchSearch?.customersResults?.nbHits}
        fromCheckout={fromCheckout}
        matchLabel={
          matchSearch?.customersResults?.nbHits ? (
            <>
              We&#39;ve found <b>{matchSearch?.customersResults?.nbHits || 0}</b> &nbsp;{titleString}
            </>
          ) : (
            <>No matches to current members</>
          )
        }
        setMatchesResult={matchSearch.setShowCustomerMatches}
        history={history}
        offerId={offerId}
      />
    );
  } else if (matchSearch.customersResults?.hits) {
    matchCustomer = (
      <LinkedCustomer
        classes={classes}
        link={`/customer/${offer?.quote?.existingCustomer?.id}`}
        label={`Linked to ${matchSearch?.customersResults?.hits[0]?.attributes?.name} ${matchSearch.customersResults?.hits[0]?.attributes?.family_name} ID: ${offer?.quote?.existingCustomer?.id}`}
        containerClassName={classes.matchCustomer}
        fromCheckout={fromCheckout}
        setMatchesResult={matchSearch.setShowCustomerMatches}
        history={history}
        offerId={offerId}
      />
    );
  }

  const copyPreBindId = () => {
    const copied = copy(offer.webUserId);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'Pre-Bind ID was copied to your clipboard.'
      });
    }
  };

  const coappName = getCoappName(offer?.quote?.drivers);
  const subtitles = !loading ? (
    <>
      {offer?.quote?.drivers && coappName && (
        <Grid container direction="row" alignItems="baseline">
          <Label className={classes.subtitle} type="infoLabel">
            Co-Applicant
          </Label>
          <Label type="infoSubtitle">{coappName}</Label>
        </Grid>
      )}
      {offer?.webUserId && (
        <Grid container direction="row" alignItems="baseline">
          <Label className={classes.subtitleOrange} type="infoLabel">
            Pre-bind ID
          </Label>
          <Label className={classes.hover} type="infoSubtitleOrange" onClick={copyPreBindId}>
            {offer.webUserId}
          </Label>
        </Grid>
      )}
      {!offer?.quote?.existingCustomer && <EditReferral fromCheckout={fromCheckout} inviter={offer?.quote?.inviter} />}
    </>
  ) : undefined;

  if (!options.showCustomerMatches && !options.showOfferMatches) {
    return (
      <ScreenHeader
        title={titleString}
        subtitles={subtitles}
        onBack={() => history.push('/search/offers')}
        options={options}
      />
    );
  }

  return (
    <ScreenHeader
      title={titleString}
      subtitles={subtitles}
      onBack={onBack}
      options={options}
      rightValue={PotentialCustomerMatches({
        matchCustomer
      })}
    />
  );
};

OfferHeader.defaultProps = {
  options: { showAddress: false, hideBackButton: false, showCustomerMatches: true, showOfferMatches: true },
  history: {},
  onBack: () => {}
};

OfferHeader.propTypes = {
  offerId: PropTypes.string.isRequired,
  history: PropTypes.object,
  store: PropTypes.object.isRequired,
  options: PropTypes.object,
  onBack: PropTypes.func
};

export default flowRight(withStore)(OfferHeader);
