import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    minWidth: 800,
    minHeight: 284,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: theme.spacing(12)
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    color: theme.colorPalette.green_10,
    fontSize: 40,
    lineHeight: '56px',
    fontWeight: 300,
    padding: 0,
    width: '100%'
  },
  text: {
    color: theme.colors.green_15,
    marginTop: 0,
    fontSize: 16
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButtonWithText: {
    width: '94px',
    fontSize: '16px',
    marginLeft: theme.spacing(4)
  },
  priorPolicyButton: {
    width: 180,
    fontSize: 16
  }
});

export default makeStyles(styles);
