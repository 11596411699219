import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import Cookies from 'js-cookie';
import { Auth as AwsAuth } from 'aws-amplify';
import { format } from 'date-fns';

import config from '../../aws-exports';

/**
 * @param {string} phoneNumber
 * @returns {string | null} E.164 formatted phone number (+11234567890) or null
 */
const tryConvertingPhoneToE164Number = (phoneNumber) => {
  // Remove all non-numeric characters
  let e164PhoneNumber = String(phoneNumber).replace(/\D/g, '');

  if (e164PhoneNumber.length > 10) {
    e164PhoneNumber = e164PhoneNumber.slice(-10);
  }

  if (e164PhoneNumber.length === 10) {
    return `+1${e164PhoneNumber}`;
  }

  return null;
};

export const identify = async (data, trackUrl = true) => {
  if (config.trackingEnabled && window.analytics) {
    if (data && data.phone) {
      data.phone = tryConvertingPhoneToE164Number(data.phone) || data.phone;
    }

    if (data && data.phoneNumber) {
      data.phoneNumber = tryConvertingPhoneToE164Number(data.phoneNumber) || data.phoneNumber;
    }

    // Get cognito user creation date
    let cognitoUserCreationDate = window.localStorage.getItem('cognitoUserCreationDate');
    if (!cognitoUserCreationDate) {
      try {
        const credentials = await AwsAuth.currentCredentials();
        const cognito = new CognitoIdentityServiceProvider({
          region: 'us-east-1',
          credentials: AwsAuth.essentialCredentials(credentials)
        });

        const cognitoUser = await cognito
          .adminGetUser({
            Username: data.username,
            UserPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID
          })
          .promise();

        cognitoUserCreationDate = format(new Date(cognitoUser.UserCreateDate), 'yyyy-MM-dd');
        window.localStorage.setItem('cognitoUserCreationDate', cognitoUserCreationDate);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error getting cognito user creation date: ', e);
      }
    }

    data.userCreatedDate = cognitoUserCreationDate;
    const payload = trackUrl ? { ...data, mostRecentClusterUrl: window.location.href.substring(0, 1000) } : data;
    // Identify the user in Segment
    // remove all values where it's longer than 1000 bytes because it will not be accepted by segment
    for (const key of Object.keys(payload)) {
      if (String(payload[key]).length > 1000) {
        payload[key] = '';
      }
    }

    window.analytics.alias(`staff-${data.username}`);
    window.analytics.identify(`staff-${data.username}`, payload);

    // Identify the user in Logrocket
    import('logrocket').then(({ default: LogRocket }) => {
      if (data.username) {
        LogRocket.identify(`staff-${data.username}`, payload);
      }
    });
  }
};

export const track = (event, data) => {
  if (config.trackingEnabled && window.analytics) {
    if (data && data.phone) {
      data.phone = tryConvertingPhoneToE164Number(data.phone) || data.phone;
    }

    if (data && data.phoneNumber) {
      data.phoneNumber = tryConvertingPhoneToE164Number(data.phoneNumber) || data.phoneNumber;
    }

    const branchUsername = window.sessionStorage.getItem('branchSessionUsername') || undefined;
    if (branchUsername) {
      data = { ...data, branchUsername };
    }

    const sessionId = Cookies.get('ampSessionId') || Date.now();
    window.analytics.track(event, data, { integrations: { Amplitude: { sessionId } } });
    import('logrocket').then(({ default: LogRocket }) => LogRocket.track(event));
    Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 }); // session expires after 30 min
  }
};
