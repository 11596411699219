import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { useStore } from 'core/store';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import ScreenHeader from 'core/components/screen-header';

import PropertyViews from '../home/property-views';
import VerifyPropertyForm from '../home/verify-property-continental-form';
import VerifyPropertyFooter from './verify-property-footer';
import CannotOfferModal from './cannot-offer-modal';
import { OfferDialog } from '../../../dialog';

import useStyles from './verify-property-container.styles.js';

const VerifyPropertyContainer = observer(({ conditions }) => {
  const history = useHistory();
  const { offerId } = useParams();
  const [showCannotOfferModal, setShowCannotOfferModal] = useState(false);
  const { offer: offerStore } = useStore();

  const classes = useStyles();

  const { dirty } = useFormikContext();

  useEffect(() => {
    offerStore.setFormAction({ dirty, stale: offerStore.isStale, fromHomeVerification: true });
    // eslint-disable-next-line
  }, []);

  if (!offerStore.loading) {
    return (
      <div>
        {offerStore.openDialog && <OfferDialog onClose={() => offerStore.triggerOfferDialog(false)} />}
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.container}
        >
          {showCannotOfferModal && <CannotOfferModal setShowCannotOfferModal={setShowCannotOfferModal} />}
          <ScreenHeader
            title="Verify the property"
            subtitles="Certain home characteristics indicate this home may not qualify for coverage. Completing the below questions will confirm Branch's ability to provide homeowners insurance. Information provided here will not impact premium."
            onBack={() => {
              history.push(`/offer/${offerId}`);
            }}
          />
          <Label type="darkGreenMedium" className={classes.pleaseConfirmText}>
            Please confirm the following items by viewing an image of the home or by asking the potential member
          </Label>
          <PropertyViews />
          <VerifyPropertyForm {...conditions} />
          <Grid className={classes.attestationContainer}>
            <Label type="subtitle">Agent Attestation</Label>
            <Grid>
              <Field
                className={classes.attestationCheckbox}
                id="signedPropertyUWAttestation"
                name="signedPropertyUWAttestation"
                type="checkbox"
                label="I have viewed the imagery of the home or, if no images were available, discussed the property characteristics with the potential member."
                mode="light"
              />
            </Grid>
          </Grid>
          <VerifyPropertyFooter setShowCannotOfferModal={setShowCannotOfferModal} />
        </Grid>
      </div>
    );
  }
});

export default VerifyPropertyContainer;
