import { types, flow, getParent } from 'mobx-state-tree';

import { runSearch, searchForWebUserId } from './search.service';

export const SearchStore = types
  .model({
    customersResults: types.optional(types.frozen(), {}),
    offersResults: types.optional(types.frozen(), {}),
    policiesResults: types.optional(types.frozen(), {}),
    branchPlusPoliciesResults: types.optional(types.frozen(), {}),
    loading: types.boolean,
    searchString: types.optional(types.string, ''),
    currentResultsPage: types.optional(types.integer, 0),
    showCustomerMatchesModal: types.boolean,
    showReferralMatchesModal: types.optional(types.boolean, false),
    resultsPerPage: types.optional(types.integer, 10),
    expandedSearch: types.optional(types.boolean, false),
    showOfferHistory: types.optional(types.boolean, false),
    showOfferMatchesModal: types.boolean,
    error: types.maybeNull(types.frozen())
  })
  .views((self) => ({
    get meiliSearchKey() {
      return getParent(self).userPreferences.meiliSearchKey;
    },
    get meiliSearchHost() {
      return getParent(self).userPreferences.meiliSearchHost;
    },
    get userPreferences() {
      return getParent(self).userPreferences;
    }
  }))
  .actions((self) => ({
    setSearchString(value) {
      self.searchString = value;
    },
    searchOffersAction: flow(function* searchOffers() {
      self.loading = true;

      try {
        const indexName = self.showOfferHistory ? 'offerHistory' : 'offers';
        self.offersResults = yield runSearch(
          {
            searchString: self.searchString,
            index: indexName,
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage,
            expandedSearch: self.expandedSearch,
            showOfferHistory: self.showOfferHistory
          },
          self.userPreferences
        );
        self.loading = false;
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),

    searchPolicyID: function searchPolicyID() {
      // replace any policy Ids in the search string with just the account ID
      const policyIdRegex = new RegExp(/^[0-9]{9}-[a-z]{4}[0-9]*-[0-9]{3}-[A-Z]{2}$/);
      const searchStringElements = self.searchString.split(' ');
      const updatedSearchString = searchStringElements
        .map((element) => {
          if (element.match(policyIdRegex)) {
            return element.split('-');
          }
          return element;
        })
        .join(' ');

      // returns all elements of the search string seperated by commas ex: 987210165,auto,001,BX
      return updatedSearchString;
    },

    searchCustomersAction: flow(function* searchCustomers() {
      self.loading = true;
      // this logic allows people to search for policy IDs in the Member index
      // replace any policy Ids in the search string with just the account ID because this is the Member index
      const updatedSearchString = self.searchPolicyID().split(',', 1).join(' ');
      try {
        self.customersResults = yield runSearch(
          {
            searchString: updatedSearchString,
            index: 'users',
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage,
            expandedSearch: self.expandedSearch
          },
          self.userPreferences
        );
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),
    searchPoliciesAction: flow(function* searchPolicies(isInternalAgent) {
      self.loading = true;
      const externalSalesSearchValue = self.searchPolicyID().split(',', 2).join('-'); // returns poliyId-type (ex: 123456789-home)
      try {
        self.policiesResults = yield runSearch(
          {
            searchString: isInternalAgent ? self.searchString : externalSalesSearchValue,
            index: 'policy',
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage
          },
          self.userPreferences
        );
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),
    searchBranchPlusPoliciesAction: flow(function* searchBranchPlusPolicies() {
      self.loading = true;
      try {
        const results = yield runSearch(
          {
            searchString: self.searchString,
            index: 'branchPlusPolicies',
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage,
            expandedSearch: self.expandedSearch
          },
          self.userPreferences
        );

        if (results.hits.length) {
          const { carriers } = getParent(self);
          for (const policy of results.hits) {
            const carrierInfo = yield carriers.getCarrierInformation(policy.carrier, policy.policy_type);
            if (carrierInfo) {
              policy.login_url = carrierInfo.loginUrl;
            }
          }
        }
        self.branchPlusPoliciesResults = results;
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),
    searchStaffUsers: flow(function* searchStaffUsers({ username }) {
      const response = yield runSearch(
        {
          searchString: username,
          index: 'staffUsers',
          page: 0,
          hitsPerPage: 10
        },
        self.userPreferences
      );
      // meilisearch will return the best match as first result
      const user = response.hits[0];
      if (user?.Username === username) {
        return user;
      }
      return undefined;
    }),
    searchForOffersWithWebUserId: flow(function* searchForOffersWithWebUserId(webUserId) {
      const response = yield searchForWebUserId(webUserId, self.userPreferences);
      return response.hits;
    }),
    clearInput() {
      self.searchString = '';
    },
    resetCustomerResults() {
      self.customersResults = {};
    },
    setCurrentResultsPage(page) {
      self.currentResultsPage = page;
    },
    setShowCustomerMatches(show = true) {
      self.showCustomerMatchesModal = show;
    },
    setShowReferralMatches(show = true) {
      self.showReferralMatchesModal = show;
    },
    setExpandedSearch(expanded) {
      self.expandedSearch = expanded;
    },
    setShowOfferHistory(show) {
      self.showOfferHistory = show;
    }
  }));
