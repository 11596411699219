import React from 'react';

const errorLookup = (state, isAgency) => {
  return {
    // ALERTS
    5001: {
      title: 'Update the prior address details and resubmit to get a price',
      why: 'We are missing some applicant details. Adding the prior address may provide remaining details and provide the best price for the member.'
    },
    5002: {
      title: 'Date of birth required',
      why: "Please provide the applicant's date of birth so we can confirm their identity and complete their offer."
    },
    5003: {
      title: 'Current auto coverage details required',
      why: "We couldn't find information about the applicant's current auto insurance provider. These details help us confirm their identity and provide the most accurate price. Please include current coverage limits and continuous years of auto coverage to complete the offer."
    },
    5004: {
      title: ' Home details required',
      why: "We're missing some information about the applicant's home. These details help us to provide the most accurate price and coverage options. Please enter home details to complete the offer."
    },
    5005: {
      title: 'VIN required',
      why: "Please enter the applicant's VIN to complete the offer. These details help us confirm their identity and provide the most accurate price."
    },
    5006: {
      title: ' Retry VIN',
      why: 'There was an unknown error in finding your offer. Resubmit the VIN to complete the offer.'
    },
    5009: {
      title: 'Ineligible vehicle',
      why: "Due to underwriting guidelines, we're not able to cover the vehicle indicated by this VIN. Try another VIN or proceed without an auto coverage offer."
    },
    5010: {
      title: 'Retry VIN',
      why: "We can't identify that VIN number. Check for typos and try again, or add a different applicant vehicle."
    },
    5012: {
      title: "Driver's license number required",
      why: "Please enter the applicant's driver's license number to complete the offer. These details help us confirm their identity and provide the most accurate price."
    },
    5013: {
      title: 'Invalid address',
      why: "Sorry, we don't allow PO Box addresses. Please provide a physical address for the applicant to complete the offer."
    },
    5015: {
      title: "Driver's license details required",
      why: "We can't verify the primary insured's driving record. These details help us confirm the applicant's identity and provide the most accurate price. Please submit the applicant's driver's license state and number to complete the offer."
    },
    5016: {
      title: 'Additional information required',
      why: "We need more information on the primary applicant to confirm their identity and provide the most accurate price. Please submit the applicant's date of birth, state, and driver's license number."
    },
    5023: {
      title: 'Additional address details required',
      why: "We weren't able to locate the applicant's address. Resubmit with the address's latitude and longitude to complete the offer."
    },
    5024: {
      title: 'Retry ZIP code',
      why: "We can't find a rate for this ZIP code, likely due to a typo or this being a non-residential ZIP code. Double check and retry the applicant's ZIP code, and if neither of these is the case, please contact support."
    },
    5025: {
      title: 'Unit number required',
      why: "We need the unit number for the applicant's address. Enter this information in the secondary address field to complete the offer."
    },
    // Blocks
    4000: {
      title: 'Driver info error',
      subtitle:
        'We seem to be missing information on one of the applied drivers. Please resubmit the info and try again. If the error continues, contact support.',
      branchPlusEligible: false
    },
    4001: {
      title: 'Try again: Unknown error',
      subtitle:
        "Sorry, we're not sure what went wrong. Please try again, and if the problem persists, contact support.",
      branchPlusEligible: false
    },
    4002: {
      title: 'Check back later: Unknown error',
      subtitle: "Sorry, we're not sure what went wrong. Check back later or contact support.",
      branchPlusEligible: false
    },
    4003: {
      title: 'Ineligible state',
      subtitle: "We aren't currently writing insurance policies in the applied state.",
      branchPlusEligible: true
    },
    4004: {
      title: 'Try again: Unknown error',
      subtitle:
        "Sorry, we're not sure what went wrong. Please try again, and if the problem persists, contact support.",
      branchPlusEligible: false
    },
    4005: {
      title: 'Information error',
      subtitle:
        'There was an error in the information provided for this offer. Please check that the full name and address is correct and resubmit to complete the offer.',
      branchPlusEligible: false
    },
    5008: {
      title: 'Ineligible state',
      subtitle: "We aren't currently writing insurance policies in the applied state.",
      branchPlusEligible: true
    },
    5011: {
      title: 'Moratorium zip code',
      subtitle:
        "Due to a higher risk for severe weather, we temporarily aren't writing insurance policies in the applied area.",
      branchPlusEligible: true
    },
    5014: {
      title: 'Rater error',
      subtitle:
        "Sorry, we're not sure what went wrong, but we can't proceed with an offer with the information provided. Please review the details and resubmit, or contact support if the error continues.",
      branchPlusEligible: false
    },
    5017: {
      title: 'Restricted state',
      subtitle:
        'Branch is currently only writing offers with specific, authorized entities in this state, and otherwise is not selling coverage here.',
      branchPlusEligible: true
    },
    5018: {
      title: 'Setting fees',
      subtitle:
        "There's been an error in finding the correct local premium taxing authority. Check that the submitted address is correct, or contact support if the error continues.",
      branchPlusEligible: false
    },
    5019: {
      title: 'Suspicious activity',
      subtitle:
        "While maintaining the security of our services, our system has detected elements of this offer that appear unusual or suspicious. To complete this offer, please resubmit with the applicant's name and address.",
      branchPlusEligible: false
    },
    5020: {
      title: 'Information error',
      subtitle:
        "Sorry, we're not sure what went wrong, but we can't proceed with an offer with the information provided. Please review the details and resubmit, or contact support if the error continues.",
      branchPlusEligible: false
    },
    5021: {
      title: 'Repeat applicant',
      subtitle:
        "The applicant has been quoted multiple times in a row, which our system doesn't allow to ensure security and accurate offers. Please navigate to a prior offer for this applicant, or resubmit later.",
      branchPlusEligible: false
    },
    5022: {
      title: 'History of nonpayment cancellations',
      subtitle:
        'Records show this applicant has had too many prior cancellations due to nonpayment, and Branch is unable to offer them coverage.',
      branchPlusEligible: true
    },
    5026: {
      title: 'Try again: Unknown error',
      subtitle: "Sorry, we're not sure what went wrong. Resubmit the offer or contact support.",
      branchPlusEligible: true
    },
    5027: {
      title: 'Ineligible zip code',
      subtitle: "We aren't currently writing insurance policies in the applied area.",
      branchPlusEligible: true
    },
    5028: {
      title: 'Disabled affinity code',
      subtitle:
        "Sorry, it seems your affinity code is disabled, so you're unable to create offers at this time. Please contact support.",
      branchPlusEligible: false
    },
    5029: {
      title: 'Restricted state',
      subtitle:
        "Sorry, it seems you don't have access to create an offer in the applicant's state. If you believe this is in error, contact support.",
      branchPlusEligible: true
    },
    5030: { title: 'Invalid offer', subtitle: 'This offer is too old to update.' },
    5031: { title: 'Invalid offer', subtitle: 'We are not offering rideshare coverage at this time.' },
    5032: {
      title: 'Ineligible to bundle',
      subtitle:
        "Due to one or more of the requested policy types being ineligible, this offer isn't eligible for a bundle.",
      branchPlusEligible: true
    },
    5033: { title: 'Invalid offer', subtitle: 'There are no available options for this offer.' },
    5034: {
      title: 'Excess rejections',
      subtitle: 'This offer returned with an auxiliary auto rate, we are unable to offer a price at this time.'
    },
    5035: {
      title: 'Wait and retry',
      subtitle:
        'Too many offers for this applicant were submitted in a small span of time. Please wait and retry in thirty seconds to complete the offer.'
    },
    // Bypass Alerts
    50001: {
      title: 'Submit VIN',
      why: "Please provide a VIN to complete a bundled offer. Or, if there's not an eligible vehicle, resubmit to proceed for a monoline offer without an auto policy."
    },
    50006: {
      title: 'Submit home details',
      why: "Please provide home details to complete a bundled offer. Or, if there's not an eligible home, resubmit to proceed for a monoline offer without a home policy."
    },
    // Dismissible Rejections
    50096: {
      title: 'Is this property a [type_placeholder]',
      why: 'Our data sources indicate that the property at this address is not a condo but rather a house. '
    },
    50097: {
      title: 'Is this a commercial property?',
      why: 'Our data sources indicate that the property at this address is zoned for commercial use, and Branch does not insure commercial properties in this state. '
    },
    50098: {
      title: 'Is this residence any of the following?',
      subtitle: (
        <ul>
          <li>Mobile Home</li>
          <li>Apartment</li>
          <li>Manufactured Home</li>
          <li>Condo</li>
        </ul>
      ),
      why: 'Our data sources indicate that the property at this address is zoned for non-residential use, and Branch does not insure these types of properties in this state.                               '
    }
  };
};

export const getErrorData = ({ error, state, isAgency }) => {
  let errorData = null;

  if (error?.message === 'Execution timed out.') {
    return {
      code: error.code,
      title: 'Execution timed out',
      message: `This offer took too long to generate. After 15 seconds, please search for the applicant's name and address in
        the offer search. If the offer does not appear, please retry.`
    };
  }

  // grab all our relevant data from the errorData object for this error code
  if (!isNaN(error.code)) {
    errorData = { ...error, ...errorLookup(state, isAgency)[error?.code] };
  }
  // replace the type placeholder if needed for the title or message
  if (errorData.type && errorData.title && String(errorData.title).includes('[type_placeholder]')) {
    errorData.title = String(errorData.title).replace('[type_placeholder]', errorData.data?.type || errorData.type);
  }

  errorData.code = Number(error.code);
  return errorData;
};

export default getErrorData;
