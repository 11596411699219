import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { types } from 'core/helpers/sanitize';
import { Form, Formik, FieldArray } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Loading } from 'core';
import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import AddButton from 'core/components/add-button';
import RemoveButton from 'core/components/remove-button';
import withDatePicker from 'core/components/with-date-picker';
import { dateFormatter, phoneNumberFormatter } from 'core/helpers/formatters';
import validationSchema from './account-details-form.validation-schema';
import useStyles from '../../account-details.styles';

const AccountDetailsForm = observer(function AccountDetailsForm({ onSuccess, onError }) {
  const { account } = useStore();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const onSubmit = async (values) => {
    try {
      const { email: initialEmail, phoneNumber: initialPhoneNumber } = account;

      const { id } = account;
      const { created, additionalPhoneNumbers, phoneNumber, email, ...restOfValues } = values;

      const formattedAdditionalPhoneNumbers = additionalPhoneNumbers
        .filter((phoneDetail) => phoneDetail.phoneNumber) // remove items where phone number is not defined
        .map((phoneDetail) => {
          return {
            phoneNumber: phoneNumberFormatter({ phoneNumber: phoneDetail.phoneNumber }),
            note: phoneDetail.note,
            canText: phoneDetail.canText
          };
        });

      const primaryPhoneNumber = phoneNumberFormatter({ phoneNumber });

      const newAccount = {
        id,
        ...types.AccountDetailsInput({
          ...restOfValues,
          email: email.toLowerCase(),
          phoneNumber: primaryPhoneNumber,
          additionalPhoneNumbers: formattedAdditionalPhoneNumbers,
          initialPhoneNumber: initialPhoneNumber || '',
          initialEmail: initialEmail || ''
        })
      };
      setLoading(true);
      const { data, error } = await account.updateAccount(newAccount);

      if (data) {
        onSuccess();
      } else {
        errorUpdatingAccount(error);
      }
    } catch (e) {
      errorUpdatingAccount(e.message);
    }
  };

  const errorUpdatingAccount = (message) => {
    onError(message);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        fname: '',
        lname: '',
        mailingAddress: '',
        email: '',
        additionalPhoneNumbers: account.additionalPhoneNumbers?.length ? [...account.additionalPhoneNumbers] : [],
        ...account
      }}
      onSubmit={onSubmit}
    >
      {({ touched, values, setFieldTouched }) => (
        <>
          {loading ? (
            <Grid container justifyContent="center">
              <Loading noBackground />
            </Grid>
          ) : (
            <Form className="form">
              <Grid key="container" container justifyContent="space-around" alignItems="flex-start" spacing={2}>
                <FormField
                  mode="light"
                  name="fname"
                  id="fname"
                  label="First Name"
                  type="string"
                  xs={12}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="lname"
                  id="lname"
                  label="Last Name"
                  type="string"
                  xs={12}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="mailingAddress"
                  id="mailingAddress.address"
                  label="Address"
                  type="address"
                  xs={12}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="mailingAddress.address2"
                  id="mailingAddress.address2"
                  label="Address 2"
                  type="string"
                  optional
                  xs={6}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="mailingAddress.city"
                  id="mailingAddress.city"
                  label="City"
                  type="string"
                  optional
                  xs={6}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="mailingAddress.state"
                  id="mailingAddress.state"
                  label="State"
                  type="state"
                  optional
                  xs={6}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="mailingAddress.zip"
                  id="mailingAddress.zip"
                  label="Zip Code"
                  type="string"
                  optional
                  xs={6}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="email"
                  id="email"
                  label="Email"
                  type="string"
                  xs={12}
                  permissions={{ isLicensedAction: false }}
                />
                <FormField
                  mode="light"
                  name="phoneNumber"
                  id="phoneNumber"
                  label="Phone Number"
                  type="string"
                  xs={12}
                  permissions={{ isLicensedAction: false }}
                />
                <FieldArray
                  name="additionalPhoneNumbers"
                  render={(arrayHelpers) => (
                    <>
                      {values.additionalPhoneNumbers?.length !== 0 &&
                        values.additionalPhoneNumbers?.map((_, index) => (
                          <Grid
                            container
                            key={`addtl-phone-${index}`}
                            className={classes.secondaryPhoneContainer}
                            alignContent="flex-start"
                            spacing={2}
                          >
                            <Grid container item alignItems="center" justifyContent="space-between">
                              <FormField
                                mode="light"
                                name={`additionalPhoneNumbers[${index}].phoneNumber`}
                                id={`additionalPhoneNumbers[${index}].phoneNumber`}
                                label="Secondary Phone"
                                type="string"
                                xs={8}
                                permissions={{ isLicensedAction: false }}
                              />
                              <RemoveButton
                                mode="big"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                  setFieldTouched('additionalPhoneNumbers');
                                }}
                              />
                            </Grid>
                            <FormField
                              mode="light"
                              name={`additionalPhoneNumbers[${index}].note`}
                              id={`additionalPhoneNumbers[${index}].note`}
                              label="Description"
                              type="string"
                              xs={12}
                              permissions={{ isLicensedAction: false }}
                            />
                            <FormField
                              mode="light"
                              name={`additionalPhoneNumbers[${index}].canText`}
                              id={`additionalPhoneNumbers[${index}].canText`}
                              label="Does the member agree to receiving texts at this number?"
                              type="checkbox"
                              xs={12}
                              permissions={{ isLicensedAction: false }}
                            />
                          </Grid>
                        ))}
                      <AddButton
                        type="full"
                        mode="xl"
                        onClick={() => {
                          arrayHelpers.push({ phoneNumber: '', note: '' });
                          setFieldTouched('additionalPhoneNumbers');
                        }}
                        label="Add an additional phone number"
                      />
                    </>
                  )}
                />

                <FormField
                  name="created"
                  id="created"
                  label="Member Since"
                  type="value"
                  formatter={dateFormatter}
                  xs={12}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    disabled={Object.keys(touched).length === 0}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    data-cy="submit-search"
                    xs={12}
                  >
                    Confirm
                  </Button>
                </div>
              </Grid>
            </Form>
          )}
        </>
      )}
    </Formik>
  );
});

AccountDetailsForm.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default flowRight(withDatePicker)(AccountDetailsForm);
