import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Dialog, DialogTitle, DialogContent, Button, Grid } from '@material-ui/core';

import { ViewSchedule } from 'common/components/payment/view-schedule';
import { ActionButton } from 'core/components/action-button';
import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import Field from 'core/components/form/form.v2';
import { useStore } from 'core/store';
import { NotificationCard } from 'core/components/notification-card';
import AgencySupportBannerExtended from 'core/components/agency-support-banner-extended';
import { PolicyTabs } from 'core/store/account-store/policies-store/policy-store/policy-store';
import useSession from 'core/hooks/use-session';
import { useToast } from 'core/components/toast';
import { dateFormatter, numberFormatter } from 'core/helpers/formatters';

import useStyles from './pay-ahead-modal.styles';

const PayAheadModal = ({ open, onClose }) => {
  const classes = useStyles();
  const {
    isAgency,
    user: { username }
  } = useSession();
  const toast = useToast();
  const {
    account: {
      fetchFullAccountAndPolicy,
      id: accountId,
      policies: {
        policy: { billingDetails, setCurrentTab, policy, runPayAheadManualCharge, payAheadNextPayments }
      }
    }
  } = useStore();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    allPaymentMethods,
    activePaymentMethod,
    remainingPayments,
    nextPaymentAmount,
    totalDue,
    renewalPaymentDate,
    nextPayments
  } = billingDetails;

  const paymentMethodOptions = allPaymentMethods
    ? allPaymentMethods.map((paymentMethod) => {
        const { id, brand, last4, bankName } = paymentMethod;
        return { id, value: `${bankName ? bankName.toUpperCase() : brand.toUpperCase()} ****${last4}` };
      })
    : [];

  const renewDate = renewalPaymentDate ? dateFormatter(renewalPaymentDate) : 'policy renewal';

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      const { amount } = values;
      try {
        const res = await runPayAheadManualCharge(policy, values);
        setLoading(false);
        if (res.ok) {
          if (res.chargeSuccess) {
            toast.notify({
              type: 'success',
              message: `The payment of $${numberFormatter(amount, 2)} was billed successfully to ${res.paymentMethod} ${
                res.last4
              }`
            });
          } else {
            setLoading(false);
            toast.notify({
              type: 'error',
              message: `There was an error when trying to process the payment.
        Please try with a different payment method or contact ${isAgency ? 'Agency Support' : 'help desk'}`
            });
          }
        }
      } catch (e) {
        setLoading(false);
        toast.notify({
          type: 'error',
          message: e.message || 'There was an error during the pay ahead process.'
        });
      }
      fetchFullAccountAndPolicy(policy.id, accountId);
    },
    [runPayAheadManualCharge, toast, username, policy.id]
  );

  const handleChange = (id) => {
    const index = allPaymentMethods.findIndex((paymentMethod) => paymentMethod.id === id);
    const selectedPaymentMethod = allPaymentMethods[index];
    const { brand, last4 } = selectedPaymentMethod;
    const paymentMethodType = brand ? 'credit card' : 'bank account';
    setPaymentMethod(` ${paymentMethodType} ending in ${last4} `);
  };
  const { brand, last4 } = activePaymentMethod;
  const paymentMethodType = brand ? 'credit card' : 'bank account';
  const initialPaymentMethod = ` ${paymentMethodType} ending in ${last4}.`;

  const paymentAmount = remainingPayments === 0 ? totalDue : nextPaymentAmount;
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle className={classes.dialogContainer}>
        <Label type="titleSecondary" className={classes.title}>
          Pay Ahead
        </Label>
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <Formik
          initialValues={{
            paymentMethod: activePaymentMethod.id,
            amount: paymentAmount,
            billType: 'feePremium',
            internalDescription: `Pay Ahead payment made by ${username}`,
            billingHoldUntil: remainingPayments > 1 ? nextPayments[1].date : null,
            remainingPayments
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => {
            return (
              <>
                <div className={classes.text}>
                  Continuing with this action will:
                  <ul className={classes.list}>
                    <li>
                      Process an immediate installment payment in the amount of
                      <strong className={classes.strong}>{` $${numberFormatter(paymentAmount, 2)} `}</strong>
                      to
                      <strong>{paymentMethod || initialPaymentMethod}</strong>
                    </li>
                    {/* remaingPayments of 0 or 1 mean that this could be last installment of policy */}
                    {remainingPayments <= 1 ? (
                      <li>
                        After processing this payment, the current term will be fully paid. If the policy is set to
                        renew, the next payment will be due on {renewDate}.
                      </li>
                    ) : (
                      <li>
                        The member will not be charged again until the next installment on{' '}
                        {dateFormatter(nextPayments[1].date)}. <ViewSchedule nextPayments={payAheadNextPayments} />
                      </li>
                    )}
                  </ul>
                </div>
                <Grid container className={classes.detailsContent}>
                  <Field
                    item
                    name="paymentMethod"
                    type="select"
                    label="Payment Method"
                    xs={8}
                    mode="light"
                    options={paymentMethodOptions}
                    ignoreGlobalDisabledState
                    onChange={handleChange}
                  />
                  <Button
                    item
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Submit Payment
                  </Button>
                </Grid>
              </>
            );
          }}
        </Formik>
      </DialogContent>
      <NotificationCard type="light" className={classes.card}>
        <Label type="body1">
          To add a new payment method, navigate to the
          <Button
            className={classes.settingsButton}
            onClick={() => {
              onClose();
              setCurrentTab(PolicyTabs.SETTINGS);
            }}
            variant="text"
            color="secondary"
          >
            Settings
          </Button>
          tab.
        </Label>
      </NotificationCard>
      {isAgency && (
        <AgencySupportBannerExtended content="Contact us if you would like to process a different payment amount or pay ahead more than one installment." />
      )}
    </Dialog>
  );
};

PayAheadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PayAheadModal;
