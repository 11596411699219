import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'core/components/button';
import { ErrorLabel } from '../error-label';
import { errorTypes } from '../constants';
import useStyles from './override-error-button.styles';

const OverrideErrorButton = ({ type, onClick }) => {
  const classes = useStyles();
  if (type === errorTypes.Tertiary) {
    return (
      <Button variant="contained" color="secondary" className={classes.button} onClick={onClick}>
        Override error
      </Button>
    );
  }
  return (
    <ErrorLabel asLink errorType={type} onClick={onClick}>
      Approve underwriting exception and clear error
    </ErrorLabel>
  );
};

OverrideErrorButton.propTypes = {
  type: PropTypes.oneOf(Object.values(errorTypes))
};

export default OverrideErrorButton;
