import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { getOptions } from '@ourbranch/lookups';
import { useFormikContext, Form } from 'formik';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import { Collapsible } from '../collapsible';
import { autoDetailsCodes } from '../quote-forms/quote-forms.constants';
import { ErrorAlert } from '../quote-errors';
import useStyles from './auto-details.styles';

const AutoDetails = ({ sectionsToShow }) => {
  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext();
  const { state } = values;
  const {
    quote: { getAlerts }
  } = useStore();
  const limitOptions = useMemo(() => {
    return getOptions('policyLimitBIPD', state); // no 500 CSL as of 5/18/2023
  }, [state]);
  const yearsOptions = useMemo(() => {
    return getOptions('numberOfPriorYearsCoverage', state);
  }, [state]);
  const [shouldShow, setShouldShow] = useState(false);

  const autoErrors = useRef(null);

  const alerts = getAlerts(autoDetailsCodes, values.policyType);

  useEffect(() => {
    if (alerts.length && autoErrors.current && isSubmitting) {
      autoErrors.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [alerts, isSubmitting]);

  useEffect(() => {
    if (sectionsToShow && sectionsToShow?.length > 0) {
      const shouldBeShown = autoDetailsCodes.every((element) => sectionsToShow.includes(element));
      setShouldShow(shouldBeShown);
    }
  }, [sectionsToShow]);

  return (
    <Form>
      {alerts.length > 0 && <ErrorAlert errors={alerts} ref={autoErrors} />}
      {shouldShow && (
        <Collapsible title="Current Auto Details" forceOpen={alerts.length > 0} initialOpen={alerts.length > 0}>
          <Grid item xs={6}>
            <Field
              type="select"
              id="policyLimitBIPD"
              name="policyLimitBIPD"
              options={limitOptions}
              disabled={!state}
              fast={false}
              mode="light"
              label="Auto Liability Limit"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              type="select"
              id="priorCoverageInYears"
              name="priorCoverageInYears"
              options={yearsOptions}
              disabled={!state}
              fast={false}
              mode="light"
              label="Years of continous prior insurance"
            />
          </Grid>
          <Grid item xs={8} className={classes.checkboxContainer}>
            <Field
              type="checkbox"
              id="insuranceInPast31Days"
              name="insuranceInPast31Days"
              mode="light"
              label="Have you had auto coverage in the last 31 days?"
            />
          </Grid>
        </Collapsible>
      )}
    </Form>
  );
};

AutoDetails.propTypes = {
  sectionsToShow: PropTypes.array.isRequired
};

export default withDatePicker(AutoDetails);
