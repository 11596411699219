import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    fontWeight: 600,
    fontSize: 16,
    width: '100%'
  }
});

export default makeStyles(styles);
