import _omit from 'lodash-es/omit';
import _findIndex from 'lodash-es/findIndex';
import _isEmpty from 'lodash-es/isEmpty';
import { distance } from 'fastest-levenshtein';
import { paymentMethod as PaymentMethod, mortgageLookups } from '@ourbranch/lookups';

import { track } from 'core/helpers/analytics';

export const getPrimaryMortgageIndex = (mortgageDetails) => {
  if (mortgageDetails && mortgageDetails.length) {
    return _findIndex(mortgageDetails, (mortgageDetail) => {
      return mortgageDetail.primary;
    });
  }
  return -1;
};

export const pushNewBlankPrimaryMortageDetail = (mortgageDetails) => {
  return [
    ...mortgageDetails,
    {
      primary: true,
      loanNumber: undefined,
      mortgageHolderAddress: undefined,
      mortgageHolderName: undefined,
      mortgageHolderId: undefined
    }
  ];
};

export const removeBlankMortgageDetail = (mortgageDetails) => {
  if (mortgageDetails.length) {
    const newMortgageDetailsArray = [];
    mortgageDetails.forEach((mortgageDetail) => {
      if (mortgageDetail.mortgageHolderName) {
        newMortgageDetailsArray.push(mortgageDetail);
      }
    });
    return newMortgageDetailsArray;
  }
  return mortgageDetails;
};

export const removeOldPrimaryMortgageDetails = (mortgageDetails) => {
  const newMortgageDetailsArray = [];
  mortgageDetails.forEach((mortgageDetail) => {
    if (!mortgageDetail.primary) {
      newMortgageDetailsArray.push(mortgageDetail);
    }
  });
  return newMortgageDetailsArray;
};

export const getPrimaryOrBlankMortgageDetails = (mortgageDetails) => {
  const index = getPrimaryMortgageIndex(mortgageDetails);
  if (index < 0) {
    return {
      primary: true,
      loanNumber: '',
      mortgageHolderAddress: {
        address: '',
        address2: '',
        city: '',
        zip: '',
        state: ''
      },
      mortgageHolderName: '',
      mortgageHolderId: ''
    };
  }
  return mortgageDetails[index];
};

export const updatePrimaryMortgageDetails = (currentMortgageDetails, updatedPrimaryMortgageDetail, paymentMethod) => {
  let mortgageDetails = currentMortgageDetails ? [...currentMortgageDetails] : [];
  if (getPrimaryMortgageIndex(currentMortgageDetails) >= 0) {
    mortgageDetails = removeOldPrimaryMortgageDetails(mortgageDetails);
  }
  const primaryMortgageDetailCopy = _omit(updatedPrimaryMortgageDetail, ['primary', '__typename']);
  if (
    Object.values(primaryMortgageDetailCopy).some((detail) => !_isEmpty(detail)) ||
    paymentMethod === PaymentMethod.Escrow
  ) {
    mortgageDetails.push(updatedPrimaryMortgageDetail);
  }

  // remove mortgages that aren't properly filled out with a lender name
  return mortgageDetails.filter((md) => md.mortgageHolderName && md.mortgageHolderAddress?.address);
};

export function getMatchingLenders(mortgageHolderName, customLenderAddress) {
  const exactMatchingLenders = [];
  const matchingLenders = [];

  for (const mortgage of mortgageLookups) {
    const { correctName, address, city, state, zip } = mortgage;
    const existingLenderAddress = `${address}, ${city}, ${state}, ${zip}`;
    const { shorterString: shorterName, longerStringTrimmed: longerName } = getShorterAndLongerStringsTrimmed(
      correctName,
      mortgageHolderName
    );

    const distanceValue = distance(
      shorterName.toUpperCase().replace(/[^A-Za-z]/, ''),
      longerName.toUpperCase().replace(/[^A-Za-z]/, '')
    );

    // Exact match
    if (distanceValue === 0) {
      exactMatchingLenders.push(mortgage);
    }
    // There isn't an exact match yet
    else if (exactMatchingLenders.length === 0) {
      if (lenderNameMatch(shorterName, longerName)) {
        matchingLenders.push(mortgage);
      } else if (lenderAddressMatch(existingLenderAddress, customLenderAddress)) {
        matchingLenders.push(mortgage);
      }
    }
  }

  // If there're exact matches -> return those. In other case, return all the matches
  return exactMatchingLenders.length > 0 ? exactMatchingLenders : matchingLenders;
}

function getShorterAndLongerStringsTrimmed(str1, str2) {
  // Look at string with fewer characters and trim the end off the longer string
  // to make it the same number of characters as the shorter value.
  const longerString = str1.length < str2.length ? str2 : str1;
  const shorterString = longerString === str1 ? str2 : str1;
  const longerStringTrimmed = longerString.slice(0, shorterString.length);
  return { shorterString, longerStringTrimmed };
}

function lenderNameMatch(shorterString, longerStringTrimmed) {
  // 25% of the length of the shorter string, or 4 f it's greater than that.
  // So 3 characters would be 0.75 --> 1 character of distance.
  // And 18 characters would be 4.5 --> 4 characters of distance.
  const distanceToUse =
    shorterString.length <= 1 ? 1 : Math.round(shorterString.length * 0.25 < 4 ? shorterString.length * 0.25 : 4);

  // Compare for differences.
  return (
    distance(
      shorterString.toUpperCase().replace(/[^A-Za-z]/, ''),
      longerStringTrimmed.toUpperCase().replace(/[^A-Za-z]/, '')
    ) <= distanceToUse
  );
}

function lenderAddressMatch(address1, address2) {
  return (
    distance(address1.toUpperCase().replace(/[^A-Za-z]/, ''), address2.toUpperCase().replace(/[^A-Za-z]/, '')) <= 1
  );
}

export function setPrimaryMortgageValues(setFieldValue, mortgage) {
  setFieldValue('primaryMortgageDetail.mortgageHolderAddress', mortgage?.mortgageHolderAddress);
  setFieldValue('primaryMortgageDetail.mortgageHolderName', mortgage?.mortgageHolderName);
  setFieldValue('primaryMortgageDetail.mortgageHolderId', mortgage?.id);
  setFieldValue('primaryMortgageDetail.loanNumber', mortgage?.loanNumber);
  setFieldValue('primaryMortgageDetail.isCustom', mortgage?.isCustom);
}

export function trackCustomMortgageLender(
  isAdding,
  idToTrack,
  { mortgageHolderName, mortgageHolderAddress, loanNumber }
) {
  const { address, city, state, zip } = mortgageHolderAddress;
  track(`Staff ${isAdding ? 'Add' : 'Remove'} Custom Mortgage Lender`, {
    ...idToTrack,
    mortgageHolderName,
    mortgageHolderAddress: `${address}, ${city}, ${state}, ${zip}`,
    loanNumber
  });
}
