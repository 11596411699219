import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Grid } from '@material-ui/core';
import { differenceInSeconds } from 'date-fns';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import { getUserFriendlyStatus, cleanArgs, displayData, isJson } from 'tools/helpers';
import TaskOutput from 'tools/helpers/generate-task-ui/components/task-output';
import StateMachineOutput from 'tools/helpers/generate-task-ui/components/state-machine-output';
import LiveTaskMessages from 'tools/helpers/generate-task-ui/components/live-task-messages';
import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import OutputRunStatus from './components/output-run-status';
import useStyles from './tool-details.styles';

const GET_TOOL_DETAIL = gql`
  query getToolDetail($taskRunId: String!, $taskYYMM: String!) {
    getToolDetail(taskRunId: $taskRunId, taskYYMM: $taskYYMM) {
      taskRunId
      endedAt
      startedAt
      startedBy
      status
      taskName
      parentRunId
      data {
        arguments
        type
        result
        timestamp
        message
        outputAction
        outputPayload
        inputS3Url
        outputDestination {
          bucket
          key
        }
        filePaths {
          path
          output
        }
      }
    }
  }
`;

const getDuration = (startedAt, endedAt) => {
  const duration = differenceInSeconds(new Date(endedAt), new Date(startedAt));
  return duration === 0 ? '1s' : `${duration}s`;
};

const getOutput = (toolDetail) => {
  const output = toolDetail?.data?.find((d) => d.type === 'OUTPUT');
  const outputDetails = output?.result || output?.outputPayload;
  if (outputDetails) {
    const parsed = isJson(outputDetails) ? JSON.parse(outputDetails) : outputDetails;
    if (parsed?.details) {
      // regular task output
      return { ...parsed, details: isJson(parsed?.details) ? JSON.parse(parsed?.details) : parsed?.details };
    }
    // bulk task output
    return {
      ...parsed,
      filePaths: output?.filePaths,
      type: output?.type
    };
  }
  return undefined;
};

const ToolDetail = ({ toolConfig }) => {
  const classes = useStyles();
  const toast = useToast();
  const { taskRunId, taskYYMM } = useParams();
  const { name: title } = toolConfig;

  const [toolDetail, setToolDetail] = useState();

  const { data, loading, error, stopPolling } = useQuery(GET_TOOL_DETAIL, {
    variables: { taskRunId, taskYYMM },
    pollInterval: 1000
  });

  const messages = toolDetail?.data?.filter((d) => d.type === 'MESSAGE')?.map((m) => m.message);

  const inputData = toolDetail?.data?.find((d) => d.type === 'INPUT');
  const cleanedArgs = inputData ? cleanArgs(inputData) : undefined;
  const output = getOutput(toolDetail);

  useEffect(() => {
    if (error) {
      stopPolling();
      toast.notify({
        type: 'error',
        message: `Error fetching task detail - ${error?.message}`
      });
    }
    if (data?.getToolDetail?.status) {
      stopPolling();
      setToolDetail(data.getToolDetail);
    }
  }, [error, data]);

  if (loading || !toolDetail?.status) {
    return <Label type="subtitle">Loading...</Label>;
  }

  return (
    <>
      <div className={classes.header}>
        <Label type="title" className={classes.title}>
          {title}
        </Label>
        <Grid container>
          <Grid item xs={2}>
            <Label type="infoLabel">Status</Label>
            <Label type="body1">{getUserFriendlyStatus(toolDetail?.status)}</Label>
          </Grid>
          <Grid item xs={2}>
            <Label type="infoLabel">Duration</Label>
            <Label type="body1">{getDuration(toolDetail?.startedAt, toolDetail?.endedAt)}</Label>
          </Grid>
          <Grid item xs={2}>
            <Label type="infoLabel">From bulk task?</Label>
            <Label type="body1">{toolDetail?.parentRunId ? 'Yes' : 'No'}</Label>
          </Grid>
        </Grid>
      </div>
      <div className={classes.activity}>
        <Label type="subtitle" className={classes.subtitle}>
          Activity
        </Label>
        <Grid className={classes.sectionContent}>
          <Grid container alignItems="center">
            <OfflineBoltIcon className={classes.icon} />
            <Grid>
              <Label type="body1Bold">Run started</Label>
              <Label type="body1">
                Created at {new Date(toolDetail?.startedAt).toLocaleString()} by {toolDetail?.startedBy}
              </Label>
            </Grid>
          </Grid>
          {cleanedArgs && (
            <Grid container className={classes.argumentsContainer}>
              {Object.keys(cleanedArgs)?.map((key) => (
                <Grid container key={key}>
                  <Label type="body1Bold" className={classes.argumentKey}>
                    {key}:
                  </Label>
                  <Label type="body1">{displayData(cleanedArgs[key])}</Label>
                </Grid>
              ))}
            </Grid>
          )}
          {messages && <LiveTaskMessages taskRunId={toolDetail?.taskRunId} messages={messages} hideTitle />}
        </Grid>
      </div>
      <div className={classes.section}>
        <Label type="subtitle" className={classes.subtitle}>
          Output
        </Label>
        <Grid className={classes.sectionContent}>
          <OutputRunStatus output={output} toolDetail={toolDetail} />
          {output && output.details && <TaskOutput success={output?.success} taskOutput={[output.details]} hideTitle />}
          {output && !output.details && <StateMachineOutput stateMachineOutput={output} hideTitle hideDownload />}
        </Grid>
      </div>
    </>
  );
};

export default ToolDetail;
