// NOTE: This settings file is intended to have anything that's related to affinities and their
// settings that are not consumed from a frontend.

type BlockMonolinePoliciesByStateAndAffinityRegex = {
  [state: string]: Array<{ regex: RegExp; policyTypes: string[] }> | undefined;
};

// This constant is to block specified affinities from selling the specified monoline policies on the specified states
export const blockMonolinePoliciesByStateAndAffinityRegex: BlockMonolinePoliciesByStateAndAffinityRegex = {
  // Country wide monoline blocks
  '*': [
    { regex: /^ZB1.*/, policyTypes: ['H', 'A', 'C'] },
    { regex: /^GU0001.*$/, policyTypes: ['H', 'A', 'C'] },
    { regex: /^MATIC1.*$/, policyTypes: ['H', 'A', 'C'] },
    { regex: /^TRELC.*$/, policyTypes: ['H', 'A', 'C'] }
  ]
};

// This constant blocks specific lines (both bundles and single policies) from being sold on specific states
export const blockLinesByStateAndAffinityRegex = {
  MI: [{ regex: /^GH3.*$/, policyTypes: ['A'] }]
};

// This configuration is to completely block this affinities to quote in the states that are specified
// This check is executed at the rater before doing any of the data pulls
export const blockStatesForAffinity = {
  MD: [
    'TRELC',
    'TRELCD',
    'TRELCR',
    'TRELCRD',
    'TRELC_1',
    'TRELC_2',
    'TRELC_3',
    'TRELC_4',
    'TRELC_5',
    'TRELC_6',
    'TRELC_7',
    'AMEX1',
    /^GU0001.*/
  ],
  MI: ['AMEX1', /^GH1.*/, /^GH2.*/, /^GU0001.*/, 'TRELC'],
  DC: [/^GU0001.*/, 'TRELC'],
  GA: [/^GU0001.*/, 'TRELC'],
  LA: [/^GU0001.*/, 'TRELC'],
  MS: [/^GU0001.*/, 'TRELC'],
  ND: [/^GU0001.*/, 'TRELC'],
  OK: [/^GU0001.*/, 'TRELC'],
  PA: [/^GU0001.*/, 'TRELC'],
  TX: [/^GU0001.*/, 'TRELC'],
  WV: [/^GU0001.*/, 'TRELC'],
  // Country wide blocks
  '*': [/^WAY0001.*/, /^QWZE.*/, /^QWZS.*/, /^JE0003.*/, 'SALTY', 'SALTYP']
};

export const defaultHomeJustPurchasedAffinityRegex = /^GH.*/;
