import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 4),
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colors.white,
    justifyContent: 'center'
  }
});

export default makeStyles(styles);
