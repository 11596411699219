import { isSameDay, isAfter, isBefore, formatISO } from 'date-fns';
import toDate from 'date-fns-tz/toDate';

export const getDateViolations = ({ isEdit, policy, segment, startDate, minDate, maxDate, toast }) => {
  const dateViolations = {
    BEFORE_MIN_DATE: isBefore(startDate, minDate) && !isSameDay(startDate, minDate),
    AFTER_POLICY_END_DATE: isAfter(startDate, toDate(policy?.endDate)),
    START_DATE_ALREADY_EXISTS:
      isEdit &&
      policy?.segments.find(
        (segmentFromList) =>
          segmentFromList.segmentId !== segment.segmentId &&
          isSameDay(toDate(segmentFromList.startDate), toDate(startDate))
      ),
    START_DATE_AFTER_NEXT_SEGMENT_START_DATE:
      isAfter(startDate, maxDate) && !isSameDay(toDate(policy.effectiveDate), startDate)
  };

  const foundViolation = Object.keys(dateViolations).find((violation) => dateViolations[violation]);

  if (foundViolation) {
    const dateViolationMessages = ({ startDateFormatted, endDateFormatted, minDateFormatted }) => {
      return {
        BEFORE_MIN_DATE: `Please choose a date later than or equal to ${minDateFormatted[1]}/${minDateFormatted[2]}/${minDateFormatted[0]}`,
        AFTER_POLICY_END_DATE: `Please choose a date earlier than the policy term's end date: ${endDateFormatted[1]}/${endDateFormatted[2]}/${endDateFormatted[0]}`,
        START_DATE_ALREADY_EXISTS: `Segment with start date ${startDateFormatted[1]}/${startDateFormatted[2]}/${startDateFormatted[0]} already exists.`,
        START_DATE_AFTER_NEXT_SEGMENT_START_DATE: `Please choose a date before the start date of the next segment.`
      };
    };

    toast.notify({
      type: 'error',
      message: dateViolationMessages({
        startDateFormatted: formatISO(startDate).split('T')[0].split('-'),
        endDateFormatted: policy.endDate.split('-'),
        minDateFormatted: formatISO(minDate).split('T')[0].split('-')
      })[foundViolation]
    });
    return true;
  }
  return false;
};
