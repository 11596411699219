import React from 'react';

import { Label } from 'core/components/label';
import useStyles from './modal-header.styles';

const ModalHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Label type="h1">Are you sure this is a custom lender?</Label>
      <div className={classes.description}>
        <Label type="body1" className={classes.title}>
          Review and select the lender we should use for the mortgage
        </Label>
        <Label type="body2" className={classes.subtitle}>
          We have existing lenders in our system that may match the custom lender you’ve provided.
        </Label>
      </div>
    </div>
  );
};

export default ModalHeader;
