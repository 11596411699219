import { useMemo } from 'react';

const useErrors = (errors, compareFn) => {
  return useMemo(() => {
    if (!errors) {
      return [];
    }
    return errors.filter((err) => compareFn(Number(err.code)));
  }, [errors, compareFn]);
};

export default useErrors;
