import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },
  icon: {
    height: 24,
    width: 30
  },
  label: {
    padding: '-10px 0px',
    margin: '-10px 0px'
  },
  tooltip: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.colorPalette.green_10,
    fontSize: 10
  },
  button: {
    padding: 0,
    height: 24,
    '&:hover': {
      background: theme.colorPalette.orange_10_op_08
    }
  }
});

export default makeStyles(styles);
