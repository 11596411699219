import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Dialog, DialogTitle, DialogContent, Button, Grid } from '@material-ui/core';

import { lookupsJson } from '@ourbranch/lookups';

import { Card } from 'core/components/card';
import { ActionButton } from 'core/components/action-button';
import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import { getObjectValueById } from 'core/helpers/formatters';
import useStyles from './mismatch-billing-details-modal.styles';

const MismatchBillingDetails = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  const {
    renewalPaymentMethod,
    renewalPaymentType,
    paymentMethod: currentPaymentMethod,
    paymentType: currentPaymentType
  } = values;
  // honeownersPaymentMethod contains the list of all payment methods
  const { homeownersPaymentMethod, paymentType } = lookupsJson;

  const handleKeepSeparate = () => {
    onClose();
    onSubmit();
  };

  const onUpdate = () => {
    if (renewalPaymentMethod !== currentPaymentMethod) {
      setFieldValue('renewalPaymentMethod', currentPaymentMethod);
    }
    if (renewalPaymentType !== currentPaymentType) {
      setFieldValue('renewalPaymentType', currentPaymentType);
    }
    onClose();
    onSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label type="titleSecondary" className={classes.title}>
          Billing details mismatch
        </Label>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Label type="infoInnerTitleBold" className={classes.text}>
          We noticed a mismatch between this policy’s current and renewal term billing details.
        </Label>
        <Grid container>
          <Grid item xs={6} className={classes.detailsContent}>
            <Label type="infoInnerTitleBold">Current billing details</Label>
            {currentPaymentMethod !== renewalPaymentMethod && (
              <ValueField
                label="Payment Method"
                value={getObjectValueById(homeownersPaymentMethod, currentPaymentMethod)}
              />
            )}
            {currentPaymentType !== renewalPaymentType && (
              <ValueField label="Payment Frequency" value={getObjectValueById(paymentType, currentPaymentType)} />
            )}
          </Grid>
          <Grid item xs={6} className={classes.detailsContent}>
            <Label type="infoInnerTitleBold">Renewal billing details</Label>
            {currentPaymentMethod !== renewalPaymentMethod && (
              <ValueField
                label="Payment Method"
                value={getObjectValueById(homeownersPaymentMethod, renewalPaymentMethod)}
              />
            )}
            {currentPaymentType !== renewalPaymentType && (
              <ValueField label="Payment Frequency" value={getObjectValueById(paymentType, renewalPaymentType)} />
            )}
          </Grid>
        </Grid>
        <Card type="modal" className={classes.card}>
          <Label type="infoInnerTitle">How would you like to proceed?</Label>
          <Grid container className={classes.btnContainer}>
            <Button onClick={handleKeepSeparate} variant="contained" color="secondary" className={classes.btn}>
              Keep separate
            </Button>
            <Button onClick={onUpdate} variant="contained" color="primary" className={classes.btn}>
              Update renewal billing to match
            </Button>
          </Grid>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

MismatchBillingDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MismatchBillingDetails;
