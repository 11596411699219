import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { allowBindUDRStates } from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';
import { offerHasValidOptions } from 'core/helpers/underwriting-form-helpers';
import FillSkippedDataPulls from './components/fill-skipped-data-pulls';
import OfferOutOfDate from './components/offer-out-of-date';
import CheckAutoIncidents from './components/check-auto-incidents';
import UpdateOffer from './components/update-offer';
import UWForm from './components/UW-form';
import Checkout from './components/checkout';
import InvalidFooter from './components/invalid-footer';
import useStyles from './footer.styles';

const Footer = observer(({ showValidationErrorsModal, setShowValidationErrorsModal, hasDisabledAffinity }) => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useToast();
  const session = useContext(AuthContext);
  const { handleSubmit, dirty, values, validateForm, setErrors, setFieldValue, setFieldTouched, setTouched } =
    useFormikContext();
  const { offer: store } = useStore();

  const agentIsLicensedForState = store.getIsLicensedForState(session);
  const { cars, drivers } = store.offer.quote;
  const { setShowUDRModal } = store;
  const optionsAvailable = offerHasValidOptions({
    offer: store?.offer,
    priorQuoteWithPreBindUWRejections: store.priorQuoteWithPreBindUWRejections
  });

  const needToUpdateMVRs =
    !dirty &&
    values.selectedOption &&
    values.selectedOption.includes('A') &&
    drivers.some((driver) => driver.postBindMVR);
  const hasInvalidVins = cars.some((car) => String(car.make).startsWith('*'));

  const needToFillSkippedData =
    !store.isStale && !hasInvalidVins && !dirty && store.offer && store.offer.skippedDataPulls?.length > 0;

  const hasUDR = drivers.some((driver) => driver.autoViolations?.UDR >= 1) && !allowBindUDRStates.includes(store.state);

  const onCheckout = useCallback(async () => {
    const checkoutErrors = await validateForm();
    if (Object.keys(checkoutErrors).length > 0) {
      setErrors(checkoutErrors);
      setTouched(checkoutErrors);
      setShowValidationErrorsModal(true);
    } else if (hasUDR) {
      setShowUDRModal(true);
    } else {
      history.push(`/offer/${store.offer.id}/${values.selectedOption}/checkout`);
    }
  }, [
    history,
    setErrors,
    setTouched,
    store.offer.id,
    values,
    setShowValidationErrorsModal,
    setShowUDRModal,
    validateForm
  ]);

  const onContinueToUWForm = useCallback(async () => {
    const updateErrors = await validateForm();
    if (Object.keys(updateErrors).length > 0) {
      setErrors(updateErrors);
      setTouched(updateErrors);
      setShowValidationErrorsModal(true);
    } else if (hasUDR) {
      setShowUDRModal(true);
    } else {
      history.push(`/offer/${store.offer.id}/verifyUW`);
    }
  }, [history, setShowValidationErrorsModal, setShowUDRModal, validateForm, setErrors, setTouched, store.offer.id]);

  const onUpdate = async () => {
    if (!needToFillSkippedData) {
      const updateErrors = await validateForm();
      if (Object.keys(updateErrors).length > 0) {
        setErrors(updateErrors);
        setTouched(updateErrors);
        setShowValidationErrorsModal(true);
      } else if (needToUpdateMVRs) {
        setFieldValue('getMVRs', true);
        setFieldTouched('getMVRs', true);
        handleSubmit();
      } else {
        handleSubmit();
      }
    }
  };

  const copyLink = () => {
    const { protocol, host } = window.location;
    const newHost = host.replace(/staff\./i, 'www.'); // staff.ourbranch.com -> www.ourbranch.com, for example

    const selectedOption = values.selectedOption;

    let policyType;
    if (selectedOption === 'HB' || selectedOption === 'AB') {
      // if we're giving a bundled price, link to the bundle
      policyType = 'HA';
    } else {
      policyType = selectedOption;
    }

    const link = store.offer.clusterId
      ? `${protocol}//${newHost}/review?cid=${store.offer.clusterId}&planType=${store.offer.code}&policyType=${policyType}&internal=true`
      : `${protocol}//${newHost}/quote/detail/${store.offer.id}`;
    const copied = copy(link);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'The link is copied to your clipboard. This link is for customer use ONLY.'
      });
    } else {
      toast.notify({
        type: 'error',
        message: `We could not copy the link, but here it is: ${link}`
      });
    }
  };

  // Agents cannot update or checkout offers for states they are not licensed in, so don't show footer
  if (!agentIsLicensedForState) {
    return null;
  }

  return (
    <div className={classes.floatingContainer}>
      <div
        className={classNames(classes.footerContainer, {
          [classes.footerAlert]: store.isStale || !optionsAvailable || hasDisabledAffinity
        })}
      >
        <Grid container alignItems="center" className={classes.footerContent}>
          {hasDisabledAffinity ? (
            <InvalidFooter text="Sorry, you can't update the offer because your affinity code is disabled." />
          ) : (
            (store.isStale && <OfferOutOfDate onUpdate={onUpdate} />) ||
            (!optionsAvailable && <InvalidFooter text="There are no valid policy options for this offer." />) ||
            (needToFillSkippedData && <FillSkippedDataPulls />) ||
            (needToUpdateMVRs && <CheckAutoIncidents onUpdate={onUpdate} copyLink={copyLink} />) ||
            (dirty && <UpdateOffer onUpdate={onUpdate} />) ||
            (store.needsPreBindUWForm && (
              <UWForm
                showValidationErrorsModal={showValidationErrorsModal}
                copyLink={copyLink}
                onContinueToUWForm={onContinueToUWForm}
              />
            )) ||
            (!dirty && (
              <Checkout
                showValidationErrorsModal={showValidationErrorsModal}
                buttonLabel={hasInvalidVins ? 'Validate VIN(s)' : 'Continue to Checkout'}
                copyLink={copyLink}
                onCheckout={onCheckout}
              />
            ))
          )}
        </Grid>
      </div>
    </div>
  );
});

Footer.propTypes = {
  showValidationErrorsModal: PropTypes.bool.isRequired,
  setShowValidationErrorsModal: PropTypes.func.isRequired
};

export default Footer;
