import React from 'react';
import { observer } from 'mobx-react';
import { CircularProgress, Card, Grid } from '@material-ui/core';
import { connect } from 'formik';
import { getSnapshot } from 'mobx-state-tree';

import { useStore } from 'core/store';
import { useQuery, gql } from '@apollo/client';
import useSession from 'core/hooks/use-session';
import useErrors from 'common/hooks/use-errors';
import { PolicyErrors } from './components/policy-errors';
import { Additionals } from './components/additionals';
import { DiscountsCard } from './components/discounts-card';
import { AdditionalOffersSection } from './components/additional-offers-section';
import { PriceDetailsCard } from './components/price-details-card';
import { PolicyPickerCard } from './components/policy-picker-card';
import { Downloads } from './components/downloads';
import useStyles from './sidebar.styles';

const Sidebar = observer(() => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const { isAgency, isExternalSales, canBind } = useSession();
  const getErrorsAndRejections = (code) => code >= 4003;
  const errorsAndRejections = useErrors(getSnapshot(store.errors), getErrorsAndRejections);

  const shouldShowBranchTermLifeAdditionalOffer = Boolean(
    store?.offer?.isAgentSold && !isAgency && !isExternalSales && canBind
  );

  const assurityLifeEligibilityQuery = useQuery(
    gql`
      query ($offerId: String) {
        assurityLifeCheckEligibility(args: { branchOfferId: $offerId }) {
          __typename
          ... on AssurityLifeCheckEligibilitySuccessResponse {
            staffUserCapabilities
            benefitAmount
            monthlyPremiumAmount
            annualPremiumAmount
          }
        }
      }
    `,
    { variables: { offerId: store?.offer?.id }, skip: !store?.offer?.id }
  );

  const shouldShowAssurityTermLifeAdditionalOffer = Boolean(
    !assurityLifeEligibilityQuery.loading &&
      assurityLifeEligibilityQuery.data?.assurityLifeCheckEligibility.__typename ===
        'AssurityLifeCheckEligibilitySuccessResponse'
  );

  const shouldShowAdditionalOffers =
    shouldShowBranchTermLifeAdditionalOffer || shouldShowAssurityTermLifeAdditionalOffer;

  const isLoading = store.loading || !store.offer;

  if (isLoading) {
    return (
      <>
        <div className={classes.columnHeader} />
        <Card className={classes.container}>
          <Grid container className={classes.loaderContainer} alignItems="center" justifyContent="center">
            <CircularProgress className={classes.loader} />
          </Grid>
        </Card>
      </>
    );
  }

  return (
    <div className={classes.sidebar}>
      <div className={classes.columnHeader} />
      <PolicyPickerCard />
      <PriceDetailsCard />
      <DiscountsCard />
      {store.shouldShowAdditionalPaymentsSection && <Additionals />}
      {errorsAndRejections.length > 0 && <PolicyErrors />}
      {shouldShowAdditionalOffers && (
        <AdditionalOffersSection
          branchTermLife={shouldShowBranchTermLifeAdditionalOffer}
          assurityTermLife={shouldShowAssurityTermLifeAdditionalOffer}
          assurityTermLifeData={{
            ...assurityLifeEligibilityQuery.data?.assurityLifeCheckEligibility,
            branchOfferId: store?.offer?.id
          }}
        />
      )}
      <Downloads />
    </div>
  );
});

export default connect(Sidebar);
