import { nodePathToFriendlyName, cancelCodeInformationalArray } from '@ourbranch/lookups';
import up from 'core/assets/svg/up.svg';
import down from 'core/assets/svg/down.svg';
import { toCapitalized } from 'core/helpers/formatters';

export function getPriceDetails({ newTotalPrice }, total) {
  if (newTotalPrice > total) {
    return {
      arrow: up,
      label: 'increase'
    };
  }
  if (newTotalPrice < total) {
    return {
      arrow: down,
      label: 'decrease'
    };
  }
  return {
    arrow: null,
    label: 'not change'
  };
}

export function parsePreviewChanges(changes) {
  const changedNodePaths = changes.split(',');
  return changedNodePaths.map((nodePath) => nodePathToFriendlyName[nodePath] ?? toCapitalized(nodePath)).join(', ');
}

export function determineBoxTwoText(cancelReason) {
  return cancelCodeInformationalArray.find((cancelCodeObject) => cancelCodeObject.cancelCode === cancelReason)
    .boxTwoText;
}

export function canAddToInternalNote(cancelReason) {
  return !!cancelCodeInformationalArray.find((cancelCodeObject) => cancelCodeObject.cancelCode === cancelReason)
    .allowFreeFormNote;
}

export const getButtonLabel = (action) => {
  if (action === 'rescind') {
    return 'Rescind cancellation';
  }
  if (action === 'reinstate') {
    return 'Reinstate policy';
  }
  if (action === 'cancel') {
    return 'Cancel policy';
  }
  return 'Save policy changes';
};

const isValuePresentInObject = (keys, obj, value) => {
  for (const key of keys) {
    if (obj.hasOwnProperty(key) && obj[key] === value) {
      return true;
    }
  }
  return false;
};

export const getNotes = ({ action, cancelReason, nonRenewReason, itemsChanged, diff }) => {
  const cancelBoxTwoText =
    action === 'cancel' &&
    cancelReason !== 'NA' &&
    (itemsChanged?.includes('cancelReason') || itemsChanged?.includes('endDate'))
      ? determineBoxTwoText(cancelReason)
      : undefined;

  if (cancelBoxTwoText) {
    //  some pre-determined notes based on cancel reasons cannot be edited
    const disableInternalNote = cancelBoxTwoText && !canAddToInternalNote(cancelReason);
    return { notesText: cancelBoxTwoText, cancelBoxTwoText, disableInternalNote };
  }

  const nonRenewBoxTwoText =
    nonRenewReason && nonRenewReason !== 'NA' && itemsChanged?.includes('autoRenew')
      ? determineBoxTwoText(nonRenewReason)
      : undefined;

  if (nonRenewBoxTwoText) {
    return { notesText: nonRenewBoxTwoText };
  }

  // Garage address rule exception approved
  const diffObj = diff && JSON.parse(diff);
  const addedChanges = diffObj?.full?.addedChanges;
  if (addedChanges) {
    const garageAddressRuleExceptionKeys = Object.keys(addedChanges).filter((k) =>
      k.includes('isGarageAddressRuleExceptionApproved')
    );
    const approvingGarageAddressRuleException = isValuePresentInObject(
      garageAddressRuleExceptionKeys,
      addedChanges,
      true
    );
    if (approvingGarageAddressRuleException) {
      return { notesText: 'Underwriting approved garage address rule exception' };
    }
  }

  return { notesText: '' };
};
