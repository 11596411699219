import React from 'react';
import { useFormikContext } from 'formik';
import { policyTypes } from '@ourbranch/policy-types';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import NotificationIcon from 'core/assets/svg/info-light.svg';
import { NotificationCard } from 'core/components/notification-card';
import { FormField } from 'core/components/form';
import { useStore } from 'core/store/store.mobx';
import { autoMultiPolicyDiscountOptionsForOffer, homeMultiPolicyDiscountOptionsForOffer } from 'core/helpers/lookups';
import useStyles from './calculate-with-mpd.styles';

const CalculateWithMPD = () => {
  const { values, errors } = useFormikContext();
  const { selectedOption } = values;
  const { offer: store } = useStore();
  const classes = useStyles();

  const isBundle = [policyTypes.ARBundle, policyTypes.HABundle, policyTypes.CABundle, 'AB', 'HB'].includes(
    selectedOption
  );

  const existingMemberLinked = values?.existingCustomer?.id;

  const options =
    values?.selectedOption === policyTypes.Auto
      ? autoMultiPolicyDiscountOptionsForOffer
      : homeMultiPolicyDiscountOptionsForOffer;

  const optionsWithOnlyNoneAvailable = options.map((option) => {
    if (option.id !== 'N') {
      return { ...option, disabled: true };
    }
    return option;
  });

  const noRejections = store.offer?.rejections?.length === 0;
  const disable = !errors.multiPolicyDiscount && (isBundle || !existingMemberLinked || noRejections);

  return (
    <Grid className={classes.container}>
      <FormField
        type="select"
        name="forcedMultiPolicyDiscount"
        label="Multiline Discount"
        options={errors.multiPolicyDiscount ? optionsWithOnlyNoneAvailable : options}
        mode="dark"
        disabled={disable}
        fast={false}
      />
      {disable && (
        <NotificationCard icon={NotificationIcon} type="tertiary">
          Must have existing member linked, monoline offer type selected, and have a rejection to alter Multiline
          Discount
        </NotificationCard>
      )}
    </Grid>
  );
};

export default observer(CalculateWithMPD);
