import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import ValueField from 'core/components/value-field';
import useStyles from './preview-notification-card.styles';
import NotificationIconGreen from 'core/assets/svg/info-green-blank-bg.svg';

const PreviewNotificationCard = ({ title, mainText, refundWarning }) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer}>
      <img src={NotificationIconGreen} className={classes.iconBig} alt="Notification Icon" />
      <Grid className={title ? classes.notificationContentWithTitle : classes.notificationContentNoTitle}>
        {title && (
          <Grid className={classes.textContainer}>
            <p className={classes.titleText}>{title}</p>
          </Grid>
        )}

        {mainText && <ValueField value={mainText} />}

        {refundWarning && (
          <ValueField
            value={
              <div>
                Unless this is an extenuating circumstance, please go back and UNCHECK the &quot;Delay
                Payment/Refund&quot; option.
              </div>
            }
          />
        )}
      </Grid>
    </Card>
  );
};

export default PreviewNotificationCard;
