import React from 'react';
import {
  getOptions,
  coverageFOffered,
  coverageCANotOffered,
  coverageDRNotOffered,
  coverageMINotOffered,
  coverageSTNotOffered,
  coverageSPNotOffered,
  coverageFairRentalNotOffered,
  coverageYardAndGardenNotOffered,
  coverageHomeownerProtectionNotOffered
} from '@ourbranch/lookups';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';

import { getShowDayCare, getShowSinkhole, getShowOilStorageTank } from 'offer/helpers/get-coverage';
import Field from 'core/components/form/form.v2';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { NotificationCard } from 'core/components/notification-card';

import useStyles from '../coverages.styles';

function AdditionalCoverages({ state, id }) {
  const classes = useStyles();

  const offerF = coverageFOffered[state];
  const showCoverageDR = !coverageDRNotOffered[state];
  const showFairRental = !coverageFairRentalNotOffered[state];
  const showYardAndGarden = !coverageYardAndGardenNotOffered[state];
  const showHomeownerProtection = !coverageHomeownerProtectionNotOffered[state];
  const showCoverageCA = !coverageCANotOffered[state];
  const showCoverageMI = !coverageMINotOffered[state];
  const showCoverageST = !coverageSTNotOffered[state];
  const showCoverageSP = !coverageSPNotOffered[state];
  const showDayCare = getShowDayCare(state);
  const showSinkhole = getShowSinkhole(state);
  const showOilStorageTank = getShowOilStorageTank(state);

  return (
    <Grid className={classes.additionalCoverages}>
      <div className={classes.cardSubSectionDivider}>
        <Label type="formSubTitle" className={classes.cardSubSectionTitle}>
          Building coverages
        </Label>

        <Grid container item>
          {offerF && (
            <Field
              id={`${id}.coverageF`}
              name={`${id}.coverageF`}
              type="select"
              label="Fire Department Charges"
              mode="dark"
              options={getOptions('coverageF', state)}
              xs={6}
            />
          )}
          <Grid container>
            {showCoverageDR && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.coverageDR`}
                  name={`${id}.coverageDR`}
                  type="checkbox"
                  label="Electronic Data Recovery"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.coverageDR}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Field
                id={`${id}.coverageBuildingMaterialsTheft`}
                name={`${id}.coverageBuildingMaterialsTheft`}
                type="checkbox"
                label="Building Materials"
                mode="dark"
                tooltipText={tooltipHoverTexts.coverageBuildingMaterialsTheft}
                tooltipLabel="More Info"
              />
            </Grid>
            {showFairRental && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.coverageFairRental`}
                  name={`${id}.coverageFairRental`}
                  type="checkbox"
                  label="Fair Rental Income"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.coverageFairRental}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}
            {showYardAndGarden && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.coverageYardAndGarden`}
                  name={`${id}.coverageYardAndGarden`}
                  type="checkbox"
                  label="Yard and Garden"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.coverageYardAndGarden}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Field
                id={`${id}.coverageExtendedPremises`}
                name={`${id}.coverageExtendedPremises`}
                type="checkbox"
                label="Extended Premises"
                mode="dark"
                tooltipText={tooltipHoverTexts.coverageExtendedPremises}
                tooltipLabel="More Info"
              />
            </Grid>
            {showHomeownerProtection && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.homeownerProtection`}
                  name={`${id}.homeownerProtection`}
                  type="checkbox"
                  label="Host Protection"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.homeownerProtection}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}
            {showDayCare && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.coverageDC`}
                  name={`${id}.coverageDC`}
                  type="checkbox"
                  label="Day Care"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.coverageDC}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}

            {showSinkhole && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.coverageSinkhole`}
                  name={`${id}.coverageSinkhole`}
                  type="checkbox"
                  label="Sinkhole"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.coverageSinkhole}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}
            {showOilStorageTank && (
              <Grid item xs={6}>
                <Field
                  id={`${id}.coverageOilStorageTank`}
                  name={`${id}.coverageOilStorageTank`}
                  type="checkbox"
                  label="Oil Storage Tank"
                  mode="dark"
                  tooltipText={tooltipHoverTexts.coverageOilStorageTank}
                  tooltipLabel="More Info"
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <NotificationCard className={classes.notification} type="infoWhite" noBackground iconSize="icon16">
          <Label className={classes.notificationCardText}>
            Additional included home coverages vary by state and may not be listed here. Refer to your application or
            policy documents for full details.
          </Label>
        </NotificationCard>
      </div>

      <div className={classes.cardSubSectionDivider} justifyContent="flex-start" alignItems="center" spacing={2}>
        <Label type="formSubTitle" className={classes.cardSubSectionTitle}>
          Property Coverages
        </Label>

        <Grid container item spacing={4}>
          <Grid item xs={4}>
            <Field
              id={`${id}.coverageBP`}
              name={`${id}.coverageBP`}
              type="select"
              label="Business Property"
              mode="dark"
              options={getOptions('coverageBP', state)}
            />
          </Grid>

          <Grid item xs={4}>
            <Field
              id={`${id}.coverageJ`}
              name={`${id}.coverageJ`}
              type="select"
              label="Jewelry, Watches, and Furs"
              mode="dark"
              options={getOptions('coverageJ', state)}
            />
          </Grid>

          <Grid item xs={4}>
            {showCoverageCA && (
              <Field
                id={`${id}.coverageCA`}
                name={`${id}.coverageCA`}
                type="select"
                label="Cameras"
                mode="dark"
                options={getOptions('coverageCA')}
              />
            )}
          </Grid>

          <Grid item xs={4}>
            {showCoverageMI && (
              <Field
                id={`${id}.coverageMI`}
                name={`${id}.coverageMI`}
                type="select"
                label="Musical Instruments"
                mode="dark"
                options={getOptions('coverageMI', state)}
              />
            )}
          </Grid>

          <Grid item xs={4}>
            {showCoverageST && (
              <Field
                id={`${id}.coverageST`}
                name={`${id}.coverageST`}
                type="select"
                label="Silverware Theft"
                mode="dark"
                options={getOptions('coverageST', state)}
              />
            )}
          </Grid>

          <Grid item xs={4}>
            {showCoverageSP && (
              <Field
                id={`${id}.coverageSP`}
                name={`${id}.coverageSP`}
                type="select"
                label="Sports Equipment"
                mode="dark"
                options={getOptions('coverageSP', state)}
              />
            )}
          </Grid>
        </Grid>

        <NotificationCard type="infoWhite" noBackground iconSize="icon16">
          <>
            <Label className={classes.notificationCardText}>For extended property coverage, add </Label>
            <Button
              className={classes.sppLinkButton}
              type="textSecondary"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('sppContainer').scrollIntoView({ block: 'center', behavior: 'smooth' });
              }}
            >
              <Label>scheduled personal property.</Label>
            </Button>
          </>
        </NotificationCard>
      </div>
    </Grid>
  );
}

export default observer(AdditionalCoverages);
