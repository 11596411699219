export const CognitoPermissionGroups = {
  // Multi feature access
  isService: 'isService',
  isSales: 'isSales',
  isInternalSales: 'isInternalSales',
  isExternalSales: 'isExternalSales',
  isTeamLeader: 'isTeamLeader',
  isAgency: 'isAgency',
  isInternalAgent: 'isInternalAgent',
  isUnlicensed: 'isUnlicensed', // completely unlicensed, no access to quote in any state

  // Individual feature access
  canQuote: 'canQuote',
  canEditPolicies: 'canEditPolicies',
  canEditOffers: 'canEditOffers',
  canViewPolicies: 'canViewPolicies',
  canViewOffers: 'canViewOffers',
  canEdit: 'canEdit',
  canBind: 'canBind',
  canViewCheckoutPage: 'canViewCheckoutPage',
  canReinstate: 'canReinstate',
  canRescindCancellation: 'canRescindCancellation',
  canSeeFullOfferDetails: 'canSeeFullOfferDetails',
  canScrubIncidents: 'canScrubIncidents',
  canModifyAffinityAndLeadSource: 'canModifyAffinityAndLeadSource',
  canBackDate: 'canBackDate',
  canUploadDocuments: 'canUploadDocuments',
  canModifyBillingId: 'canModifyBillingId',
  canViewClarionDoorData: 'canViewClarionDoorData',
  canAddCarsManually: 'canAddCarsManually',
  canAutoRenew: 'canAutoRenew',
  canAddHoldCards: 'canAddHoldCards',
  canChangeExclusions: 'canChangeExclusions',
  canToggleEmployeeDiscount: 'canToggleEmployeeDiscount',
  canClearUDRs: 'canClearUDRs',
  viewOnly: 'viewOnly',
  canEditHomeAddress: 'canEditHomeAddress',
  canEditEffectiveDate: 'canEditEffectiveDate',
  canFlagAsSuspicious: 'canFlagAsSuspicious',
  canManuallyChargeFee: 'canManuallyChargeFee',
  canQuoteQuinstreet: 'canQuoteQuinstreet',
  canClearUWFormRejections: 'canClearUWFormRejections',
  canManuallyChargePolicy: 'canManuallyChargePolicy',
  canRewritePolicy: 'canRewritePolicy',
  canRemoveExcludedDriver: 'canRemoveExcludedDriver',
  canCancelReasonCNIP: 'canCancelReasonCNIP',
  canCancelReasonCNMS: 'canCancelReasonCNMS',
  canCancelReasonCNPY_X: 'canCancelReasonCNPY_X',
  canRejectPIP: 'canRejectPIP',
  showAgencyPortal: 'showAgencyPortal',
  canViewQlikReports: 'canViewQlikReports',
  canDownloadAnonDocs: 'canDownloadAnonDocs',
  canReinstateCNPY: 'canReinstateCNPY',
  canToggleCreditCheck: 'canToggleCreditCheck',
  canEditBillingDay: 'canEditBillingDay',
  canToggleCHDiscount: 'canToggleCHDiscount',
  canToggleInventoryDiscount: 'canToggleInventoryDiscount',
  canEditPolicyMPD: 'canEditPolicyMPD',
  canDelayPaymentOrRefund: 'canDelayPaymentOrRefund',
  canManageBillingHolds: 'canManageBillingHolds',
  canSendPaperDocs: 'canSendPaperDocs',
  canEditFeeCheckbox: 'canEditFeeCheckbox',
  canOverrideGaragingAddress: 'canOverrideGaragingAddress',
  canSwapApplicants: 'canSwapApplicants',
  canViewCarrierInitiatedCancels: 'canViewCarrierInitiatedCancels',
  canSellAndService: 'canSellAndService',
  canCancelReasonNACP: 'canCancelReasonNACP',
  canEditUneditablePropertyDetailsFields: 'canEditUneditablePropertyDetailsFields',
  canResetOnboardingTasks: 'canResetOnboardingTasks',
  canViewUWCancelCodes: 'canViewUWCancelCodes',
  canToggleIsAgencyPolicy: 'canToggleIsAgencyPolicy',
  canSkipEmailConfirmationSplashPage: 'canSkipEmailConfirmationSplashPage',
  canRefundStripePayment: 'canRefundStripePayment',
  canRescindOrReinstateGSNICPolicy: 'canRescindOrReinstateGSNICPolicy',
  canRemoveFinalPaymentMethod: 'canRemoveFinalPaymentMethod',
  canBypassDriversLicenseCheck: 'canBypassDriversLicenseCheck',
  // tools permissions
  canViewTools: 'canViewTools',
  canViewAffinityTools: 'canViewAffinityTools',
  canViewStaffDashboard: 'canViewStaffDashboard',
  canResetStaffUserPassword: 'canResetStaffUserPassword',
  canCreateStripeCharge: 'canCreateStripeCharge',
  canCreateChargedeskInvoice: 'canCreateChargedeskInvoice',
  canBulkDisableStaffUsers: 'canBulkDisableStaffUsers',
  canCreateChargedeskRefundInvoice: 'canCreateChargedeskRefundInvoice',
  canRegeneratePolicyForm: 'canRegeneratePolicyForm',
  canUpdateChargedeskRecord: 'canUpdateChargedeskRecord',
  canUpdateChargedeskRecordPolicyOrBillingId: 'canUpdateChargedeskRecordPolicyOrBillingId',
  canGenerateRenewal: 'canGenerateRenewal',
  canRunRenewalUnderwriting: 'canRunRenewalUnderwriting',
  canUpdateStaffUser: 'canUpdateStaffUser',
  canDisableStaffUser: 'canDisableStaffUser',
  canViewEditGroups: 'canViewEditGroups',
  canRunAddressesThroughCherre: 'canRunAddressesThroughCherre',
  canUpdatePolicyRep: 'canUpdatePolicyRep',
  canAddPermissionGroupsToStaffUsers: 'canAddPermissionGroupsToStaffUsers',
  canCreateStaffUser: 'canCreateStaffUser',
  canCreateFrontTicket: 'canCreateFrontTicket',
  canViewStateRateChangeDashboard: 'canViewStateRateChangeDashboard',
  canImportPoliciesToSnapsheet: 'canImportPoliciesToSnapsheet',
  canImportPoliciesToIvans: 'canImportPoliciesToIvans',
  canGetIvansFormattedPolicy: 'canGetIvansFormattedPolicy',
  canRegeneratePolicyCancelForm: 'canRegeneratePolicyCancelForm',
  canAddCatTagsToClaims: 'canAddCatTagsToClaims',
  canBulkBillCopartRecoveries: 'canBulkBillCopartRecoveries',
  canBulkBillBees: 'canBulkBillBees',
  canBulkBillPilot: 'canBulkBillPilot',
  canBulkBillFleetSubrogation: 'canBulkBillFleetSubrogation',
  canRunBillHonk: 'canRunBillHonk',
  canRunBillHover: 'canRunBillHover',
  canCopyPolicy: 'canCopyPolicy',
  canSetMemberAffinity: 'canSetMemberAffinity',
  canEnableStaffUser: 'canEnableStaffUser',
  canResetPasskeys: 'canResetPasskeys',
  canRunTechTasks: 'canRunTechTasks',
  canPullDrivingHistoryFromCSV: 'canPullDrivingHistoryFromCSV',
  canSetHasAutoPolicy: 'canSetHasAutoPolicy',
  canUpdateBillingId: 'canUpdateBillingId',
  canOverrideUmbrellaLimit: 'canOverrideUmbrellaLimit',
  canChangeOfferAffinity: 'canChangeOfferAffinity',
  canBulkBillEnterprise: 'canBulkBillEnterprise',
  canBulkBillMedlogix: 'canBulkBillMedlogix',
  canBulkBillHancock: 'canBulkBillHancock',
  canAddViolationToOffer: 'canAddViolationToOffer',
  canBulkBillAccuserve: 'canBulkBillAccuserve',
  canUpdateStaffUsername: 'canUpdateStaffUsername',
  canEditCoverageA: 'canEditCoverageA',

  // featureToggles
  canViewSoftRejections: 'canViewSoftRejections'
};

export const PermissionRequireTypes = {
  All: 'all',
  AtLeastOne: 'atLeastOne'
};
