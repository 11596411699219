const styles = (theme) => ({
  collapse: {
    marginBottom: 5,
    padding: 3,
    width: 'calc(100% + 6px)',
    marginLeft: -3
  },
  container: {
    position: 'relative',
    minHeight: 64,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible'
  },
  header: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.sizes.roundCorners
  },
  iconButtonRoot: {
    position: 'absolute',
    color: theme.colors.button__background_secondary,
    padding: 8,
    top: 8,
    right: 8
  },
  iconButtonLabel: {
    height: 32,
    width: 32
  },
  iconFold: {
    display: 'block',
    height: 32,
    width: 32,
    transition: 'transform 200ms'
  },
  icon: {
    composes: '$iconFold',
    transform: 'rotate(180deg)'
  },
  content: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0, 3, 8),
    flexWrap: 'wrap',
    gap: theme.spacing(2)
  },
  enclosedCard: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0, 3, 4),
    flexWrap: 'wrap',
    gap: theme.spacing(2)
  },
  contentRight: {
    right: 56,
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  noBorders: {
    paddingBottom: 0,
    marginBottom: 0,
    paddingTop: 0
  }
});

export default styles;
