import React from 'react';
import { Label } from 'core';

export { default as GenerateTaskUI } from './generate-task-ui/generate-task-ui';

export const displayData = (data) => {
  if (typeof data === 'boolean') {
    return String(data);
  }
  if (!data) {
    return '';
  }
  if (typeof data === 'string') {
    if (data.includes('http')) {
      const label = data.includes('s3') && data.includes('input.csv') ? 'input.csv' : data;
      return (
        <Label component="a" type="action" fontWeight={400} href={data}>
          {label}
        </Label>
      );
    }
    if (data === '1') {
      return 'true';
    }
    if (data === '0') {
      return 'false';
    }

    return data;
  }
  if (Array.isArray(data)) {
    return data.map((r) => (
      <>
        {displayData(r)}
        <br />
      </>
    ));
  }
  if (typeof data === 'object') {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }

  return data;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getUserFriendlyStatus = (status) => {
  switch (status) {
    case 'SUCCESS':
      return 'Success';
    case 'FAILED':
      return 'Failed';
    case 'PARTIAL_SUCCESS':
      return 'Partial Success';
    case 'IN_PROGRESS':
      return 'In Progress';
    default:
      return 'Finished';
  }
};

/**
 *  Clean arguments to keep the ones with values
 *
 * @param {*} argsIn
 * @returns
 */
export const cleanArgs = (inputData) => {
  if (inputData?.inputS3Url) {
    return { csv: inputData.inputS3Url };
  }

  let args = inputData?.arguments;
  if (isJson(args)) {
    args = JSON.parse(args);
  }
  if (args && Object.keys(args).length) {
    const cleanedArgs = Object.entries(args).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return cleanedArgs;
  }
  return undefined;
};
