import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Tooltip } from 'core/components/tooltip';

import { SortableTable } from 'core/components/sortable-table';
import { Label } from 'core';
import { currencyFormatter, capitalizeWords, awsDateToDateFormatter } from 'core/helpers/formatters';
import useStyles from '../payment.styles';

const PastPayments = ({ transactions, getBillingPaymentMethod }) => {
  const classes = useStyles();
  const shortenInternalDescription = (internalDescription) => {
    if (internalDescription?.length > 13) {
      return (
        <>
          {`${internalDescription.slice(0, 15)}...`}
          <Tooltip label="See more" text={internalDescription} />
        </>
      );
    }
    return internalDescription;
  };

  const modifiedTransactions = transactions.map((transaction) => {
    const combinedVariables = `${currencyFormatter(transaction.paymentAmount)} / ${currencyFormatter(
      transaction.paymentRefunded
    )}`;
    return { ...transaction, 'paymentAmount/paymentRefunded': combinedVariables };
  });

  return (
    <div className={classes.sectionContainer}>
      <Grid container alignItems="center" justifyContent="flex-start" className={classes.rowTitle}>
        <Label type="coverageValue">Past Payments</Label>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        {transactions && transactions.length > 0 && (
          <SortableTable
            classes={{
              'dark-tableRowRoot': classes.tableRow,
              'dark-tableCellRoot': classes.tableCell
            }}
            mode="dark"
            dataCy="policies"
            selfContained={false}
            headers={[
              ['paymentDate', 'Date'],
              ['paymentAmount/paymentRefunded', 'Amount/Amount Refunded'],
              ['paymentMethod', 'Payment Method'],
              ['paymentStatus', 'Payment Status'],
              ['internalDescription', 'Description']
            ]}
            data={modifiedTransactions}
            formatters={{
              paymentDate: awsDateToDateFormatter,
              paymentStatus: capitalizeWords,
              internalDescription: shortenInternalDescription,
              paymentMethod: (p, transaction) =>
                getBillingPaymentMethod(
                  transaction.paymentMethod,
                  transaction.paymentMethodBrand,
                  transaction.paymentMethodLast4,
                  classes.tableBodyPaymentMethodLast4
                )
            }}
          />
        )}
      </Grid>
    </div>
  );
};

PastPayments.propTypes = {
  transactions: PropTypes.array.isRequired,
  getBillingPaymentMethod: PropTypes.func.isRequired
};

export default PastPayments;
