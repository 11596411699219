import React, { Suspense, lazy, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import classNames from 'classnames';
import { getSnapshot } from 'mobx-state-tree';

import { ActionButton } from 'core/components/action-button';
import { useStore } from 'core/store';
import { CheckoutStatus } from 'core/store/offer-store';
import useErrors from 'common/hooks/use-errors';
import x from 'core/assets/svg/times.svg';
import xWhite from 'core/assets/svg/close.svg';

import {
  brandedTitleCarsWarning,
  powerOutage,
  purchaseFailed,
  branchSpinner,
  somethingWrong,
  vinDiscrepancy,
  mvrRetry,
  mvrRejected
} from './resolvers';
import useStyles from './dialog.styles';

const getOnlyErrors = (code) => code < 10000;
const getOnlyRejections = (code) => code >= 10000;

const OfferDialog = observer(({ onClose, fromCheckout }) => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const errors = useErrors(getSnapshot(store.errors), getOnlyErrors);
  const rejections = useErrors(getSnapshot(store.errors), getOnlyRejections);
  const isRed = errors[0] && Number(errors[0].code) < 4002 && Number(errors[0].code) !== 0;
  const vinDiscrepancies = store.vinDiscrepancies;

  const ModalContent = useMemo(() => {
    if (fromCheckout && store.status === CheckoutStatus.Purchasing) {
      return lazy(branchSpinner);
    }
    if (fromCheckout && store.status === CheckoutStatus.PurchaseFailed) {
      return lazy(purchaseFailed);
    }
    if (vinDiscrepancies && vinDiscrepancies.length) {
      return lazy(vinDiscrepancy);
    }
    if (fromCheckout && store.status === CheckoutStatus.MVRRetry) {
      return lazy(mvrRetry);
    }
    if (store.hasNewBrandedTitleCars) {
      return lazy(brandedTitleCarsWarning);
    }
    if (
      (fromCheckout && store.status === CheckoutStatus.MVRRejected) ||
      store.status === CheckoutStatus.MVRDriversChanged
    ) {
      return lazy(mvrRejected);
    }
    if (isRed) {
      return lazy(powerOutage);
    }
    if (!isRed) {
      return lazy(somethingWrong);
    }
  }, [isRed, fromCheckout, store.status, vinDiscrepancies]);

  if (!ModalContent) {
    return <></>;
  }

  return (
    <Dialog maxWidth="lg" open={!!store.openDialog || isRed || false}>
      <DialogContent className={classNames(classes.mContent, { [classes.redBack]: isRed })}>
        <Suspense fallback={null}>
          <ModalContent classes={classes} errors={errors} rejections={rejections} vinDiscrepancies={vinDiscrepancies} />
        </Suspense>
        <ActionButton
          size="big"
          type="edit"
          icon={isRed ? xWhite : x}
          className={classes.closeButton}
          onClick={onClose}
        />
      </DialogContent>
    </Dialog>
  );
});

OfferDialog.defaultProps = {
  fromCheckout: false
};

OfferDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  fromCheckout: PropTypes.bool
};

export default OfferDialog;
