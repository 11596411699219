import React, { useEffect, useCallback, useContext } from 'react';
import { observer, useAsObservableSource } from 'mobx-react';
import { reaction } from 'mobx';
import { Formik, yupToFormErrors } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import { utcToZonedTime } from 'date-fns-tz';
import _differenceWith from 'lodash-es/differenceWith';
import uuidv4 from 'uuid/v4';
import {
  preBindMVRStates,
  coverageRSExtendedForcedStates,
  coverageRSForcedStates,
  policyType,
  statesWithNonStandardPIPWLValues,
  coverageRSForcedAtXYearsStates
} from '@ourbranch/lookups';

import { stringDateToAwsDate, awsDateToDateObject, dateFormatter, cleanObject } from 'core/helpers/formatters';
import { getValues, formatSPP, sortItems } from 'core/helpers/scheduled-pp-helper';
import { types } from 'core/helpers/sanitize';
import { track } from 'core/helpers/analytics';
import { doesArrayHaveNewItems } from 'core/helpers/equality-checker';
import { useStore } from 'core/store';
import LayoutWithSidebar from 'core/components/layout-with-sidebar';
import { AuthContext } from 'core/components/auth';
import { useToast } from 'core/components/toast';
import { formatUmbrellaValues } from 'common/components/umbrella/umbrella';
import { haveAnyOfThisCarsSymbolsChanged } from 'common/helpers/car-symbols-helpers';
import {
  formatValues as formatHomeValues,
  getHighestEducationAppCoApp,
  getOldestAppCoApp
} from 'common/components/home/detail/helper';
import { getPrimaryOrBlankMortgageDetails, updatePrimaryMortgageDetails } from 'offer/helpers/mortgage-helpers';
import { canOfferMegaDownPay, setMegaDownPayOnOffer } from 'offer/helpers/mega-down-pay-helpers';
import Offer from 'offer/offer';
import { OfferHeader, OfferSidebar } from 'offer';
import { buildSchema } from './offer.validation-schema';
import { formatCondoValues } from 'common/components/condo/detail/helper';

const OfferProvider = observer(({ offerId }) => {
  const history = useHistory();
  const toast = useToast();
  const urlParams = useParams();
  const params = useAsObservableSource(urlParams);
  const session = useContext(AuthContext);
  const {
    user: { username }
  } = session;
  if (!urlParams.offerId) {
    throw new Error('Invalid URL');
  }
  const {
    offer: store,
    matchSearch: { setShowCustomerMatches },
    affinityLookups
  } = useStore();

  const selectedOption = store?.selectedOption;

  let mortgageDetails = [];
  if (selectedOption) {
    if (selectedOption.includes(policyType.Home)) {
      mortgageDetails = store?.offer?.quote?.home?.mortgageDetails;
    } else if (selectedOption.includes(policyType.Condo)) {
      mortgageDetails = store?.offer?.quote?.condo?.mortgageDetails;
    }
  }

  const primaryMortgageDetail = getPrimaryOrBlankMortgageDetails(mortgageDetails);

  const affinity = store?.offer?.quote?.global?.affinity;
  const affinityData = affinityLookups.data.find((aff) => aff.affinity.startsWith(affinity));
  const agencyAffinity = affinity && affinityData?.isAgency;
  // if agency affinity and internal agent is binding for them, keep agency rep as sales rep
  const dontOverwriteRep = Boolean(!session.isAgency && agencyAffinity);

  const initialValues = {
    ...store?.offer?.quote,
    home: store?.shouldShowHomeTab ? store?.offer?.quote.home : null,
    condo: store?.shouldShowCondoTab
      ? {
          ...store.offer.quote.condo,
          noUnitNumber: !store.offer.quote.condo.hasUnitNumber,
          monthsRentedOut: store?.offer?.quote?.condo.weeksRentedOut
            ? Math.floor(store?.offer?.quote?.condo.weeksRentedOut / 4)
            : 0
        }
      : null,
    auto: {
      ...store?.offer?.quote?.auto,
      pipAdditionalResidents: store?.offer?.quote?.auto?.pipAdditionalResidents || 0
    },
    condoCoverage: store?.shouldShowCondoTab ? store.offer.quote.condoCoverage : {},
    selectedOption: selectedOption || store?.defaultSelectedOption,
    primaryMortgageDetail,
    highestEducation:
      store?.offer?.quote?.people || store?.offer?.quote?.drivers
        ? getHighestEducationAppCoApp(store?.offer?.quote?.people || store?.offer?.quote?.drivers)
        : undefined,
    oldestResident:
      store?.offer?.quote?.people || store?.offer?.quote?.drivers
        ? getOldestAppCoApp(store?.offer?.quote?.people || store?.offer?.quote?.drivers)
        : undefined,
    scheduledPersonalProperty: {
      deductible: store?.offer?.quote?.scheduledPersonalProperty?.items
        ? store?.offer?.quote?.scheduledPersonalProperty?.deductible
        : null,
      items: store?.offer?.quote.scheduledPersonalProperty?.items.length
        ? sortItems(store.offer.quote.scheduledPersonalProperty.items.map((item) => getValues(item)))
        : []
    },
    umbrellaCoverage: store?.offer?.quote?.includeUmbrella
      ? {
          watercraftHullLengths: [],
          rentalPropertyAddresses: [],
          otherPropertyAddresses: [],
          numRVs: null,
          numMotorcyclesScooters: null,
          numVehiclesTotal: null,
          numLicensedDriversUnder25: null,
          numATVs: null,
          numPersonalWatercraft: null,
          liabilityCoverageLimit: null,
          ...cleanObject({ ...store?.offer?.quote?.umbrellaCoverage })
        }
      : null,
    dontOverwriteRep,
    global: store?.offer?.quote?.global
      ? {
          ...store.offer.quote.global,
          autoMegaDownPay:
            typeof store.offer.quote.global.autoMegaDownPay === 'boolean'
              ? store.offer.quote.global.autoMegaDownPay
              : store.selectedOption?.includes('A') || store.offer.quote.selectedOption?.includes('A') || null,
          homeMegaDownPay:
            typeof store.offer.quote.global.homeMegaDownPay === 'boolean'
              ? store.offer.quote.global.homeMegaDownPay
              : store.selectedOption?.includes('H') || store.offer.quote.selectedOption?.includes('H') || null,
          rentersMegaDownPay:
            typeof store.offer.quote.global.rentersMegaDownPay === 'boolean'
              ? store.offer.quote.global.rentersMegaDownPay
              : store.selectedOption?.includes('R') || store.offer.quote.selectedOption?.includes('R') || null,
          condoMegaDownPay:
            typeof store.offer.quote.global.condoMegaDownPay === 'boolean'
              ? store.offer.quote.global.condoMegaDownPay
              : store.selectedOption?.includes('C') || store.offer.quote.selectedOption?.includes('C') || null,
          affinity: store.offer.quote.global.affinity ?? ''
        }
      : null
  };

  useEffect(() => {
    if (params.offerId !== store?.offer?.id) {
      store.getOffer(params.offerId);
    }
  }, []);

  // this reaction tracks the loading state, and when it changes (true/false), checks if the offer was set correctly
  // essentially handling cases were access to an offer is unauthorized
  // or the offer fails to load/set in the store for any reason
  reaction(
    () => store.loading,
    (loading) => {
      if (!loading && !store.offer) {
        toast.notify({
          type: 'error',
          message: `An Error was encountered. Error: ${store.errors[0]?.message}. Please contact ${
            session.isAgency ? 'Agency Support' : 'the Help Desk'
          }.`,
          durationInSeconds: 10
        });
        setTimeout(() => {
          history.push('/search/offers');
        }, 7000);
      }
    }
  );

  const validate = useCallback(
    async (values) => {
      try {
        await buildSchema({
          formAction: store.formAction,
          includeConnectedHome: store.includeConnectedHome,
          isAdvancedConnectedHome: store.isAdvancedConnectedHome,
          needMVRs: preBindMVRStates.includes(store.state) && values.drivers.some((driver) => driver.postBindMVR),
          affinityLookups,
          session,
          selectedOption: values.selectedOption,
          state: store.state,
          messageContext: { ...values },
          initialValuesContext: initialValues
        }).validate(values, {
          abortEarly: false,
          context: {
            ...values,
            state: store.state,
            initialValues,
            canAddCarsManually: session.canAddCarsManually,
            affinityLookups
          }
        });
      } catch (error) {
        const formErrors = yupToFormErrors(error);

        // disabling eslint for the console log so we can monitor validation errors in prod
        // eslint-disable-next-line
        console.log({ formErrors });

        if (!Object.keys(formErrors).length) {
          // logging error as well in case it's not a validation error
          // eslint-disable-next-line
          console.log({ error });
        }

        return formErrors;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store.formAction, store.includeConnectedHome, store.isAdvancedConnectedHome, session, affinityLookups]
  );

  const submitRecalculate = async (details) => {
    const homeCoverage = {};

    const formattedMortgageDetails = updatePrimaryMortgageDetails(
      details?.home?.mortgageDetails || details?.condo?.mortgageDetails,
      details.primaryMortgageDetail,
      details?.global?.homeownersPaymentMethod
    );

    const roofAge = new Date().getFullYear() - details?.home?.roofYear;
    const isArkansasNewRoof = store.state === 'AR' && roofAge <= coverageRSForcedAtXYearsStates[store.state];
    const coverageRSExtendedForced = coverageRSExtendedForcedStates[store.state] || isArkansasNewRoof;
    if (coverageRSExtendedForced && !details?.homeCoverage?.coverageRSExtended) {
      homeCoverage.coverageRSExtended = true;
    }
    details.drivers.forEach((d) => {
      if (d?.clearUDRViolation) d.gotDrivingRecord = true;
    });

    if (coverageRSForcedStates[store.state] && !!details?.homeCoverage?.coverageRSExtended) {
      homeCoverage.coverageRSExtended = false;
    }

    const driversToRecheck = store.driverIdsToRecheck.map((driverId) => {
      const driverToRecheck = details.drivers.find((driver) => driver.id === driverId);
      const oldDriverIndex = details.drivers.indexOf(driverToRecheck);
      driverToRecheck.id = uuidv4();
      details.drivers.splice(oldDriverIndex, 1);

      return types.PersonDetailsInput(driverToRecheck);
    });

    if (details.selectedOption === policyType.CABundle) {
      details.condo.numVehicles = details.cars.length;
    }

    const primaryApplicant = details.drivers?.find((d) => d.isPrimary);

    // remove MPD if no existing customer linked
    const forcedMultiPolicyDiscount =
      !details.existingCustomer?.id && details.forcedMultiPolicyDiscount && details.forcedMultiPolicyDiscount !== 'N'
        ? 'N'
        : details.forcedMultiPolicyDiscount;

    let formattedDetails = {
      ...details,
      forcedMultiPolicyDiscount,
      // an offer could be Auto&Renters, or it could be Auto w/ renters added
      includeRenters: (selectedOption.includes('R') || selectedOption.includes('A')) && details.includeRenters,
      fname: primaryApplicant?.firstName ?? details.fname,
      lname: primaryApplicant?.lastName ?? details.lname,
      condo: store?.shouldShowCondoTab
        ? { ...formatCondoValues(details.condo), mortgageDetails: formattedMortgageDetails }
        : undefined,
      connectedHome: store.includeConnectedHome ? details.connectedHome : null,
      fromStaff: true,
      rep: details.dontOverwriteRep && details.rep?.length > 0 ? details.rep : username,
      umbrellaCoverage: formatUmbrellaValues(details.umbrellaCoverage, details.includeUmbrella),
      scheduledPersonalProperty: formatSPP(details.scheduledPersonalProperty),
      home: store?.shouldShowHomeTab
        ? { ...formatHomeValues(details.home), mortgageDetails: formattedMortgageDetails }
        : undefined,
      rentersCoverage: details.includeRenters && details.rentersCoverage ? details.rentersCoverage : undefined,
      autoCoverage: details?.autoCoverage
        ? {
            ...details.autoCoverage,
            policyLimitPIPWL: statesWithNonStandardPIPWLValues.includes(store.state)
              ? details.autoCoverage.policyLimitPIPWL
              : details?.drivers.some((d) => d.waivedPIPWL)
              ? 'S/WLW'
              : 'S'
          }
        : null,
      homeCoverage: details?.homeCoverage ? { ...details.homeCoverage, ...homeCoverage } : null
    };

    if (
      details.selectedOption?.includes(policyType.Home) &&
      details?.home?.roofYear !== initialValues?.home?.roofYear
    ) {
      formattedDetails = { ...formattedDetails, home: { ...formattedDetails.home, roofYearChangedManually: true } };
    }

    formattedDetails = types.QuoteDetailsInput(formattedDetails);

    track('Staff Modify Offer', { offer: { ...formattedDetails } });

    // check if we need to call rater the event field that also will add drivers/cars
    const shouldAddDrivers = doesArrayHaveNewItems(store.offer.quote.drivers, details.drivers);
    const shouldAddCars = doesArrayHaveNewItems(store.offer.quote.cars, details.cars);
    const shouldAddTrailers = doesArrayHaveNewItems(store.offer.quote.trailers, details.trailers);

    if (shouldAddDrivers || shouldAddCars || shouldAddTrailers || driversToRecheck.length >= 1) {
      // format data to all be same shape so the lodash differenceWith method works properly
      const driversIn = details.drivers.map((driver) => {
        return types.PersonDetailsInput(driver);
      });
      const savedDriversOnOffer = store.offer.quote.drivers.map((driver) => {
        return types.PersonDetailsInput(driver);
      });

      // for new/existing drivers,cars & trailers use the input subtracting the new items, otherwise you won't capture any changes to existing items.

      const newDrivers = _differenceWith(
        driversIn,
        savedDriversOnOffer,
        (driverIn, existingDriver) => driverIn.id === existingDriver.id
      ).map((newDriver) => {
        return types.PersonDetailsInput(newDriver);
      });

      const existingDrivers = _differenceWith(
        details.drivers,
        [...newDrivers, ...driversToRecheck],
        (existingDriver, driverIn) => existingDriver.id === driverIn.id
      ).map((newDriver) => {
        return types.PersonDetailsInput(newDriver);
      });

      const hasSymbols = (car, initialValuesCars) => haveAnyOfThisCarsSymbolsChanged(car, initialValuesCars);
      if (details.cars?.some((car) => hasSymbols(car))) {
        details.cars.forEach((car) => {
          if (hasSymbols(car, initialValues.cars)) {
            car.manuallyAddedSymbols = true;
            const aux = car.symbolAux?.toUpperCase() || '  ';
            car.symbolMake = car.symbolMake?.toUpperCase();
            car.symbolModel = car.symbolModel?.toUpperCase();
            car.symbolStyle = car.symbolStyle?.toUpperCase();
            car.symbolAux = String(aux).length === 2 ? aux : '  ';
            car.symbolPGS = car.symbolPGS?.toUpperCase() || ' ';
          }
        });
      }

      const newCars = _differenceWith(details.cars, store.offer.quote.cars, (a, b) => a.VIN === b.VIN).map((newCar) => {
        return types.CarDetailsInput(newCar);
      });

      const existingCars = _differenceWith(details.cars, newCars, (a, b) => a.VIN === b.VIN).map((car) => {
        return types.CarDetailsInput(car);
      });

      const newTrailers = _differenceWith(details.trailers, store.offer.quote.trailers, (a, b) => a.VIN === b.VIN).map(
        (newTrailer) => {
          return types.TrailerDetailsInput(newTrailer);
        }
      );

      const existingTrailers = _differenceWith(details.trailers, newTrailers, (a, b) => a.VIN === b.VIN).map(
        (newTrailer) => {
          return types.TrailerDetailsInput(newTrailer);
        }
      );

      if (selectedOption === policyType.CABundle) {
        formattedDetails.condo.numVehicles = existingCars.length;
      }

      if (selectedOption.includes(policyType.Auto) && store.state === 'MI') {
        const totalResidents =
          [...newDrivers, ...driversToRecheck].length + existingDrivers.length + (details.nonDrivers || []).length;
        formattedDetails.auto.pipAllResidents = totalResidents;
        if (totalResidents === 1) {
          formattedDetails.auto.pipEveryoneOnSamePlan = true;
        }
      }

      if (
        details.global?.autoMegaDownPay ||
        details.global?.homeMegaDownPay ||
        details.global?.rentersMegaDownPay ||
        details.global?.condoMegaDownPay
      ) {
        const newGlobal = setMegaDownPayOnOffer(details);
        formattedDetails = types.QuoteDetailsInput({ ...formattedDetails, global: newGlobal });
      }

      await store.addDriversAddCarsAndRecalculateCluster({
        newDrivers: [...newDrivers, ...driversToRecheck],
        newVins: newCars,
        newTrailerVins: newTrailers,
        revisedQuoteDetails: {
          ...formattedDetails,
          drivers: existingDrivers,
          cars: existingCars,
          trailers: existingTrailers
        },
        offerId: store.offer.id,
        history
      });

      if (store.hasOneOrMoreUDRs) {
        toast.notify({ type: 'error', message: 'A driver’s driving record could not be verified.' });
      }
    } else {
      /*
      if the effective dates are not today, we calculate new effective date (this operation also updates the rate control date)
      note, we need to do this in the same named function, or else formik will think our form has changed and reset everything
      */

      const { global } = details;
      const autoEffectiveDate = awsDateToDateObject(global.autoEffectiveDate);
      const homeEffectiveDate = awsDateToDateObject(global.homeEffectiveDate);
      const rentersEffectiveDate = awsDateToDateObject(global.rentersEffectiveDate);
      const condoEffectiveDate = global.condoEffectiveDate ? awsDateToDateObject(global.condoEffectiveDate) : null;
      const now = utcToZonedTime(new Date(), store.timezone);
      const shouldUpdateAuto = !isSameDay(autoEffectiveDate, now) && !isAfter(autoEffectiveDate, now);
      const shouldUpdateHome = !isSameDay(homeEffectiveDate, now) && !isAfter(homeEffectiveDate, now);
      const shouldUpdateRenters = !isSameDay(rentersEffectiveDate, now) && !isAfter(rentersEffectiveDate, now);
      const shouldUpdateCondo =
        condoEffectiveDate && !isSameDay(condoEffectiveDate, now) && !isAfter(condoEffectiveDate, now);

      if (shouldUpdateHome || shouldUpdateAuto || shouldUpdateRenters || shouldUpdateCondo) {
        const getAdjustedBillingDay = (effectiveDate, billingDay) => {
          const effectiveDay = effectiveDate.getDate();
          const shouldMoveDefaultBillingDay = billingDay === Math.min(effectiveDay === 1 ? 28 : effectiveDay - 1, 28);
          let newBillingDay = billingDay;
          if (shouldMoveDefaultBillingDay) {
            // we should move the day to the day before of the effective date
            const today = now.getDate();
            newBillingDay = Math.min(today === 1 ? 28 : today - 1, 28);
          }
          return newBillingDay;
        };

        let updatedAutoEffectiveDate = global.autoEffectiveDate;
        let updatedHomeEffectiveDate = global.homeEffectiveDate;
        let updatedRentersEffectiveDate = global.rentersEffectiveDate;
        let updatedCondoEffectiveDate = global.condoEffectiveDate;
        let updatedAutoMegaDownPay = global.autoMegaDownPay;
        let updatedHomeMegaDownPay = global.homeMegaDownPay;
        let updatedRentersMegaDownPay = global.rentersMegaDownPay;
        let updatedCondoMegaDownPay = global.condoMegaDownPay;
        let updatedAutoBillingDay = global.autoBillingDayOfMonth;
        let updatedHomeBillingDay = global.homeBillingDayOfMonth;
        let updatedRentersBillingDay = global.rentersBillingDayOfMonth;
        let updatedCondoBillingDay = global.condoBillingDayOfMonth;

        if (shouldUpdateAuto) {
          updatedAutoEffectiveDate = stringDateToAwsDate(dateFormatter(now));
          updatedAutoBillingDay = getAdjustedBillingDay(autoEffectiveDate, global.autoBillingDayOfMonth);
          updatedAutoMegaDownPay =
            canOfferMegaDownPay(updatedAutoEffectiveDate, updatedAutoBillingDay, global.autoPaymentType) || null;
        }
        if (shouldUpdateHome) {
          updatedHomeEffectiveDate = stringDateToAwsDate(dateFormatter(now));
          updatedHomeBillingDay = getAdjustedBillingDay(homeEffectiveDate, global.homeBillingDayOfMonth);
          updatedHomeMegaDownPay =
            canOfferMegaDownPay(updatedHomeEffectiveDate, updatedHomeBillingDay, global.homeownersPaymentType) || null;
        }

        if (shouldUpdateRenters) {
          updatedRentersEffectiveDate = stringDateToAwsDate(dateFormatter(now));
          updatedRentersBillingDay = getAdjustedBillingDay(rentersEffectiveDate, global.rentersBillingDayOfMonth);
          updatedRentersMegaDownPay =
            canOfferMegaDownPay(updatedRentersEffectiveDate, updatedRentersBillingDay, global.rentersPaymentType) ||
            null;
        }

        if (shouldUpdateCondo) {
          updatedCondoEffectiveDate = stringDateToAwsDate(dateFormatter(now));
          updatedCondoBillingDay = getAdjustedBillingDay(condoEffectiveDate, global.condoBillingDayOfMonth);
          updatedCondoMegaDownPay =
            canOfferMegaDownPay(updatedCondoEffectiveDate, updatedCondoBillingDay, global.condoPaymentType) || null;
        }

        const newGlobal = {
          ...global,
          autoEffectiveDate: updatedAutoEffectiveDate,
          homeEffectiveDate: updatedHomeEffectiveDate,
          rentersEffectiveDate: updatedRentersEffectiveDate,
          condoEffectiveDate: updatedCondoEffectiveDate,
          autoMegaDownPay: updatedAutoMegaDownPay,
          homeMegaDownPay: updatedHomeMegaDownPay,
          rentersMegaDownPay: updatedRentersMegaDownPay,
          condoMegaDownPay: updatedCondoMegaDownPay,
          autoBillingDayOfMonth: updatedAutoBillingDay,
          homeBillingDayOfMonth: updatedHomeBillingDay,
          rentersBillingDayOfMonth: updatedRentersBillingDay,
          condoBillingDayOfMonth: updatedCondoBillingDay
        };

        formattedDetails = types.QuoteDetailsInput({ ...formattedDetails, global: newGlobal });
      } else if (
        global.autoMegaDownPay ||
        global.homeMegaDownPay ||
        global.rentersMegaDownPay ||
        global.condoMegaDownPay
      ) {
        // only want to do this if we're not updating a stale offer
        // in which case the change in effective date should always a trigger
        // a change to the corresponding mega down pay node
        const newGlobal = setMegaDownPayOnOffer(details);
        formattedDetails = types.QuoteDetailsInput({ ...formattedDetails, global: newGlobal });
      }

      if (formattedDetails.correctedAddress) {
        delete formattedDetails.correctedAddress;
      }

      await store.recalculateQuoteToCluster(store.offer.id, formattedDetails, history);
    }

    setShowCustomerMatches(false);
  };

  return (
    <Formik onSubmit={submitRecalculate} initialValues={initialValues} validate={validate} enableReinitialize>
      <LayoutWithSidebar
        content={Offer}
        side={OfferSidebar}
        offerId={offerId}
        history={history}
        header={OfferHeader}
        onBack={() => history.push('/search/offers')}
      />
    </Formik>
  );
});

export default OfferProvider;
