import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MUITooltip from '@material-ui/core/Tooltip';
import copy from 'copy-to-clipboard';

import { Label } from 'core';
import copyIcon from './icCopy_24px.svg';
import useStyles from './label-with-copy-button.styles';
import { useToast } from 'core/components/toast';

const LabelWithCopyButton = ({ children, editing, type, ...props }) => {
  const formatted = children.toString().replaceAll(',', '');
  const title = `Copy ${formatted} to clipboard`;
  const classes = useStyles();
  const toast = useToast();

  const onClick = useCallback(() => {
    const copied = copy(formatted);
    if (copied) {
      toast.notify({
        type: 'success',
        message: `${formatted} copied to your clipboard.`
      });
    } else {
      toast.notify({
        type: 'error',
        message: `${formatted} could not be copied`
      });
    }
  }, [children, toast]);

  return (
    <Grid className={classes.container}>
      <MUITooltip title={title} arrow placement="top" classes={{ tooltip: classes.tooltip }}>
        <Button onClick={onClick} className={classes.button}>
          <Label type={type} {...props} className={classes.label}>
            {children}
          </Label>
          <img className={classes.icon} src={copyIcon} alt={'copy'} />
        </Button>
      </MUITooltip>
    </Grid>
  );
};

LabelWithCopyButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default LabelWithCopyButton;
