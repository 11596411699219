import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'core/components/label';
import { errorTypes } from '../constants';
import useStyles from './error-label.styles';

const ErrorLabel = ({ errorType, asLink, ...props }) => {
  const classes = useStyles();
  return (
    <Label
      className={classNames(classes.errorLabel, { [classes.link]: asLink })}
      type={errorType === errorTypes.Tertiary ? 'body3' : 'body2'}
      {...props}
    />
  );
};

ErrorLabel.propTypes = {
  errorType: PropTypes.oneOf(Object.values(errorTypes)),
  asLink: PropTypes.bool
};

ErrorLabel.defaultProps = {
  asLink: false
};

export default ErrorLabel;
