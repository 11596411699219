import { useEffect, useState } from 'react';

export const useScroll = (scrollTo, afterScroll) => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    if (scrollTo && !scrolled) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setScrolled(true);
        if (afterScroll) {
          afterScroll();
        }
      }
    }
  }, [scrollTo, scrolled]);
};
