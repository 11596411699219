import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { differenceWith } from 'lodash-es';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { lookupsJson } from '@ourbranch/lookups';

import { Button } from 'core/components/button';
import { useStore } from 'core/store';
import useSession from 'core/hooks/use-session';
import { FormField } from 'core/components/form';
import useStyles from './styles';
import useFeeUpdater from './use-fee-updater';

function FeeSelector({ geographicState, policyType, disabled }) {
  const { updateFees } = useFeeUpdater();
  const [feeToAdd, setFeeToAdd] = useState('');
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const classes = useStyles();
  const session = useSession();

  const allFeeOptions = lookupsJson.feesState?.[geographicState] || [];
  const feeComparator = (option, fee) => option.id === fee.type;

  const selectFee = (newFee) => {
    setFeeToAdd(newFee);
  };

  function checkForBundle() {
    // looking at first policy version because we don't drop the discount/fee if customer drops the bundle mid-term
    return (
      store.policy.segments[0].includeRenters ||
      store.policy.segments[0].attachedRenters ||
      (policyType === policyType.Auto && store.policy.segments[0].attachedHomeowners) ||
      (policyType === policyType.Home && store.policy.segments[0].attachedAuto)
    );
  }

  const createFee = () => {
    store.addFee(feeToAdd, {
      state: geographicState,
      policyType,
      isBix: store.policy.isBix,
      inBundle: checkForBundle()
    });
    updateFees();
    setFeeToAdd('');
  };

  function feeOptionsFilter(allFeeOptions) {
    // remove options already applied to policy
    const availableFeeOptions = differenceWith(allFeeOptions, store.fees, feeComparator);
    let filteredFees = availableFeeOptions;
    // Imagery fee only applies to TX GSNIC Home and MO GSNIC
    if (
      (geographicState === 'TX' && (store.policy.isBix || (!store.policy.isBix && policyType !== 'H'))) ||
      (geographicState === 'MO' && store.policy.isBix)
    ) {
      filteredFees = filteredFees.filter((fee) => !fee.id.includes('imagery'));
    }
    // Paper fee only applies for TX GSNIC policies
    if (geographicState === 'TX' && store.policy.isBix) {
      filteredFees = filteredFees.filter((fee) => !fee.id.includes('paper'));
    }
    // Policy fee does not apply to MO BIX, TX BIX, or Home policies in AL
    if (
      (geographicState === 'MO' && store.policy.isBix) ||
      (geographicState === 'TX' && store.policy.isBix) ||
      (geographicState === 'AL' && policyType === 'H')
    ) {
      filteredFees = filteredFees.filter((fee) => !fee.id.includes('policy'));
    }
    // Reinstatement fee does not apply to auto policies in MI or home policies in SD
    if ((geographicState === 'MI' && policyType === 'A') || (geographicState === 'SD' && policyType === 'H')) {
      filteredFees = filteredFees.filter((fee) => !fee.id.includes('reinstatement'));
    }
    // Auto Theft Prevention Device fee only applies to auto policies
    if (policyType !== 'A') {
      filteredFees = filteredFees.filter((fee) => !fee.id.includes('auto_theft_prevention'));
    }
    return filteredFees;
  }

  const filteredFees = feeOptionsFilter(allFeeOptions);

  return (
    <Grid className={classes.feeSelectorContainer}>
      <FormField
        className={classes.formControl}
        mode="light"
        name="fees"
        id="fees"
        options={filteredFees}
        type="select"
        onChange={selectFee}
        xs={8}
      />
      <Button
        variant="contained"
        color="secondary"
        disabled={!session.canAddHoldCards}
        className={classes.button}
        onClick={createFee}
      >
        Add
      </Button>
    </Grid>
  );
}

FeeSelector.propTypes = {
  geographicState: PropTypes.string.isRequired,
  policyType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default observer(FeeSelector);
