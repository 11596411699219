import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { lapsedLicenseMonthsStates } from '@ourbranch/lookups';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { getPreSaleChecklistConditions } from '../../helper';
import useStyles from './pre-sale-checklist.styles';

const buyingHomePolicyText =
  'I attest that I have informed the member that an inspector may come to their home within 2-3 weeks of the policy’s effective date as part of Branch’s normal due diligence.';
const buyingCondoPolicyText =
  'I attest that I have informed the member that an inspector may come to their condo within 2-3 weeks of the policy’s effective date as part of Branch’s normal due diligence.';
const inColoradoText =
  "I attest that I have informed the member that we are occasionally unable to get all the necessary underwriting reports pre-sale, that it doesn't happen often and if it impacts their policy, we'll let them know right away.";
const confirmedApplicantsText = "I attest the insured's spouse is listed as the co-applicant.";
const enteredPriorInsuranceText =
  " I attest that I have confirmed the name of the insured's prior carrier(s) and entered their previous policy numbers for Stress Free Switch if possible.";
const confirmDiscountsText =
  'I attest that I have communicated applied discounts and the steps required to retain them.';
const confirmCoveragesText =
  'I attest that I have discussed all accepted and declined coverages with the named insured and have explained the risks of not carrying certain coverages.';
const proofOfPriorCoverageText =
  'I attest that I have received proof of prior insurance OR advised member they will need to provide it to retain their policy, OR received approval for ‘No Need No Prior’.';
const getLapsedDriversLicensesText = (state) =>
  `I attest that I have verified if all drivers listed on this policy have had a valid driver's license continuously for the last ${lapsedLicenseMonthsStates[state]} months, as well as their age first licensed.`;
const roofSurfacesEndorsementText = `I attest that I have explained to the applicant that covered roof claims will be paid according to a payment schedule, rather than replacement cost, because their Home policy does not have Roof Surfaces Extended Coverage.`;
const confirmAllDriversLicenseText = 'I attest that all available driver’s license numbers have been provided.';
const getMichiganPIPText = (residents) =>
  `I have verified that the ${residents} residents listed as living in household is the total number of members in the household. I have verified that this number includes the member, their spouse, and any resident relatives that live with them.`;
const homeAgeInventoryDiscountText =
  'I attest that I have informed the member, due to the age of their home, they are required to complete the Inventory check in their Account within 15 days of bind or they risk their policy being cancelled.';
const getTrailingDocsText = ({
  hasSPP,
  hasInventoryDiscount,
  homeAgeInventoryDiscountInformed,
  hasConnectedHome,
  hasGoodStudent,
  hasTakenDrivingCourse,
  hasKiaOrHyundai
}) => {
  const proofMap = {
    hasSPP: 'scheduled personal property documentation',
    hasInventoryDiscount: 'completed inventory',
    hasConnectedHome: 'connected home proof',
    hasGoodStudent: 'good student proof',
    hasTakenDrivingCourse: 'driving course proof',
    hasKiaOrHyundai: 'Kia/Hyundai anti-theft update proof'
  };
  const proofList = Object.keys(proofMap)
    .filter((key) => {
      switch (key) {
        case 'hasSPP':
          return hasSPP;
        case 'hasInventoryDiscount':
          return hasInventoryDiscount && !homeAgeInventoryDiscountInformed;
        case 'hasConnectedHome':
          return hasConnectedHome;
        case 'hasGoodStudent':
          return hasGoodStudent;
        case 'hasTakenDrivingCourse':
          return hasTakenDrivingCourse;
        case 'hasKiaOrHyundai':
          return hasKiaOrHyundai;
        default:
          return false;
      }
    })
    .map((key) => proofMap[key]);
  const lastProof = proofList.pop();
  const proofString = proofList.join(', ') + (proofList.length ? ' and ' : '') + lastProof;

  return (
    <span>
      {`I attest that I have communicated that the following proof or documentation will be required within 15 days after
      the policy's effective date: `}
      <b>{proofString}. </b>
      {`The member will receive additional communications about the
      requirements for these items after the policy is bound. If the above is not provided, the related
      coverage/discount will be removed and may incur additional charges.`}
    </span>
  );
};

const excludedDriversText = (drivers) => {
  const excludedDriversArray = [];

  drivers.forEach((d) => d.excludeDriver && excludedDriversArray.push(`${d.firstName} ${d.lastName}`));

  return `I attest that I have communicated to the member that driver(s) ${new Intl.ListFormat('en').format(
    excludedDriversArray
  )} are excluded from the auto policy.`;
};

const getNoWindHailInformedText = () => ({
  AL: {
    firstPart:
      'I attest that I have informed the member that their home coverage does not cover wind & hail damage in their location and that this coverage must be purchased separately through',
    link: 'https://aiua.org/',
    linkText: 'AIUA.'
  },
  GA: {
    firstPart:
      'I attest that I have informed the member that their home coverage does not include wind & hail damage due to their home’s proximity to the coastline and that they can purchase this coverage through the',
    link: 'https://www.georgiaunderwriting.com/',
    linkText: 'GUA.'
  }
});

const getEarthquakeAndFloodInformedText = (includeEarthquake, includeFlood) => {
  if (!includeEarthquake && !includeFlood) {
    return null;
  }

  let secondPart = '';
  if (includeEarthquake) {
    secondPart = ' earthquake';
    if (includeFlood) {
      secondPart = `${secondPart} and flood coverages are`;
    } else {
      secondPart = `${secondPart} coverage is`;
    }
  } else if (includeFlood) {
    secondPart = ` flood coverage is`;
  }

  return `I attest that I have informed the member that their${secondPart} billed and handled separately from their main home and/or auto policies.`;
};

const PreSaleChecklist = ({ offer, policyType }) => {
  const classes = useStyles();
  const { quote } = offer;
  const { values } = useFormikContext();
  const {
    buyingHomePolicyInformed,
    allApplicantsConfirmed,
    inColoradoInformed,
    priorInsuranceEntered,
    noWindHailInformed,
    earthquakeAndFloodInformed,
    proofOfPriorCoverageInformed,
    lapsedLicensesVerified,
    excludedDriversInformed,
    buyingCondoPolicyInformed,
    roofSurfacesEndorsementInformed,
    driversLicenseInformed,
    michiganPIPInformed,
    trailingDocsInformed,
    hasSPP,
    hasInventoryDiscount,
    hasConnectedHome,
    hasGoodStudent,
    hasTakenDrivingCourse,
    hasKiaOrHyundai,
    homeAgeInventoryDiscountInformed
  } = getPreSaleChecklistConditions(quote, values, policyType);
  const noWindHailInformedText = getNoWindHailInformedText()[quote.correctedAddress.state];
  const earthquakeAndFloodInformedText = getEarthquakeAndFloodInformedText(quote.includeEarthquake, quote.includeFlood);
  const lapsedDriversLicensesText = getLapsedDriversLicensesText(quote.correctedAddress.state);
  return (
    <Section title="Pre - Sale Checklist" type="SubSection">
      <Card className={classes.cardContainer} type="primaryEditable">
        <Grid container spacing={6} className={classes.checklistContainer}>
          {allApplicantsConfirmed && (
            <FormField
              className={classes.content}
              name="allApplicantsConfirmed"
              type="checkbox"
              label={confirmedApplicantsText}
              mode="light"
            />
          )}
          {driversLicenseInformed && (
            <FormField
              className={classes.content}
              name="driversLicenseInformed"
              type="checkbox"
              label={confirmAllDriversLicenseText}
              mode="light"
            />
          )}
          <FormField
            className={classes.content}
            name="confirmDiscounts"
            type="checkbox"
            label={confirmDiscountsText}
            mode="light"
          />
          <FormField
            className={classes.content}
            name="confirmCoverages"
            type="checkbox"
            label={confirmCoveragesText}
            mode="light"
            topAlignCheckbox
          />
          {proofOfPriorCoverageInformed && (
            <FormField
              className={classes.content}
              name="proofOfPriorCoverageInformed"
              type="checkbox"
              label={proofOfPriorCoverageText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {homeAgeInventoryDiscountInformed && (
            <FormField
              className={classes.content}
              name="homeAgeInventoryDiscountInformed"
              type="checkbox"
              label={homeAgeInventoryDiscountText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {buyingHomePolicyInformed && (
            <FormField
              className={classes.content}
              name="buyingHomePolicyInformed"
              type="checkbox"
              label={buyingHomePolicyText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {buyingCondoPolicyInformed && (
            <FormField
              className={classes.content}
              name="buyingCondoPolicyInformed"
              type="checkbox"
              label={buyingCondoPolicyText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {inColoradoInformed && (
            <FormField
              className={classes.content}
              name="inColoradoInformed"
              type="checkbox"
              label={inColoradoText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {priorInsuranceEntered && (
            <FormField
              className={classes.content}
              name="priorInsuranceEntered"
              type="checkbox"
              label={enteredPriorInsuranceText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {lapsedLicensesVerified && (
            <FormField
              className={classes.content}
              name="lapsedLicensesVerified"
              type="checkbox"
              label={lapsedDriversLicensesText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {noWindHailInformed && noWindHailInformedText && (
            <FormField
              className={classes.content}
              name="noWindHailInformed"
              type="checkbox"
              label={
                <>
                  {noWindHailInformedText.firstPart}{' '}
                  <a
                    className={classes.link}
                    href={noWindHailInformedText.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {noWindHailInformedText.linkText}
                  </a>
                </>
              }
              mode="light"
              topAlignCheckbox
            />
          )}
          {earthquakeAndFloodInformed && earthquakeAndFloodInformedText && (
            <FormField
              className={classes.content}
              name="earthquakeAndFloodInformed"
              type="checkbox"
              label={earthquakeAndFloodInformedText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {excludedDriversInformed && (
            <FormField
              className={classes.content}
              name="excludedDriversInformed"
              type="checkbox"
              label={excludedDriversText(quote?.drivers)}
              mode="light"
              topAlignCheckbox
            />
          )}
          {roofSurfacesEndorsementInformed && (
            <FormField
              className={classes.content}
              name="roofSurfacesEndorsementInformed"
              type="checkbox"
              label={roofSurfacesEndorsementText}
              mode="light"
              topAlignCheckbox
            />
          )}
          <FormField
            className={classes.content}
            name="branchCommunicationConsent"
            type="checkbox"
            label={
              <>
                I attest that I have informed the member that they will receive communications (including text messages)
                from Branch according to the{' '}
                <a
                  className={classes.link}
                  href="https://www.ourbranch.com/s/blog/communication-consent/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Branch Communication Consent.
                </a>
              </>
            }
            mode="light"
            topAlignCheckbox
          />
          {michiganPIPInformed && (
            <FormField
              className={classes.content}
              name="michiganPIPInformed"
              type="checkbox"
              label={getMichiganPIPText(quote.auto.pipAllResidents)}
              mode="light"
              topAlignCheckbox
            />
          )}
          {trailingDocsInformed && (
            <FormField
              className={classes.content}
              name="trailingDocsInformed"
              type="checkbox"
              label={getTrailingDocsText({
                hasSPP,
                hasInventoryDiscount,
                homeAgeInventoryDiscountInformed,
                hasConnectedHome,
                hasGoodStudent,
                hasTakenDrivingCourse,
                hasKiaOrHyundai
              })}
              mode="light"
              topAlignCheckbox
            />
          )}
        </Grid>
      </Card>
    </Section>
  );
};

PreSaleChecklist.propTypes = {
  offer: PropTypes.object.isRequired
};

export default PreSaleChecklist;
