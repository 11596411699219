import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  checkboxContainer: {
    textAlign: 'left'
  },
  checkbox: {
    height: 'unset'
  }
});

export default makeStyles(styles);
