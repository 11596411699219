import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { usePolicyConnectedHomeSecurityProviders, providersAllowingSignUp } from '../hooks/policy-connected-home.hooks';
import useSession from 'core/hooks/use-session';
import { ConnectedHomeFormFields } from 'common/components/discounts/connected-home-form-fields';

/*
Read about the business logic behind Connected Home here:
https://app.getguru.com/card/coMeGL5i/All-About-Affinity-and-ConnectedHome-Discounts
*/

const PolicyConnectedHomeSettings = observer(() => {
  const {
    account: {
      policies: { policy: policyStore }
    },
    affinityLookups: affinityLookupsStore
  } = useStore();
  const { isAgency } = useSession();

  const isThereProvidersOfferingNewSignUp = useMemo(() => {
    return !!providersAllowingSignUp(isAgency).length;
  }, [isAgency]);

  const isAdvancedConnectedHome = policyStore?.isAdvancedConnectedHome;

  // Get policy specific providers and pass it as prop to avoid policy logic in common component
  const securityProviderOptions = usePolicyConnectedHomeSecurityProviders(
    { isAdvancedConnectedHome },
    affinityLookupsStore
  );

  return (
    <ConnectedHomeFormFields
      mode="dark"
      securityProviderOptions={securityProviderOptions}
      isThereProvidersOfferingNewSignUp={isThereProvidersOfferingNewSignUp}
    />
  );
});

export default PolicyConnectedHomeSettings;
