import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import coffeeIcon from './assets/coffee.svg';
import useStyles from './no-data-card.styles';

const NoDataCard = ({ message, icon, subMessage }) => {
  const classes = useStyles();
  return (
    <Card type="noData">
      <div className={classes.container}>
        {icon && <img src={coffeeIcon} alt="Coffee mug icon" />}
        <Label type="darkGreenMedium">{message}</Label>
        <Label className={classes.subMessage} type="darkGreenSmall">
          {subMessage}
        </Label>
      </div>
    </Card>
  );
};

NoDataCard.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.bool,
  subMessage: PropTypes.string
};

NoDataCard.defaultProps = {
  message: 'Relax, there are no items here.',
  icon: true
};

export default NoDataCard;
