import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  changed: {
    marginBottom: theme.sizes.footerHeight + 50
  },
  row: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 8)
  },
  label: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 7)
  },
  labelWithDivider: {
    borderTop: '1px solid rgba(247,243,239, .3)',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    marginBottom: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(8, 0)
  },
  toggleRow: {
    minHeight: 40
  },
  button: {
    color: theme.colors.button__text_primary,
    fontWeight: 600,
    backgroundColor: theme.colors.button__background_secondary,
    fontSize: 16,
    width: 150
  },
  secondaryButton: {
    fontSize: 16
  },
  holdPaymentWarning: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2)
  }
});

export default makeStyles(styles);
