import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    gap: theme.spacing(4),
    justifyContent: 'space-between'
  },
  icon: {
    marginTop: 8
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400
  },
  heading: {
    margin: theme.spacing(2, 0)
  },
  text: {
    color: theme.colors.green_15,
    marginTop: 0
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: 'none',
    height: 28,
    width: 28,
    backgroundColor: theme.colorPalette.white_10,
    padding: 0,
    minWidth: 0
  },
  contactText: {
    color: theme.colorPalette.orange_10,
    fontSize: 16,
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    display: 'flex'
  },
  separator: {
    width: '100%',
    margin: theme.spacing(6, 0),
    height: 1,
    borderTop: '2px solid',
    borderColor: theme.colorPalette.beige_10
  }
});

export default makeStyles(styles);
