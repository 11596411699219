import { isAfter } from 'date-fns';
import { localToUtcTime } from '@ourbranch/date-helpers';
import {
  coverageRSExtendedForcedStates,
  coverageRSForcedStates,
  extendedCoverageRSExpirationByAgeStateExclusions,
  noShowRoofSurfacesExtendedUnderXYearsStates,
  coverageRSForcedAtXYearsStates,
  extendedCoverageRSByRoofMaterialStateExclusions,
  extendedCoverageRSLimits,
  reopeningStates,
  coverageRSDescriptionStates,
  coverageRSLabelStates,
  coverageRSEDisabledStates
} from '@ourbranch/lookups';

import { tooltipHoverTexts } from 'core/helpers/constants';
import { awsDateToDateObject } from 'core/helpers/formatters';
import { useStore } from 'core/store';

const useExtendedRoofCoverage = (home, state, fromPolicy) => {
  const { account, offer: offerStore } = useStore();

  if (!home) {
    return null;
  }

  const policyStore = account?.policies?.policy;

  const roofAgeLimit =
    !extendedCoverageRSByRoofMaterialStateExclusions.includes(state) && extendedCoverageRSLimits[home.roofType]
      ? extendedCoverageRSLimits[home.roofType]
      : coverageRSForcedAtXYearsStates[state] || 15;

  let dateToCheckAgainst = new Date();

  if (fromPolicy) {
    dateToCheckAgainst = new Date(localToUtcTime(policyStore?.policy.effectiveDate, state));
  } else {
    dateToCheckAgainst = awsDateToDateObject(offerStore?.offer?.quote?.global.rateControlDate);
  }

  const roofAge = dateToCheckAgainst.getFullYear() - home.roofYear;
  let forcedRSECoverage = false;
  if (fromPolicy) {
    forcedRSECoverage = roofAge >= roofAgeLimit && !policyStore?.segment.homeCoverage.coverageRSExtended;
  }
  let shouldCheckExpirationByAge = !extendedCoverageRSExpirationByAgeStateExclusions[state] && !fromPolicy;

  if (state === 'PA') {
    let createdDate = localToUtcTime(new Date(), state);
    if (fromPolicy) {
      const { inceptionDate } = policyStore?.policy;
      const { updatedDateTime } = policyStore?.policy.versionHistory.find(({ version }) => version === 1);
      createdDate = localToUtcTime(inceptionDate || updatedDateTime, state);
      forcedRSECoverage = forcedRSECoverage && isAfter(createdDate, new Date('2024-04-01'));
    } else {
      shouldCheckExpirationByAge = isAfter(createdDate, new Date('2024-04-01'));
    }
  }

  const isRSECoverageDisabled =
    coverageRSExtendedForcedStates[state] ||
    coverageRSForcedStates[state] ||
    (noShowRoofSurfacesExtendedUnderXYearsStates[state] &&
      roofAge <= noShowRoofSurfacesExtendedUnderXYearsStates[state]) ||
    forcedRSECoverage ||
    coverageRSEDisabledStates[state] ||
    (shouldCheckExpirationByAge && roofAge > roofAgeLimit);

  let tooltip = '';

  if (
    reopeningStates.includes(state) &&
    // TODO: Remove this condition when working on #18896
    !coverageRSEDisabledStates[state]
  ) {
    tooltip = isRSECoverageDisabled
      ? 'Due to the location of the home and the roof age, this home is required to have roof replacement cost coverage'
      : tooltipHoverTexts.coverageRSExtended;
  } else if (coverageRSDescriptionStates[state]) {
    tooltip = coverageRSDescriptionStates[state];
  } else if (isRSECoverageDisabled) {
    tooltip =
      'Based on the age and material of the roof, this home is not eligible for Roof Surfaces Extended Coverage. When not selected, Branch will pay for a portion of approved roof claims according to a roof payment schedule.';
  } else {
    tooltip = tooltipHoverTexts.coverageRSExtended;
  }

  return {
    isDisabled: isRSECoverageDisabled,
    tooltip,
    label: coverageRSLabelStates[state] || 'Roof Extended (Repair/Replace)'
  };
};

export default useExtendedRoofCoverage;
