import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(5),
    borderTop: `solid 1px ${theme.colorPalette.white_30_op_30}`
  }
});

export default makeStyles(styles);
