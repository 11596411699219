import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';

import { Button } from 'core/components/button';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import { capitalize } from 'core/helpers/formatters';
import { Loader } from '../loader';
import { AddressInformation, CarrierInformation, MemberInformation } from './sections';
import useStyles from './branch-plus-policy-details.styles';

const BranchPlusPolicyDetails = ({ isLoading, policy, error }) => {
  const classes = useStyles();
  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast.notify({
        type: 'error',
        message: error
      });
    }
  }, [error]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && policy && (
        <Section title="Policy information" type="SubSection">
          <div className={classes.container}>
            <Card type="primaryEditable">
              <div className={classes.header}>
                {policy.carrier.logo && <img className={classes.logo} src={policy.carrier.logo} alt="carrier logo" />}
                <Label type="subtitle">{capitalize(policy.carrier.name)}</Label>
              </div>
              <div className={classes.content}>
                <CarrierInformation
                  policyId={policy.policyId}
                  policyType={policy.policyType}
                  carrier={policy.carrier}
                />
                <Divider className={classes.divider} />
                <MemberInformation customer={policy.customer} />
                <Divider className={classes.divider} />
                <AddressInformation address={policy.address} />
              </div>
            </Card>
            {policy.carrier.loginUrl && (
              <Button
                className={classes.login}
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    `${policy.carrier.loginUrl.includes('https://') ? '' : 'https://'}${policy.carrier.loginUrl}`,
                    '_blank'
                  )
                }
              >
                Log into {capitalize(policy.carrier.name)}
              </Button>
            )}
          </div>
        </Section>
      )}
    </>
  );
};

BranchPlusPolicyDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  policy: PropTypes.object,
  error: PropTypes.string
};

BranchPlusPolicyDetails.defaultProps = {
  policy: null,
  error: null
};

export default BranchPlusPolicyDetails;
