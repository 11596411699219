import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    marginBottom: theme.spacing(8)
  },
  content: {
    width: 776,
    display: 'flex',
    alignItems: 'flex-end'
  },
  title: {
    width: 348
  },
  options: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
});

export default makeStyles(styles);
