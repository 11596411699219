import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';

import { Loading } from 'core';
import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import ValueField from 'core/components/value-field';
import useStyles from './agent-details.styles';
import Label from 'core/components/label/label';
import { useQueryParams } from 'core/hooks/useQueryParams';
import LabelWithCopyButton from 'core/components/label-with-copy-button';

const getFullName = (user) => {
  if (user?.attributes?.given_name || user?.attributes?.family_name) {
    return `${user?.attributes?.given_name || ''} ${user?.attributes?.family_name || ''}`;
  }
  return undefined;
};

const AgentDetails = () => {
  const classes = useStyles();
  const { account, search, affinityLookups } = useStore();
  const { policies } = account;
  const recentPolicy = policies.getRecentPolicy();

  const customerAffinityCode = recentPolicy?.segments?.[0]?.global.affinity;
  const affinityData = affinityLookups.data.find(({ affinity }) => affinity === customerAffinityCode);
  const initialProducerCognitoUsername = recentPolicy?.salesRep;
  const soldProducer = recentPolicy?.bindRep;
  const initialLeadSource = recentPolicy?.offer?.quote?.leadSource;
  const isAgentSold = customerAffinityCode && affinityData?.isAgency;
  const affinityInfo = isAgentSold && affinityData;
  const agencyInfo = affinityLookups.agencyInformation[affinityData?.data?.informationId || customerAffinityCode];
  const serviceAgreement = affinityData?.data?.serviceAgreement;

  const [agentCognitoUser, setAgencyCognitoUser] = useState();
  const [loading, setLoading] = useState(true);
  const [searchingStaffUsers, setSearchingStaffUsers] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const queryParams = useQueryParams();
  const agentDetailsElement = useRef(null);

  useEffect(() => {
    const isLoading = account.accountLoading || !account.id || searchingStaffUsers || affinityLookups.partialLoading;
    setLoading(isLoading);
  }, [
    account.accountLoading,
    account.id,
    searchingStaffUsers,
    affinityLookups,
    affinityLookups.partialLoading,
    customerAffinityCode,
    agencyInfo
  ]);

  useEffect(() => {
    if (queryParams.scroll === 'agentDetails' && !scrolled && !loading && agentDetailsElement.current !== null) {
      agentDetailsElement.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      setScrolled(true);
    }
  }, [loading, scrolled, queryParams]);

  useEffect(() => {
    if (customerAffinityCode && !agencyInfo && !affinityLookups.partialLoading) {
      affinityLookups.getAgencyInformation(affinityData?.data?.informationId || customerAffinityCode);
    }
  }, [affinityLookups, isAgentSold, affinityData, customerAffinityCode, agencyInfo]);

  useEffect(() => {
    if (initialProducerCognitoUsername && isAgentSold) {
      const getAgentDataFromSearchCognitoIndex = async () => {
        setSearchingStaffUsers(true);
        const user = await search.searchStaffUsers({ username: initialProducerCognitoUsername });
        setAgencyCognitoUser(user);
        setSearchingStaffUsers(false);
      };

      getAgentDataFromSearchCognitoIndex();
    }
  }, [initialProducerCognitoUsername, isAgentSold, search]);

  if (!recentPolicy) return null;

  return (
    <div ref={agentDetailsElement}>
      <Section title="Agent Details">
        {loading ? (
          <Loading />
        ) : (
          <Card id={'agentDetails'}>
            <Grid container className={classes.card}>
              {isAgentSold && agentCognitoUser && (
                <Grid item xs={12}>
                  <Label type="darkGreenSmallBold"> Agent </Label>
                </Grid>
              )}
              <Grid item xs={6}>
                <ValueField label="Primary Producer" value={initialProducerCognitoUsername || 'N/A'} />
              </Grid>
              <Grid item xs={6}>
                <ValueField label="Sold Producer" value={soldProducer || 'N/A'} />
              </Grid>
              {isAgentSold && agentCognitoUser && (
                <>
                  <Grid item xs={12}>
                    <ValueField label="Agent Full Name" value={getFullName(agentCognitoUser) || 'N/A'} />
                  </Grid>
                  <Grid item xs={12} className={classes.separator}>
                    <ValueField label="Agent Email" value={agentCognitoUser?.attributes?.email || 'N/A'} />
                  </Grid>
                  <Label type="darkGreenSmallBold"> Agency </Label>
                  <Grid item xs={12}>
                    <ValueField label="Agency Name" value={agencyInfo?.name || affinityInfo?.name || 'N/A'} />
                  </Grid>
                  <Grid item xs={12}>
                    <ValueField label="Agency Email" value={agencyInfo?.email || affinityInfo?.ccEmail || 'N/A'} />
                  </Grid>
                  <Grid item xs={6}>
                    <ValueField label="Serviced By" value={serviceAgreement ? 'Branch' : 'Agency'} />
                  </Grid>
                  {!serviceAgreement && (
                    <Grid item xs={12}>
                      <Label type="infoLabel" className={classes.copyButtonLabel}>
                        Agency Service Phone Number
                      </Label>
                      <LabelWithCopyButton type="infoValue">
                        {agencyInfo?.servicing_phone_number || 'N/A'}
                      </LabelWithCopyButton>
                    </Grid>
                  )}
                  <Grid item xs={12} className={classes.separator}>
                    <Label type="infoLabel" className={classes.copyButtonLabel}>
                      Agency Phone Number
                    </Label>
                    <LabelWithCopyButton type="infoValue">
                      {agencyInfo?.phone || affinityInfo?.phone || 'N/A'}
                    </LabelWithCopyButton>
                  </Grid>
                </>
              )}
              {isAgentSold && agentCognitoUser && (
                <Grid item xs={12}>
                  <Label type="darkGreenSmallBold"> Additional Information </Label>
                </Grid>
              )}
              <Grid item xs={6}>
                <ValueField label="Initial Lead Source" value={initialLeadSource || 'N/A'} />
              </Grid>
              <Grid item xs={6}>
                <ValueField label="Initial Affinity Code" value={customerAffinityCode || 'N/A'} />
              </Grid>
              {!isAgentSold && (
                <Grid item xs={6}>
                  <ValueField label="Serviced By" value="Branch" />
                </Grid>
              )}
            </Grid>
          </Card>
        )}
      </Section>
    </div>
  );
};

export default observer(AgentDetails);
