import { types, flow, getEnv, getParent } from 'mobx-state-tree';
import { errorClassificationLookup, rejectionTypeToPolicyTypeMap, policyType as PolicyType } from '@ourbranch/lookups';
import omit from 'lodash-es/omit';

import {
  mapRequestQuoteToInputType,
  hasRejection,
  formattedError,
  mapRevisedQuinstreetQuoteDetails
} from 'core/helpers/quoter.service';
import { track } from 'core/helpers/analytics';
import { withRetry } from 'core/helpers/with-retry';
import { REQUEST_QUOTE, SEND_CLUSTER_LINK, REQUEST_QUINSTREET_QUOTE } from './quote.queries';

const Error = types.model({
  type: types.maybe(types.string),
  code: types.union(types.string, types.number),
  message: types.maybe(types.string),
  data: types.maybe(types.frozen()),
  handleAs: types.maybe(types.string)
});

export const QuoteStore = types
  .model({
    loading: types.boolean,
    errors: types.array(Error),
    prefillData: types.maybeNull(types.frozen()),
    overriddenProtectedFields: types.array(types.string),
    offer: types.maybeNull(types.frozen())
  })
  .actions((self) => ({
    requestQuinstreetQuoteAction: flow(function* requestQuinstreetQuote({
      quoteDetails,
      webUserId,
      rep,
      policyId,
      offerId,
      policyType
    }) {
      self.errors = [];
      self.overriddenProtectedFields = [];
      try {
        const { client } = getEnv(self);
        self.loading = true;

        const parent = getParent(self);
        const customerId = policyId ? policyId.slice(0, 9) : null;
        yield parent.account.sendEventAsMember({
          eventName: 'Staff Generated QuinStreet Quote - Customer',
          properties: JSON.stringify({ webUserId, customerId, staffRep: rep.username }),
          userId: customerId || webUserId
        });

        track('Staff Generated QuinStreet Quote', { webUserId, customerId, staffRep: rep.username });

        const {
          data: { quinstreetOffers }
        } = yield withRetry(
          client.query({
            query: REQUEST_QUINSTREET_QUOTE,
            variables: {
              quoteDetails: {
                ...mapRevisedQuinstreetQuoteDetails({ ...quoteDetails })
              },
              policyType,
              policyId: policyId || undefined,
              offerId: offerId || undefined
            },
            fetchPolicy: 'no-cache'
          }),
          'requestQuinstreetQuote'
        );

        const carrierNames = [
          // clearcover is included for dev environments
          'clearcover',
          'bristol west',
          'dairyland',
          'dairyland insurance',
          'safeco'
        ];
        if (policyType === PolicyType.Auto) {
          carrierNames.push('progressive');
        } else if (policyType === PolicyType.Home) {
          carrierNames.push('asi/progressive');
        }

        quinstreetOffers.forEach((offer) => {
          if (offer?.status === 'success') {
            track('Staff Generated QuinStreet Quote Response Success', {
              webUserId,
              premium: offer?.premium, // need to figure this out do we want multiple events?
              staffRep: rep.username,
              carrier: offer?.carrierName,
              customerId
            });
          } else {
            track('Staff Generated QuinStreet Quote Response Error', {
              webUserId,
              staffRep: rep.username,
              carrier: offer?.carrierName,
              customerId
            });
          }
        });

        const quinstreetOffersFilteredAndSorted = quinstreetOffers
          .filter((offer) => carrierNames.includes(offer.carrierName.toLowerCase()))
          .sort((a, b) => {
            if (a.status === 'success' && b.status === 'success') {
              // lowest monthly should show up first
              if (a.monthly <= b.monthly) {
                return -1;
              }
              return 1;
            }
            // success should show up before errors
            if (a.status === 'success') {
              return -1;
            }
            return 1;
          });

        return quinstreetOffersFilteredAndSorted;
      } catch (error) {
        track('Staff Generated QuinStreet Quote Response Error', {
          webUserId,
          staffRep: rep.username
        });
        // eslint-disable-next-line no-console
        console.error(`Error in requestQuinstreetQuote: ${error}`);
        throw error;
      } finally {
        self.loading = false;
      }
    }),
    requestQuoteAction: flow(function* requestQuote(userInput, allowedStates, retry = true) {
      self.errors = [];
      self.overriddenProtectedFields = [];
      if (allowedStates.includes(userInput.state)) {
        // check that the user has a license for this state
        try {
          const { client } = getEnv(self);
          self.loading = true;
          track('Staff Generated Quote', { userInput });

          const {
            data: { offer },
            errors
          } = yield withRetry(
            client.query({
              query: REQUEST_QUOTE,
              variables: mapRequestQuoteToInputType(userInput),
              fetchPolicy: 'no-cache'
            }),
            'requestQuote'
          );
          if (!offer && retry && errors?.[0]?.errorType === 'ExecutionTimeout') {
            self.requestQuoteAction(userInput, allowedStates, false);
            return;
          }
          if (errors?.length) {
            self.errors = errors.map((error) => {
              try {
                const parsed = JSON.parse(error.message);
                return {
                  code: Number(parsed.code),
                  message: parsed.message
                };
              } catch (e) {
                return e;
              }
            });
            self.loading = false;
            return;
          }
          if (hasRejection(offer)) {
            self.errors = offer.rejections.map(({ type, value, data }) => {
              return {
                type,
                code: value,
                data: data && JSON.parse(data)
              };
            });
            self.loading = false;

            if (offer?.options?.length > 0) {
              self.offer = offer;
              self.loading = false;
            }
          } else {
            self.offer = offer;
            if (offer.options.some((o) => o.type === 'H')) {
              self.checkDifferenceInProtectedFields(userInput);
            }
            self.loading = false;
          }
          self.setPrefillData(offer, false);
          self.fetchAffinityData();
          track('Staff New Offer Received', {
            user: userInput.rep,
            offerId: offer?.id,
            requestedPolicyType: userInput.policyType,
            errors: self.errors.map((error) => error.code)
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error in requestQuote: ${error}`);
          self.loading = false;
          self.handleError(error);
          track('Staff New Offer Received', {
            user: userInput.rep,
            offerId: 'No offer generated',
            requestedPolicyType: userInput.policyType,
            errors: self.errors.map((error) => error.code)
          });
        }
      } else {
        const notLicensedError = {
          message:
            'You are not licensed in this state, if you believe this is a mistake, please contact Branch at 833-4BRANCH (833-427-2624) support@ourbranch.com'
        };
        self.loading = false;
        self.handleError(notLicensedError);
      }
    }),
    clearOffer() {
      self.loading = false;
      self.errors = [];
      self.prefillData = null;
      self.overriddenProtectedFields = [];
      self.offer = null;
    },
    sendClusterLink: flow(function* sendClusterLink(offerId, email, quote, agent) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: SEND_CLUSTER_LINK,
        variables: {
          offerId,
          revisedQuoteDetails: quote,
          email,
          agent
        }
      });
    }),
    fetchAffinityData: flow(function* fetchAffinityData(affinity) {
      if (affinity) {
        const { affinityLookups } = getParent(self);
        yield affinityLookups.getByAffinity(affinity);
      }
    }),
    handleError: (error) => {
      const parsed = formattedError(error);
      if (parsed) {
        self.errors = [...self.errors, parsed];
      }
    },
    setPrefillData(offer, skipRejectionCheck) {
      self.overriddenProtectedFields = [];
      if (!skipRejectionCheck) {
        if (hasRejection(offer)) {
          self.errors = offer.rejections.map((rejection) => ({
            type: rejection.type,
            code: rejection.value,
            data: rejection.data && JSON.parse(rejection.data)
          }));
        }
      }
      self.prefillData = omit(offer.quote, '__typename');
    },
    clearPrefillData() {
      self.prefillData = null;
      self.overriddenProtectedFields = [];
    },
    clearError(code) {
      self.errors = self.errors.filter((err) => err.code !== code);
    },
    setErrorToShowAsRejection(code) {
      self.errors = self.errors.map((err) => {
        if (err.code === code) {
          return { ...err, handleAs: 'REJECTION' };
        }
        return err;
      });
    },
    checkDifferenceInProtectedFields(userInput) {
      const fieldNameMap = {
        yearBuilt: 'Year built',
        sqFt: 'Above ground sqft',
        constructionType: 'Construction type',
        basementSqFt: 'Basement sqft',
        roofShape: 'Roof shape'
      };
      self.overriddenProtectedFields = ['yearBuilt', 'sqFt', 'constructionType', 'basementSqFt', 'roofShape']
        .filter((field) => userInput.home[field] && userInput.home[field] !== self.offer.quote.home[field])
        .map((field) => fieldNameMap[field]);
    },
    getAlerts(codesArray = [], policyType) {
      return self.errors.filter((error) => {
        // if this is a bypass alert and the requested policy type is not in the rejection array, dont show this error
        if (error.code && errorClassificationLookup[Number(error.code)] === 'BYPASS_ALERT' && policyType) {
          const rejectionArray = rejectionTypeToPolicyTypeMap[error.type];
          if (rejectionArray && !rejectionArray.includes(policyType)) {
            return false;
          }
        }
        const isAlert =
          error.code &&
          ['ALERT', 'DISMISSIBLE_REJECTION', 'BYPASS_ALERT'].includes(errorClassificationLookup[Number(error.code)]);
        const handleAsAlert = error.handleAs === 'ALERT' || error.handleAs === 'BYPASS_ALERT';
        const includeAlert = codesArray.length === 0 || codesArray.includes(Number(error.code));
        return (isAlert || handleAsAlert) && includeAlert && error.handleAs !== 'REJECTION';
      });
    },
    getBlocks() {
      return self.errors.filter((error) => {
        const isBlock = !error.code || errorClassificationLookup[Number(error.code)] === 'BLOCK';
        const handleAsBlock = error.handleAs === 'BLOCK';

        return isBlock || handleAsBlock;
      });
    },
    getOfferDetails() {
      return { offer: self.offer, alerts: self.getAlerts(), blocks: self.getBlocks(), rejections: self.rejections };
    }
  }))
  .views((self) => ({
    get rejections() {
      return self.errors.filter((err) => {
        // if error code is 10000 or greater, it's a rejection
        if (
          err.code &&
          err.code >= 10000 &&
          ((!err.handleAs && !errorClassificationLookup[err.code]) || err?.handleAs === 'REJECTION')
        ) {
          return true;
        }
        return false;
      });
    }
  }));
