import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { ActionButton } from 'core/components/action-button';
import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import AgencySupportBannerExtended from 'core/components/agency-support-banner-extended';
import useSession from 'core/hooks/use-session';
import useStyles from './pay-ahead-modal.styles';

const PayAheadDisabledModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { isAgency } = useSession();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle className={classes.dialogContainer}>
        <Label type="titleSecondary" className={classes.title}>
          Pay Ahead is disabled for one or more of the following reasons
        </Label>
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <div className={classes.text}>
          <ul className={classes.list}>
            <li>The policy is cancelled/pending cancellation</li>
            <li>The policy’s most recent payment failed</li>
            <li>The policy has an active billing hold</li>
            <li>The policy is fully paid</li>
          </ul>
        </div>
      </DialogContent>
      {isAgency && (
        <AgencySupportBannerExtended content="Contact us if you have questions or still want to process a payment." />
      )}
    </Dialog>
  );
};

PayAheadDisabledModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export { PayAheadDisabledModal };
