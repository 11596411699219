import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import { Paper, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Loading } from 'core/components/loading';
import { Table, TableHeaderCell } from 'core/components/table';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { withToast } from 'core/components/toast';
import { dateFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import styles from './my-community.styles';

const MyCommunity = observer(({ classes, accountId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortDirection, setSortDirection] = useState('asc');
  const { account } = useStore();

  const sortedCommunity = account.sortedCommunity(sortDirection);
  const loading = account.accountLoading;
  const sort = (_columnId, _active, direction) => setSortDirection(direction);

  const handleChangePage = (_event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Section title="My Community" rightLabel="Total" rightValue={`${loading ? '...' : sortedCommunity.length} active`}>
      {loading ? (
        <Loading />
      ) : (
        <Card className={classes.myCommunityCard}>
          {sortedCommunity.length > 0 ? (
            <Table
              selfContained={false}
              header={
                <>
                  <TableHeaderCell key={'name'} columnId={'name'} mode={null} onSort={sort}>
                    Name
                  </TableHeaderCell>
                  <TableCell align="right">Added date</TableCell>
                </>
              }
              body={
                <>
                  {sortedCommunity.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                    <TableRow key={index} hover classes={{ hover: classes.hover, root: classes.root }}>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="right">{dateFormatter(item.communityTimeStamp)}</TableCell>
                    </TableRow>
                  ))}
                </>
              }
              paginationEnabled={sortedCommunity.length > rowsPerPage}
              count={sortedCommunity.length}
              rowsPerPage={rowsPerPage}
              currentPage={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <Paper classes={{ root: classes.noData }}>
              <p align="center">This member has yet to invite someone</p>
            </Paper>
          )}
        </Card>
      )}
    </Section>
  );
});

MyCommunity.propTypes = {
  classes: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired
};

export default flowRight(withStyles(styles), withToast)(MyCommunity);
