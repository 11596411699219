import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { TableCell, TableRow } from '@material-ui/core';
import { paymentMethod as lookupsPaymentMethod } from '@ourbranch/lookups';

import Section from 'core/components/section';
import { Table } from 'core';
import { useStore } from 'core/store';
import { useToast } from 'core/components/toast';
import RemoveButton from 'core/components/remove-button';
import { NoDataCard } from '../../../no-data-card';
import ConfirmRemoveModal from './confirm-remove-payment-method-modal';
import useSession from 'core/hooks/use-session';

const determinePaymentDescription = (paymentMethod) => {
  if (paymentMethod.bankName) {
    return `${paymentMethod.bankName} ${paymentMethod.last4}`;
  }
  return `${paymentMethod.brand} ${paymentMethod.last4} Exp: ${paymentMethod.expMonth}/${paymentMethod.expYear}`;
};

const PaymentMethods = () => {
  const {
    account: {
      policies: {
        policy: {
          policy: { paymentMethod: currentPaymentMethod },
          billingDetails: { allPaymentMethods }
        }
      }
    }
  } = useStore();
  const toast = useToast();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const { canRemoveFinalPaymentMethod } = useSession();

  const onPaymentMethodRemoval = (paymentMethod) => {
    let ccPaymentMethods = 0;
    let achPaymentMethods = 0;

    for (const paymentMethod of allPaymentMethods) {
      if (paymentMethod.bankName) {
        achPaymentMethods++;
      } else {
        ccPaymentMethods++;
      }
    }

    const onlyOneCCorACH =
      (currentPaymentMethod === lookupsPaymentMethod.CreditCard &&
        ccPaymentMethods.length === 1 &&
        !paymentMethod.bankName) ||
      (currentPaymentMethod === lookupsPaymentMethod.ACH && achPaymentMethods.length === 1 && paymentMethod.bankName);
    if (onlyOneCCorACH && !canRemoveFinalPaymentMethod) {
      toast.notify({
        type: 'error',
        message: `To pay via ${
          currentPaymentMethod === lookupsPaymentMethod.CreditCard ? 'credit card' : 'ACH'
        }, the policy needs at least one payment method of this type. Please update the payment method or add a new one before removing it.`
      });
    } else {
      setSelectedPaymentMethod(paymentMethod);
      setConfirmModalOpen(true);
    }
  };

  return (
    <>
      <Section title="Payment Methods" type="SubSection" rightLabel="Total" rightValue={[allPaymentMethods.length]}>
        {allPaymentMethods.length ? (
          <Table
            header={
              <>
                <TableCell>Description</TableCell>
                <TableCell>ID</TableCell>
                <TableCell />
              </>
            }
            body={
              <>
                {allPaymentMethods.map((paymentMethod) => (
                  <TableRow key={paymentMethod.id}>
                    <TableCell>{determinePaymentDescription(paymentMethod)}</TableCell>
                    <TableCell>{paymentMethod.id}</TableCell>
                    <TableCell>
                      <RemoveButton
                        onClick={() => {
                          onPaymentMethodRemoval(paymentMethod);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            }
          />
        ) : (
          <NoDataCard
            message="There are no methods of payment for this member."
            subMessage="When you add a method of payment, it'll appear here."
            icon={false}
          />
        )}
      </Section>
      {confirmModalOpen && (
        <ConfirmRemoveModal
          open={confirmModalOpen}
          paymentMethod={selectedPaymentMethod}
          onClose={() => setConfirmModalOpen(false)}
          paymentMethodDescription={determinePaymentDescription(selectedPaymentMethod)}
        />
      )}
    </>
  );
};

export default observer(PaymentMethods);
