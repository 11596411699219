import { MeiliSearch } from 'meilisearch';

/** @type {MeiliSearch} */
let client;

export function getClient(userPreferences) {
  if (!client) {
    client = new MeiliSearch({
      host: userPreferences.meiliSearchHost,
      apiKey: userPreferences.meiliSearchKey
    });
  }

  return client;
}
