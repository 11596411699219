/* eslint-disable func-names */
import * as Yup from 'yup';
import {
  aopDeductibleIsAlwaysPercent,
  noWindHailDeductibleStates,
  windHailDeductibleCanVaryByCounty
} from '@ourbranch/lookups';
import { validHomePurchaseDate } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.mixed, 'validHomePurchaseDate', validHomePurchaseDate);

const schema = (state, messageContext, session, initialValuesContext) =>
  Yup.object().shape({
    home: Yup.object().shape({
      purchaseDate: Yup.string().validHomePurchaseDate('home.purchaseDate', false),
      roofYear: Yup.number()
        .positive()
        .integer()
        .test('is-older-than-year-built', 'Roof year cannot be older than home built year', function (value) {
          const { yearBuilt } = this.parent;
          if (!yearBuilt) {
            return true;
          }
          return value >= yearBuilt;
        }),
      sqFt: Yup.number().test(
        'is-greater-than-data-pull-sqft',
        `Our data shows this home as ${
          messageContext?.home?.originalBasementSqFt + messageContext?.home?.originalSqFt ||
          initialValuesContext.home.sqFt
        } ${
          messageContext?.home?.originalBasementSqFt + messageContext?.home?.originalSqFt
            ? ' total sqft, including basement sqft,'
            : ' above-ground sqft,'
        } and it must remain this size or larger. If you need to lower the value from our data, please follow your designated escalation process.`,
        function (value) {
          if (session.canEditUneditablePropertyDetailsFields) {
            return true;
          }
          const { originalBasementSqFt, originalSqFt } = this.parent;
          const { sqFt: initialSqFt } =
            this.options.context && this.options.context.initialValues ? this.options.context.initialValues.home : {};

          // if no originalSqFt, can't be less than initial value
          if (!originalSqFt) {
            return value >= initialSqFt;
          }

          const originalTotalSqFt = originalSqFt + originalBasementSqFt || 0;
          const currentBasementSqFtValue = this.options.context.home.basementSqFt || 0;
          const currentTotalSqFt = value + currentBasementSqFtValue || 0;

          // decrease sqft
          if (value < originalSqFt) {
            // if the initial sqft is already lower than original, this offer let it pass so long as the value is same or higher than initial
            // this lets HD modify value, send link to agents, and lets the agents go about business
            if (initialSqFt < originalSqFt) {
              return value >= initialSqFt;
            }
            // if sqft decrease, must increase basementsqft
            if (currentBasementSqFtValue <= originalBasementSqFt) {
              return false;
            }
            // total sqft cannot decrease
            return currentTotalSqFt >= originalTotalSqFt;
          }
          // must be greater than original
          return value >= originalSqFt;
        }
      ),
      basementSqFt: Yup.number()
        .integer()
        .test(
          'is-greater-than-data-pull-basementSqFt',
          `Our data shows this home as ${
            messageContext?.home?.originalBasementSqFt + messageContext?.home?.originalSqFt ||
            initialValuesContext.home.basementSqFt
          } ${
            messageContext?.home?.originalBasementSqFt + messageContext?.home?.originalSqFt
              ? ' total sqft, including above-ground sqft,'
              : ' basement sqft,'
          } and must remain this size or larger. If you need to lower the value from our data, please follow your designated escalation process.`,
          function (value) {
            if (session.canEditUneditablePropertyDetailsFields) {
              return true;
            }
            const { originalBasementSqFt, originalSqFt } = this.parent;
            const { basementSqFt: initialBasementSqFt } =
              this.options.context && this.options.context.initialValues ? this.options.context.initialValues.home : {};

            // if no originalBasementSqFt, can't be less than initial value
            if (!originalBasementSqFt) {
              return value >= initialBasementSqFt;
            }

            const currentAboveGroundSqFt = this.options.context.home.sqFt;
            const originalTotalSqFt = originalSqFt + originalBasementSqFt || 0;
            const currentTotalSqFt = value + currentAboveGroundSqFt || 0;

            // decrease sqft
            if (value < originalBasementSqFt) {
              // if the initial sqft is already lower than original, this offer let it pass so long as the value is same or higher than initial
              // this lets HD modify value, send link to agents, and lets the agents go about business
              if (initialBasementSqFt < originalBasementSqFt) {
                return value >= initialBasementSqFt;
              }
              if (currentAboveGroundSqFt <= originalSqFt) {
                // if basementSqft decrease, must increase sqft
                return false;
              }
              // total sqft cannot decrease
              return currentTotalSqFt >= originalTotalSqFt;
            }
            // must be greater than original
            return value >= originalBasementSqFt;
          }
        )
    }),
    homeCoverage: Yup.object().shape({
      deductibleWindHail: Yup.number()
        .nullable()
        .test(
          'CompareToAllOtherPerils',
          'Wind & Hail Deductible must be greater than or equal to all other perils deductible',
          function (value) {
            const windHailExclusion = this.options.context?.homeCoverage?.windHailExclusion;
            if (windHailExclusion) {
              // force pass since no need to validiate this if excluded
              return true;
            }
            const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
            const county = this.options.context.home.county.toUpperCase();
            const windHailInState =
              (!windHailDeductibleCanVaryByCounty[state] ||
                (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][county])) &&
              !noWindHailDeductibleStates[state];

            if (hasHomeSelected && windHailInState) {
              const coverageA = this.options.context.homeCoverage?.coverageA;
              const windHailIsPercent = value < 1;
              const allOtherPerilsIsPercent = this.options.context.homeCoverage?.deductibleAllOther < 1;

              // since value is a %, we need to multiply it by coverage A to see the deductible amount
              const allOtherPerilsAmount = allOtherPerilsIsPercent
                ? this.options.context.homeCoverage?.deductibleAllOther * coverageA
                : this.options.context.homeCoverage?.deductibleAllOther;
              const windHailAmount = windHailIsPercent ? value * coverageA : value;

              return windHailAmount >= allOtherPerilsAmount;
            }
            return true;
          }
        ),
      deductibleAllOther: Yup.number()
        .nullable()
        .test(
          'WindHailPercentageMin',
          'All Other Perils deductible must be a percentage for this state',
          function (value) {
            const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
            if (hasHomeSelected && aopDeductibleIsAlwaysPercent[state] && value > 1) {
              return false;
            }
            return true;
          }
        )
    })
  });

export default schema;
