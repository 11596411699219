import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import {
  rentersPolicyAvailable,
  editableHouseholdMembersStates,
  driverCarAssignmentStates,
  nonQuinstreetStates,
  editableResidenceInsuranceType,
  isResidentialOwnerStates,
  policyType as PolicyType
} from '@ourbranch/lookups';
import { policyTypes } from '@ourbranch/policy-types';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { useDisabledState } from 'common/disabled-context';
import CarAssignment from 'common/components/auto/car-assignment';
import { QuinStreet } from 'common/components/quinstreet';
import { AutoBreakdownPreview } from './auto-breakdown-preview';
import { Coverage } from './auto-coverage';
import Cars from './cars';
import Trailers from './trailers';
import Renters from './renters';
import PriorCoverage from './prior-coverage';
import AutoPolicyDetails from './auto-policy-details';
import { ResidenceInsuranceType } from './residence-insurance-type';

function AutoOffer() {
  const { values } = useFormikContext();
  const { offer } = useStore();
  const { canQuoteQuinstreet } = useContext(AuthContext);
  const { disabled } = useDisabledState();
  const { state, selectedOption } = offer;

  const showAutoPolicyDetails = editableHouseholdMembersStates.includes(state);
  const isQuinstreetAvailable = canQuoteQuinstreet && !nonQuinstreetStates.includes(state);

  // When looking at home options, can only add Renters when HA is selected
  // When looking at renters options, the renters details will appear in a new Tab at top of offer
  // so that's why we're not including that as part of the rendering logic here in Auto Details
  const showRenters =
    rentersPolicyAvailable[state] && selectedOption === policyTypes.HABundle && values.offerings.offerRenters;

  const showResidenceInsuranceTypeSelector =
    selectedOption === policyTypes.Auto &&
    (editableResidenceInsuranceType.includes(state) || isResidentialOwnerStates.includes(state));

  return (
    <>
      <Cars disabled={disabled} />
      {driverCarAssignmentStates[state] && <CarAssignment state={state} />}
      <Trailers disabled={disabled} offer={offer} />
      {showResidenceInsuranceTypeSelector && <ResidenceInsuranceType disabled={disabled} />}
      <Coverage disabled={disabled} offer={offer} />
      {showAutoPolicyDetails && <AutoPolicyDetails disabled={disabled} />}
      <PriorCoverage />
      {showRenters && <Renters disabled={disabled} />}
      {isQuinstreetAvailable && <QuinStreet policyType={PolicyType.Auto} />}
      <AutoBreakdownPreview
        offer={offer?.offer}
        cars={getIn(values, 'cars')}
        autoCoverage={getIn(values, 'autoCoverage')}
        policyType={selectedOption}
      />
    </>
  );
}

export default observer(AutoOffer);
