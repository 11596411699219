import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  BranchPlusPolicyDetails,
  ServiceInstructions,
  Header as BranchPlusHeader
} from 'common/components/branch-plus-policy';
import { useStore } from 'core/store';
import LayoutWithSidebar from 'core/components/layout-with-sidebar';
import { Policy } from '../policy';
import { PolicyHeader } from '../policy/header';
import { PolicySidebar } from '../policy-sidebar';
import { CustomerHeader } from '../header';
import { CustomerSidebar } from '../sidebar';
import Customer from 'customer/customer';
import { useCustomerProvider } from './useCustomerProvider';

export const PolicyRoute = ({ accountId, policyId, history }) => {
  useCustomerProvider({ accountId, policyId });
  return <LayoutWithSidebar header={PolicyHeader} content={Policy} side={PolicySidebar} history={history} />;
};

PolicyRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export const BranchPlusPolicyRoute = observer(({ accountId, policyId, history }) => {
  useCustomerProvider({ accountId, branchPlusPolicyId: policyId });
  const {
    account: {
      branchPlusPolicies: {
        branchPlusPolicy: { loading, policy }
      }
    }
  } = useStore();

  const isLoading = loading || !policy;

  return (
    <LayoutWithSidebar
      header={BranchPlusHeader}
      content={BranchPlusPolicyDetails}
      side={ServiceInstructions}
      history={history}
      isLoading={isLoading}
      policy={policy}
      onBack={() => history.push(`/customer/${accountId}`)}
    />
  );
});

PolicyRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export const CustomerRoute = ({ accountId, history }) => {
  useCustomerProvider({ accountId });
  return (
    <LayoutWithSidebar
      header={CustomerHeader}
      content={Customer}
      side={CustomerSidebar}
      history={history}
      accountId={accountId}
    />
  );
};

CustomerRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};
