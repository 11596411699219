import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import { Label } from 'core/components/label';
import { Table } from 'core/components/table';
import NoResults from '../no-results';
import Header from './header';
import useStyles from './branch-plus-results.styles';
import Row from './row';

const BranchPlusResults = ({ onPageChange, loading, hits, totalHits, page, columnsToUse, error }) => {
  const classes = useStyles();

  return (
    <>
      <Label type="subtitle">Branch Plus Policies</Label>
      <Paper className={classes.resultsContainer}>
        {Boolean(loading || hits.length) && (
          <Table
            selfContained={false}
            loading={loading}
            paginationEnabled
            count={totalHits}
            rowsPerPage={hits.length}
            currentPage={page}
            onPageChange={(_, page) => onPageChange(page)}
            header={<Header columnsToUse={columnsToUse} />}
            body={<>{Boolean(hits && hits.length) && hits.map((hit) => <Row key={hit.policy_id} policy={hit} />)}</>}
          />
        )}
        {Boolean(!loading && hits.length === 0) && <NoResults error={error} />}
      </Paper>
    </>
  );
};

BranchPlusResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  hits: PropTypes.array,
  totalHits: PropTypes.number,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  columnsToUse: PropTypes.array,
  error: PropTypes.object
};

BranchPlusResults.defaultProps = {
  hits: [],
  totalHits: 0,
  columnsToUse: ['firstName', 'lastName', 'address', 'state', 'memberId', 'carrier', 'policyType', 'navigate'],
  error: null
};

export default memo(BranchPlusResults);
