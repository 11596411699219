import { basementTypes } from '@ourbranch/lookups';
import { get } from 'lodash-es';

import Notification from 'core/helpers/notifications';

export const determineIfOfferNeedsUWForm = ({ offer, priorQuoteWithPreBindUWRejections, setFormManually }) => {
  if (setFormManually) {
    return true;
  }
  const { selectedOption } = offer?.quote;
  // if either home or auto verification is filled
  // need the != vs !== so we can identify if value is null or undefined
  if (
    offer?.quote?.preBindUWCheck?.homeVerification?.signedPropertyUWAttestation != null ||
    offer?.quote?.preBindUWCheck?.autoVerification?.signedPropertyUWAttestation != null
  ) {
    const { homeVerification, autoVerification } = offer?.quote?.preBindUWCheck;

    if (homeVerification?.signedPropertyUWAttestation && autoVerification?.signedPropertyUWAttestation) {
      // if form is signed for both, we don't need to show again
      return false;
    }
    if (
      (autoVerification?.ineligibleForAutoDueToUWSelfReport || priorQuoteWithPreBindUWRejections?.ineligibleForAuto) &&
      (homeVerification?.ineligibleForHomeDueToUWSelfReport || priorQuoteWithPreBindUWRejections?.ineligibleForHome)
    ) {
      // if ineligible for home and auto, return false
      return false;
    }

    // auto
    if (selectedOption === 'A' || selectedOption === 'AB') {
      // if signed or ineligible
      if (
        autoVerification?.ineligibleForAutoDueToUWSelfReport ||
        priorQuoteWithPreBindUWRejections?.ineligibleForAuto ||
        autoVerification?.signedPropertyUWAttestation
      ) {
        return false;
      }
      // eligible for auto and hasn't signed
      return true;
    }

    // home
    if (selectedOption === 'H' || selectedOption === 'HB') {
      // if signed or ineligible
      if (
        homeVerification?.ineligibleForHomeDueToUWSelfReport ||
        priorQuoteWithPreBindUWRejections?.ineligibleForHome ||
        homeVerification?.signedPropertyUWAttestation
      ) {
        return false;
      }
      // eligible for home and hasn't signed
      return true;
    }

    // if we are here, then we are eligible
    if (selectedOption === 'HA') {
      if (offer.quote.home.homeLocation.state !== 'FL' && homeVerification?.signedPropertyUWAttestation) {
        // for now, all states (except for FL) only need home
        return false;
      }
      if (homeVerification?.signedPropertyUWAttestation && autoVerification?.signedPropertyUWAttestation) {
        return false;
      }
      return true;
    }
  } else if (
    (priorQuoteWithPreBindUWRejections?.ineligibleForHome && (selectedOption === 'H' || selectedOption === 'HB')) ||
    (priorQuoteWithPreBindUWRejections?.ineligibleForAuto && (selectedOption === 'A' || selectedOption === 'AB'))
  ) {
    return false;
  } else if (offer.quote.home) {
    if (
      offer.quote.home.homeLocation.state !== 'FL' &&
      selectedOption?.includes('H') &&
      (offer?.quote?.home?.yearBuilt < 1960 || offer?.quote?.home?.basementType === basementTypes.Raised)
    ) {
      return true;
    }
    if (offer.quote.home.homeLocation.state === 'FL') {
      return true;
    }
    // all other cases
    return false;
  } else {
    return false;
  }
};

export const getUWNotification = ({ offer, priorQuoteWithPreBindUWRejections, needsPreBindUWForm }) => {
  const notifications = [];
  // if self reported issue
  if (
    Object.values(priorQuoteWithPreBindUWRejections).some((condition) => condition === true) ||
    get(offer, 'quote.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport', false) ||
    get(offer, 'quote.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport', false)
  ) {
    notifications.push(Notification.Offer.SelfReportedUWFlags);
  }

  // if needs form
  if (needsPreBindUWForm) {
    notifications.push(Notification.Offer.OfferNeedsUWReview);
  }

  return notifications;
};

export const offerHasValidOptions = ({ offer, priorQuoteWithPreBindUWRejections }) => {
  if (offer?.options?.length < 1) {
    return false;
  }

  const ineligiblePolicyTypes = [];
  if (priorQuoteWithPreBindUWRejections?.ineligibleForAuto) {
    ineligiblePolicyTypes.push('A', 'AB', 'AR', 'HA');
  }

  if (priorQuoteWithPreBindUWRejections?.ineligibleForHome) {
    ineligiblePolicyTypes.push('H', 'HB', 'HA');
  }
  const remainingOptions = offer.options.filter((o) => !ineligiblePolicyTypes.includes(o.type));

  return !!remainingOptions.length;
};
