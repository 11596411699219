import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import reverse from 'lodash-es/reverse';
import sortBy from 'lodash-es/sortBy';

import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import { Table } from '../table';
import TableHeaderCell from './header-cell';
import useStyles from './sortable-table.styles';
import { merge } from 'lodash-es';

const Row = ({ row, onClick, dataCy, headers, mode, classes, formatters }) => {
  // allows for a row to be a different color mode than the rest of the table
  // example: mode="red" for cancelled policy row, but rest of table is mode="dark"
  const { rowMode } = row;
  const tableRowMode = rowMode || mode;
  return (
    <TableRow
      key={row.id}
      onClick={onClick ? () => onClick(row) : null}
      data-cy={`${dataCy}-${row.id}`}
      classes={tableRowMode && MapClassesToElem(`${tableRowMode}-${ElemType.TableRow}`, classes)}
    >
      {headers.map(([key]) => (
        <TableCell
          key={key}
          data-cy={`${dataCy}-${key}-${row.id}`}
          classes={mode && MapClassesToElem(`${mode}-${ElemType.TableCell}`, classes)}
        >
          {formatters[key] ? formatters[key](row[key], row) : row[key]}
        </TableCell>
      ))}
    </TableRow>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rowMode: PropTypes.PropTypes.oneOf([null, 'dark', 'red']).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  dataCy: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  mode: PropTypes.oneOf([null, 'dark']),
  classes: PropTypes.object.isRequired,
  formatters: PropTypes.object.isRequired
};

Row.defaultProps = {
  mode: null
};

const SortableTable = ({ classes, data, onClick, dataCy, headers, mode, formatters, rowCreator, ...rest }) => {
  const defaultClasses = useStyles();
  const merged = merge(defaultClasses, classes);
  const [tableConfig, setTableConfig] = useState({
    orderBy: '',
    direction: 'asc',
    data: data.map((row) => ({
      row,
      onClick,
      dataCy,
      headers,
      mode,
      classes: merged,
      formatters
    }))
  });

  const handleSort = (column) => {
    setTableConfig(({ orderBy, direction, data }) => ({
      orderBy: column,
      direction: orderBy === column && direction === 'asc' ? 'desc' : 'asc',
      data: orderBy !== column ? sortBy(data, (element) => element.row[column]) : reverse(data)
    }));
  };

  return (
    <Table
      {...rest}
      mode={mode}
      header={headers.map(([key, value]) => (
        <TableHeaderCell
          key={key}
          columnId={key}
          mode={mode}
          onSort={handleSort}
          classes={merged}
          active={tableConfig.orderBy === key}
          direction={tableConfig.direction}
        >
          {value}
        </TableHeaderCell>
      ))}
      body={tableConfig.data.map(rowCreator)}
    />
  );
};

SortableTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.array).isRequired,
  rowCreator: PropTypes.func,
  mode: PropTypes.oneOf(['dark', null]),
  formatters: PropTypes.object,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  dataCy: PropTypes.string
};

SortableTable.defaultProps = {
  rowCreator: Row,
  mode: null,
  onClick: null,
  formatters: {},
  dataCy: 'Sortable',
  classes: {}
};

export default SortableTable;
