import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(8, 6, 3)
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  title: {
    color: theme.colors.green_15,
    fontWeight: 600
  },
  subtitle: {
    color: theme.colors.green_15
  }
}));

export default useStyles;
