import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4)
  },
  notification: {
    margin: 0,
    boxShadow: 'none',
    textAlign: 'start'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  link: {
    fontWeight: 600,
    display: 'flex',
    gap: theme.spacing(2)
  }
});

export default makeStyles(styles);
