import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import { Grid } from '@material-ui/core';
import { lookupsJson } from '@ourbranch/lookups';

import { useDisabledState } from 'common/disabled-context';
import Field from 'core/components/form/form.v2';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import useStyles from './home.styles';

const Home = () => {
  const classes = useStyles();
  const { setDisabled } = useDisabledState();
  const {
    quote: { errors }
  } = useStore();
  const { canEditUneditablePropertyDetailsFields } = useSession();
  const { values, setFieldValue } = useFormikContext();

  const areProtectedFieldsDisabled =
    !values.isNewConstruction &&
    !canEditUneditablePropertyDetailsFields &&
    !errors.some((error) => error.code === 50006);

  setDisabled(
    !canEditUneditablePropertyDetailsFields && errors.some((error) => [50002, 50005, 50010].includes(error.code))
  );

  useEffect(() => {
    if (values.isNewConstruction) {
      setFieldValue('home.roofOriginal', 'true');
    }
    if (!values.isNewConstruction) {
      setFieldValue('home.roofOriginal', undefined);
    }
  }, [values.isNewConstruction]);

  const {
    typeOfHome: tohOptions,
    homeQuality: hqOptions,
    constructionType: ctOptions,
    basementType: btOptions,
    exteriorWallType: ewtOptions,
    garageType: gtOptions,
    roofShape: rsOptions,
    roofType: rtOptions,
    roofOriginal: roOptions,
    numFamiliesInDwelling: nfidOptions,
    ownershipStatus: ownershipStatusOptions
  } = useMemo(() => {
    const ownershipStatus = lookupsJson.ownershipStatus;
    const typeOfHome = lookupsJson.typeOfHome;
    const homeQuality = lookupsJson.homeQuality;
    const constructionType = lookupsJson.constructionType;
    const basementType = lookupsJson.basementType;
    const exteriorWallType = lookupsJson.exteriorWallType;
    const garageType = lookupsJson.garageType;
    const roofType = lookupsJson.roofType;
    const roofShape = lookupsJson.roofShape;
    const roofOriginal = lookupsJson.roofOriginal;

    const numFamiliesInDwelling = [
      {
        id: '1',
        value: '1'
      },
      {
        id: '2',
        value: '2'
      },
      {
        id: '3',
        value: '3'
      },
      {
        id: '4',
        value: '4'
      }
    ];

    return {
      typeOfHome,
      homeQuality,
      constructionType,
      basementType,
      exteriorWallType,
      garageType,
      roofShape,
      roofType,
      roofOriginal,
      numFamiliesInDwelling,
      ownershipStatus
    };
  }, []);

  return (
    <>
      <Field
        xs={3}
        type="numeric"
        id="home.yearBuilt"
        name="home.yearBuilt"
        mode="light"
        label="Year Built"
        disabled={areProtectedFieldsDisabled}
      />
      <Field
        xs={3}
        type="date"
        id="home.purchaseDate"
        name="home.purchaseDate"
        mode="light"
        label="Purchase Date"
        defaultToEmpty
        disableFuture={false}
        maxDate={addDays(new Date(), 60)}
      />
      <Field
        xs={3}
        type="select"
        options={tohOptions}
        id="home.typeOfHome"
        name="home.typeOfHome"
        mode="light"
        label="Type of home"
      />
      <Field
        name="home.ownershipStatus"
        type="select"
        label="Ownership"
        mode="light"
        options={ownershipStatusOptions}
        xs={3}
      />
      <Field
        xs={3}
        type="select"
        options={nfidOptions}
        id="home.numFamiliesInDwelling"
        name="home.numFamiliesInDwelling"
        mode="light"
        label="Families living"
      />
      <Field
        xs={3}
        type="select"
        options={hqOptions}
        id="home.homeQuality"
        name="home.homeQuality"
        mode="light"
        label="Home quality"
      />
      <Field
        xs={3}
        type="numeric"
        id="sqFt"
        name="home.sqFt"
        mode="light"
        label="Above-ground sqft"
        disabled={areProtectedFieldsDisabled}
      />
      <Field
        xs={3}
        type="select"
        id="home.constructionType"
        options={ctOptions}
        name="home.constructionType"
        mode="light"
        label="Construction"
        disabled={areProtectedFieldsDisabled}
      />
      <Field xs={3} type="numeric" id="numStories" name="home.numStories" mode="light" label="Stories" />
      <Field
        xs={3}
        type="numeric"
        id="home.numHalfBathrooms"
        name="home.numHalfBathrooms"
        mode="light"
        label="Half Bathrooms"
      />
      <Field
        xs={3}
        type="numeric"
        id="home.numFullBathrooms"
        name="home.numFullBathrooms"
        mode="light"
        label="Full Bathrooms"
      />
      <Field xs={3} type="numeric" id="numFireplaces" name="home.numFireplaces" mode="light" label="Fireplaces" />
      <Field
        xs={4}
        type="numeric"
        id="home.numWoodBurningStoves"
        name="home.numWoodBurningStoves"
        mode="light"
        label="Wood Burning Stoves"
      />
      <Field
        xs={4}
        type="select"
        id="home.basementType"
        options={btOptions}
        name="home.basementType"
        mode="light"
        label="Basment Type"
      />
      <Field
        xs={4}
        type="numeric"
        id="home.basementSqFt"
        name="home.basementSqFt"
        mode="light"
        label="Basement finished sqft"
        disabled={areProtectedFieldsDisabled}
      />
      <Field
        xs={6}
        type="select"
        id="home.exteriorWallType"
        options={ewtOptions}
        name="home.exteriorWallType"
        mode="light"
        label="Exterior wall type"
      />
      <Field
        xs={3}
        type="select"
        id="home.garageType"
        options={gtOptions}
        name="home.garageType"
        mode="light"
        label="Type of Garage"
      />
      <Field
        xs={3}
        type="numeric"
        id="home.garageCarCapacity"
        name="home.garageCarCapacity"
        mode="light"
        label="Garage's Car space"
      />
      <Grid item xs={3} className={classes.checkboxContainer}>
        <Field
          type="select"
          id="home.roofOriginal"
          name="home.roofOriginal"
          options={roOptions}
          mode="light"
          label="Original Roof?"
        />
      </Grid>
      <Field xs={3} type="numeric" id="home.roofYear" name="home.roofYear" mode="light" label="Roof Year" />
      <Field
        xs={3}
        type="select"
        id="home.roofType"
        options={rtOptions}
        name="home.roofType"
        mode="light"
        label="Roof type"
      />
      <Field
        xs={3}
        type="select"
        id="home.roofShape"
        options={rsOptions}
        name="home.roofShape"
        mode="light"
        label="Roof shape"
        disabled={areProtectedFieldsDisabled}
      />
      <Field xs={6} type="numeric" id="home.latitude" name="home.latitude" mode="light" label="Latitude" />
      <Field xs={6} type="numeric" id="home.longitude" name="home.longitude" mode="light" label="Longitude" />
    </>
  );
};

export default Home;
