import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { nonQuinstreetStates, policyType as PolicyType } from '@ourbranch/lookups';

import { useDisabledState } from 'common/disabled-context';
import { AuthContext } from 'core/components/auth';
import { QuinStreet } from 'common/components/quinstreet';
import { SchedulePP } from 'common/components/schedule-pp';
import { Umbrella } from 'common/components/umbrella';
import { HomeCoverage, Additionals } from './coverages';
import Detail from './detail';

function HomeOffer() {
  const { disabled } = useDisabledState();
  const { canQuoteQuinstreet } = useContext(AuthContext);
  const { values } = useFormikContext();
  const {
    homeCoverage: { coverageX, coverageCPctOfA, coverageA },
    home: {
      homeLocation: { state }
    }
  } = values;
  const isQuinstreetAvailable = canQuoteQuinstreet && !nonQuinstreetStates.includes(state);

  const sppLimits = {
    tenPctOfCoverageC: coverageA * (coverageCPctOfA / 100) * 0.1,
    fiftyPctOfCoverageC: coverageA * (coverageCPctOfA / 100) * 0.5
  };

  return (
    <>
      <Detail disabled={disabled} />
      <HomeCoverage disabled={disabled} />
      <SchedulePP disabled={disabled} sppLimits={sppLimits} />
      <Umbrella disabled={disabled} state={state} coverageX={coverageX} />
      <Additionals disabled={disabled} />
      {isQuinstreetAvailable && <QuinStreet policyType={PolicyType.Home} />}
    </>
  );
}

export default observer(HomeOffer);
