import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  purchaseLinkCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(8),
    textAlign: 'center',
    width: 720
  },
  backToSearchCard: {
    backgroundColor: theme.colorPalette.beige_60,
    display: 'flex',
    gap: theme.spacing(5),
    marginTop: theme.spacing(6),
    padding: theme.spacing(8)
  },
  searchButton: {
    flex: '0 0 auto',
    fontSize: 16,
    fontWeight: 600,
    margin: 'auto',
    padding: theme.spacing(0, 4),
    width: 'fit-content'
  }
});

export default makeStyles(styles);
