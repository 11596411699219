import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import ScreenHeader from 'core/components/screen-header';
import { capitalize } from 'core/helpers/formatters';
import useStyles from './header.styles';

const Header = ({ isLoading, policy, onBack }) => {
  const classes = useStyles();

  return (
    <ScreenHeader
      title={
        isLoading ? 'Loading...' : `${capitalize(policy.customer.firstName)} ${capitalize(policy.customer.lastName)}`
      }
      options={{ hideBackButton: !onBack }}
      rightValue={
        !isLoading &&
        policy && (
          <div className={classes.right}>
            <Label type="body4">POLICY NUMBER</Label>
            <Label type="body3">{policy.policyId}</Label>
          </div>
        )
      }
      className={classes.header}
      onBack={onBack}
    />
  );
};

Header.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  policy: PropTypes.object,
  onBack: PropTypes.func
};

Header.defaultProps = {
  policy: null,
  onBack: null
};

export default memo(Header);
