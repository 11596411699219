import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import config from 'aws-exports';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import LabelWithButton from 'core/components/label-with-button';
import { generateZillowLink } from 'core/helpers/property-view-links';
import { EditLocationDetailsModal } from './edit-location-details-modal';
import useStyles from './dwelling-address.styles';

const DwellingAddress = ({ dwelling, location, latitude, longitude, fieldName, includeApt }) => {
  const classes = useStyles();
  const { canEditHomeAddress } = useContext(AuthContext);
  const [openAddressChangeForm, setOpenAddressChangeForm] = useState(false);

  const address = `${location.address} ${location.address2 ?? ''} ${location.city} ${location.state} ${location.zip}`;

  const AddressComponent = useMemo(() => {
    if (canEditHomeAddress) {
      return LabelWithButton;
    }
    return Label;
  }, [canEditHomeAddress]);
  return (
    <>
      {openAddressChangeForm && canEditHomeAddress && (
        <EditLocationDetailsModal
          open={openAddressChangeForm}
          onClose={() => setOpenAddressChangeForm(false)}
          dwelling={dwelling}
          dwellingLocation={location}
          fieldName={fieldName}
          includeApt={includeApt}
        />
      )}
      <div>
        <AddressComponent type="infoSubtitle" onClick={() => setOpenAddressChangeForm(true)}>
          <a
            className={classes.linkAddress}
            href={`https://www.google.com/maps/place/${address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address}
          </a>
        </AddressComponent>
        <Grid container className={classes.links}>
          <Grid>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={`https://maps.googleapis.com/maps/api/streetview?size=1200x800&location=${latitude},${longitude}&fov=80&key=${config.googleGeocoderKey}`}
              className={classes.streetMapLink}
            >
              See this property on Google Maps Street View
            </a>
          </Grid>
          <Grid>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={`https://earth.google.com/web/search/=${address}`}
              className={classes.streetMapLink}
            >
              See this property on Google Earth
            </a>
          </Grid>
          <Grid>
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={generateZillowLink(location)}
              className={classes.streetMapLink}
            >
              See this property on Zillow
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

DwellingAddress.propTypes = {
  dwelling: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  includeApt: PropTypes.bool
};

DwellingAddress.defaultProps = {
  includeApt: false
};

export default DwellingAddress;
