import {
  policyType as PolicyType,
  paymentMethod as PaymentMethod,
  windHailDeductibleCanVaryByCounty,
  lapsedLicenseMonthsStates,
  reopeningStates
} from '@ourbranch/lookups';

export const hasPaymentMethod = ({
  paymentMethods,
  paymentMethod,
  policyType,
  noBindHome = false,
  noBindAuto = false
}) => {
  const { homeownersPaymentMethod, autoPaymentMethod, rentersPaymentMethod, condoPaymentMethod } = paymentMethods;
  // probably a cleverer way to do this but this works
  return (
    (policyType === PolicyType.Auto && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.ARBundle && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.Renters && rentersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.Home && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HomeBundle && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.AutoBundle && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && !noBindHome && !noBindAuto && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && !noBindHome && !noBindAuto && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && noBindHome && autoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.HABundle && noBindAuto && homeownersPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.Condo && condoPaymentMethod === paymentMethod) ||
    (policyType === PolicyType.CABundle && [autoPaymentMethod, condoPaymentMethod].includes(paymentMethod))
  );
};

export const positionToError = (e, formErrors) => {
  const positionConfig = [
    { condition: (fe) => fe.homeEffectiveDate, scrollTo: '[name=homeEffectiveDate]' },
    {
      condition: (fe) => fe.mortgageDetails && fe.mortgageDetails.mortgageHolderName,
      scrollTo: '[id=mortgageSection]'
    },
    { condition: (fe) => fe.attestationsHomeAccepted, scrollTo: '[name=attestationsHomeAccepted]' },
    { condition: (fe) => fe.autoEffectiveDate, scrollTo: '[name=autoEffectiveDate]' },
    { condition: (fe) => fe.attestationsAutoAccepted, scrollTo: '[name=attestationsAutoAccepted]' },
    { condition: (fe) => fe.currentHomeownersCarrier, scrollTo: '[name=currentHomeownersCarrier]' },
    { condition: (fe) => fe.currentAutoCarrier, scrollTo: '[name=currentAutoCarrier]' },
    { condition: (fe) => fe.attestationsCondoAccepted, scrollTo: '[name=attestationsCondoAccepted]' },
    { condition: (fe) => fe.attestationsRentersAccepted, scrollTo: '[name=attestationsRentersAccepted]' }
  ];

  for (let i = 0; i < positionConfig.length; i += 1) {
    if (positionConfig[i].condition(formErrors) && document.querySelector(positionConfig[i].scrollTo)) {
      document.querySelector(positionConfig[i].scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' });
      throw formErrors;
    }
  }

  if (document.querySelector('[name=firstName]')) {
    document.querySelector('[name=firstName]').scrollIntoView({ behavior: 'smooth' });
  }
};

export const getPaymentMethods = ({
  paymentMethods,
  policyType,
  noBindHome = false,
  noBindAuto = false,
  hasAbodeProPlan = false
}) => {
  let hasCreditCard;
  let hasACH;
  let hasEscrow;
  if (paymentMethods) {
    hasCreditCard =
      hasPaymentMethod({
        paymentMethods,
        paymentMethod: PaymentMethod.CreditCard,
        policyType,
        noBindHome,
        noBindAuto
      }) || hasAbodeProPlan;
    hasACH = hasPaymentMethod({
      paymentMethods,
      paymentMethod: PaymentMethod.ACH,
      policyType,
      noBindHome,
      noBindAuto
    });
    hasEscrow = hasPaymentMethod({
      paymentMethods,
      paymentMethod: PaymentMethod.Escrow,
      policyType,
      noBindHome,
      noBindAuto
    });
  }

  return { hasCreditCard, hasACH, hasEscrow };
};

const hasExcludedKiaOrHyundai = (cars) => {
  const matches = cars.filter((car) => {
    const { make, model, year, doors } = car;

    if (!make || !model || !year || !doors) {
      return false;
    }

    const lowerCaseMake = make.toLowerCase();
    const lowerCaseModel = model.toLowerCase();

    // Kia
    const excludedKias =
      (lowerCaseMake === 'kia' &&
        lowerCaseModel.match(/(forte|sedona|optima|rio|soul|sportage)/i) &&
        year >= 2015 &&
        year <= 2021) ||
      (lowerCaseModel.match(/(optima hybrid|sorento)/i) && year >= 2015 && year <= 2016);

    // Hyundai
    const excludedVelosters = year >= 2015 && year <= 2018 && lowerCaseModel === 'veloster';
    const excludedElantras = lowerCaseModel === 'elantra' && (doors === 2 || doors === 4);

    const excludedHyundais =
      lowerCaseMake === 'hyundai' &&
      ((year >= 2015 && year <= 2021 && (lowerCaseModel.match(/(accent|kona|santa fe|tucson)/i) || excludedElantras)) ||
        excludedVelosters);

    return !!(excludedKias || excludedHyundais);
  });
  return matches.length > 0;
};

export const getPreSaleChecklistConditions = (quote, values, selectedOption) => {
  const {
    correctedAddress,
    drivers,
    cars,
    homeCoverage,
    home,
    includeEarthquake,
    includeFlood,
    global,
    scheduledPersonalProperty,
    connectedHome
  } = quote;
  const primaryDriver = drivers?.find((driver) => driver.isPrimary);

  const { state } = correctedAddress;
  const { interestedInSeparateWindHail, windHailExclusion, coverageRSExtended } = homeCoverage;
  const { breakupWithAuto, breakupWithHomeowners } = values;
  const primaryIsMarried = primaryDriver?.maritalStatus === 'M';
  const stressFreeSwitch = breakupWithAuto || breakupWithHomeowners;
  const noWindHailInformed =
    windHailExclusion &&
    ((state === 'AL' && !interestedInSeparateWindHail) ||
      (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][home.county]));

  // https://github.com/gobranch/branch/issues/19449
  // if any of these are on the offer, the member needs to give proof within a certain amount of days
  const hasSPP = scheduledPersonalProperty && scheduledPersonalProperty?.items?.length > 0;
  const hasInventoryDiscount = global.discountInventoryScore;
  const hasConnectedHome = connectedHome && connectedHome?.monitored !== 'N';
  const hasGoodStudent = drivers?.some((d) => d.goodStudent);
  const hasTakenDrivingCourse = drivers?.some((d) => d.takenCourse);
  const hasKiaOrHyundai = cars ? hasExcludedKiaOrHyundai(cars) : false;
  const homeAgeInventoryDiscountInformed =
    hasInventoryDiscount &&
    selectedOption?.includes(PolicyType.Home) &&
    reopeningStates.includes(state) &&
    home?.yearBuilt <= 1940;

  return {
    buyingHomePolicyInformed: selectedOption.includes(PolicyType.Home),
    buyingCondoPolicyInformed: selectedOption.includes(PolicyType.Condo),
    allApplicantsConfirmed: primaryIsMarried,
    inColoradoInformed: state === 'CO',
    priorInsuranceEntered: stressFreeSwitch,
    noWindHailInformed,
    earthquakeAndFloodInformed: includeEarthquake || includeFlood,
    proofOfPriorCoverageInformed:
      selectedOption?.includes(PolicyType.Auto) &&
      ['CO', 'KY', 'ME', 'MI', 'NH', 'TX'].includes(state) &&
      (!global.receivedCVData || !global.hadInforceCVPolicy),
    lapsedLicensesVerified: lapsedLicenseMonthsStates[state] && quote?.selectedOption?.includes(PolicyType.Auto),
    excludedDriversInformed: drivers?.some((d) => d.excludeDriver),
    roofSurfacesEndorsementInformed: selectedOption?.includes(PolicyType.Home) && !coverageRSExtended,
    driversLicenseInformed:
      selectedOption?.includes(PolicyType.Auto) && drivers?.find((driver) => driver.driversLicenseNumber === null),
    michiganPIPInformed: state === 'MI' && selectedOption.includes(PolicyType.Auto),
    trailingDocsInformed:
      hasSPP ||
      (hasInventoryDiscount && !homeAgeInventoryDiscountInformed) ||
      hasConnectedHome ||
      hasGoodStudent ||
      hasTakenDrivingCourse ||
      hasKiaOrHyundai,
    hasSPP,
    hasInventoryDiscount,
    hasConnectedHome,
    hasGoodStudent,
    hasTakenDrivingCourse,
    hasKiaOrHyundai,
    homeAgeInventoryDiscountInformed
  };
};

export const getExtraQuestionsConditions = (quote, isAgency) => {
  const { selectedOption, global } = quote;
  const { affinity } = global;
  const opposingPolicySoldByAgency =
    (selectedOption === PolicyType.Home || selectedOption === PolicyType.Auto) && affinity && isAgency;

  return { opposingPolicySoldByAgency };
};
