import React, { useMemo } from 'react';
import {
  getOptions,
  policyType,
  standaloneRentersStates,
  allowInstallPayOnHomePoliciesStates,
  paymentType,
  onlyPayInFullOnAutoPoliciesStates
} from '@ourbranch/lookups';

import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store/store.mobx';
import useBillingDayOfMonthOptions from 'core/hooks/use-billing-day-of-month-options';
import HomeSettings from './home-settings';
import CondoSettings from './condo-settings';
import AutoSettings from './auto-settings';
import RentersSettings from './renters-settings';
import LeadSourceSettings from './lead-source-settings';

const Settings = () => {
  const { offer: store, affinityLookups } = useStore();

  const hasMonolineHomeOption = store.offer.options.some((option) => option.type === policyType.Home);
  const hasMonolineAutoOption = store.offer.options.some((option) => option.type === policyType.Auto);
  const hasMonolineRentersOption = store.offer.options.some((option) => option.type === policyType.Renters);
  const hasMonolineCondoOption = store.offer.options.some((option) => option.type === policyType.Condo);

  const homeBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonolineHomeOption ? policyType.Home : policyType.HABundle
  );
  const autoBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonolineAutoOption ? policyType.Auto : policyType.HABundle
  );
  const rentersBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonolineRentersOption ? policyType.Renters : policyType.ARBundle
  );
  const condoBillingDayOptions = useBillingDayOfMonthOptions(
    store.offer,
    hasMonolineCondoOption ? policyType.Condo : policyType.CABundle
  );

  const showRenters =
    standaloneRentersStates[store.offer?.quote?.correctedAddress?.state] &&
    store.offer.options.some((o) => o.type === policyType.ARBundle || o.type === policyType.Renters) &&
    store.selectedOption !== policyType.Auto;

  const {
    paymentTypeOptions,
    homePaymentTypeOptions,
    autoPaymentTypeOptions,
    rentersPaymentTypeOptions,
    hPaymentMethodOptions,
    aPaymentMethodOptions,
    rPaymentMethodOptions,
    cPaymentMethodOptions,
    leadSourceOptions
  } = useMemo(() => {
    return {
      paymentTypeOptions: getOptions('paymentType', store.state),
      homePaymentTypeOptions: allowInstallPayOnHomePoliciesStates[store.offer?.quote?.correctedAddress?.state]
        ? getOptions('paymentType', store.state)
        : getOptions('homeNewBusinessPaymentType', store.state),
      autoPaymentTypeOptions: onlyPayInFullOnAutoPoliciesStates[store.state]
        ? getOptions('autoPaymentType', store.state).filter((opt) => opt.id === paymentType.OneTime)
        : getOptions('autoPaymentType', store.state),
      rentersPaymentTypeOptions: getOptions('rentersPaymentType', store.state),
      hPaymentMethodOptions: getOptions('homeownersPaymentMethod', store.state),
      cPaymentMethodOptions: getOptions('condoPaymentMethod', store.state),
      aPaymentMethodOptions: getOptions('autoPaymentMethod'),
      rPaymentMethodOptions: getOptions('rentersPaymentMethod', store.state),
      leadSourceOptions: getOptions('leadSources')
    };
  }, [store, affinityLookups]);

  return (
    <>
      {store.shouldShowHomeTab && (
        <HomeSettings
          paymentMethodOptions={hPaymentMethodOptions}
          billingDaysOptions={homeBillingDayOptions}
          paymentTypeOptions={homePaymentTypeOptions}
        />
      )}
      {store.shouldShowCondoTab && (
        <CondoSettings
          paymentMethodOptions={cPaymentMethodOptions}
          billingDaysOptions={condoBillingDayOptions}
          paymentTypeOptions={paymentTypeOptions}
        />
      )}
      {store.shouldShowAutoTab && (
        <AutoSettings
          paymentMethodOptions={aPaymentMethodOptions}
          billingDaysOptions={autoBillingDayOptions}
          paymentTypeOptions={autoPaymentTypeOptions}
        />
      )}
      {showRenters && (
        <RentersSettings
          paymentMethodOptions={rPaymentMethodOptions}
          billingDaysOptions={rentersBillingDayOptions}
          paymentTypeOptions={rentersPaymentTypeOptions}
        />
      )}
      <LeadSourceSettings leadSourceOptions={leadSourceOptions} />
    </>
  );
};

export default withDatePicker(Settings);
