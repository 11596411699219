import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { TableCell, IconButton } from '@material-ui/core';

import { SelectableRow } from 'core/components/table/selectable-row';
import externalLinkIcon from './external-link.svg';

const Row = ({ policy }) => {
  const history = useHistory();
  const handleRowClick = (policyId) => {
    if (policy.account_id) {
      history.push(`/customer/${policy.account_id}/branch-plus/${policyId}`);
    } else {
      history.push(`/branch-plus/${policyId}`);
    }
  };

  return (
    <SelectableRow hover id={policy.policy_id} onClick={handleRowClick} data-cy="row">
      <TableCell>{policy?.customer_first_name?.toUpperCase()}</TableCell>
      <TableCell>{policy?.customer_last_name?.toUpperCase()}</TableCell>
      <TableCell>{policy?.address || ''}</TableCell>
      <TableCell>{policy?.state || ''}</TableCell>
      <TableCell>{policy?.account_id || ''}</TableCell>
      <TableCell>{policy?.carrier}</TableCell>
      <TableCell>{policy?.policy_type}</TableCell>
      <TableCell>
        {policy.login_url && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              window.open(policy.login_url, '_blank');
            }}
          >
            <img src={externalLinkIcon} alt="edit" />
          </IconButton>
        )}
      </TableCell>
    </SelectableRow>
  );
};

Row.propTypes = {
  policy: PropTypes.object.isRequired
};

export default Row;
