import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  title: {
    textAlign: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    width: 136
  }
}));

export default useStyles;
