import gql from 'graphql-tag';
import * as fragments from 'customer/components/policy/policy.query.fragments';
import { OFFER_FRAGMENT } from 'core/fragments/offer';
import { BRANCH_PLUS_POLICY_FRAGMENT } from 'core/fragments/branch-plus-policy';

export const GET_ACCOUNT = gql`
  query ($id: ID!) {
    account: getAccount(id: $id) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      inviter {
        id
        firstName
        lastName
      }
      frontTagId
    }
  }
`;

export const GET_ACCOUNT_BRANCH_PLUS_POLICY = gql`
  query ($accountId: ID!) {
    account: getAccount(id: $accountId) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      additionalPhoneNumbers {
        phoneNumber
        note
        canText
      }
      inviter {
        id
        firstName
        lastName
      }
      frontTagId
    }
    branchPlusPolicies: getAccountBranchPlusPolicies(accountId: $accountId) {
      ...BranchPlusPolicyFragment
    }
  }
  ${BRANCH_PLUS_POLICY_FRAGMENT}
`;

export const GET_FULL_ACCOUNT = gql`
  query ($id: ID!) {
    account: getAccount(id: $id) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      additionalPhoneNumbers {
        phoneNumber
        note
        canText
      }
      inviter {
        id
        firstName
        lastName
      }
      frontTagId
      policies {
        webUserId
        id
        isAgentSold
        policyType
        state
        effectiveDate
        endDate
        fullTermPolicyEndDate
        premium
        surplusContribution
        fromStaff
        stripeCustomerId
        paymentMethod
        salesRep
        bindRep
        cancelled
        nonRenewed
        offer {
          options {
            id
            name
            annual
            monthly
            type
            homeBill
            homeTotal
            autoBill
            autoTotal
            homePremium
            autoPremium
          }
          quote {
            fname
            lname
            email
            phone
            correctedAddress {
              address
              address2
              city
              state
              zip
            }
            global {
              affinity
            }
            leadSource
            rep
            isAgentSold
            home {
              yearBuilt
            }
            offerings {
              autoRejectCode
              monolineAutoRejectCode
              monolineHomeRejectCode
              monolineCondoRejectCode
              homeownersRejectCode
              rentersRejectCode
              condoRejectCode
              offerAuto
              offerHomeowners
              offerRenters
              offerCondo
            }
          }
        }
        versionHistory {
          updatedDateTime
        }
        segments {
          segmentId
          startDate
          global {
            affinity
          }
        }
        policyDetails {
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            policyLimitLPD
            petInjury
            policyLimitPIPME
            policyLimitPIPWL
            policyLimitPIPACR
            policyLimitPPI
            policyLimitIncomeLoss
            policyLimitAccidentalDeath
            policyLimitFuneralBenefits
            policyLimitExtraMedBenefits
            policyLimitTortOption
            policyLimitComboFBP
            policyLimitGuestPIP
            policyLimitNoFaultPIP
            uimAddedOn
          }
          homeCoverage {
            coverageA
            minimumDeductibleValue
            minimumWindHailDeductible
            minimumWindHailDeductibleValue
            minimumHurricaneDeductible
            minimumHurricaneDeductibleValue
            interestedInSeparateWindHail
            externalPolicyId
          }
          includeUmbrella
          includeEarthquake
          includeFlood
          earthquakeCoverage {
            policyId
          }
          floodCoverage {
            policyId
          }
          drivers {
            id
            excludeDriver
            waivedPIPWL
            pipEligible
            pipOptOut
            pipHasQHC
          }
        }
        fees {
          amount
        }
      }
    }
    documents: getDocs(accountId: $id) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
    unsignedApplications: getUnsignedApplications(accountId: $id) {
      policyId
      applicationUrl
    }
    unsignedBixConversions: getUnsignedBixConversions(accountId: $id) {
      policyId
    }
    pledges: getAccountPledges(accountId: $id) {
      pledges {
        name
        pledgedTimeStamp
      }
    }
    myCommunity: getAccountCommunity(accountId: $id) {
      community {
        name
        communityTimeStamp
      }
    }
    customerMetadata: getCustomerMetadata(accountId: $id) {
      suspiciousActivity {
        flagged
        rep
        timestampISO8601
      }
      revision
      rep
    }
    tasks: getOnboardingTasks(accountId: $id) {
      ... on OnboardingTasksResponse {
        success
        tasks {
          taskId
          taskName
          data
          completedDate
          policyId
          completedBy
          rejections {
            timestamp
            rejectedByUserId
            reason
            timestamp
          }
        }
      }
      ... on OnboardingTasksError {
        success
        error
      }
    }
    branchPlusPolicies: getAccountBranchPlusPolicies(accountId: $id) {
      ...BranchPlusPolicyFragment
    }
  }
  ${BRANCH_PLUS_POLICY_FRAGMENT}
`;

export const GET_ACCOUNT_POLICY = gql`
  query getAccountPolicy($id: ID!, $accountId: ID!) {
    account: getAccount(id: $accountId) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      inviter {
        id
        firstName
        lastName
      }
      frontTagId
      policies {
        id
        offerId
        policyType
        paymentType
        isBix
        isAgentSold
        salesRep
        bindRep
        paymentMethod
        premiumBreakdown
        webUserId
        policyDetails {
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            policyLimitLPD
            petInjury
            policyLimitPIPME
            policyLimitPIPWL
            policyLimitPIPACR
            policyLimitPPI
            policyLimitIncomeLoss
            policyLimitAccidentalDeath
            policyLimitFuneralBenefits
            policyLimitExtraMedBenefits
            policyLimitTortOption
            policyLimitComboFBP
            policyLimitGuestPIP
            policyLimitNoFaultPIP
            uimAddedOn
          }
          branchPlusPolicies {
            policyType
            policyCarrier
            policyNumber
            attestationAgent
          }
          homeCoverage {
            coverageA
            deductibleAllOther
            deductibleWindHail
            deductibleHurricane
            coverageCPctOfA
            coverageBPctOfA
            coverageX
            coverageJ
            coverageWaterBackup
            coverageG
            interestedInSeparateWindHail
            externalPolicyId
          }
          drivers {
            ...People
          }
          nonDrivers {
            ...People
          }
          people {
            ...People
          }
          cars {
            addDate
            VIN
            year
            make
            model
            bodyType
            primaryUse
            purchaseDate
            brandedTitle
            garageLocation {
              ...Address
            }
            isGarageAddressRuleExceptionApproved
            deductibleCollision
            deductibleComprehensive
            limitUMPD
            limitRental
            limitACPE
            limitIncomeLoss
            roadsideAssistance
            rideSharing
            confirmed
            aebStatus
            aebConfirmed
            antiTheftStatus
            antiTheftConfirmed
            singleAirbagStatus
            singleAirbagConfirmed
            doubleAirbagStatus
            doubleAirbagConfirmed
            luxury
            coverageLoan
            symbolMake
            symbolModel
            symbolStyle
            symbolAux
            annualMilesDriven
            hasOutstandingRecall
            symbolPGS
            symbolBI
            symbolPD
            symbolMed
            symbolPIP
            symbolColl
            symbolComp
            symbolUM
          }
          trailers {
            VIN
            type
            year
            value
            contents
            purchaseDate
            garageLocation {
              ...Address
            }
            deductibleCollision
            deductibleComprehensive
            confirmed
            coverageLoan
            addDate
          }
          includeUmbrella
          includeRenters
          includeEarthquake
          includeFlood
          earthquakeCoverage {
            policyId
          }
          floodCoverage {
            policyId
          }
          attachedHomeowners
          attachedRenters
          attachedAuto
          attachedCondo
        }
        billingDayOfMonth
        billingHoldUntil
        blockCreditCheckAtRenewal
        rateControlDate
        effectiveDate
        endDate
        fullTermPolicyEndDate
        inceptionDate
        autoRenew
        sendPaperDocuments
        renewalCreditPull
        renewalPaymentType
        renewalPaymentMethod
        renewalVariables {
          allow300KCSL
          allowForcedNonRenewal
          allowUmbrella
        }
        state
        term
        version
        premium
        surplusContribution
        rewriteReason
        cancelReason
        nonRenewReason
        cancelled
        nonRenewed
        stripeCustomerId
        surplusContribution
        defaultCreditCard {
          id
          brand
          last4
          bankName
        }
        defaultBankAccount {
          id
          routingNumber
          accountHolder
          accountType
        }
        defaultEscrowAccount {
          mortgageHolderName
          loanNumber
          mortgageHolderAddress {
            address
            address2
            country
            state
            city
            zip
          }
        }
        accountDetails {
          accountHolder
          accountType
          routingNumber
          accountNumber
        }
        bankAccounts {
          accountHolder
          accountType
          routingNumber
          accountNumber
        }
        creditCards {
          id
          brand
          last4
        }
        additionalParties {
          id
          name
          relationship
          lossPayee
          address {
            address
            address2
            unit
            country
            city
            state
            zip
          }
          loanNumber
          VIN
        }
        fees {
          type
          description
          amount
          appliedDate
        }
        fromStaff
        offer {
          ...OfferFragment
        }
        versionHistory {
          version
          username
          updatedDateTime
          coverageChange
          itemsChanged
          effectiveDate
          internalNotes
          changeInPremium
          totalPrice
        }
        segments {
          segmentId
          clarionDoorId
          startDate
          dailyRate
          segmentDays
          segmentPremium
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            policyLimitLPD
            petInjury
            policyLimitPIPME
            policyLimitPIPWL
            policyLimitPIPACR
            policyLimitPPI
            policyLimitIncomeLoss
            policyLimitAccidentalDeath
            policyLimitFuneralBenefits
            policyLimitExtraMedBenefits
            policyLimitTortOption
            policyLimitComboFBP
            policyLimitGuestPIP
            policyLimitNoFaultPIP
            uimAddedOn
          }
          scheduledPersonalProperty {
            deductible
            items {
              category
              description
              value
              breakageOption
              exTheftOption
            }
          }
          premium
          includeUmbrella
          includeRenters
          includeEarthquake
          includeFlood
          earthquakeCoverage {
            policyId
          }
          floodCoverage {
            policyId
          }
          attachedHomeowners
          attachedRenters
          attachedAuto
          attachedCondo
          umbrellaCoverage {
            highRisk
            numVehiclesTotal
            numLicensedDriversUnder25
            numMotorcyclesScooters
            numATVs
            numPersonalWatercraft
            numWatercraft
            watercraftHullLengths
            numRVs
            numRentalProperties
            uninsuredMotoristLimit
            rentalPropertyAddresses {
              ...Address
            }
            numOtherProperties
            otherPropertyAddresses {
              ...Address
            }
            presenceOfBusiness
            uninsuredMotoristLimit
            liabilityCoverageLimit
            limitBIOccurrence
          }
          branchPlusPolicies {
            policyType
            policyCarrier
            policyNumber
            attestationAgent
          }
          homeCoverage {
            coverageA
            coverageIBSC
            coverageBCPctOfA
            coverageBPctOfA
            coverageCPctOfA
            coverageDPctOfA
            coverageX
            coverageY
            coverageWaterBackup
            coverageBuildingMaterialsTheft
            coverageCourseOfConstruction
            coverageExtendedPremises
            coverageFairRental
            coveragePetBite
            coverageRSExtended
            coverageYardAndGarden
            coverageSinkhole
            coverageOilStorageTank
            coverageBP
            coverageCA
            coverageDC
            coverageDR
            coverageF
            coverageG
            coverageJ
            coverageMI
            coverageMS
            coverageSP
            coverageST
            coverageEBSL
            homeownerProtection
            deductibleAllOther
            deductibleHurricane
            deductibleWaterBackup
            deductibleWindHail
            windHailExclusion
            minimumDeductibleValue
            minimumWindHailDeductible
            minimumWindHailDeductibleValue
            minimumHurricaneDeductible
            minimumHurricaneDeductibleValue
            interestedInSeparateWindHail
            externalPolicyId
          }
          condoCoverage {
            coverageC
            coverageA
            coverageBCPctOfC
            coverageDPctOfC
            coverageEBSL
            coverageX
            coverageY
            coverageF
            coverageJ
            coverageBP
            coverageMI
            coverageCA
            coverageSP
            coverageST
            coverageDR
            minimumDeductibleValue
            personalPropReimbursement
            coverageG
            coverageWaterBackup
            coverageMSALE
            coverageMS
            deductibleAllOther
          }
          global {
            affinity
            rateControlDate
            currentlyHomeInsured
            currentAutoAMBEST
            currentAutoCarrier
            manualInventorySubmission
            discountInventoryScore
            discountDriverScore
            discountPaperless
            homeownersPaymentType
            homeownersPaymentMethod
            autoPaymentType
            autoPaymentMethod
            condoPaymentType
            condoPaymentMethod
            currentlyAutoInsured
            homeEffectiveDate
            autoEffectiveDate
            homeSecurityPartnerCustomerType
            employeeDiscount
            autoMegaDownPay
            homeMegaDownPay
            rentersMegaDownPay
            condoMegaDownPay
          }
          rentersCoverage {
            rentersLocation {
              ...Address
            }
            coverageCD
            coverageX
            coverageY
            deductible
          }
          condo {
            constructionType
            condoLocation {
              ...Address
            }
            condoQuality
            mortgageDetails {
              loanNumber
              mortgageHolderName
              mortgageHolderAddress {
                address
                address2
                country
                city
                state
                zip
              }
              primary
              isCustom
            }
            numFullBathrooms
            numHalfBathrooms
            numOccupants
            ownershipStatus
            purchaseDate
            replacementCostEstimate {
              total
              itemized {
                calculatedValue
                type
                desc
              }
            }
            roofShape
            sqFt
            typeOfCondo
            typeOfEntry
            hasBasement
            buildingHasMoreThanFiveUnits
            yearBuilt
            latitude
            longitude
            fpc
            county
            fireHydrantWithin1000ft
            windstormMitigationLevel
            fpcFromLocation
            floor
            weeksRentedOut
            hasUnitNumber
            floor
            numVehicles
            dtoName
            dtbowName
            dtbowLabel
            wildfireHazardScore
          }
          cars {
            addDate
            VIN
            year
            make
            model
            bodyType
            primaryUse
            purchaseDate
            garageLocation {
              ...Address
            }
            isGarageAddressRuleExceptionApproved
            deductibleCollision
            deductibleComprehensive
            limitUMPD
            limitRental
            limitACPE
            limitIncomeLoss
            roadsideAssistance
            rideSharing
            confirmed
            aebStatus
            aebConfirmed
            antiTheftStatus
            antiTheftConfirmed
            blindSpotStatus
            blindSpotConfirmed
            singleAirbagStatus
            singleAirbagConfirmed
            doubleAirbagStatus
            doubleAirbagConfirmed
            coverageLoan
            assignedDriver
            userAssignedDriver
            symbolMake
            symbolModel
            symbolStyle
            symbolAux
            annualMilesDriven
            hasOutstandingRecall
            brandedTitle
          }
          drivers {
            ...People
          }
          nonDrivers {
            ...People
          }
          people {
            ...People
          }
          trailers {
            VIN
            type
            year
            value
            contents
            purchaseDate
            garageLocation {
              ...Address
            }
            deductibleCollision
            deductibleComprehensive
            confirmed
            coverageLoan
            addDate
          }
          auto {
            householdMembers
            hasSetPip
            pipResidentsWithQHC
            pipExcludedResidents
            pipAllResidents
            pipEveryoneOnSamePlan
            pipHealthInsuranceType
            pipAdditionalResidents
            residenceInsuranceType
          }
          home {
            basementSqFt
            basementType
            constructionType
            exteriorWallType
            garageCarCapacity
            garageType
            homeLocation {
              ...Address
            }
            homeQuality
            mortgageDetails {
              loanNumber
              mortgageHolderAddress {
                address
                address2
                country
                city
                state
                zip
              }
              mortgageHolderName
              primary
              isCustom
            }
            numFamiliesInDwelling
            numFireplaces
            numFullBathrooms
            numHalfBathrooms
            numOccupants
            numStories
            numWoodBurningStoves
            ownershipStatus
            purchaseDate
            replacementCostEstimate {
              total
              itemized {
                calculatedValue
                type
                desc
              }
            }
            roofOriginal
            roofShape
            roofType
            roofYear
            roofDeck
            roofCover
            roofDeckAttachment
            roofToWallConnection
            openingProtection
            roofSWR
            sqFt
            swimmingPool
            typeOfHome
            yearBuilt
            latitude
            longitude
            county
            fireHydrantWithin1000ft
            porchCode
            porchSqFt
            hasTrampoline
            constructionStyle
            fpc
            fpcFromLocation
            wildfireHazardScore
            dtoName
            dtbowName
            dtbowLabel
            windstormMitigationLevel
            stormShutters
          }
          connectedHome {
            monitored
            moistureDevices
            theftPreventionDevices
            motionDetectingDevices
            smokeDetectingDevices
            autoWaterShutoffDevices
            providerName
            agreeToTerms
          }
          multiPolicyDiscount
          underwritingChanges
          homeViolationDates
          homeSingleViolationDates
        }
      }
    }
    writeOffTransactions: getWriteOffTransactions(policyId: $id) {
      date
      amount
      paymentMethod
      paymentStatus
      description
    }
    billingDetails: getBillingDetails(accountId: $accountId, policyId: $id) {
      nextPaymentDate
      nextPaymentAmount
      totalInstallments
      nextPayments {
        date
        remainingMonthly
      }
      remainingPayments
      activePaymentMethod {
        id
        brand
        last4
        bankName
        type
      }
      allPaymentMethods {
        id
        brand
        last4
        bankName
        expMonth
        expYear
      }
      transactions {
        id
        paymentAmount
        paymentAmountFormatted
        paymentRefunded
        paymentRefundedFormatted
        paymentDate
        paymentMethod
        paymentMethodBrand
        paymentMethodLast4
        paymentStatus
        internalDescription
        trigger
      }
      totalDue
      totalRemaining
      renewalPaymentDate
    }
    holdcards: getHoldCards(policyId: $id) {
      holdCardId
      createdBy
      createdDate
      reason
      deleted
      policyId
    }
    documents: getDocs(accountId: $accountId) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
    inspection: getInspectionStatus(policyId: $id) {
      status
      inspectionId
      caseNumber
      dateOrdered
      dateCompleted
      completedType
    }
    violationsForRenewal: getViolationsForRenewal(policyId: $id) {
      policyId
      gotDrivingRecord
      autoViolationDates
      driverId
    }
    unsignedBixConversions: getUnsignedBixConversions(accountId: $accountId) {
      policyId
    }
    unsignedApplications: getUnsignedApplications(accountId: $accountId) {
      policyId
      applicationUrl
    }
    customerMetadata: getCustomerMetadata(accountId: $accountId) {
      suspiciousActivity {
        flagged
        rep
        timestampISO8601
      }
      revision
      rep
    }
    tasks: getOnboardingTasks(accountId: $accountId) {
      ... on OnboardingTasksResponse {
        success
        tasks {
          taskId
          taskName
          data
          completedDate
          policyId
          completedBy
          rejections {
            timestamp
            rejectedByUserId
            reason
          }
        }
      }
      ... on OnboardingTasksError {
        success
        error
      }
    }
    policyPaidAndPendingCash: getPolicyPaidAndPendingCash(policyId: $id) {
      currentTotalPaid
      pendingAmount
    }
  }
  ${fragments.Address}
  ${fragments.People}
  ${OFFER_FRAGMENT}
`;

export const GET_TICKETS = gql`
  query ($params: GetTicketsParams!) {
    tickets: getTickets(params: $params) {
      frontTickets {
        items {
          date
          subject
          description
          url
        }
        nextFrontPageToken
        paginationEnabled
        morePages
      }
      sendGridEmails {
        id
        date
        status
        subject
        description
        url
        clicksCount
        opensCount
      }
    }
  }
`;

export const GET_FRONT_CONTACT = gql`
  query ($email: String!) {
    frontContactURL: getFrontContactURL(email: $email)
  }
`;

export const GET_ACCOUNT_CLAIMS = gql`
  query ($accountId: ID!) {
    claims: getAccountClaims(accountId: $accountId) {
      policyId
      claimNumber
      status
      TPA
      coverage
      cause
      lossDate
      claimType
      adjusterName
      adjusterEmail
      adjusterPhone
    }
  }
`;

export const GET_SNAPSHEET_EXPOSURE_DETAIL = gql`
  query ($accountId: ID!) {
    exposureDetails: getSnapsheetExposureDetail(accountId: $accountId) {
      occurrence
      claimId
      snapsheetPolicyId
      correctedPolicyId
      policyId
      accountId
      exposureNumber
      exposureName
      exposureType
      coverage
      lossDate
      claimType
      currentAdjusterName
      currentAdjusterPhone
      currentAdjusterEmail
      exposureStatus
      lossType
      vin
      vehicleMake
      vehicleModel
      vehicleYear
    }
  }
`;

export const GET_SNAPSHEET_NOTES_DETAIL = gql`
  query ($claimId: ID!) {
    notesDetail: getSnapsheetNotesDetail(claimId: $claimId) {
      dataSource
      primaryKeyId
      exposureId
      claimId
      userId
      userName
      body
      notesContactType
      notesCreatedAt
      notesExposureId
      notesNoteTemplateId
      notesNoteType
      notesProviderCode
      notesUpdatedAt
      notesVendorTaskId
      notesCreatedAtLocal
      emailsBcc
      emailsCc
      emailsConfigurableEmailTemplateId
      emailsContentType
      emailsCreatedAt
      emailsCreatedAtLocal
      emailsExposureId
      emailsFrom
      emailsIsOutgoing
      emailsNumberOfAttachments
      emailsOriginalTo
      emailsProviderCode
      emailsReferenceToken
      emailsStatus
      emailsSubject
      emailsTo
      emailsUpdatedAt
      smsConfigurableSmsTemplateId
      smsCountry
      smsCreatedAt
      smsCreatedAtLocal
      smsExposureId
      smsFrom
      smsIsOutgoing
      smsProviderCode
      smsStatus
      smsTo
      smsUpdatedAt
      createdAt
    }
  }
`;

export const SEND_EVENT_AS_MEMBER = gql`
  mutation sendEventAsMember($eventDetails: EventDetailsInput!) {
    sendEventAsMember(eventDetails: $eventDetails)
  }
`;

export const COMPLETE_TASK = gql`
  mutation completeTask($taskDetails: TaskDetailsInput!, $ticket: CompleteTaskTicketInput) {
    completeTask(taskDetails: $taskDetails, ticket: $ticket) {
      ... on CompleteTask {
        success
      }
      ... on OnboardingTasksError {
        success
        error
      }
    }
  }
`;

export const RESET_TASK = gql`
  mutation resetTask($taskDetails: TaskDetailsInput!) {
    resetTask(taskDetails: $taskDetails) {
      ... on ResetTask {
        success
      }
      ... on OnboardingTasksError {
        success
        error
      }
    }
  }
`;

export const GET_DOCS_AND_TASKS = gql`
  query ($accountId: ID!) {
    unsignedApplications: getUnsignedApplications(accountId: $accountId) {
      policyId
      applicationUrl
    }
    documents: getDocs(accountId: $accountId) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
    tasks: getOnboardingTasks(accountId: $accountId) {
      ... on OnboardingTasksResponse {
        success
        tasks {
          taskId
          taskName
          data
          completedDate
          policyId
          completedBy
          rejections {
            timestamp
            rejectedByUserId
            reason
          }
        }
      }
      ... on OnboardingTasksError {
        success
        error
      }
    }
  }
`;
