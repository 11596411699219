import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Skeleton } from '@material-ui/lab';
import { Divider } from '@material-ui/core';

import { Loading } from 'core/components/loading';
import Switch from 'core/components/switch';
import Label from 'core/components/label/label';
import Section from 'core/components/section';
import { useToast } from 'core/components/toast';
import { track } from 'core/helpers/analytics';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import { buildQuinstreetQuoteFromPolicy, getCarrierData } from './helpers';
import { IndividualQuinstreetOffer } from './individual-quinstreet-offer';
import AlertIconWhiteFilled from 'core/assets/svg/alert-white-filled.svg';
import useStyles from './quinstreet.styles';

const QuinStreet = observer(({ policyType, fromPolicy }) => {
  const classes = useStyles();
  const session = useSession();
  const store = useStore();
  const toast = useToast();
  const [toggleChecked, setToggleChecked] = useState(false);
  const [offerInput, setOfferInput] = useState(false);
  const [quinstreetOffers, setQuinstreetOffers] = useState([]);

  const {
    quote: { requestQuinstreetQuoteAction, loading },
    offer: { offer },
    account: {
      fname,
      lname,
      policies: { policy: policyStore },
      policies
    }
  } = store;

  const carrierData = getCarrierData(policyType);
  const uniqueCarrierLogos = Array.from(new Set(Object.values(carrierData).map((carrier) => carrier.logo)));

  const webUserId = offerInput.webUserId;
  const policyId = fromPolicy ? offerInput.id : undefined;
  const offerId = fromPolicy ? undefined : offerInput.id;
  const rep = session.user;

  useEffect(() => {
    setOfferInput(
      fromPolicy
        ? buildQuinstreetQuoteFromPolicy({
            list: policies.list,
            fname,
            lname,
            policy: policyStore.policy
          })
        : offer
    );
  }, [fname, fromPolicy, lname, offer, policyStore.policy, policies.list]);

  const handleGenerateExternalQuotes = useCallback(
    async (_, checked) => {
      setToggleChecked(checked);
      try {
        if (!checked) {
          setQuinstreetOffers([]);
        } else {
          track('Generate Branch Plus Quotes from Staff', {
            webUserId,
            policyId,
            offerId,
            policyType,
            rep: rep.username
          });

          const offers = await requestQuinstreetQuoteAction({
            quoteDetails: offerInput.quote,
            webUserId,
            rep,
            policyId,
            offerId,
            policyType
          });

          setQuinstreetOffers(offers);
        }
      } catch (error) {
        setToggleChecked(false);
        toast.notify({
          message: error.message,
          type: 'error'
        });
      }
    },
    [offerInput, policyId, policyType, rep, requestQuinstreetQuoteAction, toast, webUserId, offerId]
  );

  return (
    <Section
      title="Branch Plus Offers"
      titleTooltipText="When you enable Branch Plus offers, you will receive quotes from our external carrier partners based on the coverage selections you have made for this policy. It's important to note that the quotes generated are preliminary and may not reflect the final price. They are subject to change before the purchase is made, and you will need to manage them separately."
      type="SubSection"
    >
      <div className={classes.container}>
        <Switch
          id="quoteQuinstreet"
          mode="dark"
          label="Generate external quotes"
          className={classes.switch}
          value={toggleChecked}
          disabled={loading}
          onChange={handleGenerateExternalQuotes}
        />
        {!loading && !quinstreetOffers.length && (
          <div className={classes.logosContainer}>
            {uniqueCarrierLogos.map((logo, index) => (
              <img key={index} src={logo} alt="Banner Icon" />
            ))}
          </div>
        )}
        {loading
          ? Object.values(carrierData).map((_, index) => (
              <div className={classes.loadingContainer} key={index}>
                <Skeleton width="100%" animation="wave" height={56} className={classes.skeleton} />
                <Divider className={classes.divider} />
                <div className={classes.loadingContent}>
                  <Loading type="secondary" noBackground />
                </div>
              </div>
            ))
          : quinstreetOffers.map((quinstreetOffer, index) => (
              <IndividualQuinstreetOffer
                key={`${quinstreetOffer.carrier}-${index}`}
                quinstreetOffer={quinstreetOffer}
                carrierData={carrierData}
                policyType={policyType}
                webUserId={webUserId}
                policyId={policyId}
                offerId={offerId}
                repUsername={rep.username}
              />
            ))}
        <div className={classes.outlineContainer}>
          <img src={AlertIconWhiteFilled} className={classes.icon} alt="Banner Icon" />
          <Label>
            These coverages are purchased through external carriers, and are handled separately. The quotes generated
            may not reflect the final price and are subject to change prior to purchase.
          </Label>
        </div>
      </div>
    </Section>
  );
});

QuinStreet.propTypes = {
  policyType: PropTypes.string.isRequired,
  fromPolicy: PropTypes.bool
};

QuinStreet.defaultProps = {
  fromPolicy: false
};

export default QuinStreet;
