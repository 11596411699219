import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

import { awsDateToDateFormatter } from 'core/helpers/formatters';
import { SelectableRow } from 'core/components/table/selectable-row';
import { SelectableCell } from 'core/components/table/selectable-cell';
import { FormField } from 'core/components/form';
import useStyles from './customer-results.styles';

const columnTypes = {
  select: ({ hit, selectFieldName }) => (
    <FormField
      type="radio"
      name={selectFieldName}
      mode="light"
      ignoreGlobalDisabledState
      fast={false}
      options={[{ label: '', value: hit?.Username }]}
    />
  ),
  id: ({ hit }) => hit?.Username,
  fname: ({ hit }) => (hit?.attributes ? hit.attributes?.name : ''),
  lname: ({ hit }) => (hit?.attributes ? hit.attributes?.family_name : ''),
  address: ({ hit }) => (hit?.attributes ? hit.attributes?.address : ''),
  city: ({ hit }) => (hit?.attributes ? hit.attributes?.custom_city : ''),
  state: ({ hit }) => (hit?.attributes ? hit.attributes?.custom_state : ''),
  zip: ({ hit }) => (hit.attributes ? hit.attributes?.custom_zip : ''),
  phone: ({ hit }) => (hit?.attributes ? hit.attributes?.phone_number : ''),
  created: ({ hit, mode }) => awsDateToDateFormatter(new Date(hit.UserCreateDate))
};

// @Note I've move this to make the code easier to read,
// still this have a semantic mistake, we cannot have links as children of <tr> elements
// I couldn't fix it because the paddings on the table, we need to figure out the best approach to make
// this without throwing errors on the console
const Row = ({ hit, onClick, selectFieldName, mode, columnsToUse, useLink }) => {
  const classes = useStyles();
  return (
    <SelectableRow
      key={hit?.Username}
      className={classes.tableRow}
      hover
      id={hit?.Username}
      value={hit}
      onClick={onClick}
    >
      {useLink
        ? columnsToUse.map((name, ind) => (
            <SelectableCell
              to={`/customer/${hit?.Username}`}
              key={`${hit?.Username}-${ind}`}
              align={mode === 'dropdown' && (name === 'zip' || name === 'created') ? 'right' : 'inherit'}
            >
              {columnTypes[name]({ hit, selectFieldName, mode })}
            </SelectableCell>
          ))
        : columnsToUse.map((name) => (
            <TableCell
              key={name}
              align={mode === 'dropdown' && (name === 'zip' || name === 'created') ? 'right' : 'inherit'}
            >
              <>{columnTypes[name]({ hit, selectFieldName, mode })}</>
            </TableCell>
          ))}
    </SelectableRow>
  );
};

Row.propTypes = {
  hit: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  columnsToUse: PropTypes.arrayOf(
    PropTypes.oneOf(['select', 'id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'phone', 'created'])
  ),
  mode: PropTypes.string,
  selectFieldName: PropTypes.string
};

Row.defaultProps = {
  columnsToUse: ['id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'created'],
  mode: '',
  selectFieldName: null,
  onClick: () => {}
};

export default Row;
