import React from 'react';

import downloadIcon from 'core/assets/svg/download.svg';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';

const DownloadButton = ({ path }) => {
  return (
    <Button color="secondary" variant="text" onClick={() => window.open(path)}>
      <img src={downloadIcon} alt="download" />
      <Label variant="body3">Download file</Label>
    </Button>
  );
};

export default DownloadButton;
