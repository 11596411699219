import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  exposureInfo: {
    paddingLeft: theme.spacing(5)
  },
  exposureBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  exposureCard: {
    margin: theme.spacing(1, 0, 0, 0),
    border: 0
  }
}));

export default useStyles;
