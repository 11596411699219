import React, { Suspense, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Dialog, Grid } from '@material-ui/core';
import { directAffinityCodes } from '@ourbranch/lookups';
import DialogContent from '@material-ui/core/DialogContent';

import { SearchBar } from 'core/components/search-bar';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { ActionButton } from 'core/components/action-button';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store/';
import useSession from 'core/hooks/use-session';
import { FormField } from 'core/components/form';
import { Tooltip } from 'core/components/tooltip';
import { track } from 'core/helpers/analytics';
import x from 'core/assets/svg/x.svg';
import CustomersResults from 'common/components/search-results/customers-results';
import useStyles from './search-dialog.styles';

const CustomerSearchDialog = observer(() => {
  const classes = useStyles();
  const {
    matchSearch: {
      showCustomerMatchesModal,
      customersResults,
      clearInput,
      searchCustomersAction,
      currentResultsPage,
      setCurrentResultsPage,
      setShowCustomerMatches,
      loading,
      searchString,
      setSearchString
    },
    offer
  } = useStore();
  const history = useHistory();
  const toast = useToast();
  const { user } = useSession();

  const { handleSubmit, values, setFieldValue, setFieldTouched, setFieldError } = useFormikContext();

  const onLinkingExistingMember = () => {
    if (values?.accountIdMatchField?.toString().length !== 9) {
      setFieldError('accountIdMatchField', "Please enter the member's account ID to continue.");
      return;
    }
    const linkedMember = customersResults.hits.find((h) => h.objectID === values.existingCustomer.id);
    // exclude directAffinityCodes
    if (
      !offer.offer.quote.global.affinity &&
      linkedMember?.attributes?.custom_affinity_code &&
      !directAffinityCodes.includes(linkedMember?.attributes?.custom_affinity_code)
    ) {
      setFieldValue('global.affinity', linkedMember?.attributes?.custom_affinity_code);
      setFieldTouched('global.affinity', true);
    }

    if (parseInt(values.existingCustomer?.id, 10) !== values.accountIdMatchField) {
      toast.notify({
        type: 'error',
        message:
          'The account ID entered does not match the member you are trying to link the offer to. Please try again.',
        durationInSeconds: 15,
        label: 'X',
        action: () => {}
      });

      track('Failed to Link offer to existing member', {
        user: user.username,
        offerId: offer.offer.id,
        inputAccountId: values.accountIdMatchField,
        selectedAccountId: values.existingCustomer?.id
      });
      return;
    }

    track('Linked offer to existing member', {
      user: user.username,
      offerId: offer.offer.id,
      selectedAccountId: values.existingCustomer?.id
    });
    handleSubmit();
  };
  useEffect(() => {
    searchCustomersAction();
  }, [searchString, searchCustomersAction]);

  const onSearchStringChange = useCallback(
    (event) => {
      setSearchString(event.target.value);
    },
    [setSearchString]
  );

  const onPageChange = useCallback(
    (page) => {
      setCurrentResultsPage(page);
      searchCustomersAction(searchString);
    },
    [searchString, setCurrentResultsPage, searchCustomersAction]
  );

  return (
    <Dialog maxWidth="lg" fullWidth open={showCustomerMatchesModal} onClose={() => setShowCustomerMatches(false)}>
      <DialogContent className={classes.searchDialog}>
        <Suspense fallback={null}>
          <Grid container>
            <Grid item xs={12} className={classes.searchHeader}>
              <Label type="greenBig">{customersResults.nbHits} Existing members</Label>
              <Label type="infoValue" className={classes.searchSubtitle}>
                Select a match to link to member or search more
              </Label>
            </Grid>
            <Grid item xs={7} className={classes.searchBar}>
              <SearchBar
                onCloseIconClick={clearInput}
                onInputChange={onSearchStringChange}
                value={searchString}
                label="Search"
              />
            </Grid>
          </Grid>
          <CustomersResults
            loading={loading}
            hits={customersResults.hits}
            totalHits={customersResults.nbHits}
            page={currentResultsPage}
            onPageChange={onPageChange}
            history={history}
            mode="modal"
            showTitle={false}
            columnsToUse={['select', 'fname', 'lname', 'address', 'city', 'state', 'zip']}
            selectFieldName="existingCustomer.id"
          />
          <Grid container alignItems="center" justifyContent="flex-end" className={classes.searchFooter}>
            <Grid item className={classes.accountIdInput}>
              <FormField
                name="accountIdMatchField"
                type="numeric"
                label="Account ID"
                mode="light"
                format={{
                  allowNegative: false
                }}
              />
              <div className={classes.accountIdTooltipContainer}>
                <Tooltip
                  text="The account ID is the first 9 digits of the policy number; this number can also be found on the member's dec page."
                  placement="top"
                  label="More info"
                  className={classes.accountIdTooltip}
                />
              </div>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Button
                className={classes.linkButton}
                type="submit"
                disabled={loading || offer.loading || !values.existingCustomer?.id}
                loading={loading || offer.loading}
                variant="contained"
                color="primary"
                onClick={onLinkingExistingMember}
              >
                <Label>Link To Existing Member</Label>
              </Button>
            </Grid>
          </Grid>
        </Suspense>
        <ActionButton
          size="big"
          type="edit"
          icon={x}
          className={classes.closeButton}
          onClick={() => {
            setShowCustomerMatches(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
});
export default CustomerSearchDialog;
