import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import { SearchLink } from '../search-link';
import useStyles from './search-header.styles';

function SearchHeader({ children }) {
  const classes = useStyles();
  const {
    search: {
      customersResults: { nbHits: customerResultsCount },
      offersResults: { nbHits: offerResultsCount },
      policiesResults: { nbHits: policyResultsCount },
      branchPlusPoliciesResults: { nbHits: branchPlusResultsCount }
    }
  } = useStore();
  const { canEdit, canQuote, viewOnly, isInternalAgent } = useContext(AuthContext);
  const location = useLocation();

  return (
    <Grid container justifyContent="space-between" spacing={1} className={classes.container}>
      <Grid item className={classes.title}>
        <Label type="title">What are you looking for?</Label>
        <div className={classes.options}>
          {((canEdit && canQuote) || viewOnly) && (
            <SearchLink
              to="/search/offers"
              selected={location.pathname.includes('offers')}
              results={offerResultsCount}
              label="An Offer"
              dataCy="offers"
            />
          )}
          <SearchLink
            to="/search/customers"
            selected={location.pathname.includes('customer')}
            results={customerResultsCount}
            label="A Member"
            dataCy="customers"
          />
          <SearchLink
            to="/search/policies"
            selected={location.pathname.includes('policies')}
            results={policyResultsCount}
            label="A Policy"
            dataCy="policies"
          />
          {isInternalAgent && (
            <SearchLink
              to="/search/branch-plus"
              selected={location.pathname.includes('branch-plus')}
              results={branchPlusResultsCount}
              label="A Branch Plus Policy"
              dataCy="branch-plus"
            />
          )}
        </div>
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  );
}

SearchHeader.propTypes = {
  children: PropTypes.node
};

SearchHeader.defaultProps = {
  children: null
};

export default SearchHeader;
