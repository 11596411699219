import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(8)
  },
  instructions: {
    '& > p': {
      margin: 0
    },
    '& > p > a': {
      fontFamily: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.colorPalette.orange_10
    }
  }
});

export default makeStyles(styles);
