import React from 'react';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import config from 'aws-exports';

import { offerHasValidOptions } from 'core/helpers/underwriting-form-helpers';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { OfferNotifications } from 'offer/components/notifications';
import { CheckoutForm } from './components/checkout-form';
import OptionNotAvailable from './components/option-not-available-modal';
import useCheckoutContextGenerator from './hooks/use-checkout-context-generator';
import CheckoutContext from './checkout.context';

const stripePromise = loadStripe(config.stripeKey);

const Checkout = observer(({ option }) => {
  const history = useHistory();
  const { offer: offerStore } = useStore();
  const { offer, getOffer, selectedOption } = offerStore;
  const { offerId } = useParams();
  const { bt } = useBasisTheory(config.basisTheoryApiKey, { elements: true });
  const checkoutContext = useCheckoutContextGenerator();
  let optionNotAvailable = false;

  if (!offer) {
    getOffer(offerId);
    return <Loading noBackground />;
  }

  offerStore.setSelectedOption(option);

  if (
    offer.skippedDataPulls?.length > 0 ||
    (selectedOption &&
      (selectedOption.includes('H') || selectedOption.includes('C')) &&
      !offer.fetchedForeclosureReport)
  ) {
    history.push(`/offer/${offer.id}`);
  }

  if (offer?.options) {
    const optionsAvailable = offerHasValidOptions({
      offer: offerStore?.offer,
      priorQuoteWithPreBindUWRejections: offerStore.priorQuoteWithPreBindUWRejections
    });

    if (!optionsAvailable || !offer.options.some((o) => o.type === getQuoteFriendlySelectedOption(option))) {
      optionNotAvailable = true;
    }
  }

  return (
    <BasisTheoryProvider bt={bt}>
      <CheckoutContext.Provider value={checkoutContext}>
        <Elements stripe={stripePromise}>
          {optionNotAvailable && <OptionNotAvailable optionNotAvailable={optionNotAvailable} />}
          {offerStore.notifications?.length && <OfferNotifications />}
          <CheckoutForm disabled={optionNotAvailable} />
        </Elements>
      </CheckoutContext.Provider>
    </BasisTheoryProvider>
  );
});

export default Checkout;
