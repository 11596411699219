import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TableCell, Grid } from '@material-ui/core';

import { Table, SelectableRow, Loading, Label } from 'core';
import Section from 'core/components/section';
import { Button } from 'core/components/button';
import upRightArrowIcon from 'core/assets/svg/up-right-arrow.svg';
import { Card } from 'core/components/card';
import { useStore } from 'core/store';
import { dateFormatter, capitalize } from 'core/helpers/formatters';
import useStyles from './claims.styles';
import ExternalClaimDetail from './external-claim-detail';
import BranchClaimDetail from './branch-claim-detail/branch-claim-detail';
import { NoDataCard } from '../no-data-card';
import { labelStyleMap } from './constants';
import { formatClaimNumber } from './claim-number-formatter';

const Claims = () => {
  const classes = useStyles();
  const { account } = useStore();
  const [externalClaimDetailOpen, setExternalClaimDetailOpen] = useState(false);
  const [selectedClaim, setSelectedExternalClaim] = useState();
  const [branchClaimDetailOpen, setBranchClaimDetailOpen] = useState(false);
  const [selectedBranchClaim, setSelectedBranchClaim] = useState();

  useEffect(() => {
    if (account.id) {
      if (!account.claims.length && !account.claimsLoading) {
        account.getClaims();
      }
      if (!account.exposureDetails.length && !account.exposureDetailsLoading) {
        account.getSnapsheetExposureDetail();
      }
    }
  }, [account, account.id]);

  if (account.claimsLoading) {
    return <Loading />;
  }

  const externallyHandledClaims = account?.claims.filter((claim) => claim.TPA.toLowerCase() !== 'branch claims');

  const branchHandledClaims = account?.claims.filter((claim) => claim.TPA.toLowerCase() === 'branch claims');

  const exposureDetail = account?.exposureDetails;

  const showClaimDetail = (claim) => {
    setSelectedExternalClaim(claim);
    setExternalClaimDetailOpen(true);
  };

  const showBranchClaimDetail = (claim) => {
    setSelectedBranchClaim(claim);
    setBranchClaimDetailOpen(true);
  };

  const hideExternalClaimDetail = () => {
    setExternalClaimDetailOpen(false);
    setSelectedExternalClaim({});
  };

  const hideBranchClaimDetail = () => {
    setBranchClaimDetailOpen(false);
    setSelectedBranchClaim({});
  };

  return (
    <>
      <Section title="Branch Handled Claims">
        {branchHandledClaims.length > 0 ? (
          <>
            {branchHandledClaims.map(
              (claim, index) =>
                claim.status.toLowerCase() !== 'drafted' &&
                claim.status.toLowerCase() !== 'cancelled' && (
                  <Card className={classes.branchClaim} key={`claim-${index}`} id={`claim-${index}`}>
                    <Grid container flex="row">
                      <Grid container item xs={5}>
                        <Grid>
                          <Label type="darkGreenBig" style={{ paddingLeft: 30, paddingTop: 10 }}>
                            {capitalize(claim.cause) || 'Unknown'}
                          </Label>
                        </Grid>
                        <Grid>
                          <Label type="body3" style={{ paddingLeft: 30 }}>
                            For{' '}
                            <Label type="infoLabel" className={classes.policyId}>
                              {claim.policyId}
                            </Label>{' '}
                            on {dateFormatter(claim.lossDate)}
                          </Label>
                        </Grid>
                      </Grid>
                      <Grid container item justifyContent="flex-end" alignItems="center" xs={7}>
                        <Grid item className={classes.sideSpacing}>
                          <Label type={`${labelStyleMap[claim.status || 'UNKNOWN']}`}> &#x25CF; {claim.status}</Label>
                        </Grid>
                        <Grid item className={classes.sideSpacing}>
                          <Label>{claim.claimNumber}</Label>
                        </Grid>
                        <Grid item className={classes.sideSpacing}>
                          <Button
                            onClick={() => showBranchClaimDetail(claim)}
                            variant="text"
                            color="secondary"
                            className={classes.button}
                          >
                            <img alt="upper right corner arrow" src={upRightArrowIcon} className={classes.arrowIcon} />
                            View Claim
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                )
            )}
          </>
        ) : (
          <NoDataCard
            message="This member does not have any Branch handled claims."
            subMessage="When a Branch handled claim opens, it'll appear here soon."
            icon={false}
          />
        )}
      </Section>
      {branchClaimDetailOpen && (
        <BranchClaimDetail
          open={branchClaimDetailOpen}
          claim={selectedBranchClaim}
          hideBranchClaimDetail={hideBranchClaimDetail}
          exposureDetail={exposureDetail}
        />
      )}
      <Section
        title="Externally Handled Claims"
        rightLabel="total"
        titleTooltipText="These Branch claims are managed by a third party administrator. They are broken into individual coverages. 
        This means you may see a claim listed multiple times. This information is refreshed on a 24-hour basis."
      >
        {externallyHandledClaims.length > 0 ? (
          <Card type="scrollable">
            <Table
              selfContained={false}
              header={
                <>
                  <TableCell>Date of Loss</TableCell>
                  <TableCell>Claim Number</TableCell>
                  <TableCell>Coverage</TableCell>
                  <TableCell>Status</TableCell>
                </>
              }
              body={
                <>
                  {externallyHandledClaims.map((claim, index) => (
                    <SelectableRow key={`claim-${index}`} id={`claim-${index}`} onClick={() => showClaimDetail(claim)}>
                      <TableCell>{dateFormatter(claim.lossDate)}</TableCell>
                      <TableCell>{formatClaimNumber(claim.claimNumber, claim.TPA)}</TableCell>
                      <TableCell>{claim.coverage || 'Unspecified'}</TableCell>
                      <TableCell className={classes[claim.status]}>
                        <Label type={`${labelStyleMap[claim.status || 'UNKNOWN']}`}>
                          &#x25CF; {claim.status || 'Unknown'}
                        </Label>
                      </TableCell>
                    </SelectableRow>
                  ))}
                </>
              }
            />
          </Card>
        ) : (
          <NoDataCard
            message="This member does not have any externally handled claims."
            subMessage="When an externally handled claim opens, it'll appear here soon."
            icon={false}
          />
        )}
      </Section>
      {externalClaimDetailOpen && (
        <ExternalClaimDetail
          open={externalClaimDetailOpen}
          claim={selectedClaim}
          hideExternalClaimDetail={hideExternalClaimDetail}
        />
      )}
    </>
  );
};

export default observer(Claims);
