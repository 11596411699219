import React, { useContext, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import ValueField from 'core/components/value-field';
import { currencyFormatter, awsDateToDateFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import { Button } from 'core/components/button';
import useFeeUpdater from './use-fee-updater';
import useStyles from './styles';

function FeeRow({ fee }) {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const [undo, setUndo] = useState(false);
  const { isExternalSales, isService, isInternalAgent, viewOnly } = session;
  const { updateFees } = useFeeUpdater();
  const {
    account: {
      policies: {
        policy: { policy: store }
      }
    }
  } = useStore();
  const readOnly = viewOnly || isExternalSales;
  const toggleForRemoval = () => {
    fee.toggleMarkedForRemoval();
    updateFees();
    if (fee.status === 'removed') {
      setUndo(true);
    } else {
      setUndo(!undo);
    }
  };

  function variableFeePlaceholder(fee) {
    if (store.state === 'AL' && fee.type === 'installment' && fee.status !== 'pre-existing') {
      return '?';
    }
    return fee.amount;
  }

  return useObserver(() => (
    <TableRow className={undo ? classes.undo : classes.normal}>
      <TableCell>
        <ValueField className={classes.valueField} value={fee.description} mode="dark" label="type" />
      </TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={currencyFormatter}
          value={variableFeePlaceholder(fee)}
          mode="dark"
          label="amount"
        />
      </TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={awsDateToDateFormatter}
          value={
            fee.appliedDate?.includes(',')
              ? fee.appliedDate.split(',')[0].replaceAll('[', '').replaceAll(`"`, '') // fix bad data
              : fee.appliedDate
          }
          mode="dark"
          label="date added"
        />
      </TableCell>
      <>
        {!readOnly && (
          <TableCell align="center">
            <Button
              variant="text"
              color="secondary"
              onClick={toggleForRemoval}
              disabled={!isService || !isInternalAgent}
            >
              {undo ? 'Undo' : 'Remove Fee'}
            </Button>
          </TableCell>
        )}
      </>
    </TableRow>
  ));
}

export default FeeRow;
