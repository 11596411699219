import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import AgencySupportBannerExtended from 'core/components/agency-support-banner-extended';

import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';
import useStyles from './moratorium-modal.styles';
import useSession from 'core/hooks/use-session';
import { getStateFullName } from 'common/helpers/address-helpers';

const MoratoriumModal = ({ moratoriumModalOpen, onClose, statesJSON }) => {
  const classes = useStyles();

  const { isAgency } = useSession();
  const statesList = Object.keys(statesJSON).sort();

  return (
    <Dialog open={moratoriumModalOpen} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label className={classes.title}>Moratorium in effect</Label>
      </DialogTitle>
      <DialogContent>
        <p className={classes.text}>
          New business and endorsements cannot be processed at this time in the following zip codes due to a
          weather-related moratorium:
        </p>
        {statesList.map((state) => {
          const stateName = getStateFullName(state);
          if (!stateName) {
            return null;
          }
          return (
            <Grid className={classes.stateLabel}>
              <Label type="infoLabel">{stateName}</Label>
              {statesJSON?.[state]?.slice(0, 60).join(', ')}
              {statesJSON?.[state]?.length > 60 && ` ... ${statesJSON?.[state]?.length - 60} more`}
            </Grid>
          );
        })}
        {isAgency && (
          <>
            <AgencySupportBannerExtended content="Contact us if you have questions about this moratorium." />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MoratoriumModal;
