import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import { capitalize, phoneNumberToStringFormatter } from 'core/helpers/formatters';
import { InfoField } from '../../info-field';
import { InnerSection } from './inner-section';
import useStyles from './sections.styles';

const CarrierInformation = ({ policyType, policyId, carrier }) => {
  const classes = useStyles();
  return (
    <InnerSection title="Carrier information">
      <InfoField label="Policy type" value={policyType} formatter={capitalize} xs={4} />
      <InfoField label="Policy number" value={policyId} xs={4} />
      <InfoField
        label="Carrier phone"
        value={carrier.phoneNumber}
        formatter={carrier.phoneNumber ? phoneNumberToStringFormatter : null}
        xs={4}
      />
      <InfoField
        xs={12}
        label="Carrier website"
        value={
          carrier.website ? (
            <Label
              onClick={() =>
                window.open(`${carrier.website.includes('https://') ? '' : 'https://'}${carrier.website}`, '_blank')
              }
              type="infoValue"
              className={classes.link}
            >
              {carrier.website.replace('https://www.', '')}
            </Label>
          ) : null
        }
      />
    </InnerSection>
  );
};

CarrierInformation.propTypes = {
  policyType: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  carrier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phoneNumber: PropTypes.number,
    website: PropTypes.string
  }).isRequired
};

export default CarrierInformation;
