import React from 'react';

import { Tooltip } from 'core/components/tooltip';
import ezCarrierIcon from 'core/assets/svg/rater-logo-ez.svg';
import plCarrierIcon from 'core/assets/svg/rater-logo-pl.svg';
import branchCarrierIcon from 'core/assets/svg/rater-logo-branch.svg';
import homeIcon from 'core/assets/svg/ic-home.svg';
import autoIcon from 'core/assets/svg/ic-auto.svg';

import useStyles from './offer-block.styles';

const OfferBlock = ({ raterIcon, type, bundle, isBranch }) => {
  const classes = useStyles();

  const topType = type === 'H' ? 'Home' : 'Auto';
  const topValue = type === 'H' ? bundle?.homeTotal : bundle?.autoTotal;
  const bottomType = type === 'H' ? 'Auto' : 'Home';
  const bottomValue = type === 'H' ? bundle?.autoTotal : bundle?.homeTotal;

  const toolTipText = `This ${bottomType.toLowerCase()} offer was generated using data ${
    isBranch ? "collected from Branch's trusted data sources." : 'entered from the rater.'
  }`;

  return (
    <>
      <div className={classes.bundleTopCard}>
        <div className={classes.iconSection}>
          {raterIcon === 'EZ' && <img src={ezCarrierIcon} className={classes.carrierIcon} alt="EZ Lynx Carrier Icon" />}
          {raterIcon === 'PL' && <img src={plCarrierIcon} className={classes.carrierIcon} alt="PL Carrier Icon" />}
          {topType}
        </div>
        <div className={classes.priceSection}>
          {topType === 'Auto' && <img src={autoIcon} className={classes.typeIcon} alt="Auto Icon" />}
          {topType === 'Home' && <img src={homeIcon} className={classes.typeIcon} alt="Home Icon" />}${topValue}
          {topType === 'Auto' && <span className={classes.time}>/ 6 MOS</span>}
          {topType === 'Home' && <span className={classes.time}>/ 12 MOS</span>}
        </div>
      </div>

      <div className={classes.bundleBottomCard}>
        <div className={classes.iconSection}>
          {raterIcon === 'EZ' && !isBranch && (
            <img src={ezCarrierIcon} className={classes.carrierIcon} alt="EZ Lynx Carrier Icon" />
          )}
          {raterIcon === 'PL' && !isBranch && (
            <img src={plCarrierIcon} className={classes.carrierIcon} alt="PL Carrier Icon" />
          )}
          {isBranch && <img src={branchCarrierIcon} className={classes.carrierIcon} alt="Branch Icon" />}
          {bottomType}
          <Tooltip smallIcon labelNextToText text={toolTipText} placement="top" />
        </div>
        <div className={classes.priceSection}>
          {bottomType === 'Auto' && <img src={autoIcon} className={classes.typeIcon} alt="Auto Icon" />}
          {bottomType === 'Home' && <img src={homeIcon} className={classes.typeIcon} alt="Home Icon" />}${bottomValue}
          {bottomType === 'Auto' && <span className={classes.time}>/ 6 MOS</span>}
          {bottomType === 'Home' && <span className={classes.time}>/ 12 MOS</span>}
        </div>
      </div>
    </>
  );
};

export default OfferBlock;
