import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './label.styles';

const Label = ({
  className,
  children,
  type,
  marginBottom,
  fontSize,
  fontWeight,
  paddingLeft,
  paddingTop,
  component,
  inlineDisplay,
  ...props
}) => {
  const classes = useStyles(props);
  const labelClasses = classNames(classes[type], className);
  const inlineStyles = { marginBottom, fontSize, fontWeight, paddingLeft, paddingTop };
  if (inlineDisplay) {
    inlineStyles.display = 'inline';
  }
  const Component = component || 'span';
  return (
    <Component className={labelClasses} style={inlineStyles} {...props}>
      {children}
    </Component>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'subtitle1',
    'label',
    'title',
    'titleLight',
    'titleSecondary',
    'subtitle',
    'subtitleLight',
    'smallSubtitleLight',
    'formSubTitle',
    'formSubTitleLight',
    'infoLabel',
    'infoValue',
    'infoValueBold',
    'infoValueFormatted',
    'infoSubtitle',
    'infoSubtitleWhite',
    'infoSubtitleOrange',
    'infoInnerTitle',
    'infoInnerTitleBold',
    'infoCardTitle',
    'infoCardText',
    'coverageLabel',
    'coverageValue',
    'coverageParagraph',
    'coverageAmount',
    'action',
    'formTitle',
    'toast',
    'greenSmall',
    'greenExtraSmall',
    'greenBig',
    'darkSmall',
    'darkGreenSmall',
    'darkGreenSmallBold',
    'darkGreenMedium',
    'darkGreenBig',
    'whiteMedium',
    'whiteSmall',
    'noteSmall',
    'paymentLabel',
    'titleDollarLabel',
    'policyLabel',
    'infoSubtitleBold',
    'formLabel',
    'formLabelLight',
    'smallLightLabel',
    'status',
    'statusUnknown',
    'statusUnknownMedium',
    'statusClosed',
    'statusClosedMedium',
    'statusOpen',
    'statusOpenMedium',
    'statusWarning',
    'statusWarningMedium',
    'smallWarning',
    'appliedDiscountsLabel',
    'discount',
    'discountPromo',
    'smallWhiteBold',
    'smallWhite',
    'largeWarning',
    'modalBody',
    'h1',
    'body1',
    'body1Bold',
    'body2',
    'body2Bold',
    'body3',
    'body3Bold',
    'body4',
    'toolDescription',
    'liveTaskMessage'
  ]),
  marginBottom: PropTypes.number,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingTop: PropTypes.string,
  inlineDisplay: PropTypes.bool
};

Label.defaultProps = {
  type: 'label',
  className: null,
  marginBottom: null,
  fontSize: null,
  fontWeight: null,
  component: null,
  paddingLeft: null,
  paddingTop: null,
  inlineDisplay: false
};

export default memo(Label);
