import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: theme.spacing(5),
    minHeight: 60,
    composes: '$root',
    backgroundColor: theme.colorPalette.beige_10,
    padding: '10px 10px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  notificationContentWithTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '-8px 0px 0px 6px'
  },
  notificationContentNoTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2px 0px 0px 6px'
  },
  iconBig: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
    transform: 'rotate(180deg)'
  },
  textContainer: {
    display: 'flex'
  },
  titleText: {
    fontWeight: 'bold',
    marginBottom: -2
  }
}));

export default useStyles;
