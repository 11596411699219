export const applicantCodes = [5013, 5024, 5025, 50096, 50097, 50098];
export const personalDetailsCodes = [5002, 5012, 5015, 5016];
export const priorAddressCodes = [5001];
export const autoDetailsCodes = [5003];
export const addAutoCodes = [5005, 5006, 5009, 5010, 50001];
export const propDetailsCodes = [5004, 5023, 50006];
export const sections = [
  ...personalDetailsCodes,
  ...priorAddressCodes,
  ...autoDetailsCodes,
  ...addAutoCodes,
  ...propDetailsCodes
];
