import { types, flow, getEnv, getParent } from 'mobx-state-tree';
import { filter, uniqBy } from 'lodash-es';
import _orderBy from 'lodash-es/orderBy';
import { paymentMethod, stripeBankStatus } from '@ourbranch/lookups';
import { clean } from '@ourbranch/policy-utils';
import { policyTypes } from '@ourbranch/policy-types';

import Notification, { isPolicyCrossSellEligible } from 'core/helpers/notifications';
import { checkAdditionalCoverages } from 'core/helpers/quoter.service';
import { getMerged } from 'customer/components/policy/merge-segments';

import {
  GET_DOCUMENTS_AND_APPLICATIONS,
  GET_UPLOAD_URL,
  REGENERATE_POLICY_DOCUMENTS
} from 'customer/components/documents/documents.queries';
import { SEND_APPLICATION_LINK } from 'customer/components/onboarding-task-center/components/application-url-form/application-links.queries';
import { VERIFY_BANK_ACCOUNT, DOWNLOAD_ANON_POLICY_DOC, GET_PAYMENT_METHOD_DETAILS_MULTIPLE } from './policies.queries';
import { PolicyStore } from './policy-store';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import PolicyList from './models/policy-list';

export const PoliciesStore = types
  .model({
    loading: types.optional(types.boolean, false),
    list: types.array(PolicyList),
    loadingDocuments: types.optional(types.boolean, false),
    documents: types.maybeNull(types.frozen()),
    unsignedApplications: types.maybeNull(types.frozen()),
    unsignedBixConversions: types.maybeNull(types.frozen()),
    policy: types.maybe(PolicyStore),
    notifications: types.maybeNull(types.array(types.string))
  })
  .actions((self) => ({
    getBankAccountsTokens: flow(function* getBankAccountsTokens(accountId, policyIds) {
      const { client } = getEnv(self);
      const { data } = yield client.query({
        query: GET_PAYMENT_METHOD_DETAILS_MULTIPLE,
        fetchPolicy: 'no-cache',
        variables: {
          accountId,
          policyIds
        }
      });
      return data?.getACHVerificationStatus || [];
    }),
    generateAndSaveStripeBankInfoToPolicies: flow(function* generateAndSaveStripeBankInfoToPolicies(
      accountId,
      policyIds
    ) {
      const bankAccounts = yield self.getBankAccountsTokens(accountId, policyIds);
      if (bankAccounts?.length) {
        self.addStripeBankInfoToPolicies(bankAccounts);
      }
    }),
    verifyBankAccount: flow(function* verifyBankAccount(verificationDetails) {
      const { client } = getEnv(self);

      try {
        const { data } = yield client.query({
          query: VERIFY_BANK_ACCOUNT,
          fetchPolicy: 'no-cache',
          variables: {
            verificationDetails
          }
        });
        return { status: data.verifyMicrodeposits.status };
      } catch (error) {
        if (error?.graphQLErrors[0]?.message) {
          throw new Error(error.graphQLErrors[0].message);
        }
        throw error;
      }
    }),
    setVerifiedStatus(status, policyId) {
      const foundPolicy = self.list?.find(({ id }) => id === policyId);
      if (foundPolicy) {
        foundPolicy.stripeBankInfo.achStatus = status;
      }
    },
    addStripeBankInfoToPolicies(bankAccounts) {
      bankAccounts.forEach((accountInfo) => {
        const policy = self.list?.find((e) => e.id === accountInfo.policyId);
        const newStripeAccountInfo = {
          achStatus: accountInfo.achStatus,
          stripeBankAccountId: accountInfo.stripeBankAccountId,
          last4: accountInfo.last4
        };
        policy.stripeBankInfo = newStripeAccountInfo;
      });
    },
    getDocuments: flow(function* getDocuments(accountId, isAgencyUser) {
      const { client } = getEnv(self);
      self.loadingDocuments = true;
      try {
        const { data } = yield client.query({
          query: GET_DOCUMENTS_AND_APPLICATIONS,
          variables: { accountId }
        });
        self.documents = data.documents;
        self.unsignedApplications = data.unsignedApplications;
        self.setNotifications(isAgencyUser);
        self.loadingDocuments = false;
      } catch (e) {
        self.loadingDocuments = false;
        return [];
      }
    }),
    regeneratePolicyDocuments: flow(function* regeneratePolicyDocuments(policyId) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: REGENERATE_POLICY_DOCUMENTS,
        variables: {
          policyId
        }
      });
    }),
    getUploadUrl: flow(function* getUploadUrl(accountId, fileName, mimeType) {
      const { client } = getEnv(self);
      return yield client.query({
        query: GET_UPLOAD_URL,
        variables: {
          accountId,
          fileName,
          mimeType
        }
      });
    }),
    sendApplicationLink: flow(function* sendApplicationUrl(accountId, policyId) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: SEND_APPLICATION_LINK,
        variables: {
          accountId,
          policyId
        }
      });
    }),
    downloadAnonPolicyDocument: flow(function* downloadAnonPolicyDocument(policyId, asOfDate) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: DOWNLOAD_ANON_POLICY_DOC,
        variables: {
          policyId,
          asOfDate
        }
      });
    }),
    setNotifications: function setNotifications(isAgencyUser) {
      const notifications = [];
      const policy = self?.policy?.policy;
      const bixConversionDocsInfo = self.getUnsignedBixConversion(policy?.id);

      const activePolicies = self.list?.filter((p) => getPolicyStatus(p) === PolicyStatus.Active) || [];

      const accountStore = getParent(self);

      const isPolicyAgencySold = policy?.isAgentSold || policy?.offer?.quote?.isAgentSold;

      const isCrossSellEligible =
        activePolicies.length === 1 && policy && isPolicyCrossSellEligible(policy, isPolicyAgencySold, isAgencyUser);

      let isPendingThirdPartyPurchases = false;
      if (policy) {
        const { policyType, policyDetails, offer } = policy;
        const hasHome = policyType === policyTypes.Home || policyType === policyTypes.HABundle;

        if (hasHome && policyDetails) {
          const { homeCoverage, includeEarthquake, earthquakeCoverage, includeFlood, floodCoverage } = policyDetails;
          const { canAddEarthquake, canAddFlood } = checkAdditionalCoverages(offer, policyType);
          if (
            (homeCoverage?.interestedInSeparateWindHail && !homeCoverage?.externalPolicyId) ||
            (canAddEarthquake && includeEarthquake && !earthquakeCoverage?.policyId) ||
            (canAddFlood && includeFlood && !floodCoverage?.policyId)
          ) {
            isPendingThirdPartyPurchases = true;
          }
        }
      }

      if (!bixConversionDocsInfo?.signedDocUploadedInS3) {
        notifications.push(Notification.Policy.SignedConversionDocuments);
      }
      if (isCrossSellEligible) {
        notifications.push(Notification.Policy.CrossSellEligibility);
      }
      if (isPendingThirdPartyPurchases) {
        notifications.push(Notification.Policy.PendingThirdPartyPurchases);
      }
      if (accountStore.customerMetadata?.suspiciousActivity?.flagged) {
        notifications.push(Notification.Policy.SuspiciousAccount);
      }

      if (accountStore.incompleteTasks.length) {
        notifications.push(Notification.Policy.IncompleteTasks);
      }

      if (!isAgencyUser && isPolicyAgencySold) {
        notifications.push(Notification.Policy.TransferToAgency);
      }

      if (notifications.length) {
        self.notifications = notifications;
      } else {
        self.notifications = null;
      }
    }
  }))
  .views((self) => ({
    getHomePolicyDetails() {
      const homePolicy = self.list
        ? self.list.find(
            (policy) =>
              policy.policyType === policyTypes.Home &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
          )
        : undefined;
      let mergedPolicyDetails;
      if (homePolicy) {
        const { segments } = homePolicy;
        const mappedSegments = segments.slice().map(clean);
        if (mappedSegments.length >= 1) {
          mergedPolicyDetails = getMerged(mappedSegments, mappedSegments[segments.length - 1].segmentId);
        }
      }
      return mergedPolicyDetails;
    },

    getAutoPolicyDetails() {
      const inactivePolicies = self.list.filter(
        (policy) =>
          policy.policyType === policyTypes.Auto &&
          (getPolicyStatus(policy) === PolicyStatus.Inactive ||
            (getPolicyStatus(policy) === PolicyStatus.Cancelled && !policy.endDate === policy.effectiveDate))
      );
      const activeOrFuturePolicies = self.list.filter(
        (policy) =>
          policy.policyType === policyTypes.Auto &&
          (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
      );
      // this allows for either the most recent Inactive or Cancelled Policy to be check specifically for use in Umbrella validations
      const mostRecentInactive = self.getRecentNonActivePolicy(inactivePolicies);
      const autoPolicy = activeOrFuturePolicies.length
        ? activeOrFuturePolicies[0]
        : self.list
        ? self.list.find(
            (policy) =>
              policy.policyType === policyTypes.Auto &&
              (getPolicyStatus(policy) === PolicyStatus.Active ||
                getPolicyStatus(policy) === PolicyStatus.Future ||
                getPolicyStatus(policy) === PolicyStatus.InCancellation ||
                mostRecentInactive)
          )
        : undefined;
      let mergedPolicyDetails;
      if (autoPolicy) {
        const { segments } = autoPolicy;
        const mappedSegments = segments.slice().map(clean);
        if (mappedSegments.length >= 1) {
          mergedPolicyDetails = getMerged(mappedSegments, mappedSegments[segments.length - 1].segmentId);
        }
      }
      return mergedPolicyDetails;
    },

    hasActiveOrFutureAutoPolicy() {
      return self.list
        ? self.list.find((policy) => {
            return (
              policy.policyType === policyTypes.Auto &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
            );
          })
        : undefined;
    },

    hasActiveOrFutureHomePolicy() {
      return self.list
        ? self.list.find((policy) => {
            return (
              policy.policyType === policyTypes.Home &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
            );
          })
        : undefined;
    },

    hasSecondHome() {
      const activeAndFutureHomePolicies = self.list
        ? self.list.filter(
            (policy) =>
              policy.policyType === policyTypes.Home &&
              (getPolicyStatus(policy) === PolicyStatus.Active || getPolicyStatus(policy) === PolicyStatus.Future)
          )
        : [];

      // Need to filter by the stem of the policy to get unique ones becaue it can be possible that the policy
      // is Active and the renewal policy for the same home is already created with a status of Future
      const uniqueActiveAndFutureHomePolicies = uniqBy(activeAndFutureHomePolicies, (policy) => {
        const policyIdArray = policy.id.split('-');
        const stem = `${policyIdArray[0]}-${policyIdArray[1]}`;
        return stem;
      });

      return uniqueActiveAndFutureHomePolicies.length > 1;
    },
    get achPoliciesWithUniqueBankId() {
      return self.list
        ? uniqBy(
            filter(self.list, (policy) => {
              return policy.paymentMethod === paymentMethod.ACH;
            }),
            'stripeBankInfo.stripeBankAccountId'
          )
        : [];
    },
    getStripeBankInfo(policyId) {
      return self.list?.find((e) => e.id === policyId)?.stripeBankInfo;
    },
    getPoliciesNeedingStripeBankVerification() {
      // eslint-disable-next-line array-callback-return
      return self.list.reduce((result, policy) => {
        if (
          policy.paymentMethod === paymentMethod.ACH &&
          policy.stripeBankInfo?.achStatus !== stripeBankStatus.Verified &&
          getPolicyStatus(policy) !== PolicyStatus.Inactive
        ) {
          result.push(policy.id);
          return result;
        }
        return result;
      }, []);
    },
    getUnsignedBixConversion(policyId) {
      return {
        signedDocUploadedInS3: !self.unsignedBixConversions?.find((policy) => policy.policyId === policyId),
        signedTimestamp: self.policy.bixConversionSignedTimestamp
      };
    },
    getRecentPolicy() {
      const recent = _orderBy(
        self.list,
        (policy) => {
          const mostRecentVersion = policy.versionHistory.length - 1;
          return policy.versionHistory[mostRecentVersion].updatedDateTime;
        },
        ['desc']
      )[0];
      return recent;
    },
    getRecentNonActivePolicy(policies) {
      const recent = _orderBy(
        policies,
        (policy) => {
          const mostRecentVersion = policy.versionHistory.length - 1;
          return policy.versionHistory[mostRecentVersion].updatedDateTime;
        },
        ['desc']
      )[0];
      return recent;
    },
    get unsigned() {
      if (self.unsignedApplications && self.unsignedApplications.length && self.list.length) {
        const [application] = self.unsignedApplications;
        return self.list.some((p) => p.id === application.policyId) ? application : null;
      }
      return null;
    },
    get hasUmbrella() {
      return self.list.some(
        (policy) =>
          (policy.policyType === 'H' || policy.policyType === 'C') &&
          [PolicyStatus.Future, PolicyStatus.Active].includes(getPolicyStatus(policy)) &&
          policy.policyDetails.includeUmbrella
      );
    }
  }));
