import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { capitalize } from 'core/helpers/formatters';
import ValueField from 'core/components/value-field';
import Section from 'core/components/section';

const ClaimInfo = ({ claim }) => {
  return (
    <Section title="General Claim Info">
      <Grid container flex="row">
        <Grid item xs={6}>
          <ValueField label="Date Of Loss" value={claim.lossDate || 'N/A'} />
        </Grid>
        <Grid item xs={6}>
          <ValueField label="Policy" value={claim.policyId || 'N/A'} />
        </Grid>
      </Grid>
      <Grid container flex="row">
        <Grid item xs={6}>
          <ValueField label="Cause of Loss" value={capitalize(claim.cause) || 'N/A'} />
        </Grid>
        <Grid item xs={6}>
          <ValueField label="Claim Type" value={claim.claimType || 'N/A'} />
        </Grid>
      </Grid>
    </Section>
  );
};

ClaimInfo.propTypes = {
  claim: PropTypes.object.isRequired
};

export default observer(ClaimInfo);
