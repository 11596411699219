import React, { memo, useEffect, useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import { TableHeaderCell } from 'core';
import { Label } from 'core/components/label';
import { Table } from 'core/components/table';
import { displayData } from 'tools/helpers';
import DownloadButton from './download-button';
import FilePathOutput from './file-path-output';
import useStyles from '../generate-task-ui.styles';

const cleanOutput = (output) => {
  if (!output || !Array.isArray(output)) return null;
  return output.map((data) => {
    const { __typename, taskRunId, details, ...rest } = data;
    if (details) {
      return { ...rest, ...details };
    }
    return rest;
  });
};

const getAllKeys = (objects) => {
  if (!objects) {
    return [];
  }

  const keys = new Set();
  objects.forEach((object) => {
    Object.keys(object).forEach((key) => {
      if (key !== 'success' && key !== '__typename' && key !== 'details') {
        keys.add(key);
      }

      if (key === 'details') {
        Object.keys(object.details).forEach((detailKey) => {
          keys.add(detailKey);
        });
      }
    });
  });
  return Array.from(keys);
};

const getOrderedTables = (stateMachineOutput) => {
  const columns = Object.keys(stateMachineOutput).filter((key) => stateMachineOutput[key].length > 0);
  // Filter out 'success' and 'failed' to handle them separately
  const successIndex = columns.indexOf('success');
  const failedIndex = columns.indexOf('failed');

  // Remove 'success' and 'failed' from the original array to avoid duplication
  if (successIndex !== -1) {
    columns.splice(successIndex, 1);
  }

  if (failedIndex !== -1) {
    columns.splice(failedIndex - (successIndex !== -1 && successIndex < failedIndex ? 1 : 0), 1);
  }

  // Add 'success' at the start if it exists
  if (successIndex !== -1) {
    columns.unshift('success');
  }

  // Add 'failed' at the end if it exists
  if (failedIndex !== -1) {
    columns.push('failed');
  }

  return columns;
};

const formatCamelCaseToWords = (key) => {
  // Check if the key is camel case
  if (/^[a-z]+([A-Z][a-z]*)*$/.test(key)) {
    // Split the string at every uppercase letter and capitalize each word
    return (
      key
        // Insert a space before each uppercase letter (but not at the beginning)
        .replace(/([A-Z])/g, ' $1')
        // Trim spaces and split into words
        .trim()
        // Capitalize the first letter of each word
        .split(/\s+/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    );
  }
  // do nothing otherwise
  return key;
};

const rowsPerPage = 15;

function StateMachineOutput({ stateMachineOutput, hideTitle, hideDownload }) {
  const classes = useStyles();
  const { filePaths, type, ...taskOutput } = stateMachineOutput;
  const outputTables = type === 'OUTPUT' ? getOrderedTables(taskOutput) : [];

  const [paginationState, setPaginationState] = useState({});

  const onlyFilePathsToDisplay = !outputTables?.length && filePaths?.length;

  // initialize pagination
  useEffect(() => {
    const pagination = {};
    outputTables.forEach((outputKey) => {
      pagination[outputKey] = {
        page: 0,
        rowsPerPage
      };
    });
    setPaginationState(pagination);
  }, []);

  const handleChangePage = (table, newPage) => {
    setPaginationState({ ...paginationState, [table]: { page: newPage, rowsPerPage } });
  };

  return (
    <div className={classes.container}>
      <div className={classes.output}>
        {!hideTitle && (
          <Label type="subtitle" className={classes.outputTitle}>
            Output
          </Label>
        )}
        {taskOutput?.type === 'ERROR' && (
          <Label className={classes.outputError} type="subtitle">
            There was an error: {taskOutput?.message}
          </Label>
        )}
        {outputTables.map((outputKey) => {
          const rows = cleanOutput(stateMachineOutput[outputKey]);
          return (
            <div key={outputKey} className={classes.outputTable}>
              <div className={classes.outputHeader}>
                <Label type="subtitle">{formatCamelCaseToWords(outputKey)}</Label>
                <div className={classes.rightHeader}>
                  <TablePagination
                    component="div"
                    classes={{ root: classes.pagination }}
                    count={rows.length}
                    rowsPerPage={paginationState[outputKey]?.rowsPerPage || 0}
                    page={paginationState[outputKey]?.page || 0}
                    onPageChange={(event, newPage) => handleChangePage(outputKey, newPage)}
                    rowsPerPageOptions={[]}
                  />
                  {/* TODO: hiding downnload button until we have a way to re-generate signed url when expired */}
                  {filePaths?.some((file) => file.output === outputKey) && !hideDownload && (
                    <DownloadButton path={filePaths.find((file) => file.output === outputKey).path} />
                  )}
                </div>
              </div>

              <Paper className={classes.paper}>
                <Table
                  key={`table-${outputKey}`}
                  className={classes.table}
                  header={
                    <>
                      {getAllKeys(rows).map((key) => (
                        <TableHeaderCell key={key} columnId={key}>
                          {key}
                        </TableHeaderCell>
                      ))}
                    </>
                  }
                  body={rows
                    ?.slice(
                      paginationState[outputKey]?.page * paginationState[outputKey]?.rowsPerPage,
                      paginationState[outputKey]?.page * paginationState[outputKey]?.rowsPerPage +
                        paginationState[outputKey]?.rowsPerPage
                    )
                    .map((data, ind) => (
                      <TableRow key={`row-${outputKey}-${ind}`}>
                        {getAllKeys(stateMachineOutput[outputKey]).map((column, index) => (
                          <TableCell key={`${column}-${index}`}>{displayData(data[column])}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                />
              </Paper>
            </div>
          );
        })}
        {onlyFilePathsToDisplay && <FilePathOutput filePaths={filePaths} />}
      </div>
    </div>
  );
}

export default memo(StateMachineOutput);
