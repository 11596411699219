import React from 'react';

import CollapsibleCard from 'core/components/collapsible-card';
import Section from 'core/components/section';
import { Label } from 'core/components/label';
import useSession from 'core/hooks/use-session';
import { tools } from './constants';
import { ToolItem } from './tool-item';
import useStyles from './tool-item/tool-item.styles';

const ToolsList = () => {
  const session = useSession();
  const classes = useStyles();

  return (
    <Section>
      <Label type="title" marginBottom={16}>
        Staff Tools
      </Label>
      {tools.map((tool, ind) =>
        tool.items.some((item) => session[item.permissionName]) ? (
          <CollapsibleCard
            key={`${tool.category}-${ind}`}
            timeout="auto"
            unmountOnExit
            content={
              <Label type="subtitle" className={classes.category}>
                {tool.category}
              </Label>
            }
            initialOpen
          >
            <Section>
              {tool.items
                .filter((item) => session[item.permissionName])
                .map((item) => (
                  <ToolItem item={item} />
                ))}
            </Section>
          </CollapsibleCard>
        ) : null
      )}
    </Section>
  );
};

export default ToolsList;
