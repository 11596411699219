import Grid from '@material-ui/core/Grid';
import { lookupsJson } from '@ourbranch/lookups';
import Checkbox from 'core/components/checkbox';
import CollapsibleCard from 'core/components/collapsible-card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import RemoveButton from 'core/components/remove-button';
import { connect, Form, getIn } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import useStyles from '../person.styles';

function InterestedParty({
  index,
  fieldId,
  fieldName,
  item,
  removeFromList,
  onRemove,
  initialFold,
  onFold,
  disabled,
  formik: { setFieldValue, setFieldTouched, values }
}) {
  const classes = useStyles();

  return (
    <CollapsibleCard
      content={
        <Label
          type="infoSubtitle"
          style={{
            display: 'inline',
            marginRight: 8
          }}
        >{`${item.name}`}</Label>
      }
      contentRight={
        !item.isPrimary && (
          <>
            <Checkbox
              id="lossPayee"
              mode="light"
              label="Loss Payee?"
              onChange={(e) => {
                setFieldValue(`${fieldName}.lossPayee`, e.target.checked);
                setFieldTouched(fieldId);
              }}
              value={getIn(values, `${fieldName}.lossPayee`)}
            />
            <RemoveButton mode="big" onClick={() => onRemove(index, removeFromList)} />
          </>
        )
      }
      initialOpen={initialFold}
      onChange={(val) => onFold(index, val)}
    >
      <Form disabled={disabled} className={classes.container}>
        <Grid key="container" container justifyContent="space-around" alignItems="center" spacing={4}>
          <FormField
            mode="light"
            name={`${fieldName}.name`}
            id={`${fieldName}.name`}
            label="Name or Company"
            type="string"
            xs={8}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.relationship`}
            id={`${fieldName}.relationship`}
            label="Relationship"
            type="select"
            options={lookupsJson.additionalPartyRelationship}
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.address`}
            id={`${fieldName}.address.address`}
            label="Address"
            type="string"
            xs={8}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.address2`}
            id={`${fieldName}.address.address2`}
            label="Address 2"
            type="string"
            optional
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.city`}
            id={`${fieldName}.address.city`}
            label="City"
            type="string"
            optional
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.state`}
            id={`${fieldName}.address.state`}
            label="State"
            type="state"
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
          <FormField
            mode="light"
            name={`${fieldName}.address.zip`}
            id={`${fieldName}.address.zip`}
            label="Zip code"
            type="string"
            optional
            xs={4}
            permissions={{ isLicensedAction: false }}
          />
        </Grid>
      </Form>
    </CollapsibleCard>
  );
}

InterestedParty.propTypes = {
  index: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    isPrimary: PropTypes.bool,
    name: PropTypes.string.isRequired,
    relationship: PropTypes.string.isRequired,
    address: PropTypes.object
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  initialFold: PropTypes.bool.isRequired,
  onFold: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired
};

InterestedParty.defaultProps = {
  disabled: false
};

export default flowRight(connect, memo)(InterestedParty);
