import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import { track } from 'core/helpers/analytics';
import { useStore } from 'core/store';
import { useToast } from 'core/components/toast';
import useSession from 'core/hooks/use-session';
import useStyles from './confirm-override-modal.styles';

const ConfirmOverrideModal = ({ error, onClose, canOverrideWithoutSaving }) => {
  const classes = useStyles();
  const { handleSubmit, dirty, values, setFieldValue, setFieldTouched } = useFormikContext();
  const toast = useToast();
  const {
    isAgency,
    canClearUWFormRejections,
    user: { username }
  } = useSession();
  const {
    offer: { offer }
  } = useStore();

  const onOverride = () => {
    if (dirty && !canOverrideWithoutSaving) {
      toast.notify({
        type: 'error',
        message: 'Please save your changes before overriding'
      });
    } else {
      const errorsList = values.overrides?.overridesForUW?.length ? [...values.overrides.overridesForUW] : [];
      errorsList.push({
        code: parseInt(error.code, 10),
        overridingAgent: username,
        type: error.type
      });
      setFieldValue('overrides.overridesForUW', errorsList);
      setFieldTouched('overrides.overridesForUW', true);

      handleSubmit();

      track('Underwriting Rejection Overridden', {
        rejectionCode: error.code,
        rejectionReason: error.message,
        impactedPolicyType: offer?.quote?.selectedOption,
        offerId: offer.id,
        isUnderwriting: canClearUWFormRejections,
        username,
        isAgency,
        email: offer?.quote?.email,
        phone: offer?.quote?.phone,
        logEmail: 'membercomms@ourbranch.com'
      });
    }
  };

  return (
    <BaseDialog size="lg" onClose={onClose} open>
      <div className={classes.container}>
        <Label className={classes.mainLabel} type="greenBig">
          Are you sure you want to override this error?
        </Label>
        <Label type="modalBody" className={classes.description}>
          Moving forward with this action would override an error that could enable Branch to insure assets that do not
          meet our Underwriting guidelines. Only override errors if you can provide proof of eligibility or you are sure
          Branch can cover these assets.
        </Label>
        <NotificationCard className={classes.warning} type="light">
          <Label type="body1">You will not be able to undo this action.</Label>
        </NotificationCard>
        <div className={classes.buttonContainer}>
          <Button className={classes.buttons} variant="contained" color="primary" onClick={onOverride}>
            Yes, override error
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose} className={classes.buttons}>
            No, keep
          </Button>
        </div>
      </div>
    </BaseDialog>
  );
};

ConfirmOverrideModal.propTypes = {
  error: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  canOverrideWithoutSaving: PropTypes.bool
};

ConfirmOverrideModal.defaultProps = {
  canOverrideWithoutSaving: false
};

export default observer(ConfirmOverrideModal);
