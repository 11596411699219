import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store/';
import { Discount } from '../discount';

const BranchPlusMultiPolicyDiscount = observer(({ isActive, ...discount }) => {
  const {
    offer: {
      setShowBranchPlusMultiPolicyDiscountModal,
      setIncludeBranchPlusMultiPolicyDiscount,
      includeBranchPlusMultiPolicyDiscount
    }
  } = useStore();

  const { setFieldValue, values } = useFormikContext();
  let isApplied = values.branchPlusPolicies?.length ? true : isActive;

  const onAddClick = () => {
    setShowBranchPlusMultiPolicyDiscountModal(true);
  };

  const onRemoveClick = useCallback(() => {
    setFieldValue('branchPlusPolicies', []);
    setIncludeBranchPlusMultiPolicyDiscount(false);
    isApplied = false;
  }, [setShowBranchPlusMultiPolicyDiscountModal]);

  return (
    <Discount
      {...discount}
      isActive={isApplied}
      onAdd={onAddClick}
      onRemove={onRemoveClick}
      canEdit
      includeBranchPlusMultiPolicyDiscount={includeBranchPlusMultiPolicyDiscount}
    />
  );
});

export default BranchPlusMultiPolicyDiscount;
