import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  policyCard: ({ inOffer, first, last }) => ({
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius:
      first && last ? theme.sizes.roundCorners : first ? '10px 10px 0px 0px' : last ? '0px 0px 10px 10px' : 0,
    backgroundColor: inOffer ? theme.colorPalette.white_10 : '#52583C',
    width: '99.5%',
    color: inOffer ? 'black' : 'white',
    borderBottom: inOffer
      ? `2px solid ${theme.colorPalette.white_30}`
      : last
      ? null
      : `1px solid ${theme.colorPalette.white_10}`
  }),
  policyCardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  policyCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 0px 2px 10px'
  },
  removeButton: {
    marginRight: theme.spacing(2)
  },
  addPolicyContainer: ({ inOffer }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.sizes.roundCorners,
    border: inOffer ? `2px solid ${theme.colorPalette.white_10}` : `2px solid ${theme.colorPalette.white_10_op_30}`,
    padding: theme.spacing(4, 6, 2, 6),
    width: '100%',
    marginTop: theme.spacing(5),
    color: inOffer ? 'black' : 'white'
  }),
  noPoliciesCard: ({ inOffer }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: 80,
    width: '100%',
    alignItems: 'center',
    backgroundColor: inOffer ? theme.colorPalette.white_10 : theme.colorPalette.green_30,
    color: inOffer ? 'black' : 'white'
  }),
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: theme.spacing(2, 0, 2, 0)
  },
  button: {
    width: 300
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    width: '100%',
    padding: theme.spacing(6, 8),
    margin: 0,
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners,
    rowGap: theme.spacing(4)
  },
  font: {
    color: 'white'
  }
}));

export default useStyles;
