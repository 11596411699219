import React from 'react';

import { useStore } from 'core/store';
import { Error } from 'common/components/error';
import useStyles from './rejections.styles';

const Rejections = () => {
  const {
    quote: { offer, rejections }
  } = useStore();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {rejections.map((rejection) => (
        <Error
          key={rejection.code}
          error={rejection}
          type="secondary"
          state={offer?.quote?.correctedAddress?.state}
          canOverrideWithoutSaving
        />
      ))}
    </div>
  );
};

export default Rejections;
