import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: 720,
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    height: '100%',
    marginBottom: theme.spacing(8)
  },
  row: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    justifyContent: 'center'
  },
  floatingButton: {
    position: 'absolute',
    width: 140,
    fontWeight: 600,
    fontSize: 14,
    right: 0,
    top: theme.spacing(1.5)
  },
  newContainer: {
    marginBottom: theme.spacing(30)
  },
  withRejections: ({ rejections, blocks }) => ({
    marginBottom: theme.spacing(72 + rejections * 10 + blocks * 10)
  }),
  title: {
    justifyContent: 'center',
    marginBottom: theme.spacing(10)
  },
  submit: {
    width: 260,
    fontWeight: 600,
    fontSize: 16
  },
  warning: {
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(5)
  }
});

export default makeStyles(styles);
