import React from 'react';
import { observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import { currencyFormatter } from 'core/helpers/formatters';
import useStyles from './accounting-breakdown.styles';

function AccountingBreakdown() {
  const {
    account: {
      policies: { policy }
    }
  } = useStore();
  const classes = useStyles();
  return (
    <>
      <Label type="coverageValue">Accounting Breakdown</Label>
      <Card className={classes.container} alignItems="center" justifyContent="space-between" container>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Label className={classes.label}>Total Cash Collected</Label>
                <Label className={classes.value}>{currencyFormatter(policy.currentTotalPaid)}</Label>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Label className={classes.label}>Pending Amount</Label>
                <Label className={classes.value}>{currencyFormatter(policy.pendingAmount)}</Label>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  );
}

export default observer(AccountingBreakdown);
