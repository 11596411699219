import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  feeBreakdownTable: {
    '& td, th': {
      borderBottom: 'none',
      padding: theme.spacing(4, 0),
      whiteSpace: 'nowrap',
      display: 'flex',
      justifyContent: 'space-between',
      '&:first-of-type': {
        minWidth: 110
      }
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`,
      '&:first-of-type > td': {
        paddingTop: 0
      },
      '&:last-of-type': {
        borderBottom: 'none',
        '& > td': {
          paddingBottom: 0
        }
      }
    },
    '& tr:last-of-type': {
      borderBottom: 'none'
    }
  },
  feeDescription: {
    color: theme.colorPalette.beige_10,
    width: '100%'
  },
  feeSummary: {
    alignItems: 'center',
    display: 'flex',
    height: 73,
    gap: theme.spacing(4),
    justifyContent: 'flex-end'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height: 20
  },
  feeSelectorContainer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'space-between'
  },
  card: {
    display: 'flex',
    backgroundColor: theme.colorPalette.green_30,
    margin: theme.spacing(4, 0),
    alignItems: 'center',
    padding: theme.spacing(6)
  },
  noFeesCard: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: theme.colorPalette.beige_10
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: theme.colorPalette.beige_10
  },
  button: {
    fontWeight: 600,
    fontSize: 16,
    width: 196
  },
  normal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  undo: {
    composes: '$normal',
    opacity: 0.6
  }
}));

export default useStyles;
