import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';

import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { FormField } from 'core/components/form';
import { awsDateToJs } from 'core/helpers/formatters';
import withDatePicker from 'core/components/with-date-picker';
import useStyles from './reinstate-form.styles';

const ReinstateForm = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const maxDate = awsDateToJs(values.fullTermPolicyEndDate);

  if (values.reinstateDate !== values.fullTermPolicyEndDate) {
    setFieldValue('reinstateDate', values.fullTermPolicyEndDate);
  }

  return (
    <Grid container className={classes.form}>
      <FormField
        name="reinstateDate"
        type="date"
        label="Last Full Day of Coverage"
        mode="light"
        xs={6}
        disableFuture={false}
        maxDate={maxDate}
        initialFocusedDate={values.fullTermPolicyEndDate}
        ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
      />
      <FormField
        type="checkbox"
        id="applyFee"
        name="applyFee"
        mode="light"
        label="Apply Fee"
        xs={3}
        ignoreGlobalDisabledState
        permissions={{
          edit: {
            groups: [CognitoPermissionGroups.canEditFeeCheckbox]
          }
        }}
      />
    </Grid>
  );
};

export default withDatePicker(ReinstateForm);
