import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: theme.colors.card__background_primary
  },
  content: {
    backgroundColor: theme.colors.card_background_primary_editable,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8)
  },
  noteCard: {
    margin: 0,
    boxShadow: 'none',
    padding: theme.spacing(6)
  },
  noteText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  button: {
    width: 'fit-content',
    marginTop: theme.spacing(8),
    lineHeight: 2.3,
    display: 'flex',
    alignSelf: 'flex-end',
    minWidth: 100,
    padding: theme.spacing(0, 4),
    fontWeight: 600,
    fontSize: 16
  }
}));

export default useStyles;
