import { flow, types, getEnv, getParent } from 'mobx-state-tree';

import { GET_BRANCH_PLUS_POLICY } from './branch-plus.queries';

export const BranchPlusStore = types
  .model({
    loading: types.boolean,
    branchPlusPolicy: types.maybeNull(types.frozen()),
    error: types.maybeNull(types.string)
  })
  .actions((self) => ({
    getBranchPlusPolicy: flow(function* getBranchPlusPolicy(policyId) {
      self.loading = true;
      self.error = null;
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: GET_BRANCH_PLUS_POLICY,
          variables: {
            policyId
          }
        });
        if (data.branchPlus.success) {
          const { carriers } = getParent(self);
          const carrierInfo = yield carriers.getCarrierInformation(
            data.branchPlus.policy.carrier.name,
            data.branchPlus.policy.policyType
          );
          if (carrierInfo) {
            data.branchPlus.policy.carrier = {
              ...data.branchPlus.policy.carrier,
              ...carrierInfo
            };
          }
          self.branchPlusPolicy = data.branchPlus.policy;
        } else {
          self.error = data.branchPlus.error;
        }
      } catch (error) {
        self.error = error.message;
      } finally {
        self.loading = false;
      }
    })
  }));
