import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import CollapsibleCard from 'core/components/collapsible-card';
import { NotificationCard } from 'core/components/notification-card';
import useStyles from './factors-impacting-price.styles';

const TABS = {
  HOME: 'HOME CHARACTERISTICS',
  CONDO: 'CONDO CHARACTERISTICS',
  PEOPLE: 'PEOPLE',
  CARS: 'CARS'
};

const getDefaultOpenTab = ({ factors }) => {
  if (factors.homeFactors) {
    return TABS.HOME;
  }

  if (factors.peopleFactors) {
    return TABS.PEOPLE;
  }

  if (factors.carFactors) {
    return TABS.CARS;
  }

  if (factors.condoFactors) {
    return TABS.CONDO;
  }
};

const FactorsImpactingPrice = observer(({ open, onClose, factors }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(getDefaultOpenTab({ factors }));
  const { offer: store } = useStore();
  const session = useContext(AuthContext);

  const { offerId } = useParams();
  const { protocol, host } = window.location;
  const detailsLink = `${protocol}//${host}/offer/${offerId}/details`;

  const homeRejected = !!store.offer.rejections.find((r) => r.type === 'home');
  const autoRejected = !!store.offer.rejections.find((r) => r.type === 'auto');

  const redirectToCompleteOfferDetails = () => {
    window.open(detailsLink, '_blank');
  };

  return (
    <BaseDialog open={open} onClose={onClose} blockContent>
      <Label type="greenBig" className={classes.bottomSpacing}>
        Factors impacting price and eligibility
      </Label>

      <Tabs value={tab} variant="fullWidth" onChange={(_, newTab) => setTab(newTab)} className={classes.bottomSpacing}>
        {factors.homeFactors && <Tab value={TABS.HOME} label="Home Characteristics" className={classes.tab} />}
        {factors.peopleFactors && <Tab value={TABS.PEOPLE} label="People" className={classes.tab} />}
        {factors.carFactors && <Tab value={TABS.CARS} label="Cars" className={classes.tab} />}
        {factors.condoFactors && <Tab value={TABS.CONDO} label="Condo Characteristics" className={classes.tab} />}
      </Tabs>

      {/* ----- HOME FACTORS ----- */}
      {tab === TABS.HOME && factors?.homeFactors?.length > 0 && (
        <Grid className={classes.homeFactorRows}>
          {homeRejected && (
            <NotificationCard type="light">
              <Grid container alignItems="center">
                Some factors listed below may have impacted the eligibility of a rejected policy type, rather than the
                current policy's price. See Policy Errors for more information.
              </Grid>
            </NotificationCard>
          )}
          {factors?.homeFactors?.map((homeFactor, index) => (
            <Grid
              item
              key={`home-factor-${index}`}
              className={classnames(classes.factorRow, { [classes.divider]: factors.homeFactors.length > 1 })}
            >
              {homeFactor}
            </Grid>
          ))}
        </Grid>
      )}

      {/* ----- CONDO FACTORS ----- */}
      {tab === TABS.CONDO && factors?.condoFactors?.length > 0 && (
        <Grid className={classes.homeFactorRows}>
          {factors?.condoFactors?.map((condoFactor, index) => (
            <Grid
              item
              key={`condo-factor-${index}`}
              className={classnames(classes.factorRow, { [classes.divider]: factors.condoFactors.length > 1 })}
            >
              {condoFactor}
            </Grid>
          ))}
        </Grid>
      )}

      {/* ----- PEOPLE FACTORS ----- */}
      {tab === TABS.PEOPLE &&
        factors?.peopleFactors &&
        Object.keys(factors.peopleFactors).map((id) => {
          const person = store.offer.quote.drivers.find((person) => person.id === id);

          return (
            <>
              {autoRejected && (
                <NotificationCard type="light">
                  <Grid container alignItems="center">
                    Some factors listed below may have impacted the eligibility of a rejected policy type, rather than
                    the current policy's price. See Policy Errors for more information.
                  </Grid>
                </NotificationCard>
              )}
              <CollapsibleCard
                key={id}
                content={
                  <>
                    <Label
                      type="infoSubtitle"
                      className={classes.personCardLabel}
                    >{`${person.firstName} ${person.lastName}`}</Label>
                    {person.isPrimary && <Label type="infoCardTitle">Applicant</Label>}
                    {person.isCoApplicant && <Label type="infoCardTitle">Co Applicant</Label>}
                  </>
                }
              >
                <Grid className={classes.collapseCardRows}>
                  {factors.peopleFactors[id].map((personFactor, index) => (
                    <Grid
                      item
                      key={`person-factor-${index}`}
                      className={classnames(classes.collapseCardRow, {
                        [classes.divider]: factors.peopleFactors[id].length > 1
                      })}
                    >
                      {personFactor}
                    </Grid>
                  ))}
                </Grid>
              </CollapsibleCard>
            </>
          );
        })}

      {/* ----- CARS FACTORS ----- */}
      {tab === TABS.CARS &&
        factors?.carFactors &&
        Object.keys(factors.carFactors).map((VIN) => {
          const car = store.offer.quote.cars.find((c) => c.VIN === VIN);

          return (
            <CollapsibleCard
              key={VIN}
              content={
                <Label
                  type="infoSubtitle"
                  className={classes.personCardLabel}
                >{`${car.make} ${car.model} ${car.year}`}</Label>
              }
            >
              <Grid className={classes.collapseCardRows}>
                {factors.carFactors[VIN].map((carFactor, index) => (
                  <Grid
                    item
                    key={`car-factor-${index}`}
                    className={classnames(classes.collapseCardRow, {
                      [classes.divider]: factors.carFactors[VIN].length > 1
                    })}
                  >
                    {carFactor}
                  </Grid>
                ))}
              </Grid>
            </CollapsibleCard>
          );
        })}

      <Grid container justifyContent="flex-end" spacing={2} className={classes.buttons}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Grid>
        {session.canSeeFullOfferDetails && (
          <Grid item>
            <Button variant="contained" color="secondary" onClick={redirectToCompleteOfferDetails}>
              Complete Offer Details
            </Button>
          </Grid>
        )}
      </Grid>
    </BaseDialog>
  );
});

export default FactorsImpactingPrice;
