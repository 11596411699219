import React from 'react';
import { withRouter } from 'react-router-dom';
import flowRight from 'lodash-es/flowRight';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { AuthContext } from '../auth';
import styles from './account-menu.styles';
import account from './account.svg';

class AccountMenu extends React.Component {
  state = {
    userMenuEl: null
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleToolsReroute = this.handleToolsReroute.bind(this);
  }

  handleClick(event) {
    this.setState({
      userMenuEl: event.currentTarget
    });
  }

  handleClose() {
    this.setState({ userMenuEl: null });
  }

  handleToolsReroute() {
    const { history } = this.props;
    history.push('/staff/tools');
  }

  render() {
    const { props, state } = this;
    const { userMenuEl } = state;
    const { classes } = props;

    return (
      <AuthContext.Consumer>
        {(session) => (
          <>
            <IconButton
              color="primary"
              aria-label="Profile"
              className={classes.buttonUser}
              onClick={this.handleClick}
              data-cy="menu"
            >
              <img src={account} alt="account" />
            </IconButton>
            <Menu
              anchorEl={userMenuEl}
              open={Boolean(userMenuEl)}
              onClose={this.handleClose}
              onClick={this.handleClose}
            >
              {session.canViewTools && <MenuItem onClick={this.handleToolsReroute}>Tools</MenuItem>}
              <MenuItem onClick={session.logout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </AuthContext.Consumer>
    );
  }
}
export default flowRight(withRouter, withStyles(styles))(AccountMenu);
