import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    display: 'block',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(0, 2),
    fontSize: 24,
    fontWeight: 400
  },
  selected: {
    borderLeft: `4px solid ${theme.colors.button__background_secondary}`,
    fontWeight: 600
  }
});

export default makeStyles(styles);
