import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';
import { getValue, standaloneRentersStates } from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import Switch from 'core/components/switch';
import { numberFormatter } from 'core/helpers/formatters';
import usePlanPrice from 'offer/hooks/use-plan-price';
import useDetailedPrice from 'offer/hooks/use-detailed-price';
import { PriceDetail } from './price-detail';
import { CardLine } from '../card-line';
import { getFactorsImpactingPrice } from '../factors-impacting-price/helpers';
import FactorsImpactingPrice from '../factors-impacting-price';
import useStyles from './price-details-card.styles';

const PriceDetailsCard = observer(() => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const { values } = useFormikContext();
  const [showFactorsImpactingPriceModal, setOpenFactorsImpactingPriceModal] = useState(false);
  const [showExtendedPriceInformation, setShowExtendedPriceInformation] = useState(false);

  const toggleModal = useCallback(
    () => setOpenFactorsImpactingPriceModal(!showFactorsImpactingPriceModal),
    [setOpenFactorsImpactingPriceModal, showFactorsImpactingPriceModal]
  );

  const toggleExtendedPriceInformation = useCallback(
    () => setShowExtendedPriceInformation(!showExtendedPriceInformation),
    [setShowExtendedPriceInformation, showExtendedPriceInformation]
  );

  const type = values.selectedOption || '';

  const showAuto = type.includes('A');
  const showHome = type.includes('H');
  const showCondo = type.includes('C');
  const showRenters = type.includes('R') && standaloneRentersStates[store.state];
  const homeSelected = store.selectedOption?.includes('H');
  const autoSelected = store.selectedOption?.includes('A');
  const factors = getFactorsImpactingPrice({ offer: store.offer, homeSelected, autoSelected });

  // for the "fake" types we need the actual type to get the payments status
  const priceType = type === 'HB' || type === 'AB' ? 'HA' : type;
  const option = store.offer.options.find((opt) => opt.type === priceType);

  const prices = usePlanPrice(store.offer, priceType, useDetailedPrice);

  const { global } = store.offer.quote;

  return option ? (
    <Card className={classes.card}>
      <div className={classes.header}>
        <Label type="infoSubtitleWhite">Price details</Label>
      </div>
      <div className={classes.content}>
        {showHome && (
          <PriceDetail
            name={option.name}
            type="Home"
            policyType={option.type}
            isMonthly={prices.isHomeMonthly}
            downPaymentDate={prices.homeDownPaymentDate}
            remainingPayments={prices.homeRemainingPayments}
            remainingPaymentsAmount={prices.homeRemainingPaymentsAmount}
            payment={prices.homePrice}
            paymentMethod={getValue('homeownersPaymentMethod', global.homeownersPaymentMethod)}
            paymentType={getValue('paymentType', global.homeownersPaymentType)}
            termTotal={numberFormatter(option?.homeTotal, 2)}
            stale={store.isStale}
            allowCollapsableContent
            nextPayments={prices.homeNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        {showCondo && (
          <PriceDetail
            name={option.name}
            type="Condo"
            policyType={option.type}
            isMonthly={prices.isCondoMonthly}
            downPaymentDate={prices.condoDownPaymentDate}
            remainingPayments={prices.condoRemainingPayments}
            remainingPaymentsAmount={prices.condoRemainingPaymentsAmount}
            payment={prices.condoPrice}
            paymentMethod={getValue('condoPaymentMethod', store.offer.quote.global.condoPaymentMethod)}
            paymentType={getValue('paymentType', store.offer.quote.global.condoPaymentType)}
            termTotal={numberFormatter(option.condoTotal, 2)}
            stale={store.isStale}
            allowCollapsableContent
            nextPayments={prices.condoNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        {showAuto && (
          <PriceDetail
            name={option.name}
            type="Auto"
            policyType={option.type}
            isMonthly={prices.isAutoMonthly}
            downPaymentDate={prices.autoDownPaymentDate}
            remainingPayments={prices.autoRemainingPayments}
            remainingPaymentsAmount={prices.autoRemainingPaymentsAmount}
            payment={prices.autoPrice}
            paymentMethod={getValue('autoPaymentMethod', store.offer.quote.global.autoPaymentMethod)}
            paymentType={getValue('paymentType', store.offer.quote.global.autoPaymentType)}
            termTotal={numberFormatter(option.autoTotal, 2)}
            autoTotalAR={numberFormatter(prices.autoTotalAR, 2)}
            rentersTotalAR={numberFormatter(prices.rentersTotalAR, 2)}
            stale={store.isStale}
            allowCollapsableContent
            nextPayments={prices.autoNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        {showRenters && (
          <PriceDetail
            name={option.name}
            type="Renters"
            policyType={option.type}
            isMonthly={prices.isRentersMonthly}
            downPaymentDate={prices.rentersDownPaymentDate}
            remainingPayments={prices.rentersRemainingPayments}
            remainingPaymentsAmount={prices.rentersRemainingPaymentsAmount}
            payment={prices.rentersPrice}
            paymentMethod={getValue('rentersPaymentMethod', store.offer.quote.global.rentersPaymentMethod)}
            paymentType={getValue('rentersPaymentType', store.offer.quote.global.rentersPaymentType)}
            termTotal={numberFormatter(option.rentersTotal, 2)}
            stale={store.isStale}
            standaloneRenters={standaloneRentersStates[store.state]}
            allowCollapsableContent
            nextPayments={prices.rentersNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        <Button variant="contained" color="primary" disabled={!factors} onClick={toggleModal}>
          <Label className={classes.button}>
            {factors ? 'See impact factors' : 'There are no additional factors impacting price'}
          </Label>
        </Button>
      </div>
      {showFactorsImpactingPriceModal && (
        <FactorsImpactingPrice open={showFactorsImpactingPriceModal} onClose={toggleModal} factors={factors} />
      )}
      <CardLine className={classes.footer}>
        <Switch
          id="extendedPriceInfo"
          className={classes.switchContainer}
          switchClassName={classes.switch}
          mode="dark"
          label="Show extended price information"
          value={showExtendedPriceInformation}
          onChange={toggleExtendedPriceInformation}
        />
      </CardLine>
    </Card>
  ) : null;
});

export default PriceDetailsCard;
