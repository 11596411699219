import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import useSession from 'core/hooks/use-session';
import { useQuery, gql } from '@apollo/client';
import { useStore } from 'core/store/store.mobx';
import { MyCommunity } from '../my-community';
import { Pledges } from '../pledges';
import { CustomerAdditionalOffersSection } from '../customer-additional-offers-section';
import { MicroDeposits } from '../micro-deposits';
import { AccountDetails } from '../account-details';
import { AgentDetails } from '../agent-details';
import useStyles from './sidebar.styles';

const CustomerSidebar = observer((props) => {
  const {
    account,
    affinityLookups,
    account: {
      policies,
      policies: { achPoliciesWithUniqueBankId }
    }
  } = useStore();
  const recentPolicy = policies.getRecentPolicy();

  const customerAffinityCode = recentPolicy?.segments?.[0]?.global.affinity;
  const affinityData = affinityLookups.data.find(({ affinity }) => affinity === customerAffinityCode);

  const isAgentSold = customerAffinityCode && affinityData?.isAgency;
  const classes = useStyles();
  const { isAgency, isExternalSales, canEditPolicies } = useSession();

  const shouldShowBranchTermLifeAdditionalOffer = Boolean(
    !isAgentSold && !isAgency && !isExternalSales && canEditPolicies
  );

  const assurityLifeEligibilityQuery = useQuery(
    gql`
      query ($policyId: String) {
        assurityLifeCheckEligibility(args: { branchPolicyId: $policyId }) {
          __typename
          ... on AssurityLifeCheckEligibilitySuccessResponse {
            staffUserCapabilities
            benefitAmount
            monthlyPremiumAmount
            annualPremiumAmount
          }
        }
      }
    `,
    { variables: { policyId: recentPolicy?.id }, skip: !recentPolicy?.id }
  );

  const shouldShowAssurityTermLifeAdditionalOffer = Boolean(
    !assurityLifeEligibilityQuery.loading &&
      assurityLifeEligibilityQuery.data?.assurityLifeCheckEligibility.__typename ===
        'AssurityLifeCheckEligibilitySuccessResponse'
  );

  const shouldShowAdditionalOffers =
    shouldShowBranchTermLifeAdditionalOffer || shouldShowAssurityTermLifeAdditionalOffer;

  return (
    <Grid container>
      <Grid item className={classes.bottomSpacing}>
        <AccountDetails {...props} />
      </Grid>
      {canEditPolicies && !!achPoliciesWithUniqueBankId.length && account.id === props.accountId && (
        <Grid item className={classes.bottomSpacing}>
          <MicroDeposits {...props} />
        </Grid>
      )}
      <Grid item className={classes.gridItem}>
        <AgentDetails {...props} />
      </Grid>
      <Grid item className={classes.gridItem}>
        <MyCommunity {...props} />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Pledges {...props} />
      </Grid>
      {shouldShowAdditionalOffers && (
        <Grid item className={classes.gridItem}>
          <CustomerAdditionalOffersSection
            branchTermLife={shouldShowBranchTermLifeAdditionalOffer}
            assurityTermLife={shouldShowAssurityTermLifeAdditionalOffer}
            assurityTermLifeData={{
              ...assurityLifeEligibilityQuery?.data?.assurityLifeCheckEligibility,
              branchPolicyId: recentPolicy?.id
            }}
          />
        </Grid>
      )}
    </Grid>
  );
});

export default CustomerSidebar;
