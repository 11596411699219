import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    textDecoration: 'none'
  },
  dateTime: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  icon: {
    padding: theme.spacing(1)
  },
  editIcon: {
    display: 'block',
    height: 32,
    width: 32
  }
});

export default makeStyles(styles);
