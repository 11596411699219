import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ActionButton } from 'core/components/action-button';
import * as yup from 'yup';
import { captureException } from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import disclaimerIcon from 'core/assets/svg/alert-dark-green.svg';
import closeIcon from 'core/assets/svg/x.svg';
import Label from 'core/components/label/label';
import useStyles from './assurity-life-modal.styles';
import TextField from 'core/components/text-field/text-field';
import Button from 'core/components/button/button';
import Checkbox from 'core/components/checkbox';
import { useToast } from 'core/components/toast';
import { gql, useMutation } from '@apollo/client';

const createAndSendCaseMutation = gql`
  mutation SendLinkMutation($email: AWSEmail!, $branchOfferId: String!, $branchPolicyId: String!) {
    assurityLifeCreateAndSendCase(
      args: { branchOfferId: $branchOfferId, branchPolicyId: $branchPolicyId, prospectEmail: $email }
    ) {
      __typename
      ... on AssurityLifeCreateAndSendCaseErrorResponse {
        message
      }

      ... on AssurityLifeCreateAndSendCaseSuccessResponse {
        message
      }
    }
  }
`;

const createCaseToOpenMutation = gql`
  mutation CreateCaseToOpenMutation($branchOfferId: String!, $branchPolicyId: String!) {
    assurityLifeCreateCaseToOpen(args: { branchOfferId: $branchOfferId, branchPolicyId: $branchPolicyId }) {
      __typename
      ... on AssurityLifeCreateCaseToOpenErrorResponse {
        message
      }

      ... on AssurityLifeCreateCaseToOpenSuccessResponse {
        agentLink
      }
    }
  }
`;

const AssurityLifeModal = ({ open, onClose, assurityData }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [acceptedCommunicationConsent, setAcceptedCommunicationConsent] = useState(false);

  const toast = useToast();

  const [createAndSendCase, createAndSendCaseResult] = useMutation(createAndSendCaseMutation);
  const [createCaseToOpen, createCaseToOpenResult] = useMutation(createCaseToOpenMutation);

  const sendLink = async () => {
    try {
      await yup.string().email().required().validate(email);
      setEmailError(false);
    } catch (e) {
      setEmailError(true);
      return;
    }

    try {
      const result = await createAndSendCase({
        variables: {
          email,
          branchOfferId: assurityData.branchOfferId || '',
          branchPolicyId: assurityData.branchPolicyId || ''
        }
      });
      switch (result.data?.assurityLifeCreateAndSendCase.__typename) {
        case 'AssurityLifeCreateAndSendCaseErrorResponse':
          toast.notify({ type: 'error', message: result.data.assurityLifeCreateAndSendCase.message });
          break;
        case 'AssurityLifeCreateAndSendCaseSuccessResponse':
          toast.notify({ type: 'success', message: result.data.assurityLifeCreateAndSendCase.message });
          break;
        default:
          break;
      }
      onClose();
    } catch (e) {
      captureException(e);
      toast.notify({ type: 'error', message: 'An error occurred while sending the link' });
    }
  };

  const openOffer = async () => {
    try {
      const result = await createCaseToOpen({
        variables: {
          branchOfferId: assurityData.branchOfferId || '',
          branchPolicyId: assurityData.branchPolicyId || ''
        }
      });
      switch (result.data?.assurityLifeCreateCaseToOpen.__typename) {
        case 'AssurityLifeCreateCaseToOpenErrorResponse':
          toast.notify({ type: 'error', message: result.data.assurityLifeCreateCaseToOpen.message });
          break;
        case 'AssurityLifeCreateCaseToOpenSuccessResponse':
          window.open(result.data.assurityLifeCreateCaseToOpen.agentLink, '_blank');
          break;
        default:
          break;
      }
      onClose();
    } catch (e) {
      captureException(e);
      toast.notify({ type: 'error', message: 'An error occurred while opening the offer' });
    }
  };

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} onClick={onClose} className={classes.closeButton} />
      <DialogTitle>
        <Label className={classes.title}>Instant Term Life Insurance</Label>
        <Label className={classes.subtitle}>From Assurity, quoted through Branch</Label>
      </DialogTitle>
      <DialogContent>
        <p className={classes.text}>
          Branch is partnering with Assurity to offer no-exam term life insurance, quoted within Branch's platform.
          Life-licensed agents can view the offer and send potential members an email to apply online in minutes,
          without a medical exam, and receive an instant decision. Policy auto renews after five years with a new
          premium rate.
          <br />
          <br />
          The benefit amount is editable (up to $500,000) after opening the offer link.
        </p>
        <div className={classes.separator} />
        <p className={classes.priceLine}>
          Default offer for <strong>${assurityData.benefitAmount.toLocaleString()}</strong> of coverage is{' '}
          <strong>${assurityData.monthlyPremiumAmount.toLocaleString()}/mo</strong> or{' '}
          <strong>${assurityData.annualPremiumAmount.toLocaleString()}/yr</strong>
        </p>
        <div className={classes.disclaimerContainer}>
          <img src={disclaimerIcon} className={classes.disclaimerIcon} alt="" />
          <p className={classes.disclaimerText}>
            This offer price is based on the currently provided applicant details, assuming no tobacco use. The final
            price is subject to change based on additional details.
          </p>
        </div>
        <div className={classes.subContentContainer}>
          <div className={classes.subContainerTitle}>Interested in term life insurance?</div>
          <div className={classes.subContainerRow}>
            <TextField
              type="email"
              name="email"
              mode="light"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              className={classes.emailField}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.subContainerButton}
              disabled={
                !acceptedCommunicationConsent || createAndSendCaseResult.loading || createCaseToOpenResult.loading
              }
              onClick={sendLink}
            >
              Send Link
            </Button>
            {assurityData.staffUserCapabilities.includes('CAN_VIEW') && (
              <>
                <p className={classes.orText}>OR</p>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.subContainerButton}
                  disabled={
                    !acceptedCommunicationConsent || createAndSendCaseResult.loading || createCaseToOpenResult.loading
                  }
                  onClick={openOffer}
                >
                  Open Offer
                </Button>
              </>
            )}
          </div>
          <div className={classes.subContainerRow}>
            <Checkbox
              mode="light"
              value={acceptedCommunicationConsent}
              onChange={(e) => setAcceptedCommunicationConsent(e.target.checked)}
            />
            <p className={classes.communicationConsentText}>
              The client agrees to receive emails and/or text messages regarding term life insurance. Message and data
              rates may apply. Message frequency varies.
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AssurityLifeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  assurityData: PropTypes.shape({
    branchOfferId: PropTypes.string,
    branchPolicyId: PropTypes.string,
    staffUserCapabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
    benefitAmount: PropTypes.number.isRequired,
    monthlyPremiumAmount: PropTypes.number.isRequired,
    annualPremiumAmount: PropTypes.number.isRequired
  })
};

export default AssurityLifeModal;
