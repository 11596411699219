import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import { phoneNumberToStringFormatter } from 'core/helpers/formatters';
import useCheckoutContext from '../../../hooks/use-checkout-context';
import whiteSmsIcon from '../assets/sms-icon-white.svg';
import beigeEmailIcon from '../assets/email-icon-beige.svg';
import { Method } from '../helpers';
import useStyles from './purchase-link-actions.styles';

const PurchaseLinkActions = ({ loading, onSendLink }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { isLicensedToPurchase } = useCheckoutContext();

  const missingContactInfo = !values.email || !values.phone || values.phone.length < 10;

  return (
    <>
      <div className={classes.header}>
        <Label type="darkGreenMedium">
          To complete the purchase, the new member must complete checkout through a purchase link.
        </Label>
        {isLicensedToPurchase && !missingContactInfo && (
          <Label type="body1">How do you want to share the purchase link with the potential member?</Label>
        )}
      </div>
      {!isLicensedToPurchase ? (
        <NotificationCard className={classes.notification} type="light" header="Sorry, you're not licensed to purchase">
          If you believe this is an error, please follow your desginated support flow to update your user configuration
          and permissions
        </NotificationCard>
      ) : (
        <>
          {missingContactInfo && (
            <NotificationCard className={classes.notification} type="light">
              Please fill out the contact information to be able to send the purchase link.
            </NotificationCard>
          )}
          {!missingContactInfo && (
            <div className={classes.actions}>
              <Button
                loading={loading}
                disabled={loading}
                variant="contained"
                color="secondary"
                className={classes.action}
                onClick={() => onSendLink(Method.Sms)}
              >
                <div className={classes.buttonContent}>
                  <img src={whiteSmsIcon} alt="Outlined white cellphone and speech balloon" />
                  Send link to {phoneNumberToStringFormatter(values.phone)}
                </div>
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                variant="contained"
                color="primary"
                className={classes.action}
                onClick={() => onSendLink(Method.Email)}
              >
                <div className={classes.buttonContent}>
                  <img src={beigeEmailIcon} alt="Outlined beige envelope" />
                  <span className={classes.email}>Send link to {values.email}</span>
                </div>
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

PurchaseLinkActions.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSendLink: PropTypes.func.isRequired
};

export default PurchaseLinkActions;
