import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  bundleTopCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    width: '100%',
    borderRadius: '6px',
    padding: theme.spacing(4),
    border: `2px solid ${theme.colorPalette.white_30}`,
    marginBottom: theme.spacing(2)
  },
  bundleBottomCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    width: '100%',
    borderRadius: '6px',
    padding: theme.spacing(4),
    backgroundColor: theme.colorPalette.white_30
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.colorPalette.green_90
  },
  priceSection: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 24,
    color: theme.colorPalette.green_90
  },
  carrierIcon: {
    height: 48,
    width: 48
  },
  typeIcon: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  time: {
    color: theme.colorPalette.green_20,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    marginLeft: theme.spacing(1)
  }
});

export default makeStyles(styles);
