import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  left: {
    textAlign: 'start',
    marginLeft: 12
  },
  radio: {
    paddingLeft: 16
  },
  radioGroup: {
    marginLeft: -12
  },
  whereDidYouLive: ({ isNewResidence }) => ({
    textAlign: 'start',
    marginLeft: 12,
    paddingTop: 8,
    borderTop: isNewResidence ? 0 : `1px solid ${theme.colorPalette.beige_10}`
  })
});

export default makeStyles(styles);
