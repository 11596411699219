import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  tooltipText: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 5
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  loadingContainer: {
    height: 280
  },
  bannerContent: {
    padding: '1px 4px'
  },
  moratoriumBanner: {
    padding: '16px'
  }
});

export default makeStyles(styles);
