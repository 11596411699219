import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';

import { Card } from 'core/components/card';
import NotificationIconWhite from './assets/alert-white.svg';
import useStyles from './collapsible-card-notification.styles';

const icon = {
  primary: NotificationIconWhite
};

const CollapsibleCardNotification = ({ children, type, open }) => {
  const classes = useStyles();

  return (
    <Card className={classnames(classes.containerSmall, classes[type])} type={open ? 'noBorders' : 'noBordersTop'}>
      <Grid className={classes.notificationContentContainerSmall}>
        <img src={icon[type]} className={classes.iconSmall} alt="Notification Icon" />
        <Grid className={classes.grid}>{children}</Grid>
      </Grid>
    </Card>
  );
};

CollapsibleCardNotification.defaultProps = {
  type: 'primary'
};

CollapsibleCardNotification.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary'])
};

export default CollapsibleCardNotification;
