import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { changeRejectionLangToNeedToTalkStates, errorClassificationLookup } from '@ourbranch/lookups';

import { openFrontChatPopup } from 'core/components/front-chat';
import { Label } from 'core/components/label';
import { phoneNumberToStringFormatter } from 'core/helpers/formatters';
import useError from 'common/hooks/use-error';
import useSession from 'core/hooks/use-session';
import { ErrorLabel } from './error-label';
import { getErrorData } from 'quote/components/quote-errors';
import { errorTypes, phoneNumber } from './constants';
import { OverrideErrorButton } from './override-error-button';
import { ConfirmOverrideModal } from './confirm-override-modal';
import useStyles from './error.styles';

const Error = ({ error, children, type, state, canOverrideWithoutSaving, selectedOption }) => {
  const classes = useStyles({ type });
  const { canClearUWFormRejections, isAgency } = useSession();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const errorData = useError(error, state, selectedOption);
  const showContactLink =
    !changeRejectionLangToNeedToTalkStates[state] && !canClearUWFormRejections && errorData.canBeOverridden && isAgency;

  // need to add the re-run offer to solve message
  if ((errorData.code === 50098 || errorData.code === 50097) && window.location?.pathname?.includes('offer')) {
    errorData.message += ' If this is incorrect, please re-run offer to solve.';
  }

  // this is a temporary fix until we replace this error component with the new one.
  if (errorClassificationLookup[errorData.code] === 'BLOCK') {
    const { title, subtitle } = getErrorData({
      error,
      state,
      isAgency
    });
    errorData.title = title;
    errorData.message = subtitle;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Label type={type === errorTypes.Tertiary ? 'body3' : 'body4'} className={classes.errorCode}>
          ERROR CODE: {errorData.code}
        </Label>
        {errorData.title && (
          <ErrorLabel errorType={type} type={type === errorTypes.Tertiary ? 'smallSubtitleLight' : 'infoSubtitle'}>
            {errorData.title}
          </ErrorLabel>
        )}
      </div>
      <div className={classes.description}>
        <ErrorLabel errorType={type}>{errorData.message}</ErrorLabel>
        {errorData.action && <ErrorLabel errorType={type}>{errorData.action}</ErrorLabel>}
        {changeRejectionLangToNeedToTalkStates[state] && (
          <ErrorLabel errorType={type}>
            This rejection is not permanent, but needs a a referral from underwriting before continuing.
          </ErrorLabel>
        )}
        {showContactLink && (
          <ErrorLabel errorType={type}>
            Please contact Agency support through&nbsp;
            <ErrorLabel asLink errorType={type} inlineDisplay onClick={openFrontChatPopup}>
              chat
            </ErrorLabel>
            &nbsp;or call&nbsp;
            <a className={classes.link} target="_blank" rel="noopener noreferrer" href={`tel:+1${phoneNumber}`}>
              <ErrorLabel errorType={type} asLink inlineDisplay>
                {phoneNumberToStringFormatter(phoneNumber)}
              </ErrorLabel>
            </a>
            &nbsp;for questions.
          </ErrorLabel>
        )}
        {errorData.canBeOverridden && canClearUWFormRejections && !changeRejectionLangToNeedToTalkStates[state] && (
          <>
            <OverrideErrorButton type={type} onClick={() => setConfirmModalOpen(true)} />
            {confirmModalOpen && (
              <ConfirmOverrideModal
                error={errorData}
                onClose={() => setConfirmModalOpen(false)}
                canOverrideWithoutSaving={canOverrideWithoutSaving}
              />
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.object.isRequired,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(errorTypes)),
  state: PropTypes.string,
  canOverrideWithoutSaving: PropTypes.bool,
  selectedOption: PropTypes.string
};

Error.defaultProps = {
  state: '',
  selectedOption: '',
  canOverrideWithoutSaving: false
};

export default Error;
