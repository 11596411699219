import React, { memo, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import { tooltipHoverTexts } from 'core/helpers/constants';
import Field from 'core/components/form/form.v2';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Tooltip } from 'core/components/tooltip';
import notificationIcon from './alert.svg';
import useStyles from './garage-location.styles';

const GarageLocation = observer(({ fieldName, index }) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();
  const { canOverrideGaragingAddress, isInternalSales, isExternalSales } = useContext(AuthContext);
  const prevGarageLocationRef = useRef();
  const { isGarageAddressRuleExceptionApproved, garageLocation } = values.cars[index];
  const showGarageAddressRuleExceptionCallOut =
    isGarageAddressRuleExceptionApproved && !canOverrideGaragingAddress && (isInternalSales || isExternalSales);

  useEffect(() => {
    // Avoid first render check
    if (prevGarageLocationRef.current !== undefined) {
      // If garage location is changed and has canOverrideGaragingAddress permission group, reset garage address rule exception approval
      if (!canOverrideGaragingAddress && isGarageAddressRuleExceptionApproved) {
        setFieldValue(`${fieldName}.isGarageAddressRuleExceptionApproved`, false);
      }
    }
    prevGarageLocationRef.current = garageLocation;
  }, [garageLocation, canOverrideGaragingAddress, isGarageAddressRuleExceptionApproved]);

  return (
    <>
      <div className={classes.separator} />
      <Grid container className={classes.garageAddressContainer}>
        <Grid item xs={6}>
          <Label type="formSubTitleLight">Garage address</Label>
        </Grid>
        {canOverrideGaragingAddress && (
          <Field
            id="isGarageAddressRuleExceptionApproved"
            name={`${fieldName}.isGarageAddressRuleExceptionApproved`}
            type="checkbox"
            mode="light"
            label="Approve garage address rule exception"
            tooltipText={tooltipHoverTexts.garageAddressRuleException}
            tooltipLabel="More Info"
            xs={6}
          />
        )}
      </Grid>
      <Field
        id={`${fieldName}.garageLocation.address`}
        name={`${fieldName}.garageLocation.address`}
        type="address"
        label="Garage Location"
        mode="light"
        xs={8}
        permissions={{ isLicensedAction: false }}
      />
      <Field
        id={`${fieldName}.garageLocation.address2`}
        name={`${fieldName}.garageLocation.address2`}
        type="string"
        label="Address 2"
        mode="light"
        xs={4}
        permissions={{ isLicensedAction: false }}
      />
      <Field
        id={`${fieldName}.garageLocation.city`}
        name={`${fieldName}.garageLocation.city`}
        type="string"
        label="City"
        mode="light"
        xs={4}
        permissions={{ isLicensedAction: false }}
      />
      <Field
        id={`${fieldName}.garageLocation.state`}
        name={`${fieldName}.garageLocation.state`}
        type="state"
        label="State"
        mode="light"
        xs={4}
        permissions={{ isLicensedAction: false }}
      />
      <Field
        id={`${fieldName}.garageLocation.zip`}
        name={`${fieldName}.garageLocation.zip`}
        type="string"
        label="Zip Code"
        mode="light"
        xs={4}
        permissions={{ isLicensedAction: false }}
      />
      {showGarageAddressRuleExceptionCallOut && (
        <Card className={classes.card}>
          <img src={notificationIcon} alt="Notification Icon" />
          <Label type="darkSmall" className={classes.label}>
            This garage address exception was approved by underwriting.
            <Tooltip
              labelNextToText
              label="More Info"
              text="You can change the garage address to a location in the policy state and valid ZIP code range. If another exception needs to be made, please follow your appropriate process for escalating to a Branch underwriting representative."
              placement="top"
            />
          </Label>
        </Card>
      )}
    </>
  );
});

GarageLocation.propTypes = {
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default memo(GarageLocation);
