import React, { useCallback } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Label } from 'core';
import { useStore } from 'core/store/store.mobx';
import styles from '../regenerate-document/regenerate-document.styles';

const RecreateApplication = observer(({ classes, toast }) => {
  const {
    account: {
      policies: {
        policy: {
          policy: { id: policyId },
          recreatingApplication,
          recreateApplication
        }
      }
    }
  } = useStore();

  const handleRecreateApplication = useCallback(async () => {
    const pdfUrl = await recreateApplication(policyId);

    if (!pdfUrl) {
      return toast.notify({
        type: 'error',
        message: `There was an error recreating the policy application`
      });
    }

    toast.notify({
      type: 'success',
      message: 'The regenerated Application has been added to the Internal Documents section on the Member Profile'
    });
  }, [recreateApplication, policyId]);

  return (
    <>
      <div className={classNames(classes.regenerateDocContainer, { [classes.withDocumentPolicy]: policyId })}>
        <Label type="greenSmall">Need to Recreate application?</Label>
        <div className={classNames(classes.actions, policyId && classes.withDocumentAction)}>
          <div
            className={classNames(classes.buttonContainer, {
              [classes.regeneratingDocs]: recreatingApplication
            })}
          >
            <Button
              disabled={!policyId || recreatingApplication}
              key="addButton"
              onClick={handleRecreateApplication}
              mode="big"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Recreate Application
            </Button>
          </div>
        </div>
      </div>
      {recreatingApplication && <LinearProgress color="secondary" />}
    </>
  );
});

RecreateApplication.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RecreateApplication);
