import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Header from '../header/header';
import SystemStatus from 'core/components/system-status';
import backgroundLogo from 'core/assets/svg/opaque-branch-logo.svg';
import useSession from 'core/hooks/use-session';

import useStyles from './layout.styles';

const Layout = observer(({ children }) => {
  const session = useSession();
  const classes = useStyles({ showHeader: session.isLoggedIn });

  return (
    <>
      {session.isLoggedIn ? <Header /> : <img src={backgroundLogo} alt="gobranch" className={classes.logo} />}
      <div className={classes.layout}>
        {session.isLoggedIn && <SystemStatus />}
        <div className={classes.container}>{children}</div>
      </div>
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired
};

export default Layout;
