import { localToUtcTime } from 'customer/components/policy/dates';
import { isBefore, addDays, isToday } from 'date-fns';

export const PolicyStatus = {
  Cancelled: 'Cancelled',
  InCancellation: 'In Cancellation',
  Inactive: 'Inactive',
  Future: 'Future',
  Active: 'Active'
};

export const getPolicyStatus = (policy) => {
  const { endDate, effectiveDate, state, fullTermPolicyEndDate, transactionDate } = policy;

  if (transactionDate) {
    // transactionDate is for ODEN cancels, where the endDate would not change until policy preview, so let's just put InCancellation
    return PolicyStatus.InCancellation;
  }

  const now = new Date();
  const endDateFromPolicy = localToUtcTime(`${endDate}T23:59:59.99`, state);

  const originalEndDate = localToUtcTime(String(fullTermPolicyEndDate || endDate).concat('T23:59:59.99'), state);
  const cancelledEndDate = isBefore(endDateFromPolicy, originalEndDate)
    ? localToUtcTime(`${endDate}T00:01:00.00`, state)
    : undefined;
  const startDateFromPolicy = localToUtcTime(effectiveDate, state);

  if (cancelledEndDate) {
    if (
      // add a day here to allow rescinding cancellation on canceled date
      isBefore(addDays(cancelledEndDate, 1), now) ||
      // flat cancel logic
      (endDate === effectiveDate && isToday(localToUtcTime(endDateFromPolicy)))
    ) {
      return PolicyStatus.Cancelled;
    }
    return PolicyStatus.InCancellation;
  }

  if (endDateFromPolicy < now) {
    return PolicyStatus.Inactive;
  }

  if (startDateFromPolicy > now) {
    return PolicyStatus.Future;
  }

  return PolicyStatus.Active;
};

export const getPolicyChange = (current, newPolicy) => {
  const isNewPolicyActiveOrFuture = [PolicyStatus.Active, PolicyStatus.Future].includes(newPolicy);
  if (current === PolicyStatus.Cancelled && isNewPolicyActiveOrFuture) {
    return 'reinstate';
  }
  if (current === PolicyStatus.InCancellation && isNewPolicyActiveOrFuture) {
    return 'rescind';
  }
  if (newPolicy === PolicyStatus.Cancelled || newPolicy === PolicyStatus.InCancellation) {
    return 'cancel';
  }
};
