import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import copy from 'copy-to-clipboard';

import { useToast } from 'core/components/toast';
import copyIcon from 'core/assets/svg/copy.svg';
import { Tooltip } from 'core/components/tooltip';
import { Label } from '../label';
import useStyles from './value-field.styles';

function ValueField({ label, value, width, style, mode, formatter, className, showTooltip, ...props }) {
  const classes = useStyles();
  const toast = useToast();
  return (
    <div className={classNames(classes.container, className)} data-cy="value-field" style={style} {...props}>
      <Label>
        {label && (
          <Label type="infoLabel" className={classes[mode ? `${mode}-label` : 'label']}>
            {label}
          </Label>
        )}
        {showTooltip && (
          <Tooltip className={classes.valueFieldTooltip} text={showTooltip.onHoverText} label="More Info" />
        )}
      </Label>
      <Label id="value" type="infoValue" className={classes[mode ? `${mode}-value` : 'value']}>
        <Grid container direction="row" alignItems="center">
          {formatter ? formatter(value) : value}
          {props.copyable && (
            <IconButton
              className={classes.copyButton}
              onClick={() => {
                const copied = copy(value);
                if (copied) {
                  toast.notify({
                    type: 'success',
                    message: `${value} copied to clipboard`
                  });
                }
              }}
            >
              <img src={copyIcon} alt="copy" />
            </IconButton>
          )}
        </Grid>
      </Label>
    </div>
  );
}

ValueField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  mode: PropTypes.oneOf([null, 'light', 'dark', 'big-font-dark']),
  formatter: PropTypes.func,
  className: PropTypes.string,
  showTooltip: PropTypes.string,
  copyable: PropTypes.bool
};

ValueField.defaultProps = {
  label: '',
  value: null,
  width: undefined,
  style: {},
  mode: null,
  formatter: null,
  className: '',
  showTooltip: '',
  copyable: false
};

export default ValueField;
