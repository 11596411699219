import React from 'react';
import PropTypes from 'prop-types';
import { useObserver } from 'mobx-react';
import { useFormikContext } from 'formik';
import { Grid, Button } from '@material-ui/core';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { useStore } from 'core/store/store.mobx';
import useStyles from './dialog.styles';

const RestoreCheckoutDataDialog = ({ open, onClose, hasCreditCard }) => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const { setFieldValue, initialValues } = useFormikContext();

  const onRestore = () => {
    if (store?.checkoutFormData?.creditCardInfo && hasCreditCard) {
      setFieldValue('recoveredPaymentData.creditCard', store?.checkoutFormData?.creditCardInfo);
      setFieldValue('basisTheoryCardToken', store?.checkoutFormData?.creditCardInfo.token);
      setFieldValue('completeCardData', !!store?.checkoutFormData?.creditCardInfo.token);
    }

    if (store?.checkoutFormData?.email) {
      setFieldValue('email', store?.checkoutFormData?.email);
    }

    if (store?.checkoutFormData?.phone) {
      setFieldValue('phone', store?.checkoutFormData?.phone);
    }
    onClose();
  };

  const onClear = () => {
    setFieldValue('recoveredPaymentData', null);
    setFieldValue('email', initialValues.email);
    setFieldValue('phone', initialValues.phone);
    store.saveCheckoutFormData(null);
  };

  const clearAndClose = () => {
    onClear();
    onClose();
  };

  return useObserver(() => (
    <BaseDialog open={open} onClose={clearAndClose} size="lg">
      <Label type="titleSecondary" className={classes.title}>
        Restore Checkout Data?
      </Label>
      <Label type="infoInnerTitle" className={classes.content}>
        Would you like to restore the data you previously entered in this checkout page? Here is what we saved:
      </Label>
      <Grid container className={classes.content}>
        {store?.checkoutFormData?.creditCardInfo && hasCreditCard && (
          <>
            <Label type="infoInnerTitleBold">Credit Card Data</Label>
            <Grid container className={classes.innerContent}>
              <Grid item xs={4}>
                <Label type="infoLabel"> Last 4</Label>
                <Label type="infoValue">{store.checkoutFormData.creditCardInfo.card.last4}</Label>
              </Grid>
              <Grid item xs={4}>
                <Label type="infoLabel">Expr Date</Label>
                <Label type="infoValue">
                  {store.checkoutFormData.creditCardInfo.card.expirationMonth}/
                  {store.checkoutFormData.creditCardInfo.card.expirationYear}
                </Label>
              </Grid>
            </Grid>
          </>
        )}

        {(store?.checkoutFormData?.email || store?.checkoutFormData?.phone) && (
          <>
            <Label type="infoInnerTitleBold">Member Information</Label>
            <Grid container>
              {store?.checkoutFormData?.email && (
                <Grid item xs={6}>
                  <Label type="infoLabel">Email</Label>
                  <Label type="infoValue">{store.checkoutFormData.email}</Label>
                </Grid>
              )}

              {store?.checkoutFormData?.phone && (
                <Grid item xs={4}>
                  <Label type="infoLabel">Phone</Label>
                  <Label type="infoValue">{store.checkoutFormData.phone}</Label>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Grid container alignItems="center" justifyContent="flex-end" className={classes.bottom}>
        <Grid item>
          <Button onClick={clearAndClose} variant="contained" color="primary">
            <Label>No, let me fill it in again</Label>
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onRestore} variant="contained" color="secondary">
            <Label>Yes, restore all this data</Label>
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  ));
};

RestoreCheckoutDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RestoreCheckoutDataDialog;
