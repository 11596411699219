import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    minWidth: 800,
    minHeight: 428,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: theme.spacing(5)
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    color: theme.colorPalette.green_75,
    fontSize: 40,
    fontWeight: 300,
    padding: 0,
    marginBottom: theme.spacing(6)
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0
  },
  comparisonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  centerIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  termInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 328,
    padding: theme.spacing(4, 6),
    border: `2px solid ${theme.colorPalette.beige_10}`,
    borderRadius: 6,
    marginBottom: theme.spacing(6)
  },
  termLabel: {
    color: theme.colorPalette.green_75,
    fontWeight: 600,
    fontSize: 14
  },
  priceLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.colorPalette.green_75,
    marginRight: theme.spacing(1),
    height: 14,
    marginTop: theme.spacing(2)
  },
  price: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.colorPalette.green_15
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
    fontWeight: 600,
    width: 180,
    fontSize: 12
  },
  green: {
    backgroundColor: theme.colorPalette.green_75_10,
    color: theme.colorPalette.green_75
  },
  grey: {
    width: 120,
    backgroundColor: theme.colorPalette.beige_10,
    color: theme.colorPalette.beige_90
  },
  red: {
    backgroundColor: theme.colorPalette.red_100_10,
    color: theme.colorPalette.red_20
  },
  upArrowIcon: {
    width: 8,
    height: 10
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 14,
    height: 14,
    padding: 0,
    margin: theme.spacing(0, 2, 0, 0),
    borderRadius: '50%',
    backgroundColor: theme.colorPalette.green_75
  },
  decrease: {
    backgroundColor: theme.colorPalette.green_75,
    transform: 'rotate(180deg)'
  },
  increase: {
    backgroundColor: theme.colorPalette.red_20
  },
  iconFold: {
    display: 'block',
    height: 32,
    width: 32,
    transition: 'transform 200ms'
  },
  icon: {
    composes: '$iconFold',
    transform: 'rotate(180deg)'
  },
  collapse: {
    marginBottom: theme.spacing(6)
  },
  collapseContainer: {
    backgroundColor: theme.colorPalette.white_10,
    borderRadius: theme.spacing(2)
  },
  cardHeader: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colorPalette.white_10_op_10,
    padding: theme.spacing(4, 6)
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: 20,
    color: theme.colorPalette.green_20
  },
  cardCount: {
    fontWeight: 400,
    fontSize: 10,
    color: theme.colorPalette.green_15,
    marginLeft: theme.spacing(2)
  },
  lineItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(6),
    borderTop: `1px solid ${theme.colorPalette.beige_10}`
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 0, 4, 6)
  },
  impactIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 127,
    fontSize: 12,
    fontWeight: 600,
    height: 32,
    borderRadius: theme.spacing(4),
    marginLeft: theme.spacing(4)
  },
  increasesColor: {
    backgroundColor: theme.colorPalette.red_100_10,
    color: theme.colorPalette.red_20
  },
  savingsColor: {
    backgroundColor: theme.colorPalette.green_75_10,
    color: theme.colorPalette.green_75
  },
  opportunitiesColor: {
    backgroundColor: theme.colorPalette.white_30,
    color: theme.colorPalette.beige_90
  },
  impactIconImage: {
    marginRight: theme.spacing(2)
  },
  lineItemTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.colorPalette.green_75
  },
  lineItemDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.colorPalette.green_15
  },
  learnMoreLink: {
    width: 108,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.colorPalette.orange_10,
    padding: theme.spacing(2)
  },
  bundlePolicyLink: {
    width: 228,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.colorPalette.orange_10,
    padding: theme.spacing(2)
  },
  learnMoreLinkText: {
    fontSize: 12,
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    color: `${theme.colorPalette.orange_10} !important`
  },
  noResults: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 8, 8, 8)
  },
  noResultsIcon: {
    height: 32,
    width: 32
  },
  noResultsBanner: {
    fontSize: 16,
    color: theme.colorPalette.green_15,
    lineHeight: '24px',
    marginBottom: theme.spacing(1)
  },
  noResultsText: {
    fontSize: 14,
    color: theme.colorPalette.green_15,
    lineHeight: '20px'
  }
});

export default makeStyles(styles);
