import React from 'react';
import { Grid } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';

import { Label } from 'core/components/label';
import { displayData, getUserFriendlyStatus, cleanArgs } from 'tools/helpers';
import useStyles from './task-history-row.styles';

const TaskHistoryRow = ({ task, title }) => {
  const classes = useStyles(task);
  const { path } = useRouteMatch();
  const argsWithValues = cleanArgs(task);

  const hideArgsFromDisplay = title.toLowerCase().includes('bulk'); // hide args from display for bulk tasks bc args are an S3 file path

  return (
    <Link to={{ pathname: `${path}/run/${task.taskYYMM}/${task.taskRunId}` }} className={classes.link}>
      <Grid container spacing={2}>
        <Grid container justifyContent="space-between" item xs={12}>
          <Label type="body2Bold" className={classes.bold}>
            {title}
          </Label>
          <Grid className={classes.secondaryInfo}>
            Started at {new Date(task.startedAt).toLocaleString()}
            {task.startedBy && (
              <>
                {' '}
                <span>
                  by <span className={classes.bold}>{task.startedBy}</span>
                </span>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          className={classes.secondaryInfo}
        >
          <Grid>
            <span className={classes.status} status={task.status}>
              {getUserFriendlyStatus(task.status)}{' '}
            </span>
            at {new Date(task.endedAt).toLocaleString()}
          </Grid>
        </Grid>
        {argsWithValues && !hideArgsFromDisplay && (
          <Grid container xs={12} spacing={2}>
            {Object.keys(argsWithValues).map((key, index) => (
              <Grid item key={index} alignItems="center" className={classes.argument}>
                <Label type="body2" className={classes.argumentKey}>
                  {key}:
                </Label>
                <Label type="body2" className={classes.argumentValue}>
                  {displayData(argsWithValues[key])}
                </Label>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Link>
  );
};

export default TaskHistoryRow;
