import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';
import useStyles from './no-reinstatement-modal.styles';

const NoReinstatementModal = ({
  open,
  setShowNoReinstateForCNPYModal,
  showNoReinstateForCNPYModal,
  setShowNoReinstateForPriorCancelledModal,
  showNoReinstateForPriorCancelledModal,
  priorCancelledTerm
}) => {
  const classes = useStyles();

  const onClose = () => {
    if (showNoReinstateForCNPYModal) {
      setShowNoReinstateForCNPYModal(false);
    }
    if (showNoReinstateForPriorCancelledModal) {
      setShowNoReinstateForPriorCancelledModal(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label className={classes.title}>Policy Ineligible for Reinstatement</Label>
      </DialogTitle>
      <DialogContent>
        {showNoReinstateForCNPYModal && (
          <p className={classes.text}>
            This policy was canceled due to non-payment and is therefore ineligible to be reinstated. If you have
            questions about this, please reach out to your manager.
          </p>
        )}
        {showNoReinstateForPriorCancelledModal && (
          <p className={classes.text}>
            {`You cannot remove this cancellation as the prior term is cancelled. Please remove cancellation on prior term ${priorCancelledTerm}
            before making this change to avoid a lapse in coverage. If you have questions about this, please follow your designated escalation process.`}
          </p>
        )}
      </DialogContent>
      <Grid container className={classes.buttonRow}>
        {showNoReinstateForPriorCancelledModal && priorCancelledTerm && (
          <Button
            target="_blank"
            href={`/customer/${priorCancelledTerm?.split('-')[0]}/policy/${priorCancelledTerm}`}
            variant="contained"
            color="secondary"
            className={classes.priorPolicyButton}
          >
            <Label className={classes.buttonLabel}>Go to Prior Policy</Label>
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onClose} className={classes.closeButtonWithText}>
          <Label className={classes.buttonLabel}>Go back</Label>
        </Button>
      </Grid>
    </Dialog>
  );
};
export default NoReinstatementModal;
