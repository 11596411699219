import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errorsLabel: {
    marginTop: theme.spacing(10),
    display: 'block',
    marginBottom: theme.spacing(2)
  },
  errorCard: {
    minHeight: 215,
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(4)
  },
  button: {
    fontWeight: 600,
    fontSize: 16,
    backgroundColor: theme.colorPalette.orange_10,
    width: '100%'
  }
}));

export default useStyles;
