import React from 'react';
import { useStore } from 'core/store';
import { observer } from 'mobx-react';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { useToast } from 'core/components/toast';
import useStyles from './confirm-remove-payment-method-modal.styles';

const ConfirmRemoveModal = ({ open, paymentMethod, onClose, paymentMethodDescription }) => {
  const classes = useStyles();
  const {
    account: {
      id: accountId,
      policies: {
        policy: {
          removePaymentMethod,
          policy: { id }
        }
      }
    }
  } = useStore();
  const toast = useToast();

  const handleRemovePaymentMethod = async (paymentMethod) => {
    try {
      await removePaymentMethod(paymentMethod.id, accountId, id);
      toast.notify({
        type: 'success',
        message: `Payment method "${paymentMethod.brand} ${paymentMethod.last4} Exp: ${paymentMethod.expMonth}/${paymentMethod.expYear}" successfully removed.`
      });
      onClose();
    } catch (error) {
      toast.notify({
        type: 'error',
        message: error.message
      });
    }
  };

  return (
    <BaseDialog open={open} onClose={onClose}>
      <Label type="greenBig">Are you sure you want to delete this payment method?</Label>
      <Label type="modalBody" className={classes.subLabel}>
        {paymentMethodDescription} will be removed from payment methods on this policy.
      </Label>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={() => handleRemovePaymentMethod(paymentMethod)}>
          Delete Payment Method
        </Button>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </BaseDialog>
  );
};

export default observer(ConfirmRemoveModal);
