import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Loading } from 'core';
import { withToast } from 'core/components/toast';
import { Card } from 'core/components/card';
import { useStore } from 'core/store/store.mobx';
import MicroDepositsForm from './micro-deposits-form';
import useStyles from './microdeposits.styles';

const MicroDeposits = observer(({ toast, accountId }) => {
  const classes = useStyles();
  const {
    account: accountStore,
    account: {
      policies: {
        achPoliciesWithUniqueBankId,
        getPoliciesNeedingStripeBankVerification,
        generateAndSaveStripeBankInfoToPolicies
      }
    }
  } = useStore();
  const [loading, setLoading] = useState(false);

  // get list of policy ids we need info for
  const policiesNeedingVerifying = getPoliciesNeedingStripeBankVerification();

  useEffect(() => {
    async function fetchStripeInfo() {
      if (
        accountStore.id === accountId && // has the store completely refreshed?
        achPoliciesWithUniqueBankId?.length &&
        policiesNeedingVerifying?.length &&
        !loading
      ) {
        setLoading(true);
        try {
          // get stripebankinfo and verification status for all policies in policiesNeedingVerifying
          await generateAndSaveStripeBankInfoToPolicies(accountId, policiesNeedingVerifying);
        } catch (error) {
          toast.notify({
            type: 'error',
            message: error.message
          });
        }
        setLoading(false);
      }
    }

    if (policiesNeedingVerifying?.length && !loading) {
      fetchStripeInfo();
    }
  }, []);

  if (!policiesNeedingVerifying?.length) {
    return null;
  }

  if (loading && policiesNeedingVerifying?.length) {
    return (
      <Card className={classes.loadingCard}>
        <Loading noBackground />
      </Card>
    );
  }

  if (achPoliciesWithUniqueBankId?.length && policiesNeedingVerifying?.length) {
    // map over unique policies so long as it is in policiesNeedingVerifying
    return achPoliciesWithUniqueBankId?.map((policy) => {
      if (policiesNeedingVerifying.includes(policy.id)) {
        return (
          <Grid item key={`${policy.id}-microdeposits-grid`} className={classes.bottomSpacing}>
            <MicroDepositsForm key={`${policy.id}-microdeposits`} policyId={policy.id} toast={toast} />
          </Grid>
        );
      }
      return null;
    });
  }

  return null;
});

MicroDeposits.propTypes = {
  toast: PropTypes.object.isRequired,
  accountId: PropTypes.string
};

export default withToast(MicroDeposits);
