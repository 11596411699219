import React from 'react';
import PropTypes from 'prop-types';

import { LabelTooltip } from '../label-tooltip';
import { FormField } from '../form';

const FieldWithTooltip = ({ label, tooltip, labelNextToTooltip, mode, type, ...props }) => (
  <LabelTooltip
    label={label}
    mode={mode}
    labelNextToText={labelNextToTooltip}
    tooltip={{ label: 'More info', onHoverText: tooltip }}
  >
    <FormField {...props} mode={mode} type={type} />
  </LabelTooltip>
);

FieldWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['light', 'dark']),
  type: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  labelNextToTooltip: PropTypes.bool
};

FieldWithTooltip.defaultProps = {
  labelNextToTooltip: false,
  mode: 'dark'
};

export default FieldWithTooltip;
