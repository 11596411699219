import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { useStore } from 'core/store';
import { BranchPlusPolicyDetails, ServiceInstructions, Header } from 'common/components/branch-plus-policy';
import LayoutWithSidebar from 'core/components/layout-with-sidebar';

const BranchPlus = () => {
  const urlParams = useParams();
  const {
    branchPlus: { loading, branchPlusPolicy, error, getBranchPlusPolicy }
  } = useStore();

  if (!urlParams.policyId) {
    throw new Error('Invalid URL');
  }

  useEffect(() => {
    if (urlParams.policyId !== branchPlusPolicy?.policyId) {
      getBranchPlusPolicy(urlParams.policyId);
    }
  }, []);

  const isLoading = loading || !branchPlusPolicy;

  return (
    <LayoutWithSidebar
      header={Header}
      content={BranchPlusPolicyDetails}
      side={ServiceInstructions}
      policy={branchPlusPolicy}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default observer(BranchPlus);
