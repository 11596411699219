/* eslint-disable react/react-in-jsx-scope */
import { limitedCollisionStates } from '@ourbranch/lookups';
import { addDays, parseISO, format } from 'date-fns';
import { awsDateToDateFormatter } from 'core/helpers/formatters';

export const ABODE_PRO_PLAN_AFFINITY = 'AB1';
export const ABODE_PRO_PLAN_PROVIDER_NAME = 'Abode Pro Plan';

export const NEW_CUSTOMER_HOME_SECURITY = {
  SIGN_UP_TYPE: 'B',
  NO_SIGN_UP_TYPE: 'C'
};

export const CONNECTED_HOME_MONITORED_TYPES = {
  ADVANCED: {
    MONITORED_BY_PARTNER: 'VP',
    MONITORED_BY_NON_PARTNER: 'P',
    NOT_MONITORED: 'N'
  },
  REGULAR: {
    CENTRAL: 'C',
    NOT_MONITORED: 'N',
    SELF: 'S'
  }
};

export const HEALTH_INSURANCE_TYPE = {
  PERSONAL: 'Personal',
  MEDICARE: 'Medicare',
  MEDICAID: 'Medicaid'
};

export const tooltipHoverTexts = {
  dwellingLimit:
    'This is the maximum amount Branch  will pay to rebuild the home. This value is calculated using current construction, materials and labor costs. If you want added peace of mind, consider increasing the increase building structure coverage.',
  additionalLivingExpense:
    'If your home becomes uninhabitable due to some kind of covered loss, this coverage provides temporary housing up to the amount you select here.',
  personalPropertyLimit:
    'If something happens to your personal property, like theft or damage due to fire, this covers you.',
  otherStructuresLimit:
    'A lot of homes have property on the premises that is affixed to the ground, but separated from the dwelling. For example: sheds, in ground pools, and detached garages. This provides coverage for those types of things up to the limit you pick.',
  waterBackup: 'Coverage in the event that water backs up from your drainage system and causes damage in your home.',
  coverageDC: 'Day care coverage',
  coverageDR:
    'Coverage for expenses associated with data recovery, in the event of physical loss to electronic data processing equipment or accessories. This can include events that harm or introduce unauthorized instructions or code through a computer, including, but not limited to a computer virus ($5k limit per occurance).',
  coverageFairRental:
    'Coverage for rental income resulting from a loss that makes the part of the residence premises you rent to others, or hold for rental, uninhabitable.',
  coverageExtendedPremises:
    'An extension of limited portions of coverage under the homeowners policy for broader premises (Ex: a multi-family dwelling or land).',
  coverageBuildingMaterialsTheft:
    'Coverage in the event of physical loss caused by theft of construction materials and supplies used in connection with your dwelling. ($5,000 limit)',
  coverageSinkhole: 'Coverage that covers damage caused by sinkholes or earth movement',
  coverageOilStorageTank:
    'Optional liability coverage that covers mitigation and clean up of damages caused by oil tank leaks',
  coverageYardAndGarden:
    'This coverage provides 5% of Coverage A for trees, plants, shrubs AND $50,000 of coverage for motorized land vehicles used solely to service the residence premises.',
  homeownerProtection:
    'Coverage for your personal property in the event that you rent your home in a home share (ex: Airbnb).',
  guestMedicalLimit:
    "If someone visiting your home sustains an injury on your property, we'll cover their medical expenses up to the limit you select here, whether you're at fault for the accident or not.",
  deductibleAllOther:
    'Deductibles are the amount of money that gets deducted from a claim payment that we make to you.',
  personalLiabilityCoverage:
    "This is coverage for when you're at fault for the property damage or bodily injury to another person.",
  PPALExpenseCoverage:
    'If something happens to your personal property, like theft or damage due to fire, this covers you. If your home becomes uninhabitable due to some kind of covered loss, this coverage provides temporary housing up to the amount you select here.',
  deductibleCollision: (state, showLimitedCollisionCopy = false) =>
    `This is the amount the member will pay out of pocket in the event their car is damaged in a collision. Their policy will pay the rest, up to the limits outlined in their policy.${
      showLimitedCollisionCopy && limitedCollisionStates[state]
        ? ' If the member selects a “Limited” option, Branch will only cover them if another driver is found to be at fault for the collision, and that driver can be identified.'
        : ''
    }`,
  deductibleComprehensive:
    "This is the amount you'll pay out of pocket in the event your car is damaged through an event not covered under collision. For example: with an animal, fire, hail, or vandalism.",
  limitRental:
    'If you experience a covered collision or comprehensive loss, this covers your transportation (for example, rental cars or rideshare services) while your car is being fixed.',
  roadsideAssistance:
    'Select Roadside Assistance to get towing and labor assistance in the event of an emergency. | Collision deductibles are required to enable this coverage.',
  limitUMPD: 'This provides coverage for damage to your car caused by an uninsured driver.',
  coverageLoan:
    "If the insured's car is totaled or stolen, this will cover the difference between the car's current value and what is owed on the loan. The limit for this coverage is 25% of the actual cash value of the vehicle. Please see the policy for details. | Comprehensive and Collision deductibles are required to enable this coverage.",
  rideSharing:
    "Most commercial auto policies from Uber, Lyft, etc, will provide coverage if you drive for them. Typically, the coverage protects you during times when you are actually driving customers. In many cases though, you aren't covered during times when you are not physically driving customers; for example, you're on the way to pick one up or waiting for one to get into the car. Select this option to extend your coverage to include those times. | Collision deductibles are required to enable this coverage.",
  policyLimitBIPD:
    "Bodily injury coverage has two limits: per person and per accident. The per person limit is the max we'll pay for each person injured in an accident. The per accident limit is the max we'll pay for each accident, regardless of the number of injuries. The Property Damage limit typically helps cover the cost of repairs if the insured is at fault for a car accident that damages another vehicle or property such as a fence or building front.",
  policyLimitMedicalPayments:
    'This covers you in the event someone is hurt while riding in your car up to the limit you select.',
  umUimBodilyInjury:
    "This is the max we'll pay for medical expenses per person injured / per accident in the event you are in an accident with someone who doesn't have insurance (or enough insurance).",
  umUimPropertyDamage:
    "This is the max we'll pay for property damage expenses in the event you are in an accident with someone who doesn't have insurance (or enough insurance).",
  umEuimBodilyInjury:
    "Uninsured Motorist coverage is required. The limits you select can be less than or equal to your liability limits and must be the same on each vehicle. If you select Uninsured Motorist coverage we will only pay you up to the difference between the at-fault driver's Liability coverage and your Uninsured Motorist coverage limit. If you select Enhanced Underinsured Motorist coverage we will pay you for your damages in addition to the at-fault driver's liability coverage limit up to your Enhanced Underinsured Motorist coverage limit.",
  umpdEumpd:
    'Uninsured Motorist Property Damage coverage is required. The limits you select can be less than or equal to your liability limits and must be the same on each vehicle. If you select Uninsured Motorist Property Damage coverage we will only pay you up to the difference between the at-fault driver’s Liability coverage and your Uninsured Motorist Property Damage coverage limit. If you select Enhanced Underinsured Motorist Property Damage coverage we will pay you for your damages in addition to the at-fault driver’s liability coverage limit up to your Enhanced Underinsured Motorist Property Damage coverage limit.',
  coverageRSExtended:
    'The Roof Extended (Repair/Replace) helps cover the repair or replacement of the roof with NO application of a payment schedule. When the Roof Extended (Repair/Replace) box is not selected, members will receive the Roof Surfaces Endorsement (Roof Payment Schedule), where Branch will pay for a portion of approved roof claims according to a roof payment schedule.',
  personalInjuryProtection:
    'This covers medical expenses and lost wages for you and passengers no matter who caused the accident',
  policyLimitPIPME:
    'Coverage for necessary medical care up to the limit you choose. Remember that you’ll need to confirm with your health insurance provider that you have qualifying health coverage if you decide to exclude PIP Medical Expense coverage.',
  policyLimitPIPACR:
    'If you carry Limited Medical Expense, you can add some additional coverage with the Attendant Care Rider.',
  waivedPIPWL: 'If you’re at least 60 years old, you can choose to waive Work Loss benefits.',
  pipResidentsWithQHC:
    'Qualified health coverage (QHC) means that your health insurance provider will cover medical expenses resulting from a motor vehicle accident. These are residents who have Medicare with parts A & B, Medicaid, or personal health insurance covering with an annual individual deductible of $6,000 or less.',
  pipExcludedResidents:
    "Residents may be excluded from personal injury protection medical (PIP ME) coverage if they have qualified health coverage (QHC), i.e. Medicare, PIP coverage elsewhere, or if this policy's PIP coverage limit is $250K.",
  pipAdditionalResidents:
    'This is the number of residents who are on a different MI auto insurance policy where they already have PIP coverage. These residents are not eligible for PIP coverage on this policy and therefore should not be considered when selecting coverages, counting residents with QHC, or counting residents to exclude from PIP coverage',
  pipFloridaLanguage: () => {
    return (
      <>
        Personal Injury Protection covers the following, up to a total of $10,000: <br />
        - 80% of covered medical expenses incurred.
        <br />- Reasonable expenses for loss of services incurred. <br />- Up to $5,000 in death benefits. <br />- 60%
        of covered work loss expenses in alignment with the member's selected plan.
      </>
    );
  },
  pipFloridaWL: 'This coverage pays up to 60% of lost wages due to an injury from an accident',
  lossAssessments:
    'Coverage for common property in a shared community or homeowners association that is damaged by a covered loss and assessed to the insured.',
  buildingCodeCoverage:
    "Helps cover the additional cost of repairing an insured's home up to code if it's damaged by a covered loss.",
  increasedBuildingStructure:
    "Also known as extended replacement cost, this provides excess coverage to repair or rebuild an insured's home when the original limits are not enough. We recommend at least 10%. This way, if it costs more to rebuild your home, you’ll have added peace of mind.",
  noMonolineAuto:
    'This customer is not eligible to purchase a monoline auto policy due to incidents or violations on one or more drivers.',
  noMonolineAutoWBundle:
    'This customer is not eligible to purchase a monoline auto policy due to incidents or violations on one or more drivers. If this customer already has a home policy with Branch, please link this offer to an existing member.',
  windHail:
    'The wind/hail deductible is an optional deductible that may help save money on premium. In order to select a wind/hail deductible, select an option that is HIGHER than the all other peril deductible. If you do not want a wind/hail deductible, select the wind/hail deductible option that is equal to the all other peril deductible selection.',
  policyLimitGuestPIP:
    'If PIP is rejected, Guest PIP coverage of $10k becomes a mandatory coverage. Guest PIP is redundant on policies with normal PIP coverage.',
  policyLimitNoFaultPIP: 'To reject PIP coverage, please reach out to your manager or Agency Success Manager.',
  discountCourseKY:
    'Course must be approved by the Transportation Cabinet of Kentucky within the last 5 years. You will be asked for proof of course completion upon purchase.',
  coverageEBSL:
    'EB protects home appliances, HVAC systems, and furnaces from electrical or mechanical breakdown ($50k per occurrence/$500 deductible). SL covers utility lines and pipes connected to the main grid supplying power, waste removal, and telecom services ($10k per occurrence/$500 deductible). SL coverage drops to $2500 if the home/service lines are over 50 years old.',
  coverageEB: 'Coverage for home appliances, furnaces, and HVAC systems in case of electrical or mechanical breakdown.',
  incomeLoss: `Income Loss coverage pays some of the gross income of an insured person up to the selected limit while the individual is prevented from working as a result of an auto accident. Your policy may also pay for reasonable expenses actually incurred hiring: (a) Special help that enables the insured to work and (b) a substitute to perform the duties the self-employed insured person would have performed.`,
  familyLiability:
    'Coverage for an insured when they are at-fault for the property damage or bodily injury to another person.',
  additionalCustomPartsLimit:
    'Coverage for an insured to repair or replace items that were added or customized on a vehicle, like a stereo, navigation system, or custom wheels.',
  incomeLossCoverage:
    'Coverage for an insured for up to 80% of lost income resulting from a covered auto accident injury.',
  funeralBenefitsCoverage:
    'Coverage for an insured for reasonable and necessary expenses related to a funeral, burial, or cremation, that resulted from a death within 24 hours of a covered auto accident.',
  extraordinaryMedicalBenefitsCoverage:
    'Coverage for an insured for up to $1 million dollars of reasonable and necessary medical treatment or rehabilitative services that exceed $100,000 in total cost.',
  combinedFirstPartyBenefits:
    'Includes Medical Expenses, Lost Income benefits, Accidental Death benefits, and Funeral Expenses up to the individual coverage maximums for up to three years from the date of a covered accident.',
  limitedPropertyDamage:
    'Liability coverage for an insured, up to the selected limit, for property damage resulting in a Mini-Tort claim that they are responsible for. Mini-Tort claims in Michigan allow for a party to seek recovery for the first $3,000 in property damage incurred during an auto accident that is not covered by other insurance.',
  swapButtonDisabledForLicenseOrPermissions:
    'You do not have permission to perform this action. Please follow your appropriate process to swap main and co-applicant.',
  swapButtonDisabledNeedsSaved: 'Please save your existing changes before performing this action.',
  inexperiencedCourseMA:
    'Any driver with less than 7 years of experience qualifies for a Driver Training discount with proof of satisfactory completion of any Massachusetts Behind-the-Wheel Driver Training Program. Proof of an equivalent course from another state may also qualify. We will ask you to provide the proof of completion after purchasing with us.',
  loanOfficerEmail:
    'To ensure a smooth home closing, we will send proof of coverage and an invoice for on-time payment to the Loan officer email provided.',
  buildingPropertyProtection:
    'This is what we estimate it will cost to reconstruct the walls-in interior of your condo unit.',
  equityDays:
    'Equity days is the number of future days the policy is paid through. Negative equity days means the policy has been active for longer than the amount of money paid on the policy should allow. Branch keeps an equity day reserve for installment policies in case of non-pay cancellations or premium-increasing endorsements.',
  priceDetailsSurplus:
    'Policy surplus reduces the cost of capital as part of the reciprocal exchange. Total surplus is the amount required for the entire policy term and is included in the total price. See application for more details.',
  priceDetailsFees:
    'These may include installment fees, cancellation fees, reinstatement fees, NSF fees, etc. Total fees is amount required for the entire policy period and is included in total price.',
  garageAddressRuleException:
    'This will allow the garage address of this vehicle to be in a location outside of the policy state or valid ZIP code range.',
  uneditableFieldsTooltipText:
    'We’ve gathered this information about the home from trusted certified sources. If an adjustment needs to be made to this information, please contact support.',
  squareFtTooltipText:
    'We’ve gathered this information about the home from trusted certified sources. If you need to lower the total square footage value, please contact support.',
  transactionDate:
    'This is the date from which the policy’s cancel effective date will be determined in accordance to state laws.',
  cancelEffectiveDate:
    'This date will be used to determine the last full day of coverage. If a mid-term cancellation, the policy will end 12:01 AM on the selected date.',
  rceText:
    'RCE, or Replacement Cost Estimate, is our estimated cost for replacing this home in the case of a total loss. This number is calculated using Home360 as well as other home data vendors. The maximum amount Branch will cover in the case of a total loss is defined by the Dwelling limit.'
};

export const connectedHomeTooltipHoverTexts = {
  moisture: {
    bix: 'These devices detect moisture, placed where leaks can occur (under sinks, near water heater or washer, etc.). Professionally installed and centrally monitored through a company or a detection system installed by a homeowner and self-monitored via an app.',
    nonbix:
      'These devices detect moisture, placed where leaks can occur (under sinks, near water heater or washer, etc.). Typically installed by a homeowner and monitored via an app.'
  },
  smoke: {
    bix: 'Battery or hard-wired smoke detectors; could be a traditional smoke detector or combination detector.',
    nonbix: 'Battery or hard-wired smoke detectors; could be a traditional smoke detector or combination detector.'
  },
  theft: {
    bix: 'These devices (door alarms, window alarms, etc.) are part of a centrally-monitored alarm system—either installed by a professional or by a homeowner—where the homeowner pays for professional monitoring.',
    nonbix:
      'These devices (door alarms, window alarms, etc.) are part of a centrally-monitored alarm system—either installed by a professional or by a homeowner—where the homeowner pays for professional monitoring.'
  },
  water: {
    bix: 'These devices detect potential water leaks and automatically shut off the water supply to minimize damage. Typically placed on the main water line inside a home and professional installation is usually recommended.',
    nonbix:
      'These devices detect potential water leaks and automatically shut off the water supply to minimize damage. Typically placed on the main water line inside a home and professional installation is usually recommended.'
  },
  motion: {
    nonbix:
      'Professionally installed and centrally monitored motion through a security company or a security system with motion detectors installed by a homeowner and self-monitored via an app.'
  },
  monitoringTypes: {
    bix: 'This discount is for professionally monitored systems. If the devices are not monitored, the discount should be turned off.',
    nonbix:
      'This discount is for professionally monitored systems. If the devices are not monitored, the discount should be turned off'
  },
  homeSecurityPartnerCustomerType:
    'Selecting this box will prompt the home security company to contact the member to buy the home security system necessary to retain the Connected Home Discount. Available with select home security partnerships only.',
  houseHoldMembers: 'This includes drivers and non-driver residents, including children.'
};

export const footerWarningTexts = {
  branchInitiatedCancel:
    'The policy’s cancel effective date will be determined based on the chosen transaction date in accordance with state laws.',
  memberInitiatedActivePolicy: (endDate) =>
    `The policy’s last full day of coverage will be ${awsDateToDateFormatter(
      endDate
    )} and will be cancelled effective ${format(addDays(parseISO(endDate), 1), 'MM/dd/yyyy')} at 12:01 AM.`,
  memberInitiatedFlatCancel: (startDate) =>
    `The cancel effective date is on the policy’s start date: ${awsDateToDateFormatter(
      startDate
    )}. Continuing with this cancellation will result in no coverage for the policy term.`,
  flatCancel: `Continuing with this cancellation will result in no coverage for this policy term.`,
  rescind: (effectiveDate, endDate) =>
    `This policy is set to be cancelled effective ${
      effectiveDate === endDate ? awsDateToDateFormatter(endDate) : format(addDays(parseISO(endDate), 1), 'MM/dd/yyyy')
    } at 12:01 AM`,
  reinstate: (effectiveDate, endDate) =>
    `This policy has been cancelled effective  ${
      effectiveDate === endDate ? awsDateToDateFormatter(endDate) : format(addDays(parseISO(endDate), 1), 'MM/dd/yyyy')
    } at 12:01 AM`
};

export const CondoType = {
  LOW_RISE: 'LR',
  MID_RISE: 'MR',
  HIGH_RISE: 'HR',
  TOWNHOUSE: 'TH',
  ROWHOUSE: 'R',
  DETACHED: 'D'
};

export const condoTypesWithEntry = [CondoType.LOW_RISE];
export const condoTypesWithBasement = [CondoType.TOWNHOUSE, CondoType.ROWHOUSE, CondoType.DETACHED];
export const condoTypesWithFloorLevel = [CondoType.LOW_RISE, CondoType.MID_RISE, CondoType.HIGH_RISE];
export const condoTypesWithUnitsInBuilding = [CondoType.LOW_RISE, CondoType.TOWNHOUSE, CondoType.ROWHOUSE];
