import React, { memo } from 'react';
import { Grid } from '@material-ui/core';

import ValueField from 'core/components/value-field';
import useStyles from './info-field.styles';

const InfoField = ({ label, value, formatter, xs }) => {
  const classes = useStyles();
  return (
    <Grid item xs={xs}>
      <ValueField className={classes.field} label={label} value={value || 'Not provided'} formatter={formatter} />
    </Grid>
  );
};

export default memo(InfoField);
