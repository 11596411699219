import React from 'react';

import { Card } from '../card';
import notificationIconWhite from '../../assets/svg/alert-white-bold.svg';
import useStyles from './small-notification.styles';

const SmallNotification = ({ children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <img src={notificationIconWhite} alt="Notification Icon" />
      {children}
    </Card>
  );
};

export default SmallNotification;
