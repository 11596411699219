import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  warning: {
    display: 'block',
    fontSize: 14,
    lineHeight: 1.4
  },
  link: {
    display: 'inline',
    color: theme.colorPalette.white_10,
    textDecoration: 'underline'
  }
});

export default makeStyles(styles);
