// NOTE: This settings file is intended to have any setting that's directly related to our rating logic
// For example: any setting that it's used inside underwriting-rules, rating packages or rater package might fit here

export const ratingSeriousness = {
  AAF: 4,
  AFM: 0,
  AFN: 2,
  ANC: 0,
  ANO: 0,
  ASW: 0,
  BOT: 1,
  CCW: 0,
  CML: 2,
  CMP: 0,
  CMU: 0,
  CRD: 4,
  DEV: 2,
  DEQ: 2,
  DR: 8,
  DWI: 8,
  FAR: 2,
  FDL: 2,
  FEL: 8,
  FFR: 8,
  FLE: 8,
  FRA: 2,
  FTC: 2,
  FTY: 2,
  HOM: 8,
  IBK: 2,
  IP: 2,
  IT: 2,
  LDL: 4,
  LIC: 2,
  LTS: 4,
  MAJ: 4,
  MMV: 2,
  NAF: 0,
  NFX: 0,
  PUA: 0,
  PUN: 0,
  REF: 1,
  RKD: 8,
  SAF: 2,
  SCH: 2,
  SLV: 4,
  SPD: 2,
  SUS: 10,
  TMP: 0,
  UDR: 4,
  WSR: 2
};

export const monarchPoints = {
  AAF: 3,
  CMP: 2,
  NAF: 1
};

export const homeClaimPoints = {
  D: 4,
  G: 3,
  E: 2,
  F: 1
};

export const mortgageClauses = {
  RM1: {
    correctName: 'T2 FINANCIAL LLC DBA/ REVOLUTION MORTGAGE ISAOA/ATIMA',
    address: '480 Olde Worthington Road',
    address2: 'Ste 300',
    city: 'Westerville',
    state: 'OH',
    zip: '43082'
  }
};

export const minimumDrivingAgefor5YearDiscount = {
  AL: 21,
  AK: 21,
  AZ: 21,
  AR: 21,
  CA: 21,
  CO: 21,
  CT: 21.33,
  DE: 21.5,
  DC: 21.5,
  FL: 21,
  GA: 21,
  HI: 21,
  ID: 20,
  IL: 21,
  IN: 21.25,
  IA: 21,
  KS: 21,
  KY: 21.5,
  LA: 21,
  ME: 21,
  MD: 21.5,
  MA: 21.5,
  MI: 21,
  MN: 21,
  MS: 21,
  MO: 21,
  MT: 20,
  NE: 21,
  NV: 21,
  NH: 21,
  NJ: 22,
  NM: 20.5,
  NY: 21.5,
  NC: 21,
  ND: 20,
  OH: 21,
  OK: 21,
  OR: 21,
  PA: 21.5,
  RI: 21.5,
  SC: 20.5,
  SD: 19.5,
  TN: 21,
  TX: 21,
  UT: 21,
  VT: 21,
  VA: 21,
  WA: 21,
  WV: 21,
  WI: 21,
  WY: 21
};

export const cleanEligibilityInHomeownersStates = {
  AL: 523,
  AZ: 523,
  AR: 523,
  GA: 523,
  IL: 523,
  ID: 523,
  IN: 523,
  IA: 523,
  KS: 402,
  LA: 523,
  MI: 523,
  MS: 523,
  MT: 523,
  ND: 523,
  NE: 523,
  NM: 523,
  TN: 523,
  OH: 523,
  OK: 523,
  OR: 523,
  PA: 523,
  SC: 402,
  SD: 523,
  TX: 523,
  UT: 523,
  VA: 523,
  VT: 523,
  WV: 523,
  WI: 523,
  WY: 523
};

// Note: Here are the DTC values translated to distance
// 01: 0 to 500 ft, 02: 501 to 1000, 03: 1001 to 1500, 04: 1501 to 2000, 05: 2001 to 2500
// 06: 2501 ft to less than 1 mi, 07: 1 to <2mi, 08: 2 to <3mi, 09: 3 to <4mi, 10: 4 to < 5mi
// 11: 5 to <10mi, 12: 10 to <20, 13: 15mi to <20, 14: 20mi to <25, 15: 25mi to <30, 16: 30mi +

export const ineligibleDistanceToOceanCodesStates = {
  MA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  MD: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  ME: ['01', '02', '03', '04', '05', '1', '2', '3', '4', '5'],
  MS: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  VA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  FL: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
};

export const windHailExclusionDistanceToOceanCodesStates = {
  GA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  MD: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  ME: ['01', '02', '03', '04', '05', '1', '2', '3', '4', '5'],
  MS: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  VA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6']
};

export const windHailTwoPercentMinimumDeductibleCodes = ['07', '08', '09', '10', '7', '8', '9'];

export const driverViolationsDisqualifyingCourseDiscountStates = {
  AL: ['AAF', 'CRD', 'DR', 'FEL', 'FLE', 'HOM', 'LDL', 'LTS', 'MAJ', 'RKD', 'SUS', 'DWI'],
  MS: ['AAF', 'DWI', 'MAJ'],
  MT: ['AAF', 'CRD', 'DR', 'FEL', 'FLE', 'HOM', 'LDL', 'LTS', 'MAJ', 'MMV', 'RKD', 'SPD', 'SUS', 'DWI']
};

export const disqualifyingChargesByState = {
  MD: ['CRD', 'DR', 'DWI', 'FDL', 'FEL', 'FLE', 'FSV', 'HOM', 'LDL', 'LTS', 'RKD', 'SLV', 'SUS'],
  MI: ['CRD', 'DR', 'DWI', 'FEL', 'FLE', 'FSV', 'HOM', 'LDL', 'LTS', 'RKD', 'SLV', 'SUS']
};

export const rceLimitOnline = {
  AL: 950000,
  AR: 950000,
  AZ: 950000,
  CO: 950000,
  DC: 950000,
  FL: 950000,
  GA: 950000,
  IA: 950000,
  ID: 950000,
  IL: 950000,
  IN: 950000,
  KS: 950000,
  KY: 950000,
  LA: 950000,
  MA: 950000,
  MD: 950000,
  ME: 950000,
  MI: 950000,
  MO: 9999999, // as per github issue 18612
  MS: 950000,
  MT: 950000,
  ND: 950000,
  NE: 950000,
  NH: 950000,
  NM: 950000,
  OH: 950000,
  OR: 950000,
  PA: 950000,
  SC: 950000,
  SD: 950000,
  TN: 950000,
  TX: 850000,
  UT: 950000,
  VA: 950000,
  VT: 950000,
  WI: 950000,
  WV: 950000,
  WY: 950000
};

export const rceMinimum = {
  MI: 50000
};

// where we cannot use Branch claims for 3/5 year discounts
export const companyNoRateCodesForDiscounts = {
  AL: ['NAF'],
  AR: ['NAF'],
  AZ: ['NAF'],
  CO: ['NAF'],
  DC: ['NAF'],
  GA: ['NAF'],
  IL: ['NAF'],
  KS: ['NAF'],
  MD: ['NAF'],
  MI: ['NAF'],
  MO: ['NAF'],
  MS: ['NAF'],
  MT: ['NAF'],
  ND: ['NAF'],
  NH: ['NAF'],
  OK: ['NAF'],
  SC: ['NAF'],
  SD: ['NAF'],
  TN: ['NAF'],
  UT: ['NAF'],
  VT: ['NAF'],
  WI: ['NAF'],
  WY: ['NAF']
};

// These zero out violations of these types so they cannot be used at all for rating
export const autoViolationNoRateCodes = {
  IA: ['NAF', 'CMP', 'CMU'],
  MO: ['PUN', 'NAF', 'CMP', 'CMU'],
  MT: ['PUN', 'NAF', 'CMP', 'CMU'],
  ND: ['NAF', 'CMP', 'CMU'],
  NH: ['NAF', 'CMP', 'CMU'],
  TN: ['CMP', 'CMU'],
  TX: ['PUN', 'CMP', 'CMU'],
  VT: ['ANO', 'ANC', 'PUA', 'NAF', 'CMP', 'CMU'],
  WY: ['CMP', 'CMU']
};

export const HAS_AUTO_CALCULATION_DATE = '2023-07-05';

// We are blocking the home/condo offer when the risk concentration
// is greater than the threshold for the state
export const radiusRiskConcentrationLimit = {
  // Added OH just to show how a state specific threshold would work
  OH: 2500000,
  // Country wide threshold
  '*': 2500000
};

// also grid risk concentration definitions
export const gridRiskConcentrationStrictRules = {
  AR: true,
  FL: true,
  GA: true,
  HI: true,
  KY: true,
  MN: true,
  MO: true,
  NE: true,
  NC: true,
  MD: true,
  RI: true,
  TN: true,
  TX: true,
  WV: true,
  WY: true,
  CO: true,
  CT: true,
  LA: true,
  ME: true,
  MT: true,
  VA: true
};

export const financialResponsibilityTiersForReopening = ['A-1', 'B-1', 'C-1', 'D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1'];

export const policySpecificRateCappingStates = {
  AR: {
    H: true
  },
  GA: {
    H: true
  },
  MA: {
    A: true
  },
  PA: {
    A: true
  }
};

export const onlyThirtySixLookbackStates = {
  MD: true,
  TX: true,
  MT: true,
  WV: true
};
