import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  error: {
    position: 'absolute',
    width: 260,
    left: -300,
    background: theme.colorPalette.green_100,
    padding: theme.spacing(4),
    borderRadius: 6,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '12px solid transparent',
    borderLeft: `16px solid ${theme.colorPalette.green_100}`,
    borderBottom: '12px solid transparent',
    position: 'absolute',
    right: theme.spacing(-4)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.colorPalette.white_10,
    gap: theme.spacing(2)
  },
  alertRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.colorPalette.green_60,
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
    gap: theme.spacing(2)
  },
  titleRow: {
    display: 'flex',
    textAlign: 'left',
    width: '100%',
    color: theme.colorPalette.white_10,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  subtitle: {
    display: 'flex',
    width: '100%',
    textAlign: 'left'
  },
  whyBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `2px solid ${theme.colorPalette.green_15}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  whyRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px'
  },
  why: {
    textAlign: 'left',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.colorPalette.white_10
  },
  actions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  button: {
    fontSize: 16,
    fontWeight: 600
  },
  dismiss: {
    fontWeight: 600
  },
  attestRow: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '14px',
    gap: theme.spacing(2)
  },
  attestIcon: {
    width: 18,
    height: 18
  }
});

export default makeStyles(styles);
