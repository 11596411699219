import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  loaderContainer: {
    padding: theme.spacing(8, 0),
    display: 'flex',
    justifyContent: 'center',
    minHeight: 450
  },
  loader: {
    color: theme.palette.secondary.main
  }
});

export default makeStyles(styles);
