import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(4),
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.button__background_primary,
    gap: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
  },
  firstContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(4)
  },
  button: {
    padding: theme.spacing(2, 4)
  },
  buttonIcon: {
    marginRight: theme.spacing(2)
  },
  divider: {
    backgroundColor: theme.colorPalette.green_15,
    width: '100%'
  },
  pricesContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  priceContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  beige: {
    color: theme.colors.light_text
  },
  premiumPrice: {
    color: theme.colorPalette.white_10
  },
  errorContainer: {
    border: `2px solid ${theme.colorPalette.green_15}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
    color: theme.colorPalette.white_30,
    borderRadius: theme.sizes.roundCorners
  }
}));

export default useStyles;
