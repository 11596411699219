import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { getOptions } from '@ourbranch/lookups';

import FormList from 'core/components/form-list';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { getValues, sortItems } from 'core/helpers/scheduled-pp-helper';
import AddProperty from './add-property';
import Property from './property';
import useStyles from './schedule-pp.styles';

const id = 'scheduledPersonalProperty';

const SchedulePP = ({ disabled, sppLimits }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const items = values[id] ? values[id].items : [];

  // we display the categories differently than how they are represented in the DB so we need to format when a value is added.
  useEffect(() => {
    setFieldValue(`${id}.items`, sortItems(items.map((item) => getValues(item))));
  }, [items.length]);

  return (
    <div id="sppContainer">
      <FormList
        disabled={disabled}
        id={`${id}.items`}
        item={Property}
        addForm={AddProperty}
        title="Schedule Personal Properties"
        addLabel="Add Personal Property"
        singular="property"
        plural="properties"
        sppLimits={{ ...sppLimits, items }}
      >
        {items && items.length > 0 && (
          <Form disabled={disabled}>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.deductibleContainer}>
              <Label type="infoSubtitle" key="spp-label" xs={4}>
                Total Deductible for all SPP
              </Label>
              <FormField
                type="select"
                name={`${id}.deductible`}
                options={getOptions('scheduledPersonalPropertyDeductible')}
                className={classes.selectRoot}
                label="Select a Deductible"
                xs={4}
                mode="light"
                fast={false}
              />
            </Grid>
          </Form>
        )}
      </FormList>
    </div>
  );
};

SchedulePP.propTypes = {
  disabled: PropTypes.bool
};

SchedulePP.defaultProps = {
  disabled: false
};

export default SchedulePP;
