const roofSurfacesExtendedCompositionMaxAge = {
  AL: 15,
  AR: 15,
  AZ: 15,
  CO: 15,
  DC: 15,
  GA: 15,
  IA: 15,
  ID: 15,
  IL: 15,
  IN: 15,
  KS: 15,
  KY: 15,
  LA: 15,
  MA: 15,
  MD: 15,
  ME: 15,
  MI: 15,
  MO: 10,
  MS: 15,
  MT: 15,
  ND: 15,
  NE: 15,
  NM: 15,
  OH: 15,
  OK: 15,
  OR: 15,
  PA: 15,
  SC: 15,
  SD: 15,
  TN: 15,
  TX: 15,
  UT: 15,
  VA: 15,
  VT: 15,
  WI: 15,
  WV: 15,
  WY: 15
};

type WillHaveCoverageRSExtended = {
  roofType: string;
  roofAge: number;
  state: keyof typeof roofSurfacesExtendedCompositionMaxAge;
};

export function willHaveCoverageRSExtended({ roofType, roofAge, state }: WillHaveCoverageRSExtended) {
  return (
    !['OK', 'TX'].includes(state) &&
    ((['AF', 'AR', 'C'].includes(roofType) && roofAge <= (roofSurfacesExtendedCompositionMaxAge[state] || 15)) ||
      (['SL', 'ST', 'TN', 'CL', 'CO', 'MI', 'CP', 'CP', 'M', 'T', 'S'].includes(roofType) && roofAge <= 20) ||
      roofAge <= 10)
  );
}
