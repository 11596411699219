import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import { numberFormatter } from 'core/helpers/formatters';
import useStyles from './cross-sell-eligibility-notification.styles';

const calculateBundleSavings = ({ options }) => {
  const bundleOption = options?.find((o) => o.name === 'Bundle');
  const autoOption = options?.find((o) => o.type === 'A');
  const homeOption = options?.find((o) => o.type === 'H');

  if (!bundleOption) {
    return 0;
  }

  const monoHomeTotal = homeOption?.homeTotal || 0;
  const monoAutoTotal = autoOption?.autoTotal || 0;
  const bundleHomeTotal = bundleOption?.homeTotal || 0;
  const bundleAutoTotal = bundleOption?.autoTotal || 0;

  const homeSavings = monoHomeTotal > bundleHomeTotal ? monoHomeTotal - bundleHomeTotal : 0;
  const rentersTotal = bundleOption?.autoCoverages?.find((coverage) => coverage.type === 'renters_total')?.amount || 0;
  const autoSavings =
    monoAutoTotal > bundleAutoTotal - rentersTotal ? (monoAutoTotal - (bundleAutoTotal - rentersTotal)) * 2 : 0;

  return homeSavings + autoSavings;
};

const money = (value) => `$${numberFormatter(value)}`;

const CrossSellEligibilityNotification = () => {
  const classes = useStyles();
  const {
    account: {
      policies: { list }
    },
    quote: { setPrefillData }
  } = useStore();
  const [existingPolicy] = list;
  const existing = existingPolicy.policyType === 'A' ? 'Auto' : 'Home';
  const newPolicy = existingPolicy.policyType === 'A' ? 'Home' : 'Auto';
  const savings = useMemo(() => calculateBundleSavings(existingPolicy.offer), [existingPolicy]);

  const handleBundleNowClick = useCallback(
    (location) => {
      setPrefillData(existingPolicy.offer);
      return '/quote';
    },
    [existingPolicy, setPrefillData]
  );

  if (!savings) {
    return (
      <Label className={classes.warning}>
        This member is probably eligible to add a {newPolicy} policy to their existing {existing} policy. When this
        member bought their current policy, we did not have enough information to price the {newPolicy} policy.
        <Link to={handleBundleNowClick} className={classes.link}>
          <span> Bundle Now! </span>
        </Link>
      </Label>
    );
  }

  return (
    <Label className={classes.warning}>
      This member is eligible to add a {newPolicy} policy to their existing {existing} policy. Based on the
      member&apos;s most recent offer before they purchased, this would save approximately {money(savings)} per year.{' '}
      <Link to={handleBundleNowClick} className={classes.link}>
        <span> Bundle Now! </span>
      </Link>
    </Label>
  );
};

export default CrossSellEligibilityNotification;
