import { lookupsJson, validZipCodeRanges } from '@ourbranch/lookups';

export const checkZipCodeRange = (zip, state) => {
  const zipCodeRanges = validZipCodeRanges[state];
  const numericZip = parseInt(zip, 10);
  return zipCodeRanges.some((zipRange) => {
    if (typeof zipRange === 'number') {
      return zipRange === numericZip;
    }
    return numericZip >= zipRange[0] && numericZip <= zipRange[1];
  });
};

export const getStateFullName = (state) => {
  const name = lookupsJson.usStates.find((stateName) => stateName.id === state);
  return name?.value;
};
