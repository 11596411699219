import React from 'react';
import Grid from '@material-ui/core/Grid';

import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import NotificationIconGreen from 'core/assets/svg/alert-green.svg';

import useStyles from './reset-task-modal.styles';

const ResetModal = ({ open, onClose, taskName, onReset, completedBy, rejections }) => {
  const classes = useStyles();

  return (
    <BaseDialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <Label type="greenBig" className={classes.modalHeader}>
        Reset the "{taskName}" task?
      </Label>
      <Label type="darkGreenSmall" className={classes.modalContent}>
        This will reset the task to incomplete status. The currently uploaded files for this task will still be
        accessible in the internal documents to anyone with access.
      </Label>
      <Card className={classes.card}>
        <Grid className={classes.cardContent}>
          <img className={classes.notificationIcon} src={NotificationIconGreen} alt="Notification Icon" />
          <Label className={classes.cardText}>
            {completedBy !== 'member' &&
              'Proceeding with the reset will NOT trigger a communication to the member. Manual outreach is required if additional member action is needed.'}
            {completedBy === 'member' &&
              (rejections?.length ? (
                <>
                  <b>This task has been reset previously.</b>
                  Proceeding with the reset will notify the member and, if agency-bound, their associated agency, that
                  the task has been denied and that it will be treated as incomplete.
                </>
              ) : (
                "Resetting informs the member, and if agency-bound, their associated agency. It also extends the member's task deadline by 5 days if they have less than 2 days left or if overdue."
              ))}
          </Label>
        </Grid>
      </Card>
      <Grid className={classes.btncontainer}>
        <Button variant="contained" color="secondary" className={classes.nobtn} onClick={onClose}>
          No, keep
        </Button>
        <Button variant="contained" color="primary" className={classes.yesbtn} onClick={onReset}>
          Yes, reset task
        </Button>
      </Grid>
    </BaseDialog>
  );
};

export default ResetModal;
