import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 800,
    minHeight: 284,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: theme.spacing(12)
  },
  dialogContainer: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  title: {
    color: theme.colorPalette.green_10,
    fontSize: 40,
    lineHeight: '56px',
    fontWeight: 300,
    padding: 0,
    width: '100%'
  },
  text: {
    color: theme.colors.green_15,
    fontSize: 16,
    fontWeight: 400,
    display: 'inline',
    lineHeight: 1.5,
    margin: theme.spacing(4, 0)
  },
  content: {
    padding: theme.spacing(0, 6),
    gap: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  detailsContent: {
    gap: theme.spacing(9),
    margin: theme.spacing(4, 0),
    alignItems: 'center'
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(7),
    margin: theme.spacing(0)
  },
  card: {
    padding: theme.spacing(6),
    margin: theme.spacing(0),
    boxShadow: 'none'
  },
  btnContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center'
  },
  submit: {
    width: 198,
    fontSize: 16,
    padding: theme.spacing(1.5, 4)
  },
  select: {
    width: 428
  },
  settingsButton: {
    padding: theme.spacing(0, 1),
    maxHeight: '24.5px',
    fontSize: 16
  }
}));

export default useStyles;
