import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  label: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 10,
    paddingBottom: 0
  },
  container: {
    height: 54
  },
  disabled: {
    opacity: 0.4
  },
  labelNextToText: {
    justifyContent: 'start',
    gap: theme.spacing(1)
  }
});

export default makeStyles(styles);
