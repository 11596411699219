import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { Link, useLocation } from 'react-router-dom';

import backIcon from 'core/components/screen-header/go-to.svg';
import { FormField, FormSubmit } from 'core/components/form';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import withDatePicker from 'core/components/with-date-picker';
import TaskOutput from './components/task-output';
import StateMachineOutput from './components/state-machine-output';
import LiveTaskMessages from './components/live-task-messages';
import TaskHistory from './components/task-history';
import { isJson } from 'tools/helpers';

import useStyles from './generate-task-ui.styles';

const typeToFormField = {
  selection: 'select',
  shorttext: 'string',
  boolean: 'switch',
  integer: 'numeric',
  longtext: 'textarea'
};

const getType = (type) => {
  if (!typeToFormField[type]) {
    return type;
  }
  return typeToFormField[type];
};

const TASK_LIVE_OUTPUT_SUBSCRIPTION = gql`
  subscription onTaskLiveOutput($taskRunId: String!) {
    onTaskLiveOutput(taskRunId: $taskRunId) {
      taskRunId
      message
      type
    }
  }
`;

const parseOutput = (output) => {
  if (!output) {
    return undefined;
  }
  if (output.type === 'OUTPUT' && output?.message) {
    return { type: 'OUTPUT', ...JSON.parse(output.message) };
  }
  if (output.type === 'ERROR') {
    return output;
  }
  return undefined;
};

/**
 * GenerateTaskUI component renders a form for a given task.
 * This component will work if task fires state machine or a single mutation that triggers a lambda.
 *
 * @component
 * @param {string} name - The name of the task.
 * @param {string} description - The description of the task.
 * @param {Object} parameters - The parameters required for the task.
 * @param {Function} onSubmit - The function to be called when the form is submitted.
 * @param {boolean} loading - Indicates whether the task is currently running.
 * @param {Object} error - The error object if there was an error during task execution.
 * @param {Object} response - The response object containing the task output. If task started a state machine, this will have the taskRunId.
 * @param {Object} validationSchema - The Yup validation schema for the form.
 * @returns {JSX.Element} The rendered GenerateTaskUI component.
 */
const GenerateTaskUI = ({ name, description, parameters, onSubmit, loading, error, response, validationSchema }) => {
  const classes = useStyles();
  const [runStarted, setRunStarted] = useState(false);
  const [messages, setMessages] = useState([]);
  const [output, setOutput] = useState();

  // hide history if we're using this component in a dashboard
  const { pathname } = useLocation();
  const fromDashboard = pathname.includes('dashboard');

  const keys = Object.keys(parameters);
  const initialValues = keys.reduce((acc, key) => {
    acc[key] = parameters[key].default;
    return acc;
  }, {});

  const taskRunId = response?.taskRunId;
  const parsedTaskOutput =
    response?.details && isJson(response?.details) ? JSON.parse(response?.details) : response?.details;
  // need to check if parsedTaskOutput is an object and not an array because typeof array is object and we want [{...}] not [[{...}]]
  const taskOutput =
    parsedTaskOutput && typeof parsedTaskOutput === 'object' && !Array.isArray(parsedTaskOutput)
      ? [parsedTaskOutput]
      : parsedTaskOutput;
  const success = response?.__typename === 'ToolSuccess' || parsedTaskOutput?.success;
  const stateMachineOutput = parseOutput(output);

  const { data: subscriptionData } = useSubscription(TASK_LIVE_OUTPUT_SUBSCRIPTION, {
    variables: { taskRunId },
    skip: !taskRunId
  });

  useEffect(() => {
    // for state machine support
    const type = subscriptionData?.onTaskLiveOutput?.type;
    if (subscriptionData?.onTaskLiveOutput?.message && (!type || type === 'MESSAGE')) {
      setMessages((prevMessages) => [...prevMessages, subscriptionData.onTaskLiveOutput.message]);
    }
    if (subscriptionData?.onTaskLiveOutput?.message && (type === 'OUTPUT' || type === 'ERROR')) {
      setOutput(subscriptionData.onTaskLiveOutput);
      setRunStarted(false);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (taskRunId && !runStarted) {
      // handle loading state if state machine is started
      setRunStarted(true);
    }
  }, [taskRunId]);

  useEffect(() => {
    if (taskOutput || stateMachineOutput) {
      setRunStarted(false);
    }
  }, [taskRunId]);

  return (
    <>
      <Link to="/staff/tools">
        <Grid container alignItems="center">
          <Button variant="text" color="secondary">
            <img src={backIcon} className={classes.icon} alt="back" />
            Back to Tools Home
          </Button>
        </Grid>
      </Link>
      <Card type="secondary" className={classes.card}>
        <Grid>
          {name && (
            <Label type="titleLight" className={classes.title}>
              {name}
            </Label>
          )}
          {description && <Label type="subtitle">{description}</Label>}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            <Form>
              {parameters &&
                keys.map((param, idx) => {
                  const conditionalFieldProps = {
                    ...(parameters[param].type !== 'affinity' && { options: parameters[param].options })
                  };

                  return (
                    <FormField
                      key={`${param}-${idx}`}
                      name={param}
                      label={parameters[param].name}
                      type={getType(parameters[param].type)}
                      description={parameters[param].description}
                      xs={parameters[param].type === 'date' ? 4 : 12}
                      defaultToEmpty={parameters[param].defaultToEmpty}
                      mode="dark"
                      templateUrl={parameters[param].templateUrl}
                      fast={parameters[param].useFastField !== false}
                      {...conditionalFieldProps}
                    />
                  );
                })}
              <FormSubmit
                variant="contained"
                color="secondary"
                loading={loading || runStarted}
                disabled={loading || runStarted || taskOutput || stateMachineOutput}
                className={classes.submit}
              >
                Submit
              </FormSubmit>
            </Form>
          </Formik>
        </Grid>
      </Card>
      {(loading || (runStarted && !taskOutput && !stateMachineOutput && !messages?.length)) && (
        <Label type="subtitle">Starting task...</Label>
      )}
      {(error || response?.error) && (
        <Label className={classes.output} type="subtitle">
          There was an error: {error?.message || response?.error}
        </Label>
      )}
      <LiveTaskMessages messages={messages} taskRunId={taskRunId} doneRunning={taskOutput || stateMachineOutput} />
      {taskOutput && <TaskOutput success={success} taskOutput={taskOutput} />}
      {stateMachineOutput && <StateMachineOutput stateMachineOutput={stateMachineOutput} />}
      {!fromDashboard && <TaskHistory title={name} doneRunning={taskOutput || stateMachineOutput} />}
    </>
  );
};

export default withDatePicker(GenerateTaskUI);
