import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import useStyles from './tool-item.styles';

const ToolItem = ({ item }) => {
  const classes = useStyles();

  return (
    <Link to={item.path}>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={12} className={classes.rowTitle}>
          <Label type="body1" className={classes.link}>
            {item.name}
          </Label>
        </Grid>
        <Grid xs={12} className={classes.rowDescription}>
          <Label type="body1" className={classes.link}>
            {item.description}
          </Label>
        </Grid>
      </Grid>
    </Link>
  );
};

ToolItem.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default memo(ToolItem);
