import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  header: {
    marginBottom: 40,
    flexWrap: 'nowrap'
  },
  container: {
    position: 'relative'
  },
  iconButtonRoot: {
    position: 'absolute',
    color: theme.colors.button__background_secondary,
    left: -48,
    padding: 0
  },
  iconButtonLabel: {
    height: 48,
    width: 48
  },
  icon: {
    height: 48,
    width: 48,
    transform: 'rotate(180deg)'
  },
  rightLabel: {
    fontSize: '14px',
    display: 'inline',
    marginRight: 8
  },
  rightValue: {
    fontSize: '14px',
    display: 'inline'
  },
  rightContainer: {
    composes: '$container',
    marginTop: 10
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  subtitle: {
    paddingRight: 10
  },
  rightSideInfo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  agencyTag: {
    backgroundColor: theme.colorPalette.beige_60,
    padding: theme.spacing(2, 4),
    height: 32,
    width: 136,
    gap: theme.spacing(2)
  }
});

export default makeStyles(styles);
