import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import styles from './table.styles';

const defaultRowsLabel = ({ from, to, count }) => `${from}-${to} of ${count}`;

const DataTable = (props) => {
  const {
    body,
    className,
    count,
    currentPage,
    header,
    loading,
    onPageChange,
    onRowsPerPageChange,
    labelDisplayedRows,
    paginationEnabled,
    nextIconButtonProps,
    rowsPerPageOptions,
    rowsPerPage,
    selfContained,
    title,
    classes,
    mode
  } = props;
  const content = (
    <>
      {loading && (
        <LinearProgress variant="indeterminate" classes={MapClassesToElem(ElemType.LinearProgress, classes)} />
      )}
      {title && (
        <Toolbar>
          <Typography variant="title" id="tableTitle" component="div">
            {title}
          </Typography>
        </Toolbar>
      )}
      <Table>
        {header && (
          <TableHead>
            <TableRow
              className={classes.header}
              classes={mode && MapClassesToElem(`${mode}-${ElemType.TableRow}Header`, classes)}
            >
              {header}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{body}</TableBody>
        {paginationEnabled && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                labelDisplayedRows={labelDisplayedRows}
                rowsPerPageOptions={rowsPerPageOptions || []}
                nextIconButtonProps={nextIconButtonProps}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );

  return selfContained ? <Paper className={classNames(classes.container, className)}>{content}</Paper> : content;
};

DataTable.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  paginationEnabled: PropTypes.bool,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  labelDisplayedRows: PropTypes.func,
  selfContained: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['dark'])
};

DataTable.defaultProps = {
  header: null,
  loading: false,
  paginationEnabled: false,
  count: 0,
  rowsPerPage: 10,
  currentPage: 0,
  onPageChange: null,
  onRowsPerPageChange: null,
  labelDisplayedRows: defaultRowsLabel,
  rowsPerPageOptions: [],
  selfContained: true,
  title: null,
  mode: null,
  className: ''
};

export default withStyles(styles)(DataTable);
