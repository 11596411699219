import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { dateFormatter } from 'core/helpers/formatters';
import { Table, SelectableRow } from 'core';
import { Label } from 'core/components/label';
import { NoDataCard } from '../../no-data-card';

const SendgridTicketSection = ({ tickets }) => {
  return (
    <Section title="Outbound Emails" rightLabel="total" rightValue={`${tickets ? tickets.length : 0} Item(s)`}>
      {tickets && tickets.length > 0 ? (
        <Card type="scrollable">
          <Table
            selfContained={false}
            header={
              <>
                <TableCell>Last Action Date</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Click Count</TableCell>
                <TableCell>Open Count</TableCell>
                <TableCell>Status</TableCell>
              </>
            }
            body={
              <>
                {tickets.map((ticket) => (
                  <SelectableRow key={ticket.id} id={ticket.id} onClick={() => window.open(ticket.url, '_blank')}>
                    <TableCell>{dateFormatter(ticket.date)}</TableCell>
                    <TableCell>{ticket.subject}</TableCell>
                    <TableCell>{ticket.clicksCount}</TableCell>
                    <TableCell>{ticket.opensCount}</TableCell>
                    <TableCell>
                      <Label type={ticket.status === 'opened' ? 'statusOpened' : 'statusClosed'}>{ticket.status}</Label>
                    </TableCell>
                  </SelectableRow>
                ))}
              </>
            }
          />
        </Card>
      ) : (
        <NoDataCard />
      )}
    </Section>
  );
};

SendgridTicketSection.propTypes = {
  tickets: PropTypes.array
};

SendgridTicketSection.defaultProps = {
  tickets: []
};

export default SendgridTicketSection;
