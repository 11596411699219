import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    marginBottom: 0
  },
  title: {
    color: theme.colorPalette.green_75,
    fontWeight: 400
  }
});

export default makeStyles(styles);
