import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(4, 6, 6, 6)
  },
  textField: {
    flexGrow: 1,
    width: '100%',
    marginBottom: 0
  },
  button: {
    width: 200,
    height: 36
  },
  search: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    alignItems: 'center'
  }
});

export default makeStyles(styles);
