import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spacing: {
    padding: '20px 0 20px 0'
  },
  wrapText: {
    wordBreak: 'break-word'
  },
  sideSpacing: {
    padding: '0px 10px 0px 10px'
  },
  branchClaim: {
    marginBottom: theme.spacing(2)
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(3, 2, 3, 2),
    borderRadius: 3,
    height: 'auto',
    '&:hover': {
      background: theme.colorPalette.orange_10_op_08
    }
  },
  arrowIcon: {
    paddingRight: 5
  },
  policyId: {
    fontWeight: '900',
    padding: '2px 3px 8px 3px'
  }
}));

export default useStyles;
