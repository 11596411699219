import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  },
  trigger: {
    padding: 0
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    padding: theme.spacing(6, 8),
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  modal: {
    '&:first-child': {
      paddingTop: theme.spacing(12)
    },
    padding: theme.spacing(12)
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'baseline'
  },
  subtitle: {
    composes: '$title',
    marginTop: theme.spacing(4)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  }
}));

export default useStyles;
