import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600
  },
  link: {
    display: 'block',
    borderTop: `1px solid ${theme.colorPalette.green_10_op_30}`,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.colorPalette.green_10_op_30}`
    },
    '&:hover': {
      backgroundColor: theme.colorPalette.beige_20
    }
  },
  status: {
    fontWeight: 500,
    color: ({ status }) => {
      switch (status) {
        case 'SUCCESS':
          return theme.colors.green_dark;
        case 'FAILED':
          return theme.colors.error;
        case 'PARTIAL_SUCCESS':
          return theme.colorPalette.orange_10;
        case 'IN_PROGRESS':
          return theme.colorPalette.orange_50;
        default:
          return theme.colors.body;
      }
    }
  },
  secondaryInfo: {
    color: theme.colors.body_light
  },
  argument: {
    display: 'flex'
  },
  argumentKey: {
    backgroundColor: theme.colorPalette.beige_75,
    color: 'white',
    borderRadius: 5,
    padding: 4,
    marginRight: theme.spacing(1)
  },
  argumentValue: {
    color: theme.colors.body_light,
    padding: '2px 5px 2px 5px',
    borderRadius: '0px 5px 5px 0px',
    border: `2px solid ${theme.colorPalette.beige_75}`,
    marginLeft: '-7px'
  }
}));

export default useStyles;
