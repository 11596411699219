import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Table } from '../../../../core/components/table';
import { withVersionUtils } from './context';
import styles from './versions.styles';

export const BodyTableCell = withStyles(styles)(({ children, classes, className, ...props }) => (
  <TableCell className={classNames(classes.tableCell, className)} {...props}>
    {children}
  </TableCell>
));

BodyTableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const VersionHistoryTable = memo(
  ({ versions, classes, header, body, onPageChange, count, page, versionsUtils, state }) => {
    const { setOrder, order } = versionsUtils;
    const HeaderComponent = header;
    const BodyComponent = body;

    // some versions may not have total price because we did not retroactively calculate it for all versions after it was added to version history details
    // if at least one version has total price, we will show the column
    const showTotalPriceColumn = versions.some((version) => version.totalPrice !== undefined);

    return (
      <div className={classes.tableContainer}>
        <Table
          classes={{ container: classes.table, header: classes.tableHeader }}
          header={
            <HeaderComponent
              classes={classes}
              order={order}
              onClick={setOrder}
              showTotalPriceColumn={showTotalPriceColumn}
            />
          }
          body={(versions || []).map(
            ({ version, itemsChanged, coverageChange, updatedDateTime, effectiveDate, changeInPremium, ...item }) => (
              <BodyComponent
                {...item}
                state={state}
                key={version}
                itemsChanged={itemsChanged || ''}
                coverageChange={coverageChange || ''}
                version={version}
                updatedDateTime={updatedDateTime}
                effectiveDate={effectiveDate}
                changeInPremium={changeInPremium}
                classes={classes}
                showTotalPriceColumn={showTotalPriceColumn}
              />
            )
          )}
        />
        {!!onPageChange && (
          <TablePagination
            component="div"
            onPageChange={onPageChange}
            count={count}
            page={page}
            rowsPerPage={5}
            rowsPerPageOptions={[]}
            classes={{ caption: classes.paginationCaption }}
          />
        )}
      </div>
    );
  }
);

VersionHistoryTable.propTypes = {
  versions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  header: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  versionsUtils: PropTypes.object.isRequired,
  state: PropTypes.string.isRequired
};

VersionHistoryTable.defaultProps = {
  onPageChange: undefined,
  count: undefined,
  page: undefined
};

export default flowRight(withStyles(styles), withVersionUtils)(VersionHistoryTable);
