import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    loaderContainer: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loaderCard: {
      width: 480,
      padding: theme.spacing(20, 6),
      height: 'auto',
      margin: 'auto',
      background: theme.colorPalette.white_30,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 6,
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)'
    }
  })
);
