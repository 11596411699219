import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  noteDetail: {
    padding: '3px 10px 3px 20px',
    backgroundColor: 'white',
    borderRadius: '5px',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1)
  },
  noteContainer: {
    margin: '10px 0px 15px 0px'
  },
  commNote: {
    borderLeft: '4px solid white',
    lineHeight: '180%',
    marginLeft: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  tooltip: {
    padding: 0
  },
  noteTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  noteSpacing: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

export default useStyles;
