import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import copy from 'copy-to-clipboard';

import { capitalize } from 'core/helpers/formatters';
import { Label } from 'core';
import copyIcon from 'core/assets/svg/copy.svg';
import { useToast } from 'core/components/toast';
import { labelStyleMap } from '../constants';
import useStyles from './claim-title.styles';

const ClaimTitle = ({ claim }) => {
  const toast = useToast();
  const classes = useStyles();

  const formattedTitle = `${capitalize(claim.cause) || 'Unknown'} - ${claim.claimNumber}`;

  const copyTitle = () => {
    const copied = copy(formattedTitle);
    if (copied) {
      toast.notify({
        type: 'success',
        message: `${formattedTitle} copied to your clipboard.`
      });
    } else {
      toast.notify({
        type: 'error',
        message: `${formattedTitle} could not be copied`
      });
    }
  };

  return (
    <>
      <Label type="title">
        <span>
          {formattedTitle}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img className={classes.copyButton} src={copyIcon} alt="copy icon" onClick={() => copyTitle()} />
        </span>
      </Label>
      <Label type={`${labelStyleMap[claim.status] || 'UNKNOWN'}`} paddingTop={20}>
        &#x25CF; {claim.status}
      </Label>
    </>
  );
};

ClaimTitle.propTypes = {
  claim: PropTypes.object.isRequired
};

export default observer(ClaimTitle);
