import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import {
  lookupsJson,
  getOptions,
  vehicleEquipmentStatusCode,
  annualMilesDrivenLimits,
  noUMPDStates,
  policyLevelUMPDStates,
  minimumDeductibleCollision,
  minimumDeductibleComprehensive
} from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { numberFormatter } from 'core/helpers/formatters';
import useSession from 'core/hooks/use-session';
import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { LabelTooltip } from 'core/components/label-tooltip';
import { Label } from 'core/components/label';
import CollapsibleCard from 'core/components/collapsible-card';
import { SmallNotification } from 'core/components/small-notification';
import { getDeductibleOptions } from './helpers';
import { AddCarDetails } from './add-car-details';
import { GarageLocation } from './garage-location';
import useStyles from './car.styles';

const deductibleComprehensiveLabels = {
  VA: 'OTHER THAN COLLISION DEDUCTIBLE'
};
const limitRentalLabels = {
  VA: 'TRANSPORTATION EXPENSES (RENTAL)'
};
const roadsideLabels = {
  VA: 'TOWING COVERAGE'
};

const acpeLabels = {
  VA: 'ADD-ON EQUIPMENT'
};

export const requiredCarFields = [
  'deductibleCollision',
  'deductibleComprehensive',
  'limitRental',
  'limitUMPD',
  'limitACPE'
];

const getCarHeader = (item) => {
  const header = `${item.year || ''} ${item.make || ''} `.concat(
    item.year || item.model || item.make ? ` ${item.model || ''}` : ` ${item.model || 'New Car'}`
  );
  return header;
};

const getDisabledOptionsButCurrent = (options, currentValue) =>
  options.map((option) => {
    return {
      ...option,
      disabled: option.id !== 'NONE' && currentValue && option.id !== currentValue
    };
  });

const getCoveragesOptions = (state, car, policy) => {
  const collisionOptions = getOptions('deductibleCollision', state);
  let comprehensiveOptions = getOptions('deductibleComprehensive', state);

  // https://github.com/gobranch/branch/issues/18296
  if (state === 'KY' && (!policy || policy.rateControlDate > '2023-11-16')) {
    comprehensiveOptions = comprehensiveOptions.filter((option) => option.id === 'NONE' || option.id.includes('/0GL'));
  }

  const deductibleCollisionOptions = getDeductibleOptions(
    collisionOptions,
    state,
    car?.deductibleCollision,
    minimumDeductibleCollision
  );

  const deductibleComprehensiveOptions = getDeductibleOptions(
    comprehensiveOptions,
    state,
    car?.deductibleComprehensive,
    minimumDeductibleComprehensive
  );

  return {
    deductibleCollisionOptions,
    deductibleComprehensiveOptions
  };
};

const Car = observer(({ fieldName, item = {}, index, removeFromList, onRemove, disabled, fromPolicy }) => {
  const classes = useStyles();
  const {
    offer: offerStore,
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const car =
    (fromPolicy ? policyStore?.policy?.policyDetails?.cars[index] : offerStore?.offer?.quote?.cars[index]) || {};
  const displayLeaseLoan = fromPolicy ? policyStore?.showLeaseLoan : offerStore?.showLeaseLoan;

  const { setTouched, errors, setFieldValue, initialValues } = useFormikContext();

  // https://github.com/gobranch/branch/issues/20927
  // only show the rideshare option if they already have it on
  const initialCar = initialValues?.cars?.[index];
  const showRideShare = initialCar?.rideSharing;

  const { canAddCarsManually } = useSession();

  const state = item?.garageLocation?.state || null;

  const { deductibleComprehensiveOptions, deductibleCollisionOptions } = getCoveragesOptions(
    state,
    car,
    policyStore.policy
  );

  const limitRentalOptions = getOptions('limitRental', state);
  const limitACPEOptions = getOptions('limitACPE', state);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setTouched(errors);
    }
  }, [errors, setTouched]);

  const {
    aebStatus,
    antiTheftStatus,
    blindSpotStatus,
    singleAirbagStatus,
    doubleAirbagStatus,
    deductibleCollision,
    deductibleComprehensive
  } = item;

  // build the header string dynamically
  const getHeaderStatuses = useMemo(() => {
    const buildLabelText = (statuses, type) => {
      const statusLabels = {
        aebStatus: 'Automatic Emergency Braking',
        antiTheftStatus: 'Anti-theft Device',
        blindSpotStatus: 'Blind Spot Warning System',
        singleAirbagStatus: 'Single Airbag',
        doubleAirbagStatus: 'Dual Airbags'
      };

      const statusType =
        type === 'standard' ? vehicleEquipmentStatusCode.STANDARD : vehicleEquipmentStatusCode.OPTIONAL;

      const typeText = type === 'standard' ? 'Equipped with Standard: ' : 'Optionally Equipped with: ';

      return Object.entries(statuses)
        .filter((entry) => String(entry[1]) === String(statusType))
        .reduce((statusText, [status, value], idx, arr) => {
          const lastItem = idx === arr.length - 1;
          if (!statusText) {
            // Empty accumulator
            return `${statusText} ${typeText} ${statusLabels[status]}${lastItem ? '.' : ''}`;
          }
          if (lastItem) {
            // last standard or optional item
            return `${statusText}, and ${statusLabels[status]}.`;
          }
          return `${statusText}, ${statusLabels[status]}`;
        }, '');
    };

    const statuses = { aebStatus, antiTheftStatus, blindSpotStatus, singleAirbagStatus, doubleAirbagStatus };
    return buildLabelText(statuses, 'standard').concat(buildLabelText(statuses, 'optional'));
  }, [antiTheftStatus, aebStatus, blindSpotStatus, singleAirbagStatus, doubleAirbagStatus]);

  const leaseLoanAvailable = useMemo(() => {
    // lease loan in all BIX and all GSNIC that isn't our original five
    return (
      deductibleCollision !== 'NONE' &&
      deductibleComprehensive !== 'NONE' &&
      displayLeaseLoan &&
      (!item.brandedTitle || (item.brandedTitle && car.leaseLoanAvailable))
    );
  }, [deductibleCollision, deductibleComprehensive, displayLeaseLoan]);

  const rideshareAvailable = useMemo(() => {
    return (deductibleCollision !== 'NONE' && !item.brandedTitle) || (item.brandedTitle && car.rideshareAvailable);
  }, [deductibleCollision]);

  const roadsideAvailable = useMemo(() => {
    return (deductibleCollision !== 'NONE' && !item.brandedTitle) || (item.brandedTitle && car.roadsideAssistance);
  }, [deductibleCollision]);

  useEffect(() => {
    if (!leaseLoanAvailable) {
      setFieldValue(`${fieldName}.coverageLoan`, false);
    }
  }, [leaseLoanAvailable, fieldName, setFieldValue]);

  // we now show blind spot in all states so we don't have to keep up with which ones have it as a discount
  // (in response to GH issue 10780)
  const blindSpotAvailable = true;

  // policy-level UMPD should block showing UMPD:
  const showCarLevelUMPD = !policyLevelUMPDStates[state] && !noUMPDStates[state];

  const showIncomeLoss = state === 'VA';

  const annualMilesDrivenOptions = useMemo(() => {
    const config = annualMilesDrivenLimits[state];
    if (annualMilesDrivenLimits[state]) {
      const { max, step } = config;
      return [
        {
          id: -1,
          value: 'Unknown'
        },
        ...Array.from({ length: Math.floor(max / step) }, (_, ix) => {
          const upper = (ix + 1) * step;
          const lower = ix ? upper - (step - 1) : upper - step;
          return {
            id: upper,
            value: `${numberFormatter(lower)}-${numberFormatter(upper)}`
          };
        }),
        {
          id: max + 1,
          value: `Over ${numberFormatter(max)}`
        }
      ];
    }
    return null;
  }, [state]);

  return (
    <CollapsibleCard
      content={
        <div className={classes.header}>
          <Label type="infoSubtitle">{getCarHeader(item)}</Label>
          <Label type="infoLabel" className={classes.headerStatus}>
            {getHeaderStatuses}
          </Label>
        </div>
      }
      contentRight={
        <Field
          id={fieldName}
          name={fieldName}
          type="remove"
          disabled={disabled}
          mode="big"
          onClick={() => onRemove(index, removeFromList)}
        />
      }
      initialOpen={item.initialOpen}
      collapsibleCardNotification={
        item?.hasOutstandingRecall
          ? 'This make/model has an outstanding recall. We require proof within 15 days that this has been resolved to keep this vehicle on the policy.'
          : null
      }
    >
      <Form>
        <Grid spacing={4} container justifyContent="space-between" className={classes.cardBody}>
          <Field id={`${fieldName}.VIN`} name={`${fieldName}.VIN`} type="string" label="VIN" mode="light" xs={4} />
          <Field
            id={`${fieldName}.primaryUse`}
            name={`${fieldName}.primaryUse`}
            type="select"
            label="Primary Use"
            mode="light"
            xs={4}
            options={lookupsJson.primaryUse}
          />
          <Field
            id={`${fieldName}.purchaseDate`}
            name={`${fieldName}.purchaseDate`}
            type="date"
            label="Purchase Date"
            mode="light"
            xs={4}
          />
          <GarageLocation fieldName={fieldName} index={index} />
          {canAddCarsManually && <AddCarDetails fieldName={fieldName} classes={classes} state={state} />}
        </Grid>
        <Grid spacing={4} container justifyContent="space-between" className={classes.containerDark} key={state}>
          <Grid item xs={4}>
            <LabelTooltip
              label="Collision Deductible"
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleCollision(state, true) }}
            >
              <Field
                id={`${fieldName}.deductibleCollision`}
                name={`${fieldName}.deductibleCollision`}
                type="select"
                mode="dark"
                disabled={item.brandedTitle && car.deductibleCollision === 'NONE'}
                // how do we change this over to lookupsJson?
                options={
                  item.brandedTitle
                    ? getDisabledOptionsButCurrent(deductibleCollisionOptions, car.deductibleCollision)
                    : deductibleCollisionOptions
                }
              />
            </LabelTooltip>
          </Grid>

          <Grid item xs={4}>
            <LabelTooltip
              label={deductibleComprehensiveLabels[state] || 'Comprehensive Deductible'}
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleComprehensive }}
            >
              <Field
                id={`${fieldName}.deductibleComprehensive`}
                name={`${fieldName}.deductibleComprehensive`}
                type="select"
                mode="dark"
                disabled={item.brandedTitle && car.deductibleComprehensive === 'NONE'}
                // how do we change this over to lookupsJson?
                options={
                  item.brandedTitle
                    ? getDisabledOptionsButCurrent(deductibleComprehensiveOptions, car.deductibleComprehensive)
                    : deductibleComprehensiveOptions
                }
              />
            </LabelTooltip>
          </Grid>

          <Grid item xs={4}>
            <LabelTooltip
              label={limitRentalLabels[state] || 'Rental Car Expense'}
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.limitRental }}
            >
              <Field
                id={`${fieldName}.limitRental`}
                name={`${fieldName}.limitRental`}
                type="select"
                mode="dark"
                disabled={item.brandedTitle && car.limitRental === 'NONE'}
                // how do we change this over to lookupsJson?
                options={
                  item.brandedTitle
                    ? getDisabledOptionsButCurrent(limitRentalOptions, car.limitRental)
                    : limitRentalOptions
                }
              />
            </LabelTooltip>
          </Grid>

          {showCarLevelUMPD && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Uninsured Motorist Property Damage Limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.limitUMPD }}
              >
                <Field
                  id={`${fieldName}.limitUMPD`}
                  name={`${fieldName}.limitUMPD`}
                  type="select"
                  mode="dark"
                  // how do we change this over to lookupsJson?
                  options={getOptions('limitUMPD', state)}
                />
              </LabelTooltip>
            </Grid>
          )}
          <Grid item xs={6}>
            <LabelTooltip
              label={acpeLabels[state] ? `${acpeLabels[state]} COVERAGE` : 'Additional Custom Parts Limit'}
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.additionalCustomPartsLimit }}
            >
              <Field
                id={`${fieldName}.limitACPE`}
                name={`${fieldName}.limitACPE`}
                type="select"
                mode="dark"
                disabled={item.brandedTitle && car.limitACPE === 'NONE'}
                xs={12}
                options={
                  item.brandedTitle ? getDisabledOptionsButCurrent(limitACPEOptions, car.limitACPE) : limitACPEOptions
                }
              />
            </LabelTooltip>
          </Grid>
          {showIncomeLoss && (
            <Field
              id={`${fieldName}.limitIncomeLoss`}
              name={`${fieldName}.limitIncomeLoss`}
              type="select"
              label="Income Loss"
              mode="dark"
              xs={6}
              options={getOptions('limitIncomeLoss', state)}
              disabled={item.brandedTitle && car.limitIncomeLoss === 'NONE'}
              tooltipText={tooltipHoverTexts.incomeLoss}
              tooltipLabel="More Info"
            />
          )}

          {annualMilesDrivenOptions && (
            <Grid item xs={12}>
              <Field
                id={`${fieldName}.annualMilesDriven`}
                name={`${fieldName}.annualMilesDriven`}
                type="select"
                label="Annual Miles Driven"
                mode="dark"
                xs={6}
                options={annualMilesDrivenOptions}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              id={`${fieldName}.roadsideAssistance`}
              name={`${fieldName}.roadsideAssistance`}
              type="checkbox"
              mode="dark"
              label={roadsideLabels[state] || 'With Roadside Assistance Coverage'}
              tooltipText={tooltipHoverTexts.roadsideAssistance}
              tooltipLabel="More Info"
              disabled={!roadsideAvailable}
              fast={false}
            />
          </Grid>

          {showRideShare && (
            <Grid item xs={6}>
              <Field
                id={`${fieldName}.rideSharing`}
                name={`${fieldName}.rideSharing`}
                type="checkbox"
                label="With Rideshare Coverage"
                mode="dark"
                tooltipText={tooltipHoverTexts.rideSharing}
                tooltipLabel="More Info"
                disabled={!rideshareAvailable}
                fast={false}
              />
            </Grid>
          )}

          {displayLeaseLoan && (
            <Grid item xs={6}>
              <Field
                id={`${fieldName}.coverageLoan`}
                name={`${fieldName}.coverageLoan`}
                type="checkbox"
                label="With Lease/Loan Coverage"
                mode="dark"
                disabled={!leaseLoanAvailable}
                fast={false}
                tooltipText={tooltipHoverTexts.coverageLoan}
                tooltipLabel="More Info"
              />
            </Grid>
          )}

          {aebStatus === vehicleEquipmentStatusCode.OPTIONAL && (
            <Field
              id={`${fieldName}.aebConfirmed`}
              name={`${fieldName}.aebConfirmed`}
              type="checkbox"
              label="With factory-installed Automatic Emergency Braking"
              mode="dark"
              xs={6}
            />
          )}
          {antiTheftStatus === vehicleEquipmentStatusCode.OPTIONAL && (
            <Field
              id={`${fieldName}.antiTheftConfirmed`}
              name={`${fieldName}.antiTheftConfirmed`}
              type="checkbox"
              label="With factory-installed Anti-Theft Device"
              mode="dark"
              xs={6}
            />
          )}

          {blindSpotAvailable && blindSpotStatus === vehicleEquipmentStatusCode.OPTIONAL && (
            <Field
              id={`${fieldName}.blindSpotConfirmed`}
              name={`${fieldName}.blindSpotConfirmed`}
              type="checkbox"
              label="With factory-installed Blind Spot Warning System"
              mode="dark"
              xs={6}
            />
          )}
          {item.brandedTitle && (
            <Grid xs={12}>
              <SmallNotification>
                Most coverages for this vehicle are disabled because it only qualifies for liability-only coverage with
                Branch. Our vehicle title data sources indicate that this vehicle was rebuilt, restored, or was a
                manufacturer lemon buyback. If this is inaccurate, please contact support.
              </SmallNotification>
            </Grid>
          )}
        </Grid>
      </Form>
    </CollapsibleCard>
  );
});

Car.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    VIN: PropTypes.string,
    addDate: PropTypes.string,
    bodyType: PropTypes.string,
    confirmed: PropTypes.bool,
    deductibleCollision: PropTypes.string,
    deductibleComprehensive: PropTypes.string,
    coverageLoan: PropTypes.bool,
    garageLocation: PropTypes.object,
    limitACPE: PropTypes.string,
    limitRental: PropTypes.string,
    limitUMPD: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    primaryUse: PropTypes.string,
    purchaseDate: PropTypes.string,
    roadsideAssistance: PropTypes.bool,
    year: PropTypes.number,
    aebStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    antiTheftStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    singleAirbagStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    doubleAirbagStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    aebConfirmed: PropTypes.bool,
    antiTheftConfirmed: PropTypes.bool,
    singleAirbagConfirmed: PropTypes.bool,
    doubleAirbagConfirmed: PropTypes.bool,
    annualMilesDriven: PropTypes.number,
    symbolMake: PropTypes.string,
    symbolModel: PropTypes.string,
    symbolStyle: PropTypes.string,
    symbolAux: PropTypes.string,
    isGarageAddressRuleExceptionApproved: PropTypes.bool,
    hasOutstandingRecall: PropTypes.bool
  }).isRequired,
  index: PropTypes.number.isRequired,
  removeFromList: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

Car.defaultProps = {
  disabled: false
};

export default withDatePicker(memo(Car));
