import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  getOptions,
  HighestEducation,
  windstormMitigationDiscountStates,
  stormShuttersStates,
  enhancedRoofWindstormStates,
  hideHighestEducationStates,
  windstormMitigationDiscountYearBuilt,
  stormShuttersInAdvancedWindstormSectionStates,
  lookupsJson
} from '@ourbranch/lookups';
import classnames from 'classnames';

import { tooltipHoverTexts } from 'core/helpers/constants';
import { DwellingAddress } from 'common/components/dwelling-address';
import { useCurrentState } from 'common/hooks/useCurrentState';
import CollapsibleCard from 'core/components/collapsible-card';
import { FieldWithTooltip } from 'core/components/field-with-tooltip';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Section from 'core/components/section';
import FormList from 'core/components/form-list';
import withDatePicker from 'core/components/with-date-picker';
import { NotificationCard } from 'core/components/notification-card';
import { Tooltip } from 'core/components/tooltip';
import useSession from 'core/hooks/use-session';
import { setPrimaryMortgageValues } from 'offer/helpers/mortgage-helpers';
import AdvancedWindstormDetails from './advanced-windstorm-details/advanced-windstorm-details';
import { StormShutters } from './storm-shutters';
import { AddMortgage, MortgageItem } from '../../mortgage-details';
import useStyles from './detail.styles';

const id = 'home';

const Detail = memo(({ disabled, fromPolicy }) => {
  const classes = useStyles();
  const { canEditUneditablePropertyDetailsFields } = useSession();
  const { values, setFieldValue, errors } = useFormikContext();
  const { home } = values;
  const state = useCurrentState(values);
  const showStormShutters = stormShuttersStates.includes(home.homeLocation.state);
  const showEnhancedRoofWindstorm = enhancedRoofWindstormStates[state];
  const showWindstormMitigationLevel =
    windstormMitigationDiscountStates[state] &&
    (!home.yearBuilt || home.yearBuilt < windstormMitigationDiscountYearBuilt);
  const showAdvancedWindstormDetails = showEnhancedRoofWindstorm || showWindstormMitigationLevel || showStormShutters;
  const showStormShuttersInAdvancedSection =
    stormShuttersStates.includes(state) && stormShuttersInAdvancedWindstormSectionStates.includes(state);
  const showStormShuttersInRoofSection = stormShuttersStates.includes(state) && !showStormShuttersInAdvancedSection;
  const mortgageDetails = home.mortgageDetails;
  const estimatedCost = `${home.replacementCostEstimate.total.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })}`;

  useEffect(() => {
    if (mortgageDetails.length > 0) {
      if (!mortgageDetails.find((item) => item.primary)) {
        const mortgage = mortgageDetails[0];
        setFieldValue(`${id}.mortgageDetails.0`, { ...mortgage, primary: true });
        setPrimaryMortgageValues(setFieldValue, mortgage);
      }
    }
  }, [mortgageDetails, setFieldValue]);

  if (!home) {
    return null;
  }

  return (
    <Section title="Home detail" type="SubSection">
      <CollapsibleCard
        initialOpen
        content={
          <DwellingAddress
            dwelling="Home"
            fieldName="home.homeLocation"
            location={home.homeLocation}
            latitude={home.latitude}
            longitude={home.longitude}
          />
        }
      >
        <Grid container justifyContent="space-around" className={classes.containerDark}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Residents</Label>
            </Grid>
            <FormField
              name={`${id}.ownershipStatus`}
              type="select"
              label="Ownership"
              mode="light"
              options={getOptions('ownershipStatus')}
              xs={6}
            />
            <FormField
              name={`${id}.purchaseDate`}
              type="date"
              label="House Purchased"
              mode="light"
              xs={3}
              disableFuture={false}
              maxDate={addDays(new Date(), 60)}
            />
            <FormField
              name={`${id}.numOccupants`}
              type="numeric"
              label="Residents"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            {!hideHighestEducationStates.includes(state) && (
              <FormField
                name="highestEducation"
                type="value"
                label="Highest Education of App/Co-App"
                value={HighestEducation[values.highestEducation] || 'N/A'}
                xs={6}
              />
            )}
            <FormField
              name="oldestResident"
              type="value"
              label="Age of Oldest App/Co-App"
              value={values.oldestResident || 'N/A'}
              xs={hideHighestEducationStates.includes(state) ? 12 : 6}
            />
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Property</Label>
            </Grid>
            {canEditUneditablePropertyDetailsFields ? (
              <>
                <FormField
                  name={`${id}.yearBuilt`}
                  type="numeric"
                  label="Year built in"
                  mode="light"
                  format={{
                    allowNegative: false
                  }}
                  xs={4}
                />
                <FormField
                  name={`${id}.sqFt`}
                  type="numeric"
                  label="Above-Ground SqFt"
                  mode="light"
                  format={{
                    allowNegative: false
                  }}
                  xs={4}
                />
                <FormField
                  name={`${id}.basementSqFt`}
                  type="numeric"
                  label="Basement SqFt"
                  mode="light"
                  format={{
                    allowNegative: false
                  }}
                  xs={4}
                />
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <FieldWithTooltip
                    name={`${id}.yearBuilt`}
                    label="Year built in"
                    mode="light"
                    tooltip={tooltipHoverTexts.uneditableFieldsTooltipText}
                    labelNextToTooltip
                    type="value"
                  />
                </Grid>
                <Grid item xs={4} className={classnames({ [classes.sqFtErrorSpacing]: errors?.home?.sqFt })}>
                  <FieldWithTooltip
                    name={`${id}.sqFt`}
                    type="numeric"
                    label="Above-Ground SqFt"
                    mode="light"
                    format={{
                      allowNegative: false
                    }}
                    tooltip={tooltipHoverTexts.squareFtTooltipText}
                  />
                </Grid>
                <Grid item xs={4} className={classnames({ [classes.sqFtErrorSpacing]: errors?.home?.basementSqFt })}>
                  <FieldWithTooltip
                    name={`${id}.basementSqFt`}
                    type="numeric"
                    label="Basement SqFt"
                    mode="light"
                    format={{
                      allowNegative: false
                    }}
                    tooltip={tooltipHoverTexts.squareFtTooltipText}
                  />
                </Grid>
              </>
            )}
            <FormField
              name={`${id}.typeOfHome`}
              type="select"
              label="Home Type"
              mode="light"
              options={getOptions('typeOfHome')}
              xs={4}
            />
            <FormField
              name={`${id}.numFamiliesInDwelling`}
              type="numeric"
              label="Families in Dwelling"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.homeQuality`}
              type="select"
              label="Home Quality"
              mode="light"
              options={getOptions('homeQuality')}
              xs={4}
            />
            <FormField
              name={`${id}.numStories`}
              type="numeric"
              label="Number of Stories"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.numHalfBathrooms`}
              type="numeric"
              label="Number of Half Bathrooms"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.numFullBathrooms`}
              type="numeric"
              label="Number of Full Bathrooms"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            {canEditUneditablePropertyDetailsFields ? (
              <FormField
                name={`${id}.constructionType`}
                type="select"
                label="Construction Type"
                mode="light"
                options={getOptions('constructionType')}
                xs={3}
              />
            ) : (
              <Grid item xs={3}>
                <FieldWithTooltip
                  name={`${id}.constructionType`}
                  label="Construction Type"
                  mode="light"
                  tooltip={tooltipHoverTexts.uneditableFieldsTooltipText}
                  type="value"
                  formatter={(value) => lookupsJson.constructionType.find(({ id }) => id === value).value}
                />
              </Grid>
            )}
            <FormField
              name={`${id}.numFireplaces`}
              type="numeric"
              label="Number of Fireplaces"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numWoodBurningStoves`}
              type="numeric"
              label="Number of Wood Stoves"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.basementType`}
              type="select"
              label="Basement Type"
              mode="light"
              options={getOptions('basementType')}
              xs={4}
            />
            <FormField
              name={`${id}.exteriorWallType`}
              type="select"
              label="Exterior Wall Type"
              mode="light"
              options={getOptions('exteriorWallType')}
              xs={4}
            />
            <FormField
              name={`${id}.garageCarCapacity`}
              type="numeric"
              label="Garage's car space"
              mode="light"
              format={{
                allowNegative: false
              }}
              disabled={home.garageType === 'X'}
              xs={4}
            />
            <FormField
              name={`${id}.garageType`}
              type="select"
              label="Garage Type"
              mode="light"
              options={getOptions('garageType')}
              xs={4}
            />
            <FormField
              name={`${id}.roofYear`}
              type="numeric"
              label="Roof Year"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.roofType`}
              type="select"
              label="Roof Type"
              mode="light"
              options={getOptions('roofType')}
              xs={4}
            />
            {canEditUneditablePropertyDetailsFields ? (
              <FormField
                name={`${id}.roofShape`}
                type="select"
                label="Roof Shape"
                mode="light"
                options={getOptions('roofShape')}
                xs={4}
              />
            ) : (
              <Grid item xs={3}>
                <FieldWithTooltip
                  name={`${id}.roofShape`}
                  label="Roof Shape"
                  mode="light"
                  options={getOptions('roofShape')}
                  tooltip={tooltipHoverTexts.uneditableFieldsTooltipText}
                  labelNextToTooltip
                  type="value"
                  formatter={(value) => lookupsJson.roofShape.find(({ id }) => id === value).value}
                />
              </Grid>
            )}
            {showStormShuttersInRoofSection && <StormShutters />}
            {showStormShuttersInRoofSection && showAdvancedWindstormDetails && (
              <AdvancedWindstormDetails fromPolicy={fromPolicy} />
            )}
            {home.fpcFromLocation?.includes('/') && (
              <FormField
                name={`${id}.fireHydrantWithin1000ft`}
                id={`${id}.fireHydrantWithin1000ft`}
                type="checkbox"
                label="Fire hydrant within 1,000 feet?"
                mode="light"
                xs={12}
              />
            )}
            <FormField
              name={`${id}.fpc`}
              type="value"
              label="Fire Protection Class (non-editable)"
              value={home.fpc || 'N/A'}
              xs={6}
            />
            <FormField
              name={`${id}.wildfireHazardScore`}
              type="value"
              label="Wildfire Hazard Score (non-editable)"
              value={home.wildfireHazardScore || 'N/A'}
              xs={6}
            />
            <FormField
              name={`${id}.dtoName`}
              type="value"
              label="Distance to Ocean (non-editable)"
              value={home.dtoName || 'N/A'}
              xs={4}
            />
            <FormField
              name={`${id}.dtbowLabel`}
              type="value"
              label="Nearest BOW (non-editable)"
              value={home.dtbowLabel || 'N/A'}
              xs={4}
            />
            <FormField
              name={`${id}.dtbowName`}
              type="value"
              label="Distance to BOW (non-editable)"
              value={home.dtbowName || 'N/A'}
              xs={4}
            />
            {!showStormShuttersInRoofSection && showAdvancedWindstormDetails && (
              <AdvancedWindstormDetails fromPolicy={fromPolicy} />
            )}

            <NotificationCard className={classes.notification} iconSize="icon16" type="infoGreen">
              <Grid container justifyContent="space-between">
                <Grid container sm={8} className={classes.rceValues} alignContent="center">
                  <Label>Our estimated cost to replace this home</Label>
                  <Tooltip label="More info" text={tooltipHoverTexts.rceText} />
                </Grid>
                <Grid container sm={4} justifyContent="flex-end" className={classes.rceValues}>
                  <Label>RCE</Label>
                  <Label type="infoValueBold">{estimatedCost || 'N/A'}</Label>
                </Grid>
              </Grid>
            </NotificationCard>
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.mortgagesContainer}>
          <FormList
            classes={{ container: classes.container, rightValue: classes.sectionRightValue }}
            disabled={disabled}
            id={`${id}.mortgageDetails`}
            item={MortgageItem}
            type="InnerSection"
            addForm={AddMortgage}
            title="Mortgage lender details"
            singular="Mortgage"
            plural="Mortgages"
          />
        </Grid>
      </CollapsibleCard>
    </Section>
  );
});

Detail.propTypes = {
  disabled: PropTypes.bool,
  fromPolicy: PropTypes.bool
};

Detail.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default withDatePicker(Detail);
