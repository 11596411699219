/* eslint-disable func-names */
import * as Yup from 'yup';
import {
  UMPDOnlyWithUMBIStates,
  allow50100UMBIon2550BIStates,
  combinedUMUIM,
  doNotShowCarLevelUMPDStates,
  driverCarAssignmentStates,
  noUDRsAllowedStates,
  policyLevelUMPDStates,
  preBindMVRStates,
  canRejectPIPStates,
  inexperiencedYearsLimit
} from '@ourbranch/lookups';
import { differenceInYears } from 'date-fns';

import { requiredString, validUSState } from 'common/helpers/yup-helpers';
import { driversLicenseNumberSchema } from 'core/helpers/drivers-license-number-validations';
import { getPLimitValue, getUMPDValue, isLimitACPEValid } from 'core/helpers/car-validations';
import { hasMakeModelStyleSymbols, haveAnyOfThisCarsSymbolsChanged } from 'common/helpers/car-symbols-helpers';
import { checkZipCodeRange } from 'common/helpers/address-helpers';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

const autoSchema = (state, session) =>
  Yup.object().shape({
    autoCoverage: Yup.object().shape({
      policyLimitUMBI: Yup.string()
        .test('umbi', 'UMBI cannot be higher than BIPD', function (ob) {
          const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};
          if (
            !policyLimitUMBI ||
            !policyLimitBIPD ||
            (allow50100UMBIon2550BIStates[this.options.context?.correctedAddress?.state] &&
              policyLimitUMBI === '50/100')
          ) {
            return true;
          }

          const valueUMBI = policyLimitUMBI.includes('/')
            ? Number(policyLimitUMBI.split('/')[0])
            : Number(policyLimitUMBI.split(' ')[0]);
          const valueBIPD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[0])
            : Number(policyLimitBIPD.split(' ')[0]);

          return valueBIPD >= valueUMBI;
        })
        .test('umbiCSL', 'BI cannot be CSL unless policy UM/UIM BI is also CSL.', function () {
          const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};

          const combinedUMUIMState = combinedUMUIM[this.options.context?.correctedAddress?.state];
          if (!combinedUMUIMState) {
            return true;
          }
          const umbiCSL = policyLimitUMBI.includes('CSL');
          const bipdCSL = policyLimitBIPD.includes('CSL');

          return policyLimitUMBI === '0/0' || umbiCSL === bipdCSL;
        })
        .test('FL_UM/UIMBI_must_match_BIPD', 'UM/UIMBI must match BIPD limits', function (val) {
          const { policyLimitBIPD } = this.options.context?.autoCoverage || {};
          if (this.options.context?.correctedAddress?.state !== 'FL' || val === '0/0') {
            return true;
          } // FL UMBI is in this format 10/20 N, but BIPD is in this format 10/20/10
          return policyLimitBIPD.includes(val.split(' ')[0]);
        }),
      policyLimitUIMBI: Yup.string()
        .test('uimbi', 'UIMBI cannot be higher than BIPD', function (obj) {
          const { policyLimitUIMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};

          if (combinedUMUIM[this.options.context?.correctedAddress?.state] || !policyLimitUIMBI || !policyLimitBIPD) {
            return true;
          }

          const valueUIMBI = policyLimitUIMBI.includes('/')
            ? Number(policyLimitUIMBI.split('/')[0])
            : Number(policyLimitUIMBI.split(' ')[0]);
          const valueBIPD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[0])
            : Number(policyLimitBIPD.split(' ')[0]);
          return valueBIPD >= valueUIMBI;
        })
        .test('uimbiCSL', 'UIM BI cannot be CSL unless policy UM BI and BI/PD are also CSL.', function () {
          const { policyLimitBIPD, policyLimitUIMBI } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          const combinedUMUIMState = combinedUMUIM[this.options.context?.correctedAddress?.state];
          if (combinedUMUIMState || !hasAutoPolicy) {
            return true;
          }
          const bipdCSL = policyLimitBIPD.includes('CSL');
          const uimbiCSL = policyLimitUIMBI.includes('CSL');
          return policyLimitUIMBI === '0/0' || bipdCSL === uimbiCSL;
        }),
      policyLimitUMPD: Yup.string()
        .test('UMPDMustBeLowerThanPD', 'UMPD cannot be higher than PD', function (ob) {
          const { policyLimitBIPD, policyLimitUMPD } = this.options.context?.autoCoverage || {};
          const state = this.options.context.correctedAddress?.state;

          if (!policyLimitBIPD || !policyLevelUMPDStates[state]) {
            return true;
          }

          if (this.options.context?.correctedAddress?.state === 'NM') {
            // in NM, we just have a UMPD = PD limit option, dont run validation
            return true;
          }

          const valuePD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[2])
            : Number(policyLimitBIPD.split(' ')[0]);

          const valueUMPD = parseFloat(policyLimitUMPD === 'NONE' ? '0' : policyLimitUMPD.split('/')[0]) / 1000;

          return valuePD >= valueUMPD;
        })
        .test('UMPDMustHaveUMBIInThiState', 'UMPD cannot be chosen in this state without UMBI', function (value) {
          const { policyLimitUMBI, policyLimitUMPD } = this.options.context?.autoCoverage || {};
          if (!UMPDOnlyWithUMBIStates[this.options.context?.correctedAddress?.state]) {
            return true;
          }

          const valueUMBI = policyLimitUMBI.includes('/')
            ? Number(policyLimitUMBI.split('/')[0])
            : Number(policyLimitUMBI.split(' ')[0]);

          const valueUMPD = Number(policyLimitUMPD === 'NONE' ? '0' : policyLimitUMPD) / 1000;

          return !(valueUMBI === 0 && valueUMPD > 0);
        }),

      policyLimitPIPME: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      policyLimitPIPACR: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      policyLimitMedicalPayments: Yup.string()
        .test('medicalPaymentsOrComboFBP', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'PA') {
            const { policyLimitComboFBP } = this.parent;
            if (policyLimitComboFBP && policyLimitComboFBP !== 'NONE' && value !== 'NONE') {
              return this.createError({
                message: 'Can not choose both this and combined first party benefits'
              });
            }
            if (policyLimitComboFBP && policyLimitComboFBP === 'NONE' && value === 'NONE') {
              return this.createError({
                message: 'Choose medical payments or combined first party benefits'
              });
            }
          }
          return true;
        })
        .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and PIP in TX', function (value) {
          if (this.options.context?.correctedAddress?.state === 'TX') {
            const { policyLimitPIP } = this.parent;
            if (value !== 'NONE' && policyLimitPIP && policyLimitPIP !== 'NONE') {
              return false;
            }
            return true;
          }
          return true;
        })
        .nullable(),
      policyLimitComboFBP: Yup.string()
        .test('medicalPaymentsOrComboFBP', 'required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'PA') {
            const { policyLimitMedicalPayments } = this.parent;
            if (policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE' && value !== 'NONE') {
              return this.createError({
                message: 'Can not choose both this and medical payments'
              });
            }
            if (policyLimitMedicalPayments && policyLimitMedicalPayments === 'NONE' && value === 'NONE') {
              return this.createError({
                message: 'Choose medical payments or combined first party benefits'
              });
            }
          }
          return true;
        })
        .nullable(),
      policyLimitPIP: Yup.string()
        .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and MedPay in TX', function (value) {
          if (this.options.context?.correctedAddress?.state === 'TX') {
            const { policyLimitMedicalPayments } = this.parent;
            if (value !== 'NONE' && policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE') {
              return false;
            }
            return true;
          }
          return true;
        })
        .test(
          'policyLimitNoFaultPIPIncluded',
          'You must reject PIP coverage in order to opt out of PIP',
          function (value) {
            if (canRejectPIPStates.includes(this.options.context?.correctedAddress?.state)) {
              const { policyLimitNoFaultPIP } = this.parent;
              if (value === 'NONE' && policyLimitNoFaultPIP && policyLimitNoFaultPIP === 'INCLUDED') {
                return false;
              }
              return true;
            }
            return true;
          }
        )
        .test(
          'policyLimitNoFaultPIPNone',
          'PIP coverage must be set to "No Coverage" if PIP is rejected',
          function (value) {
            if (canRejectPIPStates.includes(this.options.context?.correctedAddress?.state)) {
              const { policyLimitNoFaultPIP } = this.parent;
              if (value !== 'NONE' && policyLimitNoFaultPIP && policyLimitNoFaultPIP === 'NONE') {
                return false;
              }
              return true;
            }
            return true;
          }
        )
        .test(
          'numberOfHouseholdMembersMustbeGreaterThan2ToHaveAllApplicants',
          'PIP Deductible must be applied to “Applicant Only” if there is only 1 household resident',
          function (value) {
            if (this.options.context?.correctedAddress?.state === 'FL') {
              const { householdMembers } = this.options.context.auto;
              // NI === Applicant Only / Named Insured
              if (!value.includes('NI') && householdMembers === 1) {
                return false;
              }
              return true;
            }
            return true;
          }
        )
        .nullable()
    }),
    trailers: Yup.array(
      Yup.object().shape({
        VIN: Yup.string().requiredString('VIN is required'),
        type: Yup.string().requiredString('Trailer type is required'),
        year: Yup.number().typeError('Trailer year should be a number').required('Trailer year is required'),
        value: Yup.number()
          .typeError('Trailer value should be a number between $0 - $50000')
          .positive('Trailer value should be between $0 - $50000')
          .integer('Trailer value should be between $0 - $50000')
          .min(1, 'Trailer value should be greater than $0')
          .max(50000, 'Trailer value should be less than or equal to $50000')
          .when(
            ['deductibleCollision', 'deductibleComprehensive'],
            (deductibleCollision, deductibleComprehensive, schema) => {
              const validValues = [deductibleCollision.split('/')[0], deductibleComprehensive.split('/')[0]].filter(
                (value) => value !== 'NONE' && value
              );

              if (!validValues.length) return schema;
              const minValue = Math.min(...validValues.map((value) => value.split('/')[0]));

              return schema.min(500 + minValue, `Trailer value should be greater than ${500 + minValue}`);
            }
          )
          .required('Trailer value is required'),
        contents: Yup.number()
          .typeError('Content value should be a number between $0 - $5000')
          .positive('Content value should be between $0 - $5000')
          .integer('Content value should be between $0 - $5000')
          .min(0, 'Content value should be greater than $0')
          .max(5000, 'Content value should be less than or equal to $5000')
          .required('Trailer content value is required'),

        deductibleCollision: Yup.string()
          .requiredString('Collision deductible is required')
          .test(
            'minimumCollDeductibleMet',
            'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
            function (currentTrailerDeductibleCollision, { options }) {
              const { context, parent } = options;
              if (currentTrailerDeductibleCollision === 'NONE' && parent.deductibleComprehensive === 'NONE')
                return true;
              const { cars } = context;
              for (const car of cars) {
                if (
                  car.deductibleCollision === currentTrailerDeductibleCollision &&
                  car.deductibleComprehensive.split('/')[0] === parent.deductibleComprehensive.split('/')[0]
                ) {
                  return true;
                }
              }
              return false;
            }
          ),

        deductibleComprehensive: Yup.string()
          .requiredString('Comprehensive deductible is required')
          .test(
            'minimumCompDeductibleMet',
            'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
            function (currentTrailerDeductibleComprehensive, { options }) {
              const { context, parent } = options;
              if (currentTrailerDeductibleComprehensive === 'NONE' && parent.deductibleCollision === 'NONE')
                return true;
              const { cars } = context;
              for (const car of cars) {
                if (
                  car.deductibleComprehensive.split('/')[0] === currentTrailerDeductibleComprehensive.split('/')[0] &&
                  car.deductibleCollision === parent.deductibleCollision
                ) {
                  return true;
                }
              }
              return false;
            }
          ),
        garageLocation: Yup.object().shape({
          address: Yup.string()
            .requiredString('Address is required')
            .test('is-po-box', 'PO Box Addresses are invalid', (value) => {
              const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
              return !(pattern.test(value) || pattern.test(value));
            }),
          city: Yup.string().requiredString('City is required'),
          state: Yup.string().requiredString('State is required').validUSState('offer.trailers.garageLocation'),
          zip: Yup.string().requiredString('Zip code is required').min(5, 'Zip code must be 5 digits')
        })
      })
    ).nullable(),
    drivers: Yup.array()
      .of(
        Yup.object().shape({
          driversLicenseNumber: Yup.string()
            .test(
              'driversLicenseRequired',
              'Drivers license is required for this state',
              function (val, { options: { context, parent } }) {
                if (preBindMVRStates.includes(context.correctedAddress.state) && parent.postBindMVR) {
                  return !!val;
                }
                return true;
              }
            )
            .concat(driversLicenseNumberSchema)
            .required('Drivers license number is required')
            .nullable(),
          driversLicenseState: Yup.string().validUSState('offer.drivers.driverLicenseState'),
          schoolName: Yup.string()
            .when('fullTimeStudent', {
              is: (fullTimeStudent) => fullTimeStudent === true,
              then: Yup.string().requiredString('School Name is required')
            })
            .nullable(),

          schoolLocation: Yup.object()
            .when('fullTimeStudent', {
              is: (fullTimeStudent) => fullTimeStudent === true,
              then: Yup.object().shape({
                address: Yup.string().requiredString('School Address is required'),
                city: Yup.string().requiredString('School City is required'),
                state: Yup.string().requiredString('School State is required'),
                zip: Yup.string().requiredString('School Zip code is required').min(5, 'Zip code must be 5 digits')
              })
            })
            .nullable(),

          autoViolations: Yup.object().test(
            'hasUDR',
            "There are drivers on this offer that have an unverified driving record (UDR), and we do not allow that in this state. Please drop the drivers and re-add with Driver's License information to pull driving records.",
            function (obj) {
              if (noUDRsAllowedStates.includes(this.options.context?.correctedAddress.state)) {
                return obj.UDR === 0;
              }
              return true;
            }
          ),
          assignedCar: Yup.mixed().test('assignedCar', 'Each driver must be assigned to one car', function (value) {
            if (
              driverCarAssignmentStates[this.options.context?.correctedAddress?.state] &&
              this.options.context?.correctedAddress?.state !== 'MA'
            ) {
              if (!value) {
                return this.createError({
                  message: 'All drivers must be assigned a primary vehicle'
                });
              }
              const cars = this.options.context.cars;
              if (value && !cars.some((car) => car.VIN === value)) {
                return this.createError({
                  message: 'Driver assigned VIN that does not exist '
                });
              }
              const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
              const assignedDrivers = cars.map((car) => car.assignedDriver);

              if (drivers.length === cars.length) {
                const driversSet = new Set(assignedDrivers);
                // assert that each driver assigned exactly once
                return driversSet.size === assignedDrivers.length && drivers.length === driversSet.size
                  ? true
                  : this.createError({
                      message: 'Each driver must be assigned to one car'
                    });
              }

              return true;
            }
            return true;
          }),
          userAssignedCar: Yup.mixed().test('userAssignedCar', 'This car already has a main driver', function (value) {
            if (
              driverCarAssignmentStates[this.options.context?.correctedAddress?.state] &&
              this.options.context?.correctedAddress?.state === 'MA'
            ) {
              if (!value || value === 'NONE') {
                return true;
              }
              const cars = this.options.context.cars;
              if (value && !cars.some((car) => car.VIN === value)) {
                return this.createError({
                  message: 'Driver assigned VIN that does not exist '
                });
              }
              const inexperiencedDrivers = this.options.context.drivers.filter((driver) => {
                let age = driver.age;
                if (!age) {
                  const today = new Date();
                  const birthDate = new Date(driver.dateOfBirth);
                  age = differenceInYears(today, birthDate);
                }
                return driver.age - driver.ageFirstLicensed < inexperiencedYearsLimit[this.options.context.state];
              });

              if (inexperiencedDrivers.filter((d) => d.userAssignedCar === value).length > 1) {
                return this.createError({
                  message: 'This car already has a main driver'
                });
              }
            }
            return true;
          })
        })
      )
      .nullable(),
    cars: Yup.array()
      .of(
        Yup.object().shape({
          make: Yup.string().test(
            'make-must-not-start-with-an-asterisk',
            'This car entry was made by a comparative rater without a valid VIN. Please add a new car with the correct VIN and remove the car with the invalid VIN to continue.',
            (value) => {
              return !String(value).startsWith('*');
            }
          ),
          garageLocation: Yup.object().shape({
            state: Yup.string()
              .validUSState('offer.cars.garageLocation.state')
              .test('garage-state-offer-test', function (value) {
                // eslint-disable-next-line no-unused-vars
                const [_, currentCar] = this.from;
                const { isGarageAddressRuleExceptionApproved } = currentCar.value;
                if (isGarageAddressRuleExceptionApproved) {
                  return true;
                }

                if (value !== this.options?.context.state) {
                  const isInternalOrExternalAgent = session.isInternalSales || session.isExternalSales;
                  return this.createError({
                    message: `Garage address must be in the same state as the offer.${
                      isInternalOrExternalAgent
                        ? ' If an exception needs to be made, please follow your appropriate process for escalating to a Branch underwriting representative.'
                        : ''
                    }`
                  });
                }
                return true;
              }),
            zip: Yup.string()
              .nullable()
              .min(5, 'Zip code must be 5 digits')
              .test('garage-zip-offer-test', function (value) {
                // eslint-disable-next-line no-unused-vars
                const [_, currentCar] = this.from;
                const { isGarageAddressRuleExceptionApproved } = currentCar.value;
                if (isGarageAddressRuleExceptionApproved) {
                  return true;
                }

                const isValidZipCode = checkZipCodeRange(value, this?.options?.context?.state);
                if (!isValidZipCode) {
                  const isInternalOrExternalAgent = session.isInternalSales || session.isExternalSales;
                  return this.createError({
                    message: `Garage address must be in the valid zip code range of the offer state.${
                      isInternalOrExternalAgent
                        ? ' If an exception needs to be made, please follow your appropriate process for escalating to a Branch underwriting representative.'
                        : ''
                    }`
                  });
                }
                return true;
              })
          }),
          limitUMPD: Yup.mixed()
            .nullable()
            .test(
              'limitUMPDMustBeLowerThanPLimitValue',
              "Error, this can't be higher than property damage limit",
              function (value) {
                const state = this.options.context?.correctedAddress?.state;

                if (state === 'NM' || doNotShowCarLevelUMPDStates[state]) {
                  // in NM, we just have a UMPD = PD limit option and shouldn't run this check.
                  // or we don't have UMPD in this state or car level UMPD in this state
                  // or not selecting auto
                  // so no need to validate
                  return true;
                }
                const { autoCoverage } = this.options.context;
                return getUMPDValue(value) <= getPLimitValue(autoCoverage.policyLimitBIPD);
              }
            )
            .test(
              'noLimitUMPDWithoutUMBI',
              'Error, UMPD cannot be chosen in this state without UMBI',
              function (value) {
                const state = this.options.context?.correctedAddress.state;
                if (doNotShowCarLevelUMPDStates[state]) {
                  // we don't have UMPD in this state, or car level UMPD in this state, or not selecting Auto so don't run this check
                  return true;
                }
                const { autoCoverage } = this.options.context;
                return !(
                  value !== 'NONE' &&
                  UMPDOnlyWithUMBIStates[state] &&
                  (autoCoverage.policyLimitUMBI?.startsWith('0/0') || autoCoverage.policyLimitUMBI === 'NONE')
                );
              }
            ),
          assignedDriver: Yup.mixed().test(
            'assignedDriver',
            'A driver may only be assigned to one car',
            function (value) {
              if (
                driverCarAssignmentStates[this.options.context?.correctedAddress?.state] &&
                this.options.context?.correctedAddress?.state !== 'MA'
              ) {
                if (!value) {
                  return this.createError({
                    message: 'All cars must be assigned a driver'
                  });
                }
                const cars = this.options.context.cars;
                const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
                const assignedDrivers = cars.map((car) => car.assignedDriver);
                const driversSet = new Set(assignedDrivers);

                if (drivers.length <= cars.length) {
                  return drivers.length === driversSet.size
                    ? true
                    : this.createError({
                        message: 'Every driver must be assigned to a car'
                      });
                }
                if (drivers.length > cars.length) {
                  return cars.length <= driversSet.size
                    ? true
                    : this.createError({
                        message: 'A driver may only be assigned to one car'
                      });
                }

                return true;
              }
              return true;
            }
          ),
          symbolMake: Yup.string()
            .test(
              'symbolMake-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (state === 'FL') {
                  return true;
                }
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolAux: Yup.string()
            .test(
              'symbolAux-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (state === 'FL') {
                  return true;
                }
                if (hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolModel: Yup.string()
            .test(
              'symbolModel-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (state === 'FL') {
                  return true;
                }
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolStyle: Yup.string()
            .test(
              'symbolStyle-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (state === 'FL') {
                  return true;
                }
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolPGS: Yup.string()
            .test(
              'symbolPGS-length-is-1',
              'Must be exactly 1 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (state !== 'FL') {
                  return true;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 1 : true;
              }
            )
            .nullable(),
          symbolBI: Yup.number()
            .test('symbolBI-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolPD: Yup.number()
            .test('symbolPD-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolMed: Yup.number()
            .test('symbolMed-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolPIP: Yup.number()
            .test('symbolStyle-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolColl: Yup.number()
            .test('symbolCOLL-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolComp: Yup.number()
            .test('symbolCOMP-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          symbolUM: Yup.number()
            .test('symbolUMUIM-length-is-required', 'Required', (value, { options: { context, parent } }) => {
              if (!context.canAddCarsManually) return true;
              if (state !== 'FL') {
                return true;
              }
              return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length >= 0 : true;
            })
            .nullable(),
          limitACPE: Yup.string().test(
            'Invalid',
            'To add Additional Custom Parts Limit, Collision and Comprehensive Deductibles are required',
            function (value) {
              return isLimitACPEValid(value, this.parent);
            }
          )
        })
      )
      .test(
        'garage-state-test',
        'Garage address must be in the same state as the home address for at least one vehicle',
        function (cars) {
          const state = this.options.context?.correctedAddress.state;
          if (cars.length > 0) {
            return cars.some((car) => car.garageLocation.state === state);
          }
          return true;
        }
      )
      .min(
        1,
        'Please add a car before updating this offer. If there are no cars to insure, please remove auto from the policy type. Branch does not insure auto policies without cars.'
      )
      .nullable(),
    auto: Yup.object().shape({
      householdMembers: Yup.number()
        .test(
          'FL_household_members_must_be_equal_or_greater_than_drivers',
          'The number of household members cannot be lower than the number of active drivers on the policy.',
          function (value) {
            if (this.options.context?.correctedAddress.state === 'FL') {
              const drivers = this.options.context.drivers.filter(
                (driver) => !(driver?.schoolLocation && driver.schoolLocation.address)
              );

              return !((value || 0) < drivers.length);
            }
            return true;
          }
        )
        .nullable()
    })
  });

export default autoSchema;
