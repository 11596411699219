import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';

import { useDisabledState } from 'common/disabled-context';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import { SchedulePP } from 'common/components/schedule-pp';
import { Umbrella } from 'common/components/umbrella';
import FormList from 'core/components/form-list';
import AddApplicant from 'common/components/people/add-form';
import { Detail } from 'common/components/condo/detail';
import { CondoCoverage } from 'common/components/condo/condo-coverage';
import { AutoInformation } from 'common/components/condo/auto-information';
import Applicant from '../applicant/applicant';
import Discounts from '../../discounts';

function CondoPolicy() {
  const { disabled } = useDisabledState();
  const session = useContext(AuthContext);
  const {
    account: {
      policies: {
        list,
        policy: { getAllowLicensedActions, policy }
      }
    }
  } = useStore();
  const { values } = useFormikContext();
  const {
    condoCoverage: { coverageX, coverageC },
    condo: {
      condoLocation: { state }
    }
  } = values;
  const allowLicensedActions = getAllowLicensedActions(session);

  const hasAuto = useMemo(() => !!list.find((p) => p.id === policy.id.replace('condo', 'auto')), [policy.id]);

  const sppLimits = {
    tenPctOfCoverageC: coverageC * 0.1,
    fiftyPctOfCoverageC: coverageC * 0.5
  };

  return (
    <>
      <FormList
        disabled={disabled || !allowLicensedActions}
        id="people"
        title="People"
        item={Applicant}
        addForm={AddApplicant}
        addLabel="Add People"
        fromPolicy
      />
      <Detail disabled={disabled} />
      {!hasAuto && <AutoInformation disabled={disabled} />}
      <CondoCoverage disabled={disabled} fromPolicy />
      <SchedulePP disabled={disabled} sppLimits={sppLimits} />
      <Umbrella disabled={disabled} coverageX={coverageX} state={state} fromPolicy />
      <Discounts disabled={disabled} />
    </>
  );
}

export default observer(CondoPolicy);
