import React from 'react';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';

export const tools = [
  {
    category: 'Affinity tasks',
    items: [
      {
        name: 'Affinity Dashboards',
        path: '/staff/tools/dashboards/affinities-tools',
        description: 'List, add and update affinities groups and agency information',
        permissionName: CognitoPermissionGroups.canViewAffinityTools,
        component: React.lazy(() => import('../dashboards/affinities-tools/affinities-tools'))
      },
      {
        name: 'Bulk Update Agency Information',
        path: '/staff/tools/agency/bulk-update-agency-info',
        description: 'Update agency information via csv',
        permissionName: CognitoPermissionGroups.canViewAffinityTools,
        component: React.lazy(() => import('../tasks/agency/bulk-update-agency-info')),
        toolName: 'bulkUpdateAgencyInformation',
        requiredCSVHeaders: ['code']
      },
      {
        name: 'Bulk process affinities from branch_agent_sync BQ table',
        path: '/staff/tools/agency/bulk-affinities-branch-agent-sync',
        description: `Agent Sync Data from Big Query exported csv table 'branch_agent_sync'`,
        permissionName: CognitoPermissionGroups.canViewAffinityTools,
        component: React.lazy(() => import('../tasks/agency/bulk-create-affinities-from-branch-agent-sync-bq')),
        toolName: 'bulkProcessAffinitiesFromBranchAgentSyncBq'
      },
      {
        name: 'Bulk process affinities from branch_agent_sync_contact BQ table',
        path: '/staff/tools/agency/bulk-affinities-branch-agent-sync-contact',
        description: `Agent Sync Data from Big Query exported csv table 'branch_agent_sync'`,
        permissionName: CognitoPermissionGroups.canViewAffinityTools,
        component: React.lazy(() => import('../tasks/agency/bulk-create-affinities-from-branch-agent-sync-contact-bq')),
        toolName: 'bulkProcessAffinitiesFromBranchAgentSyncContactBq'
      },
      {
        name: 'Disable affinities',
        path: '/staff/tools/agency/disable-affinities',
        description: `List of one or more affinity codes to disable`,
        permissionName: CognitoPermissionGroups.canViewAffinityTools,
        component: React.lazy(() => import('../tasks/agency/disable-affinities')),
        toolName: 'toolsDisableAffinities'
      },
      {
        name: 'Enable affinities',
        path: '/staff/tools/agency/enable-affinities',
        description: `List of one or more affinity codes to enable`,
        permissionName: CognitoPermissionGroups.canViewAffinityTools,
        component: React.lazy(() => import('../tasks/agency/enable-affinities')),
        toolName: 'toolsEnableAffinities'
      }
    ]
  },
  {
    category: 'Agency',
    items: [
      {
        name: 'Import Policies to IVANS',
        path: '/staff/tools/agency/import-policies-to-ivans',
        description: 'Import policies to IVANS',
        permissionName: CognitoPermissionGroups.canImportPoliciesToIvans,
        component: React.lazy(() => import('../tasks/agency/import-policies-to-ivans')),
        toolName: 'toolsImportPoliciesToIvans'
      },
      {
        name: 'Get Ivans Formatted Policy',
        path: '/staff/tools/agency/get-ivans-formatted-policy',
        description: 'Get Policy in IVANS format',
        permissionName: CognitoPermissionGroups.canGetIvansFormattedPolicy,
        component: React.lazy(() => import('../tasks/agency/get-ivans-formatted-policy')),
        toolName: 'toolsGetIvansFormattedPolicy'
      }
    ]
  },
  {
    category: 'Staff Users Management',
    items: [
      {
        name: 'Staff Users Dashboard',
        path: '/staff/tools/dashboards/staff-users',
        description: 'View Staff users and their details',
        permissionName: CognitoPermissionGroups.canViewStaffDashboard,
        component: React.lazy(() => import('../dashboards/staff-users/staff-users'))
      },
      {
        name: 'Disable Staff Users (Bulk)',
        path: '/staff/tools/bulk-disable-staff-users',
        description: 'Bulk Disable Staff Users',
        permissionName: CognitoPermissionGroups.canBulkDisableStaffUsers,
        component: React.lazy(() => import('../tasks/staff-users/bulk-disable-staff-users')),
        toolName: 'bulkDisableStaffUsers'
      },
      {
        name: 'Add Permission Groups to Staff Users (Bulk)',
        path: '/staff/tools/add-permission-groups-to-staff-users-bulk',
        permissionName: CognitoPermissionGroups.canAddPermissionGroupsToStaffUsers,
        component: React.lazy(() => import('../tasks/staff-users/add-permission-groups-to-staff-users-bulk')),
        toolName: 'addPermissionGroupsToStaffUsersBulk',
        requiredCSVHeaders: ['username', 'group']
      },
      {
        name: 'Create Staff User',
        path: '/staff/tools/create-staff-user',
        permissionName: CognitoPermissionGroups.canCreateStaffUser,
        component: React.lazy(() => import('../tasks/staff-users/create-staff-user')),
        toolName: 'toolsCreateStaffUser'
      },
      {
        name: 'Create Staff User (Bulk)',
        path: '/staff/tools/create-staff-user-bulk',
        permissionName: CognitoPermissionGroups.canCreateStaffUser,
        component: React.lazy(() => import('../tasks/staff-users/create-staff-user-bulk')),
        toolName: 'createStaffUserBulk',
        requiredCSVHeaders: [
          'username',
          'email',
          'firstName',
          'lastName',
          'phone',
          'licensedAllStates',
          'unlicensedAllStates'
        ]
      },
      {
        name: 'Update Staff Users (Bulk)',
        path: '/staff/tools/update-staff-users-bulk',
        permissionName: CognitoPermissionGroups.canUpdateStaffUser,
        component: React.lazy(() => import('../tasks/staff-users/update-staff-users-bulk')),
        toolName: 'updateStaffUserBulk',
        requiredCSVHeaders: ['username', 'firstName', 'lastName']
      },
      {
        name: 'Change Staff Username (Bulk)',
        path: '/staff/tools/change-staff-username-bulk',
        permissionName: CognitoPermissionGroups.canUpdateStaffUsername,
        component: React.lazy(() => import('../tasks/staff-users/change-staff-username-bulk')),
        toolName: 'changeStaffUsernameBulk',
        requiredCSVHeaders: ['currentUsername', 'newUsername', 'affinity']
      }
    ]
  },
  {
    category: 'Billing',
    items: [
      {
        name: 'Create a Stripe charge',
        path: '/staff/tools/create-stripe-charge',
        permissionName: CognitoPermissionGroups.canCreateStripeCharge,
        component: React.lazy(() => import('../tasks/billing/create-stripe-charge')),
        toolName: 'toolsCreateStripeCharge'
      },
      {
        name: 'Create Chargedesk Invoice',
        path: '/staff/tools/create-chargedesk-invoice',
        permissionName: CognitoPermissionGroups.canCreateChargedeskInvoice,
        component: React.lazy(() => import('../tasks/billing/create-chargedesk-invoice')),
        toolName: 'toolsCreateChargedeskInvoice'
      },
      {
        name: 'Create Chargedesk Invoice (Bulk)',
        path: '/staff/tools/create-chargedesk-invoice-bulk',
        permissionName: CognitoPermissionGroups.canCreateChargedeskInvoice,
        component: React.lazy(() => import('../tasks/billing/create-chargedesk-invoice-bulk')),
        toolName: 'createChargedeskInvoiceBulk'
      },
      {
        name: 'Create Chargedesk Refund Invoice',
        path: '/staff/tools/create-chargedesk-refund-invoice',
        permissionName: CognitoPermissionGroups.canCreateChargedeskRefundInvoice,
        component: React.lazy(() => import('../tasks/billing/create-chargedesk-refund-invoice')),
        toolName: 'toolsCreateChargedeskRefundInvoice'
      },
      {
        name: 'Create Chargedesk Refund Invoice (Bulk)',
        path: '/staff/tools/create-chargedesk-refund-invoice-bulk',
        permissionName: CognitoPermissionGroups.canCreateChargedeskRefundInvoice,
        component: React.lazy(() => import('../tasks/billing/create-chargedesk-refund-invoice-bulk')),
        toolName: 'createChargedeskRefundInvoiceBulk'
      },
      {
        name: 'Update Chargedesk Record',
        path: '/staff/tools/update-chargedesk-record',
        permissionName: CognitoPermissionGroups.canUpdateChargedeskRecord,
        component: React.lazy(() => import('../tasks/billing/update-chargedesk-record')),
        toolName: 'toolsUpdateChargedeskRecord'
      },
      {
        name: 'Update Chargedesk Record (Bulk)',
        path: '/staff/tools/update-chargedesk-record-bulk',
        permissionName: CognitoPermissionGroups.canUpdateChargedeskRecord,
        component: React.lazy(() => import('../tasks/billing/update-chargedesk-record-bulk')),
        toolName: 'updateChargedeskRecordBulk'
      },
      {
        name: 'Update Chargedesk Record Policy ID or Billing ID',
        path: '/staff/tools/update-chargedesk-record-policy-or-billing-id',
        permissionName: CognitoPermissionGroups.canUpdateChargedeskRecordPolicyOrBillingId,
        component: React.lazy(() => import('../tasks/billing/update-chargedesk-record-policy-or-billing-id')),
        toolName: 'toolsUpdateChargedeskRecordLimited'
      }
    ]
  },
  {
    category: 'Claims',
    items: [
      {
        name: 'Import Policies to Snapsheet',
        path: '/staff/tools/import-policies-to-snapsheet',
        permissionName: CognitoPermissionGroups.canImportPoliciesToSnapsheet,
        component: React.lazy(() => import('../tasks/claims/import-policies-to-snapsheet')),
        toolName: 'toolsImportPoliciesToSnapsheet'
      },
      {
        name: 'Add CAT Tags to Claims',
        path: '/staff/tools/add-cat-tags-to-claims',
        permissionName: CognitoPermissionGroups.canAddCatTagsToClaims,
        component: React.lazy(() => import('../tasks/claims/add-cat-tags-to-claims')),
        toolName: 'toolsAddCatTagsToClaims'
      },
      {
        name: 'Bulk Bill Copart Recoveries',
        path: '/staff/tools/bulk-bill-copart-recoveries',
        permissionName: CognitoPermissionGroups.canBulkBillCopartRecoveries,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-copart-recoveries')),
        toolName: 'toolsBulkBillCopartRecoveries'
      },
      {
        name: 'Bulk Bill Fleet Subrogation',
        path: '/staff/tools/bulk-bill-fleet-subrogation',
        permissionName: CognitoPermissionGroups.canBulkBillFleetSubrogation,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-fleet-subrogation')),
        toolName: 'toolsBulkBillFleetSubrogation'
      },
      {
        name: 'Bill Honk',
        path: '/staff/tools/bill-honk',
        permissionName: CognitoPermissionGroups.canRunBillHonk,
        component: React.lazy(() => import('../tasks/claims/bill-honk')),
        toolName: 'toolsRunBillHonk'
      },
      {
        name: 'Bill Hover',
        path: '/staff/tools/bill-hover',
        permissionName: CognitoPermissionGroups.canRunBillHover,
        component: React.lazy(() => import('../tasks/claims/bill-hover')),
        toolName: 'toolsRunBillHover'
      },
      {
        name: 'Bulk Bill Bees 360',
        path: '/staff/tools/bulk-bill-bees',
        permissionName: CognitoPermissionGroups.canBulkBillBees,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-bees')),
        toolName: 'toolsBulkBillBees'
      },
      {
        name: 'Bulk Bill Pilot Cat Services',
        path: '/staff/tools/bulk-bill-pilot-cat-services',
        permissionName: CognitoPermissionGroups.canBulkBillPilot,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-pilot-cat-services')),
        toolName: 'toolsBulkBillPilotCatServices'
      },
      {
        name: 'Bulk Bill Medlogix',
        path: '/staff/tools/bulk-bill-medlogix',
        permissionName: CognitoPermissionGroups.canBulkBillMedlogix,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-medlogix')),
        toolName: 'toolsBulkBillMedlogix'
      },
      {
        name: 'Bulk Bill Enterprise',
        path: '/staff/tools/bulk-bill-enterprise',
        permissionName: CognitoPermissionGroups.canBulkBillEnterprise,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-enterprise')),
        toolName: 'toolsBulkBillEnterprise'
      },
      {
        name: 'Bulk Bill Accuserve',
        path: '/staff/tools/bulk-bill-accuserve',
        permissionName: CognitoPermissionGroups.canBulkBillAccuserve,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-accuserve')),
        toolName: 'toolsBulkBillAccuserve'
      },
      {
        name: 'Bulk Bill Hancock',
        path: '/staff/tools/bulk-bill-hancock',
        permissionName: CognitoPermissionGroups.canBulkBillHancock,
        component: React.lazy(() => import('../tasks/claims/bulk-bill-hancock')),
        toolName: 'toolsBulkBillHancock'
      }
    ]
  },
  {
    category: 'Operations',
    items: [
      {
        name: 'Run Addresses Through Cherre',
        path: '/staff/tools/bulk-run-addresses-through-cherre',
        description: 'Audit addresses through Cherre',
        permissionName: CognitoPermissionGroups.canRunAddressesThroughCherre,
        component: React.lazy(() => import('../tasks/operations/bulk-run-addresses-through-cherre')),
        toolName: 'bulkRunAddressesThroughCherre'
      },
      {
        name: 'Create Front Ticket',
        path: '/staff/tools/create-front-ticket',
        permissionName: CognitoPermissionGroups.canCreateFrontTicket,
        component: React.lazy(() => import('../tasks/operations/create-front-ticket')),
        toolName: 'toolsCreateFrontTicket'
      },
      {
        name: 'Create Front Ticket(s) Bulk',
        path: '/staff/tools/create-front-ticket-bulk',
        permissionName: CognitoPermissionGroups.canCreateFrontTicket,
        component: React.lazy(() => import('../tasks/operations/create-front-ticket-bulk')),
        toolName: 'createFrontTicketBulk'
      },
      {
        name: 'State Rate Change Dashboard',
        path: '/staff/tools/dashboards/state-rate-change',
        description: 'Dashboard to query latest rate change records for all states',
        permissionName: CognitoPermissionGroups.canViewStateRateChangeDashboard,
        component: React.lazy(() => import('../dashboards/state-rate-change/state-rate-change'))
      }
    ]
  },
  {
    category: 'Policy Actions',
    items: [
      {
        name: 'Update Policy Rep',
        path: '/staff/tools/update-policy-rep',
        permissionName: CognitoPermissionGroups.canUpdatePolicyRep,
        component: React.lazy(() => import('../tasks/policy-actions/update-policy-rep')),
        toolName: 'toolsUpdatePolicyRep'
      },
      {
        name: 'Copy Policy',
        path: '/staff/tools/copy-policy',
        permissionName: CognitoPermissionGroups.canCopyPolicy,
        component: React.lazy(() => import('../tasks/policy-actions/copy-policy')),
        toolName: 'toolsCopyPolicy'
      },
      {
        name: 'Set Affinity Code for a Member or List of Members',
        path: '/staff/tools/set-affinity-code-for-member',
        permissionName: CognitoPermissionGroups.canSetMemberAffinity,
        component: React.lazy(() => import('../tasks/policy-actions/set-affinity-code-for-member')),
        toolName: 'toolsSetMemberAffinity'
      },
      {
        name: 'Update Policy Rep (Bulk)',
        path: '/staff/tools/bulk-update-policy-rep',
        permissionName: CognitoPermissionGroups.canUpdatePolicyRep,
        component: React.lazy(() => import('../tasks/policy-actions/bulk-update-policy-rep')),
        toolName: 'bulkUpdatePolicyRep'
      },
      {
        name: 'Pull Driving History From CSV',
        path: '/staff/tools/pull-driving-history-from-csv',
        permissionName: CognitoPermissionGroups.canPullDrivingHistoryFromCSV,
        component: React.lazy(() => import('../tasks/policy-actions/pull-driving-history-from-csv')),
        toolName: 'pullDrivingHistoryFromCsv'
      },
      {
        name: 'Set Has Auto Policy',
        path: '/staff/tools/set-has-auto-policy',
        description: 'This task should be used when has_auto_policy is changing mid-term unexpectedly',
        permissionName: CognitoPermissionGroups.canSetHasAutoPolicy,
        component: React.lazy(() => import('../tasks/policy-actions/set-has-auto-policy')),
        toolName: 'toolsSetHasAutoPolicy'
      },
      {
        name: 'Update Billing Id (Bulk)',
        path: '/staff/tools/update-billing-id',
        permissionName: CognitoPermissionGroups.canUpdateBillingId,
        component: React.lazy(() => import('../tasks/policy-actions/bulk-update-billing-id')),
        toolName: 'bulkUpdateBillingId'
      },
      {
        name: 'Override Umbrella Limit',
        path: '/staff/tools/override-umbrella-limit',
        description: 'This task should be used when we need to override to allow 300K CSL',
        permissionName: CognitoPermissionGroups.canOverrideUmbrellaLimit,
        component: React.lazy(() => import('../tasks/policy-actions/override-umbrella-limit')),
        toolName: 'toolsOverrideUmbrellaLimit'
      },
      {
        name: 'Change affinities on offers (Bulk)',
        path: '/staff/tools/change-offer-affinity',
        description: `List of one or more offers to change affinity`,
        permissionName: CognitoPermissionGroups.canChangeOfferAffinity,
        component: React.lazy(() => import('../tasks/policy-actions/bulk-change-offer-affinity')),
        toolName: 'bulkChangeOfferAffinity'
      }
    ]
  },
  {
    category: 'Offer Actions',
    items: [
      {
        name: 'Add Auto Violation to Offer',
        path: '/staff/tools/add-auto-violation-to-offer',
        permissionName: CognitoPermissionGroups.canAddViolationToOffer,
        component: React.lazy(() => import('../tasks/offer-actions/add-auto-violation-to-offer')),
        toolName: 'toolsAddViolationToOffer'
      }
    ]
  },
  {
    category: 'Samples',
    items: [
      {
        name: 'Sample Non-CSV Bulk Task',
        path: '/staff/tools/sample-non-csv-bulk-task',
        description: 'Execute a CSV writer lambda',
        permissionName: CognitoPermissionGroups.canBulkDisableStaffUsers,
        component: React.lazy(() => import('../tasks/sample/non-csv-bulk-task'))
      }
    ]
  },
  {
    category: 'Forms & Documents',
    items: [
      {
        name: 'Regenerate Policy Form',
        path: '/staff/tools/regenerate-policy-form',
        description: 'Request a regeneration of a policy form',
        permissionName: CognitoPermissionGroups.canRegeneratePolicyForm,
        component: React.lazy(() => import('../tasks/forms-and-docs/regenerate-policy-form'))
      },
      {
        name: 'Regenerate Policy Cancellation/Non-Renew Form',
        path: '/staff/tools/regenerate-cancellation-form',
        description: 'Request a regeneration of a cancellation or non-renew form',
        permissionName: CognitoPermissionGroups.canRegeneratePolicyCancelForm,
        component: React.lazy(() => import('../tasks/forms-and-docs/regenerate-cancellation-form')),
        toolName: 'regenerateCancellationForm'
      },
      {
        name: 'Regenerate Policy Documents (Bulk)',
        path: '/staff/tools/regenerate-policy-forms-bulk',
        description: 'Request policy documents to be regenerated for multiple policies via CSV',
        permissionName: CognitoPermissionGroups.canRegeneratePolicyForm,
        component: React.lazy(() => import('../tasks/forms-and-docs/regenerate-policy-forms-bulk')),
        toolName: 'regeneratePolicyFormsBulk'
      }
    ]
  },
  {
    category: 'Renewals',
    items: [
      {
        name: 'Generate Renewal',
        path: '/staff/tools/generate-renewal',
        permissionName: CognitoPermissionGroups.canGenerateRenewal,
        component: React.lazy(() => import('../tasks/renewals/generate-renewal')),
        toolName: 'toolsGenerateRenewal'
      },
      {
        name: 'Run Renewal Underwriting',
        path: '/staff/tools/run-renewal-underwriting',
        permissionName: CognitoPermissionGroups.canRunRenewalUnderwriting,
        component: React.lazy(() => import('../tasks/renewals/run-renewal-underwriting')),
        toolName: 'toolsRunRenewalUnderwriting'
      }
    ]
  },
  {
    category: 'Tech tasks',
    items: [
      {
        name: 'Send bulk segment events',
        path: '/staff/tools/send-bulk-segment-events',
        description: 'send bulk segment events uploading a csv file',
        permissionName: CognitoPermissionGroups.canRunTechTasks,
        component: React.lazy(() => import('../tasks/tech/send-bulk-segment-events')),
        toolName: 'sendBulkSegmentEvents'
      },
      {
        name: 'Get policies by status',
        path: '/staff/tools/get-policies-by-status',
        description: 'categorize policies by status uploading a csv file',
        permissionName: CognitoPermissionGroups.canRunTechTasks,
        component: React.lazy(() => import('../tasks/tech/get-policies-by-status')),
        toolName: 'getPoliciesByStatus'
      },
      {
        name: 'Bulk Automated Endorsements',
        path: '/staff/tools/bulk-automated-endorsements',
        description: 'bulk automated endorsements uploading a csv file',
        permissionName: CognitoPermissionGroups.canRunTechTasks,
        component: React.lazy(() => import('../tasks/tech/bulk-automated-endorsements')),
        toolName: 'bulkReRater'
      }
    ]
  }
];
