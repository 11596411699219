import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import ScreenHeader from 'core/components/screen-header';
import { useStore } from 'core/store';
import FrontContact from '../front-contact';
import useStyles from './header.styles';

const CustomerHeader = ({ history }) => {
  const classes = useStyles();
  const store = useStore();
  const { account: accountStore } = store;
  const { isExternalSales } = useContext(AuthContext);

  const titleText = () => {
    return accountStore.accountLoading ? 'Loading...' : `${accountStore.fname} ${accountStore.lname}`;
  };

  const title = titleText();

  const back = useCallback(() => {
    history.push(`/search/customers`);
  }, [history]);

  return (
    <div className={classes.header}>
      <ScreenHeader title={title} onBack={back} options={{ copyableTitle: true }} />
      {!isExternalSales && <FrontContact />}
    </div>
  );
};

export default observer(CustomerHeader);
