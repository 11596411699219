import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import SwapButton from 'core/components/swap-button';
import { FormField } from 'core/components/form';

const OfferApplicantActions = (props) => {
  const { allowLicensedActions, person, formikFieldPath, onRemove, index, removeFromList } = props;
  const { values } = useFormikContext();

  // looking at values.drivers because all people are under the drivers node on offers even if its Home only
  const showSwapButton = person.isPrimary && values.drivers?.some((driver) => driver.isCoApplicant);
  const coApplicant = values.drivers?.find((person) => person.isCoApplicant);

  // since we're using the drivers node for even home only offers here, the backend doesn't accept them as co-app and excluded.
  // for now, they have to add as co-app after bind
  const disableCoApplicantField = (coApplicant && !person.isCoApplicant) || person.excludeDriver;

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={4}>
      {showSwapButton && <SwapButton mode="big" allowLicensedActions={allowLicensedActions} formikPath="drivers" />}
      {!person.isPrimary && (
        <Grid item>
          <FormField
            id={`${formikFieldPath}.isCoApplicant`}
            name={`${formikFieldPath}.isCoApplicant`}
            type="checkbox"
            label="Co Applicant?"
            mode="light"
            disabled={disableCoApplicantField}
            tooltipLabel={disableCoApplicantField && person.excludeDriver ? 'More Info' : undefined}
            tooltipText={
              disableCoApplicantField && person.excludeDriver
                ? `Adding ${person.firstName} ${person.lastName} as co-applicant will be available after bind`
                : undefined
            }
          />
        </Grid>
      )}
      <Grid item>
        <FormField
          type="remove"
          id={formikFieldPath}
          name={formikFieldPath}
          disabled={person.isPrimary}
          mode="big"
          onClick={() => onRemove(index, removeFromList)}
          fast={false}
        />
      </Grid>
    </Grid>
  );
};

export default OfferApplicantActions;
