import { useMemo } from 'react';
import { getIn } from 'formik';
import { useStore } from 'core/store/store.mobx';

// @TODO this could be a mobx computed property
export const useCurrentState = (formikValues) => {
  const store = useStore();
  if (!store && !formikValues) {
    throw new Error('This hook has to be called inside policyStore or offer formik context');
  }
  const policy = store.account.policies?.policy?.policy;
  const policies = store.account.policies.list;
  const offer = store.offer;

  return useMemo(() => {
    if (policy) {
      return policy.state;
    }

    // If on customer route, return first state in policies list.
    // Might want to expand this later to return the array of all unique states the customer has policies in?
    if (policies?.length) {
      return policies[0].state;
    }

    // path of values depends if we're editing offer or checking out the offer
    const state = offer.state || getIn(formikValues, 'correctedAddress.state') || getIn(formikValues, 'address.state');
    return state;
  }, [policy, formikValues, offer, policies]);
};
