/* eslint-disable func-names */
import * as Yup from 'yup';

import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';

export const connectedHomeValidationSchema = (isAdvancedConnectedHome) =>
  Yup.object()
    .shape({
      monitored: Yup.string(),
      moistureDevices: Yup.number()
        .min(0, 'Please enter a positive number')
        .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
          return !context.parent.monitored || context.parent?.monitored === 'N' || value >= 0;
        }),
      motionDetectingDevices: Yup.number()
        .min(0, 'Please enter a positive number')
        .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
          return (
            !context.parent.monitored || isAdvancedConnectedHome || context.parent?.monitored === 'N' || value >= 0
          );
        })
        .nullable(),
      smokeDetectingDevices: Yup.number()
        .min(0, 'Please enter a positive number')
        .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
          return !context.parent.monitored || context.parent?.monitored === 'N' || value >= 0;
        }),
      theftPreventionDevices: Yup.number()
        .min(0, 'Please enter a positive number')
        .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
          return !context.parent.monitored || context.parent?.monitored === 'N' || value >= 0;
        })
        .nullable(),
      autoWaterShutoffDevices: Yup.boolean().default(false),
      agreeToTerms: Yup.boolean()
        .test('ch-on', 'Required', function (value, context) {
          if (
            context.parent.monitored &&
            context.parent.monitored !== 'N' &&
            this.options.context.global.homeSecurityPartnerCustomerType === NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE
          ) {
            return value;
          }
          return true;
        })
        .nullable(),
      providerName: Yup.string().test('ch-on', 'Required', function (value, context) {
        return !context.parent.monitored || context.parent?.monitored === 'N' || context.parent?.providerName?.length;
      })
    })
    .nullable();
