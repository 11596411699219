import React, { useState, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
// commenting out because we are turning off the confirm email splash page (FOR NOW)
// import gql from 'graphql-tag';

import { track } from 'core/helpers/analytics';
import useSession from 'core/hooks/use-session';
import { AgencyPortalHeader } from './components/header';
import { AgencyTabs } from './components/agency-tabs';
import { Footer } from './components/footer';
import routes from './routes';
// commenting out because we are turning off the confirm email splash page (FOR NOW)
// import ConfirmEmailSplashPage from 'common/components/confirm-email-splash-page';
// import { useLazyQuery } from '@apollo/client';

// const GET_CONFIRMED_EMAIL_ADDRESS = gql`
//   query ($email: String!) {
//     result: getConfirmedEmailAddress(emailAddress: $email) {
//       emailAddress
//       username
//     }
//   }
// `;

const PortalPage = ({ src, title }) => {
  return (
    <iframe
      src={src}
      title={title}
      style={{
        width: '100%',
        height: 'calc(100vh - 350px)',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: 'none',
        boxShadow: '0 2px 2px #ccc '
      }}
    />
  );
};

const AgencyPortal = () => {
  const history = useHistory();
  const { isAgency, user } = useSession();
  // commenting out because we are turning off the confirm email splash page (FOR NOW)
  // const [showSplashPage, setShowSplashPage] = useState(false);
  const [activeTab, setActiveTab] = useState(() => {
    const pathName = history.location.pathname.slice(8);
    return routes.findIndex((route) => route.route === pathName);
  });

  // commenting out because we are turning off the confirm email splash page (FOR NOW)
  // const [getConfirmedEmailAddress] = useLazyQuery(GET_CONFIRMED_EMAIL_ADDRESS, {
  //   variables: { email: user.email }
  // });

  // useEffect(async () => {
  //   const { data: response } = await getConfirmedEmailAddress();
  //   const hasEmailBeenConfirmed = response?.result?.length > 0 && response?.result[0]?.username === user.username;
  //   const shouldShowSplashPage = isAgency && user.username !== 'gh1_gservice23' && !hasEmailBeenConfirmed;
  //   setShowSplashPage(shouldShowSplashPage);
  // }, []);

  const handleTabChange = useCallback(
    (_, tab) => {
      const tabName = routes[tab].title;
      track(`Agency Portal - ${tabName} Tab Clicked`, {
        isAgency,
        affinityCode: user.affinityCode
      });
      setActiveTab(tab);
      history.push(`/portal/${routes[tab].route}`);
    },
    [setActiveTab, routes, history]
  );

  return (
    <>
      {/* commenting out because we are turning off the confirm email splash page (FOR NOW) */}
      {/* {showSplashPage && <ConfirmEmailSplashPage onClose={() => setShowSplashPage(false)} />} */}
      <AgencyPortalHeader />
      <AgencyTabs activeTab={activeTab} handleTabChange={handleTabChange} setActiveTab={setActiveTab} />
      <Switch>
        {routes.map(({ route, src, title }) => (
          <Route key={route} path={`/portal/${route}`}>
            <PortalPage src={src} title={title} />
          </Route>
        ))}
      </Switch>
      <Footer activeTab={activeTab} />
    </>
  );
};

export default AgencyPortal;
