import { screens } from './variables';

export const sharedStyles = {
  container: {
    width: '100%',
    padding: '16px 0',
    alignSelf: 'center',
    marginLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [`@media (min-width: ${screens.tablet}px)`]: {
      maxWidth: 720
    },
    [`@media (min-width: ${screens.small_desktop}px)`]: {
      maxWidth: 1128
    }
  },
  paper: {
    padding: 32,
    margin: '16px 0',
    transition: 'opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, all 100ms ease-out 0ms'
  },
  paperHidden: {
    height: 0,
    transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, all 100ms ease-out 0ms',
    margin: 0,
    padding: '0 32px',
    opacity: 0,
    willChange: 'opacity',
    '& *': {
      height: 0,
      margin: 0,
      padding: 0
    }
  },
  addButtonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 16,
    alignItems: 'center',
    marginBottom: 16,
    flexDirection: 'column'
  },
  paperTitle: {
    marginTop: 32,
    textAlign: 'center',
    fontSize: 32,
    '@media (min-width: 500px)': {
      textAlign: 'unset',
      marginBottom: 32
    }
  }
};
