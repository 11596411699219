import React, { forwardRef } from 'react';
import { errorClassificationLookup, rejectCodes, policyType } from '@ourbranch/lookups';
import { useFormikContext } from 'formik';

import { useToast } from 'core/components/toast';
import { useStore } from 'core/store';
import useSession from 'core/hooks/use-session';
import { track } from 'core/helpers/analytics';
import { Button } from 'core/components/button';
import Alert from 'core/assets/svg/ic-alert.svg';
import Solution from 'core/assets/svg/ic-solution.svg';
import AlertIconWhite from 'core/assets/svg/alert-white-bold.svg';
import useStyles from './error-alert.styles';
import { getErrorData } from '../get-error-data';

export const ErrorAlert = forwardRef(({ errors }, ref) => {
  const classes = useStyles();
  const { setFieldValue, values, handleSubmit } = useFormikContext();
  const toast = useToast();
  const {
    quote: { offer, clearError, setErrorToShowAsRejection }
  } = useStore();
  const { user, isAgency, canClearUWFormRejections } = useSession();

  const onSwitchClick = (error, newPropertyType) => {
    setFieldValue(
      'policyType',
      values.policyType.includes(policyType.Condo)
        ? values.policyType.replace(policyType.Condo, policyType.Home)
        : values.policyType.replace(policyType.Home, policyType.Condo)
    );
    clearError(error.code);
    toast.notify({
      type: 'success',
      message: `We have changed your residence property type to ${newPropertyType}. Review the applicant details and select "See Price" to generate a new offer.`
    });
  };

  const onDismissClick = async (error) => {
    const overrides = values.overrides?.overridesForUW?.length ? [...values.overrides.overridesForUW] : [];
    overrides.push({
      code: parseInt(error.code, 10),
      overridingAgent: user.username,
      type: error.type
    });
    await setFieldValue('overrides.overridesForUW', overrides);
    clearError(error.code);

    track('Underwriting Rejection Overridden', {
      rejectionCode: error.code,
      rejectionReason: error.message,
      impactedPolicyType: offer?.quote?.selectedOption,
      offerId: offer?.id,
      isUnderwriting: canClearUWFormRejections,
      username: user.username,
      isAgency,
      email: offer?.quote?.email,
      phone: offer?.quote?.phone,
      logEmail: 'membercomms@ourbranch.com'
    });

    toast.notify({
      type: 'success',
      message: `Please wait while we re-run your offer.`
    });

    await setFieldValue('fcraDisclaimer', true);
    handleSubmit();
  };
  return (
    <div ref={ref}>
      {errors.map((error) => {
        const errorData = getErrorData({
          error,
          isAgency,
          state: values.state
        });
        const errorClassification = errorClassificationLookup?.[Number(error.code)];
        const newPropertyType = error.type === 'condo' ? 'home' : 'condo';
        const isDismissibleRejection = errorClassification === 'DISMISSIBLE_REJECTION';
        return (
          <div key={`error-${error.code}`} className={classes.error}>
            <div className={classes.triangle} />

            <div className={classes.container}>
              <div className={classes.alertRow}>
                <img src={Alert} alt="alert" className={classes.alertIcon} /> ALERT: {errorData.code}
              </div>
              <div className={classes.titleRow}>{errorData.title || 'Error title'}</div>
              {errorData.subtitle && <div className={classes.subtitle}>{errorData.subtitle}</div>}
              <div className={classes.whyBox}>
                <div className={classes.whyRow}>
                  <img src={Solution} alt="why?" /> WHY?
                </div>
                <div className={classes.why}>{errorData.why}</div>
              </div>

              {/* dismissible rejection button */}
              {isDismissibleRejection && (
                <div className={classes.actions}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={
                      error.code === rejectCodes.PROPERTY_MISMATCH
                        ? () => onSwitchClick(error, newPropertyType)
                        : () => setErrorToShowAsRejection(error.code)
                    }
                  >
                    {error.code === rejectCodes.PROPERTY_MISMATCH ? `Yes, switch to ${newPropertyType}` : 'Yes'}
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    className={classes.dismiss}
                    onClick={() => onDismissClick(error)}
                  >
                    No, dismiss
                  </Button>
                  <div className={classes.attestRow}>
                    <img src={AlertIconWhite} className={classes.attestIcon} alt="Alert Icon" />
                    By dismissing, you attest that the data flagging this property as ineligible is incorrect.
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default ErrorAlert;
