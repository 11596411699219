// note: using the prefix "um" because "in" is a reserved word
export const umal = () => import(/* webpackChunkName: "alabama-umbi" */ './al-umbi.js');
export const umar = () => import(/* webpackChunkName: "arkansas-umbi" */ './ar-umbi.js');
export const umaz = () => import(/* webpackChunkName: "arizona-umbi" */ './az-umbi.js');
export const umco = () => import(/* webpackChunkName: "colorado-umbi" */ './co-umbi.js');
export const umdc = () => import(/* webpackChunkName: "dc-umbi" */ './dc-umbi.js');
export const umga = () => import(/* webpackChunkName: "iowa-umbi" */ './ga-umbi.js');
export const umfl = () => import(/* webpackChunkName: "florida-umbi" */ './fl-umbi/fl-umbi.js');
export const umia = () => import(/* webpackChunkName: "georgia-umbi" */ './ia-umbi.js');
export const umid = () => import(/* webpackChunkName: "idaho-umbi" */ './id-umbi.js');
export const umil = () => import(/* webpackChunkName: "illinois-umbi" */ './il-umbi.js');
export const umin = () => import(/* webpackChunkName: "indiana-umbi" */ './in-umbi.js');
export const umks = () => import(/* webpackChunkName: "kansas-umbi" */ './ks-umbi.js');
export const umky = () => import(/* webpackChunkName: "kentucky-umbi" */ './ky-umbi.js');
export const umla = () => import(/* webpackChunkName: "louisiana-umbi" */ './la-umbi.js');
export const umma = () => import(/* webpackChunkName: "massachusetts-umbi" */ './ma-umbi.js');
export const ummd = () => import(/* webpackChunkName: "maryland-umbi" */ './md-umbi.js');
export const umme = () => import(/* webpackChunkName: "maine-umbi" */ './me-umbi.js');
export const ummi = () => import(/* webpackChunkName: "michigan-umbi" */ './mi-umbi.js');
export const ummo = () => import(/* webpackChunkName: "missouri-umbi" */ './mo-umbi.js');
export const umms = () => import(/* webpackChunkName: "mississippi-umbi" */ './ms-umbi.js');
export const ummt = () => import(/* webpackChunkName: "montana-umbi" */ './mt-umbi.js');
export const umnd = () => import(/* webpackChunkName: "north-dakota-umbi" */ './nd-umbi.js');
export const umne = () => import(/* webpackChunkName: "nebraska-umbi" */ './ne-umbi.js');
export const umnh = () => import(/* webpackChunkName: "new-hampshire-umbi" */ './nh-umbi.js');
export const umnm = () => import(/* webpackChunkName: "new-mexico-umbi" */ './nm-umbi.js');
export const umoh = () => import(/* webpackChunkName: "ohio-umbi" */ './oh-umbi.js');
export const umok = () => import(/* webpackChunkName: "oklahoma-umbi" */ './ok-umbi.js');
export const umor = () => import(/* webpackChunkName: "oregon-umbi" */ './or-umbi.js');
export const umpa = () => import(/* webpackChunkName: "pennsylvania-umbi" */ './pa-umbi.js');
export const umsc = () => import(/* webpackChunkName: "south-carolina-umbi" */ './sc-umbi.js');
export const umsd = () => import(/* webpackChunkName: "south-dakota-umbi" */ './sd-umbi.js');
export const umtn = () => import(/* webpackChunkName: "tennessee-umbi" */ './tn-umbi.js');
export const umtx = () => import(/* webpackChunkName: "texas-umbi" */ './tx-umbi.js');
export const umut = () => import(/* webpackChunkName: "utah-umbi" */ './ut-umbi.js');
export const umva = () => import(/* webpackChunkName: "virginia-umbi" */ './va-umbi.js');
export const umvt = () => import(/* webpackChunkName: "vermont-umbi" */ './vt-umbi.js');
export const umwi = () => import(/* webpackChunkName: "wisconsin-umbi" */ './wi-umbi.js');
export const umwv = () => import(/* webpackChunkName: "west-virginia-umbi" */ './wv-umbi.js');
export const umwy = () => import(/* webpackChunkName: "wyoming-umbi" */ './wy-umbi.js');
