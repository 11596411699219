import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { lookupsJson, branchInitiatedCancelReasons, flatCancelReasons } from '@ourbranch/lookups';
import { observer } from 'mobx-react';
import flowRight from 'lodash-es/flowRight';
import { isBefore, parseISO, format, subDays } from 'date-fns';

import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import { awsDateToJs } from 'core/helpers/formatters';
import useSession from 'core/hooks/use-session';
import { FieldWithTooltip } from 'core/components/field-with-tooltip';
import { useStore } from 'core/store';
import { tooltipHoverTexts } from 'core/helpers/constants';

import useStyles from './cancellation-form.styles';

const EndDateOfTodayReasons = ['CNDB', 'CNAP', 'CNDC', 'CNDE', 'CNMI', 'CNMV', 'CNNO', 'CNDA', 'CNPR', 'CNSO']; // currently, all EndDateOfTodayReasons are Insured requested
const PermissionGatedReasonsDetails = [
  { code: 'CNMS', permission: 'canCancelReasonCNMS' },
  { code: 'CNIP', permission: 'canCancelReasonCNIP' },
  { code: 'CNPY_X', permission: 'canCancelReasonCNPY_X' },
  { code: 'CNRW', permission: 'canRewritePolicy' },
  { code: 'CNCN', permission: 'canViewCarrierInitiatedCancels' },
  { code: 'CNDP', permission: 'canViewCarrierInitiatedCancels' },
  { code: 'CNPP', permission: 'canViewCarrierInitiatedCancels' },
  { code: 'CNPY', permission: 'canViewCarrierInitiatedCancels' },
  { code: 'NACP', permission: 'canCancelReasonNACP' },
  { code: 'CNUW', permission: 'canViewUWCancelCodes' },
  { code: 'CNUV', permission: 'canViewUWCancelCodes' },
  { code: 'CNUH', permission: 'canViewUWCancelCodes' },
  { code: 'CNSR', permission: 'canViewUWCancelCodes' },
  { code: 'CNFP', permission: 'canViewUWCancelCodes' }
];
const stateGatedReasons = { AZ: ['NACP'] };
const PermissionGatedReasons = PermissionGatedReasonsDetails.map((detail) => detail.code);

const CancellationForm = () => {
  const classes = useStyles();
  const [disableEndDate, setDisableEndDate] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const {
    endDate,
    effectiveDate,
    state,
    cancelReason,
    transactionDate,
    fullTermPolicyEndDate,
    term,
    cancelEffectiveDate
  } = values;

  const session = useSession();
  const {
    account: {
      policies: { policy }
    }
  } = useStore();
  const currentTotalPaid = policy?.totalReceived;

  const today = new Date().toISOString().split('T')[0];
  const needsEndDateCalculation = branchInitiatedCancelReasons.includes(cancelReason);

  const cancelReasons = lookupsJson.cancelReason
    .filter((cancelReasonOption) => !cancelReasonOption?.deprecated && !cancelReasonOption?.nonRenew)
    .filter((currentCancelReasonOption) => {
      // filter out codes that are disabled or user doesnt have permission
      const cancelCodeAllowedInThisState = !stateGatedReasons[state]?.includes(currentCancelReasonOption.id);
      let hasPermissionToCancelWithThisCode = true;

      if (PermissionGatedReasons.includes(currentCancelReasonOption.id)) {
        const { permission } = PermissionGatedReasonsDetails.find(
          (detail) => detail.code === currentCancelReasonOption.id
        );
        if (currentTotalPaid > 0 && currentCancelReasonOption.id === 'NACP') {
          hasPermissionToCancelWithThisCode = false;
        }
        if (permission && !session[permission]) {
          hasPermissionToCancelWithThisCode = false;
        }
      }

      return cancelCodeAllowedInThisState && hasPermissionToCancelWithThisCode;
    })
    .sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0));

  const isTodayBeforeEffectiveDate = isBefore(parseISO(today), parseISO(effectiveDate));
  const originalEndDate = awsDateToJs(values.fullTermPolicyEndDate);
  // for non branchInitiated, we use cancelEffectiveDate, so we need be able to select up to the full term end date (which will translate to endDate === cancelEffectiveDate -1)
  const maxDate = needsEndDateCalculation
    ? new Date(new Date(originalEndDate).setDate(originalEndDate.getUTCDate() - 1))
    : new Date(new Date(originalEndDate).setDate(originalEndDate.getUTCDate()));
  const minDate = session.canBackDate ? new Date(effectiveDate) : new Date();

  if (session.canBackDate) {
    minDate.setDate(new Date(effectiveDate).getDate() + 1);
  }
  useEffect(() => {
    // endDate is always cancelEffectiveDate - 1, unless flat cancel
    if (cancelEffectiveDate && effectiveDate === cancelEffectiveDate) {
      setFieldValue('endDate', effectiveDate);
    } else if (cancelEffectiveDate) {
      setFieldValue('endDate', format(subDays(parseISO(cancelEffectiveDate), 1), 'yyyy-MM-dd'));
    }
  }, [cancelEffectiveDate]);

  useEffect(() => {
    // First term ODEN policies always get fee. ODEN policies use transactionDate instead of endDate for cancels.
    if (term === 1 && endDate !== effectiveDate && transactionDate !== effectiveDate) {
      setFieldValue('applyFee', true);
    } else if (endDate === effectiveDate) {
      setFieldValue('applyFee', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, effectiveDate, term, transactionDate]);

  const updateSuggestedEndDate = (reason) => {
    const branchInitiated = branchInitiatedCancelReasons.includes(reason);

    if (flatCancelReasons.includes(cancelReason)) {
      setFieldValue('cancelEffectiveDate', effectiveDate);
      setDisableEndDate(true);
    } else if (EndDateOfTodayReasons.includes(cancelReason)) {
      setFieldValue('cancelEffectiveDate', isTodayBeforeEffectiveDate ? effectiveDate : today);
      setDisableEndDate(false);
    } else if (branchInitiated) {
      // branchInitiated uses transactionDate, so reset end date to full term, reset cancel effective date to undefined
      setFieldValue('endDate', fullTermPolicyEndDate);
      setFieldValue('cancelEffectiveDate', undefined);
      setFieldValue('transactionDate', isTodayBeforeEffectiveDate ? effectiveDate : today);
    } else {
      // everything else, default to today or effective date
      setFieldValue('cancelEffectiveDate', isTodayBeforeEffectiveDate ? effectiveDate : today);
      setDisableEndDate(false);
    }
    if (!needsEndDateCalculation) {
      // if not branch initiated, should not have transactionDate
      setFieldValue('transactionDate', undefined);
    }
  };

  return (
    <Grid container key="details" className={classes.form}>
      <Grid className={classes.cancelFieldsContainer}>
        <FormField
          name="cancelReason"
          type="select"
          label="Cancel Reason"
          mode="light"
          options={cancelReasons}
          xs={cancelReason ? 5 : 8}
          className={cancelReason && cancelReason !== 'NA' ? classes.cancelReasonSmall : classes.cancelReasonBig}
          ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
          onChange={(val) => {
            updateSuggestedEndDate(val);
          }}
        />
        {cancelReason && cancelReason !== 'NA' && (
          <Grid className={classes.endDateContainer}>
            <FieldWithTooltip
              // no longer directly setting endDate for cancels. For transactionDate, user selects date and tehn ODEN calculates endDate. For cancelEffectiveDate, endDate = cancelEffectiveDate -1
              name={needsEndDateCalculation ? 'transactionDate' : 'cancelEffectiveDate'}
              label={needsEndDateCalculation ? 'Transaction Date' : 'Cancel Effective Date'}
              type="date"
              mode="light"
              disableFuture={false}
              maxDate={maxDate}
              minDate={minDate}
              initialFocusedDate={maxDate}
              xs={4}
              disabled={disableEndDate}
              labelNextToTooltip
              className={classes.endDate}
              ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
              defaultToEmpty
              tooltip={
                needsEndDateCalculation ? tooltipHoverTexts.transactionDate : tooltipHoverTexts.cancelEffectiveDate
              }
            />
          </Grid>
        )}
      </Grid>
      <FormField
        type="checkbox"
        id="applyFee"
        name="applyFee"
        mode="light"
        label="Apply Fee"
        xs={2}
        className={classes.applyFee}
        ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        permissions={{
          edit: {
            groups: [CognitoPermissionGroups.canEditFeeCheckbox]
          }
        }}
      />
    </Grid>
  );
};

export default flowRight(withDatePicker, observer)(CancellationForm);
