import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'core/components/label';
import useStyles from './payment-method-label.styles';

function PaymentMethodLabel({ children, className }) {
  const classes = useStyles();
  return (
    <Label type="infoLabel" className={classNames(className, classes.value)}>
      {children}
    </Label>
  );
}

PaymentMethodLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

PaymentMethodLabel.defaultProps = {
  className: null
};

export default PaymentMethodLabel;
