import React from 'react';

import { Label } from 'core/components/label';

import useStyles from '../footer.styles';

const InvalidFooter = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Label className={classes.invalidOptionTitle} type="subtitle">
        {text}
      </Label>
    </div>
  );
};
export default InvalidFooter;
