import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Auth as AwsAuth } from 'aws-amplify';
import { oAuthStorage } from 'core/components/auth';
import { isLocalhost } from 'core/helpers/misc';
import { identify, track } from 'core/helpers/analytics';

const redirectUri = `${window.location.origin}/oauth-response`;
const cognitoOAuthDomain = `https://${process.env.REACT_APP_BRANCH_COGNITO_OAUTH_DOMAIN}.auth.us-east-1.amazoncognito.com`;
const authorizeEndpoint = `${cognitoOAuthDomain}/oauth2/authorize`;
const tokenEndpoint = `${cognitoOAuthDomain}/oauth2/token`;
const logoutEndpoint = `${cognitoOAuthDomain}/logout`;

const parseJson = (attribute) => {
  try {
    return JSON.parse(attribute);
  } catch (e) {
    return [];
  }
};

const sha256 = async (str) => window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));

const generateNonce = async () => {
  const hash = await sha256(window.crypto.getRandomValues(new Uint32Array(4)).toString());
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

const base64URLEncode = (string) =>
  btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

// This function creates all necessary parameters and hits /authorize endpoint of Cognito to start the OAuth flow
// AWS Docs: https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
export async function signIn() {
  const state = await generateNonce();
  const pkce_key = await generateNonce();
  const code_challenge = base64URLEncode(await sha256(pkce_key));

  oAuthStorage.setState(state);
  oAuthStorage.setPKCE(pkce_key);
  oAuthStorage.setInitiatedAuthStatus(true);

  const code_challenge_method = 'S256';
  const scopesString = 'email openid profile phone aws.cognito.signin.user.admin';

  const queryParams = {
    response_type: 'code',
    code_challenge,
    code_challenge_method,
    state,
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
    identity_provider: isLocalhost
      ? process.env.REACT_APP_LOCALHOST_DESCOPE_PROVIDER_NAME
      : process.env.REACT_APP_HOSTED_APP_DESCOPE_PROVIDER_NAME,
    scope: scopesString
  };

  const queryString = Object.entries(queryParams)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  const URL = `${authorizeEndpoint}?${queryString}`;
  window.open(URL, '_self');
}

const getCognitoTokens = (code) => {
  const code_verifier = oAuthStorage.getPKCE();
  const queryParams = {
    grant_type: 'authorization_code',
    client_id: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
    redirect_uri: redirectUri,
    code,
    code_verifier
  };

  const queryString = Object.entries(queryParams)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  return fetch(tokenEndpoint, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
    body: queryString
  });
};

// This solution is based on https://github.com/aws-amplify/amplify-js/issues/8632
const handleOAuthResponse = async (payload) => {
  const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: payload.access_token
  });

  const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: payload.id_token
  });

  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: payload.refresh_token
  });

  const sessionData = {
    IdToken,
    AccessToken,
    RefreshToken
  };

  const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

  const poolData = {
    UserPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
    ClientId: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID
  };

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: AccessToken.payload.username,
    Pool: userPool
  });

  const { payload: userData } = IdToken;

  // Segment analytics tracking
  await identify({
    email: userData.email,
    firstName: userData.given_name || null,
    lastName: userData.family_name || null,
    username: userData['cognito:username'],
    isAgency:
      !!parseJson(userData['custom:affinity_groups']).length ||
      !!parseJson(userData['custom:affinity_group_regex']).length,
    canSellAndService:
      userData['cognito:groups']?.includes('ExternalSales') && parseJson(userData['custom:allowed_states'])?.length > 0,
    agencyRole: userData['custom:agency_role'] || null
  });

  track('Staff Sign In', {
    username: userData['cognito:username'],
    email: userData.email
  });

  cognitoUser.setSignInUserSession(session);

  const deepLink = oAuthStorage.getDeepLink();
  window.location.href = deepLink || '/';
};

export const setCognitoUserSession = async (code) => {
  const response = await getCognitoTokens(code);
  const tokens = await response.json();
  await handleOAuthResponse(tokens);
};

export const signOut = async () => {
  await AwsAuth.signOut();
  oAuthStorage.clearAll();
  const queryParams = {
    client_id: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
    logout_uri: window.location.origin
  };

  const queryString = Object.entries(queryParams)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  const URL = `${logoutEndpoint}?${queryString}`;
  window.open(URL, '_self');
};
