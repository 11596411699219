import React from 'react';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { Card } from 'core/components/card';
import useStyles from './additionals.styles';

const ABODE_TOOLTIP_TEXT =
  'This member is getting Abode Home Security monitoring and saving $300 off their insurance by singing up to the Abode Pro Plan.';

const Additionals = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.additionalsContainer}>
      <Label type="darkGreenMedium" className={classes.columnHeader} component="div">
        Additionals
      </Label>
      <Card className={classes.cardContainer}>
        <LabelTooltip
          className={classes.labelAndTooltipContainer}
          label="Abode Pro Plan"
          tooltip={{ label: 'More Info', onHoverText: ABODE_TOOLTIP_TEXT }}
        />
        <Grid container item alignItems="center">
          <Label type="titleLight">$100.00</Label>
          <Label type="paymentLabel" className={classes.payment}>
            12 MONTHS
          </Label>
        </Grid>
      </Card>
    </Grid>
  );
};

export default Additionals;
