import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    height: 34,
    fontWeight: 300,
    paddingLeft: theme.spacing(2),
    color: theme.colorPalette.green_100
  },
  footerContainer: {
    height: 98,
    display: 'flex',
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  footerAlertOrange: {
    background: theme.colorPalette.orange_10
  },
  footerAlertRed: {
    background: theme.colorPalette.red_20
  },
  floatingContainer: {
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 1000
  },
  footerContent: {
    width: '100%',
    maxWidth: 1280,
    height: 98,
    margin: theme.spacing(0, 'auto')
  },
  subtitle: {
    marginLeft: 10,
    color: theme.colorPalette.green_100
  },
  agentSold: {
    textAlign: 'right',
    color: theme.colorPalette.white_10
  },
  subtitleLink: {
    color: theme.colorPalette.white_10,
    borderBottom: `1px solid ${theme.colorPalette.white_10}`,
    marginLeft: '3px',
    cursor: 'pointer'
  },
  cancelledTextContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column'
  },
  cancelCallOut: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

export default useStyles;
