import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { IncompleteTasksNotification } from 'common/components/notifications/incomplete-tasks-notification';
import { TransferToAgencyNotification } from 'common/components/notifications/transfer-to-agency-notification';
import NotificationType from 'core/helpers/notifications';
import { NotificationCard } from 'core/components/notification-card';
import { useStore } from 'core/store';
import { CrossSellEligibilityNotification } from '../cross-sell-eligibility-notification';
import { SuspiciousAccountNotification } from '../suspicious-account-notification';
import useStyles from './customer-notifications.styles';

const Notification = {
  [NotificationType.Customer.CrossSellEligibility]: CrossSellEligibilityNotification,
  [NotificationType.Customer.SuspiciousAccount]: SuspiciousAccountNotification,
  [NotificationType.Customer.IncompleteTasks]: IncompleteTasksNotification,
  [NotificationType.Customer.TransferToAgency]: TransferToAgencyNotification
};

const CustomerNotifications = observer(({ type }) => {
  const {
    account: { notifications }
  } = useStore();
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      {notifications?.map((notificationType) => {
        const NotificationComponent = Notification[notificationType];
        return (
          <NotificationCard key={notificationType} type={type}>
            <NotificationComponent />
          </NotificationCard>
        );
      })}
    </Grid>
  );
});

export default CustomerNotifications;
