/* eslint-disable func-names */
import * as Yup from 'yup';

export const rentersValidationSchema = () =>
  Yup.object().shape({
    people: Yup.array(
      Yup.object().shape({
        firstName: Yup.string().trim().required('First Name is required'),
        lastName: Yup.string().trim().required('Last Name is required')
      })
    ),
    rentersCoverage: Yup.object().shape({
      rentersLocation: Yup.object().shape({
        address: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip: Yup.string().required('Zip is required')
      })
    })
  });
