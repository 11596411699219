import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import { Loading } from 'core';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { useStore } from 'core/store';
import caret from 'core/assets/svg/caretOrange.svg';
import useSession from 'core/hooks/use-session';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { track } from 'core/helpers/analytics';

import FeeBreakdown from '../fee-breakdown';
import PriceBreakdown from '../price-breakdown';
import EquityBreakdown from '../equity-breakdown';
import AccountingBreakdown from '../accounting-breakdown';
import PriceSummary from '../price-summary';
import useStyles from './price-details.styles';
import NotificationIconWhite from 'core/assets/svg/alert-white.svg';
import RenewalCompareIcon from 'core/assets/svg/ic-renewal.svg';
import CompareRenewalPriceFactorsModal from './compare-renewal-price-factors-modal';

function PriceDetails() {
  const store = useStore();
  const policyStore = store.account.policies.policy;
  const policiesList = store.account.policies.list;
  const { account } = store;
  const { billingDetails, writeOffTransactions, policy: currentPolicy, getRenewalPriceComparisonDetails } = policyStore;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const { isExternalSales, isAgency } = useSession();
  const [renewalPriceComparisonData, setRenewalPriceComparisonData] = useState({});
  const [loadingComparisonDetails, setLoadingComparisonDetails] = useState(true);

  const previousPolicy = policiesList
    .filter(
      (policy) =>
        // is it not the current policy
        policy.id !== currentPolicy.id &&
        // ensure that it matches and that it is not a home2 policy etc
        policy.id.slice(0, 15) === currentPolicy.id.slice(0, 15) &&
        // ensure policy types match
        policy.policyType === currentPolicy.policyType &&
        // ensure policies are active or inactive
        (getPolicyStatus(policy) === PolicyStatus.Inactive || getPolicyStatus(policy) === PolicyStatus.Active)
    )
    .sort((a, b) => b.term - a.term)?.[0];

  const priceComparisonModalAvailable =
    (currentPolicy?.policyType === 'A' || currentPolicy?.policyType === 'H') && currentPolicy.term > 1;

  useEffect(() => {
    const fetchIncreases = async () => {
      const { renewalPriceComparisonDetails } = await getRenewalPriceComparisonDetails(
        currentPolicy.id,
        previousPolicy.id
      );
      setRenewalPriceComparisonData(renewalPriceComparisonDetails);
      setLoadingComparisonDetails(false);
    };

    if (previousPolicy && priceComparisonModalAvailable) {
      fetchIncreases();
    }
  }, [policyStore]);

  const currentFees = currentPolicy?.fees?.reduce((acc, fee) => acc + fee.amount, 0) || 0;
  const currentSurplus = currentPolicy?.surplusContribution || 0;
  const currentPremium = currentPolicy?.premium || 0;
  const currentPolicyTotalPrice = (currentPremium + currentFees + currentSurplus).toFixed(2);

  const previousFees = previousPolicy?.fees?.reduce((acc, fee) => acc + fee.amount, 0) || 0;
  const previousSurplus = previousPolicy?.surplusContribution || 0;
  const previousPremium = previousPolicy?.premium || 0;
  const previousPolicyExitPrice = (previousPremium + previousFees + previousSurplus).toFixed(2);

  const policyPriceChange = currentPolicyTotalPrice - previousPolicyExitPrice;

  const toggleCompareModal = () => {
    const currentURL = window.location.pathname;
    if (!compareModalOpen) {
      track('Viewed Renewal Price Increase Modal', {
        policyID: currentPolicy.id,
        priceIncrease: policyPriceChange,
        policyType: currentPolicy.policyType,
        isAgency,
        memberAccountId: account.id
      });
      window.history.pushState({}, '', `${currentURL}?comparisonModalOpen=true`);
    } else {
      window.history.pushState({}, '', currentURL.replace('?comparisonModalOpen=true', ''));
    }
    setCompareModalOpen(!compareModalOpen);
  };

  return (
    <Section title="Price Details" type="SubSection">
      {compareModalOpen && (
        <CompareRenewalPriceFactorsModal
          open={compareModalOpen}
          onClose={toggleCompareModal}
          currentPolicy={currentPolicy}
          previousPolicy={previousPolicy}
          renewalPriceComparisonDetails={renewalPriceComparisonData}
          loading={loadingComparisonDetails}
          currentPolicyTotalPrice={currentPolicyTotalPrice}
          previousPolicyExitPrice={previousPolicyExitPrice}
        />
      )}
      <Card type="secondary">
        <Collapse in={open} collapsedSize={100}>
          <Card className={classes.container}>
            <Grid className={classes.cardHeader}>
              <PriceSummary
                billingDetails={billingDetails}
                writeOffTransactions={writeOffTransactions}
                premium={policyStore.premium}
                fees={policyStore.totalFeeCost}
                surplus={policyStore.surplusContribution}
              />
              <Button onClick={() => setOpen(!open)} variant="text" color="secondary">
                {!open ? 'See Breakdown' : 'Close Breakdown'}{' '}
                {<img className={open ? classes.icon : classes.iconFold} src={caret} alt={open ? 'fold' : 'unfold'} />}
              </Button>
            </Grid>
            {open && (
              <>
                <div className={classes.subSection}>
                  {policyStore.policyEquityStatus.status !== 'done' ? (
                    <Loading className={classes.loadingWheel} type="secondary" />
                  ) : (
                    <PriceBreakdown
                      premium={policyStore.premium}
                      fees={policyStore.totalFeeCost}
                      surplus={policyStore.surplusContribution}
                    />
                  )}
                </div>
                {!isExternalSales && (
                  <div className={classes.subSection}>
                    <AccountingBreakdown />
                  </div>
                )}
                <div className={classes.subSection}>
                  <FeeBreakdown />
                </div>
              </>
            )}
          </Card>
        </Collapse>
        {!isExternalSales && (
          <Card type="noBorders" className={classes.container}>
            <EquityBreakdown />
          </Card>
        )}
        {previousPolicy && priceComparisonModalAvailable && (
          <div className={classes.module}>
            <div className={classes.priceIncrease}>
              <div className={classes.iconSection}>
                <img className={classes.notificationIcon} src={NotificationIconWhite} alt="Notification Icon" />
                {`This policy's price has ${
                  policyPriceChange >= 0 ? (policyPriceChange > 0 ? 'increased' : 'remained the same') : 'decreased'
                } from the prior term.`}
              </div>
              <IconButton onClick={toggleCompareModal} className={classes.compareSection}>
                <div className={classes.compareLabel}>
                  <img className={classes.compareIcon} src={RenewalCompareIcon} alt="Compare Icon" />
                  Compare
                </div>
              </IconButton>
            </div>
          </div>
        )}
      </Card>
    </Section>
  );
}

export default observer(PriceDetails);
