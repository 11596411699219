import React, { lazy, Suspense, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FastField, Field, getIn, useFormikContext } from 'formik';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import { withStyles } from '@material-ui/core/styles';

import { Label } from 'core/components/label';
import { CognitoPermissionGroups, PermissionRequireTypes } from 'core/helpers/cognito-permission-groups';
import { useFormFieldPermissionHelpers, defaultPermissions } from 'core/hooks/use-form-field-permission-helper';
import { useDisabledState } from 'common/disabled-context/context';
import { fieldTypes } from './field-types';
import withSpacing from './with-spacing';
import styles from './form.styles';

const ValidPermissionProp = (propValue, key) => {
  const permission = propValue[key];
  if (!CognitoPermissionGroups[permission]) {
    return new Error(`Invalid 'permissions' group prop with value ${permission} supplied to 'FormField'`);
  }
};

/**
 * Renders a form field component based on the type provided.
 * The form field will be connected to the state of the closest parent Formik instance.
 *
 * @param {Object} props - The props for the form field.
 * @param {Object} props.fieldProps - The props for the field component.
 * @param {string} props.type - The type of the field.
 * @param {Object} props.props - The additional props for the field component.
 * @param {string} props.className - The CSS class name for the field component.
 * @param {boolean} props.fast - Indicates whether to use FastField for performance optimization. If a field is not performing/updating as expected, set this to false.
 * @param {Object} props.permissions - The permissions for the field.
 *    @param {boolean} props.permissions.isLicensedAction - Indicates whether the action should only be performed by a licensed agent. Default is true.
 *    @param {Object} props.permissions.edit - The edit permissions for the field.
 *        @param {Array} props.permissions.edit.groups - The groups that are allowed to edit the field. Send in constants from CognitoPermissionGroups in 'core/helpers/cognito-permission-groups'
 *        @param {string} props.permissions.edit.type - The type of edit permission. Either 'all' or 'atLeastOne'. Default is 'atLeastOne' meaning at least one of the groups in the above array is needed to edit the field.
 * @param {boolean} props.disabled - Indicates whether the field is disabled.
 * @param {boolean} props.ignoreGlobalDisabledState - Indicates whether to ignore the global disabled state.
 *    Global disabled state is set by the disabled context. Set to true when a field should be enabled no matter if the policy is cancelled.
 * @param {Object} props.rest - The rest of the props for the form field.'
 * @param {string} props.pii - Indicates whether the field contains PII and should be hidden from screen recording tools.
 * @returns {JSX.Element} The rendered form field component.
 */

const FormField = memo(
  ({
    fieldProps,
    type,
    props: elementProps,
    className,
    fast,
    permissions,
    disabled: manuallyDisabled,
    ignoreGlobalDisabledState,
    ...props
  }) => {
    const { disabled: disabledContext } = useDisabledState();
    const { id, name, pii, description, ...rest } = props;
    const FieldComponent = useMemo(() => lazy(fieldTypes[type]), [type]);
    const { allowedToModify } = useFormFieldPermissionHelpers({ permissions });
    const disableField = (!ignoreGlobalDisabledState && !!disabledContext) || !allowedToModify || manuallyDisabled;
    const shouldUseFastField = !disableField && fast;
    const FieldProvider = useMemo(() => (shouldUseFastField ? FastField : Field), [shouldUseFastField]);
    const { errors, touched } = useFormikContext();

    return (
      <Suspense fallback={null}>
        <FieldProvider id={id || name} name={name}>
          {({ form, field: { value } }) => (
            <FieldComponent
              field={{
                id: id || name,
                name,
                value,
                disabled: disableField,
                // TODO: Remove "no-margin-bottom" class once we migrate to use new forms in the whole application
                className: classNames(className, 'no-margin-bottom', { 'no-upscope': pii }),
                ...elementProps,
                ...rest
              }}
              ctx={{
                classes: {},
                ...form
              }}
              fieldProps={fieldProps}
            />
          )}
        </FieldProvider>
        {description && (
          <Label
            type="toolDescription"
            hasError={getIn(touched, name || id) && getIn(errors, name || id)}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {description}
          </Label>
        )}
      </Suspense>
    );
  }
);

FormField.propTypes = {
  fieldProps: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mode: PropTypes.string,
  props: PropTypes.object,
  className: PropTypes.string,
  fast: PropTypes.bool,
  ignoreGlobalDisabledState: PropTypes.bool,
  permissions: PropTypes.shape({
    isLicensedAction: PropTypes.bool,
    edit: PropTypes.shape({
      groups: PropTypes.arrayOf(ValidPermissionProp),
      type: PropTypes.oneOf([PermissionRequireTypes.All, PermissionRequireTypes.AtLeastOne])
    })
  }),
  pii: PropTypes.bool,
  description: PropTypes.string
};

FormField.defaultProps = {
  fieldProps: {},
  label: undefined,
  mode: undefined,
  props: {},
  className: '',
  id: '',
  fast: true,
  ignoreGlobalDisabledState: false,
  permissions: defaultPermissions,
  pii: false,
  description: undefined
};

export default flowRight(withStyles(styles), withSpacing)(FormField);
