import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  policyType as PolicyTypes,
  lookupsJson,
  paymentType as PaymentTypes,
  paymentMethod as PaymentMethods,
  allowInstallPayOnHomePoliciesStates
} from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { BankAccounts } from './bank-accounts';
import { CreditCards } from './credit-cards';
import { Mortgage } from './mortgage';
import { daysOfMonth } from './helpers';
import { RenewalPaymentOptions } from './renewal-payment-options';
import usePaymentFrequencyOptions from './hooks/use-payment-frequency-options';
import useStyles from './billing-details.styles';

const paymentMethodComponentMap = {
  [PaymentMethods.ACH]: BankAccounts,
  [PaymentMethods.Escrow]: Mortgage,
  [PaymentMethods.CreditCard]: CreditCards
};

const BillingDetails = ({ paymentType, state, policyType, isCancelled }) => {
  const { values, setFieldValue, setFieldTouched, initialValues } = useFormikContext();
  const classes = useStyles();
  const isMortgage = values.paymentMethod === PaymentMethods.Escrow;

  const defaultPaymentMethod = policyType === PolicyTypes.Auto ? PaymentMethods.CreditCard : PaymentMethods.Escrow;
  const PaymentMethod = paymentMethodComponentMap[values.paymentMethod || defaultPaymentMethod];

  const { frequencyOptions, isDisabled, tooltip } = usePaymentFrequencyOptions(paymentType, policyType, state);

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Label type="formSubTitle" className={classes.label}>
          Billing Details
        </Label>
      </Grid>
      <Grid container className={classes.row} spacing={4}>
        <FormField
          name="paymentMethod"
          type="select"
          label="Payment Method"
          options={
            policyType === PolicyTypes.Auto ? lookupsJson.autoPaymentMethod : lookupsJson.homeownersPaymentMethod
          }
          mode="dark"
          xs={4}
          permissions={{ isLicensedAction: false }}
          onChange={(val) => {
            if (val === PaymentMethods.CreditCard && policyType === PolicyTypes.Home) {
              // forcing one-time payments for credit cards in certain states
              // but if the user has already selected monthly, we don't want to change it
              const paymentType =
                allowInstallPayOnHomePoliciesStates[state] || initialValues.paymentType === PaymentTypes.Monthly
                  ? PaymentTypes.Monthly
                  : PaymentTypes.OneTime;
              setFieldValue('paymentType', paymentType);
              setFieldTouched('paymentType', true);
            }
          }}
        />
        {tooltip ? (
          <Grid item xs={4}>
            <LabelTooltip
              label="Payment frequency"
              tooltip={{
                label: 'More Info',
                onHoverText: tooltip
              }}
            >
              <FormField
                name="paymentType"
                type="select"
                mode="dark"
                disabled={isMortgage || isDisabled}
                options={
                  isMortgage ? frequencyOptions : frequencyOptions.filter((opt) => opt.id !== PaymentTypes.Escrow)
                }
                permissions={{ isLicensedAction: false }}
              />
            </LabelTooltip>
          </Grid>
        ) : (
          <FormField
            name="paymentType"
            type="select"
            label="Payment Frequency"
            mode="dark"
            xs={4}
            disabled={isMortgage || isDisabled}
            options={isMortgage ? frequencyOptions : frequencyOptions.filter((opt) => opt.id !== PaymentTypes.Escrow)}
            permissions={{ isLicensedAction: false }}
          />
        )}

        {(values.paymentType === PaymentTypes.Monthly || values.renewalPaymentType === PaymentTypes.Monthly) && (
          <Grid item xs={4}>
            <FormField
              name="billingDayOfMonth"
              label="Billing Day"
              type="select"
              mode="dark"
              icon="calendar"
              options={daysOfMonth}
              permissions={{
                edit: {
                  groups: [CognitoPermissionGroups.canEditBillingDay]
                }
              }}
            />
          </Grid>
        )}
        <PaymentMethod frequencyOptions={frequencyOptions} isCancelled={isCancelled} />
        <RenewalPaymentOptions />
      </Grid>
    </>
  );
};

BillingDetails.propTypes = {
  paymentType: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  policyType: PropTypes.string.isRequired,
  isCancelled: PropTypes.bool.isRequired
};

export default BillingDetails;
