import * as Yup from 'yup';

import { validUSState } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

export const mortgageValidationSchema = Yup.object().shape({
  loanNumber: Yup.string().required('Loan number is required'),
  // eslint-disable-next-line func-names
  mortgageHolderName: Yup.string().test('mortgageHolderName', function (value) {
    if (value) {
      return true;
    }
    const { isCustom } = this.parent;
    return this.createError({ message: `Lender${isCustom ? ' name' : ''} is required` });
  }),
  mortgageHolderAddress: Yup.object().shape({
    address: Yup.string().required('Address is required'),
    address2: Yup.string().nullable(),
    city: Yup.string().required('City is required'),
    state: Yup.string().validUSState('offer.mortgageHolderAddress.state').required('State is required'),
    zip: Yup.string().required('Zip code is required')
  })
});
