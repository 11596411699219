// NOTE: This settings file is intended to have anything that's related to state configs

export const restrictedPurchaseStates = ['FL', 'GA', 'LA', 'MA', 'MD', 'MI'];

export const restrictedButAllowAgencyPartnerPagesStates = ['GA', 'MI'];

export const pdInUMStates = ['DC', 'SC', 'WV'];

export const restrictedPurchaseOnlyForDirectStates = [
  'AR',
  'DC',
  'FL',
  'GA',
  'IL',
  'IN',
  'IA',
  'KY',
  'LA',
  'MA',
  'MD',
  'MI',
  'MS',
  'MO',
  'MT',
  'NE',
  'NM',
  'ND',
  'OK',
  'PA',
  'TX',
  'WV',
  'WA'
];
