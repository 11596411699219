/* eslint-disable func-names */
import * as Yup from 'yup';
import { isAfter } from 'date-fns';
import { lookupsJson } from '@ourbranch/lookups';
import { localToUtcTime } from '@ourbranch/date-helpers';
import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';

export const connectedHomeSchema = (isAdvancedConnectedHome, isAgency) =>
  Yup.object().shape({
    connectedHome: Yup.object()
      .shape({
        monitored: Yup.string(),
        moistureDevices: Yup.number()
          .min(0, 'Please enter a positive number')
          .required('This is required, if none enter 0'),
        motionDetectingDevices: Yup.number()
          .min(0, 'Please enter a positive number')
          .test('ch-on', 'This is required, if none enter 0', function (value, context) {
            return (
              !context.parent.monitored || isAdvancedConnectedHome || context.parent?.monitored === 'N' || value >= 0
            );
          })
          .nullable(),
        smokeDetectingDevices: Yup.number()
          .min(0, 'Please enter a positive number')
          .required('This is required, if none enter 0'),
        theftPreventionDevices: Yup.number()
          .min(0, 'Please enter a positive number')
          .required('This is required, if none enter 0'),
        autoWaterShutoffDevices: Yup.boolean().required(),
        providerName: Yup.string()
          .test('ch-on', 'Required', function (value, context) {
            return !context.parent.monitored || context.parent?.monitored === 'N' || value?.length;
          })
          .test(
            'connected home new sign up still available',
            'The new sign up offer for this home security provider has expired, please delete the discount and add again with a different provider',
            function (value) {
              if (
                this.options.context.global.homeSecurityPartnerCustomerType === NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE
              ) {
                if (
                  lookupsJson.securityProviders.find((p) => p.id === value)?.[
                    isAgency ? 'agencyPromotionDeadlineToBind' : 'internalPromotionDeadlineToBind'
                  ] &&
                  isAfter(
                    localToUtcTime(new Date(), this.options.context.home.homeLocation.state),
                    localToUtcTime(
                      new Date(
                        lookupsJson.securityProviders.find((p) => p.id === value)?.[
                          isAgency ? 'agencyPromotionDeadlineToBind' : 'internalPromotionDeadlineToBind'
                        ]
                      ),
                      this.options.context.home.homeLocation.state
                    )
                  )
                ) {
                  return false;
                }
                if (
                  !lookupsJson.securityProviders.find((p) => p.id === value)?.[
                    isAgency ? 'agencyPromotionDeadlineToBind' : 'internalPromotionDeadlineToBind'
                  ]
                ) {
                  // all CHD new sign ups must have an end date now, even if it's in 2099
                  return false;
                }
              }
              return true;
            }
          )
      })
      .nullable()
  });
