import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../../common/verify-property-container.styles.js';

const FLHomeUseQuestions = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Label className={classes.sectionTitle}>Home use</Label>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Is this your primary home?</Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Branch does not insure secondary/seasonal dwelling policies unless the member already have their primary
          residence insured with us. However, Branch company policy prohibits the sale of a secondary/seasonal dwelling
          policy in any way being conditioned on the purchase of another policy with Branch. Agents must avoid creating
          even the appearance of violating this company policy.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.isPrimaryHome"
          name="home.isPrimaryHome"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Does your home have a trampoline, skateboard ramp, bike ramp, water slide, diving board, or an empty or
          unfenced pool?
        </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Branch does not cover properties with any of these items.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.hasTrampolinesOrSkateBoardRampEtc"
          name="home.hasTrampolinesOrSkateBoardRampEtc"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Do you rent out this home to others? Either multiple times a year or full-time to one tenant.
        </Label>
        <FormField
          className={classes.formItem}
          id="home.hadRentedMultTimesOrFullTimeOneTenant"
          name="home.hadRentedMultTimesOrFullTimeOneTenant"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Do you run a daycare out of your home? </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          In order for Branch to insure this property, the potential member will need to submit a copy of their current
          commercial liability policy for Underwriting review.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.runDayCareOutOfMyHome"
          name="home.runDayCareOutOfMyHome"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Do you run any other business, not including daycare, out of your home?
        </Label>
        <FormField
          className={classes.formItem}
          id="home.hasCommercialOccupation"
          name="home.hasCommercialOccupation"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
    </Grid>
  );
};
export default FLHomeUseQuestions;
