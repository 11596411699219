import React from 'react';

import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import { Tooltip } from 'core/components/tooltip';
import Block from 'core/assets/svg/ic-block.svg';
import { getErrorData } from '../get-error-data';
import useStyles from './blocks.styles';

export const Blocks = ({ blocks }) => {
  const classes = useStyles();
  const { isAgency, isInternalAgent } = useSession();
  const { offer: store } = useStore();
  return (
    <div className={classes.container}>
      {blocks.map((error) => {
        const blockData = getErrorData({
          error,
          isAgency,
          state: store.state
        });

        return (
          <div className={classes.block} key={`block-${blockData.code}`}>
            {/* top row */}
            <div className={classes.iconRow}>
              {/* left content */}
              <div className={classes.row}>
                <img src={Block} alt="block" />
                {/* stacked block details */}
                <div className={classes.titleColumn}>
                  <div className={classes.code}>BLOCK: {blockData.code}</div>
                  <div className={classes.title}>{blockData?.title || 'Unknown Block'}</div>
                </div>
              </div>

              {/* right content */}
              {isInternalAgent && blockData?.branchPlusEligible && (
                <div className={classes.branchPlusEligible}>
                  Branch Plus eligible
                  <Tooltip
                    text="Although we may not be able to write this coverage due to this reason, our partnered providers typically do."
                    placement="top"
                  />
                </div>
              )}
            </div>

            {/* subtitle row */}
            <div className={classes.subtitle}>{blockData?.subtitle || blockData?.message}</div>

            {/* optional place to add a "why" section later */}
          </div>
        );
      })}
    </div>
  );
};

export default Blocks;
