import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Grid, Divider, Box } from '@material-ui/core';

import { Label } from 'core';
import Section from 'core/components/section';
import CollapsibleCard from 'core/components/collapsible-card';
import ValueField from 'core/components/value-field';
import LabelWithCopyButton from 'core/components/label-with-copy-button';
import { labelStyleMap } from '../constants';
import { phoneNumberToStringFormatter } from 'core/helpers/formatters';
import useStyles from './exposure-info.styles';

const determineAffectedItem = (exposure) => {
  if (exposure.claimType === 'auto') {
    return `${exposure.vehicleYear} ${exposure.vehicleMake} ${exposure.vehicleModel}`;
  }
  return 'N/A';
};

const ExposureInfo = ({ currentClaimExposureDetail }) => {
  const classes = useStyles();

  return (
    <Section>
      {currentClaimExposureDetail.map(
        (exposure) =>
          exposure.exposureStatus.toLowerCase() !== 'drafted' &&
          exposure.exposureStatus.toLowerCase() !== 'cancelled' && (
            <CollapsibleCard
              key={exposure.exposureNumber}
              id={exposure.exposureNumber}
              className={classes.exposureCard}
              content={<Label type="darkGreenBig">{exposure.exposureName || exposure.exposureType || 'Unknown'}</Label>}
              contentRight={
                <Label type={`${labelStyleMap[exposure.exposureStatus || 'UNKNOWN']}`}>
                  {' '}
                  &#x25CF; {exposure.exposureStatus}
                </Label>
              }
            >
              <Box>
                <Divider variant="fullWidth" />
                <Box className={classes.exposureBox}>
                  <Grid container flex="row" className={classes.exposureInfo}>
                    <Grid item xs={6}>
                      <ValueField label="Claims Rep" value={exposure.currentAdjusterName || 'N/A'} />
                    </Grid>
                    <Grid item xs={6}>
                      <ValueField
                        label="Phone"
                        value={
                          <LabelWithCopyButton className={classes.copyableLabel}>
                            {exposure.currentAdjusterPhone
                              ? phoneNumberToStringFormatter(exposure.currentAdjusterPhone)
                              : 'N/A'}
                          </LabelWithCopyButton>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container flex="row" className={classes.exposureInfo}>
                    <Grid item xs={6}>
                      <ValueField
                        label="Email"
                        value={<LabelWithCopyButton>{exposure.currentAdjusterEmail || 'N/A'}</LabelWithCopyButton>}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ValueField label="Affected Item" value={determineAffectedItem(exposure) || 'N/A'} />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CollapsibleCard>
          )
      )}
    </Section>
  );
};

ExposureInfo.propTypes = {
  currentClaimExposureDetail: PropTypes.array.isRequired
};

export default observer(ExposureInfo);
