import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
import { format } from 'date-fns';

import checkIcon from 'core/assets/svg/check-green.svg';
import { Button } from 'core/components/button';
import { NotificationCard } from 'core/components/notification-card';
import { useToast } from 'core/components/toast';
import { phoneNumberToStringFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import emailIcon from '../assets/email-icon-orange.svg';
import smsIcon from '../assets/sms-icon-orange.svg';
import copyIcon from '../assets/copy-squares-icon.svg';
import { Method } from '../helpers';
import useStyles from './purchase-link-sent.styles';

const PurchaseLinkSent = ({ onSendLink }) => {
  const classes = useStyles();
  const {
    offer: { offer, loading }
  } = useStore();
  const { purchaseLinkSentTimestamp, purchaseLinkSentBy, purchaseLink } = offer;
  const toast = useToast();
  const { values } = useFormikContext();

  const copyLink = () => {
    const copied = copy(purchaseLink);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'The link is copied to your clipboard. This link is for customer use ONLY.'
      });
    } else {
      toast.notify({
        type: 'error',
        message: `We could not copy the link, but here it is: ${purchaseLink}`
      });
    }
  };

  return (
    <div className={classes.container}>
      <NotificationCard icon={checkIcon} className={classes.notification} type="light">
        Link sent on {format(new Date(purchaseLinkSentTimestamp), 'MM/dd/yyyy @ h:mm a')}. An email to{' '}
        {purchaseLinkSentBy} will be sent once the purchase is complete.
      </NotificationCard>
      <div className={classes.links}>
        {values.email && (
          <Button disabled={loading} variant="text" color="secondary" onClick={() => onSendLink(Method.Email)}>
            <span className={classes.link}>
              <img alt="Outlined orange envelope" src={emailIcon} />
              Re-send link to {values.email}
            </span>
          </Button>
        )}
        {values.phone && (
          <Button disabled={loading} variant="text" color="secondary" onClick={() => onSendLink(Method.Sms)}>
            <span className={classes.link}>
              <img alt="Outlined orange cellphone and speech balloon" src={smsIcon} />
              Re-send link to {phoneNumberToStringFormatter(values.phone)}
            </span>
          </Button>
        )}
        <Button disabled={loading} variant="text" color="secondary" onClick={copyLink}>
          <span className={classes.link}>
            <img alt="Two overlapped squares" src={copyIcon} />
            Copy purchase link to clipboard
          </span>
        </Button>
      </div>
    </div>
  );
};

PurchaseLinkSent.propTypes = {
  onSendLink: PropTypes.func.isRequired
};

export default observer(PurchaseLinkSent);
