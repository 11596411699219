import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: 28,
      fontWeight: 300
    },
    button: {
      marginTop: 50,
      width: '50%',
      alignSelf: 'end'
    },
    container: {
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    descopeWidget: {
      width: 480,
      margin: '20px auto'
    }
  })
);
