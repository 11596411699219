import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { stripeCustomerUrl } from '@ourbranch/be-constants';
import { getValue, paymentMethod, paymentType, policyType } from '@ourbranch/lookups';
import classNames from 'classnames';
import isBefore from 'date-fns/isBefore';
import { isSameDay } from 'date-fns';
import { observer } from 'mobx-react';

import { ViewSchedule } from 'common/components/payment/view-schedule';
import { Loading } from 'core';
import useSession from 'core/hooks/use-session';
import { Card } from 'core/components/card';
import { Tooltip } from 'core/components/tooltip';
import ValueField from 'core/components/value-field';
import { awsDateToDateFormatter, currencyFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { HoldPaymentForm } from '../hold-payment-form';
import { ManualChargeForm } from '../manual-charge-form';
import { PastPayments } from '../past-payments';
import { RefundCharge } from '../refund-charge';
import { PaymentMethodLabel } from '../payment-method-label';
import { PayAheadModal } from '../pay-ahead-modal';
import { PayAheadDisabledModal } from '../pay-ahead-modal/pay-ahead-disabled';
import questionIcon from 'customer/assets/questionIcon.svg';
import useStyles from '../payment.styles';

const maskingDotsText = '\u25CF\u25CF\u25CF\u25CF';

export function getBillingPaymentMethod(method, brand, last4, className) {
  if (!method && !brand && !last4) {
    return 'Unknown';
  }
  if (method === paymentMethod.Escrow) {
    return getValue('homeownersPaymentMethod', method) || getValue('condoPaymentMethod', method);
  }
  if (method === 'Check') {
    return method;
  }
  if (!brand && last4) {
    return (
      <>
        <PaymentMethodLabel className={className}>
          ACH/ETF <span>{maskingDotsText}</span>
        </PaymentMethodLabel>
        {last4}
      </>
    );
  }
  return (
    <>
      {brand && <PaymentMethodLabel className={className}>{brand}</PaymentMethodLabel>}
      <PaymentMethodLabel className={className}>
        {maskingDotsText}
        {last4}
      </PaymentMethodLabel>
    </>
  );
}

function getNextPaymentDue(amount, date, escrowPastDue) {
  if (escrowPastDue && amount) {
    return `${currencyFormatter(amount)} Unpaid`;
  }

  if (date && amount && amount > 0) {
    return `${currencyFormatter(amount)} on ${awsDateToDateFormatter(date)}`;
  }
  if (amount < 0) {
    return `${currencyFormatter(amount, { removeNegativeSign: true })}`;
  }

  return 'On Policy Renewal';
}

function getNextPaymentDueLabel({ escrowPastDue, hasBillingHold, nextPaymentAmount, nextPaymentDate }) {
  if (hasBillingHold) {
    return 'Payment Held Until';
  }

  if (escrowPastDue) {
    return 'Past Due';
  }

  if (nextPaymentAmount < 0 && !nextPaymentDate) {
    return 'Refund owed';
  }

  return `Next Payment ${nextPaymentAmount ? 'Due' : ''}`;
}

const BillingDetails = observer(function BillingDetails() {
  const classes = useStyles();
  const { account } = useStore();
  const { canManuallyChargePolicy, canManageBillingHolds, isAgency, canRefundStripePayment } = useSession();
  const [cardDetails, setCardDetails] = useState(null);
  const [openHoldPaymentForm, setOpenHoldPaymentForm] = useState(false);
  const [openPayAheadModal, setOpenPayAheadModal] = useState(false);
  const [openDisabledPayAheadModal, setOpenDisabledPayAheadModal] = useState(false);

  const {
    policies: {
      policy: { billingDetails, policy, writeOffTransactions, allTransactions, hasBillingHold, isOverdue }
    }
  } = account;
  const policyStatus = getPolicyStatus(policy);
  const atLeastOnePaidPayment = allTransactions.some((transaction) => transaction.paymentStatus === 'paid');
  // if allTransactions.length is less than 2, default to atLeastOnePaidPayment
  const lastTwoPaymentsNotFailed =
    allTransactions.length > 1
      ? allTransactions[0].paymentStatus !== 'failed' || allTransactions[1].paymentStatus !== 'failed'
      : atLeastOnePaidPayment;

  const hasDoneSuccessfulPayments = atLeastOnePaidPayment && lastTwoPaymentsNotFailed;

  const showHoldPaymentsButton =
    hasDoneSuccessfulPayments ||
    canManageBillingHolds ||
    (!isAgency && !canManageBillingHolds && hasDoneSuccessfulPayments);

  const cancelled = policyStatus === PolicyStatus.InCancellation || policyStatus === PolicyStatus.Cancelled;

  useEffect(() => {
    if (!cardDetails && billingDetails) {
      const { brand, last4 } = billingDetails?.activePaymentMethod || {};
      setCardDetails({
        brand,
        last4
      });
    }
  }, [cardDetails, billingDetails, setCardDetails]);

  const toggleHoldPaymentForm = useCallback(() => {
    setOpenHoldPaymentForm(!openHoldPaymentForm);
  }, [setOpenHoldPaymentForm, openHoldPaymentForm]);

  const {
    transactions,
    nextPaymentDate,
    nextPaymentAmount,
    remainingPayments,
    nextPayments,
    totalInstallments,
    totalRemaining
  } = billingDetails || {};

  const paid = useMemo(() => {
    if (transactions) {
      return transactions.filter((t) => ['paid', 'partially refunded', 'pending'].includes(t.paymentStatus));
    }
  }, [transactions]);
  const escrowPastDue =
    policy.paymentType === paymentType.Escrow &&
    totalRemaining > 0 &&
    transactions.filter((t) => t.paymentStatus === 'unpaid').length > 0 &&
    !isBefore(new Date(), new Date(policy.effectiveDate)) &&
    !isSameDay(new Date(), new Date(policy.effectiveDate));

  const paidAmount = useMemo(() => {
    if (paid) {
      const writeOffTotal = writeOffTransactions.reduce((total, writeOff) => total + writeOff.amount, 0);
      return paid.reduce((prev, t) => t.paymentAmount - t.paymentRefunded + prev, 0) + writeOffTotal;
    }
    return 0;
  }, [paid, writeOffTransactions]);

  const pastDue =
    escrowPastDue ||
    (transactions &&
      Array.isArray(transactions) &&
      transactions.length > 0 &&
      transactions[0].paymentStatus === 'failed' &&
      totalRemaining > 0);

  if (!billingDetails || !policy) {
    return <Loading type="secondary" />;
  }

  const payAheadDisabled = hasBillingHold || totalRemaining <= 0 || isOverdue || cancelled;
  return (
    <Card type="secondary">
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        className={classNames(classes.billingDetails, { [classes.pastDueRow]: pastDue })}
      >
        <ValueField
          className={classes.billingInfo}
          label="Amount Paid/Pending"
          value={
            policy.paymentType === paymentType.Escrow ? (
              currencyFormatter(paidAmount)
            ) : (
              <a
                className={classes.stripeCustomerLink}
                href={`${stripeCustomerUrl}${policy.stripeCustomerId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {currencyFormatter(paidAmount)}
              </a>
            )
          }
          mode="dark"
        />
        <ValueField
          className={classes.billingInfo}
          label={getNextPaymentDueLabel({ escrowPastDue, hasBillingHold, nextPaymentAmount, nextPaymentDate })}
          value={
            <div>
              <div>{getNextPaymentDue(nextPaymentAmount, nextPaymentDate, escrowPastDue)}</div>
              {showHoldPaymentsButton && !openHoldPaymentForm && (
                <div className={classes.holdPaymentButtonContainer}>
                  <Button
                    className={classes.holdPaymentButton}
                    onClick={() => {
                      setOpenHoldPaymentForm(true);
                    }}
                    variant="text"
                    color="secondary"
                    disabled={!canManageBillingHolds}
                  >
                    {hasBillingHold ? 'Edit Payment Hold' : 'Add a payment hold'}
                  </Button>
                  {!canManageBillingHolds && (
                    <Tooltip
                      className={classes.holdPaymentWarning}
                      text="Billing holds are only permitted if the member has paid ahead on their upcoming installment. Please follow your designated process to place a billing hold."
                      placement="bottom"
                      label="More Info"
                    />
                  )}
                </div>
              )}
              {policy.paymentType === paymentType.Monthly && (
                <div className={classes.holdPaymentButtonContainer}>
                  <Button
                    className={classes.holdPaymentButton}
                    onClick={() => {
                      setOpenPayAheadModal(true);
                    }}
                    variant="text"
                    color="secondary"
                    disabled={payAheadDisabled}
                  >
                    Pay Ahead
                  </Button>
                  {payAheadDisabled && (
                    <Button
                      onClick={() => {
                        setOpenDisabledPayAheadModal(true);
                      }}
                    >
                      <img src={questionIcon} alt={'Pay Ahead Disabled'} />
                    </Button>
                  )}
                </div>
              )}
            </div>
          }
          mode="dark"
        />
        {policy.paymentType === paymentType.Monthly && (
          <div>
            <ValueField
              label="Remaining Payments"
              value={`${remainingPayments} out of ${totalInstallments}`}
              mode="dark"
            />
            <ViewSchedule nextPayments={nextPayments} />
          </div>
        )}
        {cardDetails && (
          <ValueField
            className={classes.billingInfo}
            label="Payment Method"
            value={
              <div>
                {policy.paymentMethod === paymentMethod.CreditCard
                  ? getBillingPaymentMethod(
                      paymentMethod.CreditCard,
                      cardDetails.brand,
                      cardDetails.last4,
                      classes.creditCardLast4
                    )
                  : getValue(
                      policy.policyType === policyType.Condo ? 'condoPaymentMethod' : 'homeownersPaymentMethod',
                      policy.paymentMethod
                    )}
              </div>
            }
            mode="dark"
          />
        )}
      </Grid>
      {openHoldPaymentForm && (
        <Grid container>
          <HoldPaymentForm close={toggleHoldPaymentForm} />
        </Grid>
      )}
      {canManuallyChargePolicy && <ManualChargeForm />}
      <PastPayments transactions={allTransactions} getBillingPaymentMethod={getBillingPaymentMethod} />

      {policy.paymentType === paymentType.Monthly && (
        <PayAheadModal open={openPayAheadModal} onClose={() => setOpenPayAheadModal(false)} />
      )}
      {payAheadDisabled && (
        <PayAheadDisabledModal open={openDisabledPayAheadModal} onClose={() => setOpenDisabledPayAheadModal(false)} />
      )}
      {canRefundStripePayment && <RefundCharge transactions={allTransactions} />}
    </Card>
  );
});

export default BillingDetails;
