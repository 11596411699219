import queryString from 'query-string';
import Prismic from 'prismic-javascript';

export const ContentTypes = {
  CarriersInformation: 'carriers_information'
};

// Mapped query operators
const validOperators = {
  and: 'at',
  or: 'any',
  '>': 'gt',
  '<': 'lt',
  between: 'inRange',
  in: 'in',
  not: 'not'
};

const mapOptionsToQuery = (type, queryOptions) =>
  Object.entries(queryOptions).reduce((res, [key, { value, isCustom = false, operator = 'and' }]) => {
    const mappedOperator = validOperators[operator];
    const path = isCustom ? `my.${type}.${key}` : `document.${key}`;
    if (!mappedOperator) {
      return res;
    }
    return [...res, Prismic.Predicates[mappedOperator](path, value)];
  }, []);

const apiEndpoint = 'https://branch.prismic.io/api/v2';
let _masterRef = null;

const getMasterRef = () => {
  if (_masterRef) {
    return Promise.resolve(_masterRef);
  }
  return fetch(apiEndpoint)
    .then((response) => response.json())
    .then((data) => data.refs.find((ref) => ref.isMasterRef))
    .then((ref) => {
      _masterRef = ref;
      return _masterRef;
    });
};

export const getSingle = (documentType, fetchLinks = null, ref = null) => {
  return getMasterRef()
    .then((masterRef) => {
      const query = queryString.stringify({
        page: 1,
        pageSize: 1,
        fetchLinks,
        ref: ref || masterRef.ref,
        q: `[[at(document.type, "${documentType}")]]`
      });

      return fetch(`https://branch.prismic.io/api/v2/documents/search?${query}`);
    })
    .then((response) => response.json())
    .then((response) => {
      if (response && response.results && response.results.length) {
        return response.results[0];
      }
    });
};

export const Client = (req = null) => Prismic.client(apiEndpoint, { req });

export const getRepeatable = async (documentType, uid, req, ref, otherOptions = {}) => {
  const content = await Client(req).getByUID(documentType, uid, { ...otherOptions, ref });
  if (!content || !content.data) {
    throw new Error(`Content not found for Document type: ${documentType}, uid: ${uid}`);
  }
  return content;
};

export const getByQuery = (documentType, req, queryOptions, ref, otherOptions = {}) => {
  const queryParams = [
    ...mapOptionsToQuery(documentType, queryOptions),
    Prismic.Predicates.at('document.type', documentType)
  ];

  return Client(req).query(queryParams, { ...otherOptions, ref });
};
