import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  separator: {
    width: '100%',
    borderBottom: `1px solid ${theme.colorPalette.beige_10}`,
    margin: theme.spacing(5, 0, 4)
  },
  garageAddressContainer: {
    marginBottom: theme.spacing(2)
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
    margin: theme.spacing(4, 0, 6),
    backgroundColor: theme.colorPalette.beige_10,
    color: theme.colors.light_text,
    justifyContent: 'center',
    width: '100%'
  },
  label: {
    display: 'flex',
    fontWeight: 400
  }
}));

export default useStyles;
