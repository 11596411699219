import { types, flow, getEnv, getParent } from 'mobx-state-tree';
import orderBy from 'lodash-es/orderBy';
import { differenceInDays, isPast, parseISO } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { clean } from '@ourbranch/policy-utils';
import { onboardingTasks, reopeningStates } from '@ourbranch/lookups';
import { policyTypes } from '@ourbranch/policy-types';

import { types as GQLTypes } from 'core/helpers/sanitize';
import Notification, { isPolicyCrossSellEligible } from 'core/helpers/notifications';
import { checkIfUnauthorizedAndPushToSearchPage, formattedError } from 'core/helpers/quoter.service';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import {
  RESET_PWD,
  UPDATE_ACCOUNT,
  FLAG_SUSPICIOUS
} from 'customer/components/account-details/account-details.queries';
import { CHANGE_SEGMENT, CONFIRM } from 'customer/components/policy/policy.queries';
import { getMerged } from 'customer/components/policy/merge-segments';
import {
  GET_ACCOUNT,
  GET_FULL_ACCOUNT,
  GET_ACCOUNT_POLICY,
  GET_TICKETS,
  GET_FRONT_CONTACT,
  GET_ACCOUNT_CLAIMS,
  SEND_EVENT_AS_MEMBER,
  COMPLETE_TASK,
  RESET_TASK,
  GET_DOCS_AND_TASKS,
  GET_ACCOUNT_BRANCH_PLUS_POLICY,
  GET_SNAPSHEET_EXPOSURE_DETAIL,
  GET_SNAPSHEET_NOTES_DETAIL
} from './account.queries';
import { PoliciesStore } from './policies-store';

const AdditionalPhoneNumber = types.model({
  phoneNumber: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  canText: types.maybeNull(types.boolean)
});
const Error = types.model({
  code: types.union(types.string, types.number),
  message: types.maybeNull(types.string),
  data: types.maybeNull(types.frozen())
});

export const AccountStore = types
  .model({
    id: types.string,
    fname: types.string,
    lname: types.string,
    phoneNumber: types.string,
    additionalPhoneNumbers: types.maybeNull(types.optional(types.array(AdditionalPhoneNumber), [])),
    email: types.string,
    created: types.string,
    mailingAddress: types.maybeNull(types.frozen()),
    accountLoading: types.optional(types.boolean, false),
    ticketsLoading: types.optional(types.boolean, false),
    frontTicketsLoading: types.optional(types.boolean, false),
    tickets: types.maybeNull(
      types.model({
        frontTickets: types.frozen(),
        pointers: types.maybeNull(
          types.model({
            current: types.optional(types.string, ''),
            next: types.optional(types.string, ''),
            prev: types.optional(types.array(types.string), [])
          })
        ),
        sendGridEmails: types.array(types.frozen())
      }),
      null
    ),
    policies: types.maybe(PoliciesStore),
    branchPlusPolicies: types.model({
      list: types.array(types.frozen(), []),
      branchPlusPolicy: types.model({
        loading: types.optional(types.boolean, false),
        policy: types.maybeNull(types.frozen())
      }),
      loading: types.optional(types.boolean, false)
    }),
    pledges: types.frozen(),
    community: types.frozen(),
    inviter: types.maybeNull(types.frozen()),
    claims: types.optional(types.array(types.frozen()), [], [null, undefined]),
    claimsLoading: types.optional(types.boolean, false),
    exposureDetails: types.optional(types.array(types.frozen()), [], [null, undefined]),
    exposureDetailsLoading: types.optional(types.boolean, false),
    notesDetail: types.optional(types.array(types.frozen()), [], [null, undefined]),
    notesDetailLoading: types.optional(types.boolean, false),
    notifications: types.maybeNull(types.array(types.string)),
    tasks: types.optional(types.array(types.frozen()), []),
    customerMetadata: types.maybeNull(types.frozen()),
    tasksError: types.maybeNull(types.string),
    frontContactURL: types.maybeNull(types.string),
    errors: types.array(Error)
  })
  .actions((self) => ({
    handleError(error) {
      const parsed = formattedError(error);
      if (parsed) {
        self.accountLoading = false;
        const errors = [...self.errors, parsed];
        self.errors = errors;
      }
      return parsed;
    },
    fetchFullAccount: flow(function* fetchFullAcount(id, history, isAgency) {
      const { client } = getEnv(self);
      self.tasksError = null;
      self.accountLoading = true;
      self.policies.loading = true;
      self.branchPlusPolicies.loading = true;
      self.errors = [];
      try {
        const { data } = yield client.query({
          query: GET_FULL_ACCOUNT,
          variables: {
            id
          }
        });
        const {
          account,
          documents,
          community,
          pledges,
          unsignedApplications,
          unsignedBixConversions,
          customerMetadata,
          tasks: { success, tasks, error },
          branchPlusPolicies
        } = data;
        self.setAccount(account);
        self.policies.list = account.policies;
        self.branchPlusPolicies.list = branchPlusPolicies;
        self.policies.documents = documents;
        self.pledges = pledges;
        self.community = community;
        self.policies.unsignedApplications = unsignedApplications;
        self.policies.unsignedBixConversions = unsignedBixConversions || [];
        self.customerMetadata = customerMetadata;
        self.fetchAffinityData();
        if (success) {
          self.setOngoingTasks(tasks);
        } else {
          self.tasksError = error;
        }
      } catch (error) {
        self.handleError(error);
        checkIfUnauthorizedAndPushToSearchPage(error, history);
      } finally {
        self.accountLoading = false;
        self.policies.loading = false;
        self.branchPlusPolicies.loading = false;
        self.setNotifications(isAgency);
      }
    }),
    fetchFullAccountAndPolicy: flow(function* fetchFullAccountAndPolicy(id, accountId, history, isAgencyUser) {
      const { client } = getEnv(self);
      self.errors = [];
      self.tasksError = null;
      self.accountLoading = true;
      self.policies.policy.loading = true;
      self.policies.policy.holdCardLoading = true;
      self.policies.loadingDocuments = true;
      try {
        const { data } = yield client.query({
          query: GET_ACCOUNT_POLICY,
          variables: {
            id,
            accountId
          }
        });
        const {
          account,
          billingDetails,
          writeOffTransactions,
          documents,
          holdcards,
          inspection,
          unsignedBixConversions,
          unsignedApplications,
          customerMetadata,
          violationsForRenewal,
          tasks: { success, tasks, error },
          policyPaidAndPendingCash: { currentTotalPaid, pendingAmount }
        } = data;
        self.policies.list = account.policies;
        self.fetchAffinityData();
        self.setAccount(account);
        // fill out child PolicyStore info
        self.policies.policy.billingDetails = billingDetails;
        self.policies.policy.writeOffTransactions = writeOffTransactions;
        self.policies.policy.holdCards = holdcards;
        self.policies.policy.inspection = inspection;
        self.policies.unsignedApplications = unsignedApplications;
        self.policies.documents = documents;
        self.customerMetadata = customerMetadata;
        self.policies.policy.currentTotalPaid = currentTotalPaid;
        self.policies.policy.pendingAmount = pendingAmount;
        if (success) {
          self.setOngoingTasks(tasks);
        } else {
          self.tasksError = error;
        }
        const selectedPolicy = account.policies.find((p) => p.id === id);
        if (selectedPolicy?.billingHoldUntil && isPast(parseISO(selectedPolicy.billingHoldUntil))) {
          self.policies.policy.policy = { ...selectedPolicy, billingHoldUntil: null };
        } else {
          self.policies.policy.policy = selectedPolicy;
        }
        const { fees, segments } = selectedPolicy;
        self.policies.policy.fees = fees;
        const mappedSegments = segments.slice().map(clean);
        self.policies.policy.segments = mappedSegments;
        const currentlySelectedSegmentId =
          self.policies.policy?.segment?.segmentId || mappedSegments[mappedSegments.length - 1].segmentId;
        self.policies.policy.segment = getMerged(mappedSegments, currentlySelectedSegmentId);
        self.policies.unsignedBixConversions = unsignedBixConversions || {};
        const bixConversionDocsInfo = self.policies.getUnsignedBixConversion(id);
        if (!bixConversionDocsInfo.signedDocUploadedInS3) {
          // If entered, there are no signed documents in S3, but there could be a delay up to 3 hours from
          // when the person signed to when the signed pdfs are uploaded. so, check if that's the case
          // if so, the below function updates the policy store with the signed timestamp
          yield self.policies.policy.getBixConversationSignatureFromTable(id);
        }
        self.policies.policy.setViolationsForRenewal(violationsForRenewal);
      } catch (error) {
        self.handleError(error);
        if (history) {
          checkIfUnauthorizedAndPushToSearchPage(error, history);
        }
      } finally {
        self.accountLoading = false;
        self.policies.policy.loading = false;
        self.policies.policy.holdCardLoading = true;
        self.policies.loadingDocuments = false;
        self.policies.policy.policyEquityStatus.status = 'done';
        self.policies.setNotifications(isAgencyUser);
        self.setNotifications(isAgencyUser);
      }
    }),
    fetchFullAccountAndBranchPlusPolicy: flow(function* fetchFullAccountAndBranchPlusPolicy(
      branchPlusPolicyId,
      accountId,
      history,
      isAgencyUser
    ) {
      const { client } = getEnv(self);
      self.accountLoading = true;
      self.branchPlusPolicies.branchPlusPolicy.loading = true;

      self.errors = [];
      try {
        const { data } = yield client.query({
          query: GET_ACCOUNT_BRANCH_PLUS_POLICY,
          variables: {
            accountId,
            policyId: branchPlusPolicyId
          }
        });
        const { account, branchPlusPolicies } = data;
        self.setAccount(account);
        self.branchPlusPolicies.list = branchPlusPolicies;
        const branchPlusPolicy = cloneDeep(branchPlusPolicies.find(({ policyId }) => policyId === branchPlusPolicyId));
        const { carriers } = getParent(self);
        const carrierInfo = yield carriers.getCarrierInformation(
          branchPlusPolicy.carrier.name,
          branchPlusPolicy.policyType
        );

        if (carrierInfo) {
          branchPlusPolicy.carrier = {
            ...branchPlusPolicy.carrier,
            ...carrierInfo
          };
        }
        self.branchPlusPolicies.branchPlusPolicy.policy = branchPlusPolicy;
      } catch (error) {
        self.handleError(error);
        if (history) {
          checkIfUnauthorizedAndPushToSearchPage(error, history);
        }
      } finally {
        self.accountLoading = false;
        self.branchPlusPolicies.branchPlusPolicy.loading = false;
        self.setNotifications(isAgencyUser);
      }
    }),
    fetchAccount: flow(function* getAccount(id) {
      const { client } = getEnv(self);
      self.errors = [];
      self.tasksError = null;
      self.accountLoading = true;
      try {
        const { data } = yield client.query({
          query: GET_ACCOUNT,
          variables: {
            id
          }
        });
        const { account } = data;
        self.setAccount(account);
      } catch (error) {
        self.handleError(error);
      } finally {
        self.accountLoading = false;
      }
    }),
    fetchAffinityData: flow(function* fetchAffinityData() {
      const affinities = [];
      if (self.policies?.list?.length) {
        for (const policy of self.policies.list) {
          const policyAffinities = Array.from(
            new Set(policy.segments.map((segment) => segment.global?.affinity).filter((affinity) => affinity))
          );
          affinities.push(...policyAffinities);
        }
      }

      if (affinities.length > 0) {
        const { affinityLookups } = getParent(self);
        yield affinityLookups.getByAffinityList(affinities, 'ALL');
      }
    }),
    fetchTickets: flow(function* getTickets({ frontTicketsSearchConfig = {} } = {}) {
      const { client } = getEnv(self);
      self.ticketsLoading = !frontTicketsSearchConfig;
      self.frontTicketsLoading = !!frontTicketsSearchConfig;
      self.errors = [];
      const { id: accountId, fname, lname, email, phoneNumber, frontTagId } = self;
      const { page: nextPage, prevPage, itemsPerPage } = frontTicketsSearchConfig;

      const nextPageToken = self.getFrontPageToken({ prevPage, newPage: nextPage });
      try {
        const { data } = yield client.query({
          query: GET_TICKETS,
          variables: {
            params: {
              accountId,
              fname,
              lname,
              email,
              phoneNumber,
              frontTicketsSearchConfig: {
                itemsPerPage,
                page: nextPage,
                tagId: frontTagId,
                pageToken: nextPageToken
              }
            }
          }
        });
        if (self.frontTicketsLoading) {
          self.tickets = {
            ...self.tickets,
            frontTickets: data.tickets.frontTickets
          };
        } else {
          self.tickets = data.tickets;
        }

        const prevPointers = self.tickets.pointers?.prev || [];
        self.tickets.pointers = {
          next: data.tickets.frontTickets.nextFrontPageToken || undefined,
          // If going forward, store prev pointers in a stack to enable backwards pagination
          prev: nextPage > prevPage ? [...prevPointers, self.tickets.pointers.current] : prevPointers,
          current: nextPageToken
        };
      } catch (error) {
        self.handleError(error);
      } finally {
        self.ticketsLoading = false;
        self.frontTicketsLoading = false;
      }
    }),
    fetchFrontContactURL: flow(function* getFrontContactURL(email) {
      if (!email) return;
      const { client } = getEnv(self);
      self.tasksError = null;
      self.errors = [];
      try {
        const { data } = yield client.query({
          query: GET_FRONT_CONTACT,
          variables: {
            email
          }
        });
        const { frontContactURL } = data;
        self.frontContactURL = frontContactURL;
      } catch (error) {
        self.handleError(error);
      }
    }),
    resetPwd: flow(function* resetPwd(accountId) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: RESET_PWD,
        variables: {
          username: accountId
        }
      });
    }),
    sendEventAsMember: flow(function* sendEventAsMember(eventDetails) {
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: SEND_EVENT_AS_MEMBER,
          variables: {
            eventDetails
          }
        });
        return { data };
      } catch (error) {
        return { error: error.message };
      } finally {
        self.accountLoading = false;
      }
    }),
    updateAccount: flow(function* updateAccount(newAccountDetails) {
      self.accountLoading = true;
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: UPDATE_ACCOUNT,
          variables: {
            account: newAccountDetails
          }
        });
        self.setAccount(data.updateAccount);
        return { data };
      } catch (error) {
        return { error: error.message };
      } finally {
        self.accountLoading = false;
      }
    }),
    completeTask: flow(function* completeTask({ taskId, accountId, documentUrl, policyId, taskName }) {
      self.tasksError = null;
      self.accountLoading = true;
      const { client } = getEnv(self);
      const completeTaskVariables = { taskDetails: { taskId, accountId, documentUrl, policyId, taskName } };
      const policy = self.policies?.list?.find(({ id }) => id === policyId);

      if (
        taskName === onboardingTasks.CompleteInventory &&
        policy &&
        policy.policyType === policyTypes.Home &&
        reopeningStates.includes(policy.offer.quote.correctedAddress.state) &&
        policy.offer.quote.home.yearBuilt &&
        policy.offer.quote.home.yearBuilt <= 1940
      ) {
        completeTaskVariables.ticket = {
          subject: 'Home inventory completed - 1940 or older',
          body: `Policy ${policyId} was bound with an effective date of ${policy.effectiveDate}. Please review the completed inventory and take action if needed.`
        };
      }

      const { data } = yield client.mutate({
        mutation: COMPLETE_TASK,
        variables: completeTaskVariables
      });
      const {
        completeTask: { success }
      } = data;
      if (!success) {
        const { error } = data;
        return { error };
      }
      if (success) {
        if (taskName === onboardingTasks.CompleteInventory) {
          const { segments } = self.policies.list.find(({ id }) => id === policyId);
          const { startDate, segmentId } = segments[segments.length - 1];
          const {
            data: { previewPolicyChangeToSegment }
          } = yield client.mutate({
            mutation: CHANGE_SEGMENT,
            variables: {
              policyId,
              segmentId,
              segment: GQLTypes.PolicyDetailsInput({
                startDate,
                global: { manualInventorySubmission: true }
              })
            }
          });

          yield client.mutate({
            mutation: CONFIRM,
            variables: {
              policyId,
              previewId: previewPolicyChangeToSegment.id,
              internalNotes: 'Completed inventory task -> Manually submitted inventory'
            }
          });
        }
        yield self.loadDocsAndTasks(accountId);
        return { ok: success };
      }
    }),
    resetTask: flow(function* resetTask({ taskId, accountId, policyId, taskName, completedBy }) {
      self.tasksError = null;
      self.accountLoading = true;
      const { client } = getEnv(self);
      const { data } = yield client.mutate({
        mutation: RESET_TASK,
        variables: { taskDetails: { taskId, accountId, policyId, taskName, completedBy } }
      });
      const {
        resetTask: { success }
      } = data;
      if (!success) {
        const { error } = data;
        return { error };
      }
      if (success) {
        yield self.loadDocsAndTasks(accountId);
        return { ok: success };
      }
    }),
    loadDocsAndTasks: flow(function* loadDocsAndTasks(accountId) {
      const { client } = getEnv(self);
      const { data } = yield client.query({
        query: GET_DOCS_AND_TASKS,
        variables: { accountId }
      });
      const {
        documents,
        tasks: { success, tasks, error },
        unsignedApplications
      } = data;
      self.policies.unsignedApplications = unsignedApplications;
      self.policies.documents = documents;
      if (success) {
        self.setOngoingTasks(tasks);
      } else {
        self.tasksError = error;
      }

      self.accountLoading = false;
      self.setNotifications();
    })
  }))
  .actions((self) => ({
    setAccount(account) {
      self.id = account.id || self.id;
      self.fname = account.fname || self.fname;
      self.lname = account.lname || self.lname;
      self.phoneNumber = account.phoneNumber || self.phoneNumber;
      self.email = account.email || self.email;
      self.created = account.created || self.created;
      self.mailingAddress = account.mailingAddress || self.mailingAddress;
      self.inviter = account.inviter || self.inviter;
      self.additionalPhoneNumbers = account.additionalPhoneNumbers || self.additionalPhoneNumbers;
      self.frontTagId = account.frontTagId;
    },
    setNotifications: function setNotifications(isAgencyUser) {
      const notifications = [];

      const activePolicies = self.policies?.list?.filter((p) => getPolicyStatus(p) === PolicyStatus.Active) || [];

      const isPolicyAgencySold = self.policies.list[0]?.isAgentSold || self.policies.list[0]?.offer?.quote?.isAgentSold;

      const isCrossSellEligible =
        activePolicies.length === 1 &&
        self.policies.list[0] &&
        isPolicyCrossSellEligible(self.policies.list[0], isPolicyAgencySold, isAgencyUser);

      if (isCrossSellEligible) {
        notifications.push(Notification.Customer.CrossSellEligibility);
      }

      if (self?.customerMetadata?.suspiciousActivity?.flagged) {
        notifications.push(Notification.Customer.SuspiciousAccount);
      }

      if (self?.incompleteTasks.length) {
        notifications.push(Notification.Customer.IncompleteTasks);
      }
      if (!isAgencyUser && isPolicyAgencySold) {
        notifications.push(Notification.Customer.TransferToAgency);
      }

      if (notifications.length) {
        self.notifications = notifications;
      } else {
        self.notifications = null;
      }
    },
    setOngoingTasks(tasks) {
      self.tasks = tasks
        .filter(({ taskName, policyId }) => {
          return (
            !!policyId &&
            taskName !== onboardingTasks.StressFreeSwitchAuto &&
            taskName !== onboardingTasks.StressFreeSwitchHome &&
            taskName !== onboardingTasks.StressFreeSwitchRenters &&
            taskName !== onboardingTasks.StressFreeSwitchUmbrella
          );
        })
        .filter(({ policyId }) => {
          const policy = self.policies.list.find(({ id }) => id === policyId);
          if (policy) {
            const today = new Date();
            const effectiveDate = parseISO(policy.effectiveDate);
            return differenceInDays(new Date(effectiveDate), today) < 100;
          }
          return false;
        })
        .map(({ data, ...task }) => {
          return {
            ...task,
            data: data ? JSON.parse(data) : null,
            completed: !!task.completedDate
          };
        });
    },
    getClaims: flow(function* getClaims() {
      const { client } = getEnv(self);
      self.claimsLoading = true;
      try {
        const { data } = yield client.mutate({
          mutation: GET_ACCOUNT_CLAIMS,
          variables: {
            accountId: self.id
          }
        });
        self.claims = data.claims;
      } finally {
        self.claimsLoading = false;
      }
    }),
    getSnapsheetExposureDetail: flow(function* getSnapsheetExposureDetail() {
      const { client } = getEnv(self);
      self.exposureDetailsLoading = true;
      try {
        const { data } = yield client.query({
          query: GET_SNAPSHEET_EXPOSURE_DETAIL,
          variables: {
            accountId: self.id
          }
        });
        self.exposureDetails = data.exposureDetails;
      } finally {
        self.exposureDetailsLoading = false;
      }
    }),
    getSnapsheetNotesDetail: flow(function* getSnapsheetNotesDetail(claimId) {
      const { client } = getEnv(self);
      self.notesDetailLoading = true;
      try {
        const { data } = yield client.query({
          query: GET_SNAPSHEET_NOTES_DETAIL,
          variables: {
            claimId
          }
        });
        self.notesDetail = data.notesDetail;
      } finally {
        self.notesDetailLoading = false;
      }
    }),
    toggleSupiciousFlag: flow(function* toggleSupiciousFlag() {
      const { client } = getEnv(self);
      try {
        const res = yield client.mutate({
          mutation: FLAG_SUSPICIOUS,
          variables: {
            accountId: self.id,
            suspiciousActivity: { flagged: !self.customerMetadata?.suspiciousActivity?.flagged } // toggle opposite of current value
          }
        });
        if (res?.data?.customerMetadata) {
          const { suspiciousActivity, rep, revision } = res.data.customerMetadata;
          self.customerMetadata = { suspiciousActivity, rep, revision };
        }
        self.setNotifications();
        return res;
      } catch (error) {
        return { error: error.message };
      }
    })
  }))
  .views((self) => ({
    getAllTickets() {
      return self.tickets;
    },
    getFrontTickets() {
      return self.tickets.frontTickets;
    },
    getFrontPageToken({ prevPage, newPage }) {
      if (newPage > prevPage) {
        return self.tickets?.pointers?.next;
      }

      return self.tickets?.pointers?.prev.pop();
    },
    getSendgridTickets() {
      return self.tickets.sendGridEmails;
    },
    getFrontContactURL() {
      return self.frontContactURL;
    },
    get completedTasks() {
      return self.tasks.filter(({ completed }) => completed);
    },
    get incompleteTasks() {
      return self.tasks.filter(({ completed }) => !completed);
    }
  }))
  .views((self) => ({
    sortedCommunity(sortDirection) {
      if (self.community) {
        return orderBy(self.community, ['name'], [sortDirection]);
      }
      return [];
    }
  }));
