import { lookupsJson, paymentMethod } from '@ourbranch/lookups';

const states = lookupsJson.usStates;

const mortgageFieldsNameMap = {
  loanNumber: 'Loan number',
  mortgageHolderName: 'Mortgage lender name',
  'mortgageHolderAddress.address': 'Address',
  'mortgageHolderAddress.city': 'City',
  'mortgageHolderAddress.zip': 'Zip code',
  'mortgageHolderAddress.state': 'State'
};

export function validUSState(name) {
  return this.test({
    name: `${name}.validUSState`,
    message: 'Please enter a valid US state',
    test: (val) => !val || states.some((state) => state.id === val),
    exclusive: false
  });
}

export function validHomePurchaseDate(name, isRequired = true) {
  return this.test({
    name: `${name}.validHomePurchaseDate`,
    message: 'Home purchase date cannot be before year built',
    test: function test(val) {
      if (!val && isRequired) {
        return this.createError({
          message: 'Purchase date is required',
          path: name
        });
      }

      const propertyType = name.includes('home') ? 'home' : 'condo';
      const yearBuilt = this.options.context?.[propertyType]?.yearBuilt;
      const yearPurchasedInput = Number(this.options.context?.[propertyType]?.purchaseDate?.split('-')?.[0]);

      if (yearPurchasedInput && yearPurchasedInput < yearBuilt) {
        return this.createError({
          message: 'Purchase date cannot be before year built',
          path: name
        });
      }

      return true;
    }
  });
}

export function requiredString(label) {
  return this.ensure().required(label || 'Required');
}

// @precondition, needs to have affinityLookups store object in context
export function validAffinityCode(name, ctx) {
  const { affinityLookups, isAgency } = ctx;
  return this.test({
    name: `${name}.validAffinityCode`,
    message: 'Please enter a valid affinity partner name or code',
    test: function test(val) {
      if (val && val !== 'None') {
        const matchedToAffinityInLookups = affinityLookups.data.find(({ affinity }) => affinity === val);

        if (!matchedToAffinityInLookups) {
          return this.createError({
            message: `Affinity value ${val} not found in our database. ${
              isAgency ? 'Please contact your designated agency success manager to fix the error to continue' : ''
            }`,
            path: name
          });
        }
      }
      return true;
    }
  });
}

export function validMortgageDetails(fieldName) {
  // The test passes if the payment method is not mortgage or if there's a value filled for mortgage holder name..
  // ...but we only need to check this in cases where the selected policy has a Home
  const normalizedFieldName = mortgageFieldsNameMap[fieldName];
  return this.test(
    'Require mortgage fields when home is added to policy',
    `${normalizedFieldName} is required`,
    function test(val) {
      const policyHasHome = this.options.context?.selectedOption?.includes('H') && !this.options.context.noBindHome;
      const policyHasCondo = this.options.context?.selectedOption?.includes('C');

      const isHomeMortgaged = this.options.context?.global?.homeownersPaymentMethod === paymentMethod.Escrow;
      const isCondoMortgaged = this.options.context?.global?.condoPaymentMethod === paymentMethod.Escrow;

      if ((policyHasHome && isHomeMortgaged) || (policyHasCondo && isCondoMortgaged)) {
        return val && val?.trim().length > 0;
      }

      return true;
    }
  );
}

const commonBogusLoanNumbers = [
  'TBD',
  'NA',
  'N/A',
  'NONE',
  'NO',
  // no more than 5 repeating digits at any part of the loan number
  '11111',
  '22222',
  '33333',
  '44444',
  '55555',
  '66666',
  '77777',
  '88888',
  '99999'
];
function containsNumbers(str) {
  return /\d/.test(str);
}

function containsBogusInfo(str) {
  return new RegExp(commonBogusLoanNumbers.join('|'), 'i').test(str);
}

export function validMortgageLoanNumber() {
  return this.test('validLoanNumber', 'Please enter a valid loan number', function test(val) {
    const policyHasHome = this.options.context?.selectedOption?.includes('H');
    const policyHasCondo = this.options.context?.selectedOption?.includes('C');

    const isHomeMortgaged = this.options.context?.homeownersPaymentMethod === paymentMethod.Escrow;
    const isCondoMortgaged = this.options.context?.condoPaymentMethod === paymentMethod.Escrow;
    if ((policyHasHome && isHomeMortgaged) || (policyHasCondo && isCondoMortgaged)) {
      if (!containsNumbers(val) || val.length < 4 || containsBogusInfo(val) || val?.startsWith('123')) {
        return false; // user is probably trying to bypass the loan number by entering bad information
      }
      return true;
    }

    return true;
  });
}

export function validAffinityGroups() {
  return this.test('validAffinityGroups', '', (value, context) => {
    // If write affinity code was provided
    if (context.parent.affinity) {
      // Make sure affinity groups are provided and they include the write affinity code
      if (!value) {
        return context.createError({ message: 'Affinity groups are required when affinity code is set' });
      }

      if (!value.includes(context.parent.affinity)) {
        return context.createError({ message: 'Affinity groups must include the write affinity code' });
      }
    }

    return true;
  });
}
