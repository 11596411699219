import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createHttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Auth as AwsAuth } from 'aws-amplify';
import awsConfig from '../aws-exports';

// We do this so that RetryLink will treat AppSync execution timeouts as errors
// and retry them. By default only network errors are considered errors for retrying,
// and errors within the GraphQL response are not considered "errors while making the request"
// and are not retried. This needs to be between the HTTP link and the retry link.
//
// Shape of data.errors:
// [
//   {
//     "data": null,
//     "errorInfo": null,
//     "errorType": "ExecutionTimeout",
//     "locations": [{ "column": 3, "line": 2, "sourceName": null }],
//     "message": "Execution timed out.",
//     "path": ["recalculateCluster"]
//   }
// ]
const promoteAppSyncExecutionTimeoutsToErrorsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.some((error) => error.errorType === 'ExecutionTimeout')) {
      throw new Error('AppSyncExecutionTimeout');
    }
    return data;
  });
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 3000,
    jitter: true
  },
  attempts: {
    max: 5
  }
});

const config = {
  url: awsConfig.graphqlEndpoint,
  region: awsConfig.region,
  auth: {
    type: awsConfig.authenticationType,
    jwtToken: async () => {
      try {
        const session = await AwsAuth.currentSession();
        return session.getIdToken().getJwtToken();
      } catch (e) {
        // No active session, ignore
      }
    }
  }
};

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    promoteAppSyncExecutionTimeoutsToErrorsLink,
    createAuthLink(config),
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      createSubscriptionHandshakeLink(config), // link for subscriptions
      createSubscriptionHandshakeLink(
        // link for graphql http requests
        awsConfig.graphqlEndpoint,
        createHttpLink({ uri: awsConfig.graphqlEndpoint, fetch: (...args) => window.fetch(...args) })
      )
    )
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    },
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
});
