import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { types } from 'mobx-state-tree';
import { Provider as MobxProvider, inject, observer, MobXProviderContext } from 'mobx-react';
import makeInspectable from 'mobx-devtools-mst';

import OfferStore, { CheckoutStatus, FormAction } from './offer-store';
import { QuoteStore } from './quote-store';
import { AccountStore } from './account-store';
import { AdditionalOffersStore } from './additional-offers-store';
import { SearchStore } from './search-store';
import { AffinityLookupsStore } from './affinity-lookups-store';
import { BranchPlusStore } from './branch-plus-store';
import { CarriersStore } from './carriers-store';

const initialState = {
  offer: {
    errors: [],
    loading: false,
    offer: null,
    openDialog: false,
    mortgageOptions: null,
    status: CheckoutStatus.Initial,
    purchasedAccountId: null,
    formAction: FormAction.Update,
    includeConnectedHome: false,
    addingReferral: false,
    includeInventory: false,
    includePaperless: false,
    notifications: null,
    vinDiscrepancies: null,
    successfulNewVins: null,
    carAndDriverSuggestions: null,
    checkoutFormData: null,
    showConectedHomeModal: false,
    showUDRModal: false,
    needsPreBindUWForm: false,
    areRejectCodesCleared: false,
    priorQuoteWithPreBindUWRejections: {
      ineligibleForHome: false,
      ineligibleForAuto: false
    },
    showBranchPlusMultiPolicyDiscountModal: false,
    includeBranchPlusMultiPolicyDiscount: false,
    scrollTo: null
  },
  account: {
    id: '',
    fname: '',
    lname: '',
    phoneNumber: '',
    additionalPhoneNumbers: [],
    email: '',
    created: '',
    mailingAddress: null,
    tickets: null,
    accountLoading: false,
    ticketsLoading: false,
    purchasedAccountId: '',
    inviter: {},
    branchPlusPolicies: {
      loading: false,
      list: [],
      branchPlusPolicy: {
        loading: false,
        policy: null
      }
    },
    policies: {
      loading: false,
      list: [],
      loadingDocuments: false,
      documents: undefined,
      policy: {
        segments: [],
        changed: false,
        segment: null,
        segmentId: null,
        policy: null,
        loading: true,
        holdCards: [],
        holdCardLoading: undefined,
        billingDetails: null,
        policyEquityStatus: {},
        inspection: undefined,
        scrollTo: null
      }
    },
    pledges: {},
    community: {},
    customerMetadata: {},
    errors: []
  },
  quote: {
    loading: false,
    errorCodes: [],
    offer: null,
    prefillData: null
  },
  search: {
    loading: false,
    customersResults: {},
    offersResults: {},
    policiesResults: {},
    searchString: '',
    showCustomerMatchesModal: false,
    expandedSearch: false,
    showOfferHistory: false,
    showReferralMatchesModal: false,
    showOfferMatchesModal: false,
    error: null
  },
  matchSearch: {
    loading: false,
    customersResults: {},
    offersResults: {},
    searchString: '',
    showCustomerMatchesModal: false,
    showOfferMatchesModal: false,
    resultsPerPage: 5
  },
  userPreferences: {
    affinityGroups: undefined,
    meiliSearchKey: undefined,
    meiliSearchHost: undefined,
    searchEngine: 'meilisearch'
  },
  affinityLookups: {
    loading: false,
    partialLoading: false,
    data: [],
    agencyInformation: {}
  },
  referralSearch: {
    loading: false,
    customersResults: {},
    offersResults: {},
    policiesResults: {},
    searchString: '',
    showCustomerMatchesModal: false,
    expandedSearch: false,
    showReferralMatchesModal: false,
    showOfferMatchesModal: false
  },
  additionalOffers: {
    offerId: '',
    termLifeSubmittedLast: ''
  },
  branchPlus: {
    loading: false,
    branchPlusPolicy: null
  },
  carriers: {
    list: []
  }
};

const UserPreferences = types
  .model({
    affinityGroups: types.maybe(types.string)
  })
  .actions((self) => ({
    setUserPreferences(userPreferences) {
      const { affinityGroups } = userPreferences;

      self.affinityGroups = affinityGroups || undefined;
      self.meiliSearchHost = userPreferences.meiliSearchHost;
      self.meiliSearchKey = userPreferences.meiliSearchKey;
      self.searchEngine = userPreferences.searchEngine;
    }
  }));

export const StaffStore = types
  .model({
    offer: OfferStore,
    account: AccountStore,
    quote: QuoteStore,
    search: SearchStore,
    matchSearch: SearchStore,
    userPreferences: UserPreferences,
    affinityLookups: AffinityLookupsStore,
    referralSearch: SearchStore,
    additionalOffers: AdditionalOffersStore,
    branchPlus: BranchPlusStore,
    carriers: CarriersStore
  })
  .actions((self) => ({
    resetStore() {
      self.offer = initialState.offer;
      self.quote = initialState.quote;
      self.account = initialState.account;
      self.branchPlus = initialState.branchPlus;
    }
  }));

export const Provider = ({ store, children }) => <MobxProvider store={store}>{children}</MobxProvider>;

Provider.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export function createStore(client) {
  const staffStore = StaffStore.create(initialState, { client });
  makeInspectable(staffStore);
  if (process.env.NODE_ENV !== 'production') {
    window.store = staffStore;
  }
  return staffStore;
}

export const useStore = () => {
  const stores = useContext(MobXProviderContext);
  const store = stores?.store;
  return store;
};

export function withStore(target) {
  return inject('store')(observer(target));
}
