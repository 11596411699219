import { makeStyles } from '@material-ui/core/styles';
import { errorTypes } from './constants';

const getBackgroundColor = (theme, type) => {
  switch (type) {
    case errorTypes.Primary:
      return theme.colorPalette.red_20;
    case errorTypes.Secondary:
      return theme.colorPalette.orange_10;
    default:
      return theme.colorPalette.white_30;
  }
};

const getFontColor = (theme, type) =>
  type === errorTypes.Tertiary ? theme.colorPalette.green_20 : theme.colorPalette.white_30;

const styles = (theme) => ({
  container: ({ type }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(type === errorTypes.Secondary ? 1 : 2),
    backgroundColor: getBackgroundColor(theme, type),
    color: getFontColor(theme, type)
  }),
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  errorCode: ({ type }) => ({
    display: 'flex',
    color: type === errorTypes.Tertiary ? theme.colorPalette.green_75 : 'inherit',
    fontWeight: type === 'primary' ? 600 : 400
  }),
  link: {
    cursor: 'pointer',
    color: 'inherit',
    fontWeight: 'normal',
    textDecoration: 'underline'
  }
});

export default makeStyles(styles);
