import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { Button } from 'core/components/button';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import Section from 'core/components/section';
import { phoneNumberToStringFormatter } from 'core/helpers/formatters';
import useSession from 'core/hooks/use-session';
import { useStore } from 'core/store';
import useCheckoutContext from '../../hooks/use-checkout-context';
import { Method } from './helpers';
import { PurchaseLinkActions } from './purchase-link-actions';
import { PurchaseLinkSent } from './purchase-link-sent';
import useStyles from './purchase-link-section.styles';

const PurchaseLinkSection = ({ toggleValidationErrorsModal }) => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useToast();
  const { offer: store } = useStore();
  const { validateForm, setErrors, setTouched, values } = useFormikContext();
  const {
    user: { username }
  } = useSession();
  const { isLicensedToPurchase } = useCheckoutContext();

  const wasLinkSent = !!store.offer.purchaseLinkSentTimestamp;

  const onSendLink = useCallback(
    async (method) => {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        const destination = method === Method.Email ? values.email : phoneNumberToStringFormatter(values.phone);
        const policyType = [PolicyType.AutoBundle, PolicyType.HomeBundle].includes(values.selectedOption)
          ? PolicyType.HABundle
          : values.selectedOption;
        await store.sendPurchaseLink(store.offer.webUserId, store.offer.id, {
          contactInformation: {
            method,
            email: values.email.trim(),
            phoneNumber: values.phone,
            firstName: store.offer.quote.fname,
            lastName: store.offer.quote.lname
          },
          bindInformation: {
            clusterId: store.offer.clusterId,
            policyType,
            breakupWithAuto: values.breakupWithAuto,
            breakupWithHomeowners: values.breakupWithHomeowners,
            breakupWithRenters: values.breakupWithRenters,
            currentAutoCarrier: values.breakupWithAuto ? values.currentAutoCarrier : null,
            currentAutoCarrierPolicyNumber: values.breakupWithAuto ? values.currentAutoCarrierPolicyNumber : null,
            currentHomeownersCarrier: values.breakupWithHomeowners ? values.currentHomeownersCarrier : null,
            currentHomeownersCarrierPolicyNumber: values.breakupWithHomeowners
              ? values.currentHomeownersCarrierPolicyNumber
              : null,
            currentRentersCarrier: values.breakupWithRenters ? values.currentRentersCarrier : null,
            currentRentersCarrierPolicyNumber: values.breakupWithRenters
              ? values.currentRentersCarrierPolicyNumber
              : null
          }
        });
        if (store.failedToSendLink || (store.errors && store.errors.length)) {
          toast.notify({
            type: 'error',
            message: 'There was an error sending the purchase link.'
          });
        } else {
          toast.notify({
            type: 'success',
            message: `The purchase link has been sent via ${method.toLowerCase()} to ${destination}. An email will be sent to ${username} once the purchase has been complete.`
          });
        }
      } else {
        toggleValidationErrorsModal(true);
        toast.notify({
          type: 'error',
          message: 'The purchase link has not been sent. Please fill out the required information before sending.'
        });
        setErrors(errors);
        setTouched(errors);
      }
    },
    [values, store.errors, store.offer.id]
  );

  return (
    <Section title="Purchase Link" type="SubSection">
      <Card className={classes.purchaseLinkCard} type="primaryEditable">
        {!wasLinkSent && <PurchaseLinkActions loading={store.loading} onSendLink={onSendLink} />}
        {wasLinkSent && isLicensedToPurchase && <PurchaseLinkSent onSendLink={onSendLink} />}
      </Card>
      {wasLinkSent && (
        <Card className={classes.backToSearchCard}>
          <Label type="body2">
            Once the purchase is complete, the bound policy can be found in the policy or member search page.
          </Label>
          <Button
            variant="contained"
            color="primary"
            className={classes.searchButton}
            onClick={() => {
              history.push('/search/customers');
            }}
          >
            Return to search page
          </Button>
        </Card>
      )}
    </Section>
  );
};

export default observer(PurchaseLinkSection);
