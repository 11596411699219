import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button } from 'core/components/button';
import { numberAbbrFormatter } from 'core/helpers/formatters';
import useStyles from './search-link.styles';

const SearchLink = ({ selected, results, label, to, dataCy }) => {
  const classes = useStyles();

  return (
    <Button
      color="primary"
      component={Link}
      to={to}
      className={classNames(classes.button, {
        [classes.selected]: selected
      })}
      data-cy={dataCy}
    >
      {label}
      {results > 0 && ` (${numberAbbrFormatter(results)}${results === 1000 ? '+' : ''})`}
    </Button>
  );
};

SearchLink.propTypes = {
  selected: PropTypes.bool.isRequired,
  results: PropTypes.number,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired
};

SearchLink.defaultProps = {
  results: 0
};

export default memo(SearchLink);
