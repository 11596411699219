import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import { TextField } from 'core/components/text-field';
import { LabelTooltip } from 'core/components/label-tooltip';
import Checkbox from 'core/components/checkbox';
import useStyles from './search-form.styles';

const ExpandedSearchTooltips = {
  customers:
    'This will search within additional criteria not shown on the columns below. Expanded search includes: email, primary phone, and additional phone numbers',
  offers:
    'This will search within additional criteria not shown on the columns below. Expanded search includes: email, primary phone, and additional phone numbers',
  policies:
    'Affinity, Car VINs, Mortgage lender name, and drivers/people on policy for the following attributes: first name, last name, and DOB (formatted as YYYY-MM-DD).',
  branchPlus:
    'Expanded search will look for additional criteria beyond what is displayed in the columns below. This includes email, primary phone, and policy number.'
};

const SHOW_OFFER_HISTORY_TOOLTIP_TEXT =
  'This will update the search results to show all versions of all accessible offers, if you need to return to an earlier version of an offer.';

const SearchForm = ({ value, onChange, onSearchButtonPress, location }) => {
  const classes = useStyles();
  const isSearchingOffers = location.pathname === '/search/offers';
  const isSearchingCustomers = location.pathname === '/search/customers';
  const isSearchingPolicies = location.pathname === '/search/policies';
  const isSearchingBranchPlusPolicies = location.pathname === '/search/branch-plus';
  const { search: store } = useStore();

  const toggleExpandedSearch = useCallback(
    (_, expanded) => {
      store.setExpandedSearch(expanded);
      onSearchButtonPress();
    },
    [store, onSearchButtonPress]
  );

  const toggleShowOfferHistory = useCallback(
    (_, show) => {
      store.setShowOfferHistory(show);
      onSearchButtonPress();
    },
    [store, onSearchButtonPress]
  );

  return (
    <Card className={classes.card} type="secondary">
      <CardContent className={classes.content}>
        <div className={classes.search}>
          <TextField
            label="Search"
            name="query"
            className={classes.textField}
            autoComplete="off"
            onChange={onChange}
            value={value}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            data-cy="submit-search"
            onClick={onSearchButtonPress}
          >
            Search
          </Button>
        </div>
        {(isSearchingOffers || isSearchingCustomers) && (
          <Checkbox
            id="toggleExpandedSearch"
            label="Expanded Search"
            tooltipText={ExpandedSearchTooltips.customers}
            tooltipLabel="More Info"
            onChange={toggleExpandedSearch}
            value={store.expandedSearch}
          />
        )}
        {isSearchingOffers && (
          <Checkbox
            id="toggleShowOfferHistory"
            label="Show Offer History"
            tooltipText={SHOW_OFFER_HISTORY_TOOLTIP_TEXT}
            tooltipLabel="More Info"
            onChange={toggleShowOfferHistory}
            value={store.showOfferHistory}
            xs={12}
          />
        )}
        {isSearchingPolicies && (
          <LabelTooltip
            tooltip={{
              onHoverText: ExpandedSearchTooltips.policies,
              label: 'What can I search?'
            }}
            placement="bottom"
          />
        )}
        {isSearchingBranchPlusPolicies && (
          <Checkbox
            id="toggleExpandedSearch"
            label="Expanded Search"
            tooltipText={ExpandedSearchTooltips.branchPlus}
            tooltipLabel="More Info"
            onChange={toggleExpandedSearch}
            value={store.expandedSearch}
          />
        )}
      </CardContent>
    </Card>
  );
};

SearchForm.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearchButtonPress: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default SearchForm;
