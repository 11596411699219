import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6, 24),
    gap: theme.spacing(4),
    backgroundColor: theme.colorPalette.orange_10
  }
});

export default makeStyles(styles);
