import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { openFrontChatPopup } from 'core/components/front-chat';
import { parseISO, format, addDays } from 'date-fns';
import { awsDateToDateFormatter } from 'core/helpers/formatters';
import clock from 'core/assets/svg/clock.svg';
import alert from 'core/assets/svg/alert-white-filled.svg';

import { PolicyStatus } from 'core/helpers/policy-status';
import { Label } from 'core/components/label';
import { withToast } from 'core/components/toast';

import useStyles from './cancelled-policy-footer.styles';

const CancelledPolicyFooter = ({ effectiveDate, isAgency, endDate, policyStatus, policy }) => {
  const classes = useStyles();

  const isAgentSold = policy.offer.quote.isAgentSold || policy.isAgentSold;

  const openFrontChat = () => {
    if (isAgency) {
      openFrontChatPopup();
    }
  };

  const getSubTitle = () => {
    if (effectiveDate === endDate) {
      return `It has been cancelled effective ${awsDateToDateFormatter(endDate)} at 12:01 AM`;
    }
    if (policyStatus === PolicyStatus.Cancelled) {
      return `It has been cancelled effective ${format(addDays(parseISO(endDate), 1), 'MM/dd/yyyy')} at 12:01 AM`;
    }
    if (policyStatus === PolicyStatus.InCancellation) {
      return `It is set to be cancelled effective ${format(addDays(parseISO(endDate), 1), 'MM/dd/yyyy')} at 12:01 AM`;
    }
  };

  const getIcon = () => {
    if (policyStatus === PolicyStatus.Cancelled || effectiveDate === endDate) {
      return <img src={alert} alt="Notification Icon" />;
    }
    if (policyStatus === PolicyStatus.InCancellation) {
      return <img src={clock} alt="Notification Icon" />;
    }
  };

  const getTitle = () => {
    if (policyStatus === PolicyStatus.Cancelled || effectiveDate === endDate) {
      return 'This policy is cancelled.';
    }
    if (policyStatus === PolicyStatus.InCancellation) {
      return 'This policy is pending cancellation.';
    }
  };
  const showRedFooter = !isAgency && isAgentSold;
  const footerClasses = classNames({
    [classes.footerContainer]: true,
    [classes.footerAlertOrange]: !showRedFooter,
    [classes.footerAlertRed]: showRedFooter
  });

  return (
    <div className={classes.floatingContainer}>
      <div className={footerClasses}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.footerContent}>
          <Grid className={classes.cancelCallOut}>
            {getIcon()}
            <Grid className={classes.cancelledTextContainer}>
              <Label className={classes.title} type="subtitleLight">
                {getTitle()}
              </Label>
              <Label className={classes.subtitle}>{getSubTitle()}</Label>
            </Grid>
          </Grid>
          {isAgentSold && !isAgency ? ( // only shows to internal users
            <Label className={classes.agentSold}>
              Do not attempt to rewrite as Branch direct or Branch Plus
              <br />
              To reinstate, please follow your designated escalation process
            </Label>
          ) : (
            <Label className={classes.subtitle}>
              {isAgency
                ? `To reinstate or rewrite this policy, please contact `
                : `To reinstate or rewrite this policy, please contact your supervisor.`}
              {isAgency && (
                <span onClick={openFrontChat} className={classes.subtitleLink}>
                  Branch Support.
                </span>
              )}
            </Label>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default withToast(CancelledPolicyFooter);
