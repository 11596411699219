import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { Grid, Button } from '@material-ui/core';
import { paymentMethod } from '@ourbranch/lookups';

import { useBasisTheoryToken } from 'common/hooks/use-basis-theory-token';
import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';
import { useStore } from 'core/store';
import { useToast } from 'core/components/toast';
import { CreditCardForm } from '../../../payment-tab/credit-card-form';
import { maskingDots } from '../helpers';
import { StripeCustomerIdField } from '../stripe-customer-id-field';
import useStyles from '../billing-details.styles';

const CreditCards = ({ isCancelled }) => {
  const classes = useStyles();
  const { viewOnly } = useContext(AuthContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const toast = useToast();
  const {
    account: {
      id: accountId,
      policies: {
        policy: {
          policy: { id },
          addCreditCard,
          changeCreditCard,
          replaceCreditCard,
          billingDetails
        }
      }
    }
  } = useStore();
  const { getBasisTheoryCCToken } = useBasisTheoryToken();
  const [showModify, setShowModify] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const allPaymentMethods = billingDetails.allPaymentMethods || [];
  const showActions = !showModify && !showAdd && values.paymentMethod === paymentMethod.CreditCard && !viewOnly;
  const defaultCreditCard = values?.defaultCreditCard?.last4;

  const handleChangeCardDetails = async () => {
    try {
      const { token } = await getBasisTheoryCCToken();

      if (token) {
        const card = await changeCreditCard({
          accountId,
          policyId: id,
          basisTheoryCardToken: token
        });
        if (card.data) {
          const { creditCard } = card.data;
          toast.notify({
            type: 'success',
            message: `Default card ending in ${defaultCreditCard} has been replaced by card ending in ${creditCard.last4}`
          });

          setShowModify(false);
          replaceCreditCard(values.defaultCreditCard, creditCard);
          setFieldValue('defaultCreditCard', {
            id: creditCard.id || '',
            value: `${creditCard.brand ? creditCard.brand : ''} ${maskingDots}${creditCard.last4}`
          });
        }
      }
    } catch (error) {
      await toast.notify({
        type: 'error',
        message: `An error occurred, ${error.message}`
      });
    }
  };

  const handleAddCard = async () => {
    try {
      const { token, card } = await getBasisTheoryCCToken();
      const cardDetails = await addCreditCard({
        accountId,
        policyId: id,
        basisTheoryCardToken: token
      });
      toast.notify({
        type: 'success',
        message: `Card ending in ${card.last4} has been added.`
      });
      setShowAdd(false);
      setFieldValue('defaultCreditCard', {
        id: cardDetails.id,
        value: `${cardDetails.brand ? cardDetails.brand : ''} ${maskingDots}${cardDetails.last4}`
      });
      setFieldTouched('defaultCreditCard', true);
    } catch (error) {
      toast.notify({
        type: 'error',
        message: `An error occurred, ${error.message}`
      });
    }
  };

  const toggleModifyCreditCardForm = () => {
    setShowModify(!showModify);
    setShowAdd(false);
  };

  const toggleAddCreditCardForm = () => {
    setShowAdd(!showAdd);
    setShowModify(false);
  };

  const creditCardsOptions = allPaymentMethods
    .filter((method) => !!method.brand)
    .map((card) => ({
      id: card.id || '',
      value: `${card.brand ? card.brand : ''} ${maskingDots}${card.last4}`
    }));

  return (
    <>
      <Grid container justifyContent="flex-start" className={classes.billingDetailsContainer} spacing={4}>
        <FormField
          name="defaultCreditCard.id"
          xs={4}
          type="select"
          mode="dark"
          options={creditCardsOptions}
          label="Credit Card Number"
          disabled={!creditCardsOptions.length || isCancelled}
          fast={false}
          permissions={{ isLicensedAction: false }}
        />
        <StripeCustomerIdField />
      </Grid>

      {showActions && (
        <Grid item container xs={12}>
          {creditCardsOptions.length > 0 && !isCancelled && (
            <Button onClick={toggleModifyCreditCardForm} style={{ paddingLeft: 0 }} variant="text" color="secondary">
              Replace/modify default card
            </Button>
          )}
          <Button onClick={toggleAddCreditCardForm} variant="text" color="secondary">
            Add new card
          </Button>
        </Grid>
      )}

      {(showModify || showAdd) && (
        <Grid container className={classes.billingDetailsContainer}>
          {showAdd && <CreditCardForm onSubmit={handleAddCard} close={toggleAddCreditCardForm} buttonText="Add Card" />}
          {showModify && <CreditCardForm onSubmit={handleChangeCardDetails} close={toggleModifyCreditCardForm} />}
        </Grid>
      )}
    </>
  );
};

CreditCards.propTypes = {
  frequencyOptions: PropTypes.array.isRequired,
  isCancelled: PropTypes.bool
};

CreditCards.defaultProps = {
  isCancelled: false
};

export default observer(CreditCards);
