import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    background: theme.colorPalette.green_100
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxWidth: 880,
    width: '100%',
    gap: theme.spacing(2)
  },
  iconRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  row: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  titleColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1)
  },
  code: {
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 600,
    color: theme.colorPalette.red_50
  },
  title: {
    color: theme.colorPalette.white_10,
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 600
  },
  subtitle: {
    textAlign: 'left',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: theme.colorPalette.white_30
  },
  branchPlusEligible: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colorPalette.green_15,
    color: theme.colorPalette.white_30,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(0, 2),
    height: 26,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600
  }
});

export default makeStyles(styles);
