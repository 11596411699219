import React, { useEffect, useState } from 'react';
import { Descope } from '@descope/react-sdk';
import { Redirect } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { AmplifyAuthWidget, AuthLoader, oAuthStorage, signIn } from 'core/components/auth';
import { initializeFrontChatAndIdentify } from 'configure-services.js';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import Label from 'core/components/label/label';
import ErrorDialog from 'core/components/error-dialog/error-dialog.js';
import { useQueryParams } from 'core/hooks/useQueryParams';
import useSession from 'core/hooks/use-session';

import useStyles from './sign-in-screen.styles.js';

const descopeAuthEnabled = process.env.REACT_APP_DESCOPE_AUTH_ENABLED === '1';

function SignInScreen() {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const session = useSession();

  const [connectionExpired, setConnectionExpired] = useState(false);
  const [error, setError] = useState(null);
  const [descopeLoading, setDescopeLoading] = useState(true);

  const { hasInitOAuth } = oAuthStorage.getState();

  useEffect(() => {
    // initialize front chat only once when reaching sign in page for first time
    initializeFrontChatAndIdentify();

    if (descopeAuthEnabled) {
      // `signIn` function initializes the auth flow and saves the state in oAuthStorage storage.
      // If user is signing in with SSO, Descope will redirect back to /signin with `code` query param. In this case we don't need to re-initialize the auth flow.
      if (!hasInitOAuth && !queryParams.code) {
        signIn();
      }

      // OIDC connection between Descope and Cognito times out after 5 min.
      // Need to let the user know that the connection has expired and they need to start over.
      const signInTimeout = setTimeout(() => {
        setConnectionExpired(true);
      }, 1000 * 60 * 4.9);

      return () => {
        clearTimeout(signInTimeout);
      };
    }
  }, []);

  if (session.isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (error) {
    return (
      <ErrorDialog
        open
        onClose={() => {
          setError(null);
        }}
        title="Authentication Error"
        errors={[error]}
        label="Contact Support"
        // @TODO: Handle onClick
        onClick={() => {}}
      />
    );
  }

  if (connectionExpired) {
    return (
      <BaseDialog size="md" open>
        <Grid container direction="column">
          <Label type="greenBig" className={classes.title}>
            Your session has expired due to inactivity
          </Label>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Return to sign in page
          </Button>
        </Grid>
      </BaseDialog>
    );
  }

  if (descopeAuthEnabled) {
    return (
      <>
        {descopeLoading && <AuthLoader />}
        <div className={classes.container}>
          <div className={classes.descopeWidget}>
            <Descope
              flowId="sign-in-and-migration-v3"
              form={{ email: oAuthStorage.getAuthEmail() || '' }}
              onError={(error) => {
                setError(error?.detail?.message);
              }}
              onReady={() => {
                setDescopeLoading(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <AmplifyAuthWidget />
    </div>
  );
}

export default SignInScreen;
