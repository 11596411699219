import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  status: {
    fontWeight: 500,
    color: ({ status }) => {
      switch (status) {
        case 'SUCCESS':
          return theme.colors.green_dark;
        case 'FAILED':
          return theme.colors.error;
        case 'PARTIAL_SUCCESS':
          return theme.colorPalette.orange_10;
        case 'IN_PROGRESS':
          return theme.colorPalette.orange_50;
        default:
          return theme.colors.body;
      }
    }
  },
  title: {
    paddingBottom: theme.spacing(5),
    fontWeight: 600
  },
  subtitle: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    fontWeight: 600,
    borderTop: `1px solid ${theme.colorPalette.grey_10_op_38}`
  },
  header: {
    paddingBottom: theme.spacing(5)
  },
  section: {
    padding: `20px 0px`
  },
  sectionContent: {
    marginLeft: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.colorPalette.beige_75
  },
  argumentsContainer: {
    boxShadow: `0px 0px 5px ${theme.colorPalette.grey_10_op_38}`,
    backgroundColor: 'white',
    marginTop: theme.spacing(2),
    borderRadius: 5,
    padding: theme.spacing(4)
  },
  argumentKey: {
    marginRight: theme.spacing(1)
  },
  failedIcon: {
    composes: '$icon',
    color: theme.colorPalette.red_10
  },
  successIcon: {
    composes: '$icon',
    color: theme.colors.green_dark
  },
  partialSuccessIcon: {
    composes: '$icon',
    color: theme.colorPalette.orange_10
  }
}));

export default useStyles;
