import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'core/components/card';
import notificationIconWhite from './alert-small-white.svg';
import useStyles from './future-effective-date-warning.styles';

const FutureEffectiveDateWarning = ({ daysOut }) => {
  const classes = useStyles();
  return (
    <Card className={classes.warning}>
      <img src={notificationIconWhite} alt="Notification Icon" />
      {`All policies must be at least ${daysOut} day${daysOut > 1 ? 's' : ''} future effective.`}
    </Card>
  );
};

FutureEffectiveDateWarning.propTypes = {
  daysOut: PropTypes.number.isRequired
};

export default FutureEffectiveDateWarning;
