import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  footerContainer: {
    display: 'flex',
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  footerRow: {
    display: 'flex',
    alignItems: 'center',
    height: 112,
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(8, 10)
  },
  coverageColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  selectedCoverage: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
    color: theme.colorPalette.green_75,
    marginBottom: theme.spacing(1)
  },
  coverageRow: {
    display: 'flex',
    width: 'fit-content',
    gap: theme.spacing(2)
  },
  pill: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '130px',
    height: '32px',
    borderRadius: '22px',
    color: theme.colorPalette.white_10,
    fontWeight: 600,
    fontSize: 16
  },
  dashed: {
    border: `1px dashed ${theme.colorPalette.green_100}`
  },
  active: {
    backgroundColor: theme.colorPalette.green_75
  },
  pricePill: {
    display: 'flex',
    width: '130px',
    alignItems: 'center',
    height: '24px',
    fontWeight: 400,
    lineHeight: '12px',
    fontSize: 10,
    color: theme.colorPalette.green_90,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 0, 0, 1)
  },
  priceBold: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    margin: theme.spacing(0, 0.5)
  },
  error: { backgroundColor: theme.colorPalette.green_30 },
  submitButton: {
    width: 260,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 24
  },
  buttonGap: {
    marginRight: theme.spacing(6)
  },
  errorIcon: {
    marginRight: theme.spacing(1)
  },
  title: {
    height: 34,
    alignItems: 'flex-end'
  },
  floatingContainer: {
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 99
  },
  footerContent: {
    width: '100%',
    maxWidth: 1280,
    height: 98,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  btn: {
    width: 164
  },
  btnLabel: {
    fontWeight: 600,
    fontSize: 16
  },
  footerRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(6)
  },
  skeleton: {
    backgroundColor: theme.colorPalette.green_75,
    transform: 'scale(1, 1)',
    borderRadius: 22
  }
});

export default makeStyles(styles);
