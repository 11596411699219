const getDeductibleValueFromId = (id) => {
  const idParts = id.split('/');
  return +idParts[0];
};

export const getDeductibleOptions = (options, state, currentDeductible, minimumDeductible) => {
  if (state === 'MD') {
    return options;
  }

  const currentValue = currentDeductible ? getDeductibleValueFromId(currentDeductible) : null;
  const minimum = currentValue && currentValue < minimumDeductible ? currentValue : minimumDeductible;

  return options.map((option) => {
    const value = getDeductibleValueFromId(option.id);
    return {
      ...option,
      disabled: +value < minimum
    };
  });
};
