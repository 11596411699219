/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import debounce from 'lodash-es/debounce';

import { OAuthResponse, SignInScreen } from 'core/components/auth';
import { BranchPlus } from 'branch-plus';
import useSession from 'core/hooks/use-session';
import { PolicyRoute, CustomerRoute, BranchPlusPolicyRoute } from 'customer/components/provider';
import PreviewClarionDoorRequestDifference from 'customer/components/policy/preview-clariondoor-request-diff';
import { OfferProvider, OfferDetails, OfferHeader, CheckoutSidebar, Checkout, Purchased, PreBindUW } from '../../offer';
import LayoutWithSidebar from './layout-with-sidebar';
import Quote from '../../quote';
import Search from '../../search/search';
import AgencyPortal from '../../agency-portal';
import Tools from '../../tools';
import { ReferralRedirect } from '../../referral-redirect';
import { ProtectedRoute } from './protected-route';

import awsConfig from 'aws-exports';

const routesExcludedFromTokenCheck = ['/', '/signin', '/oauth-response'];

Amplify.configure(awsConfig);

const Routes = () => {
  const session = useSession();

  // Amplify doesn't have a straightforward way of checking that the refresh token has expired, so we resort to this.
  useEffect(() => {
    // No need to check for a valid session on the auth routes
    if (routesExcludedFromTokenCheck.includes(window.location.pathname)) {
      return;
    }

    async function validateTokenOrLogout() {
      try {
        await Auth.currentSession();
      } catch (err) {
        await Auth.signOut();
      }
    }

    // Check that there's a valid session every 1 minute
    const interval = setInterval(validateTokenOrLogout, 1000 * 60);

    // On clicks, taps, or keypresses, check that there's a valid session -- debounced to 5 seconds for performance reasons
    const debouncedValidateTokenOrLogoutFunction = debounce(validateTokenOrLogout, 5000, {
      leading: true,
      trailing: false,
      maxWait: 5000
    });
    const events = ['mousedown', 'touchstart', 'keydown'];
    const eventListeners = events.map((event) =>
      document.addEventListener(event, debouncedValidateTokenOrLogoutFunction, true)
    );

    return () => {
      clearInterval(interval);
      events.forEach((event, idx) => {
        document.removeEventListener(event, eventListeners[idx]);
      });
    };
  }, []);

  // check to see if page changes
  const location = useLocation();
  useEffect(() => {
    window.analytics?.page && window.analytics?.page();
  }, [location]);

  if (session.loading) {
    return <></>;
  }

  return (
    <Switch>
      <Route exact path="/signin" component={SignInScreen} />
      <Route exact path="/oauth-response" component={OAuthResponse} />
      {session.isLoggedIn ? (
        <Redirect exact from="/" to={session.showAgencyPortal ? '/portal/updates' : '/search/offers'} />
      ) : (
        <Redirect exact from="/" to="/signin" />
      )}
      <ProtectedRoute exact path="/quote" permission={session.canQuote} component={Quote} />
      <ProtectedRoute path="/portal" permission={session.showAgencyPortal} component={AgencyPortal} />
      <ProtectedRoute path="/search" permission={session.isLoggedIn} component={Search} />
      <ProtectedRoute exact path="/offer/:offerId/purchased" permission={session.canBind}>
        {({
          history,
          match: {
            params: { offerId }
          }
        }) => <Purchased offerId={offerId} history={history} />}
      </ProtectedRoute>
      <ProtectedRoute exact path="/offer/:offerId/:option/checkout" permission={session.canViewCheckoutPage}>
        {({
          history,
          match: {
            params: { offerId, option }
          }
        }) => (
          <LayoutWithSidebar
            offerId={offerId}
            header={OfferHeader}
            content={Checkout}
            side={CheckoutSidebar}
            history={history}
            onBack={() => {
              history.push(`/offer/${offerId}`);
            }}
            option={option}
          />
        )}
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/offer/:offerId/details"
        permission={session.canSeeFullOfferDetails}
        component={OfferDetails}
      />
      <ProtectedRoute
        exact
        path="/offer/:offerId/verifyUW"
        permission={session.canViewCheckoutPage}
        component={PreBindUW}
      />
      <ProtectedRoute path="/offer/:offerId" permission={session.canQuote || session.viewOnly}>
        {({
          match: {
            params: { offerId }
          }
        }) => <OfferProvider offerId={offerId} />}
      </ProtectedRoute>
      <ProtectedRoute path="/branch-plus/:policyId" permission={session.isInternalAgent} component={BranchPlus} />
      <ProtectedRoute
        path="/customer/:id/policy/:policyId/:policyPreviewId"
        permission={session.canViewClarionDoorData}
      >
        {({
          history,
          match: {
            params: { id, policyId, policyPreviewId }
          }
        }) => (
          <PreviewClarionDoorRequestDifference
            accountId={id}
            policyId={policyId}
            history={history}
            policyPreviewId={policyPreviewId}
          />
        )}
      </ProtectedRoute>
      <ProtectedRoute permission={session.isLoggedIn} path="/customer/:id/policy/:policyId">
        {({
          history,
          match: {
            params: { id, policyId }
          }
        }) => {
          if (id !== policyId.split('-')[0]) {
            const correctedId = policyId.split('-')[0];
            return <Redirect to={`/customer/${correctedId}/policy/${policyId}`} />;
          }
          return <PolicyRoute accountId={id} policyId={policyId} history={history} />;
        }}
      </ProtectedRoute>
      <ProtectedRoute path="/customer/:id/branch-plus/:policyId" permission={session.isInternalAgent}>
        {({
          history,
          match: {
            params: { id, policyId }
          }
        }) => <BranchPlusPolicyRoute accountId={id} policyId={policyId} history={history} />}
      </ProtectedRoute>
      <ProtectedRoute permission={session.isLoggedIn} path="/customer/:id">
        {({
          history,
          match: {
            params: { id }
          }
        }) => <CustomerRoute accountId={id} history={history} />}
      </ProtectedRoute>
      <ProtectedRoute path="/staff/referral" component={ReferralRedirect} permission={session.canQuote} />
      <ProtectedRoute path="/staff/tools" component={Tools} permission={session.canViewTools} />
      {/* This handles No Match routes */}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
