import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import useStyles from './mortgage-information.styles';

const MortgageInformation = ({ error, mortgageDetails, children }) => {
  const classes = useStyles();
  const { correctName, address, city, state, zip } = mortgageDetails;

  return (
    <Grid container justifyContent="space-between">
      <div className={classes.nameContainer}>
        <Label type="body2" className={classNames({ [classes.error]: error })}>
          {correctName}
        </Label>
        <Label type="body4" className={classNames(classes.address, { [classes.error]: error })}>
          {address}, {city}, {state}, {zip}
        </Label>
      </div>
      {children}
    </Grid>
  );
};

MortgageInformation.propTypes = {
  mortgageDetails: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default MortgageInformation;
