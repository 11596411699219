import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    amplifyWidgetFooter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 20,
      padding: '0px 32px',
      '& .anotherEmailBtn': {
        background: theme.colorPalette.green_30,
        color: '#FFF',
        width: '100%',
        height: 42,
        borderRadius: 4,
        fontSize: 16,
        fontWeight: 600,
        transition: 'all 0.2s ease-out',
        margin: '5px 0px',
        border: 'none',
        '&:hover': {
          cursor: 'pointer',
          opacity: 0.8
        }
      },
      '& .resetPasswordBtn': {
        // This component will be deprecated soon enough so not necessary to add this color to the theme
        color: '#FF9900',
        background: 'none',
        border: 'none',
        marginTop: 30
      }
    }
  })
);
