import * as Yup from 'yup';
import { isWithinInterval, parseISO, isSameDay, format, isBefore, isAfter, addDays } from 'date-fns';
import { getValue, paymentMethod, paymentType, mustRenewAutoLimit, flatCancelReasons } from '@ourbranch/lookups';

import { PolicyStatus } from 'core/helpers/policy-status';
import { requiredString } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.string, 'requiredString', requiredString);

/* FYI
 * address node is already validated as required in the add-interested-party-form.js
 * we don't want to add required again here, because we can load up a policy with an address as null,
 * and we should allow the policy modification to go through without this validation blocking it
 */
const lienHolderValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().required('Name or Company are required!'),
    address: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable()
      })
      .nullable(),
    loanNumber: Yup.string().nullable(),
    VIN: Yup.string().nullable()
  })
).nullable();

const partiesValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().requiredString('Name or Company are required!'),
    relationship: Yup.string().requiredString('Relationship is required'),
    address: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable()
      })
      .nullable()
  })
).nullable();

const validations = {
  A: lienHolderValidation,
  H: partiesValidation
};

const today = format(new Date(), 'yyyy-MM-dd');

export const validationSchema = ({
  policyType,
  minDate,
  maxDate,
  values,
  wasEffectiveDateUpdated,
  policyStatus,
  policyDetails,
  billingDetails,
  term,
  agentCanBackDate
}) => {
  const effectiveDateSchema = Yup.object().shape({
    effectiveDate: Yup.string().test('invalidEffectiveDate', `Invalid Effective Date`, function test(value) {
      const valueParsed = parseISO(value);

      // reference getStartMinMaxDate function for min/max date logic
      if (term === 1) {
        const effectiveDateWithinValidRange =
          isWithinInterval(valueParsed, { start: minDate, end: maxDate }) ||
          isSameDay(valueParsed, minDate) ||
          isSameDay(valueParsed, maxDate);

        if (!effectiveDateWithinValidRange) {
          return this.createError({
            message: `Effective date must be between ${format(minDate, 'MM/dd/yyyy')} and ${format(
              maxDate,
              'MM/dd/yyyy'
            )}`
          });
        }
      }

      const policyIsActive = PolicyStatus.Active === policyStatus;

      if (wasEffectiveDateUpdated && policyIsActive) {
        return this.createError({
          message: 'Cannot move effective date once policy is already active.'
        });
      }

      return true;
    })
  });
  // validate end date and cancel reason, if you add validation for endDate, you also need to add it for transactionDate
  if (values.cancel) {
    return Yup.object().shape({
      cancelEffectiveDate: Yup.string()
        // can only have cancelEffectiveDate OR transactionDate, so ignore if transactionDate present
        // cancelEffectiveDate = endDate +1 and behaves like endDate, so most of this validation will be similar, but with dates increased by 1
        .test(
          'cancelEffectiveDate_before_effectiveDate',
          'You cannot set the cancel date prior to the policy’s effective date.',
          function test(value) {
            if (this.parent.transactionDate) return true;
            return new Date(this.parent.effectiveDate) <= new Date(value);
          }
        )
        .test('cannot_backdate', 'The Cancel Effective Date must be today or in the future', function test(value) {
          if (this.parent.transactionDate) return true;
          // If agent can't cancelEffectiveDate, then the end date must be today or in the future
          return (
            agentCanBackDate ||
            flatCancelReasons.includes(this.parent.cancelReason) ||
            isBefore(new Date(today), new Date(value)) ||
            isSameDay(new Date(today), new Date(value))
          );
        })
        .test(
          'cancelEffectiveDate_past_fullTermPolicyEndDate',
          'You cannot cancel the policy after the last full day of coverage.',
          function test(value) {
            if (this.parent.transactionDate) return true;
            // cancelEffectiveDate = endDate +1, so compare to fullTermPolicyEndDate +1
            const fullTermCancelEffectiveDate = format(
              addDays(parseISO(values.fullTermPolicyEndDate), 1),
              'yyyy-MM-dd'
            );

            return isAfter(parseISO(fullTermCancelEffectiveDate), parseISO(value));
          }
        )
        .test(
          'no_policies_with_one_day_coverage_cancelEffectiveDate',
          'Branch does not support policies with a single day of coverage.',
          function test(value) {
            if (this.parent.transactionDate) return true;
            // cancelEffectiveDate = endDate +1, so compare to effectiveDate +1
            const effectiveDatePlusOne = format(addDays(parseISO(this.parent.effectiveDate), 1), 'yyyy-MM-dd');

            return value !== effectiveDatePlusOne;
          }
        ),
      endDate: Yup.string()
        // ignore if transactionDate present, otherwise, endDate = cancelEffectiveDate -1
        .required('Required')
        .test(
          'endDate_before_effectiveDate',
          'You cannot set the cancel date prior to the policy’s effective date.',
          function test(value) {
            if (this.parent.transactionDate) return true;
            return new Date(this.parent.effectiveDate) <= new Date(value);
          }
        )
        .test(
          'endDate_past_fullTermPolicyEndDate',
          'You cannot cancel the policy on or after the last full day of coverage.',
          function test(value) {
            if (this.parent.transactionDate) return true;
            return isAfter(parseISO(values.fullTermPolicyEndDate), parseISO(value));
          }
        ),
      transactionDate: Yup.string()
        // can only have cancelEffectiveDate OR transactionDate
        .test(
          'cannot_backdate_transactionDate',
          'You cannot cancel the policy before today’s date.',
          function test(value) {
            if (!value) return true;
            // If agent can't backdate, then the end date must be today or in the future
            return (
              agentCanBackDate ||
              flatCancelReasons.includes(this.parent.cancelReason) ||
              isBefore(new Date(today), new Date(value)) ||
              isSameDay(new Date(today), new Date(value))
            );
          }
        )
        .test(
          'TransactionDate_past_fullTermPolicyEndDate',
          'You cannot cancel the policy after the last full day of coverage.',
          function test(value) {
            if (!value) return true;
            return isAfter(parseISO(values.fullTermPolicyEndDate), parseISO(value));
          }
        ),

      cancelReason: Yup.string().required('Please select the reason for cancelling.')
    });
  }

  let baseSchema = effectiveDateSchema.concat(
    Yup.object().shape({
      additionalParties: validations[policyType],
      paymentType: Yup.string().test('valid', 'Invalid payment type for that payment method', function test(value) {
        if (value === null) {
          return true;
        }

        if (
          (value === paymentType.Escrow && this.parent.paymentMethod !== paymentMethod.Escrow) ||
          (this.parent.paymentMethod === paymentMethod.Escrow && value !== paymentType.Escrow)
        ) {
          const paymentMethod = getValue(
            policyDetails.home ? 'homeownersPaymentMethod' : 'condoPaymentMethod',
            this.parent.paymentMethod
          );
          const paymentType = getValue('paymentType', value);
          return this.createError({
            path: this.path,
            message: `${paymentType} payment type is not valid for ${paymentMethod} payment method`
          });
        }

        return true;
      }),
      renewalPaymentMethod: Yup.string()
        .test('hasPaymentMethodReadyToCharge', 'There is no payment for this payment type', function test(value) {
          const currentPaymentMethod = values.paymentMethod;
          if (!value || value === currentPaymentMethod) {
            return true;
          }

          const primaryMortgage =
            policyDetails?.home?.mortgageDetails.find((md) => md.primary) ||
            policyDetails?.condo?.mortgageDetails.find((md) => md.primary);

          if (value === paymentMethod.Escrow && !primaryMortgage) {
            return this.createError({
              path: this.path,
              message: `Need primary mortgage on policy to be able to have a renewal payment method of Mortgage.`
            });
          }
          if (value === paymentMethod.CreditCard) {
            const hasCreditCard = billingDetails.allPaymentMethods.find(({ id }) => id?.startsWith('card'));
            const hasStripeCustomerId = values.stripeCustomerId?.startsWith('cus');
            if (!hasStripeCustomerId || !hasCreditCard) {
              return this.createError({
                path: this.path,
                message: `Need credit card on policy to have renewal payment method of Credit Card`
              });
            }
          }
          if (value === paymentMethod.ACH) {
            const hasStripeCustomerId = values.stripeCustomerId?.startsWith('cus');
            const hasBankAccount = billingDetails.allPaymentMethods.some(
              ({ id, routingNumber }) => id?.startsWith('ba') || routingNumber
            );

            if (!hasStripeCustomerId || !hasBankAccount) {
              return this.createError({
                path: this.path,
                message: `Need bank account on policy to have renewal payment method of ACH`
              });
            }
          }
          return true;
        })
        .nullable(),
      renewalPaymentType: Yup.string().test(
        'valid',
        'Invalid payment type for the renewal payment method',
        function test(value) {
          if (value == null || values.renewalPaymentMethod == null) return true;

          if (
            (value === paymentType.Escrow && values.renewalPaymentMethod !== paymentMethod.Escrow) ||
            (values.renewalPaymentMethod === paymentMethod.Escrow && value !== paymentType.Escrow)
          ) {
            return false;
          }

          return true;
        }
      ),
      defaultEscrowAccount: Yup.object().when('paymentMethod', {
        is: paymentMethod.Escrow,
        then: Yup.object().shape({
          mortgageHolderName: Yup.string().test('requireIfNotCancelling', 'Required', function test(value) {
            return value || values.cancel;
          })
        }),
        otherwise: Yup.object().nullable()
      }),
      defaultBankAccount: Yup.object().when('paymentMethod', {
        is: paymentMethod.ACH,
        then: Yup.object()
          .shape({
            id: Yup.string().required('Bank account information is required')
          })
          .typeError('Bank account information is required'),
        otherwise: Yup.object().nullable()
      }),
      defaultCreditCard: Yup.object().when('paymentMethod', {
        is: paymentMethod.CreditCard,
        then: Yup.object()
          .shape({
            id: Yup.string().required('Credit card information is required')
          })
          .typeError('Credit card information is required'),
        otherwise: Yup.object().nullable()
      })
    })
  );

  const limitForCancellingAutoPolicyRenewal = mustRenewAutoLimit[values.state]?.[policyType];
  if (limitForCancellingAutoPolicyRenewal) {
    baseSchema = baseSchema.concat(
      Yup.object().shape({
        autoRenew: Yup.boolean().test(
          'valid',
          `Policies more than ${limitForCancellingAutoPolicyRenewal} terms must be renewed.`,
          function test(value) {
            if (this.parent.term <= limitForCancellingAutoPolicyRenewal) {
              return true;
            }
            return this.parent.term > limitForCancellingAutoPolicyRenewal && value;
          }
        )
      })
    );
  }

  if (values.isNonRenew) {
    return Yup.object().shape({
      nonRenewReason: Yup.mixed().test('non renew reason', 'Required', function test(value) {
        const isNonRenew = this.parent.isNonRenew;
        if (isNonRenew && !value) {
          return this.createError({
            message: 'Please choose a Non-renewal reason'
          });
        }
        return true;
      })
    });
  }

  return baseSchema;
};
