import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import NavigationPrompt from 'react-router-navigation-prompt';
import Button from '@material-ui/core/Button';
import { branchInitiatedCancelReasons, flatCancelReasons } from '@ourbranch/lookups';
import Grid from '@material-ui/core/Grid';

import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import useSession from 'core/hooks/use-session';
import { Label } from 'core';
import LeavePageDialog from 'core/components/leave-page-dialog';
import { footerWarningTexts } from 'core/helpers/constants';
import Field from 'core/components/form/form.v2';
import NotificationIconGreen from 'core/assets/svg/alert-dark-green.svg';

import useStyles from './footer.styles';

function Footer({
  title,
  form,
  leftForm,
  buttonLabel,
  onClick,
  setShowRewriteModal,
  isPolicyEligibleForRewrite,
  policy
}) {
  const classes = useStyles();
  const session = useSession();

  useEffect(() => {
    window.onbeforeunload = () => true;
  }, []);

  const { handleSubmit, values } = useFormikContext();
  const { cancelEffectiveDate, endDate, cancelReason, fullTermPolicyEndDate, transactionDate, effectiveDate } = values;

  let delayPaymentOrRefundTooltip = null;

  if (!session.canDelayPaymentOrRefund) {
    delayPaymentOrRefundTooltip = `If you would like to delay payment/refund for an extenuating circumstance, please ${
      session.isAgency ? 'reach out to Agency Support' : 'follow your designated process'
    }.`;
  }

  const showFooterWarning = cancelReason && cancelReason !== 'NA';
  const needsEndDateCalculation = branchInitiatedCancelReasons.includes(cancelReason);

  const getFooterWarningText = () => {
    if (title && title?.toUpperCase().includes('RESCIND')) {
      return footerWarningTexts.rescind(effectiveDate, endDate);
    }
    if (title && title?.toUpperCase().includes('REINSTATE')) {
      return footerWarningTexts.reinstate(effectiveDate, endDate);
    }
    if (needsEndDateCalculation) {
      return footerWarningTexts.branchInitiatedCancel;
    }
    if (flatCancelReasons?.includes(cancelReason)) {
      return footerWarningTexts.flatCancel;
    }
    if (transactionDate === effectiveDate || endDate === effectiveDate || cancelEffectiveDate === effectiveDate) {
      return footerWarningTexts.memberInitiatedFlatCancel(effectiveDate);
    }
    return footerWarningTexts.memberInitiatedActivePolicy(transactionDate || endDate || fullTermPolicyEndDate);
  };

  return (
    <div className={classNames(classes.container, showFooterWarning && classes.containerWithWarning)}>
      <NavigationPrompt renderIfNotActive={false} when>
        {({ isActive, onCancel, onConfirm }) => {
          return <LeavePageDialog open={isActive} onClose={onCancel} cb={onConfirm} />;
        }}
      </NavigationPrompt>
      <Grid container className={classNames(classes.footerInner, showFooterWarning && classes.innerFooterWithWarning)}>
        <div className={classNames(classes.footerItem, { [classes.withLeftForm]: !!leftForm })}>
          <Label className={classNames(classes.label, { [classes.leftFormLabel]: !!leftForm })} type="subtitle1">
            {title}
          </Label>
          {leftForm}
        </div>
        {form && <div className={classNames(classes.footerItem, { [classes.right]: !!form })}>{form}</div>}
        <Grid className={classNames(classes.footerItem, classes.last)}>
          {session.canDelayPaymentOrRefund && (
            <Field
              type="checkbox"
              id="skipImmediateBillOrRefund"
              name="skipImmediateBillOrRefund"
              mode="light"
              label="Delay Payment/Refund"
              className={classNames({ [classes.checkbox]: !!leftForm })}
              tooltipText={delayPaymentOrRefundTooltip}
              tooltipLabel={delayPaymentOrRefundTooltip ? 'More Info' : null}
              checkboxTooltipClassName={classes.checkboxTooltip}
              ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
              permissions={{
                isLicensedAction: false,
                edit: {
                  groups: [CognitoPermissionGroups.canDelayPaymentOrRefund]
                }
              }}
            />
          )}

          {isPolicyEligibleForRewrite && session.canRewritePolicy && (
            <Button variant="text" className={classes.rewriteBtn} onClick={() => setShowRewriteModal(true)}>
              Rewrite Policy
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            data-cy="preview-policy-changes"
            onClick={onClick || handleSubmit}
            className={classes.btn}
          >
            <Label className={classes.btnLabel}>{buttonLabel || 'Preview policy changes'}</Label>
          </Button>
        </Grid>
      </Grid>
      {showFooterWarning && (
        <Grid className={classes.footerWarning}>
          <img src={NotificationIconGreen} className={classes.iconXSmall} alt="Notification Icon" />
          {getFooterWarningText()}
        </Grid>
      )}
    </div>
  );
}

Footer.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  form: PropTypes.node,
  leftForm: PropTypes.node,
  buttonLabel: PropTypes.string,
  isPolicyEligibleForRewrite: PropTypes.bool
};

Footer.defaultProps = {
  onClick: null,
  form: undefined,
  leftForm: undefined,
  title: 'Create new version',
  buttonLabel: 'Preview policy changes',
  isPolicyEligibleForRewrite: false
};

export default memo(Footer);
